import React, { useRef } from 'react';
import { CrossIcon } from '../../../assets/icons';
import BackgroundDivOverlay  from '../Backgound/BackgrounDivOverlay';
import './Modal.global.scss';

interface IParentProps {
	show: boolean;
	className?: string;
	children: any;
	onClose: () => any;
	appendToElId?: string;
	zIndex?: number;
	id?: string; 
}

const Modal: React.FunctionComponent<IParentProps> = ({
	show,
	children,
	appendToElId = 'root',
	onClose,
	className = '',
	zIndex,
	id = 'zmdl-background', 
}) => {
	if (!show) return null;
	const classes = ['zmdl-inner-container', className];
	const bkgrRef = useRef(null);
	const closeBtnRef = useRef(null);

	const onClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target !== closeBtnRef.current && e.target !== bkgrRef.current)
			return;
		onClose();
	};

	const modal = <div className={classes.join(' ')}>
				    <div
						className={'zmdl-close-btn'}
						onClick={onClickHandler}
						ref={closeBtnRef}
					>
						<CrossIcon />
					</div>
					{children}
				</div>;

	return (
		<BackgroundDivOverlay 
			onClose={onClickHandler}
			appendToElId={appendToElId}
			className={className}
			zIndex={zIndex}
			children={modal}
			backgroundId={id}
		/>
	);
};

export default Modal;
