import React from 'react';

import BackgroundDivOverlay  from '../../../Backgound/BackgrounDivOverlay'; 
import CSS from './TrackingPreview.scss';

interface IParentProps {
	onClose: () => any;
	imgUrl: string;
}

const TImagePreview: React.FunctionComponent<IParentProps> = ({ onClose, imgUrl }) => {
	const fullScreenImage = <img className={CSS.Image} src={imgUrl} />; 

	return (
		<BackgroundDivOverlay 
			children={fullScreenImage}
			onClose={onClose}
		/>
	)
};

export default TImagePreview;
