import { ISceneSnapshotDict } from '../store/actions';
import { zwClient } from '../syncdoc';
import { Environment } from 'zw-api-client/src/specs';
import {
	ISocialProvider,
	ISocialOptions,
	IAreaTypes,
	ITuple2,
	IFontTypes,
	IUnitTypes,
} from '../components/r3f/r3f-components/component-data-structure';
import { IDomIdSelectors } from '../../typings';
import { SNAPSHOT_PX_HEIGHT, SNAPSHOT_TTL_MS } from '.';
import { useRef } from 'react';

export const isNull = (num: null | number) => (num as any) === null;

export const calcBooleanMarkerIndexVariables = (markerIndexPressed: number) => {
	return {
		hMiddleMarkerPressed: markerIndexPressed === 1 || markerIndexPressed === 5,
		vMiddleMarkerPressed: markerIndexPressed === 3 || markerIndexPressed === 7,
		topSideMarkerPressed: markerIndexPressed < 3,
		rightSideMarkerPressed: markerIndexPressed > 1 && markerIndexPressed < 5,
		bottomSideMarkerPressed: markerIndexPressed > 3 && markerIndexPressed < 7,
		leftSideMarkerPressed: markerIndexPressed === 0 || markerIndexPressed > 5,
		topCornerPressed: markerIndexPressed === 0 || markerIndexPressed === 2,
		bottomCornerPressed: markerIndexPressed === 4 || markerIndexPressed === 6,
	};
};

export const replaceCharInStringAt = (i: number, str: string, rpl: string) => {
	return str.substr(0, i) + rpl + str.substr(i + rpl.length);
};

interface ISocialRegex {
	[provider: string]: RegExp;
}

interface ISceneSnapshotData {
	value: string,
	expiry: number,
}

export const socialRegex: ISocialRegex = {
	facebook: /(?:(?:http|https):\/\/)?(?:www.)?(?:facebook.com|fb.com)(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/,
	youtube: /(?:(?:http|https):\/\/)?(?:www.)?(?:youtube.com)(?:\/user\/|\/channel\/)?(?:(?:\w)*#!\/)?([\w\-]*)?/,
	reddit: /(?:(?:http|https):\/\/)?(?:(?:www.)?(?:(?:(?:(?:reddit.com)(?:\/user|\/channel))|(?:^u|^r))(?:\/)?(?:(?:\w)*#!\/)?([\w\-]*)|(?:reddit.com(?:\/)?)))/,
	tiktok: /(?:(?:http|https):\/\/)?(?:www.)?(?:tiktok.com)(?:(?:\/@)(?:(?:\w)*#!\/)?([\w\-]*))?/,
	soundCloud: /(?:(?:http|https):\/\/)?(?:www.)?(?:soundcloud.com)(?:(?:\/)(?:(?:\w)*#!\/)?([\w\-]*))?/,
	twitch: /(?:(?:http|https):\/\/)?(?:www.)?(?:twitch.tv)\/?(?:team\/)?(?:(?:\w)*#!\/)?([\w\-]*$)?/,
	linkedIn: /(?:(?:http|https):\/\/)?(?:www.)?(?:linkedin.com)(?:(?:\/in|\/company)(?:\/)(?:(?:\w)*#!\/)?([\w\-]*))?/,
	vimeo: /(?:(?:http|https):\/\/)?(?:www.)?(?:vimeo.com)\/?(?:(?:\w)*#!\/)?([\w\-]*)?/,
	instagram: /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com)\/?(?:(?:\w)*#!\/)?([\w\-]*)?/,
	spotify: /(?:(?:http|https):\/\/)?(?:open.spotify.com)\/?(?:(?:user|playlist)(?:\/)(?:(?:\w)*#!\/)?([\w\-]*))?/,
	twitter: /(?:(?:http|https:))?(?:(?:(?:\/\/)?(?:www)?(?:twitter.com))|(?:\/\/)t.co|^t.co)(?:(?:\/)(?:(?:\w)*#!\/)?([\w\-]*))?/,
};

export const parseSocialUrl = (url: string) => {
	const socials = Object.keys(socialRegex);
	let userName: string | undefined;
	let selectedSocial: ISocialProvider = ISocialProvider.noSocial;

	for (let i = 0; i < socials.length; i++) {
		const provider = socials[i];
		const regex = socialRegex[provider];
		const res = url.match(regex);

		if (res) {
			switch (provider) {
				case 'facebook':
					selectedSocial = ISocialProvider.facebook;
					break;
				case 'youtube':
					selectedSocial = ISocialProvider.youtube;
					break;
				case 'twitter':
					selectedSocial = ISocialProvider.twitter;
					break;
				case 'reddit':
					selectedSocial = ISocialProvider.reddit;
					break;
				case 'tiktok':
					selectedSocial = ISocialProvider.tiktok;
					break;
				case 'soundCloud':
					selectedSocial = ISocialProvider.soundCloud;
					break;
				case 'twitch':
					selectedSocial = ISocialProvider.twitch;
					break;
				case 'linkedIn':
					selectedSocial = ISocialProvider.linkedIn;
					break;
				case 'vimeo':
					selectedSocial = ISocialProvider.vimeo;
					break;
				case 'instagram':
					selectedSocial = ISocialProvider.instagram;
					break;
				case 'spotify':
					selectedSocial = ISocialProvider.spotify;
					break;
				default:
					selectedSocial = ISocialProvider.noSocial;
					break;
			}
			userName = res[1];
		}
	}
	return {
		selectedSocial,
		userName,
	};
};

export const generateSocialUrl = (
	provider: ISocialProvider,
	userName: string
) => {
	let generatedUrl = '';
	let user = userName || '';
	switch (provider) {
		case ISocialProvider.facebook:
			generatedUrl = `https://www.facebook.com/${user}`;
			break;
		case ISocialProvider.youtube:
			generatedUrl = `https://www.youtube.com/user/${user}`;
			break;
		case ISocialProvider.instagram:
			generatedUrl = `https://www.instagram.com/${user}`;
			break;
		case ISocialProvider.reddit:
			generatedUrl = `https://www.reddit.com/user/${user}`;
			break;
		case ISocialProvider.tiktok:
			generatedUrl = `https://www.tiktok.com/@${user}`;
			break;
		case ISocialProvider.soundCloud:
			generatedUrl = `https://soundcloud.com/${user}`;
			break;
		case ISocialProvider.twitch:
			generatedUrl = `https://www.twitch.tv/${user}`;
			break;
		case ISocialProvider.linkedIn:
			generatedUrl = `https://www.linkedin.com/in/${user}`;
			break;
		case ISocialProvider.vimeo:
			generatedUrl = `https://vimeo.com/${user}`;
			break;
		case ISocialProvider.spotify:
			generatedUrl = `https://open.spotify.com/user/${user}`;
			break;
		case ISocialProvider.twitter:
			generatedUrl = `https://www.twitter.com/${user}`;
			break;
		default:
			break;
	}
	return generatedUrl;
};

export const getSocialUrlForProvider = (
	socialOptions: ISocialOptions,
	provider: ISocialProvider
) => {
	switch (provider) {
		case ISocialProvider.facebook:
			return socialOptions.facebook;
		case ISocialProvider.youtube:
			return socialOptions.youtube;
		case ISocialProvider.instagram:
			return socialOptions.instagram;
		case ISocialProvider.reddit:
			return socialOptions.reddit;
		case ISocialProvider.tiktok:
			return socialOptions.tiktok;
		case ISocialProvider.soundCloud:
			return socialOptions.soundCloud;
		case ISocialProvider.twitch:
			return socialOptions.twitch;
		case ISocialProvider.linkedIn:
			return socialOptions.linkedIn;
		case ISocialProvider.vimeo:
			return socialOptions.vimeo;
		case ISocialProvider.spotify:
			return socialOptions.spotify;
		case ISocialProvider.twitter:
			return socialOptions.twitter;
		case ISocialProvider.custom:
			return socialOptions.custom;
		default:
			break;
	}
};

export const parseCustomUrl = (pageUrl: string) => {
	let res = pageUrl.match(
		/(?:(?:http|https):\/\/)?(?:www\.)?((?:[\w\-]{1,})\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%\-_\+.~#?&\/=]*)/
	) as string[] | null;
	let isValid = !!res;
	if (res) isValid = res[0].length === pageUrl.length;
	return {
		isValid,
		domain: res ? res[1] : null,
	};
};

export const parseSceneTitle = (title: string) => {
	return title.match(/([\S]{1,}\s{1}(?:\[{1}[\d]{1,}\]){0,})\[{1}([\d]{1,})\]{1}$/);
}

export const parsePhoneNumber = (numberString: string) => {
	let res = numberString.match(
		/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
	) as string[] | null;
	let isValid = !!res;
	return { isValid };
};

// Email validator that adheres directly to the specification for email address naming.
// It allows for everything from ipaddress and country-code domains, to very rare characters
// in the username.

export const parseEmailAddress = (email: string) => {
	let res = email.match(
		/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
	) as string[] | null;
	let isValid = !!res;
	return { isValid };
};

function setLocalStorageWithExpiry(key: string, value: any, ttlInMs: number): void {
	const now = new Date()
	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item: ISceneSnapshotData = {
		value: value,
		expiry: now.getTime() + ttlInMs,
	}
	localStorage.setItem(key, JSON.stringify(item))
}

export const updateLStorageWithSnapshot = (
	activeSceneId: string,
	projectId: string,
) => {
  if (!activeSceneId) return;
  const canvasWrapperDiv = document?.getElementById?.(IDomIdSelectors.zapparCanvas);
  const canvas = canvasWrapperDiv?.getElementsByTagName?.("canvas")?.item(0);
  if (!canvas) return;
  const canvasRatio = canvas.width / canvas.height;
  const sizeAdjCanvas = document.createElement("canvas");
  sizeAdjCanvas.width = SNAPSHOT_PX_HEIGHT * canvasRatio;
	sizeAdjCanvas.height = SNAPSHOT_PX_HEIGHT;
	const sizeAdjCanvasCtx = sizeAdjCanvas.getContext('2d');
	sizeAdjCanvasCtx.imageSmoothingEnabled = false;
	sizeAdjCanvasCtx.drawImage(
		canvas,
		0,
		0,
		canvas.width,
		canvas.height,
		0,
		0,
		sizeAdjCanvas.width ,
		sizeAdjCanvas.height
	);

	const snapshotBase64 = sizeAdjCanvas.toDataURL('image/png');
	setLocalStorageWithExpiry(`${projectId}_${activeSceneId}_zapparScene`, snapshotBase64, SNAPSHOT_TTL_MS)
	return { [activeSceneId]: snapshotBase64 };
};

function getSnapshotAndUpdateExpiry(key: string, ttlInMs: number) {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) return null;
	let item = JSON.parse(itemStr) as ISceneSnapshotData;
	const now = new Date();
	item.expiry = now.getTime() + ttlInMs;
	localStorage.setItem(key, JSON.stringify(item))
	return item.value
}

export const removeExpiredSceneSnapshots = () => {
	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i);
		const isZapparScene = key.split('_')[2] === 'zapparScene';
		if (!isZapparScene) continue;
		const itemStr = localStorage.getItem(key);
		const item = JSON.parse(itemStr)
		const now = new Date();
		if (now.getTime() > item.expiry) localStorage.removeItem(key)
	}
}

export const getSnapshotDictFromLStorage = (
	localStorage: Storage,
	currentProjectId: string
) => {
	let scenesSnapshotDict: ISceneSnapshotDict = {};
	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i);
		const isZapparScene = key.split('_')[2] === 'zapparScene';
		if (!isZapparScene) continue;
		const projectId = key.split('_')[0];
		if (projectId !== currentProjectId) continue; //TODO: refactor
		const sceneId = key.split('_')[1];
		const dataUrl = getSnapshotAndUpdateExpiry(key, SNAPSHOT_TTL_MS)
		//const dataUrl = localStorage.getItem(key);
		scenesSnapshotDict[sceneId] = dataUrl;
	}
	return scenesSnapshotDict;
};



export const removeSnapshotFromLStorage = (
	localStorage: Storage,
	currentProjectId: string,
	sceneId: string
) => {
	localStorage.removeItem(`${currentProjectId}_${sceneId}_zapparScene`)
}

interface IObject {
	[key: string]: any;
}

export const checkIfObjectArrayPropsEqual = (
	props: string[],
	objArray: IObject[]
) => {
	let propsToCheck: { [key: string]: boolean[] } = {};

	for (let i = 0; i < props.length; i++) {
		const property = props[i];
		const value = objArray[0][property];
		if (typeof value !== 'object' && typeof value !== 'undefined')
			propsToCheck[property] = [true];
		else if (value instanceof Array)
			propsToCheck[property] = value.map((val: any) => true);
	}
	for (let i = 0; i < objArray.length - 1; i++) {
		const prevObj = objArray[i];
		const currObj = objArray[i + 1];
		for (const key in propsToCheck) {
			for (let i = 0; i < propsToCheck[key].length; i++) {
				propsToCheck[key][i] = prevObj[key]?.[i] === currObj[key]?.[i];
			}
		}
	}
	return propsToCheck;
};

export const addUrlToSocialParams = (
	url: string,
	socialData: ISocialOptions,
	provider: ISocialProvider
) => {
	switch (provider) {
		case ISocialProvider.facebook:
			socialData.facebook = url;
			break;
		case ISocialProvider.youtube:
			socialData.youtube = url;
			break;
		case ISocialProvider.instagram:
			socialData.instagram = url;
			break;
		case ISocialProvider.reddit:
			socialData.reddit = url;
			break;
		case ISocialProvider.tiktok:
			socialData.tiktok = url;
			break;
		case ISocialProvider.soundCloud:
			socialData.soundCloud = url;
			break;
		case ISocialProvider.twitch:
			socialData.twitch = url;
			break;
		case ISocialProvider.linkedIn:
			socialData.linkedIn = url;
			break;
		case ISocialProvider.vimeo:
			socialData.vimeo = url;
			break;
		case ISocialProvider.spotify:
			socialData.spotify = url;
			break;
		case ISocialProvider.twitter:
			socialData.twitter = url;
			break;
		case ISocialProvider.custom:
			socialData.custom = url;
			break;
		default:
			break;
	}
};

export const getDimensionsForAreaTypeInMm: (a: IAreaTypes) => ITuple2 = (
	areaType: IAreaTypes
) => {
	switch (areaType) {
		case IAreaTypes.a5:
			return [148, 210];
		case IAreaTypes.a4:
			return [210, 297];
		case IAreaTypes.a3:
			return [297, 420];
		case IAreaTypes.ltr:
			return [215.9, 279.4];
		case IAreaTypes.b5:
			return [176, 250];
		case IAreaTypes.b4:
			return [257, 364];
		case IAreaTypes.pstc:
			return [197.56, 101.6];
		case IAreaTypes.bsnc:
			return [88.9, 50.8];
		case IAreaTypes.pstr:
			return [457.2, 609.6];
		case IAreaTypes.blbrd:
			return [6096, 3084];
		case IAreaTypes.tbl:
			return [279.4, 431.8];
		case IAreaTypes.lghtBx:
			return [1187, 1750];
		default:
			return [210, 297];
	}
};

export const getAreaAspectRatioForArea = (a: IAreaTypes) => {
	const scaleInMm = getDimensionsForAreaTypeInMm(a);
	return scaleInMm[0] / scaleInMm[1];
}

export const getAreaTypeForAreaScaleInMm = (definedAreaTypes: IAreaTypes[], area: ITuple2) => {
	return definedAreaTypes.filter(areaType => {
		const areaTypeScaleInMm = getDimensionsForAreaTypeInMm(areaType);
		// console.log('areaTypeScaleInMm', areaTypeScaleInMm)
		// console.log('area', area)
		return areaTypeScaleInMm[0] === area[0] && areaTypeScaleInMm[1] === area[1]
	})[0] || IAreaTypes.custom;
};



export const convUnitToMm = (value: number, unit: IUnitTypes) => {
	switch (unit) {
		case IUnitTypes.coords:
			return 0.01;
		case IUnitTypes.mm:
			return 1 * value;
		case IUnitTypes.cm:
			return 10 * value;
		case IUnitTypes.inch:
			return 25.4 * value;
		case IUnitTypes.ft:
			return 304.8 * value;
		// case IUnitTypes.m:
		// 	return 1000 * value;
		default:
			return null;
	}
}

export const getInputStepByUnit = (unit: IUnitTypes): number=> {
	switch (unit) {
		case IUnitTypes.coords: 
			return 1; 
		case IUnitTypes.cm: 
			return 0.01;
		case IUnitTypes.mm: 
			return 0.1; 
		case IUnitTypes.ft: 
			return (0.01 * 0.0328084);
		case IUnitTypes.inch: 
			return (0.01 * 0.3937008);
		default: 
			return 1; 
	}
}

export const convMmToUnit = (value: number, unit: IUnitTypes) => {
	return value / convUnitToMm(1, unit);
}

export const getProjectsUrl = () => {
	switch (zwClient.env) {
		case Environment.Local:
			return 'https://local.my.zap.works/projects';
		case Environment.Dev:
			return 'https://dev.my.zap.works/projects';
		case Environment.Staging:
			return 'https://staging.my.zap.works/projects';
		default:
			return 'https://my.zap.works/projects';
	}
};

export const getXYCoordsFromTransform = (transformString: string) => {
	return [
		transformString
			.split('(')[1]
			.split(',')[0]
			.split('px')[0],
		transformString
			.split('(')[1]
			.split(',')[1]
			.split('px')[0],
	];
};

export const getFontUrlFromDict = (
	fontType: IFontTypes,
	fontObjArray: { [fontName: string]: string }[]
) => {
	const selFontObjArray = fontObjArray.filter(
		fontObj => fontObj.name === fontType
	);
	if (!selFontObjArray.length) return null;
	return selFontObjArray[0].url;
};

export const loadFontsToDocument = async (
	fontObjArray: { fontFamily: IFontTypes; url: string; desc: string }[]
) => {
	for (let i = 0; i < fontObjArray.length; i++) {
		const { url, fontFamily } = fontObjArray[i];
		// FontFace() has poor browser support: https://developer.mozilla.org/en-US/docs/Web/API/FontFace/FontFace
		// const font = new FontFace(fontFamily, `url(${url})`);
		// await font.load();
		// document.fonts.add(font);

		const style = document.createElement('style');
		style.innerHTML = `@font-face {
			font-family: ${fontFamily};
			src: url(${url}) format("woff");
		}`;
      	document.head.appendChild(style);
	}
};

export const moveArrayItemsByOffset = (arr1: any[], arr2: any[], offset: number) => {
	let newArray: string[] = [];
	const selIdArray = arr2.map(id => ({id, index: arr1.indexOf(id) + offset}));
	const filtArray = arr1
		.filter(item => !arr2.includes(item))
		.map(id => ({id, index: arr1.indexOf(id)}));

	// console.log('selIdArray', selIdArray);
	// console.log('filtArray', filtArray);

	for (let i = (offset < 0 ? offset : 0); i < arr1.length + (offset < 0 ? 0 : offset); i++) {
		const selItem = selIdArray.filter(el => el.index === i)[0];
		const filtItem = filtArray.filter(el => el.index === i)[0];
		console.log(arr1[i], selItem, filtItem);
		if (!selItem && !filtItem) continue;
		else if (selItem && filtItem) newArray = [...newArray, selItem.id, filtItem.id];
		else if (filtItem) newArray = [...newArray, filtItem.id];
		else if (selItem) newArray = [...newArray, selItem.id];
	}
	return newArray;
}

export const useIsDoubleClick = (ms: number = 400) => {
	const clickRef = useRef(0)
	let timeoutRef = useRef<NodeJS.Timeout>();

	return () => {
		clickRef.current++
		if (clickRef.current === 1) {
			timeoutRef.current = setTimeout(() => clickRef.current = 0, ms);
			return false;
		} else {
			timeoutRef.current && clearTimeout(timeoutRef.current);
			clickRef.current = 0;
			return true;
		}
	}
}