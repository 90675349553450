import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { onSetMultipleEntityProps_Ed_Doc, onSetPositions_Cn_Doc, onSetRotations_Cn_Doc, onSetScales_Cn_Doc } from "../../../../store/actions";
import TransformControlsReactWrapper from "./TransformControlsReactWrapper";
import { Vector3, Euler } from "three";
import { maths } from '../../../../utils';
import { IDesignerState } from "../../../../../typings";
import { IBaseSpatialComponent, IComponentType } from "../../r3f-components/component-data-structure";
import { Axis } from "./TransformUtils";


const TransformControls = () => {
  // Refs
  const transformControlsRef = useRef<any>();
  const prevScale = useRef<any>();

  // Redux
  const dispatch = useDispatch();
  const mode = useSelector((state: IDesignerState) => state.userReducer.transformControlsMode);
  const entityId = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds[0]);
  const entity = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById[entityId]);
  const aspectRatioIsLocked = useSelector((state: IDesignerState) => state.editorReducer.editorDoc.aspectRatioLocked[entityId]);
  const startPosition = useSelector((state: IDesignerState) => (entityId ? (state.contentReducer.contentDoc.componentsById[entityId] as IBaseSpatialComponent).position : null));
  const initialEntityScale = useSelector((state: IDesignerState) => (state.contentReducer.contentDoc.componentsById[entityId] as IBaseSpatialComponent).scale);
  const initialEntityRotation = useSelector((state: IDesignerState) => (state.contentReducer.contentDoc.componentsById[entityId] as IBaseSpatialComponent).rotation);
  const transientEntityScale = useSelector((state: IDesignerState) => state.editorReducer.editorDoc.scales[entityId]);
  const transientEntityRotation = useSelector((state: IDesignerState) => state.editorReducer.editorDoc.rotations[entityId]);
  const hideZ = entity.type !== IComponentType.Model3d && mode === 'scale';

  // Helpers
  const translateAction = (newPosition: Vector3, mode: 'change' | 'complete') => {
    const position = newPosition.toArray();
    // While changing, update the editor doc for transient changes, on complete update the content doc and reset the editor doc for this entity
    if (mode === 'change') {
      dispatch(onSetMultipleEntityProps_Ed_Doc([{ id: entityId, positions: position }]));
    } else {
      dispatch(onSetPositions_Cn_Doc({ [entityId]: position }));
      dispatch(onSetMultipleEntityProps_Ed_Doc([{ id: entityId, positions: null }]));
    }
  };

  const constrainTo360 = (angle: number) => {
    if (angle >= 0 && angle <= 360) return angle;
    if (angle < 0) return 360 + angle;
  }

  const rotateAction = (newRotation: Euler, mode: 'change' | 'complete') => {
    let rotation = newRotation.toArray();
    let updatedRotation = (transientEntityRotation ? transientEntityRotation : initialEntityRotation);
    updatedRotation = [constrainTo360(maths.toDegrees(rotation[0])),constrainTo360(maths.toDegrees(rotation[1])),constrainTo360(maths.toDegrees(rotation[2]))];

    // While changing, update the editor doc for transient changes, on complete update the content doc and reset the editor doc for this entity
    if (mode === 'change') {
      dispatch(onSetMultipleEntityProps_Ed_Doc([{ id: entityId, rotations: updatedRotation }]));
    } else {
      dispatch(onSetRotations_Cn_Doc({ [entityId]: updatedRotation }));
      dispatch(onSetMultipleEntityProps_Ed_Doc([{ id: entityId, rotations: null }]));
    }
  };

  const scaleAction = (newScale: Vector3, axis: Axis, mode: 'change' | 'complete') => {
    let scale = newScale.toArray();
    let updatedScale = (transientEntityScale ? transientEntityScale : initialEntityScale);
    // Calculate difference from previous state if we are in the middle of dragging, or use 1, 1, 1 if this is the first change
    let difference = (prevScale.current
      ? [scale[0] - prevScale.current[0], scale[1] - prevScale.current[1], scale[2] - prevScale.current[2]]
      : [scale[0] - 1, scale[1] - 1, scale[2] - 1]);
    let dampener = 1; // Use to adjust the speed of the scaling
    prevScale.current = scale;

    // console.log('=====================');
    // console.log('Prev State: ', prevValue.current);
    // console.log('Initial scale: ', initialEntityScale);
    // console.log('Transient scale: ', transientEntityScale);
    // console.log('TC Supplied scale: ', scale);
    // console.log('Difference: ', difference);

    if (aspectRatioIsLocked) {
      const XYratio = initialEntityScale[0] / initialEntityScale[1];
      const XZratio = initialEntityScale[0] / initialEntityScale[2];
      const YXratio = initialEntityScale[1] / initialEntityScale[0];
      const YZratio = initialEntityScale[1] / initialEntityScale[2];
      const ZXratio = initialEntityScale[2] / initialEntityScale[0];
      const ZYratio = initialEntityScale[2] / initialEntityScale[1];
      
      switch (axis) {
        case 'X':
          updatedScale[0] += (difference[0] * dampener);
          updatedScale[1] = updatedScale[0] / XYratio; 
          updatedScale[2] = updatedScale[0] / XZratio;
          break;
        case 'Y':
          updatedScale[1] += (difference[1] * dampener);
          updatedScale[0] = updatedScale[1] / YXratio; 
          updatedScale[2] = updatedScale[1] / YZratio;
          break;
        case 'Z':
          updatedScale[2] += (difference[2] * dampener);
          updatedScale[0] = updatedScale[2] / ZXratio; 
          updatedScale[1] = updatedScale[2] / ZYratio; 
        default:
          break;
      }
      // console.log(`${axis} Axis changed, updating X to ${updatedScale[0]}`);
      // console.log(`${axis} Axis changed, updating Y to ${updatedScale[1]}`);
      // console.log(`${axis} Axis changed, updating Z to ${updatedScale[2]}`);
    } else {
      updatedScale[0] += difference[0] * dampener;
      updatedScale[1] += difference[1] * dampener;
      updatedScale[2] += difference[2] * dampener;
    }
    // console.log('Updated scale: ', updatedScale);
    
    // While changing, update the editor doc for transient changes, on complete update the content doc and reset the editor doc for this entity
    if (mode === 'change') {
      dispatch(onSetMultipleEntityProps_Ed_Doc([{ id: entityId, scales: [updatedScale[0], updatedScale[1], updatedScale[2]] }]));
    } else {
      dispatch(onSetScales_Cn_Doc({ [entityId]: [updatedScale[0], updatedScale[1], updatedScale[2]] }));
      dispatch(onSetMultipleEntityProps_Ed_Doc([{ id: entityId, scales: null }])); 
    }
  };

  const _onMouseUp = (e: Event) => {
      // console.log({ e });
      switch (mode) {
        case 'translate':
          translateAction((e?.target as any).object.position, 'complete');
          break;
        case 'scale':
          scaleAction((e?.target as any).object.scale, (e?.target as any).axis, 'complete');
          break;
        case 'rotate':
          rotateAction((e?.target as any).object.rotation, 'complete');
          break;
      }
  }

  const _onObjectChange = (e: Event) => {
      // console.log(e.target);
      switch (mode) {
        case 'translate':
          translateAction((e?.target as any).object.position, 'change');
          break;
        case 'scale':
          scaleAction((e?.target as any).object.scale, (e?.target as any).axis, 'change');
          break;
        case 'rotate':
          rotateAction((e?.target as any).object.rotation, 'change');
          break;
      }
  }

  return (
    <TransformControlsReactWrapper
      ref={transformControlsRef}
      mode={mode}
      scale={[1, 1, 1]}
      initialEntityRotation={
        (transientEntityRotation
          ? [maths.toRadians(transientEntityRotation[0]), maths.toRadians(transientEntityRotation[1]), maths.toRadians(transientEntityRotation[2])]
          : [maths.toRadians(initialEntityRotation[0]), maths.toRadians(initialEntityRotation[1]), maths.toRadians(initialEntityRotation[2])]
        )}
      startPosition={startPosition}
      showZ={!hideZ}
      entityId={entityId}
      onMouseUp={_onMouseUp}
      onObjectChange={_onObjectChange}
    />
  );
};

export default TransformControls;
