import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import ArrowUpIcon from '../../../../assets/icons/arrow-up';
import ArrowDownIcon from '../../../../assets/icons/arrow-down';
import CSS from './Numerical.scss';

export enum IInputPosition {
	first = 'first',
	center = 'center',
	last = 'last',
}

const FUNCTIONAL_KEYS = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Enter', 'Meta', 'Tab']
const POSITION_STYLES = {
    [IInputPosition.first]: CSS.First,
    [IInputPosition.center]: CSS.Center,
    [IInputPosition.last]: CSS.Last,
}
interface IProps {
    className?: string;
    placeHolder?: string;
    value?: number;
    labelText?: string;
    labelColor?: string;
    step?: number;
    decimals?: number;
    min?: number;
    max?: number;
    adjustmentFactor?: number;
    disabled?: boolean;
    allowNegative?: boolean;
    continuousRange?: boolean;
    position?: IInputPosition;
    onChange?: (v: number | undefined, e?: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => any;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => any;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => any;
    debug?: boolean;
}

const NumericalInput = ({
    className,
    placeHolder,
    value,
    labelText = '',
    labelColor = '#5D5D5D',
    step = 0.01,
    decimals = 2,
    min,
    max,
    adjustmentFactor = 1,
    disabled = false,
    allowNegative = true,
    continuousRange = false,
    position = IInputPosition.center,
    onChange, 
    onBlur,
    onFocus,
    debug = false,
}: IProps)  => {    
    const [displayValue, setDisplayValue] = useState<string>('');
    const [hasFocus, setHasFocus] = useState<boolean>(false);
    const [hidePlaceholder, setHidePlaceholder] = useState<boolean>(false);
    const [shiftPressed, setShiftPressed] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    // The timeout is used to implement the 'press arrow button, hold and increment/decrement functionality'
    const [timeOut, setTimeOut] = useState<ReturnType<typeof setTimeout> | undefined>(undefined);
    const timeOutDuration = 50;

    // Wrapper around setDisplayValue to take into account an adjustmentFactor
    const updateDisplayValue = useCallback(
      (displayValue: string) => {
        if (debug) console.log("----------");
        if (debug) console.log("DISPLAY VALUE SUPPLIED: ", displayValue);

        // Handle undefined
        let updateValue = displayValue === undefined ? "" : (+displayValue).toString();
        if (debug) console.log("INTERMEDIATE updateValue: ", updateValue);

        // Handle empty string
        if (displayValue === "") {
          updateValue = "";
          setHidePlaceholder(true);
        }

        // Handle -0 ( minus zero )
        if (displayValue === "-0") updateValue = "-0";
        // And -0. ( minus zero point)
        if (displayValue === "-0.") updateValue = "-0.";

        // If the last character of the string is the decimal point it needs to be re-added
        const lastKeyDecimalPoint = displayValue !== undefined && displayValue[displayValue.length - 1] === ".";
        if (lastKeyDecimalPoint) updateValue = updateValue + ".";
        // .. unless it is the ONLY character
        if (lastKeyDecimalPoint && displayValue === ".") updateValue = ".";
        // ... or the specific case of minus zero point, -0.
        if (lastKeyDecimalPoint && displayValue === "-0.") updateValue = "-0.";

        // If we have a floating .0 ( like 5.0 ) we need to retain the zero in case user is trying to type 5.05 ( for example )
        if (displayValue !== undefined && displayValue.substring(displayValue.length - 2) === ".0")
          updateValue = updateValue + ".0";

        // If user enters minus '-' on it's own this causes updateValue to be NaN, this fixes the issue
        if (isNaN(+updateValue) && displayValue === "-" && allowNegative) updateValue = "-";
        if (debug) console.log("DISPLAY VALUE IS UNDEFINED?: ", displayValue === undefined);
        if (debug) console.log("--> CHANGE IT FOR DISPLAY: ", updateValue);
        if (debug) console.log("----------");
        // Final update
        setDisplayValue(updateValue);
      },
      [adjustmentFactor, setDisplayValue, debug]
    );

    useEffect(() => {
      if (debug)
        console.log("USE EFFECT FIRING - ROUNDING OFF FRACTION ON MOUNT", (value * adjustmentFactor).toFixed(decimals));
      // Only update if there is a change and it's not undefined
      if (value !== undefined && value.toFixed(decimals) !== displayValue)
        updateDisplayValue((value * adjustmentFactor).toFixed(decimals));
    }, [updateDisplayValue, debug, value]);

    useLayoutEffect(() => {
      if (debug) console.log("USE LAYOUT EFFECT FIRING, CURRENT VALUE: ", value);
      const updateValue =
        value !== undefined
          ? decimalPlaces(value) > decimals
            ? value?.toFixed(decimals).toString()
            : value?.toString()
          : undefined;
      if (debug) console.log({ updateValue });

      // Only update if there is a change
      if (updateValue !== displayValue) updateDisplayValue(updateValue);
    }, [value, updateDisplayValue, setDisplayValue]);

    // Update state - display and parent change handler
    const updateState = (
      value: string,
      e?: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>,
      reset?: boolean
    ) => {
      if (reset) {
        if (debug) console.log("RESETTING DISPLAY VALUE & REDUX");
        updateDisplayValue("");
        onChange?.(undefined, e);
      } else {
        if (isValidInput(value)) {
          updateDisplayValue(value);
          if (isValidNumber(value)) {
            //  && (displayAdjustmentFactor < 0 && +displayValue === 0)
            if (debug) console.log("--> CHANGE IT FOR REDUX", value);
            onChange?.(+value * adjustmentFactor, e);
          }
        }
      }
    };

    // Returns the number of decimal places in a given float
    const decimalPlaces = (number: number) => {
      return (+number).toFixed(20).replace(/^-?\d*\.?|0+$/g, "").length;
    };

    // True if it can be stored as a number by JS
    const isValidNumber = (v: string) => {
      // Return early if outside of min / max props
      if (max && +v > max) return false;
      if (min && +v < min) return false;

      return (
        !isNaN(+v) &&
        v[v.length - 1] !== "." && // The second condition checks it isn't something like '5.' as JS would just reset to '5' in this instance
        v !== "-0"
      ); // Without this, if a user starts typing '-0.5' then '-0' will fire onChange, and '-0' will adjust to '0'
    };

    const isArrowKeyPress = (key: string) => {
      return key === "ArrowUp" || key === "ArrowDown";
    };

    // True if it is a partial valid number but potentially can't be stored as a number, eg. '-' or '5.'
    const isValidInput = (v: string) => {
      // Negative Check
      if (allowNegative) {
        return /^[-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/.test(v) || v === "" || v === "-" || v === ".";
      } else {
        return /^([0-9]+\.?[0-9]*|\.[0-9]+)$/.test(v) || v === "" || v === ".";
      }
    };

    // True if it's a key like left, right, delete, backspace etc
    const isFunctionalKey = (key: string) => {
      return FUNCTIONAL_KEYS.includes(key);
    };

    // If user clicks arrows, or presses up/down arrows
    const handleArrowPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "ArrowUp") {
        stepUp();
      } else {
        stepDown();
      }
    };

    const handleArrowClick = (e: React.MouseEvent<HTMLButtonElement>, upOrDown: "up" | "down") => {
      e.preventDefault();
      setTimeOut(setTimeout(() => handleArrowClick(e, upOrDown), timeOutDuration));
      if (upOrDown === "up") {
        stepUp();
      } else {
        stepDown();
      }
    };

    const _onPointerUp = (e: React.MouseEvent<HTMLButtonElement>) => {
      clearTimeout(timeOut);
      e.preventDefault();
    };

    const stepUp = () => {
      const proposedUpdate = shiftPressed ? +inputRef?.current?.value + step * 10 : +inputRef?.current?.value + step;
      // Return early if outside of max props and we aren't allowing continuous input
      if (max !== undefined && proposedUpdate > max && !continuousRange) return false;
      if (debug) console.log("STEPPING UP: ", proposedUpdate.toFixed(decimals));

      // If we are allowing continuous input and this would go beyond max, set to min
      const updateValue = continuousRange && proposedUpdate > max ? min : proposedUpdate;
      updateState(updateValue.toFixed(decimals));
    };

    const stepDown = () => {
      const proposedUpdate = shiftPressed ? +inputRef?.current?.value - step * 10 : +inputRef?.current?.value - step;
      // Return early if outside of min props and we aren't allowing continuous input
      if (min !== undefined && proposedUpdate < min && !continuousRange) return false;
      if (debug) console.log("STEPPING DOWN: ", proposedUpdate.toFixed(decimals));

      // If we are allowing continuous input and this would go beyond max, set to min
      const updateValue = continuousRange && proposedUpdate < min ? max : proposedUpdate;
      updateState(updateValue.toFixed(decimals));
    };

    const isMinusAtStart = (start: number, end: number, keyPressed: string) => {
      return start === 0 && start === end && keyPressed === "-";
    };

    const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      updateState(value, e);
    };

    const _onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (debug) console.log("ON BLUR FIRED, DISPLAY VALUE: ", displayValue);
      // ONLY update the display value to the decimal places, as redux / js would round it off, as stored as number
      if (displayValue !== "") updateDisplayValue((+displayValue).toFixed(decimals));
      // If the user has entered - or . and then pressed enter or otherwise caused a blur, reset to the last valid number entered
      if (displayValue === "-" || displayValue === ".") updateDisplayValue(value.toFixed(decimals));

      // Handle 'snap to' min/max values if user enters a value that is out of bounds
      if (max && +displayValue > max) updateState(max.toString(), e);
      if (min && +displayValue < min) updateState(min.toString(), e);

      setHasFocus(false);
      onBlur?.(e);
    };

    const _onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setHasFocus(true);
      onFocus?.(e);
    };

    const isAllSelected = (e: React.KeyboardEvent<HTMLInputElement>): boolean => {
      const positionStart = (e.target as any)?.selectionStart as number;
      const positionEnd = (e.target as any)?.selectionEnd as number;
      const currentLength = inputRef?.current?.value?.length;
      if (positionStart === 0 && positionEnd === currentLength) {
        return true;
      }
      return false;
    };

    const isCmdA = (e: React.KeyboardEvent<HTMLInputElement>): boolean => {
      return (e.metaKey && e.key === "a") || (e.ctrlKey && e.key === "a");
    };

    const _onKeyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Shift") setShiftPressed(false);
    };

    const _onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
      let potentialValue = inputRef?.current?.value.concat(e.key) as string;
      const positionStart = (e.target as any)?.selectionStart as number;
      const positionEnd = (e.target as any)?.selectionEnd as number;
      const currentLength = inputRef?.current?.value?.length;
      const keyPressed = e.key;
      if (debug) console.log({ keyPressed });
      if (debug) console.log({ potentialValue });

      // Keep track of whether shift is being pressed
      if (e.key === "Shift") setShiftPressed(true);

      // If all input is selected, update state to the last key pressed, or delete if backspace or delete
      if (isAllSelected(e)) {
        if (debug) console.log("ALL INPUT IS SELECTED");
        if (keyPressed === "Backspace" || keyPressed === "Delete") {
          updateState("", e, true);
        } else if (keyPressed === "ArrowUp" || keyPressed === "ArrowDown") {
          handleArrowPress(e);
        } else {
          updateState(keyPressed, e);
        }
        e.preventDefault();
        return;
      }

      // If user has pressed cmd or ctrl + a then move adjust to reflect 'select all'
      if (isCmdA(e)) {
        if (debug) console.log("USER HAS PRESSED CMD/CTRL + A");
        (inputRef as any).current.selectionStart = 0;
        (inputRef as any).current.selectionEnd = currentLength;
        e.preventDefault();
      }

      // If user enters '5.5' then move cursor to start and presses - then it registers as '5.5-' when it should be '-5.5' - this fixes it
      if (isMinusAtStart(positionStart, positionEnd, keyPressed)) {
        potentialValue = e.key.concat(inputRef?.current?.value as string);
      }

      // Don't accept non valid entries, but do allow button presses like backspace etc.
      if (!isValidInput(potentialValue) && !isFunctionalKey(e.key)) {
        if (debug) console.log(`BLOCKING ${e.key} AS IT ISN'T A VALID STRING - WOULD BE ${potentialValue}`);
        e.preventDefault();
      }

      // Handle user pressing up/down keys
      if (isArrowKeyPress(e.key)) {
        handleArrowPress(e);
      }

      // Blur for UI on enter press
      if (e.key === "Enter") {
        inputRef?.current?.blur();
      }
    };

    return (
      <>
        <div
          className={[
            CSS.NumericalInput, // Container
            hasFocus && POSITION_STYLES[position], // Borders if in focus
            className && className, // Prop className if present
            disabled && CSS.Disabled,
          ] // Disbled if disabled
            .join(" ")}
        >
          <input
            type="text"
            ref={inputRef}
            placeholder={hidePlaceholder ? undefined : placeHolder}
            value={displayValue}
            disabled={disabled}
            onChange={_onChange}
            onKeyDown={_onKeyDownHandler}
            onKeyUp={_onKeyUpHandler}
            onBlur={_onBlur}
            onFocus={_onFocus}
          />
          {hasFocus && !disabled ? (
            <div className={CSS.InputBtnContainer}>
              <button
                className={CSS.TopArrow}
                onPointerDown={(e) => handleArrowClick(e, "up")}
                onPointerUp={_onPointerUp}
              >
                <ArrowUpIcon viewBox={[0, 0, 7, 7]} />
              </button>
              <button
                className={CSS.BottomArrow}
                onPointerDown={(e) => handleArrowClick(e, "down")}
                onPointerUp={_onPointerUp}
              >
                <ArrowDownIcon viewBox={[0, 0, 7, 7]} />
              </button>
            </div>
          ) : (
            labelText && <label style={{ color: labelColor }}>{labelText}</label>
          )}
        </div>
      </>
    );
}

export default React.memo(NumericalInput);