import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	IDesignerState,
	ILoadedImageInfo,
	ILoadedModel3dInfo,
	IUnionLoadedVideoInfo,
} from '../../../../../../../../typings';
import {
	onGetZmlFileInfo,
	onSetLoadingMedia,
	onRemoveLoadedMedia,
} from '../../../../../../../store/actions';
import { IFileType } from 'zw-api-client/src/zml/specs';
import ZmlEntityDisplay from './ZmlEntityDisplay/ZmlEntityDisplay';
import DragSkeleton from '../../../../../skeletons/DragCntnrSkeleton/DragCntnrSkeleton';
import { zwClient } from '../../../../../../../syncdoc';
import { NUM_OF_ENTITIES_DISPLAYED } from '../../../../../../../utils';

import CSS from './ZmlEntitySelection.scss';
import SubmenuHeader from '../SubmenuHeader/SubmenuHeader';

interface IParentProps {
    loadedEntities: IUnionLoadedVideoInfo[] | ILoadedImageInfo[] | ILoadedModel3dInfo[] | null;
    columns: number;
    fileType: IFileType.Hls | IFileType.Image | IFileType.Model3d;
	showTitle?: boolean;
	onClose: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => any;
}

const ZmlEntitySelection: React.FunctionComponent<IParentProps> = ({
	loadedEntities,
    fileType,
    columns = 2,
	showTitle = false,
	onClose
}) => {
	const dispatch = useDispatch();
	const loadingMedia = useSelector((state: IDesignerState) => state.userReducer.loadingMedia);

	const [totalZmlCount, setTotalZmlCount] = useState(null);
	useEffect(() => {
		const loadZmlMedia = async () => {
			if (loadedEntities?.length) dispatch(onRemoveLoadedMedia({ type: fileType }));
			dispatch(onSetLoadingMedia(true));
			const count = await zwClient.zml.getTotalMediaCount(fileType);
			setTotalZmlCount(count);
			dispatch(onGetZmlFileInfo({ type: fileType, limit: NUM_OF_ENTITIES_DISPLAYED * columns, page: 1}))
		}
		loadZmlMedia();
	}, [dispatch, fileType, columns]);

	const title = useMemo(() => {
		switch (fileType) {
			case IFileType.Hls:
				return 'Videos';
			case IFileType.Image:
				return 'Images';
			case IFileType.Model3d:
				return '3D Models'
			default:
				break;
		}
	}, [fileType])

	const helpUrl = useMemo(() => {
		switch (fileType) {
			case IFileType.Hls:
				return "https://docs.zap.works/designer/components/video/";
			case IFileType.Image:
				return "https://docs.zap.works/designer/components/image/";
			case IFileType.Model3d:
				return "https://docs.zap.works/designer/3d/supported-formats/";
			default:
				break;
		}
	}, [fileType])

	return (
		<div className={CSS.ContentContainer}>
			<SubmenuHeader title={title} helpUrl={helpUrl} onClose={onClose} tooltipHtml='dummy'/>
			{loadingMedia && <DragSkeleton columns={columns} numberDummys={NUM_OF_ENTITIES_DISPLAYED * columns} />}
			{totalZmlCount !== null && !loadingMedia && <ZmlEntityDisplay 
                columns={columns}
				showTitle={showTitle}
                totalZmlCount={totalZmlCount} 
                loadedZmlEntityInfo={loadedEntities}
                fileType={fileType}
            />}
		</div>
	);
};

export default memo(ZmlEntitySelection);