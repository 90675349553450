// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DropDown--yH8Yv{outline:none;position:relative;border:1px solid #b2c4d7;border-radius:5px;height:32px;display:flex;align-items:center;justify-content:space-between;padding:0 6px 0 16px}.DropDown--yH8Yv.Active--1VSAT{border-color:#4a90e2}.DropDown--yH8Yv>span{margin-bottom:2px}.DropDown--yH8Yv.Disabled--liyXU{opacity:.33}.DropDown--yH8Yv .SelectedOptionContainer--1dj6q{display:flex;align-items:center;width:fit-content}.DropDown--yH8Yv .SelectedOptionContainer--1dj6q svg{margin-right:16px;margin-left:0px;width:20px}.Arrow--1v0f0{margin-top:2px;transition:all .2s cubic-bezier(0.4, 0, 0.2, 1)}.RotatedArrow--2YfqZ{transform:rotate(90deg);transition:all .2s cubic-bezier(0.4, 0, 0.2, 1)}", ""]);
// Exports
exports.locals = {
	"DropDown": "DropDown--yH8Yv",
	"Active": "Active--1VSAT",
	"Disabled": "Disabled--liyXU",
	"SelectedOptionContainer": "SelectedOptionContainer--1dj6q",
	"Arrow": "Arrow--1v0f0",
	"RotatedArrow": "RotatedArrow--2YfqZ"
};
module.exports = exports;
