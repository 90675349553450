import React from 'react';
import {
	PhoneContactIcon,
	AddressContactIcon,
	MailContactIcon,
 	WebsiteContactIcon,
} from '../../../../../../../../assets/icons/index';
import {IInputDict} from '../SaveContact';
import { SaveContactIcon } from '../../../../../../../../assets/icons/index';
import CSS from './Summary.scss';


interface IParentProps {
	valuesDict: IInputDict; 
}

const SaveContactSummary: React.FunctionComponent<IParentProps> = ({
	valuesDict
}) => {

	const hasAddress = valuesDict.street1 || valuesDict.street2 || valuesDict.city || valuesDict.county || valuesDict.zip || valuesDict.country;
	
	return (
		<div className={CSS.FlexContainer}>
			<div className={`${CSS.FlexRow} ${CSS.FirstRow}`}>
				<div className={CSS.Photo}>
					<img src={SaveContactIcon}/>
				</div>
				<div className={`${CSS.FlexCol} ${CSS.Description}`}>
					<div>{valuesDict.name}</div>
					{ valuesDict.company &&
						<div className={CSS.Company}>{valuesDict.company}</div>
					}
				</div>
			</div>

			<div className={CSS.FlexRow}>
				<div className={CSS.Logo}><PhoneContactIcon /></div>
				<div className={CSS.Description}>{valuesDict.phone}</div>
			</div>

			{ valuesDict.email &&
				<div className={CSS.FlexRow}>
					<div className={CSS.Logo}><MailContactIcon /></div>
					<div className={CSS.Description}>{valuesDict.email}</div>
				</div>
			}

			{ valuesDict.website &&
				<div className={CSS.FlexRow}>
					<div className={CSS.Logo}><img src={WebsiteContactIcon}/></div>
					<div className={CSS.Description}>{valuesDict.website}</div>
				</div>
			}

			{ valuesDict.phone2 &&
				<div className={CSS.FlexRow}>
					<div className={CSS.Logo}><PhoneContactIcon /></div>
					<div className={CSS.Description}>{valuesDict.phone2}</div>
				</div>
			}

			{ valuesDict.phone3 &&
				<div className={CSS.FlexRow}>
					<div className={CSS.Logo}><PhoneContactIcon /></div>
					<div className={CSS.Description}>{valuesDict.phone3}</div>
				</div>
			}

			{hasAddress && 
				<div className={CSS.FlexRow}>
					<div className={CSS.Logo}><img src={AddressContactIcon}/></div>
			
					<div className={CSS.FlexCol}>
						{ valuesDict.street1 &&
							<div>{valuesDict.street1}</div>
						}

						{ valuesDict.street2 &&
							<div>{valuesDict.street2}</div>
						}

						{ valuesDict.city &&
							<div>{valuesDict.city}</div>
						}

						{ valuesDict.county &&
							<div>{valuesDict.county}</div>
						}

						{ valuesDict.zip &&
								<div>{valuesDict.zip}</div>
						}

						{ valuesDict.country &&
							<div>{valuesDict.country}</div>
						}
					</div>
				</div>
			}
		</div>
	);
};

export default SaveContactSummary;
