import React, { FunctionComponent, useState } from 'react';
import TypeSelection from './TypeSelection/TypeSelection';
import { onSetComponentTransition_Cn_Doc } from '../../../../../../store/actions'; 
import { NumericalInput } from '../../../../Inputs';
import Tabs, { ITabData } from '../../../../Tabs/Tabs';
import Slider from '../../../../Slider/Slider';
import { isNull } from '../../../../../../utils'
import {
	ITransitionDict,
	ITransitionCategoryTypes,
	ITransitionEffectTypes,
	ITransitionData,
} from '../../../../../r3f/r3f-components/component-data-structure';

import CSS from './Transitions.scss';
import { useDispatch } from 'react-redux';

interface ITransitionArgs {
	category?: ITransitionCategoryTypes;
	type?: ITransitionEffectTypes;
	duration?: number;
	delay?: number;
}

interface IParentProps {
	transitionDict: ITransitionDict;
}

const Transitions: FunctionComponent<IParentProps> = ({
	transitionDict,
}) => {
  const dispatch = useDispatch();
	const tabData: ITabData = [
		{ id: ITransitionCategoryTypes.enter, title: 'Enter' },
		{ id: ITransitionCategoryTypes.exit, title: 'Exit' },
	];

	const [transitionCategory, setTransitionCategory] = useState<
		ITransitionCategoryTypes
	>(ITransitionCategoryTypes.enter);
	
	const [localDurationValue, setlocalDurationValue] = useState<number | null>(null);
	const [localDelayValue, setlocalDelayValue] = useState<number | null>(null);

	const targetIds = Object.keys(transitionDict);
	const transData: ITransitionData = transitionDict?.[targetIds[0]]?.[transitionCategory] || {
		type: ITransitionEffectTypes.noEffect,
		duration: 1000, // equals 1; gets divided by 1000
		delay: 0,
	};
	// // If no local values, take redux values.
	const duration = !isNull(localDurationValue) ? localDurationValue : transData.duration;
	const delay = !isNull(localDelayValue) ? localDelayValue: transData.delay;

	const setTransitionValues = (values: ITransitionArgs) => {
		dispatch(onSetComponentTransition_Cn_Doc({
			ids: targetIds,
			category: transitionCategory,
			...transData,
			...values
		}))
	}

	return (
    <>
      <Tabs
        className={CSS.TransitionTabs}
        show={true}
        activeTabId={transitionCategory}
        tabData={tabData}
        onClick={(transitionCategory) => {
          setTransitionCategory(transitionCategory as ITransitionCategoryTypes);
        }}
      />
      <TypeSelection
        setTransitionType={(type) => setTransitionValues({ type })}
        transitionType={transData.type}
        transitionCategory={transitionCategory}
      />

      {transData.type !== ITransitionEffectTypes.noEffect && (
        <>
          <div className={CSS.SliderCol}>
            <div className={CSS.SliderTitle}>Duration</div>
            <div className={CSS.SliderRow}>
              <Slider
                className={CSS.TransitionSlider}
                value={duration / 1000}
                min={0}
                max={10}
                step={0.05}
                onChange={(val) => setlocalDurationValue(val * 1000)}
                onSubmit={() => {
                  setTransitionValues({ duration });
                  setlocalDurationValue(null);
                }}
              />
              <NumericalInput
                className={CSS.TransitionInput}
                value={duration / 1000}
                min={0}
                max={10}
                step={0.05}
                decimals={2}
                labelText="s"
                labelColor="#3F556"
                onChange={(value) => {
                  setTransitionValues({ duration: isNull(value) ? 1000 : value * 1000 });
                }}
                onBlur={(e) => {
                  if (isNull(+e?.target?.value)) setTransitionValues({ duration: 1000 });
                }}
              />
            </div>
          </div>
          <div className={CSS.SliderCol}>
            <div className={CSS.SliderTitle}>Delay</div>
            <div className={CSS.SliderRow}>
              <Slider
                className={CSS.TransitionSlider}
                value={delay / 1000}
                min={0}
                max={5}
                step={0.05}
                onChange={(val) => setlocalDelayValue(val * 1000)}
                onSubmit={() => {
                  setTransitionValues({ delay });
                  setlocalDelayValue(null);
                }}
              />
              <NumericalInput
                value={delay / 1000}
                min={0}
                max={5}
                step={0.05}
                decimals={2}
                labelText="s"
                labelColor="#3F556"
                onChange={(value) => setTransitionValues({ delay: isNull(value) ? 0 : value * 1000 })}
                onBlur={(e) => {
                  if (isNull(+e?.target?.value)) setTransitionValues({ delay: 0 });
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Transitions;
