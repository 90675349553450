import React, { useState, Fragment } from 'react';

import Divider from '../Divider/Divider';
import Tab from './Tab/Tab';

import CSS from './Tabs.scss';

interface ITab {
	id: string;
	title: string;
}

export type ITabData = ITab[];

interface IParentProps {
	style?: any;
	className?: string;
	show: boolean;
	tabData: ITabData;
	activeTabId: string;
	onClick: (tabId: string) => any;
}

const Tabs: React.SFC<IParentProps> = ({
	style,
	show,
	tabData,
	onClick,
	className = '',
	activeTabId,
}) => {
	if (!show) return null;
	const classes = [CSS.TabRow, className];

	const tabs = tabData.map((tab, i) => {
		return (
			<Fragment key={tab.id}>
				<Tab
					id={tab.id}
					title={tab.title}
					onClick={onClick}
					isActive={activeTabId === tab.id}
				/>
				{i < tabData.length - 1 && <Divider height={'80%'} />}
			</Fragment>
		);
	});
	return (
		<div className={classes.join(' ')} style={{ ...style }}>
			{tabs}
		</div>
	);
};

export default Tabs;
