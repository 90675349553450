// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes placeHolderShimmer--3nEbL{0%{background-position:-1500px 0}100%{background-position:1500px 0}}.OuterBackground--3Np5m,.InnerBackground--15jJy{background-color:#f5f5f5;height:100%;width:100%}.OuterBackground--3Np5m{position:absolute}.FadeGradient--2Bj0N{position:absolute;left:0;bottom:-25px;height:100%;width:100%;z-index:1;background-image:linear-gradient(rgba(247, 247, 247, 0), #f7f7f7)}.Skeleton--25nkN{position:relative;transform:rotate(35deg);height:800%;width:800%;top:-370%}.LightShimmer--y30Nw{height:100%;width:100%;position:absolute;top:0;left:0;z-index:2;animation-duration:2.5s;animation-fill-mode:forwards;animation-iteration-count:infinite;animation-name:placeHolderShimmer--3nEbL;animation-timing-function:linear;background-image:linear-gradient(to right, rgba(252, 252, 252, 0) 45%, #fcfcfc 50%, rgba(252, 252, 252, 0) 55%);background-repeat:no-repeat}", ""]);
// Exports
exports.locals = {
	"OuterBackground": "OuterBackground--3Np5m",
	"InnerBackground": "InnerBackground--15jJy",
	"FadeGradient": "FadeGradient--2Bj0N",
	"Skeleton": "Skeleton--25nkN",
	"LightShimmer": "LightShimmer--y30Nw",
	"placeHolderShimmer": "placeHolderShimmer--3nEbL"
};
module.exports = exports;
