// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ButtonRow--3C39c{display:flex;align-items:center;justify-content:center;height:56px;border-radius:6px 6px 0 0;position:absolute;bottom:0;background-color:#fff}.ButtonRow--3C39c button{height:56px}.ButtonRow--3C39c .CopyBtn--1-RmH{width:40px;margin:0 16.5px 0 24.4px}.ButtonRow--3C39c .AddBtn--3vDAr{width:40px;margin:0 24.4px 0 16.5px}.ButtonRow--3C39c .Arrow--3BfLZ{height:10px;width:10px}.ButtonRow--3C39c .turnedArrow--1rhx9{width:10px;height:56px}.ButtonRow--3C39c.SideArrow--2dral{height:18px;width:18px}.ButtonRow--3C39c .NameField--1vAEP{height:54px;padding-right:8px;outline:none;text-align:center;border:none;font-size:16px;font-family:lato,sans-serif;font-weight:500;font-style:normal;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.ButtonRow--3C39c .NameField--1vAEP.ActiveNameField--2jRdL{color:#4a90e2}.ButtonRow--3C39c .PrevSceneBtn--CgPlU,.ButtonRow--3C39c .NextSceneBtn--1XQlW{flex-direction:row;width:32px;height:32px;border-radius:28px;box-shadow:0 2px 4px 0 rgba(52,75,96,.1),0 1px 3px 0 rgba(52,75,96,.2)}.ButtonRow--3C39c .PrevSceneBtn--CgPlU{margin-left:10px}.ButtonRow--3C39c .PrevSceneBtn--CgPlU svg{margin-left:4px;margin-bottom:2px}.ButtonRow--3C39c .NextSceneBtn--1XQlW{margin-right:10px}.ButtonRow--3C39c .NextSceneBtn--1XQlW svg{margin-top:3px;margin-right:1px}.SceneName--Ty-7Y{display:flex;flex-direction:row;align-items:baseline;height:56px;justify-content:center;width:160px;margin:0 8px}.ButtonRow_Inner--M2PJh{display:flex;align-items:center;justify-content:center;border-radius:6px 6px 0 0;overflow:hidden}", ""]);
// Exports
exports.locals = {
	"ButtonRow": "ButtonRow--3C39c",
	"CopyBtn": "CopyBtn--1-RmH",
	"AddBtn": "AddBtn--3vDAr",
	"Arrow": "Arrow--3BfLZ",
	"turnedArrow": "turnedArrow--1rhx9",
	"SideArrow": "SideArrow--2dral",
	"NameField": "NameField--1vAEP",
	"ActiveNameField": "ActiveNameField--2jRdL",
	"PrevSceneBtn": "PrevSceneBtn--CgPlU",
	"NextSceneBtn": "NextSceneBtn--1XQlW",
	"SceneName": "SceneName--Ty-7Y",
	"ButtonRow_Inner": "ButtonRow_Inner--M2PJh"
};
module.exports = exports;
