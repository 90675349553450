import React, { FunctionComponent, useMemo, memo } from 'react';
import { ITuple3, ITuple4 } from '../../component-data-structure';
import RoundedRect from '../RoundedRectWithBorder';
import SvgMesh from '../Svg/Svg';
import play from '../assets/play.svg';
import pause from '../assets/pause.svg';

interface IParentProps {
	showPlayIcon: boolean;
	svgOpacity: number;
	bkgrOpacity: number;
	renderOrder: number;
	name: string;
	visible?: boolean;
}

const PlayBtn: FunctionComponent<IParentProps> = ({
	showPlayIcon,
	svgOpacity,
	bkgrOpacity = 0.4,
	renderOrder,
	name,
	visible = true
}) => {
	const d = useMemo(() => {
    return {
      rectCol: [0, 0, 0, 1] as ITuple4,
      rectScl: [0.2, 0.28, 0] as ITuple3,
      rectPos: [0, 0, 0] as ITuple3,
      playScl: [0.8, 1.25, 0] as ITuple3,
      pauseScl: [0.4, 0.8, 0] as ITuple3,
      playPositionAdjustment: [0, -0.12, 0] as ITuple3,
      pausePositionAdjustment: [0, -0.09, 0] as ITuple3,
    };
  }, []);

  return (
    <group scale={d.rectScl} position={d.rectPos} renderOrder={renderOrder}>
      <RoundedRect
        key={"video_center_btn_bkgr"}
        name={name}
        radius={0.2}
        visible={visible}
        opacity={bkgrOpacity}
        fillRgba={d.rectCol}
        scale={[1, 1, 0]}
        renderOrder={renderOrder}
        depthWrite={false}
      />
      <SvgMesh
        key={"video_play_pause_shape"}
        visible={visible}
        name={name}
        color={"#FFFFFF"}
        opacity={svgOpacity}
        svgUrl={showPlayIcon ? play : pause}
        scale={showPlayIcon ? d.playScl : d.pauseScl}
        position={
          showPlayIcon ? d.playPositionAdjustment : d.pausePositionAdjustment
        }
        renderOrder={renderOrder + 5}
        depthWrite={false}
      />
    </group>
  );
};

export default memo(PlayBtn);
