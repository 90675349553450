import React from 'react';
import { ExpandIcon, qrCodeBorder } from '../../../../../assets/icons';
import { GhostButton } from '../../../buttons';
import PublishBtn from '../../../upload/PublishBtn/PublishBtn';

import CSS from './PreviewSummaryContent.scss';

interface IParentProps {
	projectTitle: string;
	targetThumbnailUrl: string;
	onClose: () => any;
	onShowEnlargeImg: () => any;
	previewCodeSrc: string;
}

const PreviewSummaryContent: React.FunctionComponent<IParentProps> = ({
	projectTitle,
	targetThumbnailUrl,
	previewCodeSrc,
	onClose,
	onShowEnlargeImg,
}) => {
	if (!previewCodeSrc) return null;
	return (
		<div className={CSS.PreviewContent}>
			<span className={CSS.ProjectTitle}>{projectTitle} preview</span>
			<div className={CSS.MainContent}>
				<div className={CSS.QRCodeInfo}>
					<div className={CSS.FlexRow}>
						<span className={CSS.Numbering}>1-</span>
						<span>Use your device camera to scan the test QR code below.</span>
					</div>
					<div className={CSS.FlexRow}>
						<span className={CSS.Numbering}>2-</span>
						<span>Aim at the target image to preview your content.</span>
					</div>

					<div className={CSS.QRCodeContainer}>
						<div className={`${CSS.TestCode} ${CSS.LeftTestCode}`}><div>TEST CODE</div> <div>TEST CODE</div></div>
						<div className={`${CSS.TestCode} ${CSS.RightTestCode}`}><div>TEST CODE</div> <div>TEST CODE</div></div>
						<div className={`${CSS.TestCode} ${CSS.TopTestCode}`}><div>TEST CODE</div> <div>TEST CODE</div></div>
						<div className={`${CSS.TestCode} ${CSS.BottomTestCode}`}><div>TEST CODE</div> <div>TEST CODE</div></div>
						<div className={CSS.CoverDiv} />
						<img className={CSS.QRCodeBorder} src={qrCodeBorder} />
						<img className={CSS.QRCodeIcon} src={previewCodeSrc} />
					</div>
				</div>
				<div className={CSS.TargetContainer}>
					<img className={CSS.TargetImage} src={targetThumbnailUrl} />
					<GhostButton
						className={CSS.FullScreenBtn}
						onClick={() => onShowEnlargeImg()}
					>
						<ExpandIcon />
						Full Screen
					</GhostButton>
				</div>
	
			</div>
			<div className={CSS.ButtonRow}>
				<GhostButton className={CSS.GoBack} onClick={onClose}>
					Go back
				</GhostButton>
				<PublishBtn />
			</div>
		</div>
	);
};

export default PreviewSummaryContent;
