import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../../../typings';
import { onSetActionData_Global,} from '../../../../../../../store/actions';
import { parsePhoneNumber } from '../../../../../../../utils';
import DialNumberSummary from './Summary/Summary';
import { ITriggerTypes, IDialOptions, IActionCategory,} from '../../../../../../r3f/r3f-components/component-data-structure';
import { TextInput } from '../../../../../Inputs';
import CSS from './DialNumber.scss';

interface IParentProps {
	triggerType: ITriggerTypes;
	setShowActionSummary: (value: React.SetStateAction<boolean>) => void;
	showActionSummary: boolean;
}

const SceneLink: React.FunctionComponent<IParentProps> = ({
	triggerType,
	showActionSummary,
	setShowActionSummary,
}) => {
	// Redux & Derived
	const dispatch = useDispatch();
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const id = selectedEntityIds[0];
	const actionsDict = useSelector((state: IDesignerState) => state.editorReducer.editorDoc?.actions?.[id] || {});
	const activeTrigger = useSelector((state: IDesignerState) => state.editorReducer.editorDoc.activeTriggers?.[id] || ITriggerTypes.onLoad);
	const editorDialNumberParams = actionsDict?.[activeTrigger]?.dialOptions || ({} as IDialOptions);

	// State
	const [linkedPhoneNumber, setLinkedPhoneNumber] = useState<string>(editorDialNumberParams.tel || '');
	const [error, setError] = useState<string>('');

	const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLinkedPhoneNumber(e.target.value);
		setError('');
	};

	const keyDownHandler = (e: React.KeyboardEvent) => {
		if (e.keyCode !== 13) return;
		const { isValid } = parsePhoneNumber(linkedPhoneNumber);
		if (!isValid) {
			setError('invalid number');
			return;
		}
		dispatch(onSetActionData_Global({
			ids: selectedEntityIds,
			triggerType: triggerType,
			selectedActionCategory: IActionCategory.dialNumber,
			dialOptions: { tel: linkedPhoneNumber },
		}));
		setShowActionSummary(true);
	};

	return !showActionSummary ? (
		<div className={CSS.FlexContainer}>
			<span className={CSS.Description}>Enter the recipient phone number</span>
			<TextInput
				value={linkedPhoneNumber}
				placeholder={"Area code - 123 4567 890"}
				onChange={changeHandler}
				onKeyDown={keyDownHandler}
				className={CSS.DialNumberInputField}
			/>
			<div className={CSS.Error}>{error}</div>
			<span className={CSS.Description}>
				Note that anyone who accesses your experience will be able to call
				this number by tapping this button.
			</span>
			<a target="_blank" href="https://www.google.com">Learn more</a>
		</div>
	) : (
		<DialNumberSummary tel={linkedPhoneNumber} />
	);
};

export default React.memo(SceneLink);
