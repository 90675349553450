import React, { FunctionComponent, useEffect, useState } from 'react';
import { drawAudio } from './utils';

import AudioControls from './AudioControls/AudioControls';
import VolumeControl from '../../VolumeControl/VolumeControl';

import CSS from './AudioPlayer.scss';

export enum IPlayerStatus {
	paused = 'paused',
	playing = 'playing',
	loading = 'loading',
}

interface IParentProps {
	className?: string;
	src: string;
	loop?: boolean; 
}

const AudioPlayer: FunctionComponent<IParentProps> = ({ className, src, loop = false }) => {
	if (!src) return null;
	const [audio, setAudio] = useState<HTMLAudioElement>(null);

	useEffect(() => {
		const aud = new Audio(src);
		setAudio(aud);
		drawAudio(aud.src);
		aud.pause();
		aud.currentTime = 0;

		let timeUpdateInterval: any;

		const addTimeupdateInterval = () => {
			timeUpdateInterval = window.setInterval(() => {
				const newCurrTime = aud.currentTime;
				setCurrentTime(newCurrTime);
			}, 30);
		};

		const removeTimeUpdateInterval = () => {
			window.clearInterval(timeUpdateInterval);
		};

		const endedHandler = (e: Event) => {
			window.clearInterval(timeUpdateInterval);
			aud.currentTime = 0;
			setCurrentTime(0);
			setStatus(IPlayerStatus.paused);
		};
		// component did unmount (runs once)
		aud.addEventListener('play', addTimeupdateInterval);
		aud.addEventListener('ended', endedHandler);
		aud.addEventListener('pause', removeTimeUpdateInterval);

		// clean up after component will unmount
		return function cleanup() {
			aud.pause();
			aud.currentTime = 0;
			aud.removeEventListener('play', addTimeupdateInterval);
			aud.removeEventListener('ended', endedHandler);
			aud.removeEventListener('pause', removeTimeUpdateInterval);
		};
	}, []);

	const classes = [CSS.Player, className ? className : ''];
	const [status, setStatus] = useState<IPlayerStatus | null>(null);
	const [currentTime, setCurrentTime] = useState<number>(0);
	const [volume, setVolume] = useState<number>(66);

	const limitOffsetXPosition = (offsetX: number) => {
		if (offsetX < 0) return 0;
		return offsetX;
	};

	if (!audio) return null;
	audio.loop = loop; 

	return (
		<div className={classes.join(' ')}>
			<div className={CSS.CanvasContainer}>
				<div
					className={CSS.ProgressDiv}
					style={{
						width: `${(currentTime / audio.duration) * 100}%`,
					}}
				/>
				<canvas
					id={'AudioPlayerCanvas'}
					className={CSS.CanvasGraph}
					onClick={e => {
						const offsetX = limitOffsetXPosition(e.nativeEvent.offsetX);
						const totalWidth = e.currentTarget.clientWidth;
						const ratio = offsetX / totalWidth;
						const newTime = ratio * audio.duration;
						setCurrentTime(newTime);
						audio.currentTime = newTime;
					}}
				></canvas>
			</div>
			<AudioControls
				time={currentTime}
				audio={audio}
				status={status}
				changeStatus={s => setStatus(s)}
				changeTime={t => setCurrentTime(t)}
			/>
			{/* <div className={CSS.VolumeDesc}>Volume</div> */}
			<VolumeControl
				value={volume}
				onChange={volume => {
					setVolume(volume);
					audio.volume = volume / 100;
				}}
			/>
		</div>
	);
};

export default AudioPlayer;
