// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InputRow--2M9c1 {\n\tmargin-bottom: 16px;\n\tdisplay: flex;\n\tfont-size: 14px;\n\tflex-direction: row;\n}\n\n.RowTitle--3Xq7s {\n\tdisplay: flex;\n\talign-items: center;\n\tmargin-bottom: 8px;\n}\n\n.InputRowTitle--DDSWz {\n\twidth: 60px;\n\tdisplay: flex;\n\tfont-size: 14px;\n\tfont-family: 'Lato', sans-serif;\n\tfont-weight: 500;\n\talign-items: center;\n\tcolor: #9d9d9d;\n\tjustify-content: space-between;\n}\n\n.TransformInput--6Bbsj {\n\twidth: 75px;\n}", ""]);
// Exports
exports.locals = {
	"InputRow": "InputRow--2M9c1",
	"RowTitle": "RowTitle--3Xq7s",
	"InputRowTitle": "InputRowTitle--DDSWz",
	"TransformInput": "TransformInput--6Bbsj"
};
module.exports = exports;
