import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../../../typings';
import { convMmToUnit,} from '../../../../../../../utils';
import { onSetMultipleEntityProps_Ed_Doc,onSetMultipleComponentProps_Cn_Doc,IMultipleEntityProps_Cn_Doc, IMultipleEntityProps_Ed_Doc } from '../../../../../../../store/actions';
import { ITuple4, ISpatialComponentUnion, IUnitTypes,} from '../../../../../../r3f/r3f-components/component-data-structure';
import { pictureDummy } from '../../../../../../../assets/icons';
import { NumericalInput } from '../../../../../Inputs';
import ColorPickerMenu from '../../../../ColorPickerMenu/ColorPickerMenu';
import ColorCircle from '../../../Shared/ColorCircle/ColorCircle';
import Slider from '../../../../../Slider/Slider';
import CSS from './ImgVidAppearance.scss';

enum IColorType {
	fillColor = 'fillColor',
	borderColor = 'borderColor,',
}

const AppearanceContent: React.FunctionComponent = () => {
	// Redux
	const dispatch = useDispatch();
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const entityContentDict = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const id = selectedEntityIds[0];
	const editorBorderRgba = useSelector((state: IDesignerState) => {
		if (!state.editorReducer.editorDoc) return undefined;
		if (!state.editorReducer.editorDoc.borderRgba) return undefined; //TODO: fix
		return state.editorReducer.editorDoc.borderRgba[id] as ITuple4;
	});
	const targetDisplayUnits = useSelector((state: IDesignerState) => state.editorReducer.editorDoc?.targetDisplayInfo?.displayUnits);
	const targetHeightInMm = useSelector((state: IDesignerState) => state.editorReducer.editorDoc?.targetDisplayInfo?.heightInMm);
	

	if (selectedEntityIds.length === 0) return null;
	const [activeColorType, setActiveColorType] = useState<IColorType>();
	const borderColorCircleRef = useRef();
	const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
	const entity = entityContentDict[selectedEntityIds[0]] as ISpatialComponentUnion;
	const {
		borderRgba = null,
		opacity = 1,
		borderRadius = 0,
		borderWidth = 0,
		scale,
		type,
	} = entity;
	
	const entityBorderRgba = editorBorderRgba || (borderRgba as ITuple4);
	const unit  = targetDisplayUnits || IUnitTypes.cm; // TODO: remove conditional
	let multiplier = convMmToUnit(unit === IUnitTypes.coords ? 1 : targetHeightInMm, unit) / 4;

	const onClickHandler = (colorType: IColorType) => {
		setActiveColorType(colorType);
		setShowColorPicker(prevState => !prevState);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div className={CSS.SliderCol}>
				<div className={CSS.SliderTitle}>
				Opacity
				</div>
				<div className={CSS.SliderRow}>
					<Slider
						className={CSS.Slider}
						value={opacity * 100}
						max={100}
						min={0}
						step={1}
						onChange={val => {
							const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
							for (let i = 0; i < selectedEntityIds.length; i++) {
								const entityProps: IMultipleEntityProps_Cn_Doc = {
									id: selectedEntityIds[i],
									opacity: val / 100,
								};
								multipleEntityProps_Cn_Doc[i] = entityProps;
							}
							dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
						}}
					/>
					<NumericalInput
						className={CSS.Input}
						value={opacity * 100}
						min={0}
						max={100}
						decimals={0}
						labelText={'%'}
						step={1}
						onChange={val => {
							const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
							for (let i = 0; i < selectedEntityIds.length; i++) {
								const entityProps: IMultipleEntityProps_Cn_Doc = {
									id: selectedEntityIds[i],
									opacity: val / 100,
								};
								multipleEntityProps_Cn_Doc[i] = entityProps;
							}
							dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
						}}
					/>
				</div>
			</div>

			<div>Frames</div>
			<div className={CSS.Grid}>
				<div className={CSS.Square_No_Frame} onClick={() => {
					const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
					for (let i = 0; i < selectedEntityIds.length; i++) {
						const entityProps: IMultipleEntityProps_Cn_Doc = {
							id: selectedEntityIds[i],
							borderWidth: 0,
						};
						multipleEntityProps_Cn_Doc[i] = entityProps;
					}
					dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
				}}>
					<span>No frame</span>
				</div>
				<div className={CSS.Square_White_Border_Outer} onClick={() => {
					const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
					for (let i = 0; i < selectedEntityIds.length; i++) {
						const entityProps: IMultipleEntityProps_Cn_Doc = {
							id: selectedEntityIds[i],
							borderWidth: 0.05,
							borderRgba: entityBorderRgba ? [...entityBorderRgba] : [150, 191, 239, 1],
							borderRadius: 0
						};
						multipleEntityProps_Cn_Doc[i] = entityProps;
					}
					dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
				}}>
					<img className={CSS.Square_White_Border_Inner} src={pictureDummy} />
				</div>
				<img className={CSS.Rounded} src={pictureDummy} onClick={() => {
					const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
					for (let i = 0; i < selectedEntityIds.length; i++) {
						const entityProps: IMultipleEntityProps_Cn_Doc = {
							id: selectedEntityIds[i],
							borderWidth: 0,
							borderRadius: 0.25
						};
						multipleEntityProps_Cn_Doc[i] = entityProps;
					}
					dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
				}}/>
				<img className={CSS.Square_Black_Border} src={pictureDummy} onClick={() => {
					const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
					for (let i = 0; i < selectedEntityIds.length; i++) {
						const entityProps: IMultipleEntityProps_Cn_Doc = {
							id: selectedEntityIds[i],
							borderWidth: 0.05,
							borderRgba: entityBorderRgba ? [...entityBorderRgba] : [150, 191, 239, 1],
							borderRadius: 0.25
						};
						multipleEntityProps_Cn_Doc[i] = entityProps;
					}
					dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
				}}/>
			</div>
			
			<div className={CSS.SliderCol}>
				<div className={CSS.Title}>
					Corner radius
				</div>
				<div className={CSS.SliderRow}>
					<Slider
						className={CSS.Slider}
						value={borderRadius * 100}
						max={100}
						min={0}
						step={1}
						onChange={val => {
							const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
							for (let i = 0; i < selectedEntityIds.length; i++) {
								const entityProps: IMultipleEntityProps_Cn_Doc = {
									id: selectedEntityIds[i],
									borderRadius: val / 100,
								};
								multipleEntityProps_Cn_Doc[i] = entityProps;
							}
							dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
						}}
					/>
					<NumericalInput
						className={CSS.Input}
						value={borderRadius * 100}
						min={0}
						max={100}
						decimals={0}
						labelText={'%'}
						step={1}
						onChange={val => {
							const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
							for (let i = 0; i < selectedEntityIds.length; i++) {
								const entityProps: IMultipleEntityProps_Cn_Doc = {
									id: selectedEntityIds[i],
									borderRadius: val / 100,
								};
								multipleEntityProps_Cn_Doc[i] = entityProps;
							}
							dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
						}}
					/>
				</div>
			</div>

			<div className={CSS.FlexRow} style={{height: '60px'}}>
				<div className={CSS.FlexColumn}>
					<div className={CSS.Title}>
						Border width
					</div>	
					<NumericalInput
						className={CSS.BorderWidthInput}
						value={borderWidth * multiplier}
						min={0}
						decimals={2}
						step={0.2}
						labelText={targetDisplayUnits}
						onChange={v => {
							const val = v / multiplier;
							const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
							for (let i = 0; i < selectedEntityIds.length; i++) {
								const { borderRgba } = entityContentDict[selectedEntityIds[i]] as ISpatialComponentUnion;
								const entityProps: IMultipleEntityProps_Cn_Doc = {
									id: selectedEntityIds[i],
									borderWidth: val,
									borderRgba: borderRgba || [150, 191, 239, 1],
								};
								multipleEntityProps_Cn_Doc[i] = entityProps;
							}
							dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
						}}
					/>
				</div>
				<div 
					className={CSS.FlexColumn} 
					style={{
						opacity: !!borderWidth ? 1 : 0.4,
					}}
				>
					<div className={CSS.Title}>Border color</div>
					<div 
						ref={borderColorCircleRef}
						className={CSS.ColorCircleContainer}  
						style={{ width: 'fit-content' }}
					>
						<ColorCircle
							isActive={showColorPicker}
							disabled={!borderWidth}
							rgba={entityBorderRgba || [150, 191, 239, 1]}
							onClick={() => onClickHandler(IColorType.borderColor)}
							rectangle={true}
						/>
					</div>
				</div>
			</div>
			
			<ColorPickerMenu
				show={showColorPicker}
				rgba={(entityBorderRgba || [150, 191, 239, 1]) as ITuple4}
				targetElRef={borderColorCircleRef}
				onClose={() => setShowColorPicker(false)}
				onChange={([r, g, b, a]) => {
					const multipleEntityProps_Ed_Doc: IMultipleEntityProps_Ed_Doc[] = [];
					for (let i = 0; i < selectedEntityIds.length; i++) {
						multipleEntityProps_Ed_Doc[i] = {
							id: selectedEntityIds[i],
							borderRgba: [r, g, b, a / 100],
						};
					}
					dispatch(onSetMultipleEntityProps_Ed_Doc(multipleEntityProps_Ed_Doc));
				}}
				onSubmit={([r, g, b, a]) => {
					const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
					const multipleEntityProps_Ed_Doc: IMultipleEntityProps_Ed_Doc[] = [];
					for (let i = 0; i < selectedEntityIds.length; i++) {
						multipleEntityProps_Cn_Doc[i] = {
							id: selectedEntityIds[i],
							borderRgba: [r, g, b, a / 100],
						};
						multipleEntityProps_Ed_Doc[i] = {
							id: selectedEntityIds[i],
							borderRgba: [r, g, b, a / 100],
						};
					}
					dispatch(onSetMultipleEntityProps_Ed_Doc(multipleEntityProps_Ed_Doc));
					dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
				}}
			/>
		</div>
	);
};

export default React.memo(AppearanceContent);
