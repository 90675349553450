import React, { useState, useMemo } from 'react';
import Modal from '../../modals/Modal';
import PreviewSummaryContent from './PreviewSummaryContent/PreviewSummaryContent';
import TrackingImgPreview from '../PreviewModal/TrackingPreview/TrackingPreview';

import CSS from './PreviewModal.scss';


interface IParentProps {
	projectTitle: string;
	targetThumbnailUrl: string;
	previewCodeSrc: string;
	onClose: () => any;
}

const PreviewModal: React.FunctionComponent<IParentProps> = ({
	projectTitle,
	targetThumbnailUrl: url,
	previewCodeSrc,
	onClose,
}) => {
	const targetThumbnailUrl = url ? url.split('_')[0] : null; // remove the img dimensions from url
	const [showEnlargeImg, setShowEnlargeImg] = useState<boolean>(false);


	return (
		<>
			{!showEnlargeImg ? (
			<Modal show={true} onClose={onClose}>
				<PreviewSummaryContent
				 		projectTitle={projectTitle}
				 		targetThumbnailUrl={targetThumbnailUrl}
				 		previewCodeSrc={previewCodeSrc}
				 		onShowEnlargeImg={() => setShowEnlargeImg(true)}
						onClose={onClose}
				 	/>
			</Modal>) : (
				<TrackingImgPreview
					onClose={() => setShowEnlargeImg(false)}
					imgUrl={targetThumbnailUrl}
				/>
			)}
		</>
	);
};

export default PreviewModal;
