import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const warningIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="16"
			height="15"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 16 15'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<path d="M5.913 1.06402C6.0831 0.742778 6.33754 0.473969 6.64896 0.286487C6.96038 0.0990046 7.317 -6.10352e-05 7.6805 -6.10352e-05C8.044 -6.10352e-05 8.40062 0.0990046 8.71204 0.286487C9.02346 0.473969 9.2779 0.742778 9.448 1.06402L15.126 11.789C15.2874 12.0938 15.3672 12.4351 15.3578 12.7798C15.3484 13.1245 15.25 13.4609 15.0721 13.7563C14.8943 14.0518 14.6431 14.2962 14.343 14.466C14.0428 14.6357 13.7038 14.7249 13.359 14.725H2.003C1.65808 14.7251 1.319 14.636 1.01869 14.4663C0.718381 14.2967 0.467054 14.0522 0.289121 13.7568C0.111188 13.4613 0.012696 13.1248 0.00321286 12.78C-0.00627029 12.4352 0.0735773 12.0938 0.235 11.789L5.913 1.06402Z" fill={hexFill} />
<path d="M6.681 11.889C6.68124 11.6333 6.78208 11.388 6.96172 11.2062C7.14137 11.0243 7.38538 10.9204 7.641 10.917C7.8972 10.917 8.1429 11.0188 8.32406 11.1999C8.50522 11.3811 8.607 11.6268 8.607 11.883C8.607 12.1392 8.50522 12.3849 8.32406 12.566C8.1429 12.7472 7.8972 12.849 7.641 12.849C7.38728 12.8461 7.14476 12.7441 6.96534 12.5646C6.78592 12.3852 6.68386 12.1427 6.681 11.889V11.889ZM6.969 10.125L6.717 4.72498H8.553L8.313 10.125H6.969Z" fill="white"/>
</svg>

	);
};

export default warningIcon;
