import React, { forwardRef, FunctionComponent, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import MenuList, { IMenuItem } from '../MenuList/MenuList';
// import { IIconParentProps } from '../../../../../assets/icons';
import CSS from './PopupMenu.scss';

interface IParentProps {
	id?: string;
	className?: string;
	style?: React.CSSProperties;
	show: boolean;
	multiSelect?: boolean;
	targetElRef: React.MutableRefObject<any>;
	optionData: IMenuItem[];
	selectedOption?: IMenuItem;
	onChange: (selectedOption: IMenuItem) => any;
	onClose: (e: MouseEvent | null) => any;
	appendToElById?: string;
	appendToRef?: React.MutableRefObject<any>;
}

const PopupMenu: FunctionComponent<IParentProps> = forwardRef(({
	id,
	style,
	className = '',
	show,
	onClose,
	targetElRef,
	multiSelect,
	optionData,
	selectedOption,
	onChange,
	appendToRef,
	appendToElById,
}, ref) => {
	if (!show) return null;
	const popupRef = useRef(null);

	const classes = [CSS.OptionsContainer, className];

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				popupRef.current &&
				!popupRef.current.contains(event.target) &&
				targetElRef?.current &&
				!targetElRef.current?.contains(event.target)
			) {
				onClose(event);
			}
		};
		document.body.addEventListener('pointerdown', handleClickOutside);
		// clean up after 'unmount'
		return function cleanup() {
			document.body.removeEventListener('pointerdown', handleClickOutside);
		};
	}, []);



	const _onChange = (opt: IMenuItem) => {
		onChange(opt);
		if (!multiSelect) onClose(null);
	};

	// append popup to 'root' or specified element
	return createPortal(
		<><div id={id} ref={popupRef} className={classes.join(' ')} style={style}>
			<MenuList
				show
				optionData={optionData}
				selectedOption={selectedOption}
				onChange={_onChange}
			/>
		</div>
		<div 
			onClick={e => e.stopPropagation()}
			className={CSS.OutsideClickDiv}>
		</div>
		</>,
		appendToRef?.current || document.getElementById(appendToElById || 'root')
	);
});

export default PopupMenu;
