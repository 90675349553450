import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const NoActionIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
<svg
			onClick={onClick}
			className={className || ''}
			width="22px"
			height="22px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 22 22`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
  <g id="Atom_Icon_No_Action" transform="translate(-11012.146 -515.646)">
    <rect id="Rectangle_73" width="20" height="20" transform="translate(11012.5 516)" fill="none"/>
    <g id="Group_299" transform="translate(-255.5 116)">
      <g id="Ellipse_17" transform="translate(11268 400)" fill="none" stroke={hexFill} strokeWidth="1">
        <circle cx="10" cy="10" r="10" stroke="none"/>
        <circle cx="10" cy="10" r="9.5" fill="none"/>
      </g>
      <line id="Line_50" y1="20" x2="20" transform="translate(11268 400)" fill="none" stroke={hexFill} strokeWidth="1"/>
    </g>
  </g>
</svg>

	);
};

export default NoActionIcon;
