import React from 'react';
import { ITuple3, countDecimals } from '../../Colorpicker';

import './RgbInputs_1.global.scss';

interface IParentProps {
	rgb: ITuple3;
	updateColorValues: (newRgb: ITuple3) => void;
}

const Rgb01Inputs: React.SFC<IParentProps> = ({ rgb, updateColorValues }) => {
	return (
		<div className={'zcp-flex-row'}>
			<span className={'zcp-desc'}>RGBA</span>
			<input
				className={'zcp-rgb01-input'}
				value={
					countDecimals(rgb[0] / 255) > 3
						? (rgb[0] / 255).toFixed(3)
						: rgb[0] / 255
				}
				type="number"
				min={0}
				max={1}
				step={0.001}
				onChange={e => {
					const val = e.target.valueAsNumber;
					if (val > 1) return;
					updateColorValues([val * 255, rgb[1], rgb[2]]);
				}}
			/>
			<input
				className={'zcp-rgb01-input'}
				value={
					countDecimals(rgb[1] / 255) > 3
						? (rgb[1] / 255).toFixed(3)
						: rgb[1] / 255
				}
				type="number"
				min={0}
				max={1}
				step={0.001}
				onChange={e => {
					const val = e.target.valueAsNumber;
					if (val >= 1) return;
					updateColorValues([rgb[0], val * 255, rgb[2]]);
				}}
			/>
			<input
				className={'zcp-rgb01-input'}
				value={
					countDecimals(rgb[2] / 255) > 3
						? (rgb[2] / 255).toFixed(3)
						: rgb[2] / 255
				}
				type="number"
				min={0}
				max={1}
				step={0.001}
				onChange={e => {
					const val = e.target.valueAsNumber;
					if (val >= 1) return;
					updateColorValues([rgb[0], rgb[1], val * 255]);
				}}
			/>
			{/* <input
				className={CSS.RGBA_A}
				value={alpha}
				type="number"
				min={0}
				max={1}
				step={0.001}
				onChange={e => {
					const val = e.target.valueAsNumber;
					if (val >= 1) return;
					setAlpha(val);
				}}
			/> */}
		</div>
	);
};

export default Rgb01Inputs;
