import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getContentEntityDict, getEditorTargetHeightInMm, getSelectedEditorPositions, getEditorTargetDisplayUnits } from "../../../../../../../store/selectors";
import { IDesignerState } from "../../../../../../../../typings";
import { onSetPositions_Cn_Doc } from "../../../../../../../store/actions";
import { IUnitTypes, ISpatialComponentUnion } from "../../../../../../r3f/r3f-components/component-data-structure";
import { convMmToUnit } from "../../../../../../../utils";
import { NumericalInput } from "../../../../../Inputs";
import Divider from '../../../../../Divider/Divider';
import { IInputPosition } from '../../../../../Inputs/Numerical/Numerical';
import CSS from './TransformsPosition.scss'

interface IParentProps {
  entityIsLocked: boolean;
  entitiesHaveSame: { [key: string]: boolean[] };
}

const TransformsPosition = ({
  entityIsLocked,
  entitiesHaveSame
}: IParentProps) => { 
  // Redux 
  const dispatch = useDispatch();
  const is3dMode = useSelector((state: IDesignerState) => state.userReducer.is3dMode);
  const targetDisplayUnits = useSelector((state: IDesignerState) => getEditorTargetDisplayUnits(state));
  const targetHeightInMm = useSelector((state: IDesignerState) => getEditorTargetHeightInMm(state));
  const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
  const entityContentDict = useSelector((state: IDesignerState) => getContentEntityDict(state));
  const editorPositionDict = useSelector((state: IDesignerState) => getSelectedEditorPositions(state));
  
  // Derived
  const unit = targetDisplayUnits || IUnitTypes.cm;
  const unitIsMm = unit === IUnitTypes.mm;
  const multiplier = convMmToUnit(unit === IUnitTypes.coords ? 1 : targetHeightInMm, unit) / 2;
  const id = selectedEntityIds[0];
  const entity = entityContentDict[id] as ISpatialComponentUnion;
  const position = editorPositionDict[id] || entity.position;

  // Helpers
  const getInputPosition = (index: number) => {
    switch (index) {
      case 0:
        return IInputPosition.first;
      case 1:
        return IInputPosition.center;
      default:
        return IInputPosition.last;
    }
  }

  const updatePosition = (v: number, index: number) => {
      if (typeof v !== 'number') return;
      const value = (v / multiplier) * 2;
      let positionDict: { [id: string]: number[] } = {};
      for (let i = 0; i < selectedEntityIds.length; i++) {
        const id = selectedEntityIds[i];
        const entity = entityContentDict[id] as ISpatialComponentUnion;
        let newPosition = [...entity.position];
        newPosition[index] = value;
        positionDict[id] = newPosition;
      }
      dispatch(onSetPositions_Cn_Doc(positionDict));
  }

  // Content
  const positionInputOptions = is3dMode ? [
		{ text: 'X', color: 'red' },
		{ text: 'Y', color: 'green' },
		{ text: 'Z', color: 'blue' }
	] : [
		{ text: 'X', color: 'red' },
		{ text: 'Y', color: 'green' }
	]

	const positionRowContent = positionInputOptions.map((label, index) => {
		const multitpleEntitiesWithDiffPosition =	entitiesHaveSame && !entitiesHaveSame.position[index];
		const inputPosition = getInputPosition(index);
		
		return (
			<Fragment key={index}>
				<NumericalInput
					decimals={unitIsMm ? 0 : 2}
					step={unitIsMm ? 1 : 0.1}
					placeHolder={'multi'}
					position={inputPosition}
					className={CSS.TransformInput}
					labelText={label.text}
					labelColor={label.color}
					disabled={entityIsLocked}
					value={multitpleEntitiesWithDiffPosition ? undefined : (multiplier * position[index]) / 2 }
					onChange={(v) => updatePosition(v, index)}
					allowNegative={true}
				/>
				{index < (is3dMode ? 2 : 1) && <Divider height={'23px'} />}
			</Fragment>
		);
	});
  
  return (
    <>
    <div className={CSS.RowTitle}>
				Position ({unit})
			</div>
			<div className={CSS.InputRow}>
				{positionRowContent}
			</div>
    </>
  )
}

export default React.memo(TransformsPosition);