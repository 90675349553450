import React, { ForwardedRef }  from 'react';

export const useForwardedRef = <T extends unknown>(ref: ForwardedRef<T>) =>{
    const innerRef = React.useRef(null);
    React.useEffect(() => {
        if (!ref) return;
        if (typeof ref === 'function') {
            ref(innerRef.current);
        } else {
            innerRef.current = ref.current as any;
        }
    });
    return innerRef as React.MutableRefObject<T>;
}
