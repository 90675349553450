// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PublishInfo--E_2uc{display:flex;align-items:center;justify-content:center;width:fit-content}.PublishInfo--E_2uc .StatusCirlce--20fgk{height:12px;width:12px;border-radius:100%;width:12px;height:12px;margin-left:8px;box-shadow:inset 0 0 0 1px rgba(160,160,160,.24)}.PublishInfo--E_2uc .DateString--3ah6W{margin-left:8px}", ""]);
// Exports
exports.locals = {
	"PublishInfo": "PublishInfo--E_2uc",
	"StatusCirlce": "StatusCirlce--20fgk",
	"DateString": "DateString--3ah6W"
};
module.exports = exports;
