import React, { useState } from 'react';
import { TickIcon, IIconParentProps } from '../../../../../assets/icons/index';
import { IMenuItemType } from '../MenuList';
import CSS from './Option.scss';
import GhostBtn from '../../../buttons/GhostBtn/GhostBtn';
import { IFontTypes } from '../../../../r3f/r3f-components/component-data-structure';

export enum IOptionOverflowType {
	ellipsis = 'ellipsis',
}

interface IBaseProps {
	id: string;
	overflow?: IOptionOverflowType;
	type: IMenuItemType;
	icon: React.SFC<IIconParentProps>;
	fontFamily?: IFontTypes;
	children: string;
	isActive: boolean; 
	isHovered: boolean; 
	onClick: (id: string) => any;
	iconOnClick?: (id: string) => any;
	onMouseEnter?: () => any;
	onMouseLeave?: () => any;
	iconOnMouseLeave?: () => any;
	iconOnMouseEnter?: () => any;
}

interface IParentProps extends IBaseProps {
	hasButtonAtEnd: boolean; 
	endButtonText: string; 
}

interface IInternalProps extends IBaseProps {
	hasButtonAtEnd?: boolean; 
	endButtonText?: string; 
}

function Option(p: IParentProps) : JSX.Element;
function Option(p: IBaseProps) : JSX.Element;
function Option(p: any) : JSX.Element {
	const {id,
			icon: Icon,
			fontFamily,
			type,
			children,
			overflow,
			isActive,
			hasButtonAtEnd, 
			endButtonText, 
			isHovered, 
			onClick,
			iconOnClick,
			onMouseEnter,
			onMouseLeave,
			iconOnMouseLeave,
			iconOnMouseEnter,
		} = p as IInternalProps; 
	const textOverflow = overflow || 'hidden';
	let classes = [CSS.Option];
	let iconFill: string | null = isHovered ? '#4A90E2' : null;
	if (isActive) {
		classes.push(CSS.Active);
		iconFill = '#4A90E2';
	}
	if (!Icon) classes.push(CSS.HasNoIcon);
	switch (type) {
		case IMenuItemType.faded:
			classes.push(CSS.Faded);
			break;
		case IMenuItemType.disabled:
			classes.push(CSS.Disabled);
			iconFill = '#E7E7E7';
			break;
		case IMenuItemType.danger:
			classes.push(CSS.Danger);
			iconFill = '#AF0B2B';
			break;
		case IMenuItemType.header:
			classes.push(CSS.Header, CSS.IsHeader);
			iconFill = null;
			break;
		default:
			break;
	}

	return (
		<div
			className={classes.join(' ')}
			onClick={() => {
				if (type !== IMenuItemType.disabled && type !== IMenuItemType.header)
					onClick(id);
			}}
			onMouseEnter={() => onMouseEnter?.()}
			onMouseLeave={() => onMouseLeave?.()}
		>
			{Icon && (
				<div
					className={`${CSS.IconContainer} ${hasButtonAtEnd ? CSS.ButtonAtEndOption : ''}`}
					onMouseEnter={() => {
						if (iconOnMouseEnter) iconOnMouseEnter();
					}}
					onMouseLeave={() => {
						if (iconOnMouseLeave) iconOnMouseLeave();
					}}
				>
					<Icon
						className={CSS.OptionIcon}
						hexFill={iconFill}
						onClick={e => {
							if (iconOnClick) {
								e.preventDefault();
								e.stopPropagation();
								iconOnClick(id);
							}
						}}
					/>
				</div>
			)}
			
			<div className={CSS.Container}>
				<span 
					className={overflow ? CSS.Overflow : null} 
					style={(fontFamily ? Object.assign({ textOverflow },{ fontFamily }) : {textOverflow})}>
						{children}
				</span>
				{isHovered && hasButtonAtEnd && 
					<GhostBtn
						style={{padding: `4px 14px`, marginLeft: `10px`}}
					>{endButtonText}</GhostBtn>
				}
			</div>
			
			
		</div>
	);
};

export default Option;
