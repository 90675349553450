import {
	IUserState,
	ILoadedAudioInfo,
	ILoadedImageInfo,
	IUnionLoadedVideoInfo,
	IToast,
	ITransformControlsMode,
	ILoadedModel3dInfo,
} from '../../../typings';
import uuid4 from 'uuid/v4';

import {
	ISetUserIdAction,
	IUserActionTypes,
	IOnSetPositioningIsActiveAction,
	IAddEntityIdsToSelectionAction,
	IOnChangeZoomLevelAction,
	IDisableUndoAction,
	IDisableRedoAction,
	ISetScaleHotSpotEnabledAction,
	ISetHotKeyAction,
	IRemoveHotKeyAction,
	ISetPointerOffsetAction,
	ISetMarkerIndexPressedAction,
	ISetRotationHotSpotEnabledAction,
	ISetBackgroundHotSpotEnabledAction,
	ISetSelection2DPositionAction,
	ISetGroupInversionAction,
	ICopySelectedEntitiesAction,
	ISetRotationMarker2DPositionAction,
	ISetSelection2DTopLeftPositionAction,
	ISetSelection2DTopRightPositionAction,
	ISetSelection2DBottomRightPositionAction,
	ISetSelection2DBottomLeftPositionAction,
	ISetActiveSceneAction,
	ISetSceneEntityListVisibleAction,
	ISetCurrentSceneIndexAction,
	ISetSceneSnapshotsAction,
	IOnSetSceneCarouselScrollLeftAction,
	IOnSetEnlargeSceneMenuAction,
	IOnFocusSceneNameInputAction,
	ISetPRojectInfoAction,
	IOnUpdateUsers_Action,
	IOnSetCanvasLoadedAction,
	IOnSetEntityMenuDragActiveAction,
	ISetEntityMenuDrag2DPositionAction,
	IOnSetEntityMenuHotspotDragPositionAction,
	IOnSetDraggedEntityInfoAction,
	ISetLoadedImagesAction,
	IOnSetLoadingMediaAction,
	IAddMediaPositionTypes,
	IOnAddToLoadedMediaAction,
	ILoadedMediaTypes,
	ISetLoadedAudioAction,
	IOnSetAudioStatusAction,
	ISetCurrentUploadsAction,
	IOnRemoveLoadedMediaAction,
	IOnSetTrackingImageDataAction,
	ISetTrkImgIsUploadingAction,
	IOnSetPureReactInCanvasHoveredAction,
	IOnAddToastAction,
	IOnRemoveToastAction,
	IOnSetSocketIsReconnectingAction,
	IOnSetSocketHasConnectedAction,
	IOnSetProjectLoadingProgressAction,
	IOnSetProjectLoadingFailureAction,
	IOnSetSelection_Action,
	IOnEditSceneTitleAction,
	IOnScrollSceneMenuAction,
	IOnSetIs3dModeAction,
	IOnSetTransformControlsModeAction,
	IRemoveUploadAction,
	IOnSetSceneHas3dEntityAction,
	IOnSetPublishUploadStatusAction
} from '../actions/action-types';
import { IFileType } from 'zw-api-client/src/zml/specs';
import {
	IUserData,
} from '../../components/r3f/r3f-components/component-data-structure';
import { INITIAL_ZOOM_LEVEL } from '../../utils';

const setUserId = (state: IUserState, action: ISetUserIdAction) => {
	return {
		...state,
		userId: action.payload.userId,
	};
};

const addToast = (
	state: IUserState,
	action: IOnAddToastAction
) : IUserState => {
	const id = action.payload.id || uuid4();
	const toast = {...action.payload, id};
	const deepCopiedToasts = JSON.parse(JSON.stringify(state.toasts)) as IToast[];
	const newToasts = [...deepCopiedToasts, toast];
	return {
		...state, 
		toasts: newToasts
	}
}

const removeToast = (
	state: IUserState,
	action: IOnRemoveToastAction
) : IUserState => {
	const id = action.payload;
	const newToasts = state.toasts.filter(toast => toast.id !== id);

	return {
		...state, 
		toasts: newToasts
	}
}

const setTrackingImageIsUploading = (
	state: IUserState,
	action: ISetTrkImgIsUploadingAction
) : IUserState => {
	return {
		...state,
		trkImgIsUploading: action.payload,
	};
};

const setTrackingImageData = (
	state: IUserState,
	action: IOnSetTrackingImageDataAction
) : IUserState => {
	return {
		...state,
		trackImgData: {
			...state.trackImgData,
			...action.payload,
		},
	};
};

const removeTrackingImageData = (state: IUserState) : IUserState => {
	const trackImgData: null = null;
	return {
		...state,
		trackImgData,
	};
};

const setCanvasIsLoaded = (
	state: IUserState,
	action: IOnSetCanvasLoadedAction
) : IUserState => {
	return {
		...state,
		canvasLoaded: action.payload,
	};
};

const setLoadedImages = (state: IUserState, action: ISetLoadedImagesAction) : IUserState => {
	return {
		...state,
		loadedImages: action.payload,
	};
};

const setLoadedAudio = (state: IUserState, action: ISetLoadedAudioAction) : IUserState => {
	return {
		...state,
		loadedAudio: action.payload,
	};
};

const removeLoadedMedia = (
	state: IUserState,
	action: IOnRemoveLoadedMediaAction
) : IUserState => {
	switch (action.payload.type) {
		case IFileType.Model3d:
			const loaded3dModels: ILoadedModel3dInfo[] = [];
			return {...state, loaded3dModels}
		case IFileType.Image:
			const loadedImages: ILoadedImageInfo[] = [];
			return { ...state, loadedImages };
		case IFileType.Hls:
			const loadedVideos: IUnionLoadedVideoInfo[] = [];
			return { ...state, loadedVideos };
		case IFileType.Audio:
			const loadedAudio: ILoadedAudioInfo[] = [];
			return { ...state, loadedAudio };
		default:
			return state;
	}
};

const addToLoadedMedia = (
	state: IUserState,
	action: IOnAddToLoadedMediaAction
) : IUserState => {
	let media: (ILoadedImageInfo | IUnionLoadedVideoInfo | ILoadedAudioInfo | ILoadedModel3dInfo)[];
	switch (action.payload.mediaType) {
		
		case ILoadedMediaTypes.model3d:
			media = state.loaded3dModels
			? JSON.parse(JSON.stringify(state.loaded3dModels))
			: [];
		break;
		case ILoadedMediaTypes.image:
			media = state.loadedImages
				? JSON.parse(JSON.stringify(state.loadedImages))
				: [];
			media.forEach((image, i) => {
				if (image.name.includes('.png')) {
					(media[i] as ILoadedImageInfo).hasAlpha = true;
				} else {
					(media[i] as ILoadedImageInfo).hasAlpha = false;
				}
			})
			break;
		case ILoadedMediaTypes.video:
			media = state.loadedVideos
				? JSON.parse(JSON.stringify(state.loadedVideos))
				: [];
			break;
		case ILoadedMediaTypes.audio:
			media = state.loadedAudio
				? JSON.parse(JSON.stringify(state.loadedAudio))
				: [];
			break;
		default:
			break;
	}

	switch (action.payload.position) {
		case IAddMediaPositionTypes.start:
			media = [...action.payload.mediaInfo, ...media];
			break;
		case IAddMediaPositionTypes.end:
			media = [...media, ...action.payload.mediaInfo];
			break;
		default:
			media = action.payload.mediaInfo;
			break;
	}

	switch (action.payload.mediaType) {
		case ILoadedMediaTypes.image:			
			return { ...state, loadedImages: media as ILoadedImageInfo[] };
		case ILoadedMediaTypes.model3d:
			return { ...state, loaded3dModels: media as ILoadedModel3dInfo[] }
		case ILoadedMediaTypes.video:
			return { ...state, loadedVideos: media as IUnionLoadedVideoInfo[] };
		case ILoadedMediaTypes.audio:
			return { ...state, loadedAudio: media as ILoadedAudioInfo[] };
		default:
			return state;
	}
};

const removeUpload = (
	state: IUserState,
	action: IRemoveUploadAction
): IUserState => {
	const id = action.payload;
	const currentUploads = JSON.parse(JSON.stringify(state.currentUploads));
	let { numberProcessedUploads } = state;
	delete currentUploads[id];
	if (!Object.keys(currentUploads).length) {
		numberProcessedUploads = 0;
	} else {
		numberProcessedUploads--;
	}
	return {
		...state,
		currentUploads,
		numberProcessedUploads,
	};
}

const setCurrentUploads = (
	state: IUserState,
	action: ISetCurrentUploadsAction
) : IUserState => {
	const { id, progress } = action.payload;
	const currentUploads = JSON.parse(JSON.stringify(state.currentUploads));
	let { numberProcessedUploads } = state;

	// remove from uploads if payload null, else add progressInfo
	if (!progress) {
		delete currentUploads[id];
		if (!Object.keys(currentUploads).length) numberProcessedUploads = 0;
	} else {
		if (!currentUploads[id]) numberProcessedUploads += 1;
		currentUploads[id] = { ...currentUploads[id], ...progress };
	}

	return {
		...state,
		currentUploads,
		numberProcessedUploads,
	};
};

const setEntityMenuDragActive = (
	state: IUserState,
	action: IOnSetEntityMenuDragActiveAction
) : IUserState => {
	return {
		...state,
		entityMenuDragActive: action.payload,
	};
};

const setAudioStatus = (state: IUserState, action: IOnSetAudioStatusAction) : IUserState => {
	const loadedAudio = JSON.parse(
		JSON.stringify(state.loadedAudio)
	) as ILoadedAudioInfo[];
	loadedAudio[loadedAudio.findIndex(a => a.id === action.payload.id)].status =
		action.payload.status;
	return {
		...state,
		loadedAudio,
	};
};

const updateUsers = (state: IUserState, action: IOnUpdateUsers_Action) : IUserState => {
	const { users: syncUsers } = action.payload;
	let users: { [id: string]: IUserData } = {};

	for (let index = 0; index < syncUsers.length; index++) {
		const syncUser = syncUsers[index];
		users[syncUser.id] = {
			...syncUser
		};
	}
	return {
		...state,
		loggedInUsers: users,
	};
};

const setProjectInfo = (state: IUserState, action: ISetPRojectInfoAction) : IUserState => {
	return {
		...state,
		project: action.payload.project,
	};
};

const setEntityDragInfo = (
	state: IUserState,
	action: IOnSetDraggedEntityInfoAction
) : IUserState => {
	return {
		...state,
		entityDragInfo: action.payload,
	};
};

const setPublishUploadStatus = (
	state: IUserState,
	action: IOnSetPublishUploadStatusAction
) : IUserState => {
	return {
		...state,
		publishUploadStatus: action.payload
	};
}


const setHotKey = (state: IUserState, action: ISetHotKeyAction) : IUserState => {
	const { hotKey } = action.payload;
	return {
		...state,
		hotKeys: [...state.hotKeys, hotKey],
	};
};

const removeHotKey = (state: IUserState, action: IRemoveHotKeyAction) : IUserState => {
	const { hotKey } = action.payload;
	return {
		...state,
		hotKeys: state.hotKeys.filter(key => key !== hotKey),
	};
};

const setActiveScene = (state: IUserState, action: ISetActiveSceneAction) : IUserState => {
	const { activeSceneId } = action.payload;
	return {
		...state,
		activeSceneId,
	};
};

const setSortedSceneSnapshots = (
	state: IUserState,
	action: ISetSceneSnapshotsAction
) : IUserState => {
	const sceneSnapshots = action.payload;
	return {
		...state,
		sceneSnapshots,
	};
};

const setCurrentSceneIndex = (
	state: IUserState,
	action: ISetCurrentSceneIndexAction
) : IUserState => {
	const currentSceneIndex = action.payload;
	return {
		...state,
		currentSceneIndex,
	};
};

const scrollSceneMenu = (
	state: IUserState, 
	action: IOnScrollSceneMenuAction
) : IUserState => {
	const scrollSceneMenu = action.payload; 
	return {
		...state, 
		scrollSceneMenu, 
	}
};

const setSceneMenuLarge = (
	state: IUserState,
	action: IOnSetEnlargeSceneMenuAction
) : IUserState => {
	return {
		...state,
		sceneMenuIsLarge: action.payload,
	};
};

const setPositioningActive = (
	state: IUserState,
	action: IOnSetPositioningIsActiveAction
) : IUserState => {
	return {
		...state,
		positioningIsActive: action.payload,
	};
};

const setScaleHotSpotEnabled = (
	state: IUserState,
	action: ISetScaleHotSpotEnabledAction
) : IUserState => {
	return {
		...state,
		scaleHotspotIsEnabled: action.payload,
	};
};

const setRotationActive = (
	state: IUserState,
	action: ISetRotationHotSpotEnabledAction
) : IUserState => {
	return {
		...state,
		rotationIsActive: action.payload,
	};
};

const setBackgroundHotSpotEnabled = (
	state: IUserState,
	action: ISetBackgroundHotSpotEnabledAction
) : IUserState => {
	return {
		...state,
		backgroundHotspotIsEnabled: action.payload,
	};
};

const setPointerOffset = (
	state: IUserState,
	action: ISetPointerOffsetAction
) : IUserState => {
	return {
		...state,
		pointerOffset: action.payload,
	};
};

const setMarkerIndexPressed = (
	state: IUserState,
	action: ISetMarkerIndexPressedAction
) : IUserState => {
	return {
		...state,
		markerIndexPressed: action.payload,
	};
};

const setSceneEntityListVisible = (
	state: IUserState,
	action: ISetSceneEntityListVisibleAction
) : IUserState => {
	return {
		...state,
		sceneEntityListIsVisible: action.payload.isVisible,
		...(action.payload.position && {
			sceneEntityListPosition: action.payload.position,
		}),
	};
};

// const setSnapshotDict = (state: IUserState, action: ISetSnapshotDictAction) => {
// 	const newSnapshots = action.payload;
// 	const stateSnapshotDict = { ...state.snapshotDict };
// 	for (const sceneId in newSnapshots) {
// 		stateSnapshotDict[sceneId] = newSnapshots[sceneId];
// 	}
// 	return {
// 		...state,
// 		snapshotDict: stateSnapshotDict,
// 	};
// };

const disableUndo = (state: IUserState, action: IDisableUndoAction) : IUserState => {
	return {
		...state,
		undoDisabled: action.payload,
	};
};

const disableRedo = (state: IUserState, action: IDisableRedoAction) : IUserState => {
	return {
		...state,
		redoDisabled: action.payload,
	};
};

const isLoadingMedia = (
	state: IUserState,
	action: IOnSetLoadingMediaAction
) : IUserState => {
	return {
		...state,
		loadingMedia: action.payload,
	};
};

const addEntityIdsToSelection = (
	state: IUserState,
	action: IAddEntityIdsToSelectionAction
) : IUserState => {
	const selEntities = state.selectedEntityIds || [];
	const noDuplicates = new Set([...selEntities, ...action.payload]);
	return {
		...state,
		selectedEntityIds: Array.from(noDuplicates)
	}
};

const removeLocalUserSelection = (state: IUserState, action: any) : IUserState => {
	//const { ids } = action.payload;
	// const selEntities = state.selectedEntityIds || [];
	// const filteredIds = selEntities.filter(function(e) {
	// 	return this.indexOf(e) < 0;
	// }, ids);
	return {
		...state,
		selectedEntityIds: [] as string[],
	};
};

const removeSelection = (state: IUserState, action: any) : IUserState => {
	const entityIds = action.payload;
	let { selectedEntityIds } = state;
	if (!entityIds) {
		selectedEntityIds = []
	} else {
		selectedEntityIds = selectedEntityIds.filter(
			entity => entityIds.indexOf(entity) === -1
		);
	}
	
	return {
		...state,
		selectedEntityIds,
	};
};

const changeZoomLevel = (
	state: IUserState,
	action: IOnChangeZoomLevelAction
) : IUserState => {
	return {
		...state,
		zoomLevel: action.payload
	}
}

const setSocketReconnecting = (
	state: IUserState,
	action: IOnSetSocketIsReconnectingAction
) : IUserState => {
	return {
		...state,
		socketIsReconnecting: action.payload
	}
}

const setSocketHasConnected = (
	state: IUserState,
	action: IOnSetSocketHasConnectedAction
) : IUserState => {
	return {
		...state,
		socketHasConnected: action.payload
	}
}

const setProjectLoadingProgress = (
	state: IUserState,
	action: IOnSetProjectLoadingProgressAction
) : IUserState => {
	return {
		...state,
		projectLoadingProgress: action.payload
	}
}

const setProjectLoadingFailure = (
	state: IUserState,
	action: IOnSetProjectLoadingFailureAction
) : IUserState => {
	return {
		...state,
		projectLoadingFailure: action.payload
	}
}

const setSelection2DPosition = (
	state: IUserState,
	action: ISetSelection2DPositionAction
) : IUserState => {
	if (!state.selectedObject2DPosition && !action.payload) return state;
	if (
		state.selectedObject2DPosition &&
		action.payload &&
		state.selectedObject2DPosition[0] === action.payload[0] &&
		state.selectedObject2DPosition[1] === action.payload[1]
	) {
		// No change
		return state;
	}
	return {
		...state,
		selectedObject2DPosition: action.payload,
	};
};

const setSceneCarouselScrollLeft = (
	state: IUserState,
	action: IOnSetSceneCarouselScrollLeftAction
) : IUserState => {
	return {
		...state,
		sceneCarouselScrollLeft: action.payload,
	};
};

const focusSceneNameInput = (
	state: IUserState,
	action: IOnFocusSceneNameInputAction
) : IUserState => {
	return {
		...state,
		sceneNameInputIsFocused: action.payload,
	};
};

const setSelection2DTopLeftPosition = (
	state: IUserState,
	action: ISetSelection2DTopLeftPositionAction
) : IUserState => {
	if (!state.selectionTopLeft2DPosition && !action.payload) return state;
	if (
		state.selectionTopLeft2DPosition &&
		action.payload &&
		state.selectionTopLeft2DPosition[0] === action.payload[0] &&
		state.selectionTopLeft2DPosition[1] === action.payload[1]
	) {
		// No change
		return state;
	}
	return {
		...state,
		selectionTopLeft2DPosition: action.payload,
	};
};

const setSelection2DTopRightPosition = (
	state: IUserState,
	action: ISetSelection2DTopRightPositionAction
) : IUserState => {
	if (!state.selectionTopRight2DPosition && !action.payload) return state;
	if (
		state.selectionTopRight2DPosition &&
		action.payload &&
		state.selectionTopRight2DPosition[0] === action.payload[0] &&
		state.selectionTopRight2DPosition[1] === action.payload[1]
	) {
		// No change
		return state;
	}
	return {
		...state,
		selectionTopRight2DPosition: action.payload,
	};
};

const setSelection2DBottomLeftPosition = (
	state: IUserState,
	action: ISetSelection2DBottomLeftPositionAction
) : IUserState => {
	if (!state.selectionBottomLeft2DPosition && !action.payload) return state;
	if (
		state.selectionBottomLeft2DPosition &&
		action.payload &&
		state.selectionBottomLeft2DPosition[0] === action.payload[0] &&
		state.selectionBottomLeft2DPosition[1] === action.payload[1]
	) {
		// No change
		return state;
	}
	return {
		...state,
		selectionBottomLeft2DPosition: action.payload,
	};
};

const setSelection2DBottomRightPosition = (
	state: IUserState,
	action: ISetSelection2DBottomRightPositionAction
) : IUserState => {
	if (!state.selectionBottomRight2DPosition && !action.payload) return state;
	if (
		state.selectionBottomRight2DPosition &&
		action.payload &&
		state.selectionBottomRight2DPosition[0] === action.payload[0] &&
		state.selectionBottomRight2DPosition[1] === action.payload[1]
	) {
		// No change
		return state;
	}
	return {
		...state,
		selectionBottomRight2DPosition: action.payload,
	};
};

///////

const setRotationMarker2DPosition = (
	state: IUserState,
	action: ISetRotationMarker2DPositionAction
) : IUserState => {
	if (!state.rotationMarker2DPosition && !action.payload) return state;
	if (
		state.rotationMarker2DPosition &&
		action.payload &&
		state.rotationMarker2DPosition[0] === action.payload[0] &&
		state.rotationMarker2DPosition[1] === action.payload[1]
	) {
		// No change
		return state;
	}
	return {
		...state,
		rotationMarker2DPosition: action.payload,
	};
};

const setEntityMenuDrag2DPosition = (
	state: IUserState,
	action: ISetEntityMenuDrag2DPositionAction
) : IUserState => {
	// if (
	// 	state.entityDrag2DPosition &&
	// 	action.payload &&
	// 	state.entityDrag2DPosition[0] === action.payload[0] &&
	// 	state.entityDrag2DPosition[1] === action.payload[1]
	// ) {
	// 	// No change
	// 	return state;
	// }
	return {
		...state,
		entityDrag2DPosition: action.payload
			? action.payload
			: [action.payload[0], action.payload[1]],
	};
};

const setEntityMenuHotspotDragPosition = (
	state: IUserState,
	action: IOnSetEntityMenuHotspotDragPositionAction
) : IUserState => {
	return {
		...state,
		entityDragHotspotPosition: action.payload,
	};
};

const setSelectedEntityIds = (
	state: IUserState,
	action: IOnSetSelection_Action
) : IUserState => {
	const noDuplicates = new Set(action.payload);
	return {
		...state,
		selectedEntityIds: Array.from(noDuplicates)
	}
}

const setPureReactInCanvasHovered = (
	state: IUserState,
	action: IOnSetPureReactInCanvasHoveredAction
) : IUserState => {
	return {
		...state,
		pureReactInCanvasHovered: action.payload,
	};
};

const copySelectedEntities = (
	state: IUserState,
	action: ICopySelectedEntitiesAction
) : IUserState => {
	const copiedEntityIds = action.payload;
	if (!copiedEntityIds) return state;
	return {
		...state,
		copiedEntityIds,
	};
};

const setGroupInversion = (
	state: IUserState,
	action: ISetGroupInversionAction
) : IUserState => {
	const {
		xInverted: groupIsXInverted,
		yInverted: groupIsYInverted,
	} = action.payload;
	return {
		...state,
		...(typeof groupIsXInverted !== 'undefined' && { groupIsXInverted }),
		...(typeof groupIsYInverted !== 'undefined' && { groupIsYInverted }),
	};
};

const setEditSceneTitle = (
	state: IUserState, 
	action: IOnEditSceneTitleAction
) : IUserState => {
	const editSceneTitle = action.payload;
	return {
		...state,
		editSceneTitle,
	};
}

const set3dMode = (
	state: IUserState,
	action: IOnSetIs3dModeAction
) : IUserState => {
	return {
		...state,
		is3dMode: action.payload
	}
}

const setSceneHas3dEntity = (
	state: IUserState,
	action: IOnSetSceneHas3dEntityAction
): IUserState => {
	return {
		...state,
		sceneHas3dEntity: action.payload
	}
}

const setTransformControlsActive = (
	state: IUserState,
	action: IOnSetIs3dModeAction
) : IUserState => {
	return {
		...state,
		transformControlsActive: action.payload
	}
}

const setTransformControlsMode = (
	state: IUserState,
	action: IOnSetTransformControlsModeAction
) : IUserState => {
	return {
		...state,
		transformControlsMode: action.payload
	}
}

const initialState: IUserState = {
	is3dMode: false,
	sceneHas3dEntity: false,
	publishUploadStatus: null,
	transformControlsMode: ITransformControlsMode.translate,
	transformControlsActive: false,
	zoomLevel: INITIAL_ZOOM_LEVEL,
	undoDisabled: true,
	redoDisabled: true,
	activeSceneId: null,
	socketIsReconnecting: false,
	projectLoadingProgress: 0,
	projectLoadingFailure: false,
	positioningIsActive: false,
	backgroundHotspotIsEnabled: false,
	rotationIsActive: false,
	scaleHotspotIsEnabled: false,
	markerIndexPressed: null,
	hotKeys: [],
	pointerOffset: [0, 0, 0],
	selectedEntityIds: [],
	selectedObject2DPosition: null,
	selectionBottomLeft2DPosition: null,
	selectionBottomRight2DPosition: null,
	selectionTopLeft2DPosition: null,
	selectionTopRight2DPosition: null,
	rotationMarker2DPosition: null,
	entityDrag2DPosition: null,
	groupIsXInverted: false,
	groupIsYInverted: false,
	copiedEntityIds: [],
	sceneEntityListIsVisible: false,
	sceneEntityListPosition: [0, 0],
	currentSceneIndex: 0,
	scrollSceneMenu: false, 
	sceneSnapshots: {},
	sceneCarouselScrollLeft: 0,
	sceneMenuIsLarge: false,
	sceneNameInputIsFocused: false,
	project: {},
	loggedInUsers: {},
	canvasLoaded: false,
	entityMenuDragActive: false,
	entityDragHotspotPosition: null,
	entityDragInfo: null,
	loadedImages: null,
	loaded3dModels: null,
	loadedVideos: null,
	loadedAudio: null,
	loadingMedia: false,
	currentUploads: {},
	numberProcessedUploads: 0, //needed to calculate overall upload process
	// displayUnit:
	// 	(localStorage.getItem(ILocalStorageKeys.displayUnit) as IUnitTypes) ||
	// 	IUnitTypes.mm,
	trackImgData: null,
	trkImgIsUploading: false,
	pureReactInCanvasHovered: false,
	toasts: [],
	editSceneTitle: false, 
};

const userReducer = (state: IUserState = initialState, action: any) => {
	switch (action.type) {
		case IUserActionTypes.SET_USER_ID:
			return setUserId(state, action);
		case IUserActionTypes.UPDATE_USERS:
			return updateUsers(state, action);
		case IUserActionTypes.SET_TRACKING_IMG_DATA:
			return setTrackingImageData(state, action);
		case IUserActionTypes.REMOVE_TRACKING_IMG_DATA:
			return removeTrackingImageData(state);
		case IUserActionTypes.SET_TRACKING_IMG_UPLOADING:
			return setTrackingImageIsUploading(state, action);
		case IUserActionTypes.CANVAS_IS_LOADED:
			return setCanvasIsLoaded(state, action);
		case IUserActionTypes.SET_LOADED_IMAGES:
			return setLoadedImages(state, action);
		case IUserActionTypes.SET_LOADED_AUDIO:
			return setLoadedAudio(state, action);
		case IUserActionTypes.ADD_TO_LOADED_MEDIA:
			return addToLoadedMedia(state, action);
		case IUserActionTypes.REMOVE_LOADED_MEDIA:
			return removeLoadedMedia(state, action);
		case IUserActionTypes.LOADING_MEDIA:
			return isLoadingMedia(state, action);
		case IUserActionTypes.REMOVE_UPLOAD:
			return removeUpload(state, action);
		case IUserActionTypes.SET_CURRENT_UPLOADS:
			return setCurrentUploads(state, action);
		case IUserActionTypes.ADD_TOAST:
			return addToast(state, action);
		case IUserActionTypes.REMOVE_TOAST:
			return removeToast(state, action);
		case IUserActionTypes.SET_PROJECT_INFO:
			return setProjectInfo(state, action);
		case IUserActionTypes.SET_HOTKEY:
			return setHotKey(state, action);
		case IUserActionTypes.SET_MARKER_INDEX_PRESSED:
			return setMarkerIndexPressed(state, action);
		case IUserActionTypes.REMOVE_HOTKEY:
			return removeHotKey(state, action);
		case IUserActionTypes.SET_POSITIONING_ACTIVE:
			return setPositioningActive(state, action);
		case IUserActionTypes.SET_ROTATION_ACTIVE:
			return setRotationActive(state, action);
		case IUserActionTypes.SET_SCALE_HOTSPOT_ENABLED:
			return setScaleHotSpotEnabled(state, action);
		case IUserActionTypes.SET_BACKGROUND_HOTSPOT_ENABLED:
			return setBackgroundHotSpotEnabled(state, action);
		case IUserActionTypes.SET_CLICK_OFFSET:
			return setPointerOffset(state, action);
		case IUserActionTypes.ADD_ENTITY_IDS_TO_SELECTION:
			return addEntityIdsToSelection(state, action);
		case IUserActionTypes.REMOVE_SELECTION:
			return removeSelection(state, action);
		case IUserActionTypes.REMOVE_LOCAL_USER_SELECTION:
			return removeLocalUserSelection(state, action);
		case IUserActionTypes.CHANGE_ZOOM_LEVEL:
			return changeZoomLevel(state, action);
		case IUserActionTypes.DISABLE_UNDO_BTN:
			return disableUndo(state, action);
		case IUserActionTypes.DISABLE_REDO_BTN:
			return disableRedo(state, action);
		case IUserActionTypes.SET_GROUP_INVERSION:
			return setGroupInversion(state, action);
		case IUserActionTypes.SET_SELECTION_2D_POSITION:
			return setSelection2DPosition(state, action);
		case IUserActionTypes.SET_SELECTION_2D_TOP_LEFT_POSITION:
			return setSelection2DTopLeftPosition(state, action);
		case IUserActionTypes.SET_SELECTION_2D_TOP_RIGHT_POSITION:
			return setSelection2DTopRightPosition(state, action);
		case IUserActionTypes.SET_SELECTION_2D_BOTTOM_LEFT_POSITION:
			return setSelection2DBottomLeftPosition(state, action);
		case IUserActionTypes.SET_SELECTION_2D_BOTTOM_RIGHT_POSITION:
			return setSelection2DBottomRightPosition(state, action);
		case IUserActionTypes.SET_ROTATION_MARKER_2D_POSITION:
			return setRotationMarker2DPosition(state, action);
		case IUserActionTypes.SET_ENTITY_MENU_DRAG_2D_POSITION:
			return setEntityMenuDrag2DPosition(state, action);
		case IUserActionTypes.SET_ENTITY_MENU_DRAG_ACTIVE:
			return setEntityMenuDragActive(state, action);
		case IUserActionTypes.SET_ENTITY_MENU_DRAG_HOTSPOT_POSITION:
			return setEntityMenuHotspotDragPosition(state, action);
		case IUserActionTypes.SET_PURE_REACT_IN_CANVAS_HOVERED:
			return setPureReactInCanvasHovered(state, action);
		case IUserActionTypes.SET_ENTITY_MENU_DRAG_INFO:
			return setEntityDragInfo(state, action);
		case IUserActionTypes.SET_AUDIO_STATUS:
			return setAudioStatus(state, action);
		case IUserActionTypes.COPY_SELECTED_ENTITIES:
			return copySelectedEntities(state, action);
		case IUserActionTypes.SET_ACTIVE_SCENE:
			return setActiveScene(state, action);
		case IUserActionTypes.SET_CURRENT_SCENE_INDEX:
			return setCurrentSceneIndex(state, action);
		case IUserActionTypes.SCROLL_SCENE_MENU: 
			return scrollSceneMenu(state, action);
		case IUserActionTypes.SET_SCENE_SNAPSHOTS:
			return setSortedSceneSnapshots(state, action);
		case IUserActionTypes.SET_SCENE_ENTITY_LIST_VISIBLE:
			return setSceneEntityListVisible(state, action);
		case IUserActionTypes.SET_SCENE_CAROUSEL_SCROLL_LEFT:
			return setSceneCarouselScrollLeft(state, action);
		case IUserActionTypes.SET_SCENE_MENU_LARGE:
			return setSceneMenuLarge(state, action);
		case IUserActionTypes.FOCUS_SCENE_NAME_INPUT:
			return focusSceneNameInput(state, action);
		case IUserActionTypes.SET_SOCKET_RECONNECTING:
			return setSocketReconnecting(state, action)
		case IUserActionTypes.SET_SOCKET_HAS_CONNECTED:
			return setSocketHasConnected(state, action)
		case IUserActionTypes.SET_PROJECT_LOADING_PROGRESS:
			return setProjectLoadingProgress(state, action);
		case IUserActionTypes.SET_PROJECT_LOADING_FAILURE:
			return setProjectLoadingFailure(state, action);
		case IUserActionTypes.EDIT_SCENE_TITLE: 
			return setEditSceneTitle(state, action);
		case IUserActionTypes.SET_SELECTED_ENTIY_IDS:
			return setSelectedEntityIds(state, action);
		case IUserActionTypes.SET_3D_MODE:
			return set3dMode(state, action)
		case IUserActionTypes.SET_SCENE_HAS_3D_ENTITY:
			return setSceneHas3dEntity(state, action);
		case IUserActionTypes.SET_TRANSFORM_CONTROLS_ACTIVE:
			return setTransformControlsActive(state, action)
		case IUserActionTypes.SET_TRANSFORM_CONTROLS_MODE:
			return setTransformControlsMode(state, action)
		case IUserActionTypes.SET_PUBLISH_UPLOAD_STATUS:
			return setPublishUploadStatus(state, action);
		default:
			return state;
	}
};

export default userReducer;
