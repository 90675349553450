export enum IDomIdSelectors {
    zapparCanvas = 'zapparCanvas',
	smSlide = 'sm-slide',
    uploadDialog = 'uploadDialog',
    root = 'root',
    undo = 'undo',
    redo = 'redo',
}

export enum IDomClassSelector {
    scenePopupMenu = 'scene-popup-menu',
}
