import React, { FunctionComponent } from 'react';
import CSS from './ViewBtnRow.scss';
import Divider from '../../../Divider/Divider';
import Switcher2d3d from './Switcher2d3d/Switcher2d3d';
import Zoom from './Zoom/Zoom';

const ViewMenu: FunctionComponent = () => {

	return (
		<div className={CSS.OuterContainer}>
			<Switcher2d3d />
			<Divider height="20px" />			
			<Zoom />			
		</div>
	);
};

export default React.memo(ViewMenu);
