// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FlexContainer--2JH2K{display:flex;flex-direction:column;align-items:center}.SceneLink--29Fk5{display:flex;flex-direction:row;align-items:center;justify-content:center;color:#4a90e2;text-decoration:none;margin-top:8px;font-size:16px;width:fit-content;cursor:pointer}.SceneLink--29Fk5>.LinkText--2D_dZ{margin-left:8px;color:inherit}.Description--2CNIP{font-size:14px;align-self:flex-start}", ""]);
// Exports
exports.locals = {
	"FlexContainer": "FlexContainer--2JH2K",
	"SceneLink": "SceneLink--29Fk5",
	"LinkText": "LinkText--2D_dZ",
	"Description": "Description--2CNIP"
};
module.exports = exports;
