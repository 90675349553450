import { BaseTokenRequestHandler } from "@openid/appauth/built/token_request_handler"
import { RedirectRequestHandler } from "@openid/appauth/built/redirect_based_handler"
import { LocalStorageBackend } from "@openid/appauth/built/storage"
import { FetchRequestor } from "@openid/appauth/built/xhr"
import { BaseOAuth, CustomQueryStringUtils } from "./base"


export class RedirectOAuth extends BaseOAuth {
  constructor(baseURL: string, clientId: string, redirectURI: string) {
    super(baseURL, clientId)
    this.redirectURI = redirectURI;

    this._authorizationHandler = new RedirectRequestHandler(
      new LocalStorageBackend(),
      new CustomQueryStringUtils()
    );
    this._tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());

    // set notifier to deliver responses
    this._authorizationHandler.setAuthorizationNotifier(this._notifier);
    // set a listener to listen for authorization responses
    this._notifier.setAuthorizationListener((request, response, error) => {
      this._log('Authorization request complete ', request, response, error);
      if (response) {
        this._request = request;
        this._code = response.code;
      }
    });
  }

}
