// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SceneSlide--2-oFf{display:flex;flex-direction:column;align-items:center;justify-content:center;margin:24px 15px 6px 15px;border-radius:5px;position:relative;user-select:none}.SceneSlideInner--JkKby{display:flex;flex-direction:column;align-items:center;justify-content:center;width:100px;height:73px}.SceneSlideInner--JkKby.HoveredBtn--Bd5zP .ButtonContainer--10nt1{background-color:#fff}.HoveredState--1Ahjt{border:solid 1px #4a90e2}.UnhoveredState--tNeP5{border:solid 1px rgba(52,75,96,.1)}.Active--ommzD{border:solid 2px #4a90e2;box-shadow:0px 0px 0px 2px #ecf6ff;-webkit-box-shadow:0px 0px 0px 2px #ecf6ff;-moz-box-shadow:0px 0px 0px 2px #ecf6ff}.Active--ommzD .SceneSlideInner--JkKby{height:71px}.HoverDiv--1KcnW{height:100%;width:100%;background-color:rgba(115,180,255,.2);position:absolute;z-index:1;user-select:none;border-radius:3px}.ButtonContainer--10nt1{position:absolute;right:3px;top:3px;border-radius:100%;height:26px;width:26px;padding-top:5px;padding-left:11px;z-index:2}.SceneImage--1FFpN{background-repeat:no-repeat;background-size:cover;background-position:center;width:93%;height:95%}.SceneNameDiv--1Z-PT,.SceneSequenceDiv--3xUgY{position:absolute;top:calc(100% + 10px);width:100px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;text-align:center}.SceneSequenceDiv--3xUgY{top:calc(100% + 28px)}.Icon--2FqPK{pointer-events:none}.SceneContainer--8lxnd{max-width:130px}.SceneContainer--8lxnd .SceneName--167Ro{margin:0 24px;text-align:center;display:flex}.SceneContainer--8lxnd .SceneName--167Ro input{height:23px;text-align:center}.SceneContainer--8lxnd .SceneName--167Ro span{width:100%;padding:3px 5px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}", ""]);
// Exports
exports.locals = {
	"SceneSlide": "SceneSlide--2-oFf",
	"SceneSlideInner": "SceneSlideInner--JkKby",
	"HoveredBtn": "HoveredBtn--Bd5zP",
	"ButtonContainer": "ButtonContainer--10nt1",
	"HoveredState": "HoveredState--1Ahjt",
	"UnhoveredState": "UnhoveredState--tNeP5",
	"Active": "Active--ommzD",
	"HoverDiv": "HoverDiv--1KcnW",
	"SceneImage": "SceneImage--1FFpN",
	"SceneNameDiv": "SceneNameDiv--1Z-PT",
	"SceneSequenceDiv": "SceneSequenceDiv--3xUgY",
	"Icon": "Icon--2FqPK",
	"SceneContainer": "SceneContainer--8lxnd",
	"SceneName": "SceneName--167Ro"
};
module.exports = exports;
