import React, { Fragment, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getContentEntityDict, getEditorTargetHeightInMm, getSelectedEditorScales, getEditorAspRatioLockedDict, getEditorTargetDisplayUnits } from "../../../../../../../store/selectors";
import { IDesignerState } from "../../../../../../../../typings";
import { onSetMultipleEntityProps_Ed_Doc, onSetScales_Cn_Doc, IMultipleEntityProps_Ed_Doc } from "../../../../../../../store/actions";
import { IUnitTypes, ISpatialComponentUnion, IComponentType } from "../../../../../../r3f/r3f-components/component-data-structure";
import { convMmToUnit } from "../../../../../../../utils";
import TransformEntityLock from '../TransformEntityLock/TransformsEntityLock';
import { NumericalInput } from "../../../../../Inputs";
import Divider from '../../../../../Divider/Divider';
import { IInputPosition } from '../../../../../Inputs/Numerical/Numerical';
import CSS from './TransformsSize.scss'

interface IParentProps {
  entityIsLocked: boolean;
  entitiesHaveSame: { [key: string]: boolean[] };
  debug?: boolean;
}

const TransformsSize = ({
  entityIsLocked,
  entitiesHaveSame,
  debug = false
}: IParentProps) => {
  // Redux 
  const dispatch = useDispatch();
  const is3dMode = useSelector((state: IDesignerState) => state.userReducer.is3dMode);
  const targetDisplayUnits = useSelector((state: IDesignerState) => getEditorTargetDisplayUnits(state));
  const targetHeightInMm = useSelector((state: IDesignerState) => getEditorTargetHeightInMm(state));
  const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
  const entityContentDict = useSelector((state: IDesignerState) => getContentEntityDict(state));
  const editorScaleDict = useSelector((state: IDesignerState) => getSelectedEditorScales(state));
  const editorAspRatioIsLockedDict = useSelector((state: IDesignerState) => getEditorAspRatioLockedDict(state));
  
  // Derived
  const unit = targetDisplayUnits || IUnitTypes.cm;
  const unitIsMm = unit === IUnitTypes.mm;
  const unitIsCoords = unit === IUnitTypes.coords;
  const multiplier = convMmToUnit(unit === IUnitTypes.coords ? 1 : targetHeightInMm, unit) / 2;
  const id = selectedEntityIds[0];
  const entity = entityContentDict[id] as ISpatialComponentUnion;
  const scale = editorScaleDict[id] || entity.scale;
  const numberOfAspectRatiosLocked = useMemo(() => {
    return selectedEntityIds.reduce((acc, id) => {
      return editorAspRatioIsLockedDict[id] ? acc + 1 : acc;
    }, 0);
  }, [selectedEntityIds, editorAspRatioIsLockedDict]);
  
  // Helpers
  const getInputPosition = (index: number) => {
    switch (index) {
      case 0:
        return IInputPosition.first;
      case 1:
        return IInputPosition.center;
      default:
        return IInputPosition.last;
    }
  }

  const updateSize = (v: number, index: number) => {
    if (!v) return; // return if falsy (incl. 0 values)
    const value = v / multiplier; // divide by multiplier to get coords

    let scaleDict: { [id: string]: number[] } = {};
    let multipleEntityProps_Ed_Doc: IMultipleEntityProps_Ed_Doc[] = [];

    for (let i = 0; i < selectedEntityIds.length; i++) {
      const id = selectedEntityIds[i];
      const entity = entityContentDict[id] as ISpatialComponentUnion;
      let newScale = [...entity.scale];
      newScale[index] = value;

      if (numberOfAspectRatiosLocked !== 0) { // Resize all scale values if any aspect ratio is locked
        const XYratio = entity.scale[0] / entity.scale[1];
        const XZratio = entity.scale[0] / entity.scale[2];
        const YXratio = entity.scale[1] / entity.scale[0];
        const YZratio = entity.scale[1] / entity.scale[2];
        const ZXratio = entity.scale[2] / entity.scale[0];
        const ZYratio = entity.scale[2] / entity.scale[1];
        
        switch (index) {
          case 0:
            newScale[1] = value / XYratio;
            newScale[2] = value / XZratio;
            break;
          case 1:
            newScale[0] = value / YXratio;
            newScale[2] = value / YZratio;
            break;
          case 2:
            newScale[0] = value / ZXratio;
            newScale[1] = value / ZYratio;
          default:
            break;
        }
      }

      scaleDict[id] = newScale;
      multipleEntityProps_Ed_Doc.push({
        id,
        scalesInverted: [newScale[0] < 0, newScale[1] < 0, newScale[2] < 0],
      });
    }
    dispatch(onSetMultipleEntityProps_Ed_Doc(multipleEntityProps_Ed_Doc));
    dispatch(onSetScales_Cn_Doc(scaleDict));
  }

  const labelColor = (axis: "X" | "Y" | "Z") => {
    switch (axis) {
      case 'X':
        return numberOfAspectRatiosLocked === 0 ? "red" : "grey";
      case 'Y':
        return numberOfAspectRatiosLocked === 0 ? "green" : "grey";
      case 'Z':
        return numberOfAspectRatiosLocked === 0 ? "blue" : "grey";
    }
  }

  // Content
  const scaleInputOptions = is3dMode
    ? [{ text: 'W', color: labelColor('X') }, { text: 'H', color: labelColor('Y') }, { text: 'D', color: labelColor('Z') }]
    : [{ text: 'W', color: labelColor('X') }, { text: 'H', color: labelColor('Y') }];
	const scaleRowContent = scaleInputOptions.map(
		(label, index) => {
			const multitpleEntitiesWithDiffScales = entitiesHaveSame && !entitiesHaveSame.scale[index];
      const inputPosition = getInputPosition(index);
      const is2dZAxis = (entity.type !== IComponentType.Model3d && label.text === 'D');
			return (
				<Fragment key={index}>
					<NumericalInput
						decimals={unitIsMm ? 0 : 2}
						step={(unitIsMm || unitIsCoords) ? 1 : 0.1}
						position={inputPosition}
						className={CSS.TransformInput}
						placeHolder={'multi'}
            labelText={label.text}
            labelColor={label.color}
						disabled={entityIsLocked || is2dZAxis}
						adjustmentFactor={multitpleEntitiesWithDiffScales
											? undefined
											: scale[index] < 0
												? -1
												: 1}
						value={multitpleEntitiesWithDiffScales ? undefined : multiplier * scale[index]} // multiplied to convert coords to abs values
						onChange={(v) => updateSize(v, index)}
					/>
					{index < (is3dMode ? 2 : 1) && <Divider height={'23px'} />}
				</Fragment>
			);
		}
	);
  
  return (
    <>
    <div className={CSS.RowTitle}>
        Size ({unit})
        <TransformEntityLock />
    </div>
    <div className={CSS.InputRow}>
      {scaleRowContent}
    </div>
    </>
  )
}

export default React.memo(TransformsSize);