import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../../../../typings';
import { onSetMultipleEntityProps_Ed_Doc, onSetMultipleComponentProps_Cn_Doc, IMultipleEntityProps_Cn_Doc, IMultipleEntityProps_Ed_Doc } from '../../../../../../../../store/actions';
import { IColorType } from '../BtnAppearance';
import { ITuple4, IButton, ISpatialComponentUnion } from '../../../../../../../r3f/r3f-components/component-data-structure';
import ColorPickerMenu from '../../../../../ColorPickerMenu/ColorPickerMenu';

interface IParentProps {
	activeColorType: IColorType;
	show: boolean;
	targetRef: React.MutableRefObject<undefined>;
	onClose: () => any;
}

const FillBorderColPicker: React.FunctionComponent<IParentProps> = ({
	show,
	onClose,
	targetRef,
	activeColorType,
}) => {
	// Redux
	const dispatch = useDispatch();
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const id = selectedEntityIds[0];
	const entityContentDict = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const editorColor = useSelector((state: IDesignerState) => {
		if (!state.editorReducer.editorDoc) return undefined;
		return state.editorReducer.editorDoc.rgbaColors[id] as ITuple4;
	});
	const editorBorderRgba = useSelector((state: IDesignerState) => {
		if (!state.editorReducer.editorDoc) return undefined;
		if (!state.editorReducer.editorDoc.borderRgba) return undefined;
		return state.editorReducer.editorDoc.borderRgba[id] as ITuple4;
	})

	if (selectedEntityIds.length === 0) return null;
	const { color, borderRgba = null } = entityContentDict[
		selectedEntityIds[0]
	] as ISpatialComponentUnion & IButton;
	const entityRgba = (editorColor || color) as ITuple4;
	const entityBorderRgba = (editorBorderRgba ||
		borderRgba || [0, 0, 0, 1]) as ITuple4;

	return (
		<ColorPickerMenu
			show={show}
			rgba={
				activeColorType === IColorType.fillColor ? entityRgba : entityBorderRgba
			}
			targetElRef={targetRef}
			onClose={onClose}
			onChange={([r, g, b, a]) => {
				const multipleEntityProps_Ed_Doc: IMultipleEntityProps_Ed_Doc[] = [];
				for (let i = 0; i < selectedEntityIds.length; i++) {
					if (activeColorType === IColorType.fillColor) {
						multipleEntityProps_Ed_Doc[i] = {
							id: selectedEntityIds[i],
							rgbaColors: [r, g, b, a / 100],
						};
					} else {
						multipleEntityProps_Ed_Doc[i] = {
							id: selectedEntityIds[i],
							borderRgba: [r, g, b, a / 100],
						};
					}
				}
				dispatch(onSetMultipleEntityProps_Ed_Doc(multipleEntityProps_Ed_Doc));
			}}
			onSubmit={([r, g, b, a]) => {
				const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
				const multipleEntityProps_Ed_Doc: IMultipleEntityProps_Ed_Doc[] = [];
				for (let i = 0; i < selectedEntityIds.length; i++) {
					if (activeColorType === IColorType.fillColor) {
						multipleEntityProps_Cn_Doc[i] = {
							id: selectedEntityIds[i],
							color: [r, g, b, a / 100],
						};
						multipleEntityProps_Ed_Doc[i] = {
							id: selectedEntityIds[i],
							rgbaColors: null,
						};
					} else {
						multipleEntityProps_Cn_Doc[i] = {
							id: selectedEntityIds[i],
							borderRgba: [r, g, b, a / 100],
						};
						multipleEntityProps_Ed_Doc[i] = {
							id: selectedEntityIds[i],
							borderRgba: null,
						};
					}
				}
				dispatch(onSetMultipleEntityProps_Ed_Doc(multipleEntityProps_Ed_Doc));
				dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
			}}
		/>
	);
};

export default React.memo(FillBorderColPicker);
