import { TokenResponse, StringMap, LocationLike } from "@openid/appauth"
import { BasicQueryStringUtils } from '@openid/appauth/built/query_string_utils'
import { TokenRequest, GRANT_TYPE_AUTHORIZATION_CODE, GRANT_TYPE_REFRESH_TOKEN } from "@openid/appauth/built/token_request"
import { AuthorizationRequest } from "@openid/appauth/built/authorization_request"
import { AuthorizationNotifier, AuthorizationRequestHandler } from "@openid/appauth/built/authorization_request_handler"
import { TokenRequestHandler } from "@openid/appauth/built/token_request_handler"
import { AuthorizationServiceConfiguration } from "@openid/appauth/built/authorization_service_configuration"
import { NodeCrypto } from '@openid/appauth/built/node_support/crypto_utils'


export class CustomQueryStringUtils extends BasicQueryStringUtils {
  parse(input: LocationLike, useHash?: boolean) { // ignore hash
    return this.parseQueryString(input.search);
  }
}

export class BaseOAuth {
  protected _notifier: AuthorizationNotifier;
  protected _authorizationHandler: AuthorizationRequestHandler | undefined;
  protected _tokenHandler: TokenRequestHandler | undefined;

  // state
  protected _serviceConfig: AuthorizationServiceConfiguration;
  protected _request: AuthorizationRequest|undefined;
  protected _code: string|undefined;
  protected _tokenResponse: TokenResponse|undefined;

  public debug = false;
  public clientId = '';
  public redirectURI = '';

  constructor(baseURL: string, clientId: string) {
    this.clientId = clientId;
    this._serviceConfig = this.setServiceConfig(baseURL);
    this._notifier = new AuthorizationNotifier();
  }

  setServiceConfig(baseURL: string) {
    this._serviceConfig = new AuthorizationServiceConfiguration({
      authorization_endpoint: `${baseURL}/oauth2/authorize/`,
      token_endpoint: `${baseURL}/oauth2/token/`,
      revocation_endpoint: `${baseURL}/oauth2/token/refresh/`,
      userinfo_endpoint: `${baseURL}/user/`
    })
    return this._serviceConfig
  }

  getCrypto(): NodeCrypto | undefined {
    return undefined
  }

  makeAuthorizationRequest(scope = '', state?: string) {
    const request = new AuthorizationRequest({
      client_id: this.clientId,
      redirect_uri: this.redirectURI,
      scope: scope,
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
      state: state
    }, this.getCrypto());

    this._authorizationHandler?.performAuthorizationRequest(this._serviceConfig, request);
  }

  makeTokenRequest() {
    let request: TokenRequest|null = null;

    if (this._code) {
      let extras: StringMap|undefined = undefined;
      if (this._request && this._request.internal) {
        extras = {};
        extras['code_verifier'] = this._request.internal['code_verifier'];
      }
      // use the code to make the token request.
      request = new TokenRequest({
        client_id: this.clientId,
        redirect_uri: this.redirectURI,
        grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
        code: this._code,
        refresh_token: undefined,
        extras: extras
      });
    } else if (this._tokenResponse) {
      // use the refresh token to make a request for an access token
      request = new TokenRequest({
        client_id: this.clientId,
        redirect_uri: this.redirectURI,
        grant_type: GRANT_TYPE_REFRESH_TOKEN,
        code: undefined,
        refresh_token: this._tokenResponse.refreshToken,
        extras: undefined
      });
    }

    return new Promise<TokenResponse | undefined>((resolve, reject) => {
      if (request) {
        this._tokenHandler?.performTokenRequest(this._serviceConfig, request).then(response => {
          if (this._tokenResponse) {
            // copy over new fields
            this._tokenResponse.accessToken = response.accessToken;
            this._tokenResponse.issuedAt = response.issuedAt;
            this._tokenResponse.expiresIn = response.expiresIn;
            this._tokenResponse.tokenType = response.tokenType;
            this._tokenResponse.scope = response.scope;
          } else {
            this._tokenResponse = response;
          }
  
          // unset code, so we can do refresh token exchanges subsequently
          this._code = undefined;
          resolve(response)
        })
        .catch(error => {
          this._log('Something bad happened', error);
          reject()
        });
      } else {
        resolve(undefined)
      }
    })
  }

  getAccessToken() {
    return this._tokenResponse?.accessToken
  }

  checkForAuthorizationResponse() {
    return this._authorizationHandler?.completeAuthorizationRequestIfPossible();
  }

  waitForAccessToken() { // Used in NodeJS
    return new Promise<string | undefined>( resolve => resolve(this.getAccessToken()) )
  }

  protected _log(msg: string, ...args: any[]) {
    if (!this.debug) return;
    if (args.length > 0) {
      console.log(msg, ...args);
    } else {
      console.log(msg);
    }
  }

}

