import React from 'react';
import CSS from './Summary.scss';

interface IParentProps {
	tel: string;
}

const DialNumberSummary: React.SFC<IParentProps> = ({ tel }) => {
	return (
		<div className={CSS.FlexContainer}>
			<span className={CSS.Description}>Linked to following phone number:</span>
			<div className={CSS.LinkContainerDiv}>
				<a className={CSS.PhoneLink} href={`tel:${tel}`}>
					{tel}
				</a>
				<div className={CSS.GradientDiv} />
			</div>
		</div>
	);
};

export default DialNumberSummary;
