import React from 'react';

import CSS from './DangerBtn.scss';

interface IParentProps {
	children?: any;
	className?: string;
	onClick?: (e: React.MouseEvent) => any;
	style?: any;
	disabled?: boolean;
}

const DangerButton = ({
	children,
	onClick,
	className,
	style,
	disabled = false,
}: IParentProps) => {
	let classes = [CSS.ActionBtn, className ? className : ''];
	return (
		<button
			style={{ ...style }}
			className={classes.join(' ')}
			disabled={disabled}
			onClick={e => onClick(e)}
		>
			{children}
		</button>
	);
};

export default DangerButton;
