import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../../../typings';
import { onSetActionData_Global, } from '../../../../../../../store/actions';
import { ITriggerTypes, 	IActionCategory, 	IRoot, } from '../../../../../../r3f/r3f-components/component-data-structure';
import { IMenuItemType } from '../../../../MenuList/MenuList';
import { SceneIcon } from '../../../../../../../assets/icons';
import SceneSummary from './Summary/Summary';
import Dropdown from '../../../../../menus/Dropdown/SelectDropdown/SelectDropdown';
import CSS from './SceneLink.scss';

interface IParentProps {
	triggerType: ITriggerTypes;
	setShowActionSummary: (value: React.SetStateAction<boolean>) => void;
	showActionSummary: boolean;
}

interface IDropdownOptions {
	[key: string]: {
		text: string;
		val: string;
		type?: IMenuItemType
	}
}

const initialOptions: IDropdownOptions = {
	default: {
		text: 'Select a scene...', 
		val: 'default',
		type: IMenuItemType.disabled,
	}, 
	noScene: {
		text: 'No scene to link to', 
		val: 'noScene',
		type: IMenuItemType.disabled, 
	}
}

const SceneLink: FunctionComponent<IParentProps> = ({
	triggerType,
	setShowActionSummary,
	showActionSummary,
}) => {
	const dispatch = useDispatch();
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const id = selectedEntityIds[0];
	const snapshotDict = useSelector((state: IDesignerState) => state.userReducer.sceneSnapshots);
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId);
	const actionsDict = useSelector((state: IDesignerState) => state.editorReducer.editorDoc?.actions?.[id] || {});
	const activeTrigger = useSelector((state: IDesignerState) => state.editorReducer.editorDoc.activeTriggers?.[id] || ITriggerTypes.onLoad);
	const componentDict = useSelector((state: IDesignerState) => state.contentReducer?.contentDoc?.componentsById || null);
	const rootId = useSelector((state: IDesignerState) => state.contentReducer?.contentDoc?.rootComponentId || 'rootId');
	const linkedSceneId = actionsDict?.[activeTrigger]?.linkedSceneId || null;
	const rootChildren = (componentDict?.[rootId] as IRoot).children || null;
	const editorTitlesDict = useSelector((state: IDesignerState) => {
		return state.editorReducer.editorDoc && state.editorReducer.editorDoc.titles
			? state.editorReducer.editorDoc.titles
			: {};
	});

	const initialOption = (rootChildren.length > 1) ? initialOptions.default : initialOptions.noScene;
	const sceneOptions = [initialOption];

	for (let i = 0; i < rootChildren.length; i++) {
		const sceneId = rootChildren[i];
		if (sceneId === activeSceneId) continue;
		sceneOptions.push(
			{
				text: editorTitlesDict[sceneId], 
				val: sceneId,
			}
		);
	}

	const onChangeHandler = (val: string) => {
		dispatch(onSetActionData_Global({
			ids: selectedEntityIds,
			triggerType: triggerType,
			selectedActionCategory: IActionCategory.linkScene,
			linkedSceneId: val,
		}));
		setShowActionSummary(true);
	};

	return (
		<>
			{!showActionSummary ? 
				<div
					className={CSS.Dropdown}
				>
					<Dropdown
						value={initialOption.val}
						onChange={onChangeHandler}
					>
						{sceneOptions}
					</Dropdown>	
				</div> :
				<SceneSummary
					selectedEntityIds={selectedEntityIds}
					selectedSceneId={linkedSceneId}
					selectedSceneName={editorTitlesDict[linkedSceneId]}
				/>
			}
			<div className={CSS.ScenePreviewDiv}>
				{snapshotDict[linkedSceneId] && showActionSummary ?
					<img src={snapshotDict[linkedSceneId]} /> : 
					<>
						<SceneIcon hexFill={'#4A90E2'} />
						{ !linkedSceneId ? 
							<span>(no scene linked)</span> : 
							null
						}
					</>
				}
			</div>
		</>
	)
};

export default React.memo(SceneLink);
