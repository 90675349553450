import React from 'react';
import { ITuple3 } from '../../Colorpicker';
import {NumericalInput} from '../../../Inputs'
import {IInputPosition} from '../../../Inputs/Numerical/Numerical'

import './RgbInputs_255.scss';
import CSS from './RgbInputs_255.scss';

interface IParentProps {
	rgb: ITuple3;
	updateColorValues: (newRgb: ITuple3) => void;
}

const Rgb255Inputs: React.SFC<IParentProps> = ({ rgb, updateColorValues }) => {
	return (
		<div className={'zcp-flex-row'}>
			<span className={'zcp-desc'}>RGB</span>
			<NumericalInput
				value={rgb[0]}
				min={1}
				max={255}
				decimals={0}
				labelText='R'
				step={1}
				position={IInputPosition.first}
				onChange={e => {
					if (!e) return;
					const val = e;
					if (val > 255) return;
					updateColorValues([val, rgb[1], rgb[2]]);
				}}
			/>
			<div className={CSS.ColorPickerInputSeparator}></div>
			<NumericalInput
				value={rgb[1]}
				min={1}
				max={255}
				decimals={0}
				labelText='G'
				step={1}
				position={IInputPosition.center}
				onChange={e => {
					if (!e) return;
					const val = e;
					if (val > 255) return;
					updateColorValues([rgb[0], val, rgb[2]]);
				}}
			/>
			<div className={CSS.ColorPickerInputSeparator}></div>
			<NumericalInput
				value={rgb[2]}
				min={1}
				max={255}
				decimals={0}
				labelText='B'
				step={1}
				position={IInputPosition.last}
				onChange={e => {
					if (!e) return;
					const val = e;
					if (val > 255) return;
					updateColorValues([rgb[0], rgb[1], val]);
				}}
			/>
		</div>
	);
};

export default Rgb255Inputs;
