import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IDesignerState } from '../../../../../../typings';
import uuid4 from 'uuid/v4';

import { bindActionCreators } from 'redux';

import {
	IOnRemoveSelection,
	onRemoveSelection,
	onSetActiveScene,
	IOnSetActiveScene,
	onAddScenes_Global,
	IOnAddScenes_Global,
	onSetCurrentSceneIndex,
	IOnSetCurrentSceneIndex,
	IOnSetSceneCarouselScrollLeft,
	onSetSceneCarouselScrollLeft,
	onSetSceneSnapshots,
	IOnSetSceneSnapshots,
} from '../../../../../store/actions';

import { PlusIcon } from '../../../../../assets/icons/index';

// import HoverSlideCover from './HoverSlideCover/HoverSlideCover';
import SceneSlide from './SceneSlide/SceneSlide';

import CSS from './SceneCarousel.scss';
import {
	getRootComponent,
	getRootComponentId,
	getEditorTitlesDict,
} from '../../../../../store/selectors';
import {
	getSnapshotDictFromLStorage,
	updateLStorageWithSnapshot,
} from '../../../../../utils';
interface IParentProps {
	show: boolean;
}

interface IReduxProps {
	selectedEntityIds: string[];
	activeSceneId: string;
	editorTitlesDict: { [id: string]: string };
	snapshotDict: { [id: string]: string };
	currentSceneIndex: number;
	projectId: string;
	rootChildren: string[];
	rootId: string;
	scrollSceneMenu: boolean; 
}

interface IDispatchProps {
	onRemoveSelection: IOnRemoveSelection;
	onSetActiveScene: IOnSetActiveScene;
	onAddScenes_Global: IOnAddScenes_Global;
	onSetCurrentSceneIndex: IOnSetCurrentSceneIndex;
	onSetSceneCarouselScrollLeft: IOnSetSceneCarouselScrollLeft;
	onSetSceneSnapshots: IOnSetSceneSnapshots;
}

const SceneCarousel: React.SFC<IParentProps & IReduxProps & IDispatchProps> = ({
	onRemoveSelection,
	onSetActiveScene,
	selectedEntityIds,
	editorTitlesDict,
	currentSceneIndex,
	onAddScenes_Global,
	show,
	projectId,
	onSetCurrentSceneIndex,
	onSetSceneCarouselScrollLeft,
	snapshotDict,
	onSetSceneSnapshots,
	rootChildren,
	rootId,
	scrollSceneMenu, 
}) => {
	if (!show) return null;
	const carouselRef = useRef<HTMLDivElement>(null);
	const [hoverDummyScene, setHoverDummyScene] = useState<boolean>();

	useEffect(() => {
		carouselRef.current.scrollLeft = currentSceneIndex * 130;
	}, [])

	useEffect(() => {
		if(scrollSceneMenu){
			carouselRef.current.scroll({
				left: currentSceneIndex * 130 + currentSceneIndex * 2,
				behavior: 'smooth',
			});
		}
	}, [currentSceneIndex]);


	const addSceneAtEndClickHandler = () => {
		const sceneId = uuid4();
		onRemoveSelection(selectedEntityIds);
		onSetCurrentSceneIndex(rootChildren.length);
		onAddScenes_Global({
			sceneIds: rootChildren,
			newSceneIds: [sceneId],
			rootId,
			index: rootChildren.length,
		});
		onSetActiveScene({ activeSceneId: sceneId });
		setTimeout(() => {
			updateLStorageWithSnapshot(sceneId, projectId);
			onSetSceneSnapshots(getSnapshotDictFromLStorage(localStorage, projectId));
		}, 0);
	};

	let sceneImageBtns: React.ReactElement[] = [];
	for (let i = 0; i < rootChildren.length; i++) {
		const sceneId = rootChildren[i];

		sceneImageBtns.push(
			<SceneSlide
				key={sceneId}
				sceneId={sceneId}
				dataUrl={snapshotDict[sceneId]}
				index={i}
				sceneName={editorTitlesDict[sceneId]}
			/>
		);
		// add slide placeholder at end of slide row
		if (i !== rootChildren.length - 1) continue;
		sceneImageBtns.push(
			<div 
				className={CSS.SceneContainer}
				key={'addSlide'}
			>
				<div
					onClick={() => addSceneAtEndClickHandler()}
					onPointerEnter={() => setHoverDummyScene(true)}
					onPointerLeave={() => setHoverDummyScene(false)}
					className={`${CSS.DummyAddSlide} ${
						hoverDummyScene ? CSS.HoveredState : ''}`}
					title={"Add scene"}
				>
					<PlusIcon title={"Add scene"}/>
				
				{hoverDummyScene && (	
					<div className={CSS.HoverDiv}>	
					</div>
				)}
				</div>
			</div>
		);
	}
	
	return (		
		<div
			className={CSS.CarouselContainer}
			ref={carouselRef}
			onScroll={() => {
				onSetSceneCarouselScrollLeft(carouselRef.current.scrollLeft);
			}}
		>
			
			<div 
				className={CSS.SceneFlexContainer} 
			>
				{sceneImageBtns}
			</div>
		</div>
	);
};

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	return {
		selectedEntityIds: state.userReducer.selectedEntityIds,
		activeSceneId: state.userReducer.activeSceneId,
		currentSceneIndex: state.userReducer.currentSceneIndex,
		snapshotDict: state.userReducer.sceneSnapshots,
		projectId: state.userReducer.project.id,
		rootChildren: getRootComponent(state)?.children || [],
		editorTitlesDict: getEditorTitlesDict(state),
		rootId: getRootComponentId(state) || 'rootId',
		scrollSceneMenu: state.userReducer.scrollSceneMenu, 
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onRemoveSelection,
			onSetActiveScene,
			onAddScenes_Global,
			onSetCurrentSceneIndex,
			onSetSceneCarouselScrollLeft,
			onSetSceneSnapshots,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(SceneCarousel);
