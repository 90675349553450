import {
	ITuple3,
	IFontTypes,
	IUnitTypes,
	IProjectColor,
} from '../components/r3f/r3f-components/component-data-structure';
import uuid4 from 'uuid/v4';
import { IMenuItem } from '../components/dom/menus/MenuList/MenuList';
import { IUploadFileTypes } from '../components/dom/upload/specs';

export const FONT_PT_TO_MM_HEIGHT = 0.352778;
export const HOTSPOT_SCALE: ITuple3 = [100, 100, 100];
export const DEFAULT_ICON_COLOR: string = '#344B60';
export const WHITE_ICON_COLOR: string = '#FFFFFF';
export const ICON_INACTIVE_COLOR = '#B4BBC3';
export const ICON_ACTIVE_BLUE_COLOR = '#4A90E2';
export const INITIAL_ZOOM_LEVEL: number = 150;
export const MIN_ZOOM_LIMIT = INITIAL_ZOOM_LEVEL / 5;
export const MAX_ZOOM_LIMIT = INITIAL_ZOOM_LEVEL * 2;
export const ORTHOGRAPHIC_ZOOM_TO_PERSPECTIVE_POSITION = 6.145; 
export const ROTATION_MARKER_HEIGHT = 32; //px
export const ROTATION_MARKER_WIDTH = 32; //px
export const SELECTION_MARKER_HEIGHT = 0.1;
export const SELECTION_MARKER_WIDTH = 0.1;
export const MINIMUM_SCALE_WIDTH_HEIGHT = 0.5;
export const CANVAS_PADDING_TOP = 30;
export const CANVAS_PADDING_RIGHT = 30;
export const CANVAS_PADDING_BOTTOM = 70;
export const CANVAS_PADDING_LEFT = 70;
export const CONTEXT_MENU_OFFSET_X = 20;
export const CONTEXT_MENU_OFFSET_Y = 20;
export const MENU_MARGIN = 5;
export const MENU_PADDING = 20;
export const ENTITY_PREVIEW_WIDTH = 250;
export const ENTITY_PREVIEW_HEIGHT = 170;
export const ENTITY_MENU_WIDTH = 60;
export const DEFAULT_SCENE_NAME = 'Scene';
export const NUM_OF_ENTITIES_DISPLAYED = 8; 
export const SNAPSHOT_PX_HEIGHT = 100;
export const SNAPSHOT_TTL_MS = 1000 * 60 * 60 * 24 * 7; // 7 days in ms


export const ALLOWED_UPLOAD_IMAGE_TYPES = [
	IUploadFileTypes.image_png,
	IUploadFileTypes.image_jpeg,
	IUploadFileTypes.image_ext_fallback
]

export const ALLOWED_UPLOAD_VIDEO_TYPES = [IUploadFileTypes.video_all, IUploadFileTypes.video_ext_fallback]

export const ALLOWED_UPLOAD_MODEL_TYPES = [IUploadFileTypes.model_gltf, IUploadFileTypes.model_glb, IUploadFileTypes.model_glb_ext, IUploadFileTypes.model_gltf_ext]

export const FONT_STYLES = [
	{
		desc: 'Add a heading',
		fontSize: 36,
		troikaSize: 0.21,
		fontRgba: [52, 75, 96, 1],
		scale: [0.34 * 2, 0.06 * 2, 0],
	},
	{
		desc: 'Add a subheading',
		fontSize: 28,
		troikaSize: 0.15,
		fontRgba: [102, 114, 125, 1],
		scale: [0.30 * 2, 0.05 * 2, 0],
	},
	{
		desc: 'Add a paragraph',
		fontSize: 18,
		troikaSize: 0.1,
		fontRgba: [124, 137, 150, 1],
		scale: [0.19 * 2, 0.03 * 2, 0]
	},
];

export const METRIC_UNIT_MENU_OPTIONS: IMenuItem[] = [
	{ text: 'millimetres', val: IUnitTypes.mm },
	{ text: 'centimetres', val: IUnitTypes.cm },
	// { text: 'metres', val: IUnitTypes.m },
	{ text: 'inches', val: IUnitTypes.inch },
	{ text: 'feet', val: IUnitTypes.ft },
	{text: 'coordinates', val: IUnitTypes.coords},
];

export const BASIC_COLORS: IProjectColor[] = [
	{ id: uuid4(), hex: '#57A6FF', rgba: [87, 166, 255, 100] },
	{ id: uuid4(), hex: '#0CD185', rgba: [12, 209, 133, 100] },
	{ id: uuid4(), hex: '#77D353', rgba: [119, 211, 83, 100] },
	{ id: uuid4(), hex: '#FFBA5C', rgba: [255, 186, 92, 100] },
	{ id: uuid4(), hex: '#B8977E', rgba: [184, 151, 126, 100] },
	{ id: uuid4(), hex: '#FF9052', rgba: [255, 144, 82, 100] },
	{ id: uuid4(), hex: '#F95F62', rgba: [249, 95, 98, 100] },
	{ id: uuid4(), hex: '#F77FB3', rgba: [247, 127, 179, 100] },
	{ id: uuid4(), hex: '#976DD0', rgba: [151, 109, 208, 100] },
	{ id: uuid4(), hex: '#343F4B', rgba: [52, 63, 75, 100] },
	{ id: uuid4(), hex: '#5A6978', rgba: [90, 105, 120, 100] },
	{ id: uuid4(), hex: '#969FAA', rgba: [150, 159, 170, 100] },
	{ id: uuid4(), hex: '#C6CBD0', rgba: [198, 203, 208, 100] },
	{ id: uuid4(), hex: '#FFFFFF', rgba: [255, 255, 255, 100] },
];