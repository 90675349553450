import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../../../typings';
import { CrossIcon, HelpIcon } from '../../../../../../../assets/icons';
import { useTimeOutOnMount } from '../../../../../../../utils';


import CSS from './SubmenuHeader.scss';

interface IParentProps {
    title: string;
	onClose: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => any;
	tooltipHtml?: string;
	helpUrl?: string;
}

const SubmenuHeader: React.FunctionComponent<IParentProps> = ({
	title,
	onClose,
	tooltipHtml,
	helpUrl
}) => {
	const [classes, setClasses] = useState<string[]>([CSS.HeaderContainer]);
	useTimeOutOnMount(
		() => setClasses(prevState => [...prevState, CSS.FadeIn]),
		50
	);
	const [hexFill, setHexFill] = useState<string>()

	return (
		<div className={classes.join(' ')}>
			<div className={CSS.FlexRow}>
				{title}
				{!!tooltipHtml ? <a href={helpUrl} target="_blank" rel="noreferrer"><HelpIcon className={CSS.HelpIcon} hexFill='#b2c4d7' /></a> : null}
			</div>
			<CrossIcon 
				className={CSS.Cross} 
				hexFill={hexFill}
				onClick={onClose} 
				onPointerEnter={() => setHexFill('#4a90e2')}
				onPointerLeave={() => setHexFill(undefined)}
			/>
		</div>
	);
};

export default memo(SubmenuHeader);