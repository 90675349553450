import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { onSetMultipleEntityProps_Ed_Doc } from "../../../../../../../store/actions";
import { IMultipleEntityProps_Ed_Doc } from "../../../../../../../store/actions";
import { IDesignerState } from "../../../../../../../../typings";
import { AspectRatioLockedIcon, AspectRatioUnlockedIcon } from "../../../../../../../assets/icons";
import { ICON_ACTIVE_BLUE_COLOR, DEFAULT_ICON_COLOR } from "../../../../../../../utils";
import CSS from './TransformsEntityLock.scss'

const TransformEntityLock = () => {
	const dispatch = useDispatch();
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const aspectRatioLockedIds = useSelector((state: IDesignerState) => state.editorReducer.editorDoc.aspectRatioLocked);

	// If ANY of a selection is locked, display locked ( and behaviour should match accordingly )
	const locked = useMemo(() => {
		return selectedEntityIds.reduce((acc, selectedEntityId) => {
			if (!acc) { // If ANY is locked, don't lose the true value
				return Object.keys(aspectRatioLockedIds).reduce((acc, aspectRatioLockedId) => {
					return (selectedEntityId === aspectRatioLockedId && aspectRatioLockedIds[aspectRatioLockedId]) ? true : acc;
				}, false);
			} else {
				return acc;
			}
		}, false);
	}, [aspectRatioLockedIds, selectedEntityIds]);
	// console.log(locked);
	

  const toggleAspectRatio = () => {
		const multipleProps: IMultipleEntityProps_Ed_Doc[] = selectedEntityIds.map(id => {
				return {
					id,
					aspectRatioLocked: !locked,
				};
			});
		dispatch(onSetMultipleEntityProps_Ed_Doc(multipleProps));
	};
  
	return (
		<button
			className={CSS.PadLockBtn}
			onClick={toggleAspectRatio}>
			{locked
				? <AspectRatioLockedIcon title={"Unlock aspect ratio"} hexFill={ICON_ACTIVE_BLUE_COLOR} />
				: <AspectRatioUnlockedIcon title={"Lock aspect ratio"} hexFill={DEFAULT_ICON_COLOR} />}
		</button>
	);
}

export default React.memo(TransformEntityLock);