// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FlexContainer--2nyzW{display:flex;flex-direction:column;align-items:center}.Description--1UHVQ{color:#a5a5a5;font-size:13px;margin-bottom:16px;align-self:flex-start}.LinkContainerDiv--3266K{overflow:hidden;width:100%;position:relative}.PhoneLink--YmA_F{display:flex;flex-direction:row;align-items:center;justify-content:center;color:#5ea4e9;text-decoration:none;margin:8px 0 24px 0;font-size:16px;width:fit-content}.GradientDiv--1SgYb{position:absolute;top:0;right:0;height:100%;width:10%;background-image:linear-gradient(to right, rgba(255, 255, 255, 0), white)}", ""]);
// Exports
exports.locals = {
	"FlexContainer": "FlexContainer--2nyzW",
	"Description": "Description--1UHVQ",
	"LinkContainerDiv": "LinkContainerDiv--3266K",
	"PhoneLink": "PhoneLink--YmA_F",
	"GradientDiv": "GradientDiv--1SgYb"
};
module.exports = exports;
