import React, { useState, useMemo, useEffect, useRef, FunctionComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import uuid4 from 'uuid/v4';
import * as colorconvert from 'color-convert';

import { IDesignerState } from '../../../../../typings';
import {
	onSetProjectColors_Ed_Doc,
	IOnSetProjectColors_Ed_Doc,
} from '../../../../store/actions';
import {
	getContentEntityDict,
	getContentProjectColors,
} from '../../../../store/selectors';
import ColorPicker from '../../Colorpicker/Colorpicker';
import ColorCircle from '../InspectorMenu/Shared/ColorCircle/ColorCircle';

import CSS from './ColorPickerMenu.scss';
import './ColorPickerMenu.global.scss';
import { PlusIcon, BinIcon } from '../../../../assets/icons';
import {
	ITuple4,
	ISpatialComponentUnion,
	IComponentType,
	IProjectColor,
} from '../../../r3f/r3f-components/component-data-structure';
import { BASIC_COLORS } from '../../../../utils';

interface IParentProps {
	show: boolean;
	rgba: ITuple4;
	targetElRef: React.MutableRefObject<any>;
	zIndex?: number;
	onClose: (e: MouseEvent | null) => any;
	onChange?: (rgba: ITuple4) => any;
	onSubmit?: (rgba: ITuple4) => any;
}

interface IReduxProps {
	selectedEntityIds: string[];
	entityContentDict: { [id: string]: ISpatialComponentUnion };
	projectColors: IProjectColor[];
}

interface IDispatchProps {
	onSetProjectColors_Ed_Doc: IOnSetProjectColors_Ed_Doc;
}

const ColorPickerMenu: FunctionComponent<IParentProps &
	IReduxProps &
	IDispatchProps> = ({
	show,
	selectedEntityIds,
	entityContentDict,
	projectColors,
	onSetProjectColors_Ed_Doc,
	targetElRef,
	rgba,
	zIndex = 1000,
	onClose,
	onChange,
	onSubmit,
}) => {
	const menuRef = useRef<HTMLDivElement>();
	const [selectedColorId, setSelectedColorId] = useState<string | null>(null);

	useEffect(() => {
		const handleClickOutside = (event: PointerEvent) => {
			if (
				menuRef?.current?.contains((event as any).target) ||
				targetElRef?.current?.contains(event.target)
			) return;
			onClose(event);
		};
		document.body.addEventListener('pointerdown', handleClickOutside);
		// clean up after 'unmount'
		return function cleanup() {
			document.body.removeEventListener('pointerdown', handleClickOutside);
		};
	}, []);

	const prjColIsSelected = useMemo(() => projectColors.filter(col => col.id === selectedColorId).length > 0, [selectedColorId, projectColors]);
	if (!show || selectedEntityIds.length === 0) return null;



	const id = selectedEntityIds[0];

	const basicColorBtns = BASIC_COLORS.map(({id, hex, rgba}) => {
		return (
			<ColorCircle
				key={id}
				colorId={id}
				isActive={id === selectedColorId}
				rgba={rgba as ITuple4}
				onClick={() => {
					setSelectedColorId(id);
					if (onSubmit) onSubmit(rgba as ITuple4);
				}}
			/>
		);
	});


	const projectColorBtns = projectColors.map(({id, rgba: prjRgba}) => {
		return (
			<div 
				key={id} 
				className={CSS.GridCell}
			>
				<ColorCircle
					colorId={id}
					isActive={id === selectedColorId}
					rgba={prjRgba as ITuple4}
					onClick={() => {
						setSelectedColorId(id);
						const [r, g, b, a] = prjRgba;
						if (onSubmit) onSubmit([r, g, b, a * 100] as ITuple4);
					}}
				/>
			</div>
		);
	});

	

	return (
		<>
		<div className={CSS.ColorPickerMenu} ref={menuRef} style={{ zIndex }}>
			<div>Basic colors</div>
			<div className={CSS.GridContainer}>{basicColorBtns}</div>
			<div className={CSS.ColorPickerMargin}>Project colors</div>
			<div className={CSS.GridContainer}>
				<div className={CSS.GridCell}>
					<div
						className={CSS.AddColorBtn}
						onClick={() => {
							const id = uuid4();
							const [r, g, b, a] = rgba;
							const hex = colorconvert.rgb.hex([r, g, b]);
							projectColors.push({id, hex, rgba:[r, g, b, a]});
							onSetProjectColors_Ed_Doc(projectColors);
							setSelectedColorId(id);
						}}
					>
						<PlusIcon hexFill={'#5C5C5C'} />
					</div>
				</div>
				{projectColorBtns}
				{prjColIsSelected && <div className={CSS.GridCell} style={{marginLeft: '2px'}}>
					<div
						className={CSS.RemoveColorBtn}
						onClick={() => {
							const filteredColors: IProjectColor[] = []
							for (let i = 0; i < projectColors.length; i++) {
								const {id, hex, rgba: prjRgba} = projectColors[i];
								if (selectedColorId === id) continue;
								filteredColors.push({id, hex, rgba: [...prjRgba]});
							}
							onSetProjectColors_Ed_Doc(filteredColors);
						}}
					>
						<BinIcon hexFill={'#b35757'} />
					</div>
				</div>}
			</div>
			<ColorPicker
				rgba={rgba}
				onChange={rgba => {
					if (onChange) onChange(rgba);
					setSelectedColorId(null);
				}}
				onSubmit={rgba => {
					if (onSubmit) onSubmit(rgba);
					setSelectedColorId(null);
				}}
			/>
			
		</div>
		<div 
			onClick={e => e.stopPropagation()}
			className={CSS.OutsideClickDiv}>
		</div>
	</>
	);
};

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	const selectedEntityIds = state.userReducer.selectedEntityIds;
	const id = selectedEntityIds[0];
	return {
		selectedEntityIds: state.userReducer.selectedEntityIds,
		entityContentDict: getContentEntityDict(state) as any, //TODO: change
		projectColors: getContentProjectColors(state),
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onSetProjectColors_Ed_Doc,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorPickerMenu);
