import React from 'react';
import { IPauseVideos, VideoStateContext } from '../context/video-context';

export type IUpdateVideoStateByIds = (a: {id: string; playing: boolean}[]) => void;

type IUseVideoState = (id?: string) => {
    isPlaying: boolean | null;
    updateVideoStateByIds: IUpdateVideoStateByIds;
    pauseVideos: IPauseVideos;
} | null

export const useVideoState: IUseVideoState = id => {
    const videoStateContext = React.useContext(VideoStateContext);
    return React.useMemo(() => {
        if (!videoStateContext) return null;
        const {videoStateDict, updateVideoStateDict, pauseVideos} = videoStateContext;
        return {
            isPlaying: id ? videoStateDict[id] : null, 
            updateVideoStateByIds: updateVideoStateDict,
            pauseVideos,
        }
    }, [videoStateContext, id])
}