import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../../typings';
import { getContentEntityDict, getEditorAspRatioLockedDict, getEditorIsLockedDict, getEditorScalesInvertedDict } from '../../../../../../store/selectors';
import { checkIfObjectArrayPropsEqual } from '../../../../../../utils';
import { ITransformProps } from '../../../../../r3f/r3f-components/component-data-structure';
import TransformsSize from './TransformsSize/TransformsSize';
import TransformsPosition from './TransformsPosition/TransformsPosition';
import TransformsRotation from './TransformsRotation/TransformsRotation';
import TransformsOrder from './TransformsOrder/TransformsOrder';
import TransformsMirror from './TransformsMirror/TransformsMirror';

const TransformsContent = () => {	
	// Redux
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const entityContentDict = useSelector((state: IDesignerState) => getContentEntityDict(state));
	const editorIsLockedDict = useSelector((state: IDesignerState) => getEditorIsLockedDict(state));
	const editorAspRatioIsLockedDict =  useSelector((state: IDesignerState) => getEditorAspRatioLockedDict(state));
	const editorScalesInvertedDict = useSelector((state: IDesignerState) => getEditorScalesInvertedDict(state));
	const is3dMode = useSelector((state: IDesignerState) => state.userReducer.is3dMode);

	// Derived
	const entityIsLocked = selectedEntityIds.length === 1 && editorIsLockedDict[selectedEntityIds[0]];
	let entityArray = useMemo(() => {
		const noDuplicates = new Set(selectedEntityIds);
		const ids = Array.from(noDuplicates)
		return ids.map(id => {
			return {
				...entityContentDict[id],
				aspectRatioLocked: editorAspRatioIsLockedDict[id],
				scalesInverted: editorScalesInvertedDict[id],
			};
		})
	}, [selectedEntityIds, editorAspRatioIsLockedDict, editorScalesInvertedDict]);
	
	// Check if all entities have the same scale, position, rotation etc for use in multi select
	let entitiesHaveSame = useMemo(() => checkIfObjectArrayPropsEqual(
		[
			ITransformProps.scale,
			ITransformProps.position,
			ITransformProps.rotation,
			ITransformProps.scalesInverted,
			ITransformProps.aspectRatioLocked,
		],
		entityArray
	), [entityArray]);
	
	return (
		<div>
			<TransformsSize
				entityIsLocked={entityIsLocked}
				entitiesHaveSame={entitiesHaveSame} />
			<TransformsPosition
				entityIsLocked={entityIsLocked}
				entitiesHaveSame={entitiesHaveSame} />
			<TransformsRotation
				entityIsLocked={entityIsLocked}
				entitiesHaveSame={entitiesHaveSame} />
			<TransformsOrder
			  entityIsLocked={entityIsLocked}	/>
			<TransformsMirror
				entityIsLocked={entityIsLocked}
			/>
		</div>
	);
};

export default React.memo(TransformsContent);