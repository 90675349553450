// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NumericalInput--3r_8a{display:flex;position:relative;box-sizing:border-box;background-color:#eef6fe;border:1px solid #eef6fe;width:56px;height:23px;border-radius:3px;color:#344b60;overflow:hidden;border:1px solid transparent}.NumericalInput--3r_8a input{user-select:auto;width:100%;padding:5px;font-size:13px;outline:none;color:inherit;border:none;outline:none;background-color:inherit;-moz-appearance:textfield}.NumericalInput--3r_8a input::placeholder{color:#344b60}.NumericalInput--3r_8a label{position:absolute;right:3px;align-self:center;font-size:10px;font-weight:500;opacity:1;height:11px}.NumericalInput--3r_8a button{height:50%;margin:0;margin-top:1px;outline:none;background-color:transparent;border:none;padding:0;display:flex;justify-content:center;align-items:center}.NumericalInput--3r_8a button svg{display:flex;pointer-events:none;z-index:3;width:10px}.First--qp8jn:focus-within{background-color:transparent;border:1px solid #4a90e2;border-radius:3px 0 0 3px}.Center--1XD2G:focus-within{background-color:transparent;border:1px solid #4a90e2;border-radius:0 0 0 0}.Last--8i8pG:focus-within{background-color:transparent;border:1px solid #4a90e2;border-radius:0 3px 3px 0}.Disabled--2q2TU{opacity:.6}.InputBtnContainer--2Dnmh{position:absolute;right:0px;display:flex;flex-direction:column;align-items:center;justify-content:space-between;pointer-events:none;width:10px;top:0;margin:0;padding:0 0 1px 0;height:100%}input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}input[type=number]{-moz-appearance:textfield}.TopArrow--3IV6Q{position:relative;right:0;top:0;pointer-events:auto}.BottomArrow--3EHHF{position:relative;right:0;bottom:0;pointer-events:auto}", ""]);
// Exports
exports.locals = {
	"NumericalInput": "NumericalInput--3r_8a",
	"First": "First--qp8jn",
	"Center": "Center--1XD2G",
	"Last": "Last--8i8pG",
	"Disabled": "Disabled--2q2TU",
	"InputBtnContainer": "InputBtnContainer--2Dnmh",
	"TopArrow": "TopArrow--3IV6Q",
	"BottomArrow": "BottomArrow--3EHHF"
};
module.exports = exports;
