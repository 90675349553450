// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CanvasContainer--3-qgA{height:60px;margin-top:16px;position:relative}.CanvasContainer--3-qgA>.CanvasGraph--3FGwZ{z-index:1;position:absolute;top:0;left:0;height:60px;width:100%}.CanvasContainer--3-qgA>.ProgressDiv--2vvq9{z-index:0;position:absolute;top:0;left:0;height:60px;background-color:#96bfef}.VolumeDesc--35-X0{color:#5c5c5c;margin:8px 0}", ""]);
// Exports
exports.locals = {
	"CanvasContainer": "CanvasContainer--3-qgA",
	"CanvasGraph": "CanvasGraph--3FGwZ",
	"ProgressDiv": "ProgressDiv--2vvq9",
	"VolumeDesc": "VolumeDesc--35-X0"
};
module.exports = exports;
