// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ButtonRow--TbAmy{display:flex;align-items:center;justify-content:space-between;width:100%}.ButtonRow--TbAmy>.GhostBtn--1Si8Z{width:110px;height:32px}.CheckBoxRow--1Le9E{display:flex;align-items:center;justify-content:flex-start}.CheckBoxRow--1Le9E>span{margin-right:8px}.AudioListContainer--3Vyhi{margin-top:16px}.AudioList--3CzD3{margin-top:16px}.AudioTitleContainer--1gbcX{display:flex;justify-content:space-between;border:1px solid #c1cfdf;border-radius:6px;align-items:center;position:relative}.AudioTitleContainer--1gbcX .Title--WZyWu{display:flex;align-items:center;justify-content:space-between;min-width:0;flex-grow:100;align-items:center}.AudioTitleContainer--1gbcX .TitleClose--y1y07{position:absolute;right:0;margin-right:10px;cursor:pointer}.UploadButton--1sawS{margin-top:16px}.GhostButton--2skSk{background-color:#fff;color:#4a90e2;border:1px solid #4a90e2;height:32px;border-radius:6px;margin-top:24px}", ""]);
// Exports
exports.locals = {
	"ButtonRow": "ButtonRow--TbAmy",
	"GhostBtn": "GhostBtn--1Si8Z",
	"CheckBoxRow": "CheckBoxRow--1Le9E",
	"AudioListContainer": "AudioListContainer--3Vyhi",
	"AudioList": "AudioList--3CzD3",
	"AudioTitleContainer": "AudioTitleContainer--1gbcX",
	"Title": "Title--WZyWu",
	"TitleClose": "TitleClose--y1y07",
	"UploadButton": "UploadButton--1sawS",
	"GhostButton": "GhostButton--2skSk"
};
module.exports = exports;
