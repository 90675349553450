import React from "react";
import { IModel3d } from "../../../../../../r3f/r3f-components/component-data-structure";
import { ENTITY_PREVIEW_HEIGHT } from '../../../../../../../utils';
import CSS from './Model3dContent.scss'

interface IParentProps {
  entity: IModel3d
}

const Model3dContent = ({
  entity
}: IParentProps) => {
  return (
    <>
      <div className={CSS.Title}>3D Model</div>
      <div
        className={CSS.PreviewDiv}
        style={{
          width: '100%',
          height: `${ENTITY_PREVIEW_HEIGHT}px`,
        }}
      >
        <img src={entity?.thumbnailUrl}/>
      </div>
    </>
  )
}

export default React.memo(Model3dContent);