// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OptionsContainer--1Ch3R{position:absolute;top:calc(100% + 1px);z-index:99999;padding-top:8px;padding-bottom:8px;border-radius:8px;background-color:#fff;box-shadow:0 2px 4px 0 rgba(52,75,96,.1),0 1px 3px 0 rgba(52,75,96,.2)}.OutsideClickDiv--2WPRX{height:100vh;width:100vw;background-color:rgba(255,255,255,0);position:fixed;top:0;left:0;z-index:999}", ""]);
// Exports
exports.locals = {
	"OptionsContainer": "OptionsContainer--1Ch3R",
	"OutsideClickDiv": "OutsideClickDiv--2WPRX"
};
module.exports = exports;
