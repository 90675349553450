import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { IDesignerState } from '../../../../../typings';
import Tabs from '../../Tabs/Tabs';
import EntityShelves from './EntityShelves/EntityShelves';
import SceneShelves from './SceneShelves/SceneShelves';
import ProjectShelves from './ProjectShelves/ProjectShelves';
import ViewBtnRow from './ViewBtnRow/ViewBtnRow';
import * as Sentry from '@sentry/react';
import { ToastsData } from '../../../../utils/toasts-data'; 

import {
	getContentEntityDict,
	getEditorTitlesDict,
} from '../../../../store/selectors';
import { bindActionCreators } from 'redux';

import {
	onAddToast, 
	IOnAddToast, 
} from '../../../../store/actions';

import CSS from './InspectorMenu.scss';
import { ISpatialComponentUnion } from '../../../r3f/r3f-components/component-data-structure';

enum ITabs {
	scene = 'scene',
	project = 'project',
}

interface IReduxProps {
	selectedEntityIds: string[];
	entityContentDict: { [id: string]: ISpatialComponentUnion };
	sceneTitle: string;
	activeSceneId: string;
	socketHasConnected: boolean;
}

interface IDispatchProps {
	onAddToast: IOnAddToast;
}

const InspectorMenu: FunctionComponent<IReduxProps & IDispatchProps> = ({
	selectedEntityIds,
	sceneTitle,
	activeSceneId,
	entityContentDict,
	onAddToast,
	socketHasConnected
}) => {
	const [activeTabId, setActiveTabId] = useState<string>(ITabs.project);
	const entityId = selectedEntityIds[0];
	const entity = entityContentDict[entityId];
	const tabData = [
		{ id: ITabs.scene, title: 'This Scene' },
		{ id: ITabs.project, title: 'Project' },
	];
	const showNonEntityShelves = !entity && !!activeSceneId;
	return (
		<div className={CSS.InspectorMenu}> 
			<Sentry.ErrorBoundary fallback={null} onError={() => onAddToast(ToastsData.GeneralError)
			}>
				{socketHasConnected ?
				<>
					<Tabs
						className={CSS.InspectorTabs}
						show={showNonEntityShelves}
						activeTabId={activeTabId}
						tabData={tabData}
						onClick={tabId => {
							setActiveTabId(tabId);
						}}
					/>
					<div className={CSS.ShelfContainer}>
						<EntityShelves
							show={!!entity}
							entity={entity}
							entityId={entityId}
						/>
						<SceneShelves
							show={showNonEntityShelves && activeTabId === ITabs.scene}
							sceneId={activeSceneId}
							sceneTitle={sceneTitle}
						/>
						<ProjectShelves
							show={showNonEntityShelves && activeTabId === ITabs.project}
						/>
					</div>
					<ViewBtnRow />
				</> : null}
			</Sentry.ErrorBoundary>
		</div>
	);
};

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	const { activeSceneId } = state.userReducer;
	return {
		selectedEntityIds: state.userReducer.selectedEntityIds,
		entityContentDict: getContentEntityDict(state) as any, //TODO: change
		sceneTitle: getEditorTitlesDict(state)[activeSceneId],
		activeSceneId,
		socketHasConnected: state.userReducer.socketHasConnected
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onAddToast,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(InspectorMenu);
