import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR, WHITE_ICON_COLOR } from '../../utils';

const scaleDownIcon: React.FunctionComponent<IIconParentProps> = ({
	className,
	hexFill: fill,
	secondHexFill: secondFill, 
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	const secondHexFill = secondFill; 
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="46px"
			height="51px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 46 51'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<defs>
				<radialGradient cx="50%" cy="48.7357475%" fx="50%" fy="48.7357475%" r="76.1877111%" gradientTransform="translate(0.500000,0.487357),scale(1.000000,0.666667),rotate(90.000000),translate(-0.500000,-0.487357)" id="radialGradient-1">
					<stop stopColor={hexFill} stopOpacity="0.63" offset="0%"></stop>
					<stop stopColor={WHITE_ICON_COLOR} stopOpacity="0" offset="100%"></stop>
				</radialGradient>
			</defs>
			<g id="Components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Illustration-/-Scale-down-(1)" transform="translate(-11.000000, -8.000000)">
					<g id="Illustration-2" transform="translate(11.000000, 8.000000)">
						<g id="Group" transform="translate(0.000000, 0.588235)">
							<g id="Arrow" transform="translate(6.500000, 42.911765) scale(1, -1) translate(-6.500000, -42.911765) translate(0.000000, 36.411765)" fill={hexFill}>
								<path d="M11.8079017,8.3373386 L6.97538054,3.56502273 C6.81802701,3.40637386 6.60163932,3.31797702 6.37500274,3.31973506 C6.14836615,3.3215461 5.93055947,3.41336179 5.77068786,3.5745085 L0.862408199,8.42352359 C0.564797885,8.76099787 0.576807081,9.26382874 0.889970715,9.57757954 C1.20313435,9.89133033 1.71187818,9.91022887 2.0575976,9.62095389 L6.39384623,5.37165279 L10.6622776,9.58719604 C10.8196311,9.74584491 11.0360188,9.83424175 11.2626554,9.83248371 C11.489292,9.83067267 11.7070987,9.73885698 11.8669703,9.57771027 C12.0335317,9.40647817 12.1217412,9.17574953 12.110543,8.94059997 C12.0993448,8.70545042 11.9897436,8.48698012 11.8079017,8.3373386 Z" id="Path-Copy-8" transform="translate(6.379201, 6.576109) scale(1, -1) rotate(45.000000) translate(-6.379201, -6.576109) "></path>
							</g>
							<g id="Arrow" transform="translate(39.500000, 42.911765) scale(-1, -1) translate(-39.500000, -42.911765) translate(33.000000, 36.411765)" fill={hexFill}>
								<path d="M11.8079017,8.3373386 L6.97538054,3.56502273 C6.81802701,3.40637386 6.60163932,3.31797702 6.37500274,3.31973506 C6.14836615,3.3215461 5.93055947,3.41336179 5.77068786,3.5745085 L0.862408199,8.42352359 C0.564797885,8.76099787 0.576807081,9.26382874 0.889970715,9.57757954 C1.20313435,9.89133033 1.71187818,9.91022887 2.0575976,9.62095389 L6.39384623,5.37165279 L10.6622776,9.58719604 C10.8196311,9.74584491 11.0360188,9.83424175 11.2626554,9.83248371 C11.489292,9.83067267 11.7070987,9.73885698 11.8669703,9.57771027 C12.0335317,9.40647817 12.1217412,9.17574953 12.110543,8.94059997 C12.0993448,8.70545042 11.9897436,8.48698012 11.8079017,8.3373386 Z" id="Path-Copy-8" transform="translate(6.379201, 6.576109) scale(1, -1) rotate(45.000000) translate(-6.379201, -6.576109) "></path>
							</g>
							<g id="Arrow" transform="translate(0.000000, 0.411765)" fill={hexFill}>
								<path d="M11.8079017,8.3373386 L6.97538054,3.56502273 C6.81802701,3.40637386 6.60163932,3.31797702 6.37500274,3.31973506 C6.14836615,3.3215461 5.93055947,3.41336179 5.77068786,3.5745085 L0.862408199,8.42352359 C0.564797885,8.76099787 0.576807081,9.26382874 0.889970715,9.57757954 C1.20313435,9.89133033 1.71187818,9.91022887 2.0575976,9.62095389 L6.39384623,5.37165279 L10.6622776,9.58719604 C10.8196311,9.74584491 11.0360188,9.83424175 11.2626554,9.83248371 C11.489292,9.83067267 11.7070987,9.73885698 11.8669703,9.57771027 C12.0335317,9.40647817 12.1217412,9.17574953 12.110543,8.94059997 C12.0993448,8.70545042 11.9897436,8.48698012 11.8079017,8.3373386 Z" id="Path-Copy-8" transform="translate(6.379201, 6.576109) scale(1, -1) rotate(45.000000) translate(-6.379201, -6.576109) "></path>
							</g>
							<g id="Arrow" transform="translate(39.500000, 6.911765) scale(-1, 1) translate(-39.500000, -6.911765) translate(33.000000, 0.411765)" fill={hexFill}>
								<path d="M11.8079017,8.3373386 L6.97538054,3.56502273 C6.81802701,3.40637386 6.60163932,3.31797702 6.37500274,3.31973506 C6.14836615,3.3215461 5.93055947,3.41336179 5.77068786,3.5745085 L0.862408199,8.42352359 C0.564797885,8.76099787 0.576807081,9.26382874 0.889970715,9.57757954 C1.20313435,9.89133033 1.71187818,9.91022887 2.0575976,9.62095389 L6.39384623,5.37165279 L10.6622776,9.58719604 C10.8196311,9.74584491 11.0360188,9.83424175 11.2626554,9.83248371 C11.489292,9.83067267 11.7070987,9.73885698 11.8669703,9.57771027 C12.0335317,9.40647817 12.1217412,9.17574953 12.110543,8.94059997 C12.0993448,8.70545042 11.9897436,8.48698012 11.8079017,8.3373386 Z" id="Path-Copy-8" transform="translate(6.379201, 6.576109) scale(1, -1) rotate(45.000000) translate(-6.379201, -6.576109) "></path>
							</g>
							<g id="Bolt" transform="translate(7.000000, 0.411765)" fillRule="nonzero">
								<polygon id="Path-Copy-5" fill={hexFill} points="6.4 18.1937302 14.3174462 9.6 25.6 22.6825581 15.9996267 30.7248799 21.078269 38.4 6.63590342 30.6187186 13.6346203 23.2914099"></polygon>
								<polygon id="Path-Copy-5" fill="url(#radialGradient-1)" points="0 14.3228836 13.1957437 0 32 21.8042635 15.9993779 35.2081331 24.4637816 48 0.393172363 35.0311977 12.0577005 22.8190165"></polygon>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default scaleDownIcon;
