import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { onSetMultipleComponentProps_Cn_Doc } from "../../../../../../../store/actions";
import { IMultipleEntityProps_Cn_Doc } from "../../../../../../../store/actions";
import { IVideo } from "../../../../../../r3f/r3f-components/component-data-structure";
import CSS from './VideoContent.scss'
import Checkbox from '../../../../../Checkbox/Checkbox';
import { IDesignerState } from "../../../../../../../../typings";

interface IParentProps {
  entity: IVideo;
  selectedEntityIds: string[];
}

const VideoContent = ({
  entity,
  selectedEntityIds
}: IParentProps) => {
  const dispatch = useDispatch();
  const mp4Url = useSelector((state: IDesignerState) => state.editorReducer.editorDoc.mp4Url[selectedEntityIds[0]]);

  return (
    <>
      <div>
        <div className={CSS.Title}>Video</div>
        <video
          width={'100%'}
          poster={entity.thumbnailUrl}
          src={mp4Url}
          controls
          muted
          disablePictureInPicture
        />
      </div>
      <div className={CSS.FlexRow} style={{marginTop: '16px', marginBottom: '8px'}}>
        <div className={CSS.FlexColumn}>
          <div className={CSS.FlexRow}>
            <Checkbox
              checked={entity.autoplay}
              label={"Autoplay"}
              onChange={val => {
                const entityCnProps: IMultipleEntityProps_Cn_Doc[] = selectedEntityIds.map(id => ({
                  id,
                  autoplay: val,
                }))
                dispatch(onSetMultipleComponentProps_Cn_Doc(entityCnProps));
              }}
            />
          </div>
        </div>
        <div className={CSS.FlexColumn}>
          <div className={CSS.FlexRow}>
            <Checkbox
              checked={entity.loop}
              label="Loop"
              onChange={val => {
                const entityCnProps: IMultipleEntityProps_Cn_Doc[] = selectedEntityIds.map(id => ({
                  id,
                  loop: val,
                }))
                dispatch(onSetMultipleComponentProps_Cn_Doc(entityCnProps));
              }}
            />
          </div>
        </div>

      </div>
      <div className={CSS.FlexRow} style={{ margin: '0 0 16px 0' }}>
          <Checkbox
            checked={entity.stopMedia}
            label="Stop other media"
            onChange={val => {
              const entityCnProps: IMultipleEntityProps_Cn_Doc[] = selectedEntityIds.map(id => ({
                id,
                stopMedia: val,
              }))
              dispatch(onSetMultipleComponentProps_Cn_Doc(entityCnProps));
            }}
          />
        </div>
      <span>
      Note: Autoplay hides the video UI on start. Autoplay with loop hides/ disables the UI permanently.
        {entity.loop && <p>On finish actions have been disabled because the video is set to loop.</p>}
      </span>
    </>
  );
}

export default React.memo(VideoContent);