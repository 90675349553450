// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TextInputContainer--1v9KK{position:relative;display:flex;width:100%}.TextInput--14pX7{border:none;height:32px;width:100%;font-weight:500;padding:0 5px;border-radius:5px;background-color:#eef6ff;font-size:14px}.TextInput--14pX7:disabled{opacity:.7}.TextInput--14pX7.Editable--3ahFV:focus{border:1px solid #4a90e2;background-color:unset}.TextInput--14pX7.Label--36wMS{padding-left:12px}.TextInput--14pX7::placeholder{color:#b2c4d7}.TextInput--14pX7.Ellipsis--3GbfH{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}label{position:absolute;align-self:center;opacity:.8;padding-left:2px}.InError--2NDYB{border:1px solid red}", ""]);
// Exports
exports.locals = {
	"TextInputContainer": "TextInputContainer--1v9KK",
	"TextInput": "TextInput--14pX7",
	"Editable": "Editable--3ahFV",
	"Label": "Label--36wMS",
	"Ellipsis": "Ellipsis--3GbfH",
	"InError": "InError--2NDYB"
};
module.exports = exports;
