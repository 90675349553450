// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DialogContainer--lVQUY{position:absolute;right:280px;z-index:999;padding:16px 16px 24px 16px;border-radius:8px 8px 0 0;height:fit-content;width:fit-content;border:1px solid #4a90e2;background-color:#fff;box-shadow:0 2px 4px 0 rgba(52,75,96,.1),0 1px 3px 0 rgba(52,75,96,.2);font-weight:300;color:#344b60;border-bottom:none}.Button--2VSxB{position:absolute;top:12px;right:12px;height:20px;width:20px;display:flex;align-items:center;justify-content:center;cursor:pointer}.Button--2VSxB svg{width:10px;height:10px}", ""]);
// Exports
exports.locals = {
	"DialogContainer": "DialogContainer--lVQUY",
	"Button": "Button--2VSxB"
};
module.exports = exports;
