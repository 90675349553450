import { createSelector, OutputSelector } from 'reselect';
import { IDesignerState } from '../../../typings';
import {
	maths,
	INITIAL_ZOOM_LEVEL,
	getDimensionsForAreaTypeInMm,
} from '../../utils';
import {
	ITriggerTypes,
	IComponentType,
	ISpatialComponentUnion,
	ISceneComp,
	IRoot,
	IDialOptions,
	IEmailOptions,
	IContactOptions,
	ISoundOptions,
	ISocialOptions,
	IPageEditorLinkOptions,
	IActionCategory,
	IVector3,
	ITuple3,
	ITuple3Dict,
	ITuple4,
	IVector3Dict,
	ITransitionDict,
	ITrackingTypes,
	IImageTrackingState_Ed_Doc,
	IFaceTrackingState_Ed_Doc,
	IAreaTypes,
	IProjectColor,	
} from '../../components/r3f/r3f-components/component-data-structure';
import { getZoomAdjFactor } from './general-selectors';

export type IBounds = [
	ITuple3,
	ITuple3,
	ITuple3,
	ITuple3,
	ITuple3,
	ITuple3,
	ITuple3,
	ITuple3
];

interface ISelectionEntityData {
	id: string;
	rotation: IVector3;
	position: IVector3;
	scale: IVector3;
}

export type ISelectionData = ISelectionEntityData[];

// Get selected entity Ids
const getSelectedIds = (state: IDesignerState) =>
	state.userReducer.selectedEntityIds;

const getZoomFactor = (state: IDesignerState) => state.userReducer.zoomLevel;

export const getSelectedEntityIds = createSelector(
	[getSelectedIds],
	selectedEntityIds => selectedEntityIds || []
);

// Get rotations of all entities
const getAllContentEntities = (state: IDesignerState) =>
	state.contentReducer.contentDoc.componentsById;

export const getContentEntityDict = createSelector(
	[getAllContentEntities],
	entityDict => entityDict
);

const getTargetArea = (state: IDesignerState) =>
	state.editorReducer.editorDoc?.targetDisplayInfo?.targetArea

const getAllProjectColors = (state: IDesignerState) =>
	state.editorReducer.editorDoc.projectColors;

const getActiveSceneId = (state: IDesignerState) =>
	state.userReducer.activeSceneId;

export const getContentProjectColors = createSelector(
	[getAllProjectColors],
	projectColorArray => {
		if (isNaN(projectColorArray.length)) return [] as IProjectColor[]
		return JSON.parse(JSON.stringify(projectColorArray)) as IProjectColor[]
	}
);

export const getSelectedEntityTransitions = createSelector(
	[getContentEntityDict, getSelectedEntityIds],
	(entityDict, selectedEntityIds) => {
		let transitionDict: ITransitionDict = {};
		for (let i = 0; i < selectedEntityIds.length; i++) {
			const id = selectedEntityIds[i];
			const { type } = entityDict[id];
			if (type === IComponentType.Root || type === IComponentType.Scene)
				continue;
			transitionDict[id] = (entityDict[
				id
			] as ISpatialComponentUnion).transitions;
		}
		return transitionDict;
	}
);

// get individual content rotations for all selected entities
export const getSelectedContentRotations = createSelector(
	[getSelectedEntityIds, getAllContentEntities],
	(selectedEntityIds, entities) => {
		const rotationsDict: IVector3Dict = {};
		for (let i = 0; i < selectedEntityIds.length; i++) {
			const id = selectedEntityIds[i];
			const { type } = entities[id];
			if (type === IComponentType.Root || type === IComponentType.Scene)
				continue;
			rotationsDict[id] = (entities[id] as ISpatialComponentUnion).rotation;
		}
		return rotationsDict as ITuple3Dict;
	}
);

// get individual content scale factors for all selected entities
export const getContentScalesOfSelectedEntities = createSelector(
	[getSelectedEntityIds, getAllContentEntities],
	(selectedEntityIds, entities) => {
		const scaleDict: IVector3Dict = {};
		for (let i = 0; i < selectedEntityIds.length; i++) {
			const id = selectedEntityIds[i];
			const { type } = entities[id];
			if (type === IComponentType.Root || type === IComponentType.Scene)
				continue;
			scaleDict[id] = (entities[id] as ISpatialComponentUnion).scale;
		}
		return scaleDict as ITuple3Dict;
	}
);

// get boundary box positions
const getEditorPositions = (state: IDesignerState) =>
	state.editorReducer.editorDoc && state.editorReducer.editorDoc.positions
		? state.editorReducer.editorDoc.positions
		: null;

const getEditorRotations = (state: IDesignerState) =>
	state.editorReducer.editorDoc && state.editorReducer.editorDoc.rotations
		? state.editorReducer.editorDoc.rotations
		: null;

const getEditorScales = (state: IDesignerState) =>
	state.editorReducer.editorDoc && state.editorReducer.editorDoc.scales
		? state.editorReducer.editorDoc.scales
		: null;

///

const getEditorTitles = (state: IDesignerState) =>
	state.editorReducer.editorDoc && state.editorReducer.editorDoc.titles
		? state.editorReducer.editorDoc.titles
		: {};

const getEditorIsLocked = (state: IDesignerState) =>
	state.editorReducer.editorDoc && state.editorReducer.editorDoc.isLocked
		? state.editorReducer.editorDoc.isLocked
		: {};

const getEditorAspRatioIsLocked = (state: IDesignerState) =>
	state.editorReducer.editorDoc &&
	state.editorReducer.editorDoc.aspectRatioLocked
		? state.editorReducer.editorDoc.aspectRatioLocked
		: {};

const getEditorScalesInverted = (state: IDesignerState) =>
	state.editorReducer.editorDoc?.scalesInverted
		? state.editorReducer.editorDoc?.scalesInverted
		: {};

const getEditorOrigAspRatio = (state: IDesignerState) =>
	state.editorReducer.editorDoc?.originalAspectRatio
		? state.editorReducer.editorDoc?.originalAspectRatio
		: {};

const getEditorTextScale = (state: IDesignerState) =>
	state.editorReducer.editorDoc?.textScale
		? state.editorReducer.editorDoc?.textScale
		: {};

const getEdDocTargetHeightInMm = (state: IDesignerState) => 
	state.editorReducer.editorDoc?.targetDisplayInfo?.heightInMm;

const getEdDocTargetDisplayUnits = (state: IDesignerState) => 
	state.editorReducer.editorDoc?.targetDisplayInfo?.displayUnits;

const getEdDocTracking = (state: IDesignerState) => 
	state.editorReducer.editorDoc?.tracking;

const getEdDocTargetAreaAspectRatio = (state: IDesignerState) => 
	state.editorReducer.editorDoc?.targetDisplayInfo?.targetAreaAspectRatio;


export const getEditorTargetArea = createSelector(
	[getTargetArea],
	targetArea => targetArea
);

export const getEditorTargetHeightInMm = createSelector(
	[getEdDocTargetHeightInMm],
	editorHeightInMm => editorHeightInMm
);

export const getEditorTargetAreaAspectRatio = createSelector(
	[getEdDocTargetAreaAspectRatio],
	aspectRatio => aspectRatio
);

export const getEditorTrackingInfo = createSelector(
	[getEdDocTracking],
	tracking => tracking
);

export const getEditorTargetDisplayUnits = createSelector(
	[getEdDocTargetDisplayUnits],
	displayUnits => displayUnits
);

export const getEditorTitlesDict = createSelector(
	[getEditorTitles],
	editorTitlesDict => editorTitlesDict
);

export const getEditorIsLockedDict = createSelector(
	[getEditorIsLocked],
	editorIsLockedDict => editorIsLockedDict
);

export const getEditorAspRatioLockedDict = createSelector(
	[getEditorAspRatioIsLocked],
	aspRatioIsLockedDict => aspRatioIsLockedDict
);

export const getEditorScalesInvertedDict = createSelector(
	[getEditorScalesInverted],
	scalesInvertedDict => scalesInvertedDict
);

export const getEditorOrigAspRatioDict = createSelector(
	[getEditorOrigAspRatio],
	origAspRatioDict => origAspRatioDict
);

export const getEditorTextScaleDict = createSelector(
	[getEditorTextScale],
	textScaleDict => textScaleDict
);

const getContentPositions = (state: IDesignerState) => {
	const { componentsById } = state.contentReducer.contentDoc;
	const cnPositions: IVector3Dict = {};
	Object.keys(componentsById).forEach(id => {
		const { type } = componentsById[id];
		if (type !== IComponentType.Root && type !== IComponentType.Scene) {
			cnPositions[id] = (componentsById[id] as ISpatialComponentUnion).position;
		}
	});
	return cnPositions;
};

const getContentRotations = (state: IDesignerState) => {
	const { componentsById } = state.contentReducer.contentDoc;
	const cnRotations: IVector3Dict = {};
	Object.keys(componentsById).forEach(id => {
		const { type } = componentsById[id];
		if (type !== IComponentType.Root && type !== IComponentType.Scene) {
			cnRotations[id] = (componentsById[id] as ISpatialComponentUnion).rotation;
		}
	});
	return cnRotations as ITuple3Dict;
};

const getEntityScales = (state: IDesignerState) =>
	state.editorReducer.editorDoc && state.editorReducer.editorDoc.scales
		? state.editorReducer.editorDoc.scales
		: null;

const getContentScales = (state: IDesignerState) => {
	const { componentsById } = state.contentReducer.contentDoc;
	const cnScales: IVector3Dict = {};
	Object.keys(componentsById).forEach(id => {
		const { type } = componentsById[id];
		if (type !== IComponentType.Root && type !== IComponentType.Scene) {
			cnScales[id] = (componentsById[id] as ISpatialComponentUnion).scale;
		}
	});
	return cnScales as ITuple3Dict;
};

const getGroupInversion = (state: IDesignerState) => {
	const { groupIsXInverted, groupIsYInverted } = state.userReducer;
	return [groupIsXInverted, groupIsYInverted];
};

export const getSelectionBoundary = createSelector(
	[
		getSelectedEntityIds,
		getEditorRotations,
		getEditorPositions,
		getEditorScales,
		getContentPositions,
		getContentRotations,
		getContentScales,
		getGroupInversion,
	],
	(
		selectedEntityIds,
		editorRotations,
		editorPositions,
		editorScales,
		contentPositions,
		contentRotations,
		contentScales,
		groupInversionByAxis
	): IBounds => {
		if (!selectedEntityIds.length) return null;
		let accMin: IVector3;
		let accMax: IVector3;

		for (let i = 0; i < selectedEntityIds.length; i++) {
			const id = selectedEntityIds[i];
			const position = editorPositions[id] || contentPositions[id];
			const rotation = editorRotations[id] || contentRotations[id];
			const scale = editorScales?.[id] || contentScales[id] || [1, 1, 1];

			let hotspotCoords = maths.localToWorldPosition2d(
				rotation[2],
				[position[0], position[1]],
				[
					[-scale[0], scale[1]],
					[0, scale[1]],
					[scale[0], scale[1]],
					[scale[0], 0],
					[scale[0], -scale[1]],
					[0, -scale[1]],
					[-scale[0], -scale[1]],
					[-scale[0], 0],
				]
			);

			//TODO: refactor below priority setting
			hotspotCoords[0][2] = 0.007;
			hotspotCoords[1][2] = 0.008;
			hotspotCoords[2][2] = 0.009;
			hotspotCoords[3][2] = 0.007;
			hotspotCoords[4][2] = 0.006;
			hotspotCoords[5][2] = 0.005;
			hotspotCoords[6][2] = 0.005;
			hotspotCoords[7][2] = 0.005;

			if (selectedEntityIds.length === 1) {
				return hotspotCoords as IBounds;
			}

			const currMin = [
				Math.min(
					hotspotCoords[0][0],
					hotspotCoords[2][0],
					hotspotCoords[4][0],
					hotspotCoords[6][0]
				),
				Math.min(
					hotspotCoords[0][1],
					hotspotCoords[2][1],
					hotspotCoords[4][1],
					hotspotCoords[6][1]
				),
				0,
			];

			const currMax = [
				Math.max(
					hotspotCoords[0][0],
					hotspotCoords[2][0],
					hotspotCoords[4][0],
					hotspotCoords[6][0]
				),
				Math.max(
					hotspotCoords[0][1],
					hotspotCoords[2][1],
					hotspotCoords[4][1],
					hotspotCoords[6][1]
				),
				0,
			];

			if (i === 0) {
				accMin = currMin;
				accMax = currMax;
			} else {
				accMin = [
					currMin[0] < accMin[0] ? currMin[0] : accMin[0],
					currMin[1] < accMin[1] ? currMin[1] : accMin[1],
					0,
				];
				accMax = [
					currMax[0] > accMax[0] ? currMax[0] : accMax[0],
					currMax[1] > accMax[1] ? currMax[1] : accMax[1],
					0,
				];
			}
		}

		const inv = [...groupInversionByAxis];

		const xCenter = (accMax[0] + accMin[0]) / 2;
		const yCenter = (accMax[1] + accMin[1]) / 2;

		return [
			[
				inv[0] && inv[1] ? accMax[0] : accMin[0],
				inv[0] && inv[1] ? accMin[1] : accMax[1],
				0.007,
			], //top left
			[xCenter, inv[1] ? accMin[1] : accMax[1], 0.008], // top center
			[
				inv[0] && inv[1] ? accMin[0] : accMax[0],
				inv[0] && inv[1] ? accMin[1] : accMax[1],
				0.009,
			], //top right
			[inv[0] ? accMin[0] : accMax[0], yCenter, 0.007], // right center
			[
				inv[0] && inv[1] ? accMin[0] : accMax[0],
				inv[0] && inv[1] ? accMax[1] : accMin[1],
				0.006,
			], //bottom right
			[xCenter, inv[1] ? accMax[1] : accMin[1], 0.005], //bottom center
			[
				inv[0] && inv[1] ? accMax[0] : accMin[0],
				inv[0] && inv[1] ? accMax[1] : accMin[1],
				0.005,
			], //bottom left
			[inv[0] ? accMax[0] : accMin[0], yCenter, 0.005], //left center
		];
	}
);

export const getSelectionCenter = createSelector(
	[getSelectionBoundary],
	(selectionBoundary): ITuple3 => {
		let minimums = [
			Number.POSITIVE_INFINITY,
			Number.POSITIVE_INFINITY,
			Number.POSITIVE_INFINITY,
		];
		let maximums = [
			Number.NEGATIVE_INFINITY,
			Number.NEGATIVE_INFINITY,
			Number.NEGATIVE_INFINITY,
		];

		if (selectionBoundary) {
			for (let coord of selectionBoundary) {
				for (let dimension = 0; dimension < 3; dimension++) {
					maximums[dimension] = Math.max(maximums[dimension], coord[dimension]);
					minimums[dimension] = Math.min(minimums[dimension], coord[dimension]);
				}
			}
		}

		const xCenter = (maximums[0] + minimums[0]) / 2;
		const yCenter = (maximums[1] + minimums[1]) / 2;
		const zCenter = 0;
		return [xCenter, yCenter, zCenter];
	}
);

export const getSelectionRotation = createSelector(
	[getSelectedEntityIds, getEditorRotations, getContentRotations],
	(selectedIds, editorRotations, contentRotations) => {
		if (!selectedIds.length) return null;
		if (selectedIds.length === 1) {
			return (
				(editorRotations[selectedIds[0]] as ITuple3) ||
				(contentRotations[selectedIds[0]] as ITuple3)
			);
		}
		return [0, 0, 0] as ITuple3; //TODO: refactor to include temporary mutli-select rotation
	}
);

export const getSelectionScale = createSelector(
	[
		getSelectionBoundary,
		getSelectedEntityIds,
		getEditorScales,
		getContentScales,
	],
	(boundary, ids, editorScales, contentScales): ITuple3 | null => {
		switch (ids.length) {
			case 0:
				return null;
			case 1:
				return (
					(editorScales?.[ids[0]] as ITuple3) ||
					(contentScales[ids[0]] as ITuple3)
				);
			default:
				let scale: ITuple3 = [0, 0, 0];
				scale[0] =
					Math.sqrt(
						Math.pow(boundary[0][0] - boundary[2][0], 2) +
							Math.pow(boundary[0][1] - boundary[2][1], 2)
					) / 2;
				scale[1] =
					Math.sqrt(
						Math.pow(boundary[2][0] - boundary[4][0], 2) +
							Math.pow(boundary[2][1] - boundary[4][1], 2)
					) / 2;
				return scale as ITuple3;
		}
	}
);

export const getRotationMarkerPosition = createSelector(
	[
		getSelectionScale,
		getSelectionCenter,
		getEditorRotations,
		getContentRotations,
		getSelectedEntityIds,
		getSelectionScale,
		getZoomAdjFactor,
	],
	(
		selectionScale,
		center,
		editorRotations,
		contentRotations,
		ids,
		scale,
		zoomAdjFactor
	): ITuple3 | null => {
		if (!selectionScale) return null;
		const rotation =
			ids.length === 1
				? editorRotations[ids[0]] || contentRotations[ids[0]]
				: [0, 0, 0]; // group selections are never rotated
		const theta = (rotation[2] * Math.PI) / 180;
		const sfX = Math.abs(scale[0]) + 0.6 * zoomAdjFactor;
		const sfY = Math.abs(scale[1]) + 0.6 * zoomAdjFactor;
		//const sfz = scale[2] + 1.2;

		const x5 = -sfX * Math.cos(theta) + sfY * Math.sin(theta) + center[0];
		const y5 = -sfX * Math.sin(theta) - sfY * Math.cos(theta) + center[1];
		return [x5, y5, 0.05] as ITuple3;
	}
);

export const getSelectedEditorRotations = createSelector(
	[getEditorRotations, getSelectedEntityIds],
	(editorRotations, ids) => {
		const selectedEditorRotations: IVector3Dict = {};
		for (let i = 0; i < ids.length; i++) {
			selectedEditorRotations[ids[i]] = editorRotations[ids[i]];
		}
		return selectedEditorRotations as ITuple3Dict;
	}
);

export const getSelectedEditorScales = createSelector(
	[getEditorScales, getSelectedEntityIds],
	(getEditorScales, ids) => {
		const selectedEditorScales: IVector3Dict = {};
		for (let i = 0; i < ids.length; i++) {
			selectedEditorScales[ids[i]] = getEditorScales?.[ids[i]];
		}
		return selectedEditorScales as ITuple3Dict;
	}
);

export const getSelectionData = createSelector(
	[
		getSelectedEntityIds,
		getEditorRotations,
		getEditorPositions,
		getEditorScales,
		getContentRotations,
		getContentPositions,
		getContentScales,
	],
	(
		selectedIds,
		editorRotations,
		editorPositions,
		editorScales,
		contentRotations,
		contentPositions,
		contentScales
	) => {
		const selectionData: ISelectionData = selectedIds.map(id => {
			return {
				id: id,
				rotation: editorRotations[id] || contentRotations[id],
				position: editorPositions[id] || contentPositions[id],
				scale: editorScales?.[id] || contentScales[id],
			};
		});
		return selectionData;
	}
);

export const getSelectedEditorPositions = createSelector(
	[getEditorPositions, getSelectedEntityIds],
	(editorPositions, ids) => {
		const selectedEditorPositions: IVector3Dict = {};
		for (let i = 0; i < ids.length; i++) {
			selectedEditorPositions[ids[i]] = editorPositions[ids[i]];
		}
		return selectedEditorPositions as ITuple3Dict;
	}
);

export const getSelectedContentPositions = createSelector(
	[getContentPositions, getSelectedEntityIds],
	(contentPositions, ids) => {
		const selectedContentPositions: IVector3Dict = {};
		for (let i = 0; i < ids.length; i++) {
			selectedContentPositions[ids[i]] = contentPositions[ids[i]];
		}
		return selectedContentPositions as ITuple3Dict;
	}
);

export const getSelectedContentScales = createSelector(
	[getContentScales, getSelectedEntityIds],
	(contentPositions, ids) => {
		const selectedContentPositions: IVector3Dict = {};
		for (let i = 0; i < ids.length; i++) {
			selectedContentPositions[ids[i]] = contentPositions[ids[i]];
		}
		return selectedContentPositions as ITuple3Dict;
	}
);

/// Action related selectors

export const getActiveTrigger = (state: IDesignerState, id: string) =>
	state.editorReducer.editorDoc.activeTriggers?.[id] || ITriggerTypes.onLoad;

const getEditorActionDict = (state: IDesignerState, id: string) =>
	state.editorReducer.editorDoc?.actions?.[id] || {};

export const getEditorActionsDict = createSelector(
	[getEditorActionDict],
	actionsDict => actionsDict
);

const getCurrentUpload = (state: IDesignerState) => 
	state.userReducer.currentUploads; 

export const getCurrentUploadDict = createSelector(
	[getCurrentUpload],
	(uploadsDict) => uploadsDict
);

export const getEditorActiveActionsCategory = createSelector(
	[getEditorActionDict, getActiveTrigger],
	(actionsDict, activeTrigger) => {
		return (
			actionsDict?.[activeTrigger]?.selectedActionCategory ||
			IActionCategory.noAction
		);
	}
);

export const getEditorDialNumberOptions = createSelector(
	[getEditorActionDict, getActiveTrigger],
	(actionsDict, activeTrigger) => {
		return actionsDict?.[activeTrigger]?.dialOptions || ({} as IDialOptions);
	}
);

export const getEditorEmailOptions = createSelector(
	[getEditorActionDict, getActiveTrigger],
	(actionsDict, activeTrigger) => {
		return actionsDict?.[activeTrigger]?.emailOptions || ({} as IEmailOptions);
	}
);

export const getEditorContactOptions = createSelector(
	[getEditorActionDict, getActiveTrigger],
	(actionsDict, activeTrigger) => {
		return actionsDict?.[activeTrigger]?.contactOptions || ({} as IContactOptions);
	}
);

export const getEditorSocialLinkOptions = createSelector(
	[getEditorActionDict, getActiveTrigger],
	(actionsDict, activeTrigger) => {
		return (
			actionsDict?.[activeTrigger]?.socialOptions || ({} as ISocialOptions)
		);
	}
);

export const getEditorSoundOptions = createSelector(
	[getEditorActionDict, getActiveTrigger],
	(actionsDict, activeTrigger) => {
		return actionsDict?.[activeTrigger]?.soundOptions || ({} as ISoundOptions);
	}
);

export const getEditorLinkedPageOptions = createSelector(
	[getEditorActionDict, getActiveTrigger],
	(actionsDict, activeTrigger) => {
		return (
			actionsDict?.[activeTrigger]?.linkPageOptions ||
			({} as IPageEditorLinkOptions)
		);
	}
);

export const getEditorLinkedSceneId = createSelector(
	[getEditorActionDict, getActiveTrigger],
	(actionsDict, activeTrigger) => {
		return actionsDict?.[activeTrigger]?.linkedSceneId || null;
	}
);

export const getRootComponentId = (state: IDesignerState) => {
	return state.contentReducer?.contentDoc?.rootComponentId || 'rootId'; //TODO: remove default once backend assigns root id!
};

const getComponents = (state: IDesignerState) => {
	return state.contentReducer?.contentDoc?.componentsById || null;
};

export const getRootComponent = createSelector(
	[getRootComponentId, getComponents],
	(rootId, componentDict) => {
		return (componentDict?.[rootId] as IRoot) || null;
	}
);

export const getActiveSceneComponent = createSelector(
	[getComponents, getActiveSceneId],
	(componentDict, activeSceneId) => {
		return componentDict[activeSceneId] as ISceneComp;
	}
);

export const getComponentDict = createSelector(
	[getComponents],
	componentDict => componentDict
);

export const getActiveSceneTransitions = createSelector(
	[getComponentDict, getActiveSceneId],
	(componentDict, activeSceneId) => {
		let transitionDict: ITransitionDict = {};
		if (!activeSceneId) return transitionDict;
		let sceneIds = [activeSceneId]; //TODO: allow true multiscene selections
		for (let i = 0; i < sceneIds.length; i++) {
			const id = sceneIds[i];
			if (!componentDict[id]) continue;
			transitionDict[id] = (componentDict[id] as ISceneComp).transitions;
		}
		return transitionDict;
	}
);
