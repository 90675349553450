import React, { FunctionComponent, useMemo, memo } from 'react';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import { IImage, ITuple3, ITuple4 } from '../component-data-structure';
import RoundedRectWithBorder from './RoundedRectWithBorder';
import { ISharedHOCProps } from '../types';
import { IUserData } from '../utils/general';
import { useTextureLoader } from '../hooks/useTextureLoader';
import * as THREE from 'three';
import { useSRGBTexture } from '../hooks/useSRGBTexture';

const Image: FunctionComponent<IImage & ISharedHOCProps> = ({
	scale,
	imageUrl,
	opacity = 1,
	hasAlpha = false,
	position,
	rotation,
	onPointerUp,
	onPointerMove,
	onPointerDown,
	onDoubleClick,
	renderOrder,
	borderRadius: radius = 0,
	borderRgba: brgba = [255, 0, 0, 1],
	borderWidth: bw = 0,
	id = '',
}) => {
	let txtr = useTextureLoader(TextureLoader, imageUrl || '', true);
	const texture = useSRGBTexture(txtr);
	const zeroPos = useMemo(() => [0, 0, 0] as ITuple3, []);
	const userData: IUserData = useMemo(() => ({renderOrder, contentId: id}), [id, renderOrder])

	return (
		<group
			key={'image_group'}
			userData={userData}
			// renderOrder={renderOrder}
			position={position as ITuple3}
			rotation={rotation as ITuple3}
			onPointerUp={onPointerUp || undefined}
			onPointerDown={onPointerDown || undefined}
			onPointerMove={onPointerMove || undefined}
			onDoubleClick={onDoubleClick || undefined}
		>
			<RoundedRectWithBorder
				key={'img_bkgr'}
				scale={scale as ITuple3}
				radius={radius}
				position={zeroPos}
				opacity={opacity}
				hasAlpha={hasAlpha}
				texture={texture}
				depthWrite={false}
				renderOrder={renderOrder}
				borderWidth={bw}
				borderRgba={brgba as ITuple4}
			/>
		</group>
	);
};

Image.displayName = 'Image';
export default memo(Image);
