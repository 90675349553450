import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const expandIcon: React.SFC<IIconParentProps> = ({
	hexFill: fill,
	viewBox,
	className,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
<svg
			onClick={onClick}
			className={className || ''}
			width="20px"
			height="20px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 20 20`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
  <rect id="Rectangle_72" data-name="Rectangle 72" width="20" height="20" fill="none"/>
  <g id="Group_24" data-name="Group 24" transform="translate(20 20) rotate(180)">
    <path id="Dropdown_Arrow_Copy_3" data-name="Dropdown Arrow Copy 3" d="M.572,0,.521,0,.5,0a.465.465,0,0,0-.34.156A.488.488,0,0,0,.009.412L0,.5C0,.511,0,.522,0,.533S0,.56,0,.573V6.428a.564.564,0,0,0,.412.563L.5,7A.546.546,0,0,0,1,6.428V1H6.427A.563.563,0,0,0,6.991.588L7,.5A.547.547,0,0,0,6.427,0Z" transform="translate(0 0)" fill={hexFill}/>
    <path id="Line" d="M.163.163A.556.556,0,0,1,.871.1L.948.163,6,5.214a.556.556,0,0,1-.709.85L5.214,6,.163.948A.556.556,0,0,1,.163.163Z" transform="translate(0 0)" fill={hexFill}/>
  </g>
  <path id="Rectangle_67" data-name="Rectangle 67" d="M0-.573a.564.564,0,0,0,.412.564L.5,0A.546.546,0,0,0,1-.573V-6.427a.564.564,0,0,0-.412-.564L.5-7a.546.546,0,0,0-.5.573Z" transform="translate(0 7)" fill={hexFill}/>
  <path id="Rectangle_67-2" data-name="Rectangle 67" d="M6.427,0a.564.564,0,0,0,.564-.412L7-.5A.546.546,0,0,0,6.427-1H.573a.564.564,0,0,0-.564.412L0-.5A.546.546,0,0,0,.573,0Z" transform="translate(0 1)" fill={hexFill}/>
  <path id="Path" d="M.163.163A.556.556,0,0,1,.871.1L.948.163,6,5.214a.556.556,0,0,1-.709.85L5.214,6,.163.948A.556.556,0,0,1,.163.163Z" fill={hexFill}/>
  <path id="Rectangle" d="M8.75,10H1.25A1.252,1.252,0,0,1,0,8.75V1.25A1.252,1.252,0,0,1,1.25,0h7.5A1.252,1.252,0,0,1,10,1.25v7.5A1.252,1.252,0,0,1,8.75,10ZM1,1V9H9V1Z" transform="translate(5 5)" fill={hexFill}/>
</svg>
	);
};

export default expandIcon;
