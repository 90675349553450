import React, { useEffect, useState } from 'react';
import {
	BackwardsIcon,
	ForwardsIcon,
	PlayIcon,
	PauseIcon,
	PlayHighlightIcon,
	PauseHighlightIcon,
} from '../../../../../assets/icons';
import { secondsToTimeString } from '../utils';
import { IPlayerStatus } from '../AudioPlayer';

import CSS from './AudioControls.scss';

interface IParentProps {
	audio: HTMLAudioElement;
	status: IPlayerStatus;
	time: number;
	changeStatus: (s: IPlayerStatus) => any;
	changeTime: (t: number) => any;
}

const AudioControls: React.SFC<IParentProps> = ({
	audio,
	time,
	status,
	changeStatus,
	changeTime,
}) => {
	if (!audio) return null;
	const isPlaying = status === IPlayerStatus.playing;
	const timeLeft = Math.round(audio.duration) - time;
	const [controlHovered, setControlHovered] = useState<boolean>(false);
	const [backIconCol, setBackIconCol] = useState<string>(null);
	const [forwIconCol, setForwIconCol] = useState<string>(null);

	let Icon = PlayIcon;
	if (isPlaying && controlHovered) Icon = PauseHighlightIcon;
	else if (isPlaying && !controlHovered) Icon = PauseIcon;
	else if (!isPlaying && controlHovered) Icon = PlayHighlightIcon;
	else if (isPlaying && !controlHovered) Icon = PlayIcon;

	return (
		<div className={CSS.ButtonRow}>
			<div className={CSS.TimeContainerLeft}>
				<span>{secondsToTimeString(time)}</span>
			</div>
			<div className={CSS.MainControlDiv}>
				<span
					onMouseEnter={() => setBackIconCol('#4a90e2')}
					onMouseLeave={() => setBackIconCol(null)}
				>
					<BackwardsIcon
						hexFill={backIconCol}
						onClick={() => {
							audio.pause();
							changeStatus(IPlayerStatus.paused);
							changeTime(0);
							audio.currentTime = 0;
						}}
					/>
				</span>

				<div className={CSS.OuterIconContainer}>
					<div
						className={CSS.InnerIconContainer}
						onMouseEnter={() => setControlHovered(true)}
						onMouseLeave={() => setControlHovered(false)}
					>
						<Icon
							onClick={() => {
								changeStatus(
									isPlaying ? IPlayerStatus.paused : IPlayerStatus.playing
								);
								if (isPlaying) audio.pause();
								else audio.play();
							}}
						/>
					</div>
				</div>
				<span
					onMouseEnter={() => setForwIconCol('#4a90e2')}
					onMouseLeave={() => setForwIconCol(null)}
				>
					<ForwardsIcon
						hexFill={forwIconCol}
						onClick={() => {
							audio.pause();
							changeStatus(IPlayerStatus.paused);
							audio.currentTime = audio.duration - 0.01;
							changeTime(audio.duration - 0.01);
						}}
					/>
				</span>
			</div>
			<div className={CSS.TimeContainerRight}>
				<span>
					{isNaN(timeLeft) ? '../..' : `-${secondsToTimeString(timeLeft)}`}
				</span>
			</div>
		</div>
	);
};

export default AudioControls;
