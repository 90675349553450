import React, {
	FunctionComponent,
	memo,
	useMemo,
} from 'react';
import DreiText from './DreiText';
import RoundedRectWithBorder from './RoundedRectWithBorder';
import {
	ITuple3,
	ITuple4,
	FONT_TYPES,
	IFontTypes,
	ITextAlignment,
	IText,
} from '../component-data-structure';
import { ISharedHOCProps } from '../types';
import { IUserData } from '../utils/general';
import { useSRGBColors } from '../hooks/useSRGBColors';

const TextComp: FunctionComponent<IText & ISharedHOCProps> = ({
	scale: s,
	color,
	text,
	fontFamily = IFontTypes.Roboto,
	fontSize = 2,
	fontRgba,
	position,
	rotation,
	textAlignment = ITextAlignment.left,
	onPointerUp,
	onPointerMove,
	onPointerDown,
	onDoubleClick, 
	onPointerOver,
	onPointerOut,
	renderOrder,
	id = '',
}) => {
	// memoize reference type props to avoid wasted renders
	const scale = useMemo(() => s as ITuple3, [s]);
	const textPos = useMemo(() => [-s[0] / 2, s[1] / 2, 0] as ITuple3, [s])
	const userData: IUserData = useMemo(() => ({renderOrder, contentId: id}), [id, renderOrder])
	const filteredArray = useMemo(
		() => FONT_TYPES.filter((font) => font.fontFamily === fontFamily),
		[fontFamily]
	);
	const fontColor = useSRGBColors(fontRgba)

	if (!filteredArray.length) return null;

	return (
		<group
			key={'btn_group'}
			userData={userData}
			// renderOrder={renderOrder}
			position={position as ITuple3}
			rotation={rotation as ITuple3}
			onPointerUp={onPointerUp || undefined}
			onPointerDown={onPointerDown || undefined}
			onPointerMove={onPointerMove || undefined}
			onDoubleClick={onDoubleClick || undefined}
		>
			<RoundedRectWithBorder
				name={id}
				key={'text_entity_bkgr'}
				scale={scale}
				radius={0}
				position={[0, 0, 0]}
				fillRgba={color as ITuple4}
				opacity={color[3]}
				renderOrder={renderOrder}
				depthWrite={false}
			/>
			<DreiText
				key={'text_entity'}
				color={fontColor}
				alpha={fontRgba[3]}
				fontSize={fontSize}
				position={textPos}
				url={filteredArray[0]?.url}
				textAlign={textAlignment}
				anchorY={'top'}
				anchorX={'left'} // always left side at btn x center
				maxWidth={s[0]}
				depthWrite={false}
				renderOrder={renderOrder || 0 + 2}
				onPointerOver={onPointerOver || undefined}
				onPointerOut={onPointerOut || undefined}
			>
				{text}
			</DreiText>
		</group>
	);
};

export default memo(TextComp);
