import React from 'react';
import { TickIcon } from '../../../assets/icons';

import CSS from './Checkbox.scss';

interface IParentProps {
	onChange: (checked: boolean) => any;
	checked: boolean;
	label?: string;
}

const Checkbox: React.SFC<IParentProps> = ({ onChange, checked, label }) => {
	let classes = [CSS.Checkbox];
	if (checked) classes.push(CSS.Active);
	return (
		<>
			<div className={classes.join(' ')} onClick={() => onChange(!checked)}>
				{checked && <TickIcon hexFill={'#FFFFFF'} />}
			</div>
			{label && <span className={CSS.ChkbxLabel} onClick={() => onChange(!checked)}>{label}</span>}
		</>
	);
};

export default Checkbox;
