import React, { useRef, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { connect } from 'react-redux';
import { IDesignerState, IDomClassSelector, IDomIdSelectors, IToastType } from '../../../../../typings';
import SceneCarousel from './SceneCarousel/SceneCarousel';
import ButtonRow from './ButtonRow/ButtonRow';
import uuid4 from 'uuid/v4';
import { bindActionCreators } from 'redux';
import {
	onSetEnlargeSceneMenu,
	IOnSetEnlargeSceneMenu,
	IOnSetSceneSnapshots,
	IOnSetActiveScene,
	onSetSceneSnapshots,
	onSetActiveScene,
	onAddToast,
	IOnAddToast,
} from '../../../../store/actions';
import {
	getSnapshotDictFromLStorage,
	updateLStorageWithSnapshot,
} from '../../../../utils';
import {getRootComponent} from '../../../../store/selectors';
import { ToastsData } from '../../../../utils/toasts-data'; 

import CSS from './SceneMenu.scss';

interface IReduxProps {
	activeSceneId: string;
	sceneMenuIsLarge: boolean;
	socketHasConnected: boolean;
	sceneIds: string[];
	projectId: string;
	canvasIsLoading: boolean;
}
interface IDispatchProps {
	onSetEnlargeSceneMenu: IOnSetEnlargeSceneMenu;
	onSetSceneSnapshots: IOnSetSceneSnapshots;
	onSetActiveScene: IOnSetActiveScene;
	onAddToast: IOnAddToast;
}

const SceneMenu: React.SFC<IReduxProps & IDispatchProps> = ({
	activeSceneId,
	sceneMenuIsLarge,
	onSetEnlargeSceneMenu,
	onSetSceneSnapshots,
	onSetActiveScene,
	socketHasConnected,
	sceneIds,
	projectId,
	canvasIsLoading,
	onAddToast,
}) => {
	const menuEl = useRef(null);
	const [hasRendered, setHasRendered] = useState<boolean>(false);
	const rightMenu = 272; //TODO: get it dynamically
	const windowWidth = window.innerWidth; 

	useEffect(() => {
		if(!sceneIds.includes(activeSceneId) && !canvasIsLoading) {
			onSetActiveScene({ activeSceneId: sceneIds[0] });
			onSetEnlargeSceneMenu(true);
		}	
	},[sceneIds, onSetActiveScene, onSetEnlargeSceneMenu]);
	
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {			
			const undoPressed = (event?.target as any)?.id === IDomIdSelectors.undo;
			const redoPressed = (event?.target as any)?.id === IDomIdSelectors.redo;
			const popupMenu = document.getElementsByClassName(IDomClassSelector.scenePopupMenu)[0]; 
			if (menuEl?.current?.contains(event.target) ||  popupMenu?.contains(event.target as Node) || undoPressed || redoPressed) return; 
			onSetEnlargeSceneMenu(false);
		}
		document.addEventListener('pointerdown', handleClickOutside);
		return () => document.removeEventListener('pointerdown', handleClickOutside);
	}, [onSetEnlargeSceneMenu]);

	useEffect(() => {
		if (!hasRendered && socketHasConnected) {
			if (sceneIds.length) {
				const sceneId = activeSceneId || sceneIds[0];
				onSetActiveScene({ activeSceneId: sceneId });
			}
		}
	}, [activeSceneId, socketHasConnected, hasRendered, sceneIds])

	useEffect(() => {
		if (!hasRendered && socketHasConnected && !canvasIsLoading) {
			if (!sceneIds.length) {
				const sceneId = uuid4();
				onSetActiveScene({ activeSceneId: sceneId });
				updateLStorageWithSnapshot(sceneId, projectId);
				onSetSceneSnapshots(getSnapshotDictFromLStorage(localStorage, projectId));
			}
			setHasRendered(true);
		}


	}, [hasRendered, socketHasConnected, canvasIsLoading]);

	const [showSceneChangeArrow, setShowSceneChangeArrow] = useState(false);
	useEffect(() => {	
		const extraPixels = 10; 
		const menuWidth = menuEl.current.getBoundingClientRect().width + extraPixels;

		if(menuWidth > (windowWidth - rightMenu)){
			setShowSceneChangeArrow(true);
		}else{
			setShowSceneChangeArrow(false);
		}
		
	}, [sceneIds, sceneMenuIsLarge]);

	return (
		<div className={CSS.OuterContainer}>
				<div
					ref={menuEl}
					className={`${CSS.SceneMenu} ${
						sceneMenuIsLarge ? CSS.EnlargeMenu : ''
					}`}
				>
					<Sentry.ErrorBoundary fallback={null} onError={() => onAddToast(ToastsData.GeneralError)
					}>
						{socketHasConnected ? <>
							<SceneCarousel show={sceneMenuIsLarge} />
							<ButtonRow 
								showSceneChangeArrow={showSceneChangeArrow}
							/>
						</> : null}
					</Sentry.ErrorBoundary>
				</div>
		
		</div>
	);
};

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	return {
		activeSceneId: state.userReducer.activeSceneId,
		sceneMenuIsLarge: state.userReducer.sceneMenuIsLarge,
		socketHasConnected: state.userReducer.socketHasConnected,
		sceneIds: getRootComponent(state)?.children || [],
		projectId: state.userReducer.project.id,
		canvasIsLoading: !state.userReducer.canvasLoaded,
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onSetSceneSnapshots,
			onSetActiveScene,
			onAddToast,
			onSetEnlargeSceneMenu,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(SceneMenu);
