import React, {FunctionComponent, useEffect} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { 
	IActionCategory, 
	ITriggerTypes 
} from '../../../../../../r3f/r3f-components/component-data-structure';
import { 
	onSetActionData_Global, 
	IOnSetActionData_Global 
} from '../../../../../../../store/actions';
import { NoActionPlaceholderUrl } from '../../../../../../../assets/icons';

import CSS from './NoAction.scss';
interface IParentProps {
	selectedEntityIds: string[];
}
interface IDispatchProps {
	onSetActionData_Global: IOnSetActionData_Global;
}

const NoAction: FunctionComponent<IParentProps & IDispatchProps> = ({
	selectedEntityIds, 
	onSetActionData_Global
}) => {
	useEffect(() => {
		onSetActionData_Global({
			ids: selectedEntityIds,
			triggerType: ITriggerTypes.onTap,
			selectedActionCategory: IActionCategory.noAction,
		});
	}, [])

	return (
		<div className={CSS.FlexContainer}>
			<img src={NoActionPlaceholderUrl} />
		</div>
	);
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onSetActionData_Global
		},
		dispatch
	);
};

export default connect(null, mapDispatchToProps)(NoAction);