// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DummyPreview--Ad9Zy{background-color:#f9f9f9}.SearchbarDummy--2ofZ5,.UploadBtnDummy--1cm9q{width:100%;background-color:#f9f9f9;height:40px;border-radius:5px;padding:10px;width:100%;margin-bottom:8px}.DummyGrid--3LEjN{display:grid;column-gap:8px;row-gap:16px}@keyframes placeHolderShimmer--22PoK{0%{background-position:-800px 0}100%{background-position:800px 0}}.FadeGradient--1y1h2{position:absolute;left:0;bottom:-25px;height:100%;width:100%;z-index:1;background-image:linear-gradient(rgba(255, 255, 255, 0), white)}.Skeleton--3OkP0{position:relative;height:100%}.LightShimmer--3yTw-{height:100%;width:100%;position:absolute;top:0;left:0;z-index:2;animation-duration:2s;animation-fill-mode:forwards;animation-iteration-count:infinite;animation-name:placeHolderShimmer--22PoK;animation-timing-function:linear;background-image:linear-gradient(to right, transparent 0%, #ffffff 20%, transparent 40%, transparent 100%);background-repeat:no-repeat}", ""]);
// Exports
exports.locals = {
	"DummyPreview": "DummyPreview--Ad9Zy",
	"SearchbarDummy": "SearchbarDummy--2ofZ5",
	"UploadBtnDummy": "UploadBtnDummy--1cm9q",
	"DummyGrid": "DummyGrid--3LEjN",
	"FadeGradient": "FadeGradient--1y1h2",
	"Skeleton": "Skeleton--3OkP0",
	"LightShimmer": "LightShimmer--3yTw-",
	"placeHolderShimmer": "placeHolderShimmer--22PoK"
};
module.exports = exports;
