// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HeaderContainer--yrcTH{width:250px;font-weight:300;margin-bottom:18px;font-size:16px;display:flex;align-items:center;justify-content:space-between;opacity:0;transition:opacity .5s}.HeaderContainer--yrcTH.FadeIn--1HX4_{opacity:1}.Cross--eBgdu{cursor:pointer;width:12px;height:12px}.HelpIcon--1JLIA{width:14px;height:14px;margin-left:8px;cursor:pointer}.FlexRow--3KfW7{display:flex;align-items:center}", ""]);
// Exports
exports.locals = {
	"HeaderContainer": "HeaderContainer--yrcTH",
	"FadeIn": "FadeIn--1HX4_",
	"Cross": "Cross--eBgdu",
	"HelpIcon": "HelpIcon--1JLIA",
	"FlexRow": "FlexRow--3KfW7"
};
module.exports = exports;
