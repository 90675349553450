import React, { useState, FunctionComponent } from 'react';
import * as Sentry from '@sentry/react';
import Divider from '../Divider/Divider';
import { GhostButton } from '../buttons';
import TeamView from '../Header/TeamView/TeamView';
import { ArrowLeftBigIcon } from '../../../assets/icons/index';
import Spinner from '../../../assets/spinner';
import { zwClient } from '../../../syncdoc';
import PreviewModal from '../modals/PreviewModal/PreviewModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IDesignerState } from '../../../../typings';
import { ToastsData } from '../../../utils/toasts-data'; 
import { onAddToast, onSetPublishStatus_Ed_Doc, IOnSetPublishStatus_Ed_Doc, IOnAddToast } from '../../../store/actions';
import PublishInfo from './PublishInfo/PublishInfo';
import { getProjectsUrl } from '../../../utils/general';
import { ITrackingUnionState_Ed_Doc, IPublishStatus, ITrackingTypes, IImageTrackingState_Ed_Doc } from '../../r3f/r3f-components/component-data-structure';
import { store } from '../../../index';
import { Tools } from 'zw-api-client/src/specs';
import PublishBtn from '../upload/PublishBtn/PublishBtn';
import { JUTE_VERSION } from '../../../settings';

import CSS from './Header.scss';

interface IReduxProps {
	userId: string;
	projectTitle: string;
	projectId: string;
	tracking: ITrackingUnionState_Ed_Doc;
	publishStatus: IPublishStatus;
	socketHasConnected: boolean;
}

interface IDispatchProps {
	onAddToast: IOnAddToast;
	onSetPublishStatus_Ed_Doc: IOnSetPublishStatus_Ed_Doc;
}

const Header: FunctionComponent<IReduxProps & IDispatchProps> = ({
	projectTitle,
	publishStatus,
	tracking,
	projectId,
	onAddToast,
	socketHasConnected,
}) => {
	const [showPreview, setShowPreview] = useState<boolean>(false);
	const [loadingPreview, setLoadingPreview] = useState<boolean>(false);
	const [previewCodeSrc, setPreviewCodeSrc] = useState<string>('');
	
	return (
		<div className={CSS.Header}>
			<Sentry.ErrorBoundary fallback={null} onError={() => onAddToast(ToastsData.GeneralError)
			}>
				{socketHasConnected ? (
					<>
						<div className={CSS.LeftSideDiv}>
						<a 
							className={CSS.OverviewLink}
							href={`${getProjectsUrl()}/${projectId}`}> 
							<ArrowLeftBigIcon />
							<div className={CSS.ProjectTitle}>
								{projectTitle}
							</div>				
						</a>
						<PublishInfo publishData={publishStatus} />
						</div>
						<div className={CSS.RightSideDiv}>
							<a
								className={CSS.Feedback}
								href={'https://docs.google.com/forms/d/e/1FAIpQLSetTr7jw1KpR4EsAShsHTD-bn8LlRk2QcpXaJUxMqbuJYZ8kw/viewform'}
								target="_blank">
								Send Feedback
							</a>
							<Divider height="25px" />
							<TeamView className={CSS.TeamView} />
							<Divider height="25px" />

							{loadingPreview ? (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										width: '108px',
										marginLeft: '32px',
										marginRight: '16px',
									}}
								>
									<Spinner />
								</div>
							) : (
								<GhostButton
									className={(!tracking?.type ? `${CSS.AppearDisabled} ${CSS.PreviewBtn}` : CSS.PreviewBtn)}
									onClick={async () => {
										if (!tracking?.type) {
											onAddToast(ToastsData.PreviewNoTrkImgError);
											return;
										}
										setLoadingPreview(true);
										let previewResult: undefined | Tools.UAR.PreviewResponse;
										const previewData = store.getState().contentReducer.contentDoc as any;
                    					previewData.juteVersion = JUTE_VERSION;
										try {
											previewResult = await zwClient.preview<Tools.UAR.PreviewResponse>(
												projectId,
												previewData
											);
										} catch (err) { // TODO: send error to Sentry
											onAddToast(ToastsData.PreviewError)
											return
										}
										if (typeof previewResult === undefined) {
											onAddToast(ToastsData.PreviewError)
											return
										}
										setPreviewCodeSrc(previewResult.qrUrl);
										setShowPreview(true);
										setLoadingPreview(false);
									}}
								>
									Preview
								</GhostButton>
							)}

							{showPreview && tracking?.type === ITrackingTypes.image && (
								<PreviewModal
									projectTitle={projectTitle}
									previewCodeSrc={previewCodeSrc}
									targetThumbnailUrl={(tracking as IImageTrackingState_Ed_Doc)?.url || null}
									onClose={() => setShowPreview(false)}
								/>
							)}
							<PublishBtn />
						</div>
					</>
				) : null}
			</Sentry.ErrorBoundary>
			
		</div>
	);
};


const mapStateToProps = (state: IDesignerState): IReduxProps => {
	return {
		userId: state.userReducer.userId,
		tracking: state.editorReducer.editorDoc.tracking,
		projectTitle: state.userReducer.project.title,
		projectId: state.userReducer.project.id,
		publishStatus: state.editorReducer.editorDoc.publishStatus,
		socketHasConnected: state.userReducer.socketHasConnected,
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onAddToast,
			onSetPublishStatus_Ed_Doc,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
