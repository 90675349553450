import React, { FunctionComponent, useMemo } from 'react';
import { ITuple3, ITuple4 } from '../../component-data-structure';
import PlaceholderEntity from './PlaceholderEntity';
import * as Sentry from '@sentry/react'
import { ThreeEvent } from '@react-three/fiber';
import BrokenIcon from './BrokenIcon/BrokenIcon'

interface IParentProps {
	scale: ITuple3;
    rotation: ITuple3;
    position: ITuple3;
    borderWidth: number;
    borderRadius: number;
    renderOrder: number;
    children?: JSX.Element | JSX.Element[];
    onError?: (error: Error, componentStack: string, eventId: string) => any;
    onPointerUp?: (e: ThreeEvent<PointerEvent>) => any;
    onPointerDown?: (e: ThreeEvent<PointerEvent>) => any;
}

const ErrorBoundary: FunctionComponent<IParentProps> = ({ 
	scale,
    rotation,
    position,
    borderWidth,
    borderRadius,
    renderOrder,
    children,
    onPointerUp,
    onPointerDown,
    onError
}) => {

    const memo = useMemo(() => ({
        scale,
        rotation,
        position,
        fillRgba: [227, 227, 227, 1] as ITuple4,
        borderRgba: [181, 181, 181, 1] as ITuple4,
    }), [scale, rotation, position]) 


    return (
        <Sentry.ErrorBoundary
            fallback={
                <PlaceholderEntity
                    rotation={memo.rotation}
                    fillRgba={memo.fillRgba}
                    scale={memo.scale}
                    borderWidth={borderWidth}
                    borderRgba={memo.borderRgba}
                    borderRadius={borderRadius}
                    position={memo.position}
                    renderOrder={renderOrder}
                    onPointerDown={onPointerDown || undefined}
                    onPointerUp={onPointerUp || undefined}
                >
                    <BrokenIcon 
                        scale={memo.scale}
                        renderOrder={renderOrder + 2}
                    />
                </PlaceholderEntity>
            } 
            onError={(e, compStack, eventId) => {
                onError?.(e, compStack, eventId)
            }}
        >
           {children}
        </Sentry.ErrorBoundary>
    )
};

export default ErrorBoundary;


{/* <SvgMesh
    name={''}
    color={'#d9d9d9'}
    svgUrl={BrokenImage}
    opacity={1}
    position={mIcon.position}
    scale={[0.001, 0.001, 0]}
    renderOrder={renderOrder + 1}
    depthWrite={false}
/> */}