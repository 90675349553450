// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ButtonRow--2yWEZ{width:60px;display:flex;position:absolute;left:0;top:0;margin-top:8px;flex-direction:column;align-items:center;justify-content:center;z-index:1;background-color:#fff;overflow:hidden}.ButtonRow--2yWEZ span{margin-top:8px}.BtnContainer--1oHyI{display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:14px;font-family:lato,sans-serif;font-weight:300;height:100px;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"ButtonRow": "ButtonRow--2yWEZ",
	"BtnContainer": "BtnContainer--1oHyI"
};
module.exports = exports;
