import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const soundIconSmall: React.SFC<IIconParentProps> = ({
	hexFill: fill,
	viewBox,
	className,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="20px"
			height="20px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 20 20`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
  <g id="Group_2" data-name="Group 2">
    <rect id="Rectangle_68" data-name="Rectangle 68" width="20" height="20" fill="none"/>
    <path id="Line_Copy" data-name="Line Copy" d="M.455,0A.469.469,0,0,1,.9.41L.909.5v11a.479.479,0,0,1-.455.5.469.469,0,0,1-.447-.41L0,11.5V.5A.479.479,0,0,1,.455,0Z" transform="translate(2.727 4)" fill={hexFill}/>
    <path id="Line_Copy_7" data-name="Line Copy 7" d="M.455,0A.469.469,0,0,1,.9.41L.909.5v7A.479.479,0,0,1,.455,8a.469.469,0,0,1-.447-.41L0,7.5V.5A.479.479,0,0,1,.455,0Z" transform="translate(0 6)" fill={hexFill}/>
    <path id="Line_Copy_6" data-name="Line Copy 6" d="M.455,0A.469.469,0,0,1,.9.41L.909.5v7A.479.479,0,0,1,.455,8a.469.469,0,0,1-.447-.41L0,7.5V.5A.479.479,0,0,1,.455,0Z" transform="translate(19.091 6)" fill={hexFill}/>
    <path id="Line_Copy_5" data-name="Line Copy 5" d="M.455,0A.469.469,0,0,1,.9.41L.909.5v11a.479.479,0,0,1-.455.5.469.469,0,0,1-.447-.41L0,11.5V.5A.479.479,0,0,1,.455,0Z" transform="translate(16.364 4)" fill={hexFill}/>
    <path id="Line_Copy_4" data-name="Line Copy 4" d="M.455,0A.469.469,0,0,1,.9.41L.909.5v19a.479.479,0,0,1-.455.5.469.469,0,0,1-.447-.41L0,19.5V.5A.479.479,0,0,1,.455,0Z" transform="translate(13.636)" fill={hexFill}/>
    <path id="Line_Copy_2" data-name="Line Copy 2" d="M.455,0A.469.469,0,0,1,.9.41L.909.5v7A.479.479,0,0,1,.455,8a.469.469,0,0,1-.447-.41L0,7.5V.5A.479.479,0,0,1,.455,0Z" transform="translate(8.182 6)" fill={hexFill}/>
    <path id="Line_Copy_3" data-name="Line Copy 3" d="M.455,0A.469.469,0,0,1,.9.41L.909.5v14a.479.479,0,0,1-.455.5.469.469,0,0,1-.447-.41L0,14.5V.5A.479.479,0,0,1,.455,0Z" transform="translate(10.909 3)" fill={hexFill}/>
    <path id="Line_Copy_9" data-name="Line Copy 9" d="M.455,0A.469.469,0,0,1,.9.41L.909.5v14a.479.479,0,0,1-.455.5.469.469,0,0,1-.447-.41L0,14.5V.5A.479.479,0,0,1,.455,0Z" transform="translate(5.455 3)" fill={hexFill}/>
  </g>
</svg>
	);
};

export default soundIconSmall;
