// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../base-variables.scss");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".OuterContainer--pMxMG {\n  position: absolute;\n  left: $entity-menu-width;\n  top: 68px;\n  z-index: 2;\n  width: calc(100% - 272px - $entity-menu-width);\n  height: fit-content;\n  display: flex;\n  align-items: flex-end;\n  justify-content: center;\n  pointer-events: none;\n  > .ContextMenu--LKriV {\n    width: fit-content;\n    height: 36px;\n    border-radius: 8px;\n    z-index: 998;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-evenly;\n    right: calc((100vw - 272px) / 2);\n    box-shadow: 0px 3px 6px #00000029;\n    background-color: #ffffff;\n    pointer-events: auto;\n    padding: 0 5px 0 5px;\n    > .ContextMenuItem--3DIyN {\n      width: 20px;\n      height: 20px;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    }\n  }\n}\n\n.ContextMenuButton--p-6XR {\n  margin: 0 5px 0 5px;\n  display: block;\n  svg {\n    display: block;\n    height: 20px;\n    width: 20px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"OuterContainer": "OuterContainer--pMxMG",
	"ContextMenu": "ContextMenu--LKriV",
	"ContextMenuItem": "ContextMenuItem--3DIyN",
	"ContextMenuButton": "ContextMenuButton--p-6XR"
};
module.exports = exports;
