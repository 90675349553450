import React, { useRef } from 'react';

import CSS from './MenuBtn.scss';

interface IParentProps {
	children?: any;
	onClick?: (
		btnRef: React.MutableRefObject<HTMLButtonElement>,
		id: string | undefined,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => any;
	onPointerUp?: (e: React.PointerEvent<HTMLButtonElement>) => any;
	disabled?: boolean;
	style?: any;
	id?: string;
	isActive?: boolean;
	classNames?: string[] | string;
	tabIndex?: number; 
}

const Btn = ({
	onClick,
	disabled,
	children,
	style,
	id,
	classNames,
	onPointerUp,
	tabIndex, 
}: IParentProps) => {
	let btnRef: React.MutableRefObject<HTMLButtonElement> = useRef(null);
	let classes = classNames || [];
	if (typeof classes === 'string') {
		classes = [classes, CSS.Btn];
	} else classes = [...classes, CSS.Btn];

	return (
		<button
			ref={btnRef}
			className={classes.join(' ')}
			style={{
				opacity: disabled ? '0.3' : '1',
				...style,
			}}
			disabled={disabled}
			onClick={e => {
				e.stopPropagation();
				onClick?.(btnRef, id, e);
			}}
			onPointerUp={e => {
				e.stopPropagation();
				onPointerUp?.(e);
			}}
			tabIndex={tabIndex}
		>
			{children}
		</button>
	);
};

export default Btn;
