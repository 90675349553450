import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const searchIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="17px"
			height="16px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 17 16'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Molecule-/-Search-projects"
					transform="translate(-426.000000, -12.000000)"
					stroke={hexFill}
				>
					<g id="Group-12">
						<g id="Icon" transform="translate(426.937685, 12.000000)">
							<line
								x1="11.7662737"
								y1="11.8397759"
								x2="15.4416671"
								y2="15.3045177"
								id="Line-4"
								strokeLinecap="round"
							></line>
							<circle id="Oval" cx="6.4" cy="6.4" r="5.9"></circle>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default searchIcon;
