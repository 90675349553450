import { IMediaData, IImageData, IVideoData, IFileType } from './specs'

export const addInterfaceToMediaData = (mediaData: IMediaData) => {
    switch (mediaData.type) {
        case IFileType.Image:
            return mediaData as IImageData;
        case IFileType.Hls:
            return mediaData as IVideoData;
        default:
            return mediaData as IMediaData;
    }
}

export const getContentTypeFromIFileType = (t: IFileType): string => {
    switch (t) {
        case IFileType.UARPackage:
            return 'application/zip';
        default:
            return t
    }
}

export const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
}
