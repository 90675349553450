import {
	ITriggerTypes,
	IDialOptions,
	ISoundOptions,
	IEmailOptions,
	IContactOptions,
	ISocialProvider,
	ITrackingUnionState_Ed_Doc,
	IVector3,
	IVector4,
	ITargetDisplayInfo_Ed_Doc
} from '../index';
import { IProjectColor } from './general';

export interface IEditorDoc {
	version: number;
	createdAt: number;
	tracking?: ITrackingUnionState_Ed_Doc;
	targetDisplayInfo: ITargetDisplayInfo_Ed_Doc;
	publishStatus: IPublishStatus | null;
	users: { [id: string]: IUserData };
	mp4Url: { [id: string]: string }; // mp4Urls by component ID
	entityIdToUserId: { [id: string]: string };
	positions: { [id: string]: IVector3 };
	rotations: { [id: string]: IVector3 };
	rgbaColors: { [id: string]: IVector4 };
	fontRgba: { [id: string]: IVector4 };
	text: { [id: string]: string };
	borderRgba: { [id: string]: IVector4 };
	scales: { [id: string]: IVector3 };
	titles: { [id: string]: string };
	isLocked: { [id: string]: boolean };
	aspectRatioLocked: { [id: string]: boolean };
	scalesInverted: { [id: string]: boolean[] };
	originalAspectRatio: { [id: string]: number };
	projectColors: IProjectColor[];
	textScale: { [id: string]: IVector3 };
	zmlFileId: { [id: string]: string };
	activeTriggers: { [id: string]: ITriggerTypes };
	actions: { [id: string]: { [triggerType: string]: IEditorActionData } };
	editingDisabled: { [id: string]: boolean };
}

export interface IUserData {
	id: string;
	name: string;
	avatarUrl: string | null;
	profileUrl?: string;
}

export interface IPublishStatus {
	status: IPublishTypes;
	dateString: string;
}

export enum IPublishTypes {
	published = 'published',
	unpublishedChanges = 'unpublishedChanges',
	notPublished = 'notPublished',
	unpublished = 'unpublished',
}

export enum IActionCategory {
	noAction = 'noAction',
	linkSocial = 'linkSocial',
	linkPage = 'linkPage',
	linkScene = 'linkScene',
	dialNumber = 'dialNumber',
	composeEmail = 'composeEmail',
	saveContact = 'saveContact',
	playSound = 'playSound',
	enlarge = 'enlarge',
}

export enum IBtnAndTxtActionCategory {
	noAction = 'noAction',
	linkSocial = 'linkSocial',
	linkPage = 'linkPage',
	linkScene = 'linkScene',
	dialNumber = 'dialNumber',
	composeEmail = 'composeEmail',
	saveContact = 'saveContact',
	playSound = 'playSound',
}

export interface IEditorActionData {
	selectedActionCategory?: IActionCategory;
	dialOptions?: IDialOptions;
	soundOptions?: ISoundOptions;
	emailOptions?: IEmailOptions;
	contactOptions?: IContactOptions;
	linkedSceneId?: string | null;
	socialOptions?: ISocialOptions;
	linkPageOptions?: IPageEditorLinkOptions;
}

export interface IPageEditorLinkOptions {
	pageUrl?: string;
}

export interface ISocialOptions {
	selectedProvider?: ISocialProvider;
	[ISocialProvider.facebook]?: string;
	[ISocialProvider.instagram]?: string;
	[ISocialProvider.linkedIn]?: string;
	[ISocialProvider.twitter]?: string;
	[ISocialProvider.youtube]?: string;
	[ISocialProvider.spotify]?: string;
	[ISocialProvider.soundCloud]?: string;
	[ISocialProvider.reddit]?: string;
	[ISocialProvider.tiktok]?: string;
	[ISocialProvider.twitch]?: string;
	[ISocialProvider.vimeo]?: string;
	[ISocialProvider.custom]?: string;
}
