import {
  Settings, Environment, OAuthGrantType, EnvSettings, JSONObject,
  UserResponse, ProjectResponse, IProgressCallback, PreviewResponse, Tools
} from "../specs"
import { getSettingsForEnv, prodEnvSettings } from "../settings"
import { BaseOAuth } from "../oauth/base"
import { BaseZmlClient } from '../zml/clients/base'


export class BaseClient<T extends BaseOAuth> {
  protected readonly _oauth: T
  protected _accessToken: string | null | undefined;

  public env: Environment = Environment.Prod;
  public grantType: OAuthGrantType = OAuthGrantType.AuthorizationCode;
  public debug: boolean = false;
  public envSettings: EnvSettings = prodEnvSettings;
  public zml: BaseZmlClient<T> | undefined;

  constructor(settings: Settings, oauth: T) {
    this._oauth = oauth
    this.config(settings)
    this._oauth.setServiceConfig(this.envSettings.url)
  }

  config(settings: Settings) {
    this.env = typeof settings.env === "string" ? settings.env : this.env;
    this.grantType = typeof settings.grantType === "string" ? settings.grantType : this.grantType;
    this.envSettings = getSettingsForEnv(this.env);
    this.debug = typeof settings.debug === "boolean" ? settings.debug : this.envSettings.debug;
  }

  makeAuthorizationRequest(scope = '', state?: string) {
    this._oauth.makeAuthorizationRequest(scope, state);
  }

  checkForAuthorizationResponse() {
    return this._oauth.checkForAuthorizationResponse();
  }

  getAccessToken() {
    return this._accessToken ? this._accessToken : this._oauth.getAccessToken()
  }

  makeTokenRequest() {
    return this._oauth.makeTokenRequest()
  }

  requestWithProgress(url: string, body?: any, progressCb?: IProgressCallback, requestId: number | string = '') {
    console.error('NOT IMPLEMENTED')
    return
  }

  protected async _authenticationSetup() {
    console.error('NOT IMPLEMENTED')
    return {}
  }

  async getAPISVG(endpoint: string): Promise<Blob> {
    console.error('NOT IMPLEMENTED')
    return new Blob()
  }

  async apiRequest<T>(endpoint: string, postData?: unknown, method?:string): Promise<T> {
    console.error('NOT IMPLEMENTED')
    return {} as T
  }

  getUser() {
    return this.apiRequest<UserResponse>('/user/')
  }

  getWorkspaces() {
    return this.apiRequest<UserResponse>('/workspaces/')
  }

  getProject(id: string) {
    return this.apiRequest<ProjectResponse>(`/projects/${id}/`)
  }

  preview<T>(projectId: string, data: unknown) {
    return this.apiRequest<T>(`/projects/${projectId}/preview/`, data)
  }

  publish<T>(projectId: string, data: unknown) {
    return this.apiRequest<T>(`/projects/${projectId}/publish/`, data)
  }

  createUARVersion(projectId: string, data: JSONObject) {
    return this.apiRequest<Tools.UAR.PreviewResponse>(`/projects/${projectId}/uar-version/`, data)
  }

}
