import React from 'react';
import CSS from './Summary.scss';
import { onRemoveSelection, onSetActiveScene } from '../../../../../../../../store/actions';
import { SceneIcon } from '../../../../../../../../assets/icons';
import { useDispatch } from 'react-redux';

interface IParentProps {
	selectedSceneId: string;
	selectedSceneName: string;
	selectedEntityIds: string[];
}

const SceneSummary: React.SFC<IParentProps> = ({
	selectedSceneId,
	selectedSceneName,
	selectedEntityIds,
}) => {
	const dispatch = useDispatch();
	const onClickHandler = () => {
		dispatch(onRemoveSelection(selectedEntityIds));
		dispatch(onSetActiveScene({ activeSceneId: selectedSceneId }));
	};
	return (
		<>
			<div className={CSS.Description}>Linked to:</div>		
			<span className={CSS.SceneLink} onClick={onClickHandler}>
				<SceneIcon hexFill={'#4A90E2'} />
				<span className={CSS.LinkText}>{selectedSceneName}</span>
			</span>
		</>
	);
};

export default SceneSummary;
