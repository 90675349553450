import { useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { convertGltf } from "./useConvertGltf";
import * as utils from 'three/examples/jsm/utils/SkeletonUtils';
import { Mesh, Object3D, SkinnedMesh } from "three";

// global cache outside of react 
const globalModelSceneCache: {[url:string]: Object3D} = {};
const globalErrorCache: {[url: string]: Error} = {}

const createModelLoadPromise = (url: string, onError?: () => any): Promise<any> => {
    // fill the global model cache or model error cache if not yet filled for url
    return new Promise((res, rej) => {
        const loader = new GLTFLoader();
        loader.load(url, 
            function ( gltf ) {
                if (!globalModelSceneCache[url]) {
                    globalModelSceneCache[url] = convertGltf(gltf)
                }
                res(true)
            }, 
            undefined,
            function ( error ) {
                globalErrorCache[url] = new Error('model load error')
                // console.log('GLTF preloading error: ', error);
                onError?.();
                // rej(false)
            }
        )
    });
}

export const preloadGltf = (urls: string[]): Promise<any> => {
    const promises: Promise<any>[] = [];
    // create array of promises
    console.log('run preloadGltf')
    for (let i = 0; i < urls.length; i++) {
        const url = urls[i];
        if (globalModelSceneCache[url]) continue;
        const modelLoadPromise = createModelLoadPromise(url)
        promises.push(modelLoadPromise)   
    }
    return promises.length ? Promise.all([...promises]) : new Promise(res => res(true));
}

export const useGLTFLoader = (url: string, renderOrder?: number): Object3D => {
    // state used to trigger rerender in order to throw async errors into render phase
    const [_, triggerRender] = useState(0);
    // return model immediately if in cache
    if (globalModelSceneCache[url]) {
        const modelScene = globalModelSceneCache[url];
        // safely get skeleton utils clone method from different threejs versions
        const clone = (utils as any).clone || (utils as any).SkeletonUtils?.clone;
        // use Skeleton utils to safely clone skinned mesh (and other) models
        const clonedModelScene = clone(modelScene) as Object3D;

        // add render order to mesh if material(s) are transparent
        clonedModelScene.traverse((node: any) => {
            if ((node.isSkinnedMesh || node.isMesh) && typeof renderOrder !== 'undefined') {
                const mesh = node as Mesh | SkinnedMesh;
                const materialArray = Array.isArray(mesh.material) ? mesh.material : [mesh.material];
                let meshIsTransparent = false;
                for (let i = 0; i < materialArray.length; i++) {
                    if (materialArray[i].transparent) meshIsTransparent = true;
                }
                if (meshIsTransparent) mesh.renderOrder = renderOrder
            }
        });
        return clonedModelScene;
    }

    // if async error throw error into render phase (triggered via triggerRender)
	if (globalErrorCache[url]) {
		const error = globalErrorCache[url];
		// delete globalErrorCache[url];
		throw error;
	}


    // throw promise for suspense to catch and also trigger re-render once error 
    // occurs to throw async error in new render phase
    throw createModelLoadPromise(url, () => {
        console.log('prelading error')
        triggerRender((prevState) => prevState + 1)
    })
}

