// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ButtonRow--2Qj5i{display:flex;align-items:center;justify-content:space-between;width:100%}.ButtonRow--2Qj5i>.GhostBtn--i9YMC{margin-top:8px;width:100%;height:32px}.AudioListContainer--UF7wh{margin-top:16px}.AudioList--3zduz{margin-top:16px}.AudioNameInput--1Azk_{width:100%}.ChkbxLabel--3RNy7{margin-left:8px}.CheckBoxRow--3urTK{display:flex;align-items:center;justify-content:flex-start}.CheckBoxRow--3urTK>span{margin-right:8px}.AudioTitleContainer--FmXYT{display:flex;justify-content:space-between;border:1px solid #c1cfdf;border-radius:6px;align-items:center;position:relative}.AudioTitleContainer--FmXYT .Title--J7Gha{display:flex;align-items:center;justify-content:space-between;min-width:0;flex-grow:100;align-items:center}.AudioTitleContainer--FmXYT .TitleClose--3EnCc{position:absolute;right:0;margin-right:10px;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"ButtonRow": "ButtonRow--2Qj5i",
	"GhostBtn": "GhostBtn--i9YMC",
	"AudioListContainer": "AudioListContainer--UF7wh",
	"AudioList": "AudioList--3zduz",
	"AudioNameInput": "AudioNameInput--1Azk_",
	"ChkbxLabel": "ChkbxLabel--3RNy7",
	"CheckBoxRow": "CheckBoxRow--3urTK",
	"AudioTitleContainer": "AudioTitleContainer--FmXYT",
	"Title": "Title--J7Gha",
	"TitleClose": "TitleClose--3EnCc"
};
module.exports = exports;
