import React, { FunctionComponent, memo } from 'react';
import { useSelector } from 'react-redux';
import {
	IDesignerState,
} from '../../../../../../../typings';
import { IFileType } from 'zw-api-client/src/zml/specs';
import ZmlEntitySelection from '../shared/ZmlEntitySelection/ZmlEntitySelection';
import SubmenuHeader from '../shared/SubmenuHeader/SubmenuHeader';

interface ImageSelectionProps {
	onClose: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => any;
}

const ImageSelection: FunctionComponent<ImageSelectionProps> = ({onClose}) => {
	const loadedImages = useSelector((state: IDesignerState) => state.userReducer.loadedImages);
	
    return (
		<ZmlEntitySelection 
			loadedEntities={loadedImages} 
			columns={2} 
			fileType={IFileType.Image}
			showTitle={true} 
			onClose={onClose}
		/>
	);
};

export default memo(ImageSelection);
