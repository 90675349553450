// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TargetImageContainer--20PwX{margin:0;font-size:12px}.PreviewDiv--Cf-nV{border:1px solid #b2c4d7;border-radius:3px;height:170px;width:100%;overflow:hidden;display:flex;align-items:center;justify-content:center;margin-bottom:8px}.PreviewDiv--Cf-nV>button{width:70%}", ""]);
// Exports
exports.locals = {
	"TargetImageContainer": "TargetImageContainer--20PwX",
	"PreviewDiv": "PreviewDiv--Cf-nV"
};
module.exports = exports;
