import {
	IEditorDocActionTypes,
	ISetMultipleEntityProps_Ed_Doc_Action,
	IOnRemoveSelections_Ed_Doc_Action,
	IOnSetSelection_Ed_Doc_Action,
	IChange_Ed_Doc_Action,
	IOnRemoveEntities_Ed_Doc_Action,
	IOnAddTracking_Ed_Doc_Action,
	IOnSetProjectColors_Ed_Doc_Action,
	IOnSetPublishStatus_Ed_Doc_Action,
	IOnSetActionData_Ed_Doc_Action,
	IOnRemoveSceneReferences_Ed_Doc_Action,
	IOnSetTargetDisplayInfo_Ed_Doc_Action,
	IPasteCopiedEntities_Ed_Doc_Action,
	IOnCopyScenesAtIndex_Ed_Doc_Action,
} from '../actions/action-types';
import { IEditorReducer } from '../../../typings';
import { getSyncEditorDoc } from '../../syncdoc';
import {
	initEdDoc,
	IActionCategory,
} from '../../components/r3f/r3f-components/component-data-structure';
import { parseSceneTitle } from '../../utils';

const initialState: IEditorReducer = {
	editorDoc: { ...initEdDoc(), createdAt: 0 },
};

// conditionally update docs
const updateEditorDocFromBackend = (
	state: IEditorReducer,
	action: IChange_Ed_Doc_Action
): IEditorReducer => {
	return {
		...state,
		editorDoc: action.editorDoc,
	};
};

const removeUserSelectionsInEditorDoc = (
	state: IEditorReducer,
	action: IOnRemoveSelections_Ed_Doc_Action
): IEditorReducer => {
	const { userIds } = action.payload;
	const syncEditorDoc = getSyncEditorDoc()
	if (!syncEditorDoc) return state;
	let editorDoc = syncEditorDoc.change(doc => {
		for (let i = 0; i < userIds.length; i++) {
			const userId = userIds[i];
			for (const entityId in doc.entityIdToUserId) {
				const mappedUserId = doc.entityIdToUserId[entityId];
				if (userId === mappedUserId) doc.entityIdToUserId[entityId];
			}
		}
	});
	return {
		...state,
		editorDoc,
	};
};

const setPositionInEditorDoc = (
	state: IEditorReducer,
	action: any
): IEditorReducer => {
	const { ids, positionDict, pointerOffset } = action.payload;
	const syncEditorDoc = getSyncEditorDoc()
	if (!syncEditorDoc) return state;
	let editorDoc = syncEditorDoc.change(doc => {
		for (let i = 0; i < ids.length; i++) {
			const id = ids[i];
			doc.positions[id] = [
				positionDict[id][0] + pointerOffset[0],
				positionDict[id][1] + pointerOffset[1],
				positionDict[id][2] + pointerOffset[2],
			];
		}
	});
	return {
		...state,
		editorDoc,
	};
};

const setUserSelectionInEditorDoc = (
	state: IEditorReducer,
	action: IOnSetSelection_Ed_Doc_Action
): IEditorReducer => {
	const { entityIds, userId } = action.payload;
	const syncEditorDoc = getSyncEditorDoc()
	if (!syncEditorDoc) return state;
	let editorDoc = syncEditorDoc.change(doc => {
		for (let i = 0; i < entityIds.length; i++) {
			const entityId = entityIds[i];
			doc.entityIdToUserId[entityId] = userId;
		}
	});
	return {
		...state,
		editorDoc,
	};
};

const setScaleInEditorDoc = (
	state: IEditorReducer,
	action: any
): IEditorReducer => {
	const { id, scale } = action.payload;
	const syncEditorDoc = getSyncEditorDoc()
	if (!syncEditorDoc) return state;
	let editorDoc = syncEditorDoc.change(doc => {
		doc.scales[id] = scale;
	});
	return {
		...state,
		editorDoc,
	};
};

const setRotationInEditorDoc = (
	state: IEditorReducer,
	action: any
): IEditorReducer => {
	const { ids, rotation } = action.payload;
	const syncEditorDoc = getSyncEditorDoc()
	if (!syncEditorDoc) return state;
	let editorDoc = syncEditorDoc.change(doc => {
		for (let i = 0; i < ids.length; i++) {
			doc.rotations[ids[i]] = rotation;
		}
	});
	return {
		...state,
		editorDoc,
	};
};

const setMultipleEntityPropsInEditorDoc = (
	state: IEditorReducer,
	action: ISetMultipleEntityProps_Ed_Doc_Action
): IEditorReducer => {
	const multipleEntityProps = action.payload;
	const syncEditorDoc = getSyncEditorDoc()
	if (!syncEditorDoc) return state;
	const editorDoc = syncEditorDoc.change(doc => {
		let i = 0;
		while (i < multipleEntityProps.length) {
			const { id, userSelection, ...rest } = multipleEntityProps[i];
			for (const k in rest) {
				const key = k as keyof typeof rest;
				const value = rest[key];
				if (value === undefined) continue;
				if (!doc[key]) doc[key] = {};
				doc[key][id] = value;
			}
			i++;
		}
	});
	return {
		...state,
		editorDoc,
	};
};

const removeEntitiesInEditorDoc = (
	state: IEditorReducer,
	action: IOnRemoveEntities_Ed_Doc_Action
): IEditorReducer => {
	const { ids } = action.payload;
	if (!ids) return state;
	const syncEditorDoc = getSyncEditorDoc()
	if (!syncEditorDoc) return state;
	let editorDoc = syncEditorDoc.change(doc => {
		for (let i = 0; i < ids.length; i++) {
			const id = ids[i];
			const {
				version,
				createdAt,
				publishStatus,
				targetDisplayInfo,
				projectColors,
				tracking,
				...rest
			} = doc;
			for (const k in rest) {
				const key = k as keyof typeof rest;
				const value = rest[key];
				if (
					typeof value === 'object' &&
					value !== null &&
					!(value instanceof Array)
				)
					delete doc[key][ids[i]];
			}
		}
	});
	return {
		...state,
		editorDoc,
	};
};

const addTracking_Ed_Doc = (
	state: IEditorReducer,
	action: IOnAddTracking_Ed_Doc_Action
): IEditorReducer => {
	const syncEditorDoc = getSyncEditorDoc()
	if (!syncEditorDoc) return state;
	let editorDoc = syncEditorDoc.change(doc => {
		const trkImgState = JSON.parse(JSON.stringify(doc.tracking || {}));
		doc.tracking = { ...trkImgState, ...action.payload };
	});
	return {
		...state,
		editorDoc,
	};
};

const setProjectColor_Ed_Doc = (
	state: IEditorReducer,
	action: IOnSetProjectColors_Ed_Doc_Action
): IEditorReducer => {
	const syncEditorDoc = getSyncEditorDoc()
	if (!syncEditorDoc) return state;
	let editorDoc = syncEditorDoc.change(doc => {
		doc.projectColors = action.payload;
	});

	return {
		...state,
		editorDoc,
	};
};

const setPublishStatus_Ed_Doc = (
	state: IEditorReducer,
	action: IOnSetPublishStatus_Ed_Doc_Action
): IEditorReducer => {
	const syncEditorDoc = getSyncEditorDoc()
	if (!syncEditorDoc) return state;
	let editorDoc = syncEditorDoc.change(doc => {
		doc.publishStatus = action.payload;
	});
	return {
		...state,
		editorDoc,
	};
};

const removeSceneReferences_Ed_Doc = (
	state: IEditorReducer,
	action: IOnRemoveSceneReferences_Ed_Doc_Action
): IEditorReducer => {
	const syncEditorDoc = getSyncEditorDoc()
	if (!syncEditorDoc) return state;
	let editorDoc = syncEditorDoc.change(doc => {
		if (!doc.actions) return; 
		const compWithActionsIds = Object.keys(doc.actions);
		const { sceneIds } = action.payload;

		for (let i = 0; i < compWithActionsIds.length; i++) {
			const compId = compWithActionsIds[i];
			const actionData = doc.actions[compId];
			if (!actionData) continue;
			for (const triggerType in actionData) {
				if (!actionData[triggerType]?.linkedSceneId) continue;
				for (let j = 0; j < sceneIds.length; j++) {
					const sceneId = sceneIds[j];
					if (sceneId !== actionData[triggerType]?.linkedSceneId) continue;
					delete actionData[triggerType]?.linkedSceneId;
					if (actionData[triggerType]?.selectedActionCategory === IActionCategory.linkScene) {
						actionData[triggerType].selectedActionCategory = IActionCategory.noAction;
					}
				}
			}
		}
	});

	return {
		...state,
		editorDoc,
	};
};

const setTargetDisplayInfo_Ed_Doc = (
	state: IEditorReducer,
	action: IOnSetTargetDisplayInfo_Ed_Doc_Action
): IEditorReducer => {
	const syncEditorDoc = getSyncEditorDoc()
	if (!syncEditorDoc) return state;
 	let editorDoc = syncEditorDoc.change(doc => {
		doc.targetDisplayInfo = { ...doc.targetDisplayInfo, ...action.payload };
	});
	return {
		...state,
		editorDoc,
	};
};

// const setUnpublishedChangesStatus = (
// 	state: IContentReducer
// ): IContentReducer => {
// 	if (!syncContentDoc) return;
// 	let contentDoc = syncContentDoc.change(doc => {
// 		const date = new Date();
// 		doc.publishStatus = {
// 			status: IPublishTypes.unpublishedChanges,
// 			dateString: date.toUTCString(),
// 		};
// 	});
// 	return {
// 		...state,
// 		contentDoc,
// 	};
// };

const setActionData_Ed_Doc = (
	state: IEditorReducer,
	action: IOnSetActionData_Ed_Doc_Action
): IEditorReducer => {
	const { ids, triggerType, ...rest } = action.payload;
	const syncEditorDoc = getSyncEditorDoc()
	if (!ids || !triggerType || !rest || !syncEditorDoc) return state;
	const actionKeys = Object.keys(rest);

	let editorDoc = syncEditorDoc.change((doc: any) => {
		if (!doc.actions) doc.actions = {};
		for (let i = 0; i < ids.length; i++) {
			if (!doc.actions[ids[i]]) doc.actions[ids[i]] = {};
			if (!doc.actions[ids[i]][triggerType])
				doc.actions[ids[i]][triggerType] = {};

			for (let j = 0; j < actionKeys.length; j++) {
				const key = actionKeys[j] as keyof typeof rest;
				const data = rest[key];
				if (typeof data === 'object' && data !== null) {
					doc.actions[ids[i]][triggerType][key] = {
						...doc.actions[ids[i]][triggerType][key],
						...data,
					};
					continue;
				}
				doc.actions[ids[i]][triggerType][key] = data;
			}
		}
	});
	return {
		...state,
		editorDoc,
	};
};

const pasteCopiedEntities = (
	state: IEditorReducer,
	action: IPasteCopiedEntities_Ed_Doc_Action
): IEditorReducer => {
	const syncEditorDoc = getSyncEditorDoc()
	if (!syncEditorDoc) return state;
	const { copiedIds, newEntityIds } = action.payload;
	let editorDoc = syncEditorDoc.change(doc => {
		const keys = Object.keys(doc);
		for (let i = 0; i < keys.length; i++) {
			const key = keys[i] as keyof typeof doc;
			if (typeof doc[key] === 'object' && doc[key] !== null) {
				const editorDict = doc[key] as {[id: string]: any}
				for (let j = 0; j < copiedIds.length; j++) {
					const copiedId = copiedIds[j];
					const newEntityId = newEntityIds[j];
					if (typeof editorDict[copiedId] === 'undefined') continue;
					const copiedDict = JSON.parse(JSON.stringify(doc[key])) as {[id: string]: any};
					(doc[key] as {[id: string]: any})[newEntityId] = copiedDict[copiedId];
				}
			}
		}
	});
	return {
		...state,
		editorDoc,
	};
} 

const copyScenesEditorDoc = (
	state: IEditorReducer,
	action: IOnCopyScenesAtIndex_Ed_Doc_Action
): IEditorReducer => {
	const syncEditorDoc = getSyncEditorDoc()
	if (!syncEditorDoc) return state;
	const { oldToNewIdDict, sceneIds } = action.payload;

	let editorDoc = syncEditorDoc.change(doc => {
		const titles: string[] = [];
		if (doc.titles) for (const key in doc.titles) titles.push(doc.titles[key]);

		for (const oldId in oldToNewIdDict) {
				const newId = oldToNewIdDict[oldId];
				const editorKeys = Object.keys(doc)
				for (let i = 0; i < editorKeys.length; i++) {
					const editorKey = editorKeys[i] as keyof typeof doc;
					const editorProperty = doc[editorKey] as any;
					if (typeof editorProperty !== 'object' || !editorProperty?.[oldId]) continue;
					editorProperty[newId] = JSON.parse(JSON.stringify(editorProperty[oldId])) as any
				}
				for (let j = 0; j < sceneIds.length; j++) {
					const sceneId = sceneIds[j];
					const newSceneId = oldToNewIdDict[sceneId]
					let newTitle = doc.titles[sceneId] || 'New scene';
					const res = parseSceneTitle(newTitle)
					newTitle = `${newTitle}${res ? '' : ' '}[1]` // add space if not already square brakets

					// check if title with brackets already exists, increment until unused title reached
					let count = 2;
					while (titles.filter(title => title === newTitle).length && count < 100) {
						const res = parseSceneTitle(newTitle)
						newTitle = `${res[1]}[${count}]`
						count++;
					}
					
					doc.titles[newSceneId] = newTitle;
				}
			}
		}
	);

	return {
		...state,
		editorDoc,
	};
}

const editorReducer = (state: IEditorReducer = initialState, action: any) => {
	switch (action.type) {
		case IEditorDocActionTypes.SET_POSITION_ED_DOC:
			return setPositionInEditorDoc(state, action);
		case IEditorDocActionTypes.SET_SELECTION_ED_DOC:
			return setUserSelectionInEditorDoc(state, action);
		case IEditorDocActionTypes.REMOVE_USER_SELECTION_ED_DOC:
			return removeUserSelectionsInEditorDoc(state, action);
		case IEditorDocActionTypes.SET_SCALE_FACTOR_ED_DOC:
			return setScaleInEditorDoc(state, action);
		case IEditorDocActionTypes.SET_ROTATION_ED_DOC:
			return setRotationInEditorDoc(state, action);
		case IEditorDocActionTypes.REMOVE_ENTITIES_ED_DOC:
			return removeEntitiesInEditorDoc(state, action);
		case IEditorDocActionTypes.CHANGE_ED_DOC_REDUX:
			return updateEditorDocFromBackend(state, action);
		case IEditorDocActionTypes.SET_MULTIPLE_ENTITY_PROPS_ED_DOC:
			return setMultipleEntityPropsInEditorDoc(state, action);

		case IEditorDocActionTypes.ADD_TRACKING_ED_DOC:
			// setUnpublishedChangesStatus(state);
			return addTracking_Ed_Doc(state, action);

		case IEditorDocActionTypes.COPY_SCENES_ED_DOC:
			return copyScenesEditorDoc(state, action);

		case IEditorDocActionTypes.ADD_PROJECT_COLORS_ED_DOC:
			// setUnpublishedChangesStatus(state);
			return setProjectColor_Ed_Doc(state, action);

		case IEditorDocActionTypes.SET_PUBLISH_STATUS_ED_DOC:
			return setPublishStatus_Ed_Doc(state, action);

		case IEditorDocActionTypes.SET_ENTITY_ACTION_DATA_ED_DOC:
			return setActionData_Ed_Doc(state, action);

		case IEditorDocActionTypes.REMOVE_SCENE_REFERENCES_ED_DOC:
			return removeSceneReferences_Ed_Doc(state, action);

		case IEditorDocActionTypes.SET_TARGET_DISPLAY_INFO:
			return setTargetDisplayInfo_Ed_Doc(state, action);
		case IEditorDocActionTypes.PASTE_COPIED_ENTITIES_ED_DOC:
			return pasteCopiedEntities(state, action);

		default:
			return state;
	}
};

export default editorReducer;
