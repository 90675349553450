import React from 'react';
import CSS from './Summary.scss';
import {WebsiteIcon} from '../../../../../../../../assets/icons/index';

interface IParentProps {
	url: string;
}

const PageLinkSummary: React.SFC<IParentProps> = ({ url }) => {
	const lightBlueActive = "#4a90e2";

	return (
		<div className={CSS.FlexContainer}>
			<span className={CSS.Description}>Linked to:</span>
			<a className={`${CSS.LinkContainerDiv} ${CSS.TargetLink}`} href={url} target="_blank">
				<div className={CSS.IconContainer}> 
					<WebsiteIcon 
						className={CSS.ProviderIcon}
						hexFill={lightBlueActive}
					/>
				</div>
				<div className={CSS.URL}>{url}</div>
			</a>
		</div>
	);
};

export default PageLinkSummary;
