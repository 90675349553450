// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DropdownContainer--2I9aL{position:relative;display:flex;flex-direction:column;align-items:center}.DropdownMenu--Q6jts{width:100%;overflow-y:scroll;overflow-x:hidden;position:absolute;margin-bottom:20px}", ""]);
// Exports
exports.locals = {
	"DropdownContainer": "DropdownContainer--2I9aL",
	"DropdownMenu": "DropdownMenu--Q6jts"
};
module.exports = exports;
