import React from 'react';
import Entity3dContextMenu from './Entity3dContextMenu/Entity3dContextMenu';
import EntityActionContextMenu from './EntityActionContextMenu/EntityActionContextMenu';
import UndoRedoMenu from './UndoRedoMenu/UndoRedoMenu';
import Divider from '../../Divider/Divider';
import CSS from './ContextMenu.scss'
import { useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../typings';

const ContextMenu = () => {
  const socketHasConnected = useSelector((state: IDesignerState) => state.userReducer.socketHasConnected)
  return (
    <div className={CSS.OuterContainer}>
      {socketHasConnected ? 
        <div className={CSS.ContextMenu}>
          <Entity3dContextMenu />
          <div className={CSS.ContextMenuItem}>
            <Divider height="12px" />
          </div>
          <EntityActionContextMenu />
          <div className={CSS.ContextMenuItem}>
            <Divider height="12px" />
          </div>
          <UndoRedoMenu />
        </div> : null
      }
     
    </div>
  )
}

export default ContextMenu;