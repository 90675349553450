// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ActionBtn--1x70k{border-radius:6px;background-color:#4a90e2;color:#fff;cursor:pointer}.ActionBtn--1x70k:disabled{opacity:.5;cursor:default}", ""]);
// Exports
exports.locals = {
	"ActionBtn": "ActionBtn--1x70k"
};
module.exports = exports;
