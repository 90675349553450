import { IBounds } from '../../../../store/selectors';
import {
	IOnSetGroupInversion,
	IMultipleEntityProps_Ed_Doc,
} from '../../../../store/actions';
import { ThreeEvent } from '@react-three/fiber';
import {
	calcBooleanMarkerIndexVariables,
	// HOTSPOT_SCALE,
	maths,
} from '../../../../utils';
import {
	ITuple3,
	ITuple3Dict,
} from '../../../r3f/r3f-components/component-data-structure';
const { vec3 } = maths;

export enum scaleTypes {
	allSidedGroupScale = 'allSidedGroupScale',
	oneSidedGroupScale = 'oneSidedGroupScale',
	allSidedUniformEntityScale = 'allSidedUniformEntityScale',
	allSidedNonUniformEntityScale = 'allSidedNonUniformEntityScale',
	oneSidedNonUniformEntityScale = 'oneSidedNonUniformEntityScale',
	oneSidedUniformEntityScale = 'oneSidedUniformEntityScale',
}

export const calcScaleType = (
	groupSelected: boolean,
	allSidesScale: boolean,
	uniformScale: boolean
) => {
	if (groupSelected && allSidesScale) return scaleTypes.allSidedGroupScale;
	if (groupSelected && !allSidesScale) return scaleTypes.oneSidedGroupScale;
	if (!groupSelected && allSidesScale && uniformScale)
		return scaleTypes.allSidedUniformEntityScale;
	if (!groupSelected && allSidesScale && !uniformScale)
		return scaleTypes.allSidedNonUniformEntityScale;
	if (!groupSelected && !allSidesScale && !uniformScale)
		return scaleTypes.oneSidedNonUniformEntityScale;
	if (!groupSelected && !allSidesScale && uniformScale)
		return scaleTypes.oneSidedUniformEntityScale;
};

////// GROUP SCALING /////////////////

export const oneSidedGroupScale = (
	initialGroupScale: ITuple3,
	initialGroupBoundary: IBounds,
	e: ThreeEvent<PointerEvent>,
	markerIndexPressed: number,
	oppositeMarkerPosition: number,
	onSetGroupInversion: IOnSetGroupInversion,
	selectedEntityIds: string[],
	contentRotationDict: ITuple3Dict,
	contentScaleDict: ITuple3Dict,
	contentPositionDict: ITuple3Dict
) => {
	//const pointerPosition = vec3.multiply(e.localPosition, HOTSPOT_SCALE);
	const { x, y, z } = e.point;

	const {
		hMiddleMarkerPressed,
		topSideMarkerPressed,
		rightSideMarkerPressed,
		bottomSideMarkerPressed,
		leftSideMarkerPressed,
		topCornerPressed,
		bottomCornerPressed,
	} = calcBooleanMarkerIndexVariables(markerIndexPressed);

	// adjust inversion checks & group scale change to one-sided scaling
	let xInverted =
		(rightSideMarkerPressed && x < initialGroupBoundary[0][0]) ||
		(leftSideMarkerPressed && x > initialGroupBoundary[2][0]);

	let yInverted =
		((topCornerPressed || bottomCornerPressed) && xInverted) ||
		(markerIndexPressed === 1 && y < initialGroupBoundary[4][1]) ||
		(markerIndexPressed === 5 && y > initialGroupBoundary[4][1]);

	let currentGroupScale = [
		(x - initialGroupBoundary[oppositeMarkerPosition][0]) / 2,
		(y - initialGroupBoundary[oppositeMarkerPosition][1]) / 2,
		0,
	];

	let groupScaleChangeRatio = [
		currentGroupScale[0] / initialGroupScale[0],
		currentGroupScale[1] / initialGroupScale[1],
		0,
	];

	// enable negative entity scale values if group inverted
	const adjFactor = [
		yInverted && !xInverted && (topSideMarkerPressed || bottomSideMarkerPressed)
			? -1
			: 1,
		xInverted && !yInverted && (leftSideMarkerPressed || rightSideMarkerPressed)
			? -1
			: 1,
	];

	onSetGroupInversion({ xInverted, yInverted });

	let multipleEntityPropsArray: IMultipleEntityProps_Ed_Doc[] = [];
	for (let i = 0; i < selectedEntityIds.length; i++) {
		const id = selectedEntityIds[i];
		let rotation = contentRotationDict[id][2];

		// if group inverted flip/mirror rotation
		if ((xInverted && !yInverted) || (!xInverted && yInverted))
			rotation = 360 - rotation;

		// calculate entity scale factor
		let scale = [
			contentScaleDict[id][0] *
				groupScaleChangeRatio[hMiddleMarkerPressed ? 1 : 0] *
				adjFactor[0],
			contentScaleDict[id][1] *
				groupScaleChangeRatio[hMiddleMarkerPressed ? 1 : 0] *
				adjFactor[1],
			contentScaleDict[id][2] * groupScaleChangeRatio[2],
		];

		let distanceFromBorder = [
			contentPositionDict[id][0] -
				initialGroupBoundary[oppositeMarkerPosition][0],
			contentPositionDict[id][1] -
				initialGroupBoundary[oppositeMarkerPosition][1],
		];

		if (
			leftSideMarkerPressed ||
			(bottomSideMarkerPressed && !rightSideMarkerPressed)
		) {
			distanceFromBorder[0] *= -1;
			distanceFromBorder[1] *= -1;
		}

		let position = [
			initialGroupBoundary[oppositeMarkerPosition][0] +
				distanceFromBorder[0] *
					groupScaleChangeRatio[hMiddleMarkerPressed ? 1 : 0] *
					adjFactor[0],
			initialGroupBoundary[oppositeMarkerPosition][1] +
				distanceFromBorder[1] *
					groupScaleChangeRatio[hMiddleMarkerPressed ? 1 : 0] *
					adjFactor[1],
			0,
		];

		multipleEntityPropsArray.push({
			id,
			scales: [scale[0], scale[1], 0],
			scalesInverted: [scale[0] < 0, scale[1] < 0],
			rotations: [0, 0, rotation],
			positions: [position[0], position[1], 0],
		});
	}
	return multipleEntityPropsArray;
};

export const allSidedGroupScale = (
	initialGroupScale: ITuple3,
	initialSelectionCenter: ITuple3,
	e: ThreeEvent<PointerEvent>,
	markerIndexPressed: number,
	onSetGroupInversion: IOnSetGroupInversion,
	selectedEntityIds: string[],
	contentRotationDict: ITuple3Dict,
	contentScaleDict: ITuple3Dict,
	contentPositionDict: ITuple3Dict
) => {
	//const pointerPosition = vec3.multiply(e.localPosition, HOTSPOT_SCALE);
	const { x, y, z } = e.point;

	const {
		hMiddleMarkerPressed,
		topSideMarkerPressed,
		rightSideMarkerPressed,
		bottomSideMarkerPressed,
		leftSideMarkerPressed,
		topCornerPressed,
		bottomCornerPressed,
	} = calcBooleanMarkerIndexVariables(markerIndexPressed);

	// check if group is inverted/mirrored and calc % of scale change

	let xInverted =
		(rightSideMarkerPressed && x < initialSelectionCenter[0]) ||
		(leftSideMarkerPressed && x > initialSelectionCenter[0]);

	let yInverted =
		((topCornerPressed || bottomCornerPressed) && xInverted) ||
		(markerIndexPressed === 1 && y < initialSelectionCenter[1]) ||
		(markerIndexPressed === 5 && y > initialSelectionCenter[1]);

	let currentGroupScale = [
		leftSideMarkerPressed
			? initialSelectionCenter[0] - x
			: x - initialSelectionCenter[0],
		bottomSideMarkerPressed
			? initialSelectionCenter[1] - y
			: y - initialSelectionCenter[1],
		z - initialSelectionCenter[2],
	];

	let groupScaleChangeRatio = [
		currentGroupScale[0] / initialGroupScale[0],
		currentGroupScale[1] / initialGroupScale[1],
		0,
	];

	// enable negative entity scale values if group inverted
	const adjFactor = [
		yInverted && !xInverted && (topSideMarkerPressed || bottomSideMarkerPressed)
			? -1
			: 1,
		xInverted && !yInverted && (leftSideMarkerPressed || rightSideMarkerPressed)
			? -1
			: 1,
	];

	onSetGroupInversion({ xInverted, yInverted });

	let multipleEntityPropsArray: IMultipleEntityProps_Ed_Doc[] = [];

	for (let i = 0; i < selectedEntityIds.length; i++) {
		const id = selectedEntityIds[i];
		let rotation = contentRotationDict[id][2];

		// if group inverted flip/mirror rotation
		if ((xInverted && !yInverted) || (!xInverted && yInverted))
			rotation = 360 - rotation;

		// calculate entity scale factor
		let scale = [
			contentScaleDict[id][0] *
				groupScaleChangeRatio[hMiddleMarkerPressed ? 1 : 0] *
				adjFactor[0],
			contentScaleDict[id][1] *
				groupScaleChangeRatio[hMiddleMarkerPressed ? 1 : 0] *
				adjFactor[1],
			contentScaleDict[id][2] * groupScaleChangeRatio[2],
		];

		// let position = contentPositionDict[id];
		let adjGroupCenter: null | ITuple3 = null;

		let groupCenter = adjGroupCenter || initialSelectionCenter;
		if (!groupCenter) return;

		let position = [
			groupCenter[0] +
				(contentPositionDict[id][0] - groupCenter[0]) *
					groupScaleChangeRatio[hMiddleMarkerPressed ? 1 : 0] *
					adjFactor[0],
			groupCenter[1] +
				(contentPositionDict[id][1] - groupCenter[1]) *
					groupScaleChangeRatio[hMiddleMarkerPressed ? 1 : 0] *
					adjFactor[1],
			groupCenter[2] +
				(contentPositionDict[id][2] - groupCenter[2]) *
					groupScaleChangeRatio[2],
		];

		multipleEntityPropsArray.push({
			id,
			scales: [scale[0], scale[1], 0],
			scalesInverted: [scale[0] < 0, scale[1] < 0],
			rotations: [0, 0, rotation],
			positions: [position[0], position[1], 0],
		});
	}
	return multipleEntityPropsArray;
};

////// INDIVIDUAL ENTITY SCALING /////////////////

export const oneSidedNonUniformEntityScale = (
	selectionRotation: ITuple3,
	selectionCenter: ITuple3,
	markerIndexPressed: number,
	localMarkerPosition: ITuple3,
	localPointerPosition: ITuple3,
	selectionScale: ITuple3,
	selectedEntityIds: string[]
) => {
	const {
		vMiddleMarkerPressed,
		hMiddleMarkerPressed,
	} = calcBooleanMarkerIndexVariables(markerIndexPressed);
	let scale = vec3.abs(localPointerPosition);

	let position = maths.localToWorldPosition2d(
		selectionRotation[2],
		[selectionCenter[0], selectionCenter[1]],
		[
			[
				hMiddleMarkerPressed
					? localMarkerPosition[0]
					: localMarkerPosition[0] +
					  (localPointerPosition[0] - localMarkerPosition[0]) / 2,
				vMiddleMarkerPressed
					? localMarkerPosition[1]
					: localMarkerPosition[1] +
					  (localPointerPosition[1] - localMarkerPosition[1]) / 2,
			],
		]
	)[0];

	scale[0] = (localPointerPosition[0] - localMarkerPosition[0]) / 2;
	scale[1] = (localPointerPosition[1] - localMarkerPosition[1]) / 2;
	if (markerIndexPressed > 3 && markerIndexPressed < 7) scale[1] *= -1;
	if (markerIndexPressed === 0 || markerIndexPressed > 5) scale[0] *= -1;
	if (vMiddleMarkerPressed) scale[1] = selectionScale[1];
	if (hMiddleMarkerPressed) scale[0] = selectionScale[0];

	let multipleEntityPropsArray: IMultipleEntityProps_Ed_Doc[] = [];
	for (let i = 0; i < selectedEntityIds.length; i++) {
		const id = selectedEntityIds[i];
		multipleEntityPropsArray.push({
			id,
			scales: [scale[0], scale[1], 0],
			scalesInverted: [scale[0] < 0, scale[1] < 0],
			positions: [position[0], position[1], 0],
		});
	}
	return multipleEntityPropsArray;
};

export const oneSidedUniformEntityScale = (
	selectionRotation: ITuple3,
	selectionCenter: ITuple3,
	markerIndexPressed: number,
	localMarkerPosition: ITuple3,
	localPointerPosition: ITuple3,
	selectionScale: ITuple3,
	ratio: number,
	selectedEntityIds: string[],
	initialRatio: number
) => {
	const {
		vMiddleMarkerPressed,
		hMiddleMarkerPressed,
		bottomSideMarkerPressed,
		rightSideMarkerPressed,
		topSideMarkerPressed,
		leftSideMarkerPressed,
	} = calcBooleanMarkerIndexVariables(markerIndexPressed);
	let scale = vec3.abs(localPointerPosition);

	let position = maths.localToWorldPosition2d(
		selectionRotation[2],
		[selectionCenter[0], selectionCenter[1]],
		[
			[
				hMiddleMarkerPressed
					? localMarkerPosition[0]
					: localMarkerPosition[0] +
					  (localPointerPosition[0] - localMarkerPosition[0]) / 2,
				vMiddleMarkerPressed
					? localMarkerPosition[1]
					: localMarkerPosition[1] +
					  (localPointerPosition[1] - localMarkerPosition[1]) / 2,
			],
		]
	)[0];

	scale[0] = (localPointerPosition[0] - localMarkerPosition[0]) / 2;
	scale[1] = (localPointerPosition[1] - localMarkerPosition[1]) / 2;
	if (markerIndexPressed > 3 && markerIndexPressed < 7) scale[1] *= -1;
	if (markerIndexPressed === 0 || markerIndexPressed > 5) scale[0] *= -1;

	const adjFactor = [
		selectionScale[0] > 0 ? 1 : -1,
		selectionScale[1] > 0 ? 1 : -1,
	];

	if (vMiddleMarkerPressed) {
		ratio = initialRatio || ratio;
		scale[1] = Math.abs(selectionScale[0] * ratio) * adjFactor[1];
	}
	if (hMiddleMarkerPressed) {
		ratio = initialRatio || ratio;
		scale[0] = Math.abs(selectionScale[1] / ratio) * adjFactor[0];
	}

	const isOneAxisInverted = ratio < 0;

	if (
		(bottomSideMarkerPressed && rightSideMarkerPressed) ||
		(topSideMarkerPressed && leftSideMarkerPressed)
	)
		ratio *= -1;

	if (!vMiddleMarkerPressed && !hMiddleMarkerPressed) {
		scale[1] = scale[0] * Math.abs(ratio) * (isOneAxisInverted ? -1 : 1);
		position = maths.localToWorldPosition2d(
			selectionRotation[2],
			[selectionCenter[0], selectionCenter[1]],
			[
				[
					localMarkerPosition[0] +
						(localPointerPosition[0] - localMarkerPosition[0]) / 2,
					localMarkerPosition[1] +
						((localPointerPosition[0] - localMarkerPosition[0]) / 2) * ratio,
				],
			]
		)[0];
	}

	let multipleEntityPropsArray: IMultipleEntityProps_Ed_Doc[] = [];
	for (let i = 0; i < selectedEntityIds.length; i++) {
		const id = selectedEntityIds[i];
		multipleEntityPropsArray.push({
			id,
			scales: [scale[0], scale[1], 0],
			scalesInverted: [scale[0] < 0, scale[1] < 0],
			positions: [position[0], position[1], 0],
		});
	}
	return multipleEntityPropsArray;
};

export const allSidedNonUniformEntityScale = (
	markerIndexPressed: number,
	localPointerPosition: ITuple3,
	selectionScale: ITuple3,
	selectedEntityIds: string[]
) => {
	const {
		vMiddleMarkerPressed,
		hMiddleMarkerPressed,
	} = calcBooleanMarkerIndexVariables(markerIndexPressed);
	let scale = localPointerPosition;
	if (markerIndexPressed > 3 && markerIndexPressed < 7) scale[1] *= -1;
	if (markerIndexPressed === 0 || markerIndexPressed > 5) scale[0] *= -1;
	if (vMiddleMarkerPressed) scale[1] = selectionScale[1];
	if (hMiddleMarkerPressed) scale[0] = selectionScale[0];

	let multipleEntityPropsArray: IMultipleEntityProps_Ed_Doc[] = [];
	for (let i = 0; i < selectedEntityIds.length; i++) {
		const id = selectedEntityIds[i];
		multipleEntityPropsArray.push({
			id,
			scales: [scale[0], scale[1], 0],
			scalesInverted: [scale[0] < 0, scale[1] < 0],
		});
	}
	return multipleEntityPropsArray;
};

export const allSidedUniformEntityScale = (
	markerIndexPressed: number,
	localPointerPosition: ITuple3,
	selectionScale: ITuple3,
	ratio: number,
	selectedEntityIds: string[],
	initialRatio: number
) => {
	const {
		vMiddleMarkerPressed,
		hMiddleMarkerPressed,
		bottomSideMarkerPressed,
		rightSideMarkerPressed,
		topSideMarkerPressed,
		leftSideMarkerPressed,
	} = calcBooleanMarkerIndexVariables(markerIndexPressed);
	let scale = localPointerPosition;
	if (markerIndexPressed > 3 && markerIndexPressed < 7) scale[1] *= -1;
	if (markerIndexPressed === 0 || markerIndexPressed > 5) scale[0] *= -1;

	const adjFactor = [
		selectionScale[0] > 0 ? 1 : -1,
		selectionScale[1] > 0 ? 1 : -1,
	];

	if (vMiddleMarkerPressed) {
		ratio = initialRatio || ratio;
		scale[1] = Math.abs(selectionScale[0] * ratio) * adjFactor[1];
	}
	if (hMiddleMarkerPressed) {
		ratio = initialRatio || ratio;
		scale[0] = Math.abs(selectionScale[1] / ratio) * adjFactor[0];
	}
	const isOneAxisInverted = ratio < 0;
	if (
		(bottomSideMarkerPressed && rightSideMarkerPressed) ||
		(topSideMarkerPressed && leftSideMarkerPressed)
	)
		ratio *= -1;
	if (!vMiddleMarkerPressed && !hMiddleMarkerPressed) {
		scale[1] = scale[0] * Math.abs(ratio) * (isOneAxisInverted ? -1 : 1);
	}

	let multipleEntityPropsArray: IMultipleEntityProps_Ed_Doc[] = [];
	for (let i = 0; i < selectedEntityIds.length; i++) {
		const id = selectedEntityIds[i];
		multipleEntityPropsArray.push({
			id,
			scales: [scale[0], scale[1], 0],
			scalesInverted: [scale[0] < 0, scale[1] < 0],
		});
	}
	return multipleEntityPropsArray;
};
