import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { onRemoveSelection, onUndo_Cn_Doc, onRedo_Cn_Doc } from "../../../../../store/actions";
import { getSyncContentDoc } from "../../../../../syncdoc";
import { UndoRedoIcon } from "../../../../../assets/icons";
import { ICON_INACTIVE_COLOR, ICON_ACTIVE_BLUE_COLOR, DEFAULT_ICON_COLOR } from "../../../../../utils";
import CSS from "./UndoRedoMenu.scss";
import { IDesignerState } from "../../../../../../typings";

const UndoRedoMenu = () => {
  // Redux
  const dispatch = useDispatch();
  const syncContentDoc = getSyncContentDoc();
  const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
  const automergeIsLoaded = useSelector((state: IDesignerState) => !!state.editorReducer.editorDoc.createdAt);
  const canUndo = automergeIsLoaded && syncContentDoc ? syncContentDoc?.canUndo?.() : false;
  const canRedo = automergeIsLoaded && syncContentDoc ? syncContentDoc?.canRedo?.() : false;

  // Local state
  const [undoHexfill, setUndoHexFill] = useState(canUndo ? DEFAULT_ICON_COLOR : ICON_INACTIVE_COLOR);
  const [redoHexfill, setRedoHexFill] = useState(canRedo ? DEFAULT_ICON_COLOR : ICON_INACTIVE_COLOR);

  // Update colors
  useEffect(() => {
    setUndoHexFill(canUndo ? DEFAULT_ICON_COLOR : ICON_INACTIVE_COLOR);
    setRedoHexFill(canRedo ? DEFAULT_ICON_COLOR : ICON_INACTIVE_COLOR);
  }, [canUndo, canRedo]);

  const undoAction = () => {
    if (!canUndo) return;
    dispatch(onRemoveSelection(selectedEntityIds));
    dispatch(onUndo_Cn_Doc());
  };

  const redoAction = () => {
    if (!canRedo) return;
    dispatch(onRedo_Cn_Doc())
  }

  return (
    <>
      <button
        className={CSS.ContextMenuButton}
        onClick={undoAction}
        onMouseOver={() => { if (canUndo) setUndoHexFill(ICON_ACTIVE_BLUE_COLOR); }}
        onMouseOut={() => setUndoHexFill(canUndo ? DEFAULT_ICON_COLOR : ICON_INACTIVE_COLOR)}
        title="Undo">
        <UndoRedoIcon hexFill={undoHexfill} className={CSS.UndoIcon} title="Undo" />
      </button>

      <button
        className={CSS.ContextMenuButton}
        onClick={redoAction}
        onMouseOver={() => { if (canRedo) setRedoHexFill(ICON_ACTIVE_BLUE_COLOR); }}
        onMouseOut={() => setRedoHexFill(canRedo ? DEFAULT_ICON_COLOR : ICON_INACTIVE_COLOR)}
        title="Redo">
        <UndoRedoIcon hexFill={redoHexfill} className={CSS.RedoIcon} title="Redo" />
      </button>
    </>
  );
};

export default React.memo(UndoRedoMenu);