import React, {useRef, useState} from 'react';
import { rotateSvgUrl } from '../../../assets/icons/index';
import { connect, Provider } from 'react-redux';
import { Html } from '@react-three/drei';
import { IDesignerState, IDomIdSelectors } from '../../../../typings';
import { store } from '../../../index';
import {
	onSetRotationIsActive,
	onSetMarkerIndexPressed,
	IOnSetRotationIsActive,
	IOnSetMarkerIndexPressed,
	onSetBackgroundHotSpotEnabled,
	IOnSetBackgroundHotSpotEnabled,
	IMultipleEntityProps_Ed_Doc,
	IOnSetMultipleEntityProps_Ed_Doc,
	onSetMultipleEntityProps_Ed_Doc,
	IMultipleEntityProps_Cn_Doc,
	onSetMultipleComponentProps_Cn_Doc,
	IOnSetMultipleComponentProps_Cn_Doc,
} from '../../../store/actions';
import { bindActionCreators } from 'redux';
import { hotkeyIsPressed, HOTSPOT_SCALE, IHotkeyTypes, maths, ROTATION_MARKER_HEIGHT, ROTATION_MARKER_WIDTH } from '../../../utils';
import {
	getSelectedEntityIds,
	getRotationMarkerPosition,
	getEditorIsLockedDict,
	getSelectionCenter,
	getSelectedContentRotations,
	getSelectedContentPositions,
	getSelectedEditorRotations,
	getSelectedEditorPositions,
} from '../../../store/selectors'
import Plane from '../Plane/Plane'
import { ThreeEvent } from '@react-three/fiber';
import { ITuple3, ITuple3Dict } from '../r3f-components/component-data-structure';

interface IReduxProps {
	rotationMarkerPosition: ITuple3;
	renderMarker: boolean;
	selectedEntityIds: string[];
	hotKeys: string[];
	selectionCenter: ITuple3;
	editorIsLockedDict: { [id: string]: boolean };
	contentRotationDict: ITuple3Dict;
	contentPositionDict: ITuple3Dict;
	editorRotationDict: ITuple3Dict;
	editorPositionDict: ITuple3Dict;
}

interface IDispatchProps {
	onSetRotationIsActive: IOnSetRotationIsActive;
	onSetMarkerIndexPressed: IOnSetMarkerIndexPressed;
	onSetBackgroundHotSpotEnabled: IOnSetBackgroundHotSpotEnabled;
	onSetMultipleEntityProps_Ed_Doc: IOnSetMultipleEntityProps_Ed_Doc;
	onSetMultipleComponentProps_Cn_Doc: IOnSetMultipleComponentProps_Cn_Doc;
}

const RotationMarker: React.FunctionComponent<IReduxProps & IDispatchProps> = ({
	onSetRotationIsActive,
	onSetBackgroundHotSpotEnabled,
	onSetMultipleEntityProps_Ed_Doc,
	onSetMultipleComponentProps_Cn_Doc,
	onSetMarkerIndexPressed,
	selectedEntityIds,
	rotationMarkerPosition: rmp,
	renderMarker,
	hotKeys,
	selectionCenter,
	editorIsLockedDict,
	contentRotationDict,
	contentPositionDict,
	editorRotationDict,
	editorPositionDict
}) => {
	const onPointerDownRef = useRef(false);
	const canvasRef = useRef(document.getElementById(IDomIdSelectors.zapparCanvas));
	const firstMoveRef = useRef(true);
	const initialPosRef = useRef<ITuple3>(null);
	const groupCenterRef = useRef<ITuple3>(null);
	const initialCoords = useRef<ITuple3>(null);
	const hideRotationMarker =
		selectedEntityIds.filter(id => editorIsLockedDict[id]).length === 0;

	// check hotkeys
	const individualGroupRotation = hotkeyIsPressed(hotKeys, [
		IHotkeyTypes.individualGroupRotation,
	]);
	
	const onPointerDownHandler = (e: any) => {
		if (onPointerDownRef.current) return;
		onPointerDownRef.current = true;
		initialCoords.current === null;
		onSetRotationIsActive(true);
		onSetBackgroundHotSpotEnabled(false);
		onSetMarkerIndexPressed(999);
		canvasRef.current.style.cursor = 'grabbing';
	};

	const onPointerMove = (e: ThreeEvent<PointerEvent>) =>  {
		const {x, y, z} = e.point;
		if (initialCoords?.current !== null && (initialCoords.current[0] === x && initialCoords.current[1] === y && initialCoords.current[2] === z)) {
			return;
		};		
		if (e.buttons === 2 || !onPointerDownRef.current) return;
		if (firstMoveRef.current) {
			(e.target as any).setPointerCapture(e.pointerId)
			firstMoveRef.current = false
			initialCoords.current = [e.point.x, e.point.y, e.point.z]
		};

		if (groupCenterRef.current === null) groupCenterRef.current = selectionCenter;
		if (initialPosRef.current === null) initialPosRef.current = rmp;
		
		const radians = maths.calcRadiansFromPoints(
			groupCenterRef.current,
			initialPosRef.current,
			[x, y, z]
		);
		
		let rotationOffset = maths.toDegrees(radians);
		if (rotationOffset < 0) rotationOffset += 360;
		const multipleEntityPropsArray: IMultipleEntityProps_Ed_Doc[] = [];

		// If entities should rotate around their center, only set rotation and return
		if (individualGroupRotation || selectedEntityIds.length === 1) {
			for (let i = 0; i < selectedEntityIds.length; i++) {
				const id = selectedEntityIds[i];
				let rotation = contentRotationDict[id][2] + rotationOffset;
				if (rotation > 360) rotation -= 360;
				multipleEntityPropsArray.push({
					id,
					rotations: [0, 0, rotation],
				});
			}
			onSetMultipleEntityProps_Ed_Doc(multipleEntityPropsArray);
			return;
		}
		// else if rotated around group center re-calculate positions & rotations
		for (let i = 0; i < selectedEntityIds.length; i++) {
			const id = selectedEntityIds[i];
			const rotatedEntityValues = maths.rotateEntityAroundPoint2d(
				groupCenterRef.current,
				rotationOffset,
				contentPositionDict[id],
				contentRotationDict[id][2]
			);
			multipleEntityPropsArray.push({
				id,
				positions: rotatedEntityValues.position,
				rotations: [0, 0, rotatedEntityValues.rotation] as ITuple3,
			});
		}
		onSetMultipleEntityProps_Ed_Doc(multipleEntityPropsArray);
	}

	const onPointerUp = (e: PointerEvent) =>  {
		(e.target as any).releasePointerCapture(e.pointerId);
		initialCoords.current === null;
		canvasRef.current.style.cursor = 'unset';
		firstMoveRef.current = true;
		onPointerDownRef.current = false;
		if (e.buttons === 2) return;

		let multipleEntityPropsArray_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
		let multipleEntityPropsArray_Ed_Doc: IMultipleEntityProps_Ed_Doc[] = [];
		for (let i = 0; i < selectedEntityIds.length; i++) {
			const id = selectedEntityIds[i];
			multipleEntityPropsArray_Cn_Doc.push({
				id,
				...(editorRotationDict[id] && {
					rotation: [...editorRotationDict[id]],
				}),
				...(selectedEntityIds.length > 1 && (!!editorPositionDict[id]) && {
					position: [...editorPositionDict[id]],
				}),
			});
			multipleEntityPropsArray_Ed_Doc.push({
				id,
				rotations: null,
				positions: null,
			});
		}
		groupCenterRef.current = null;
		initialPosRef.current = null;
		onSetMultipleComponentProps_Cn_Doc(multipleEntityPropsArray_Cn_Doc);
		onSetMultipleEntityProps_Ed_Doc(multipleEntityPropsArray_Ed_Doc);
		onSetMarkerIndexPressed(null);
		onSetRotationIsActive(false);
	}


	return (
		<>
			{rmp && renderMarker && hideRotationMarker && 
				<Html position={[rmp[0], rmp[1], 0] as any} zIndexRange={[1, 0]} >
					<Provider store={store}>
						<img
							style={{
								height: `${ROTATION_MARKER_HEIGHT}px`,
								width: `${ROTATION_MARKER_WIDTH}px`,
								marginTop: `-${ROTATION_MARKER_HEIGHT / 2}px`, //adjusted for header height
								marginLeft: `-${ROTATION_MARKER_WIDTH / 2}px`,
								cursor: 'grab', 
								pointerEvents: onPointerDownRef.current ? 'none' : 'auto'
							}}
							src={rotateSvgUrl}
							onPointerDown={onPointerDownHandler}
						/>
					</Provider>
				</Html>
			}
			<Plane
				visible={false}
				rotation={[0, 0, 0]}
				position={[0, 0, 0]}
				scale={HOTSPOT_SCALE}
				enabled={onPointerDownRef.current}
				pointerMoveHandler={onPointerMove}
				pointerUpHandler={onPointerUp}
			/>
		</>
	);
};

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	return {
		rotationMarkerPosition: getRotationMarkerPosition(state),
		selectionCenter: getSelectionCenter(state),
		hotKeys: state.userReducer.hotKeys,
		renderMarker:
			!state.userReducer.rotationIsActive &&
			!state.userReducer.positioningIsActive &&
			!state.userReducer.scaleHotspotIsEnabled &&
			!state.userReducer.backgroundHotspotIsEnabled &&
			state.userReducer.selectedEntityIds.length > 0,
		selectedEntityIds: getSelectedEntityIds(state),
		editorIsLockedDict: getEditorIsLockedDict(state),
		contentRotationDict: getSelectedContentRotations(state),
		contentPositionDict: getSelectedContentPositions(state),
		editorRotationDict: getSelectedEditorRotations(state),
		editorPositionDict: getSelectedEditorPositions(state),
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onSetMarkerIndexPressed,
			onSetRotationIsActive,
			onSetBackgroundHotSpotEnabled,
			onSetMultipleEntityProps_Ed_Doc,
			onSetMultipleComponentProps_Cn_Doc,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(RotationMarker);
