import React, { FunctionComponent, memo } from 'react';
import { ThreeEvent } from '@react-three/fiber';
import { Texture } from 'three';
import { ITuple3, ITuple4 } from '../../component-data-structure';
import RoundedRectWithBorder from '../RoundedRectWithBorder';

interface IParentProps {
	id: string;
    scale: ITuple3;
    borderRadius: number | undefined;
    borderWidth: number | undefined;
    opacity: number;
    borderRgba: ITuple4;
    texture: Texture | undefined;
    stackedAlpha: boolean;
    hasAlpha: boolean;
    color?: ITuple4;
    onPointerDown: (e: ThreeEvent<PointerEvent>) => any;
    onPointerUp: (e: ThreeEvent<PointerEvent>) => any;
    onDoubleClick: (e: ThreeEvent<MouseEvent>) => any;
    renderOrder: number | undefined;
}

const Background: FunctionComponent<IParentProps> = (props) => {
	return (
        <RoundedRectWithBorder
            name={props.id}
            scale={props.scale}
            radius={props.borderRadius}
            borderWidth={props.borderWidth}
            opacity={props.opacity}
            borderRgba={props.borderRgba}
            hasAlpha={props.hasAlpha}
            stackedAlpha={props.stackedAlpha}
            texture={props.texture}
            fillRgba={props.color}
            onPointerDown={props.onPointerDown}
            onPointerUp={props.onPointerUp}
            onDoubleClick={props.onDoubleClick}
            renderOrder={props.renderOrder}
            depthWrite={false}
        />
	);
};

export default memo(Background);
