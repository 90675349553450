// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PadLockBtn--1bnDi{padding-left:0px;margin-left:-3px;margin-left:2px;border:none;outline:none;cursor:pointer}.PadLockBtn--1bnDi svg{height:17px;width:25px}", ""]);
// Exports
exports.locals = {
	"PadLockBtn": "PadLockBtn--1bnDi"
};
module.exports = exports;
