import React, { FunctionComponent,useMemo, memo, useEffect } from 'react';
import { IComponentType, ITuple3 } from '../../r3f-components/component-data-structure';
import Image from '../../r3f-components/components/Image';
import TrackingRearView from '../TrackingRearView/TrackingRearView';

interface IParentProps {
	scale: ITuple3;
	trkImgUrl: string;
	opacity?: number;
}

const TrkImage: FunctionComponent<IParentProps> = ({ 
	scale, 
	trkImgUrl, 
	opacity = 1,
}) => {
	const zeroTuple3 = useMemo(() => [0, 0, 0], [])

	return (
    <>
      <Image
        type={IComponentType.Image}
        imageUrl={trkImgUrl}
        scale={scale}
        rotation={zeroTuple3}
        position={zeroTuple3}
        opacity={opacity}
        renderOrder={-1}
			/>
			<TrackingRearView
				scale={[scale[0] / 2, scale[1] / 2, scale[2] / 2]}
				planeRenderOrder={-1}
				textRenderOrder={-3}
			/>
    </>
  );
};

export default memo(TrkImage);
