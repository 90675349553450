import React, { FunctionComponent, memo, useMemo } from 'react';
import {
	ITuple3, ITuple4
} from '../../component-data-structure';
import RoundedRectWithBorder from '../RoundedRectWithBorder';
import { ThreeEvent } from '@react-three/fiber';
import {IUserData} from '../../utils/general'

interface IParentProps {
    scale: ITuple3;
    borderRadius: number;
    borderWidth: number;
    fillRgba: ITuple4;
    position: ITuple3;
    rotation: ITuple3;
    renderOrder: number;
    depthWrite?: boolean;
    borderRgba: ITuple4;
    children?: JSX.Element;
    onPointerDown?: (e: ThreeEvent<PointerEvent>) => any;
    onPointerUp?: (e: ThreeEvent<PointerEvent>) => any;
}

const PlaceholderEntity: FunctionComponent<IParentProps> = ({
    scale,
    fillRgba,
    borderRgba,
	depthWrite = false,
    children = null,
    renderOrder,
    onPointerDown,
    onPointerUp,
    ...props
}) => {

    const userData: IUserData = useMemo(() => ({renderOrder, contentId: 'Placeholder'}), [renderOrder])
    
	return (
        <group {...props} userData={userData}>
            <RoundedRectWithBorder 
                renderOrder={renderOrder}
                scale={scale}
                borderRgba={borderRgba}
                rotation={[0, 0, 0]}
                position={[0, 0, 0]}
                opacity={1}
                fillRgba={fillRgba}
                depthWrite={depthWrite}
                onPointerUp={onPointerUp || undefined}
                onPointerDown={onPointerDown || undefined}
            />
            {children}
        </group>
        
	);
};

export default memo(PlaceholderEntity);
