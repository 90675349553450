// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Subsections--1fBKf{margin:8px 8px 8px 0;display:flex;flex-direction:column}.Subsections--1fBKf .Title--2J4JQ{margin-bottom:8px;color:#5c5c5c}.Subsections--1fBKf .SliderRow--2WcCj{display:flex;flex-direction:row;justify-content:space-between}.Subsections--1fBKf .SliderRow--2WcCj .Slider--2Wm7-{width:70%}.Subsections--1fBKf .SliderRow--2WcCj .Input--MeumH{width:25%}.FontSection--aJ7H0{flex-direction:row;justify-content:space-between}.FontSection--aJ7H0>div{width:100px}.FontSection--aJ7H0 .FontSizeInput--ycVoM{margin-top:13px;width:100px}.TextAlignmentRow--CA_kM{display:flex;justify-content:space-between;align-items:center;margin-top:8px}.TextAlignmentRow--CA_kM button{padding:0px}.ColorSelectorRow--3H7Mp{display:flex;flex-direction:row;justify-content:start;align-items:center}.ColorSelectorRow--3H7Mp .ColorCircleContainer--2KBEK{margin-right:8px}.ColorSelectorRow--3H7Mp .ColorCircleContainer--2KBEK>div{justify-content:unset}.ColorInput--1T-Hu{width:66px;height:24px;font-size:12px}", ""]);
// Exports
exports.locals = {
	"Subsections": "Subsections--1fBKf",
	"Title": "Title--2J4JQ",
	"SliderRow": "SliderRow--2WcCj",
	"Slider": "Slider--2Wm7-",
	"Input": "Input--MeumH",
	"FontSection": "FontSection--aJ7H0",
	"FontSizeInput": "FontSizeInput--ycVoM",
	"TextAlignmentRow": "TextAlignmentRow--CA_kM",
	"ColorSelectorRow": "ColorSelectorRow--3H7Mp",
	"ColorCircleContainer": "ColorCircleContainer--2KBEK",
	"ColorInput": "ColorInput--1T-Hu"
};
module.exports = exports;
