import React, {useState} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
	IDesignerState,
} from '../../../../../../typings';

import Properties from './Properties/Properties';
import Actions from './Actions/Actions';
import Transitions from '../Shared/Transitions/Transitions';

import Shelf from '../../../Shelf/Shelf';
import {
	onSetComponentTransition_Cn_Doc,
	IOnSetComponentTransition_Cn_Doc,
	ISceneSnapshotDict,
} from '../../../../../store/actions';
import { getActiveSceneTransitions } from '../../../../../store/selectors';
import { ITransitionDict } from '../../../../r3f/r3f-components/component-data-structure';

enum ISceneShelves {
	properties = 'properties', 
	transitions = 'transitions', 
}

interface IParentProps {
	show: boolean;
	sceneTitle: string;
	sceneId: string;
}

interface IReduxProps {
	selectedTransitionsDict: ITransitionDict;
	snapshotDict: ISceneSnapshotDict;
}

interface IDispatchProps {
	onSetComponentTransition_Cn_Doc: IOnSetComponentTransition_Cn_Doc;
}

const SceneShelves: React.FunctionComponent<IParentProps & IReduxProps & IDispatchProps> = ({
	sceneTitle,
	sceneId,
	show,
	onSetComponentTransition_Cn_Doc,
	selectedTransitionsDict,
	snapshotDict,
}) => {
	const [openShelf, setOpenSelf] = useState<ISceneShelves | null>(ISceneShelves.properties);

	return (
		show ? 
		<>
			<Shelf
				show
				title="Name"
				open={openShelf === ISceneShelves.properties}
				id={ISceneShelves.properties}
				onOpen={() => setOpenSelf(ISceneShelves.properties)}
				onClose={() => setOpenSelf(null)}
			>
				<Properties sceneId={sceneId} sceneTitle={sceneTitle} snapshotDict={snapshotDict}/>
			</Shelf>
			{/* <Shelf
				open={openShelfDict[IPropertyMenuShelfTypes.sceneActions]}
				show
				id={IPropertyMenuShelfTypes.sceneActions}
				title="Scene Actions"
				onOpen={id => setShelfOpen(id)}
				onClose={id => setShelfClosed(id)}
			>
				<Actions sceneId={sceneId} sceneTitle={sceneTitle} />
			</Shelf> */}
			<Shelf
				show
				title="Transition Effects"
				open={openShelf === ISceneShelves.transitions}
				id={ISceneShelves.transitions}
				onOpen={() => setOpenSelf(ISceneShelves.transitions)}
				onClose={() => setOpenSelf(null)}
			>
				<Transitions
					transitionDict={selectedTransitionsDict}
					onSetTransition_Cn_Doc={onSetComponentTransition_Cn_Doc}
				/>
				</Shelf>
		</> : null
	);
};

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	return {
		snapshotDict: state.userReducer.sceneSnapshots,
		selectedTransitionsDict: getActiveSceneTransitions(state),
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onSetComponentTransition_Cn_Doc,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(SceneShelves);
