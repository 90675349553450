import { loadAsset } from "../utils/loadAsset"

export interface Loader<T> extends THREE.Loader {
    load(
        url: string,
        onLoad?: (result: T) => void,
        onProgress?: (event: ProgressEvent) => void,
        onError?: (event: ErrorEvent) => void,
    ): unknown
}

export type IProto<T> = new () => Loader<T>;

function loadingFn<T>() {
    return function (Proto: new () => Loader<T>, input: string) {
        // Construct new loader and run extensions
        const loader = new Proto()
        // Go through the urls and load them
        return new Promise<T>((res, reject) =>
            loader.load(
                input,
                (data: any) => {res(data)},
                undefined,
                (error) => reject(`Could not load ${input}: ${error.message}`),
            ),
        )
    }
}
  
export function useTextureLoader<T extends THREE.Texture>(
    proto: IProto<T>,
    url: string | undefined,
    throwError: boolean,
): T | undefined {
    if (typeof url === 'undefined') return undefined;
    // Use suspense to load async assets and add webgl query string to ensure only webgl related caching
    return loadAsset(loadingFn<T>(), `${url}?=webgl`, throwError, proto) as T
}