import React, { FunctionComponent, useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IDesignerState, IDomClassSelector, IDomIdSelectors } from '../../../../../../../typings';
import { SceneIcon } from '../../../../../../assets/icons';
import {
	onRemoveSelection,
	IOnRemoveSelection,
	onSetActiveScene,
	IOnSetActiveScene,
	onSetCurrentSceneIndex,
	IOnSetCurrentSceneIndex,
	onSetSceneSnapshots,
	IOnSetSceneSnapshots,
	onSetMultipleEntityProps_Ed_Doc,
	IOnSetMultipleEntityProps_Ed_Doc,
	onScrollSceneMenu, 
	IOnScrollSceneMenu, 
	IOnEditSceneTitle,
	onEditSceneTitle,
	onCopyScenesAtIndex_Global,
	IOnCopyScenesAtIndex_Global,
	onFocusSceneNameInput,
	IOnFocusSceneNameInput,
	IOnRemoveScenes_Global,
	onRemoveScenes_Global,
} from '../../../../../../store/actions';
import {
	updateLStorageWithSnapshot,
	getSnapshotDictFromLStorage,
	removeSnapshotFromLStorage,
} from '../../../../../../utils';
import { getRootComponent } from '../../../../../../store/selectors';

import CSS from './SceneSlide.scss';
import { TextInput } from '../../../../Inputs';
import BtnDropdown from '../../../Dropdown/BtnDropdown/BtnDropdown';
import {IMenuPosition} from '../../../Dropdown/Dropdown';
import { IMenuItem, IMenuItemType } from '../../../MenuList/MenuList';

enum ISceneMenuItems {
	duplicate = 'duplicate',
	delete = 'delete',
	rename = 'rename',
	add = 'add'
}

interface IParentProps {
	sceneId: string;
	dataUrl: string | undefined;
	index: number;
	sceneName: string;
}

interface IReduxProps {
	selectedEntityIds: string[];
	activeSceneId: string;
	projectId: string;
	editSceneTitle: boolean; 
	currentSceneIndex: number;
	sceneIdSequence: string[];
	sceneCarouselScrollLeft: number;
}

interface IDispatchProps {
	onRemoveSelection: IOnRemoveSelection;
	onSetActiveScene: IOnSetActiveScene;
	onSetCurrentSceneIndex: IOnSetCurrentSceneIndex;
	onSetSceneSnapshots: IOnSetSceneSnapshots;
	onSetMultipleEntityProps_Ed_Doc: IOnSetMultipleEntityProps_Ed_Doc;
	onScrollSceneMenu: IOnScrollSceneMenu;
	onEditSceneTitle: IOnEditSceneTitle;
	onCopyScenesAtIndex_Global: IOnCopyScenesAtIndex_Global;
	onFocusSceneNameInput: IOnFocusSceneNameInput;
	onRemoveScenes_Global: IOnRemoveScenes_Global;
}

const SceneSlide: FunctionComponent<IParentProps & IReduxProps & IDispatchProps> = ({
	sceneId,
	selectedEntityIds,
	activeSceneId,
	dataUrl,
	index,
	onSetSceneSnapshots,
	onRemoveSelection,
	onSetActiveScene,
	onSetCurrentSceneIndex,
	sceneName,
	projectId,
	onSetMultipleEntityProps_Ed_Doc,
	editSceneTitle, 
	onScrollSceneMenu, 
	onEditSceneTitle,
	onCopyScenesAtIndex_Global,
	currentSceneIndex,
	onFocusSceneNameInput,
	onRemoveScenes_Global,
	sceneIdSequence,
	sceneCarouselScrollLeft,
}) => {
	const [isHovered, setIsHovered] = useState<boolean>(); 
	const isSelected = activeSceneId === sceneId;
	const [showBtnDropdownComponent, setShowBtnDropdownComponent] = useState(false);
	const [isBtnHovered, setIsBtnHovered] = useState<boolean>();
	const [isTitleHovered, setIsTitleHovered] = useState<boolean>(false);
	const [isTitleSelected, setIsTitleSelected] = useState<boolean>(false);
	const [sceneMenuPosition, setSceneMenuPosition] = useState<IMenuPosition>();

	useEffect(()=>{
		if(isHovered) setShowBtnDropdownComponent(true);
	}, [isHovered]);

	const hideDropdownComponent = () => {
		setShowBtnDropdownComponent(false);
	}

	useLayoutEffect(() => {
		hideDropdownComponent(); 
	}, [sceneCarouselScrollLeft]);

	useLayoutEffect(() => {
		const slideMenuPosition = (currentSceneIndex * 130) - sceneCarouselScrollLeft - 25; 
		if(slideMenuPosition < 0) setSceneMenuPosition(IMenuPosition.right);
		if(slideMenuPosition > 0) setSceneMenuPosition(IMenuPosition.left);
	}, [sceneCarouselScrollLeft, isSelected, setSceneMenuPosition]);

	const sceneClickHandler = (
		sceneId: string,
		activeSceneId: string,
		index: number
	) => {
		if (sceneId === activeSceneId) return;
		onRemoveSelection(selectedEntityIds);
		onSetActiveScene({ activeSceneId: sceneId });
		onSetCurrentSceneIndex(index);
		onScrollSceneMenu(false); 
		updateLStorageWithSnapshot(activeSceneId, projectId);
		onSetSceneSnapshots(getSnapshotDictFromLStorage(localStorage, projectId));
	};

	const copySceneAtIndexClickHandler = () => {
		onRemoveSelection(selectedEntityIds);
		onCopyScenesAtIndex_Global({
			projectId,
			sceneIds: [activeSceneId],
			index: currentSceneIndex + 1,
		});
		onSetCurrentSceneIndex(currentSceneIndex + 1);
	};

	const removeSelectedScenesClickHandler = () => {
		const selectedScenes = [activeSceneId];
		if (!selectedScenes.length) return;
		onRemoveScenes_Global(selectedScenes);
		const firstSelectedSceneIndex = sceneIdSequence.indexOf(selectedScenes[0]);
		onSetActiveScene({
			activeSceneId: sceneIdSequence[firstSelectedSceneIndex - 1],
		});
		removeSnapshotFromLStorage(localStorage, projectId, activeSceneId);
	};

	const optionData: IMenuItem[] = useMemo(() => [
		{
			text: 'Duplicate', 
			val: ISceneMenuItems.duplicate, 
			fn: () => {copySceneAtIndexClickHandler()},
		},
		{
			text: 'Rename', 
			val: ISceneMenuItems.rename,
			fn: () => {
				onEditSceneTitle(true);
			},
		},
		{
			text: 'Delete', 
			val: ISceneMenuItems.delete,
			fn: () => {
				removeSelectedScenesClickHandler();
			},
			type: IMenuItemType.danger,
		},
	], [currentSceneIndex, copySceneAtIndexClickHandler, onEditSceneTitle, removeSelectedScenesClickHandler]);

	const zIndex = sceneId === activeSceneId ? 1 : 0;
	return (
		<div className={CSS.SceneContainer}>
		<div
			id={IDomIdSelectors.smSlide}
			style={{zIndex}}
			className={`${CSS.SceneSlide} ${
				isHovered ? CSS.HoveredState : CSS.UnhoveredState
			} ${isSelected ? CSS.Active : ''}`}
			onClick={() => {
				sceneClickHandler(sceneId, activeSceneId, index);
			}}
			onMouseOver={() => setIsHovered(true)}
			onPointerLeave={() => setIsHovered(false)}
		>
			<div className={`${CSS.SceneSlideInner}  ${isBtnHovered ? CSS.HoveredBtn : ''}`}>
				{showBtnDropdownComponent && 
					<div 
						className={CSS.ButtonContainer} 
						onPointerEnter={() => setIsBtnHovered(true)}				
						onPointerLeave={() => setIsBtnHovered(false)}						
					>
						<BtnDropdown
							children={optionData}
							positionMenu={sceneMenuPosition}
							menuWidth={'147px'}	
							className={IDomClassSelector.scenePopupMenu}
							onClose={() => {
								hideDropdownComponent(); 
								setIsBtnHovered(false);
								setIsHovered(false);
							}}
							hideButton={!isHovered}
							appendMenuToRoot={true}
						/>
					</div>
				}
				{isHovered && (	
					<div className={CSS.HoverDiv}>	
					</div>
				)}
				{dataUrl ? (
					<div 
						style={{backgroundImage:`url(${dataUrl})`}}
						className={CSS.SceneImage}
					/>
				) : (
					<SceneIcon hexFill={'#4A90E2'} />
				)}
			</div>
		</div>
		<div className={CSS.SceneName}>
			{(isTitleHovered || isTitleSelected || editSceneTitle) ?
				<TextInput
					focused={isSelected && editSceneTitle}
					value={sceneName}
					ellispsis={true}
					title={sceneName}
					onFinalChange={e =>
						onSetMultipleEntityProps_Ed_Doc([
							{
								id: sceneId,
								titles: e.currentTarget.value,
							},
						])
					}
					onUnmount={textValue =>
						onSetMultipleEntityProps_Ed_Doc([
							{
								id: sceneId,
								titles: textValue,
							},
						])
					}
					onClick={() => setIsTitleSelected(true)}
					onBlur={() => {
						setIsTitleSelected(false); 
						setIsTitleHovered(false);
						onEditSceneTitle(false);
					}}
					onMouseLeave={() => { 
						if(!isTitleSelected) setIsTitleHovered(false)
					}}
	   			/> : 
				<span
					onMouseEnter={() => setIsTitleHovered(true)}
				>{sceneName}</span>
			}
			 
		</div>
		</div>
	);
};

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	return {
		selectedEntityIds: state.userReducer.selectedEntityIds,
		activeSceneId: state.userReducer.activeSceneId,
		projectId: state.userReducer.project.id,
		editSceneTitle: state.userReducer.editSceneTitle,
		currentSceneIndex: state.userReducer.currentSceneIndex,
		sceneIdSequence: getRootComponent(state)?.children || [],
		sceneCarouselScrollLeft: state.userReducer.sceneCarouselScrollLeft,
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onRemoveSelection,
			onSetActiveScene,
			onSetCurrentSceneIndex,
			onSetSceneSnapshots,
			onSetMultipleEntityProps_Ed_Doc,
			onScrollSceneMenu, 
			onEditSceneTitle, 
			onCopyScenesAtIndex_Global,
			onFocusSceneNameInput,
			onRemoveScenes_Global,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(SceneSlide);
