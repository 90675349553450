// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SearchBarContainer--2Zbe_{display:flex}.SearchBarContainer--2Zbe_ input{height:40px;outline:none;border:1px solid #b2c4d7;border-radius:5px 0 0 5px;flex-grow:1;padding:6px 6px 6px 10px;margin-bottom:8px}.SearchBarContainer--2Zbe_ button{width:40px;height:40px;border:1px solid #b2c4d7;border-radius:0 5px 5px 0;border-left:0}.SearchBarContainer--2Zbe_.Focused--3hy5c input,.SearchBarContainer--2Zbe_.Focused--3hy5c button{border:1px solid #4a90e2}.SearchBarContainer--2Zbe_.Focused--3hy5c button{border-left:0}", ""]);
// Exports
exports.locals = {
	"SearchBarContainer": "SearchBarContainer--2Zbe_",
	"Focused": "Focused--3hy5c"
};
module.exports = exports;
