import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const leftIcon: React.FunctionComponent<IIconParentProps> = ({
	className,
	hexFill: fill,
	secondHexFill: secondFill, 
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	const secondHexFill = secondFill; 
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="68px"
			height="68px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 68 68'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			 <defs>
                <rect id="path-1" x="0" y="0" width="68" height="68" rx="5"></rect>
                <linearGradient x1="83.0605997%" y1="50%" x2="0%" y2="50%" id="linearGradient-3">
                    <stop stopColor={secondHexFill} stopOpacity="0" offset="0%"></stop>
                    <stop stopColor={secondHexFill} offset="100%"></stop>
                </linearGradient>
            </defs>
            <g id="Components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Illustration">
                    <mask id="mask-2" fill="white">
                        <use href="#path-1"></use>
                    </mask>
                    <g id="Mask"></g>
                    <polygon id="Path-Copy-9" fill="url(#linearGradient-3)" fillRule="nonzero" mask="url(#mask-2)" points="40.8 22.6148507 43.3328447 6.8 75.6328447 6.8 75.0472582 18.6534773 74.38811 31.9959974 74.38811 45.4802808 72.536306 59.8 55.6572612 59.8 41.2300323 45.4802808 53.98811 31.9959974"></polygon>
                    <polygon id="Path-Copy-2" fill={hexFill} mask="url(#mask-2)" points="28.9 22.6148507 43.3328447 6.8 63.9 30.8755409 46.3993196 45.675647 55.6572612 59.8 29.3300323 45.4802808 42.08811 31.9959974"></polygon>
                    <path d="M27.5983051,37.6440671 L17.7766766,27.8224386 C17.4568192,27.4959855 17.0190219,27.3120213 16.5619875,27.3120213 C16.1049532,27.3120213 15.6671559,27.4959855 15.3472985,27.8224386 L5.52567002,37.6440671 C4.93083212,38.3283249 4.96303611,39.3551793 5.59958197,40.0008186 C6.23612783,40.646458 7.26242216,40.6932233 7.95504809,40.1081506 L16.632573,31.5017993 L25.3077486,40.1775614 C25.627606,40.5040145 26.0654033,40.6879787 26.5224376,40.6879787 C26.979472,40.6879787 27.4172692,40.5040145 27.7371267,40.1775614 C28.0703154,39.8306239 28.2445455,39.3609488 28.2182277,38.8806492 C28.1919099,38.4003496 27.9674057,37.952523 27.5983051,37.6440671 Z" id="Path" fill={hexFill} mask="url(#mask-2)" transform="translate(16.660415, 34.000000) scale(-1, 1) rotate(90.000000) translate(-16.660415, -34.000000) "></path>
                </g>
            </g>
		</svg>
	);
};

export default leftIcon;
