import React, {FunctionComponent} from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const textAlignCenter: FunctionComponent<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="26px"
			height="18px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 26 18'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g id="Inspect-menu-/-Button" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
				<g id="Button-/-Inspector-/-Text" transform="translate(-19.000000, -488.000000)" stroke={hexFill}>
					<g id="Group" transform="translate(19.000000, 116.000000)">
						<g id="Group-2" transform="translate(1.000000, 339.000000)">
							<g id="Group" transform="translate(0.000000, 33.000000)">
								<line x1="0" y1="0.5" x2="24" y2="0.5" id="Line-3"></line>
								<line x1="0" y1="11.5" x2="24" y2="11.5" id="Line-3-Copy-2"></line>
								<line x1="0.0295161711" y1="6.5" x2="16.0285097" y2="6.5" id="Line-3-Copy"></line>
								<line x1="0.0295161711" y1="17.5" x2="16.0285097" y2="17.5" id="Line-3-Copy-3"></line>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default textAlignCenter;
