// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ColorPickerMenu--23c5s{width:255px;padding:16px;border-radius:8px;box-shadow:0 2px 4px 0 rgba(52,75,96,.1),0 1px 3px 0 rgba(52,75,96,.2);background-color:#fff;position:absolute;bottom:50px;left:9px}.ColorPickerMenu--23c5s div{color:#5c5c5c}.ColorPickerMenu--23c5s .ColorPickerMargin--1B0ih{margin-top:16px}.OutsideClickDiv--eqfS2{height:100vh;width:100vw;background-color:rgba(255,255,255,0);position:fixed;top:0;left:0;z-index:999}.FlexRow--2_Rpl{display:flex;align-items:center;justify-content:start;margin:8px 0}.AddColorBtn--brSjn{width:24px;height:24px;border-radius:100%;display:flex;align-items:center;justify-content:center;border:1px solid #efefef}.AddColorBtn--brSjn svg{width:6px;height:6px}.AddColorBtn--brSjn{width:24px;height:24px;border-radius:100%;display:flex;align-items:center;justify-content:center;border:1px solid #efefef}.AddColorBtn--brSjn svg{width:6px;height:6px}.RemoveColorBtn--3I48L{width:28px;height:28px;border-radius:100%;display:flex;align-items:center;justify-content:center;border:1px solid #d87b7b}.RemoveColorBtn--3I48L svg{width:12px;height:12px}.GridContainer--3LAz-{display:grid;grid-template-columns:32px 32px 32px 32px 32px 32px 32px;margin:8px 0}.GridContainer--3LAz- .GridCell--3BuHk{height:32px;width:32px;display:flex;justify-content:center;align-items:center}", ""]);
// Exports
exports.locals = {
	"ColorPickerMenu": "ColorPickerMenu--23c5s",
	"ColorPickerMargin": "ColorPickerMargin--1B0ih",
	"OutsideClickDiv": "OutsideClickDiv--eqfS2",
	"FlexRow": "FlexRow--2_Rpl",
	"AddColorBtn": "AddColorBtn--brSjn",
	"RemoveColorBtn": "RemoveColorBtn--3I48L",
	"GridContainer": "GridContainer--3LAz-",
	"GridCell": "GridCell--3BuHk"
};
module.exports = exports;
