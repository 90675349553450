import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR, WHITE_ICON_COLOR} from '../../utils';

const downIcon: React.FunctionComponent<IIconParentProps> = ({
	className,
    hexFill: fill,
    secondHexFill: secondFill, 
	viewBox,
	onClick = () => {},
}) => {
    const hexFill = fill || DEFAULT_ICON_COLOR;
    const secondHexFill = secondFill; 
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="68px"
			height="68px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 68 68'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<defs>
				<path d="M5,-1.93267624e-13 L63,-1.93267624e-13 C65.7614237,-1.93774889e-13 68,2.23857625 68,5 L68,63 C68,65.7614237 65.7614237,68 63,68 L5,68 C2.23857625,68 3.38176876e-16,65.7614237 0,63 L0,5 C-3.38176876e-16,2.23857625 2.23857625,-1.92760359e-13 5,-1.93267624e-13 Z" id="path-1"></path>
				<linearGradient x1="50%" y1="-2.48949813e-15%" x2="50%" y2="100%" id="linearGradient-3">
					<stop stopColor={secondHexFill} stopOpacity="0" offset="0%"></stop>
					<stop stopColor={secondHexFill} offset="100%"></stop>
				</linearGradient>
			</defs>
			<g id="Components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Illustration">
            <mask id="mask-2" fill="white">
                <use href="#path-1"></use>
            </mask>
            <g id="Mask"></g>
            <g mask="url(#mask-2)">
                <g transform="translate(17.000000, -20.400000)">
                    <polygon id="Path-Copy-9" fill="url(#linearGradient-3)" fillRule="nonzero" points="0.430032272 15.8148507 7.63284472 9.05941988e-15 28.0328447 9.05941988e-15 35 11.8534773 35 24.0755409 26.78811 30.9020874 26.78811 53 0.430032272 38.6802808"></polygon>
                    <polygon id="Path-Copy-4" fill={hexFill} fillRule="nonzero" points="1.09618981e-12 15.8148507 14.4328447 1.20792265e-14 35 24.0755409 17.4993196 38.875647 26.7572612 53 0.430032272 38.6802808 13.18811 25.1959974"></polygon>
                    <path d="M27.5983051,76.6320458 L17.7766766,66.8104173 C17.4568192,66.4839642 17.0190219,66.3 16.5619875,66.3 C16.1049532,66.3 15.6671559,66.4839642 15.3472985,66.8104173 L5.52567002,76.6320458 C4.93083212,77.3163036 4.96303611,78.343158 5.59958197,78.9887973 C6.23612783,79.6344367 7.26242216,79.681202 7.95504809,79.0961293 L16.632573,70.489778 L25.3077486,79.1655401 C25.627606,79.4919932 26.0654033,79.6759574 26.5224376,79.6759574 C26.979472,79.6759574 27.4172692,79.4919932 27.7371267,79.1655401 C28.0703154,78.8186026 28.2445455,78.3489275 28.2182277,77.8686279 C28.1919099,77.3883283 27.9674057,76.9405017 27.5983051,76.6320458 Z" id="Path-Copy-7" fill={hexFill} fillRule="evenodd" transform="translate(16.660415, 72.987979) scale(1, -1) translate(-16.660415, -72.987979) "></path>
                </g>
            </g>
            <g id="Group-3-Copy-2" mask="url(#mask-2)" strokeLinecap="round">
                <g transform="translate(32.000000, 5.000000) scale(-1, -1) rotate(-90.000000) translate(-32.000000, -5.000000) translate(15.500000, -6.500000)">
                    <g id="Group" transform="translate(13.500000, 21.000000)">
                        <line x1="0.5" y1="1" x2="17.5" y2="1" id="Line-9-Copy-3" stroke={WHITE_ICON_COLOR}></line>
                        <line x1="0.5" y1="0.5" x2="17.5" y2="0.5" id="Line-9-Copy-4" stroke={hexFill}></line>
                    </g>
                    <g id="Group-Copy" transform="translate(14.500000, 0.500000)">
                        <line x1="0.5" y1="1" x2="17.5" y2="1" id="Line-9-Copy-3" stroke={WHITE_ICON_COLOR}></line>
                        <line x1="0.5" y1="0.5" x2="17.5" y2="0.5" id="Line-9-Copy-4" stroke={hexFill}></line>
                    </g>
                    <g id="Group-2" transform="translate(0.500000, 9.500000)">
                        <line x1="0.5" y1="1" x2="17.5" y2="1" id="Line-9-Copy" stroke={WHITE_ICON_COLOR}></line>
                        <line x1="0.5" y1="0.5" x2="17.5" y2="0.5" id="Line-9-Copy-2" stroke={hexFill}></line>
                    </g>
                </g>
            </g>
        </g>
    </g>
		</svg>
	);
};

export default downIcon;
