import {
	IOnProgressCb,
	IOnCompletionCb,
	IOnErrorCb,
	IOnAnalysisProgressCb,
	IOnAnalysisCompletionCb,
	IUploadFileTypes,
	IOnErrorData,
	IOnProgressData,
	IOnCompletionData,
} from './specs';
import { IFileProgressInfo, IProgressStatus } from 'zw-api-client/src/browser';
import {
	ILoadedMediaTypes,
	IOnSetCurrentUploads,
	IOnAddToLoadedMedia,
	IAddMediaPositionTypes,
	onAddTracking_Ed_Doc,
	onAddTracking_Cn_Doc,
	IOnAddToast,
	onAddToast,
} from '../../../store/actions';
import {
	ILoadedImageInfo,
	ILoadedUserVideoInfo,
	ILoadedAudioInfo,
	IUploadInfoDict,
	ITrkImgData,
	ILoadedModel3dInfo,
} from '../../../../typings';
import { IErrorText } from './specs';
import { VideoSourceTypes } from '../../../utils';
import uuid4 from 'uuid/v4';
import Hls from 'hls.js';
import { ITrackingTypes } from '../../r3f/r3f-components/component-data-structure';
import { IFileType, IMediaFileOutput, IModel3dData, IVideoData } from 'zw-api-client/src/zml';
import { Dispatch } from 'redux';
import { ToastsData } from '../../../utils/toasts-data';
import { zwClient } from '../../../syncdoc';

export interface IUploadIndexToFileDict {
	[index: string]: {
		file: File;
		id: string;
	};
}

export const uploadResponseHandler = (args: {
	onProgress: IOnProgressCb;
	onCompletion: IOnCompletionCb;
	onError: IOnErrorCb;
	progressData: IFileProgressInfo;
	perc: number;
	fileInfo: { id: string; file: File };
	fileType: ILoadedMediaTypes;
}) => {
	const {
		onProgress,
		onCompletion,
		onError,
		progressData: { mediaData: data, errorCode, status: progressStatus, desc },
		perc,
		fileInfo: {
			id,
			file: { name: fileName },
		},
		fileType,
	} = args;

	const baseProgressData = { id, fileName };

	switch (progressStatus) {
		case IProgressStatus.uploading:
			if (!onProgress) return;
			onProgress({ ...baseProgressData, type: IProgressStatus.uploading, perc });
			if (perc !== 100) return;
			onProgress({
				...baseProgressData,
				type: IProgressStatus.processing,
				perc: 0,
			});
			break;
		case IProgressStatus.processing:
			if (!onProgress) return;
			onProgress({
				...baseProgressData,
				type: IProgressStatus.processing,
				perc,
			});
			break;
		case IProgressStatus.completed:
			if (!onCompletion) return;
			onCompletion({
				...baseProgressData,
				data,
			});
			break;
		case IProgressStatus.error:
			if (onError) {
				onError({
					...baseProgressData,
					status: errorCode,
					desc: desc || '',
					type: IProgressStatus.error,
					mediaType: fileType,
				});
			}
			break;
		default:
			break;
	}
};

export const checkAndFilterFilesByType = (args: {
	files: FileList;
	allowedFileTypes: string[];
	onError: IOnErrorCb;
	fileType: ILoadedMediaTypes;
}) => {
	const { files, allowedFileTypes, onError, fileType } = args;
	//convert filelist to file array
	let allFileArray: File[] = [];
	for (let i = 0; i < files.length; i++) {
		// if unknown file type use extension instead
		let fileApiType = files[i].type || `.${files[i].name.split('.').pop()}`;

		if (allowedFileTypes.includes(IUploadFileTypes.video_all)) {
			if (fileApiType.split('/')[0] === 'video') {
				allFileArray.push(files[i]);
				continue;
			} else if (onError) {
				onError({
					id: uuid4(),
					fileName: files[i].name,
					desc: IErrorText.formatNotSupported,
					type: IProgressStatus.error,
					mediaType: fileType,
				});
				continue;
			}
		} 
		if (!allowedFileTypes.includes(fileApiType)) {
			if (onError) {
				onError({
					id: uuid4(),
					fileName: files[i].name,
					desc: IErrorText.formatNotSupported,
					type: IProgressStatus.error,
					mediaType: fileType,
				});
			}
		} else allFileArray.push(files[i]);
	}
	const duplicateFileNames = allFileArray
		.map(file => file.name)
		.reduce(
			(acc, v, i, arr) =>
				arr.indexOf(v) !== i && acc.indexOf(v) === -1 ? acc.concat(v) : acc,
			[]
		)
		.map((fileName: string) => {
			if (onError) {
				onError({
					id: uuid4(),
					fileName,
					desc: IErrorText.fileDuplicate,
					type: IProgressStatus.error,
					mediaType: fileType,
				});
			}
			return fileName;
		});

	const allFilteredFiles = allFileArray.filter(
		file => !duplicateFileNames.includes(file.name)
	);
	return allFilteredFiles;
};

export const returnFileType = (mediaType: IUploadFileTypes) => {
	switch (mediaType) {
		case IUploadFileTypes.model_glb:
		case IUploadFileTypes.model_gltf:
		case IUploadFileTypes.model_glb_ext:
		case IUploadFileTypes.model_gltf_ext:
			return ILoadedMediaTypes.model3d;
		case IUploadFileTypes.image_png:
		case IUploadFileTypes.image_jpeg:
			return ILoadedMediaTypes.image;
		case IUploadFileTypes.video_quicktime:
		case IUploadFileTypes.video_mpeg:
		case IUploadFileTypes.video_mp4:
		case IUploadFileTypes.video_avi:
		case IUploadFileTypes.video_webm:
		case IUploadFileTypes.video_3gp:
		case IUploadFileTypes.video_flv:
			return ILoadedMediaTypes.video;
		case IUploadFileTypes.audio_wav:
		case IUploadFileTypes.audio_ogg:
		case IUploadFileTypes.audio_mpeg:
		case IUploadFileTypes.audio_m4a:
			return ILoadedMediaTypes.audio;
		default:
			return null;
	}
};

export const onErrorHandler = (
	e: IOnErrorData,
	onSetCurrentUploads: IOnSetCurrentUploads,
	dispatch: Dispatch<any>
) => {
	const { id, fileName, status: errorStatus, desc, mediaType } = e;
	dispatch(onSetCurrentUploads(id, {
		errorText: desc || 'error uploading',
		errorStatus,
		fileName,
		mediaType,
		type: IProgressStatus.error,
	}));
};

export const onProgressHandler = (
	p: IOnProgressData,
	onSetCurrentUploads: IOnSetCurrentUploads,
	dispatch: Dispatch<any>
) => {
	const { id, ...rest } = p;
	dispatch(onSetCurrentUploads(id, rest));
};

export const onCompletionHandler = (
	c: IOnCompletionData,
	onSetCurrentUploads: IOnSetCurrentUploads,
	mediaType: ILoadedMediaTypes,
	onAddToLoadedMedia: IOnAddToLoadedMedia,
	dispatch: Dispatch<any>
) => {
	const {
		id,
		data: { url, id: zmlFileId, caption: name, filename: fileName, output },
	} = c;

	dispatch(onSetCurrentUploads(id, {
		fileName,
		type: IProgressStatus.completed,
		perc: 100,
	}));
	setTimeout(() => {
		dispatch(onSetCurrentUploads(id, null));
	}, 2000);

	const baseCompletionData = { id: uuid4(), zmlFileId, name };

	switch (mediaType) {
		case ILoadedMediaTypes.image: {
			const o = output as IMediaFileOutput;
			if (o.AspectRatio) {
				return dispatch(onAddToLoadedMedia({
					mediaType: ILoadedMediaTypes.image,
					position: IAddMediaPositionTypes.start,
					mediaInfo: [
						{
							...baseCompletionData,
							url: `https:${url}/img`,
							aspectRatio: o.AspectRatio,
							hasAlpha: (baseCompletionData.name.includes('.png') ? true : false)
						},
					],
				}));
			}
			calcImgAspectRatio(`https:${url}/img`, ratio => {
				const loadedImageInfo: ILoadedImageInfo[] = [
					{
						...baseCompletionData,
						url: `https:${url}/img`,
						aspectRatio: ratio,
						hasAlpha: (baseCompletionData.name.includes('.png') ? true : false)
					},
				];
				dispatch(onAddToLoadedMedia({
					mediaType: ILoadedMediaTypes.image,
					position: IAddMediaPositionTypes.start,
					mediaInfo: loadedImageInfo,
				}));
			});

			break;
		}
		case ILoadedMediaTypes.model3d: {
			const {output } = c.data as IModel3dData;

			calcImgAspectRatio(output.thumbnailURL, ratio => {
				const loaded3dModelInfo: ILoadedModel3dInfo[] = [
					{
						...baseCompletionData,
						model3dUrl: output.modelURL,
						aspectRatio: ratio,
						thumbnailUrl: output.thumbnailURL,
						dimensions: [output.dimensions.x, output.dimensions.y, output.dimensions.z]
					},
				];
				dispatch(onAddToLoadedMedia({
					mediaType: ILoadedMediaTypes.model3d,
					position: IAddMediaPositionTypes.start,
					mediaInfo: loaded3dModelInfo,
				}));
			})
			break;
		}
		case ILoadedMediaTypes.video: {
			const { output, url } = (c.data as IVideoData);
			calcImgAspectRatio(output.thumbnailURL, ratio => {
				const loadedVideoInfo: ILoadedUserVideoInfo[] = [
					{
						...baseCompletionData,
						videoUrl: `https:${url}/auto.m3u8`,
						aspectRatio: ratio,
						hasAlpha: output.hasAlpha,
						source: VideoSourceTypes.user,
						thumbnailUrl: output.thumbnailURL,
						mp4Url: output.mp4URL
					},
				];
				dispatch(onAddToLoadedMedia({
					mediaType: ILoadedMediaTypes.video,
					position: IAddMediaPositionTypes.start,
					mediaInfo: loadedVideoInfo,
				}));
			})
			break;
		}
		case ILoadedMediaTypes.audio:
			const loadedAudioInfo: ILoadedAudioInfo[] = [
				{
					...baseCompletionData,
					url: `https:${url}/aud.mp3`,
				},
			];

			dispatch(onAddToLoadedMedia({
				mediaType: ILoadedMediaTypes.audio,
				position: IAddMediaPositionTypes.start,
				mediaInfo: loadedAudioInfo,
			}));
			break;
		case ILoadedMediaTypes.trackingImage: {
			const o = output as IMediaFileOutput;
			dispatch(onAddTracking_Cn_Doc({
				type: ITrackingTypes.image,
				zmlId: id,
				url: `https:${url}/uploaded.zpt`
			}))
			const imageUrl = `https:${url}/img`;
			if (o.AspectRatio) {
				return dispatch(onAddTracking_Ed_Doc({
					type: ITrackingTypes.image,
					id,
					url: imageUrl,
					aspectRatio: o.AspectRatio,
					name,
				}));
			}
			calcImgAspectRatio(imageUrl, ratio => {
				dispatch(onAddTracking_Ed_Doc({
					type: ITrackingTypes.image,
					id,
					url: imageUrl,
					aspectRatio: ratio,
					name,
				}));
			});
			break;
		}
		default:
			break;
	}
};

export const onSubmitHandler = (
	fileDict: IUploadIndexToFileDict,
	onSetCurrentUploads: IOnSetCurrentUploads,
	dispatch: Dispatch<any>
) => {
	Object.keys(fileDict).forEach(uploadId => {
		const { file, id } = fileDict[uploadId];
		const loadedMediaType = returnFileType(file.type as IUploadFileTypes);

		switch (loadedMediaType) {
			case ILoadedMediaTypes.image:
				dispatch(onSetCurrentUploads(id, { mediaType: ILoadedMediaTypes.image }));
				break;
			case ILoadedMediaTypes.video:
				dispatch(onSetCurrentUploads(id, { mediaType: ILoadedMediaTypes.video }));
				break;
			case ILoadedMediaTypes.audio:
				dispatch(onSetCurrentUploads(id, { mediaType: ILoadedMediaTypes.audio }));
				break;
			case ILoadedMediaTypes.model3d:
				dispatch(onSetCurrentUploads(id, { mediaType: ILoadedMediaTypes.model3d }));
				break;
			default:
				break;
		}
	});
};

export const calcImgAspectRatio = (
	url: string,
	ratioCb: (ratio: number) => any
) => {
	const img = new Image();
	img.addEventListener('load', function() {
		ratioCb(this.naturalWidth / this.naturalHeight);
	});
	img.src = url;
};

export const calcVideoAspectRatio = (
	url: string,
	ratioCb: (ratio: number, hlsUrl: string) => any
) => {
	const vidObj = document.createElement('video');
	const hlsUrl = `https:${url}/auto.m3u8`; //auto.m3u8 low.m3u8 high.m3u8 hd.m3u8
	if (Hls.isSupported()) {
		var hls = new Hls();
		// bind them together
		hls.attachMedia(vidObj);
		hls.on(Hls.Events.MEDIA_ATTACHED, function () {
		//   console.log("video and hls.js are now bound together !");
		  hls.loadSource(hlsUrl);
		  hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
			// console.log('video loaded!!', vidObj.videoWidth / vidObj.videoHeight);
			// ratioCb(vidObj.videoWidth / vidObj.videoHeight, hlsUrl)
			ratioCb(3 / 1, hlsUrl)
			// console.log("manifest loaded, found " + data.levels.length + " quality level");
		  });
		});
	}
	
	// vidObj.addEventListener('loadeddata', function() {
	// 	console.log('data loaded!!!')
	// 	ratioCb(this.videoWidth / this.videoHeight, hlsUrl);
	// });
	// vidObj.setAttribute('src', hlsUrl);
};

export const calcTotalUploadPerc = (
	numberUploads: number,
	currentUploads: IUploadInfoDict
) => {
	const currentUploadFileNames = Object.keys(currentUploads);
	let totalUploadProgressPerc = 0;
	const adjFactor = numberUploads * 2;
	const numberCompletedUploads = numberUploads - currentUploadFileNames.length;
	totalUploadProgressPerc = (numberCompletedUploads * 2 * 100) / adjFactor;
	// uploads consist of processing & actual upload assumed taking 50:50 time

	currentUploadFileNames.forEach((fln, i) => {
		if (currentUploads[fln].type === IProgressStatus.processing
			|| currentUploads[fln].type === IProgressStatus.completed
			|| currentUploads[fln].type === IProgressStatus.trainingCompleted
		) {
			totalUploadProgressPerc += 100 / adjFactor; // 100% of loading already done, so added;
		}
		if (currentUploads[fln].perc)
			totalUploadProgressPerc += currentUploads[fln].perc / adjFactor;
	});
	return totalUploadProgressPerc;
};

export const imgQualityNumToTxt = (trkImgData: ITrkImgData | null) => {
	if (!trkImgData) return '';
	let txt = 'Poor';
	if (trkImgData?.quality === 2) txt = 'Moderate';
	if (trkImgData?.quality === 3) txt = 'Good';
	return txt;
};

export const setUploadTitle = (
	numUploads: number,
	numErrors: number,
) => {
	let title = `Uploading ${numUploads > 1 ? numUploads + ' ' : ''} asset${numUploads > 1 ? 's' : ''}...`;
	if (numErrors === 1) title = 'Asset upload failed';
	if (numErrors > 1) title = 'Assets upload failed';

	return title;
};

export const createUploadIndexToFileDict = (
	files: FileList, 
	allowedFileTypes: string[],
	onError: IOnErrorCb,
	fileType: ILoadedMediaTypes,
	dispatch: Dispatch<any>,
	onProgress?: IOnProgressCb
) => {
	// checkAndFilterFilesByType filters out filename duplicates
	const allowedFiles = checkAndFilterFilesByType({
		files,
		allowedFileTypes,
		onError,
		fileType,
	});

	let imageFilesArray: File[] = [];
	let videoFilesArray: File[] = [];
	let audioFilesArray: File[] = [];
	let model3dFilesArray: File[] = [];
	let uploadIndexToFileDict: IUploadIndexToFileDict = {};

	for (let i = 0; i < allowedFiles.length; i++) {
		const id = uuid4();
		const file = allowedFiles[i];

		const fileType = returnFileType((allowedFiles[i].type || `.${files[i].name.split('.').pop()}`) as IUploadFileTypes);
		switch (fileType) {
			case ILoadedMediaTypes.model3d: {
				if (file.size > 1000000 * 10) { // larger than 10 MB
					dispatch(onAddToast(ToastsData.MaxUploadSizeExceededError(10, 'model')))
					continue;
				} 
				model3dFilesArray.push(file);
				uploadIndexToFileDict[`model3d_${model3dFilesArray.length - 1}`] = {
					id,
					file,
				};
				break;
			}
			case ILoadedMediaTypes.image: {
				if (file.size > 1000000 * 10) { // larger than 10 MB
					dispatch(onAddToast(ToastsData.MaxUploadSizeExceededError(10, 'image')))
					continue;
				}
				imageFilesArray.push(file);
				uploadIndexToFileDict[`image_${imageFilesArray.length - 1}`] = {
					id,
					file,
				};
				break;
			}	
			case ILoadedMediaTypes.video: {
				if (file.size > 1000000 * 200) { // larger than 200 MB
					dispatch(onAddToast(ToastsData.MaxUploadSizeExceededError(200, 'video')))
					continue;
				}
				videoFilesArray.push(file);
				uploadIndexToFileDict[`video_${videoFilesArray.length - 1}`] = {
					id,
					file,
				};
				break;
			}
			case ILoadedMediaTypes.audio: {
				if (file.size > 1000000 * 50) { // larger than 50 MB
					dispatch(onAddToast(ToastsData.MaxUploadSizeExceededError(50, 'audio')))
					continue;
				}
				audioFilesArray.push(file);
				uploadIndexToFileDict[`audio_${audioFilesArray.length - 1}`] = {
					id,
					file,
				};
				break;
			}
			default:
				break;
		}
		if (!onProgress) continue;
		onProgress({
			id,
			fileName: file.name,
			type: IProgressStatus.uploading,
			perc: 0,
		});
	}
	return {uploadIndexToFileDict, imageFilesArray, videoFilesArray, audioFilesArray, model3dFilesArray};
}

export const uploadMedia = async(
	uploadIndexToFileDict: IUploadIndexToFileDict, 
	imageFilesArray: File[],
	videoFilesArray: File[],
	audioFilesArray: File[],
	model3dFilesArray: File[],
	fileType: ILoadedMediaTypes,
	onError: IOnErrorCb,
	onCompletion: IOnCompletionCb,
	onProgress: IOnProgressCb,
) => {
	await zwClient.zml.uploadImages(imageFilesArray, (perc, progressData) => {
		uploadResponseHandler({
			onProgress,
			onCompletion,
			onError,
			progressData,
			perc,
			fileInfo: uploadIndexToFileDict[`image_${progressData.id}`],
			fileType,
		});
	});
	await zwClient.zml.uploadVideos(videoFilesArray, (perc, progressData) => {
		uploadResponseHandler({
			onProgress,
			onCompletion,
			onError,
			progressData,
			perc,
			fileInfo: uploadIndexToFileDict[`video_${progressData.id}`],
			fileType,
		});
	});

	await zwClient.zml.uploadAudio(audioFilesArray, (perc, progressData) => {
		uploadResponseHandler({
			onProgress,
			onCompletion,
			onError,
			progressData,
			perc,
			fileInfo: uploadIndexToFileDict[`audio_${progressData.id}`],
			fileType,
		});
	});
	await zwClient.zml.upload3dModels(model3dFilesArray, (perc, progressData) => {
		uploadResponseHandler({
			onProgress,
			onCompletion,
			onError,
			progressData,
			perc,
			fileInfo: uploadIndexToFileDict[`model3d_${progressData.id}`],
			fileType,
		});
	})
}


