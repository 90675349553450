import React, {
	FunctionComponent,
	memo,
	useMemo,
} from 'react';
import DreiText from './DreiText';
import { TextureLoader } from 'three';
import {
	ITuple3,
	ITuple4,
	FONT_TYPES,
	IFontTypes,
	ITextAlignment,
} from '../component-data-structure';
import { IButton } from '../component-data-structure';
import RoundedRectWithBorder from './RoundedRectWithBorder';
import { ISharedHOCProps } from '../types';
import { IUserData } from '../utils/general';
import { useTextureLoader } from '../hooks/useTextureLoader';
import * as THREE from 'three';
import Svg from './Svg/Svg';
import { useSRGBColors } from '../hooks/useSRGBColors';

const Button: FunctionComponent<IButton & ISharedHOCProps> = ({
  scale: s,
  color: c,
  text,
  borderWidth: bw = 0,
  borderRgba: bCol = [255, 255, 255, 1],
  fontFamily = IFontTypes.Roboto,
  borderRadius: radius = 0,
  fontSize = 2,
  fontRgba = [0, 0, 0, 1] as ITuple4,
  position,
  rotation,
  textAlignment = ITextAlignment.center,
  textureUrl = undefined,
  svgUrl = undefined,
  onPointerUp,
  onPointerOver,
  onPointerOut,
  onPointerMove,
  onPointerDown,
  onDoubleClick,
  renderOrder = 0,
  id = "",
}) => {
  // memoize reference type props
  const zeroPos = useMemo(() => [0, 0, 0], []);
  const brgba = useMemo(() => bCol, [bCol]);
  const fontColor = useSRGBColors(fontRgba)
  const scale = useMemo(() => s, [s]);
  const userData: IUserData = useMemo(() => ({ renderOrder, contentId: id }), [id, renderOrder]);
  let color = useMemo(() => c, [c]);
  const texture = useTextureLoader(TextureLoader, textureUrl, false);
  if (texture) texture.encoding = THREE.sRGBEncoding;
  const isSvg = svgUrl ? true : false;
  const disableDoubleClick = svgUrl !== undefined || textureUrl !== undefined;
  // console.log(isSvg);

  const filteredArray = useMemo(() => FONT_TYPES.filter((font) => font.fontFamily === fontFamily), [fontFamily]);

  const padding = scale[0] / 20;
  // set button text x-position depending on text alignment
  let xPos: number;
  let anchorXPos: "left" | "right" | "center" | undefined;
  switch (textAlignment) {
    case ITextAlignment.left: {
      xPos = -scale[0] / 2 + padding;
      anchorXPos = "left";
      break;
    }
    case ITextAlignment.right: {
      xPos = scale[0] / 2 - padding;
      anchorXPos = "right";
      break;
    }
    default: {
      xPos = 0;
      anchorXPos = "center";
    }
  }
  const textPos = useMemo(() => [xPos, 0, 0] as ITuple3, [xPos]);

  if (!filteredArray.length) return null;

  return (
    <group
      key={"btn_group"}
      name={"btn_group"}
      userData={userData}
      // renderOrder={renderOrder}
      position={position as ITuple3}
      rotation={rotation as ITuple3}
      onPointerUp={onPointerUp || undefined}
      onPointerDown={onPointerDown || undefined}
      onPointerMove={onPointerMove || undefined}
      onDoubleClick={(!disableDoubleClick && onDoubleClick) || undefined}
    >
      {isSvg ? (
        <>
          <RoundedRectWithBorder
            key={"svg_btn_round_rect"}
            name={id}
            scale={scale as ITuple3}
            radius={radius}
            position={zeroPos as ITuple3}
            borderWidth={0}
            fillRgba={[0, 0, 0, 0] as ITuple4}
            borderRgba={brgba as ITuple4}
            opacity={0}
            renderOrder={renderOrder}
            depthWrite={false}
            visible={false}
          />
          <Svg
            key={"svg_btn"}
            name={id}
            scale={scale as ITuple3}
            position={[0, 0, 0]}
            opacity={1}
            depthWrite={false}
            renderOrder={renderOrder}
            svgUrl={svgUrl as string}
          />
        </>
      ) : (
        <>
          <RoundedRectWithBorder
            key={"btn_round_rect"}
            name={id}
            scale={scale as ITuple3}
            radius={radius}
            position={zeroPos as ITuple3}
            borderWidth={bw}
            fillRgba={color as ITuple4}
            borderRgba={brgba as ITuple4}
            opacity={texture ? 1 : color[3]}
            renderOrder={renderOrder}
            depthWrite={false}
            texture={texture}
          />
          <DreiText
            key={"btn_text"}
            name={id}
            color={fontColor}
            alpha={fontRgba[3]}
            fontSize={fontSize}
            position={textPos}
            url={filteredArray[0]?.url}
            textAlign={textAlignment}
            anchorY={"middle"} // always middle
            anchorX={anchorXPos} // always left side at btn x center
            maxWidth={s[0] - bw * 2 - padding * 2}
            renderOrder={renderOrder + 5}
            depthWrite={false}
            // visible={txtIsVisible}
            onPointerOver={onPointerOver || undefined}
            onPointerOut={onPointerOut || undefined}
          >
            {text}
          </DreiText>
        </>
      )}
    </group>
  );
};

Button.displayName = 'Button';
export default memo(Button);
