// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FlexContainer--1sRPI{display:flex;flex-direction:column;align-items:center}.Description--8AZ8j{color:#a5a5a5;font-size:14px;align-self:flex-start}.LinkContainerDiv--rmxFJ{width:100%;display:flex;flex-direction:row}.LinkContainerDiv--rmxFJ.TargetLink--UIWb3{text-decoration:none;margin:8px 0 24px 0;font-size:16px}.LinkContainerDiv--rmxFJ.TargetLink--UIWb3 div{color:#4a90e2}.LinkContainerDiv--rmxFJ .IconContainer--2RAns{margin-right:8px;min-width:20px}.LinkContainerDiv--rmxFJ .IconContainer--2RAns .ProviderIcon--3QA3_{height:20px;width:20px}.LinkContainerDiv--rmxFJ .URL--3RP-g{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}", ""]);
// Exports
exports.locals = {
	"FlexContainer": "FlexContainer--1sRPI",
	"Description": "Description--8AZ8j",
	"LinkContainerDiv": "LinkContainerDiv--rmxFJ",
	"TargetLink": "TargetLink--UIWb3",
	"IconContainer": "IconContainer--2RAns",
	"ProviderIcon": "ProviderIcon--3QA3_",
	"URL": "URL--3RP-g"
};
module.exports = exports;
