import React, { useMemo, useEffect, FunctionComponent, memo } from 'react';
import { Html } from '@react-three/drei';
import {
	IImageEntityDragInfo,
	IBtnEntityDragInfo,
	IUnionVideoEntityDragInfo,
	ITextEntityDragInfo,
	IModel3dEntityDragInfo,
} from '../../../store/actions';
import CSS from './DragElement.scss';
import {
	ITuple3,
	IComponentType,
} from '../../r3f/r3f-components/component-data-structure';

interface IParentProps {
	zoomLevel: number;
	position: ITuple3;
	entityDragInfo:
		| IImageEntityDragInfo
		| IBtnEntityDragInfo
		| IModel3dEntityDragInfo
		| IUnionVideoEntityDragInfo
		| null;
}

const DragElement: FunctionComponent<IParentProps> = ({
	entityDragInfo: entity,
	position,
}) => {
	if (!entity || !document.getElementById(entity.id)) return null;
	const classes = [CSS.DragElContainer];
	useEffect(() => {
		setTimeout(() => classes.push(CSS.DragShadow), 5);
	}, []);

	let { dragEl, width, height } = useMemo(() => {
		const dragEl = document.getElementById(entity.id);
		const domRect = dragEl.getBoundingClientRect();
		const { width, height }  = domRect;
		return {dragEl, width, height}
	}, [entity.id]);

	switch (entity.type) {
		case IComponentType.Text: {
			const {text, fontFamily} = entity as ITextEntityDragInfo;
			return (
        <Html onPointerMove={(e) => {}} position={position as any}>
          <div
            className={CSS.DragElement}
            style={{
              width: Math.round(width) + 5,
              height,
              opacity: 0.9,
              display: dragEl.style.display,
              alignItems: dragEl.style.alignItems,
              justifyContent: dragEl.style.justifyContent,
              fontFamily,
              fontSize: dragEl.style.fontSize,
              borderRadius: dragEl.style.borderRadius,
              borderWidth: dragEl.style.borderWidth,
              borderColor: dragEl.style.borderColor,
              backgroundColor: "none !important",
            }}
          >
            <span
              style={{
                color: dragEl.style.color,
                fontFamily,
                fontSize: dragEl.style.fontSize,
              }}
            >
              {text}
            </span>
          </div>
        </Html>
      );
		}
		case IComponentType.Button: {
			const { text } = entity as IBtnEntityDragInfo;			
			return (
				<Html onPointerMove={e => {}} position={position as any}>
					<div
						className={CSS.DragElement}
						style={{
							width: dragEl.style.width,
							height: dragEl.style.height,
							opacity: 0.9,
							display: dragEl.style.display,
							alignItems: dragEl.style.alignItems,
							justifyContent: dragEl.style.justifyContent,
							fontFamily: dragEl.style.fontFamily,
							borderRadius: dragEl.style.borderRadius,
							borderWidth: dragEl.style.borderWidth,
							borderColor: dragEl.style.borderColor,
							backgroundColor: dragEl.style.backgroundColor,
							backgroundImage: dragEl.style.backgroundImage,
							backgroundSize: 'contain',
							backgroundPositionX: 'center',
							backgroundPositionY: 'center',
							backgroundRepeat: 'no-repeat'
						}}
					>
						<span style={{color: dragEl.style.color}} >{text}</span>
					</div>
				</Html>
			);
		}
		case IComponentType.Image: {
			let {
				aspectRatio: imgRatio,
				url: imgUrl,
			} = entity as IImageEntityDragInfo;
			if (imgRatio > 0) height = width / imgRatio;
			else width = height * imgRatio;
			return (
				<Html className={CSS.DragOuter} onPointerMove={e => {}} position={position as any} >
					<img
						className={CSS.DragImage}
						style={{
							width,
							height,
							opacity: 0.9,
						}}
						src={imgUrl}
					/>
				</Html>
			);
		}
		case IComponentType.Model3d: {
			let {
				thumbnailUrl: modelUrl,
				dimensions
			} = entity as IModel3dEntityDragInfo;

			return (
				<Html className={CSS.DragOuter} onPointerMove={e => {}} position={position as any}>
					<img
						className={CSS.DragImage}
						style={{
							width: dimensions[0],
							height: dimensions[1],
							opacity: 1,
						}}
						src={modelUrl}
					/>
				</Html>
			);

		}
		case IComponentType.Video: {
			let {
				thumbnailUrl: vidUrl,
				dimensions
			} = entity as IUnionVideoEntityDragInfo;
			
			return (
				<Html className={CSS.DragOuter} onPointerMove={e => {}} position={position as any}>
					<img
						className={CSS.DragImage}
						style={{
							width: dimensions[0],
							height: dimensions[1],
							opacity: 1,
						}}
						src={vidUrl}
					/>
				</Html>
			);
		}
		default:
			break;
	}

};

export default memo(DragElement);
