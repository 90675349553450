import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThreeEvent } from '@react-three/fiber';
import Plane from '../Plane/Plane';
import LineBox from '../LineBox/LineBox';
import {
	onSetMarkerIndexPressed,
	onSetScaleHotSpotEnabled,
	IOnSetMarkerIndexPressed,
	IOnSetScaleHotSpotEnabled,
} from '../../../store/actions';
import { IDesignerState } from '../../../../typings';
import { getZoomAdjFactor } from '../../../store/selectors';
import {
	SELECTION_MARKER_HEIGHT,
	SELECTION_MARKER_WIDTH,
} from '../../../utils';
import { ITuple3 } from '../../r3f/r3f-components/component-data-structure';

interface IParentProps {
	position: ITuple3;
	id: number;
	onPointerDown?: (e: ThreeEvent<PointerEvent>) => any;
	onPointerUp?: (e: ThreeEvent<PointerEvent>) => any;
	onPointerOver?: (e: ThreeEvent<PointerEvent>) => any;
	onPointerOut?: (e: ThreeEvent<PointerEvent>) => any;
}
interface IReduxProps {
	backgroundHotspotIsEnabled: boolean;
	zoomAdjFactor: number; // used to keep size stable across zoom levels
}
interface IDispatchProps {
	onSetMarkerIndexPressed: IOnSetMarkerIndexPressed;
	onSetScaleHotSpotEnabled: IOnSetScaleHotSpotEnabled;
}

const Marker: FunctionComponent<IParentProps &
	IReduxProps &
	IDispatchProps> = ({
	id,
	position: [x, y],
	zoomAdjFactor,
	onPointerDown,
	onPointerUp,
	onPointerOver,
	onPointerOut,
	onSetMarkerIndexPressed,
	onSetScaleHotSpotEnabled,
	backgroundHotspotIsEnabled,
}) => {
	const scale = [
		SELECTION_MARKER_HEIGHT * zoomAdjFactor,
		SELECTION_MARKER_WIDTH * zoomAdjFactor,
		0.025 * zoomAdjFactor,
	] as ITuple3;

	const pointerDownHandler = (e: ThreeEvent<PointerEvent>) => {
		onPointerDown?.(e)
		e.stopPropagation();
		onSetMarkerIndexPressed(id);
		onSetScaleHotSpotEnabled(true);
	};

	const pointerUpHandler = (e: ThreeEvent<PointerEvent>) => {
		onPointerUp?.(e)
		e.stopPropagation();
		onSetMarkerIndexPressed(null);
	};

	return (
		<>
			<LineBox
				enabled={!backgroundHotspotIsEnabled}
				color={[0, 176, 255, 1]}
				rotation={[0, 0, 0]}
				position={[x, y, 0.005]}
				scale={scale}
				depthWrite={false}
				renderOrder={999995}
				/>
			<Plane
				scale={scale}
				isMarkerPlane={true}
				enabled={true}
				position={[x, y, 0]}
				depthWrite={false}
				rotation={[0, 0, 0]}
				color={[255, 255, 255, 1]}
				pointerDownHandler={e => {
					pointerDownHandler(e);
				}}
				pointerUpHandler={e => {
					pointerUpHandler(e);
				}}
				pointerOverHandler={onPointerOver || undefined}
				pointerOutHandler={onPointerOut || undefined}
				renderOrder={999999}
			/>
		</>
	);
};

const mapStateToProps = (state: IDesignerState, props: any) => {
	return {
		backgroundHotspotIsEnabled: state.userReducer.backgroundHotspotIsEnabled,
		zoomAdjFactor: getZoomAdjFactor(state),
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onSetScaleHotSpotEnabled,
			onSetMarkerIndexPressed,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Marker);
