import React, { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	IDesignerState,
	ILoadedImageInfo,
	ILoadedModel3dInfo,
	IUnionLoadedVideoInfo,
} from '../../../../../../../../../typings';
import {
	onGetZmlFileInfo,
	onAddToLoadedMedia,
	ILoadedMediaTypes,
	onSetCurrentUploads,
	onRemoveLoadedMedia,
} from '../../../../../../../../store/actions';
import Searchbar from '../../../../../../Searchbar/Searchbar';
import UploadButton from '../../../../../../upload/UploadBtn/UploadBtn';
import {
	IOnProgressData,
	IOnCompletionData,
	IOnErrorData,
} from '../../../../../../upload/specs';
import { IFileType } from 'zw-api-client/src/zml/specs';
import UploadDropzone from '../../../../../../upload/UploadDropzone/UploadDropzone';
import DragContainer from '../../../../../DragContainer/DragContainer';
import {
	onSubmitHandler,
	onErrorHandler,
	onProgressHandler,
	onCompletionHandler,
	IUploadIndexToFileDict,
} from '../../../../../../upload/utils';
import { ALLOWED_UPLOAD_IMAGE_TYPES, ALLOWED_UPLOAD_MODEL_TYPES, ALLOWED_UPLOAD_VIDEO_TYPES, NUM_OF_ENTITIES_DISPLAYED, useTimeOutOnMount } from '../../../../../../../../utils';
import { IComponentType } from '../../../../../../../r3f/r3f-components/component-data-structure';
import { ActionButton } from '../../../../../../buttons';
import { useRefState } from '../../../../../../../r3f/r3f-components/hooks';

import CSS from './ZmlEntityDisplay.scss';

interface IParentProps {
	columns: number;
	totalZmlCount: number;
	loadedZmlEntityInfo: ILoadedImageInfo[] | ILoadedModel3dInfo[] | IUnionLoadedVideoInfo[] | null;
	fileType: IFileType.Hls | IFileType.Image | IFileType.Model3d;
  showTitle: boolean;
}

const ZmlEntityDisplay: React.FunctionComponent<IParentProps> = ({
  columns,
  loadedZmlEntityInfo,
  totalZmlCount,
  showTitle,
  fileType,
}) => {	
  const dispatch = useDispatch();
  const loadingMedia = useSelector((state: IDesignerState) => state.userReducer.loadingMedia);

  
  useTimeOutOnMount(
    () => setClasses((prevState) => [...prevState, CSS.FadeIn]),
    50
    );
    const [activePagination, setActivePagination, activePaginationRef] =
    useRefState(1);
  const [isSearching, setIsSearching] = useState(false);
  const [classes, setClasses] = useState<string[]>([CSS.FadeContainer]);
  const [error, setError] = useState<string>("");
  const showLoadButton = useMemo(() => {
    return (
      totalZmlCount >= activePagination * NUM_OF_ENTITIES_DISPLAYED * columns &&
      loadedZmlEntityInfo?.length >= NUM_OF_ENTITIES_DISPLAYED * columns
    );
  }, [totalZmlCount, activePagination, loadedZmlEntityInfo]);

  const {allowedFileTypes, entityType} = useMemo(() => {
	switch (fileType) {
		case IFileType.Image:
			return {
				allowedFileTypes: ALLOWED_UPLOAD_IMAGE_TYPES,
				entityType: IComponentType.Image
			};
		case IFileType.Hls:
			return {
				allowedFileTypes: ALLOWED_UPLOAD_VIDEO_TYPES,
				entityType: IComponentType.Video
			};
		case IFileType.Model3d: 
			return {
				allowedFileTypes: ALLOWED_UPLOAD_MODEL_TYPES,
				entityType: IComponentType.Model3d
			}
		default:
			break;
	}
}, [fileType])

  const onSubmit = (fileDict: IUploadIndexToFileDict) => {
    onSubmitHandler(fileDict, onSetCurrentUploads, dispatch);
  };

  const onError = (e: IOnErrorData) => {
    // TODO: when no id then error occurred before upload process
    // if (!e.id) setError(`[${e.fileName}]: ${e.desc || 'error uploading'}`);
    onErrorHandler(e, onSetCurrentUploads, dispatch);
  };

  const onProgress = (p: IOnProgressData) => {
    onProgressHandler(p, onSetCurrentUploads, dispatch);
  };

  const onCompletion = (c: IOnCompletionData) => {
    // currently this component is  only used for images, videos and models
    let loadedFileType: ILoadedMediaTypes;
    switch (fileType) {
      case IFileType.Image:
        loadedFileType = ILoadedMediaTypes.image
        break;
      case IFileType.Hls:
        loadedFileType = ILoadedMediaTypes.video
        break;
      case IFileType.Model3d:
        loadedFileType = ILoadedMediaTypes.model3d
        break;
      default:
        break;
    }

    onCompletionHandler(
      c,
      onSetCurrentUploads,
      loadedFileType,
      onAddToLoadedMedia,
      dispatch
    );
  };

  return (
    <div className={classes.join(" ")}>
      <UploadDropzone
        className={CSS.Dropzone}
        onDragover={() => setError("")}
        onError={onError}
        onDrop={onSubmit}
        onProgress={onProgress}
        onCompletion={onCompletion}
        allowedFileTypes={allowedFileTypes}
      >
        <UploadButton
          onError={onError}
          onProgress={onProgress}
          onCompletion={onCompletion}
          onSubmit={onSubmit}
          allowedFileTypes={allowedFileTypes}
        >
          Browse from computer
        </UploadButton>
        <Searchbar
          onChange={(searchValue) => {
            if (searchValue.length > 0) {
              setIsSearching(true);
            }
            if (loadedZmlEntityInfo) dispatch(onRemoveLoadedMedia({ type: fileType }));
            setActivePagination(null);
          }}
          onSubmit={(q) => {
            if (q) {
              if (loadedZmlEntityInfo) dispatch(onRemoveLoadedMedia({ type: fileType }));
              setActivePagination(null);
              dispatch(onGetZmlFileInfo({ type: fileType, q }));
            } else {
              setActivePagination(1);
              dispatch(onGetZmlFileInfo({
                type: fileType,
                limit: NUM_OF_ENTITIES_DISPLAYED * columns,
                page: activePaginationRef.current,
              }));
            }
          }}
        />
        {error && <span>{error}</span>}
        {!loadedZmlEntityInfo?.length && !loadingMedia && !isSearching ? (
          <div className={CSS.EmptyZmlZone}></div>
        ) : (
          <div className={CSS.ScrollContainer}>
            <DragContainer
              showTitle={showTitle}
              columns={columns}
              entityType={entityType}
              loadableEntityCount={totalZmlCount}
              activePagination={activePagination}
            />
            {showLoadButton && (
              <ActionButton
                style={{ padding: "10px", marginTop: "8px", width: "100%" }}
                onClick={() => {
                  setActivePagination(activePagination + 1);
                  dispatch(onGetZmlFileInfo({
                    type: fileType,
                    limit: NUM_OF_ENTITIES_DISPLAYED * columns,
                    page: activePaginationRef.current,
                  }));
                }}
              >
                Load more
              </ActionButton>
            )}
          </div>
        )}
      </UploadDropzone>
    </div>
  );
};

export default memo(ZmlEntityDisplay) ;
