// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BtnFadeContainer--38GLP{width:calc(100% - 60px);align-self:flex-end;flex-direction:row !important;display:flex;justify-content:flex-start;padding:25px 27px 19px 16px;position:absolute;top:0;opacity:0;transition:opacity .5s;overflow-y:scroll}.BtnFadeContainer--38GLP.FadeIn--1u-oS{opacity:1}.BtnGrid--2Cpdk{display:grid;grid-column-gap:8px;grid-row-gap:8px;grid-template-columns:auto auto;justify-items:center}.BtnGrid--2Cpdk .GridItem--2XTJ3{height:fit-content;width:125px;display:flex;align-items:center;justify-content:center;cursor:pointer}.BtnGrid--2Cpdk .GridItem--2XTJ3 div{display:flex;align-items:center;justify-content:center;color:#fff;height:40px}.ButtonContainer--3-RH_{overflow:scroll}.ButtonContainer--3-RH_>.ButtonContainerInner--11SSZ{height:480px;width:250px;display:flex;flex-direction:column;align-items:flex-start}.Title--29AmM{width:100%;font-size:14px;margin-bottom:8px}.TitleDivider--2R3vH{margin-bottom:4px}", ""]);
// Exports
exports.locals = {
	"BtnFadeContainer": "BtnFadeContainer--38GLP",
	"FadeIn": "FadeIn--1u-oS",
	"BtnGrid": "BtnGrid--2Cpdk",
	"GridItem": "GridItem--2XTJ3",
	"ButtonContainer": "ButtonContainer--3-RH_",
	"ButtonContainerInner": "ButtonContainerInner--11SSZ",
	"Title": "Title--29AmM",
	"TitleDivider": "TitleDivider--2R3vH"
};
module.exports = exports;
