// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TextArea--3g5Rk{outline:none;height:83px;width:100%;background-color:#eef6ff;border:unset;border-radius:5px;font-size:14px;padding:8px 5px}.TextArea--3g5Rk::placeholder{color:#b2c4d7}.TextArea--3g5Rk:focus{border:1px solid #4a90e2;background-color:unset}", ""]);
// Exports
exports.locals = {
	"TextArea": "TextArea--3g5Rk"
};
module.exports = exports;
