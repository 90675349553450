import React from "react";
import { IIconParentProps } from "./index";
import { DEFAULT_ICON_COLOR } from "../../utils";

const padlockClosedIcon: React.SFC<IIconParentProps> = ({
  hexFill: fill,
  viewBox,
  className,
  onClick = () => {},
  title,
}) => {
  const hexFill = fill || DEFAULT_ICON_COLOR;
  return (
    <svg
      onClick={onClick}
      className={className || ""}
      width="14px"
      height="18px"
      viewBox={viewBox ? viewBox.join(" ") : `0 0 14 18`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g
        id="Expanded"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Locked-"
          transform="translate(-13.000000, -11.000000)"
          fill={hexFill}
          fillRule="nonzero"
        >
          <path
            d="M20.0001324,11 C22.7597138,11 24.9818151,12.4848196 24.9818152,15.5499999 L24.9818152,15.5499999 L24.9818152,19.0030779 C25.9985837,19.0695936 27.0077133,19.7562081 26.99996,20.8874999 L26.99996,20.8874999 L26.99996,27 C26.99996,28.1045695 26.1938048,29 25.1993619,29 L25.1993619,29 L14.8009077,29 C13.8064648,29 13.0003095,28.1045695 13.0003095,27 L13.0003095,27 L13.0003095,20.8874999 C12.9925562,19.7562081 13.9866252,19.0695936 15.0033937,19.0030779 L15.0033937,19.0030779 L15.0033937,15.5499999 C15.0033938,12.4848196 17.2405558,11 20.0001324,11 Z M25.1870229,20 L14.8129771,20 C14.3639822,20 14,20.402377 14,20.8987342 L14,20.8987342 L14,27.1012658 C14,27.597623 14.3639822,28 14.8129771,28 L14.8129771,28 L25.1870229,28 C25.6360178,28 26,27.597623 26,27.1012658 L26,27.1012658 L26,20.8987342 C26,20.402377 25.6360178,20 25.1870229,20 L25.1870229,20 Z M20,23 C20.2454599,23 20.4496084,23.1768752 20.4919443,23.4101244 L20.5,23.5 L20.5,25.5 C20.5,25.7761424 20.2761424,26 20,26 C19.7545401,26 19.5503916,25.8231248 19.5080557,25.5898756 L19.5,25.5 L19.5,23.5 C19.5,23.2238576 19.7238576,23 20,23 Z M20,12 C17.790861,12 16,13.0699562 16,15.6233766 L16,15.6233766 L16,19 L24,19 L24,15.6233766 C24,13.0699562 22.209139,12 20,12 Z"
            id="Locked"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default padlockClosedIcon;
