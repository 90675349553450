import React from 'react';
import CSS from './Summary.scss';
import {
	ExperidtorArrowIcon, 
	StarEmailIcon, 
	EmailSummaryIcon, 
} from '../../../../../../../../assets/icons/index';

interface IParentProps {
	recipient: string;
	subject: string;
	body: string;
}

const EmailSummary: React.SFC<IParentProps> = ({
	recipient,
	subject,
	body,
}) => {
	const textLines = body.split(/\r?\n/);
	const lines = textLines.map((line, i) => (
		<div className={CSS.TextLine} key={i}>{line}</div>
	));
	return (
		<div className={CSS.FlexContainer}>
			<span className={CSS.OverallDescription}>Compose an email automatically for the user from their default email app</span>
			<div className={`${CSS.FlexRow} ${CSS.FirstRow}`}>
				<div className={CSS.Logo}><img src={ExperidtorArrowIcon}/></div>
				<div className={CSS.Description}>{recipient}</div>
			</div>
			<div className={CSS.FlexRow}>
				<div className={CSS.Logo}><img src={StarEmailIcon}/></div>
				<div className={CSS.Description}>{subject}</div>
			</div>
			<div className={CSS.FlexRow}>
				<div className={CSS.Logo}><img src={EmailSummaryIcon}/></div>
				{lines}
			</div>
		</div>
	);
};

export default EmailSummary;
