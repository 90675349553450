import React, { useState } from 'react';
import {
	VolumeOffIcon,
	VolumeMinIcon,
	VolumeMedIcon,
	VolumeMaxIcon,
} from '../../../assets/icons';
import Slider from '../Slider/Slider';

import CSS from './VolumeControl.scss';

interface IParentProps {
	className?: string;
	value: number;
	onChange: (vol: number) => any;
}

const VolumeControl: React.SFC<IParentProps> = ({
	onChange,
	value,
	className,
}) => {
	let classes = [CSS.VolumeControl, className ? className : ''];
	let [oldVolume, setOldVolume] = useState<number>(value);

	let Icon = VolumeOffIcon;
	if (value > 0 && value <= 33) Icon = VolumeMinIcon;
	else if (value > 33 && value <= 66) Icon = VolumeMedIcon;
	else if (value > 66 && value <= 100) Icon = VolumeMaxIcon;

	return (
		<div className={classes.join(' ')}>
			<Icon
				onClick={() => {
					if (value === 0) {
						onChange(oldVolume);
					} else if (value !== 0) {
						onChange(0);
					}
				}}
			/>
			<Slider
				className={CSS.VolumeSlider}
				onChange={val => {
					onChange(val);
					setOldVolume(val);
				}}
				value={value}
			/>
		</div>
	);
};

export default VolumeControl;
