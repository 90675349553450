import React, { useState, useEffect, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IDesignerState, IUploadInfo } from "../../../../../typings";
import InfoRow from "./InfoRow/InfoRow";
import Progressbar from "../../Progressbar/Progressbar";
import Dialog from "../../Dialog/Dialog";
import { IProgressStatus } from "zw-api-client/src/specs";
import CSS from "./UploadDialog.scss";
import { ILoadedMediaTypes, onRemoveUpload } from "../../../../store/actions";
import { calcTotalUploadPerc, setUploadTitle } from "../utils";
import { getCurrentUploadDict } from "../../../../store/selectors";
import { useClickedOutside } from "../../../r3f/r3f-components/hooks/useClickedOutside";
import { IErrorText } from "../specs";

const UploadDialog = () => {
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const uploadDialogRef = useRef<HTMLDivElement>(null);
  const [clickedOutside, setClickedOutside] =
    useClickedOutside(uploadDialogRef);
  const dispatch = useDispatch();
  const numberProcessedUploads = useSelector(
    (state: IDesignerState) => state.userReducer.numberProcessedUploads
  );
  const currentUploads = useSelector((state: IDesignerState) =>
    getCurrentUploadDict(state)
  );
  const trkImgData = useSelector(
    (state: IDesignerState) => state.userReducer.trackImgData
  );

  useEffect(() => {
    const uploads = Object.values(currentUploads);
    if (
      uploads.length === 1 &&
      clickedOutside &&
      uploads[0].type === IProgressStatus.trainingCompleted
    ) {
      dispatch(onRemoveUpload(Object.keys(currentUploads)[0]));
    } else {
      setClickedOutside(false);
    }
  }, [clickedOutside, currentUploads]);

  if (!numberProcessedUploads) return null;

  let classes: string[] = isMinimized ? [CSS.MinimizedDialog] : [];
  let errors: string[] = [];
  let trkImgId: string;

  // If all uploads are errors then the Dialog is closable
  let allAreErrors = true;
  // If one upload is an error then we display a red border around the entire dialog ( but it's not closable )
  let oneIsError = false;
  // If any are still processing, the border should be the default ( blue )
  let oneIsProcessing = false;

  const isErrorState = (currentUpload: IUploadInfo) => {
    const { type, errorText } = currentUpload;
    if (
      type === IProgressStatus.error ||
      type === IProgressStatus.imgQualityError ||
      (type === IProgressStatus.trainingCompleted &&
        trkImgData?.quality === 1) ||
      (type === IProgressStatus.trainingCompleted &&
        errorText === IErrorText.formatNotSupported)
    ) {
      return true;
    }
    return false;
  };

  const isSuccessState = (currentUpload: IUploadInfo) => {
    const { type, errorText } = currentUpload;
    if (
      (type === IProgressStatus.completed &&
        errorText !== IErrorText.formatNotSupported) ||
      (type === IProgressStatus.trainingCompleted && trkImgData?.quality !== 1)
    ) {
      return true;
    }
    return false;
  };

  const isProcessingState = (currentUpload: IUploadInfo) => {
    const { type } = currentUpload;
    if (
      type === IProgressStatus.processing ||
      type === IProgressStatus.training ||
      type === IProgressStatus.analysing ||
      type === IProgressStatus.uploading
    ) {
      return true;
    }
    return false;
  };

  const uploadRows = Object.keys(currentUploads).map((id, i) => {
    const { type: progressType, mediaType } = currentUploads[id];
    let uploadStatus: "error" | "processing" | "success";
    if (isErrorState(currentUploads[id])) {
      oneIsError = true;
      uploadStatus = "error";
    }
    if (isSuccessState(currentUploads[id])) {
      allAreErrors = false;
      uploadStatus = "success";
    }
    if (isProcessingState(currentUploads[id])) {
      allAreErrors = false;
      oneIsProcessing = true;
      uploadStatus = "processing";
    }
    if (progressType === IProgressStatus.error) errors.push(id);
    if (mediaType === ILoadedMediaTypes.trackingImage) trkImgId = id;

    return (
      <InfoRow
        id={id}
        key={id}
        currentUploads={currentUploads}
        trkImgData={trkImgData}
        uploadStatus={uploadStatus}
      />
    );
  });

  if (!oneIsError && !oneIsProcessing) classes.push(CSS.Success);
  if (allAreErrors) classes.push(CSS.HasError);

  return (
    <>
      <Dialog
        ref={uploadDialogRef}
        className={classes.join(" ")}
        closable={allAreErrors}
        show={uploadRows.length > 0}
        onMinify={setIsMinimized}
        onClose={() => {
          [...errors, trkImgId].forEach((id) => dispatch(onRemoveUpload(id)));
        }}
      >
        <div className={!isMinimized ? CSS.UploadTitle : CSS.UploadBar}>
          {!isMinimized ? (
            setUploadTitle(uploadRows.length, errors.length)
          ) : (
            <Progressbar
              perc={calcTotalUploadPerc(numberProcessedUploads, currentUploads)}
              style={{ width: "143px" }}
            />
          )}
        </div>
        {!isMinimized && (
          <div className={CSS.InfoRowContainer}>{uploadRows}</div>
        )}
      </Dialog>
    </>
  );
};

export default memo(UploadDialog);
