import { IActionCategory } from '../index';

export enum ITriggerTypes {
	onTap = 'onTap',
	onLoad = 'onLoad',
	onFinish = 'onFinish',
}

export enum ISocialProvider {
	noSocial = 'noSocial',
	facebook = 'facebook',
	instagram = 'instagram',
	linkedIn = 'linkedIn',
	twitter = 'twitter',
	youtube = 'youtube',
	spotify = 'spotify',
	soundCloud = 'soundCloud',
	reddit = 'reddit',
	tiktok = 'tiktok',
	twitch = 'twitch',
	vimeo = 'vimeo',
	custom = 'custom',
}

export interface IDialOptions {
	tel: string;
}

export interface IBkgrSound {
	url: string;
	maxVol: number;
	loop: boolean; 
}

export interface ISoundOptions extends IBkgrSound {
	title?: string;
	stopMedia: boolean;
}

export interface IPageComponentLinkOptions {
	url: string;
}

export interface IEmailOptions {
	recipient: string;
	subject: string;
	body: string;
}

export interface IContactOptions {
	name: string; 
	phone: string; 
    company?: string; 
	email?: string; 
    website?: string; 
    phone2?: string; 
    phone3?: string; 
    street1?: string; 
    street2?: string; 
    city?: string; 
    county?: string; 
    zip?: string; 
    country?: string; 
	imgUrl?: string;
}

type ILinkedSceneId = string | null;

interface ISocialUrlOptions {
	[ISocialProvider.facebook]?: string;
	[ISocialProvider.instagram]?: string;
	[ISocialProvider.linkedIn]?: string;
	[ISocialProvider.reddit]?: string;
	[ISocialProvider.soundCloud]?: string;
	[ISocialProvider.spotify]?: string;
	[ISocialProvider.tiktok]?: string;
	[ISocialProvider.twitch]?: string;
	[ISocialProvider.twitter]?: string;
	[ISocialProvider.vimeo]?: string;
	[ISocialProvider.youtube]?: string;
	[ISocialProvider.noSocial]?: string;
}

export interface IBaseActionData {
	triggerType?: ITriggerTypes;
	[IActionCategory.linkSocial]?: ISocialUrlOptions;
	[IActionCategory.linkPage]?: IPageComponentLinkOptions;
}

export interface ISceneActionData extends IBaseActionData {
	triggerType?: ITriggerTypes.onLoad;
}

export interface IBaseSpatialActionData extends IBaseActionData {
	[IActionCategory.linkScene]?: ILinkedSceneId;
}

export interface IModel3dActionData extends IBaseSpatialActionData {
	triggerType?: ITriggerTypes.onTap;
}

export interface IVideoActionData extends IBaseSpatialActionData {
	triggerType?: ITriggerTypes.onFinish;
}

export interface IBtnAndTxtActionData extends IBaseSpatialActionData {
	triggerType?: ITriggerTypes.onTap;
	[IActionCategory.dialNumber]?: IDialOptions;
	[IActionCategory.composeEmail]?: IEmailOptions;
	[IActionCategory.saveContact]?: IContactOptions;
	[IActionCategory.playSound]?: ISoundOptions;
}

export interface IImageActionData extends IBtnAndTxtActionData {
	[IActionCategory.enlarge]?: string;
}
