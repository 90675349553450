import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const warningIcon: React.FunctionComponent<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="20px"
			height="20px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 20 20`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
  <rect id="Rectangle_67" data-name="Rectangle 67" width="20" height="20" fill="none"/>
  <path id="Oval" d="M10,20A10,10,0,1,1,20,10,10.011,10.011,0,0,1,10,20ZM10,1a9,9,0,1,0,9,9A9.01,9.01,0,0,0,10,1Z" fill={hexFill}/>
  <path id="Line_12" data-name="Line 12" d="M18,0V1H0V0Z" transform="translate(1 6)" fill={hexFill} />
  <path id="Line_12_Copy" data-name="Line 12 Copy" d="M18,0V1H0V0Z" transform="translate(1 13)" fill={hexFill}/>
  <path id="Line_13" data-name="Line 13" d="M1,0V19H0V0Z" transform="translate(9.5 0.5)" fill={hexFill} />
  <path id="Oval-2" data-name="Oval" d="M6,20H6a10.643,10.643,0,0,1-4.5-4A11.5,11.5,0,0,1,0,10,11.5,11.5,0,0,1,1.5,4,10.643,10.643,0,0,1,6,0a10.643,10.643,0,0,1,4.5,4A11.5,11.5,0,0,1,12,10a11.5,11.5,0,0,1-1.5,6A10.643,10.643,0,0,1,6,20ZM6,1.13l-.145.079C2.588,3.063,1,5.939,1,10s1.588,6.937,4.856,8.79L6,18.87l.145-.079A9.212,9.212,0,0,0,11,10.355L11,10c0-4.061-1.588-6.937-4.855-8.79Z" transform="translate(4)" fill={hexFill} />
</svg>

	);
};

export default warningIcon;
