import React, { useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThreeEvent } from '@react-three/fiber';
import Plane from '../Plane/Plane';
import LineBox from '../LineBox/LineBox';
import { IDesignerState, IDomIdSelectors } from '../../../../typings';
import {
	oneSidedGroupScale,
	allSidedGroupScale,
	oneSidedNonUniformEntityScale,
	oneSidedUniformEntityScale,
	allSidedNonUniformEntityScale,
	allSidedUniformEntityScale,
	calcScaleType,
	scaleTypes,
} from './utils/scale-utils';
import {
	maths,
	hotkeyIsPressed,
	IHotkeyTypes,
	HOTSPOT_SCALE,
} from '../../../utils';
import {
	onSetScaleHotSpotEnabled,
	IOnSetScaleHotSpotEnabled,
	onSetScale_Ed_Doc,
	onSetGroupInversion,
	onSetMultipleEntityProps_Ed_Doc,
	onSetMultipleComponentProps_Cn_Doc,
	IOnSetScale_Ed_Doc,
	onSetScaleAndPosition_Ed_Doc,
	IOnSetScaleAndPosition_Ed_Doc,
	IOnSetMultipleEntityProps_Ed_Doc,
	IOnSetMultipleComponentProps_Cn_Doc,
	onSetMarkerIndexPressed,
	IOnSetMarkerIndexPressed,
	IMultipleEntityProps_Ed_Doc,
	IMultipleEntityProps_Cn_Doc,
	IOnSetGroupInversion,
} from '../../../store/actions';
import {
	getSelectionBoundary,
	IBounds,
	getSelectionCenter,
	getSelectionRotation,
	getSelectionData,
	ISelectionData,
	getRotationMarkerPosition,
	getSelectionScale,
	getSelectedEditorScales,
	getSelectedEditorPositions,
	getSelectedContentPositions,
	getSelectedContentScales,
	getSelectedContentRotations,
	getSelectedEditorRotations,
	getSelectedEntityIds,
	getEditorIsLockedDict,
	getEditorAspRatioLockedDict,
} from '../../../store/selectors';
import { Marker } from '../Marker';
import {
	ITuple3Dict,
	ITuple3,
} from '../../r3f/r3f-components/component-data-structure';
import { useRefState } from '../r3f-components/hooks';
import { getCursorByIndex } from '../../../utils/cursor';
const { vec3 } = maths;

interface IScaleHotspotProps {
	hotKeys: string[];
	editorScaleDict: ITuple3Dict;
	editorRotationDict: ITuple3Dict;
	selectionBoundary: IBounds;
	selectedEntityIds: string[];
	scaleHotspotIsEnabled: boolean;
	selectionCenter: ITuple3;
	selectionScale: ITuple3;
	selectionData: ISelectionData;
	markerIndexPressed: number | null;
	selectionRotation: ITuple3;
	rotationMarkerPosition: ITuple3;
	contentPositionDict: ITuple3Dict;
	contentScaleDict: ITuple3Dict;
	contentRotationDict: ITuple3Dict;
	editorPositionDict: ITuple3Dict;
	editorIsLockedDict: { [id: string]: boolean };
	editorAspRatioLockedDict: { [id: string]: boolean };
}
interface IDispatchProps {
	onSetGroupInversion: IOnSetGroupInversion;
	onSetMultipleEntityProps_Ed_Doc: IOnSetMultipleEntityProps_Ed_Doc;
	onSetMultipleComponentProps_Cn_Doc: IOnSetMultipleComponentProps_Cn_Doc;
	onSetScale_Ed_Doc: IOnSetScale_Ed_Doc;
	onSetMarkerIndexPressed: IOnSetMarkerIndexPressed;
	onSetScaleHotSpotEnabled: IOnSetScaleHotSpotEnabled;
	onSetScaleAndPosition_Ed_Doc: IOnSetScaleAndPosition_Ed_Doc;
}

const ScaleHotspot: React.SFC<IScaleHotspotProps & IDispatchProps> = ({
	hotKeys,
	selectionData,
	selectionScale,
	selectionCenter,
	editorScaleDict,
	selectionRotation,
	selectionBoundary,
	selectedEntityIds,
	contentScaleDict,
	editorIsLockedDict,
	contentRotationDict,
	onSetGroupInversion,
	markerIndexPressed,
	contentPositionDict,
	editorRotationDict,
	scaleHotspotIsEnabled,
	onSetScaleHotSpotEnabled,
	onSetMarkerIndexPressed,
	editorPositionDict,
	editorAspRatioLockedDict,
	onSetMultipleEntityProps_Ed_Doc,
	onSetMultipleComponentProps_Cn_Doc,
}: IScaleHotspotProps & IDispatchProps) => {
	// set hooks
	const initialScaleRef = useRef<ITuple3>(null);
	const initialSelectionCenterRef = useRef<ITuple3>(null);
	const initialRatioRef = useRef<number>(null);
	const initialGroupBoundaryRef = useRef<IBounds>(null);
	const oldPositionRef = useRef<ITuple3>(null);
	const canvasRef = useRef<HTMLElement>(document.getElementById(IDomIdSelectors.zapparCanvas))
	const [pointerIsDown, setPointerIsDown, pointerIsDownRef] = useRefState(false);

	// check for hotkeys & set uniform scale if scale locked to uniform
	const uniformScale = useMemo(() => {
		return hotkeyIsPressed(hotKeys, [IHotkeyTypes.propScale]) ||
			(selectedEntityIds &&
				selectedEntityIds.length === 1 &&
				editorAspRatioLockedDict[selectedEntityIds[0]]);
	}, [hotKeys, selectedEntityIds, editorAspRatioLockedDict])
		

	const allSidesScale = useMemo(() => hotkeyIsPressed(hotKeys, [IHotkeyTypes.allSidesScale]), [hotKeys]);
	const groupSelected = selectedEntityIds.length > 1;
	const scaleType = useMemo (() => calcScaleType(groupSelected, allSidesScale, uniformScale), [groupSelected, allSidesScale, uniformScale]);
	// set scale and marker index related variables
	let ratio = selectionScale ? selectionScale[1] / selectionScale[0] : 1;
	let oppositePositionAdj = markerIndexPressed > 3 ? -4 : 4;
	let oppositeMarkerPosition = markerIndexPressed + oppositePositionAdj;

	const onPointerMoveHandler = (e: ThreeEvent<PointerEvent>) => {
		if (!pointerIsDownRef.current) return
		const { x, y, z } = e.point;
		if (e.buttons === 2) return;
		// Return if position has not changed (re-render and/or continuous firing)
		if (oldPositionRef.current && vec3.equal([x, y, z], oldPositionRef.current)) return;

		// update hooks
		oldPositionRef.current = [x, y, 0];
		if (initialSelectionCenterRef.current === null) initialSelectionCenterRef.current = selectionCenter;
		if (initialGroupBoundaryRef.current === null) initialGroupBoundaryRef.current = selectionBoundary;
		if (initialScaleRef.current === null) initialScaleRef.current = selectionScale;
		if (!uniformScale) initialRatioRef.current = null
		else if (initialRatioRef.current === null) initialRatioRef.current = ratio;

		// convert hotspot to entity coords & calculate scale
		let localPointerPosition = maths.worldToLocalPosition2d(
			selectionRotation[2],
			[selectionCenter[0], selectionCenter[1]],
			[[x, y]]
		)[0];

		const localMarkerPosition = maths.worldToLocalPosition2d(
			selectionRotation[2],
			[selectionCenter[0], selectionCenter[1]],
			[
				[
					selectionBoundary[oppositeMarkerPosition][0],
					selectionBoundary[oppositeMarkerPosition][1],
				],
			]
		)[0];

		let multipleEntityPropsArray: IMultipleEntityProps_Ed_Doc[];

		switch (scaleType) {
			case scaleTypes.oneSidedGroupScale:
				if (initialScaleRef.current === null || initialGroupBoundaryRef.current === null) return;
				multipleEntityPropsArray = oneSidedGroupScale(
					initialScaleRef.current,
					initialGroupBoundaryRef.current,
					e,
					markerIndexPressed,
					oppositeMarkerPosition,
					onSetGroupInversion,
					selectedEntityIds,
					contentRotationDict,
					contentScaleDict,
					contentPositionDict
				);
				break;
			case scaleTypes.allSidedGroupScale:
				if (initialScaleRef.current === null) return;
				multipleEntityPropsArray = allSidedGroupScale(
					initialScaleRef.current,
					initialSelectionCenterRef.current,
					e,
					markerIndexPressed,
					onSetGroupInversion,
					selectedEntityIds,
					contentRotationDict,
					contentScaleDict,
					contentPositionDict
				);
				break;
			case scaleTypes.oneSidedNonUniformEntityScale:
				multipleEntityPropsArray = oneSidedNonUniformEntityScale(
					selectionRotation,
					selectionCenter,
					markerIndexPressed,
					localMarkerPosition,
					localPointerPosition,
					selectionScale,
					selectedEntityIds
				);
				break;
			case scaleTypes.oneSidedUniformEntityScale:
				multipleEntityPropsArray = oneSidedUniformEntityScale(
					selectionRotation,
					selectionCenter,
					markerIndexPressed,
					localMarkerPosition,
					localPointerPosition,
					selectionScale,
					ratio,
					selectedEntityIds,
					initialRatioRef.current
				);
				break;
			case scaleTypes.allSidedNonUniformEntityScale:
				multipleEntityPropsArray = allSidedNonUniformEntityScale(
					markerIndexPressed,
					localPointerPosition,
					selectionScale,
					selectedEntityIds
				);
				break;
			case scaleTypes.allSidedUniformEntityScale:
				multipleEntityPropsArray = allSidedUniformEntityScale(
					markerIndexPressed,
					localPointerPosition,
					selectionScale,
					ratio,
					selectedEntityIds,
					initialRatioRef.current
				);
				break;
			default:
				break;
		}
		onSetMultipleEntityProps_Ed_Doc(multipleEntityPropsArray);
	};

	const onPointerUpHandler = (e: ThreeEvent<PointerEvent>) => {
		(e.target as any).releasePointerCapture(e.pointerId);
		if (e.buttons === 2) return;
		let multipleEntityPropsArray_Ed_Doc: IMultipleEntityProps_Ed_Doc[] = [];
		let multipleEntityPropsArray_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
		for (let i = 0; i < selectedEntityIds.length; i++) {
			const id = selectedEntityIds[i];
			multipleEntityPropsArray_Cn_Doc.push({
				id,
				...(editorScaleDict[id] && { scale: [...editorScaleDict[id]] }),
				...(editorPositionDict[id] && {
					position: [...editorPositionDict[id]],
				}),
				...(editorRotationDict[id] && {
					rotation: [...editorRotationDict[id]],
				}),
			});
			multipleEntityPropsArray_Ed_Doc.push({
				id,
				positions: null,
				scales: null,
			});
		}
		onSetMultipleComponentProps_Cn_Doc(multipleEntityPropsArray_Cn_Doc);
		onSetMultipleEntityProps_Ed_Doc(multipleEntityPropsArray_Ed_Doc);
		onSetMarkerIndexPressed(null);
		initialSelectionCenterRef.current = null;
		initialGroupBoundaryRef.current = null;
		initialScaleRef.current = null;
		onSetGroupInversion({ xInverted: false, yInverted: false });
		initialRatioRef.current = null;
		onSetScaleHotSpotEnabled(false);
	};

	// render selection markers (except rotation marker) if pressed
	const selectionMarkers = useMemo(() => {
		return selectionBoundary && markerIndexPressed !== 999
			? selectionBoundary.map((position: ITuple3, index: number) => {
					if (
						(typeof markerIndexPressed !== 'number' || // works also for falsy 0
							markerIndexPressed === index) &&
						!(
							selectedEntityIds.length === 1 &&
							editorIsLockedDict[selectedEntityIds[0]]
						) &&
						!(
							selectedEntityIds.length > 1 &&
							selectedEntityIds.filter(id => editorIsLockedDict[id]).length !==
								0
						)
					) {
						return <Marker 
								key={index} 
								id={index} 
								position={position} 
								onPointerDown={(e: any)=> {
									e.target.setPointerCapture(e.pointerId)
									setPointerIsDown(true)
								}}
								onPointerUp={onPointerUpHandler}
								onPointerOver={() => {
									// const r = selectionRotation[2]
									canvasRef.current.style.cursor = getCursorByIndex(index);
								}}
								onPointerOut={() => {
									canvasRef.current.style.cursor = 'default'
								}}
							/>;
					} else return null;
			})
			: null;

	}, [selectionBoundary, markerIndexPressed, selectedEntityIds, editorIsLockedDict, setPointerIsDown, onPointerUpHandler])

	// selection outlines around selected entities within groups
	const individualSelectionLineBoxesInGroup = useMemo(() => {
		return selectionData && selectionData.length > 1
			? selectionData.map((data, index) => (
					<LineBox
						key={index}
						enabled={false}
						color={[0, 176, 255, 1]}
						rotation={data.rotation as ITuple3}
						position={data.position as ITuple3}
						scale={data.scale as ITuple3}
						depthWrite={false}
					/>
			))
			: null;
	}, [selectionData])
		

	// selection outline around whole group selection
	const groupLineBox = useMemo(() => {
		return selectedEntityIds && selectedEntityIds.length ? (
			<LineBox
				enabled={scaleHotspotIsEnabled}
				color={[0, 176, 255, 1]}
				rotation={selectionRotation}
				position={selectionCenter}
				scale={selectionScale}
				depthWrite={false}
				pointerUpHandler={onPointerUpHandler}
			/>
		) : null;
	}, [
		selectedEntityIds, 
		scaleHotspotIsEnabled, 
		selectionRotation, 
		selectionCenter, 
		selectionScale, 
		onPointerUpHandler
	]);


	return (
		<>
			<group renderOrder={10000} name="Scale Hotspot Group">
				{selectionMarkers}
				{groupLineBox}
				{individualSelectionLineBoxesInGroup}
			</group>
			{pointerIsDown && <Plane
				 name="Scale Hotspot"
				visible={false}
				enabled={scaleHotspotIsEnabled}
				scale={HOTSPOT_SCALE}
				position={[0, 0, 0.01]}
				rotation={[0, 0, 0]}
				pointerMoveHandler={onPointerMoveHandler}
				depthWrite={false}
			/>}
		</>
	);
};

const mapStateToProps = (state: IDesignerState): IScaleHotspotProps => {
	return {
		hotKeys: state.userReducer.hotKeys,
		selectionData: getSelectionData(state),
		selectionScale: getSelectionScale(state),
		selectionCenter: getSelectionCenter(state),
		editorScaleDict: getSelectedEditorScales(state),
		selectedEntityIds: getSelectedEntityIds(state),
		selectionBoundary: getSelectionBoundary(state),
		selectionRotation: getSelectionRotation(state),
		markerIndexPressed: state.userReducer.markerIndexPressed,
		contentPositionDict: getSelectedContentPositions(state),
		contentRotationDict: getSelectedContentRotations(state),
		contentScaleDict: getSelectedContentScales(state),
		editorRotationDict: getSelectedEditorRotations(state),
		scaleHotspotIsEnabled: state.userReducer.scaleHotspotIsEnabled,
		rotationMarkerPosition: getRotationMarkerPosition(state),
		editorPositionDict: getSelectedEditorPositions(state),
		editorIsLockedDict: getEditorIsLockedDict(state),
		editorAspRatioLockedDict: getEditorAspRatioLockedDict(state),
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onSetMarkerIndexPressed,
			onSetGroupInversion,
			onSetScale_Ed_Doc,
			onSetScaleHotSpotEnabled,
			onSetScaleAndPosition_Ed_Doc,
			onSetMultipleEntityProps_Ed_Doc,
			onSetMultipleComponentProps_Cn_Doc,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ScaleHotspot);
