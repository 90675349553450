import React from "react";
import { IIconParentProps } from "./index";
import { DEFAULT_ICON_COLOR } from "../../utils";

const rotate3d: React.SFC<IIconParentProps> = ({
  hexFill: fill,
  viewBox,
  className,
  onClick = () => {},
  title,
}) => {
  const hexFill = fill || DEFAULT_ICON_COLOR;
  return (
    <svg
      width="21px"
      height="20px"
      viewBox="0 0 21 20"
      version="1.1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Expanded"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Rotate-20x20" fill={hexFill} fillRule="nonzero">
          <path
            d="M10.422401,0.0897234874 L10.5359444,0.148359783 L15.8021149,3.28560641 C16.0852635,3.45428821 16.2709031,3.74427126 16.3086607,4.06693621 L16.3157895,4.18929445 L16.315287,8.1188737 C18.5585019,9.16579367 20,10.7720514 20,12.6245739 C20,15.7767537 14.8002429,18.3281422 9.21368204,18.4088669 C9.35699695,18.5990506 9.56356804,18.8283044 9.83364772,19.0874958 C10.2884171,19.5239312 9.73684477,20.2180093 9.22643502,19.935519 L9.15391246,19.8883867 L7.04981681,18.3094393 C6.79732533,18.1199657 6.77207618,17.7599657 6.97406937,17.5363867 L7.04981681,17.4673341 L9.15391246,15.8883867 C9.68203211,15.4920772 10.3100728,16.2320595 9.83364772,16.6892776 C9.56926392,16.9430028 9.36573821,17.1680398 9.2204676,17.3568046 L8.94127198,17.3587891 C14.1207612,17.3587891 18.9473684,15.0372452 18.9473684,12.6245739 C18.9473684,11.352456 17.9455038,10.1633322 16.3166926,9.29504404 L16.3157895,10.0525916 C16.3157895,10.3554902 16.1853354,10.6414274 15.9616091,10.8396973 L15.8601242,10.9190967 L10.5939537,14.5431268 C10.2702609,14.7658831 9.85297049,14.7880559 9.51065471,14.6096901 L9.39955753,14.5428076 L4.1394122,10.9187774 C3.89003081,10.7469636 3.72855307,10.4775344 3.69208022,10.1810882 L3.68421053,10.0525916 L3.68330745,9.29504404 C2.05449617,10.1633322 1.05263158,11.352456 1.05263158,12.6245739 C1.05263158,12.9150889 0.816991974,13.1505978 0.526315789,13.1505978 C0.235639605,13.1505978 0,12.9150889 0,12.6245739 C0,10.7720514 1.44149811,9.16579367 3.68471301,8.1188737 L3.68421053,4.18929445 C3.68421053,3.86001164 3.8382333,3.55229935 4.09600177,3.35466002 L4.19742982,3.2858778 L9.45757515,0.148631174 C9.75278562,-0.0274377338 10.1127342,-0.0470832784 10.422401,0.0897234874 Z M4.736,4.822 L4.73684211,10.0525916 L9.473,13.316 L9.47368421,7.66311637 L4.736,4.822 Z M15.263,5.19 L10.5263158,7.67994914 L10.526,13.311 L15.2631579,10.0525916 L15.263,5.19 Z M9.99698743,1.05204783 L5.233,3.893 L10.0136842,6.76045934 L15.0181257,4.13020236 L15.103,4.094 L9.99698743,1.05204783 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default rotate3d;
