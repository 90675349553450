
import React from 'react';
import Dropdown from '../Dropdown';
import {IDropdownParentProps} from '../Dropdown';

const SelectDropdown: React.FunctionComponent<IDropdownParentProps> = (
    props
) => {
    return (
        <Dropdown
           {...props}
           isSelectInput={true}
        >
            {props.children}
        </Dropdown>
    ); 
};

export default SelectDropdown;