// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../Transforms.scss");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".InputRow--1FGNp{}.InputRowTitle--3HdL6{}.MoveForward--18DDT svg,.MoveBack--O0pO4 svg{width:20px;height:20px}button:disabled{opacity:.2;cursor:default}", ""]);
// Exports
exports.locals = {
	"InputRow": "InputRow--1FGNp " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["InputRow"] + "",
	"InputRowTitle": "InputRowTitle--3HdL6 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["InputRowTitle"] + "",
	"MoveForward": "MoveForward--18DDT",
	"MoveBack": "MoveBack--O0pO4"
};
module.exports = exports;
