import React, { FunctionComponent, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid4 from 'uuid/v4';
import { IDesignerState } from '../../../../../../../typings';
import {
	onSetEntityMenuDragActive,
	onSetDraggedEntityInfo,
	onAddText_Global,
} from '../../../../../../store/actions';
import Divider from '../../../../Divider/Divider';
import {
	ENTITY_MENU_WIDTH,
	FONT_STYLES,
	loadFontsToDocument,
	useIsDoubleClick,
	useTimeOutOnMount,
} from '../../../../../../utils';
import { FONT_TYPES, IComponentType, IFontTypes, ITextAlignment } from '../../../../../r3f/r3f-components/component-data-structure';

import CSS from './TextContent.scss';
import SubmenuHeader from '../shared/SubmenuHeader/SubmenuHeader';

loadFontsToDocument(FONT_TYPES); //TODO: check if this needs refactoring


interface TextContentProps {
	onClose: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => any;
}

const TextContent: FunctionComponent<TextContentProps> = ({onClose}) => {
	const dispatch = useDispatch();
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId);

	const [classes, setClasses] = useState<string[]>([CSS.TextFadeContainer]);
	const isDoubleClick = useIsDoubleClick();
	useTimeOutOnMount(
		() => setClasses(prevState => [...prevState, CSS.FadeIn]),
		50
	);

	// Different heading types
	const fontStyles = FONT_STYLES.map((style, index) => {
		const { desc, fontSize, troikaSize, fontRgba, scale } = style;
		const id = `em-font-styles-${index}`;
		return (
			<div
				key={id}
				id={id}
				style={{
					fontFamily: IFontTypes.Roboto,
					color: `rgb(${fontRgba[0]} ${fontRgba[1]} ${fontRgba[2]})`,
					fontSize,
					marginTop: '16px',
					cursor: 'pointer',
					width: 'fit-content'
				}}
				onPointerDown={e => {
					if (isDoubleClick()) {
						dispatch(onSetEntityMenuDragActive(false));
						dispatch(onAddText_Global({
							id: uuid4(),
							activeSceneId,
							fontRgba,
							title: desc,
							text: desc,
							fontFamily: IFontTypes.Roboto,
							fontSize: troikaSize,
							textAlignment: ITextAlignment.left,
							scale,
							position: [0, 0, 0],
							rotation: [0, 0, 0],
							color: [0, 0, 0, 0], 
						}));
						return;
					}
					const { currentTarget } = e;
					const domRect = currentTarget.getBoundingClientRect();
					dispatch(onSetEntityMenuDragActive(true));
					dispatch(onSetDraggedEntityInfo({
						id,
						type: IComponentType.Text,
						scale,
						fontRgba,
						fontSize: troikaSize,
						text: desc, 
						title: desc,
						borderRadius: 0,
						fontFamily: IFontTypes.Roboto,
						dimensions: [domRect.width, domRect.height],
						aspectRatio: domRect.width / domRect.height,
						clickOffset: [e.clientX - domRect.left + ENTITY_MENU_WIDTH, e.clientY - 50 - domRect.top],
						rotation: [0, 0, 0],
						position: [0, 0, 0],
						color: [0, 0, 0, 0], 
					}));
				}}
			>
				{desc}
			</div>
		);
	});

	// Different font families
	const fontTypes = FONT_TYPES.map((type, index) => {
		const { desc, fontFamily, scale } = type;
		const id = `em-font-types-${index}`;
		return (
			<div
				key={id}
				id={id}
				style={{ 
					fontFamily: fontFamily, 
					fontSize: '32px', 
					marginTop: '16px', 
					cursor: 'pointer', 
					width: 'fit-content' 
				}}
				onPointerDown={e => {
					if (isDoubleClick()) {
						dispatch(onSetEntityMenuDragActive(false));
						dispatch(onAddText_Global({
							id: uuid4(),
							activeSceneId,
							title: desc,
							fontRgba: [52, 75, 96, 1],
							text: desc,
							fontFamily,
							fontSize: 0.17,
							textAlignment: ITextAlignment.left,
							scale: scale || [1, 1, 0],
							borderRadius: 0,
							position: [0, 0, 0],
							rotation: [0, 0, 0],
							color: [0, 0, 0, 0], 
						}))
						return;
					}
					const { currentTarget } = e;
					const domRect = currentTarget.getBoundingClientRect();
					dispatch(onSetEntityMenuDragActive(true));
					dispatch(onSetDraggedEntityInfo({
						id,
						type: IComponentType.Text,
						scale: scale || [1, 1, 0],
						fontRgba: [52, 75, 96, 1],
						fontSize: 0.17,
						text: desc, 
						title: desc,
						borderRadius: 0,
						fontFamily,
						dimensions: [domRect.width, domRect.height],
						aspectRatio: domRect.width / domRect.height,
						clickOffset: [e.clientX - domRect.left + ENTITY_MENU_WIDTH, e.clientY - 50 - domRect.top],
						rotation: [0, 0, 0],
						position: [0, 0, 0],
						color: [0, 0, 0, 0], 
					}));
				}}
			>
				{desc}
			</div>
		);
	});


	return (
		<div className={classes.join(' ')}>
			<SubmenuHeader title='Text' onClose={onClose} />
			<span className={CSS.TextContentSubheading}>Text Hierarchy</span>
			<Divider width={'100%'} />
			<div className={CSS.FontContainer}>{fontStyles}</div>
			<span className={CSS.TextContentSubheading} style={{ marginTop: '24px' }}>Themed text</span>
			<Divider width={'100%'} />
			<div className={CSS.FontContainer} style={{height: 'calc(100vh - 385px)'}}>
				<div className={CSS.FontContainerInner}>{fontTypes}</div>
			</div>
		</div>
	);
};

export default memo(TextContent);
