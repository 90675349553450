import React, { useState, useRef } from 'react';
import { SearchIcon } from '../../../assets/icons';
import { useRefState } from '../../r3f/r3f-components/hooks';

import CSS from './Searchbar.scss';

interface IParentProps {
	onChange?: (searchVal: string) => any;
	onSubmit: (searchVal: string) => any;
	className?: string;
}

const Searchbar: React.FunctionComponent<IParentProps> = ({
	onChange,
	onSubmit,
	className,
}) => {
	const [searchVal, setSearchVal, searchValRef] = useRefState<string>('');
	const [focused, setFocused] = useState<boolean>(false);

	const timeoutRef = useRef<NodeJS.Timeout>();

	const classes = [CSS.SearchBarContainer];
	if (className) classes.push(className);
	if (focused) classes.push(CSS.Focused);


	return (
		<div className={classes.join(' ')}>
			<input
				type="text"
				placeholder={focused ? '' : 'Search...'}
				value={searchVal}
				onChange={e => {
					setSearchVal(e.target.value);
					if(onChange) onChange(e.target.value);
					
					clearTimeout(timeoutRef.current); 
					timeoutRef.current = setTimeout(() => { 
						onSubmit(searchValRef.current);
					}, 500);
				}}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
			/>
			<button onClick={() => onSubmit(searchValRef.current)}>
				<SearchIcon />
			</button>
		</div>
	);
};

export default Searchbar;
