import React, { FunctionComponent, useRef, useState} from 'react';
import Option, { IOptionOverflowType } from './Option/Option';
import { IIconParentProps } from '../../../../assets/icons';

import CSS from './MenuList.scss';
import { IFontTypes } from '../../../r3f/r3f-components/component-data-structure';

export enum IMenuItemType {
	standard = 'standard',
	faded = 'faded',
	disabled = 'disabled',
	danger = 'danger',
	header = 'header',
}

export interface IMenuItem {
	text: string;
	val: string;
	fontFamily?: IFontTypes;
	fn?: () => any;
	iconFn?: (option: IMenuItem) => any;
	icon?: React.SFC<IIconParentProps>;
	type?: IMenuItemType;
}

interface IParentProps {
	style?: any;
	show: boolean;
	className?: string;
	optionData: IMenuItem[];
	selectedOption?: IMenuItem | null;
	overflow?: IOptionOverflowType;
	hasButtonAtEnd?: boolean; 
	endButtonText?: string; 
	onChange: (option: IMenuItem) => any;
	onMouseEnterOption?: (option: IMenuItem) => any;
	onMouseLeaveOption?: (option: IMenuItem) => any;
	onMouseEnterIcon?: (option: IMenuItem) => any;
	onMouseLeaveIcon?: (option: IMenuItem) => any;
}

const MenuList: FunctionComponent<IParentProps> = ({
	style,
	className,
	show,
	optionData,
	selectedOption,
	hasButtonAtEnd,
	endButtonText, 
	onChange,
	overflow,
	onMouseEnterOption,
	onMouseLeaveOption,
	onMouseEnterIcon,
	onMouseLeaveIcon,
}) => {
	if (!show) return null;
	const menuEl = useRef(null);
	const [hoveredOption, setHoveredOption] = useState<IMenuItem | null>();
	let classes = [CSS.MenuList, className ? className : ''];

	let options = optionData.map((opt, i) => {
		return (
      <Option
        key={i}
        id={opt.val}
        icon={opt.icon}
        fontFamily={opt.fontFamily}
        overflow={overflow}
        hasButtonAtEnd={hasButtonAtEnd}
        endButtonText={endButtonText}
        isHovered={opt.val === hoveredOption?.val}
        type={opt.type || IMenuItemType.standard}
        isActive={!!selectedOption && selectedOption.val === opt.val}
        onClick={() => {
          onChange(opt);
          opt?.fn?.();
        }}
        iconOnClick={() => opt?.iconFn?.(opt)}
        onMouseEnter={() => {
          onMouseEnterOption?.(opt);
          setHoveredOption((prevOption: IMenuItem) => {
            onMouseLeaveIcon?.(prevOption);
            onMouseLeaveOption?.(prevOption);
            return opt;
          });
        }}
        iconOnMouseEnter={() => onMouseEnterIcon?.(opt)}
        iconOnMouseLeave={() => onMouseLeaveIcon?.(opt)}
      >
        {opt.text}
      </Option>
    );
	});
	return (
		<div 
			ref={menuEl} 
			className={classes.join(' ')} 
			style={...style}
			onMouseLeave={() => {
				setHoveredOption((prevOption: IMenuItem):null => {
					onMouseLeaveIcon?.(prevOption);
					onMouseLeaveOption?.(prevOption);
					return null; 
				});
			}}
		>
				{options}
		</div>
	);
};

export default MenuList;
