import {
	IContentDocActionTypes,
	IPasteCopiedEntities_Cn_Doc_Action,
	IOnCopyScenesAtIndex_Cn_Doc_Action,
	IOnRemoveSceneReferences_Cn_Doc_Action,
	IChange_Cn_Doc_Action,
	IOnRemoveEntities_Cn_Doc_Action,
	IOnSetPositions_Cn_Doc_Action,
	IOnSetRotations_Cn_Doc_Action,
	IOnSetScales_Cn_Doc_Action,
	IOnSetRgbaColors_Cn_Doc_Action,
	IOnSetMultipleComponentProps_Cn_Doc_Action,
	IOnSetComponentAction_Cn_Doc_Action,
	IOnSetComponentTransition_Cn_Doc_Action,
	IOnAddTracking_Cn_Doc_Action,
	IOnSetActiveSceneChildren_Cn_Doc_Action,
} from '../actions/action-types';
import { IContentReducer } from '../../../typings';
import { getSyncContentDoc } from '../../syncdoc';
import {
	initCnDoc,
	ISpatialComponentUnion,
	IComponentType,
	IButton,
	IAbstractComponentUnion,
	ISceneComp,
	IActionCategory,
	ITransitionEffectTypes,
	IRoot,
} from '../../components/r3f/r3f-components/component-data-structure';

const initialState: IContentReducer = {
	contentDoc: initCnDoc(),
};

// conditionally update docs
const updateContentDocFromBackend = (
	state: IContentReducer,
	action: IChange_Cn_Doc_Action
): IContentReducer => {
	return {
		...state,
		contentDoc: action.contentDoc,
	};
};

const updateContentDoc = (
	state: IContentReducer,
	action: any
): IContentReducer => {
	const { id, activeSceneId, ...rest } = action.payload;
	if (!id) return state;
	const syncContentDoc = getSyncContentDoc()
	if (!syncContentDoc) return state;

	let contentDoc = syncContentDoc.change((doc: any) => {
		if (!doc.componentsById[id]) doc.componentsById[id] = {};
		if (
			action.type === IContentDocActionTypes.ADD_BUTTON_CN_DOC ||
			action.type === IContentDocActionTypes.ADD_IMAGE_CN_DOC ||
			action.type === IContentDocActionTypes.ADD_VIDEO_CN_DOC ||
			action.type === IContentDocActionTypes.ADD_TEXT_CN_DOC ||
			action.type === IContentDocActionTypes.ADD_MODEL3D_CN_DOC
		) {
			if (!doc.componentsById[activeSceneId])
				doc.componentsById[activeSceneId] = {};
			if (!doc.componentsById[activeSceneId].children)
				doc.componentsById[activeSceneId].children = [];
		}
		doc.componentsById[activeSceneId].children.push(id);
		Object.keys(rest).map(key => (doc.componentsById[id][key] = rest[key]));
	});

	return {
		...state,
		contentDoc,
	};
};

const setPositionsInContentDoc = (
	state: IContentReducer,
	action: IOnSetPositions_Cn_Doc_Action
): IContentReducer => {
	const positionDict = action.payload;
	const syncContentDoc = getSyncContentDoc()
	if (!syncContentDoc) return state;
	let contentDoc = syncContentDoc.change(doc => {
		for (const id in positionDict) {
			(doc.componentsById[id] as ISpatialComponentUnion).position =
				positionDict[id];
		}
	});

	return {
		...state,
		contentDoc,
	};
};

const setRgbaColorsInContentDoc = (
	state: IContentReducer,
	action: IOnSetRgbaColors_Cn_Doc_Action
): IContentReducer => {
	const colorDict = action.payload;
	const syncContentDoc = getSyncContentDoc()
	if (!syncContentDoc) return state;
	let contentDoc = syncContentDoc.change(doc => {
		for (const id in colorDict) {
			if (doc.componentsById[id].type !== IComponentType.Button) continue;
			(doc.componentsById[id] as IButton).color = colorDict[id];
		}
	});

	return {
		...state,
		contentDoc,
	};
};

const setRotationsInContentDoc = (
	state: IContentReducer,
	action: IOnSetRotations_Cn_Doc_Action
): IContentReducer => {
	const rotationDict = action.payload;
	const syncContentDoc = getSyncContentDoc()
	if (!syncContentDoc) return state;
	let contentDoc = syncContentDoc.change(doc => {
		for (const id in rotationDict) {
			(doc.componentsById[id] as ISpatialComponentUnion).rotation =
				rotationDict[id];
		}
	});

	return {
		...state,
		contentDoc,
	};
};

const setScalesInContentDoc = (
	state: IContentReducer,
	action: IOnSetScales_Cn_Doc_Action
): IContentReducer => {
	const scaleDict = action.payload;
	const syncContentDoc = getSyncContentDoc()
	if (!syncContentDoc) return state;
	let contentDoc = syncContentDoc.change(doc => {
		for (const id in scaleDict) {
			(doc.componentsById[id] as ISpatialComponentUnion).scale = scaleDict[id];
		}
	});

	return {
		...state,
		contentDoc,
	};
};


const setActiveSceneChildren = (
	state: IContentReducer,
	action: IOnSetActiveSceneChildren_Cn_Doc_Action
): IContentReducer => {
	const syncContentDoc = getSyncContentDoc()
	if (!syncContentDoc) return state;
	const {activeSceneId, sceneChildren} = action.payload;
	let contentDoc = syncContentDoc.change(doc => {
		const scene = doc.componentsById[activeSceneId];
		if (scene.type === IComponentType.Scene) {
			scene.children = sceneChildren;
		};
	});
	return {
		...state,
		contentDoc
	}
}

const addTracking_Cn_Doc = (
	state: IContentReducer,
	action: IOnAddTracking_Cn_Doc_Action
): IContentReducer => {
	const syncContentDoc = getSyncContentDoc()
	if (!syncContentDoc) return state;
	let contentDoc = syncContentDoc.change(doc => {
		doc.tracking = action.payload
	});
	return {
		...state,
		contentDoc,
	};
}

const removeComponentsFromContentDoc = (
	state: IContentReducer,
	action: IOnRemoveEntities_Cn_Doc_Action
): IContentReducer => {
	const { ids: componentIds } = action.payload;
	const syncContentDoc = getSyncContentDoc()
	if (!componentIds.length || !syncContentDoc) return state;
	let contentDoc = syncContentDoc.change(doc => {
		for (let i = 0; i < componentIds.length; i++) {
			const componentId = componentIds[i];
			// remove component
			if (doc.componentsById[componentId])
				delete doc.componentsById[componentId];
			// remove component id from children of other components
			for (const id in doc.componentsById) {
				let { children } = doc.componentsById[id] as IAbstractComponentUnion;
				if (!children) continue;
				(doc.componentsById[
					id
				] as IAbstractComponentUnion).children = children.filter(
					id => componentId !== id
				);
			}
		}
	});

	return {
		...state,
		contentDoc,
	};
};

const setComponentTransition_Cn_Doc = (
	state: IContentReducer,
	action: IOnSetComponentTransition_Cn_Doc_Action
): IContentReducer => {
	const { ids, category, type, duration, delay } = action.payload;
	if (!ids) return state;
	const syncContentDoc = getSyncContentDoc()
	if (!syncContentDoc) return state;
	let contentDoc = syncContentDoc.change(doc => {
		for (let i = 0; i < ids.length; i++) {
			if (doc.componentsById[ids[i]].type === IComponentType.Root) continue;
			const component = (doc.componentsById[ids[i]] as
				| ISpatialComponentUnion
				| ISceneComp);

			// to keep content doc lean delete transition data if noEffect selected 
			if (type === ITransitionEffectTypes.noEffect) {
				// if no transition data doc no need to delete data
				if(typeof component.transitions !== 'object') continue;
				const transDataEmpty = Object.keys(component.transitions).length === 0;
				// if data for trans category exists delete
				if(!transDataEmpty) delete component.transitions[category]
				// else delete whole transition object
				else {
					delete component.transitions
				};
			} else {
				if (!component.transitions) component.transitions = {};
				component.transitions[category] = { type, duration, delay }
			}};
	});
	return {
		...state,
		contentDoc,
	};
};

const copyScenesAtIndex_Cn_Doc = (
	state: IContentReducer,
	action: IOnCopyScenesAtIndex_Cn_Doc_Action
): IContentReducer => {

	const syncContentDoc = getSyncContentDoc();
	const { sceneIds, oldToNewIdDict, index } = action.payload;
	if (!oldToNewIdDict || !sceneIds || !syncContentDoc) return state;

	let contentDoc = syncContentDoc.change(doc => {
		const { componentsById } = doc;
		for (let i = 0; i < sceneIds.length; i++) {
			// deep copy old scene, add new children id array
			const oldSceneId = sceneIds[i];
			const newSceneId = oldToNewIdDict[oldSceneId];
			const sceneToCopy = componentsById[oldSceneId] as ISceneComp;
			const newScene = JSON.parse(JSON.stringify(sceneToCopy)) as ISceneComp;
			newScene.children = sceneToCopy.children.map(id => oldToNewIdDict[id])
			componentsById[newSceneId] = newScene;

			// deep copy children of old scene, add copied children to componentByIds dict
			for (let j = 0; j < sceneToCopy.children.length; j++) {
				const oldSceneChildId = sceneToCopy.children[j];
				const newSceneChildId = oldToNewIdDict[oldSceneChildId];
				componentsById[newSceneChildId] = JSON.parse(JSON.stringify(componentsById[oldSceneChildId]));
			}

		}
		// add new ids to root children at index
		const root = doc.componentsById[doc.rootComponentId] as IRoot;
		root.children?.splice(index, 0, ...sceneIds.map(id => oldToNewIdDict[id]));
	});

	return {
		...state,
		contentDoc,
	}
};

const pasteCopiedEntitiesInContentDoc = (
	state: IContentReducer,
	action: IPasteCopiedEntities_Cn_Doc_Action
): IContentReducer => {
	const { activeSceneId, copiedIds, newEntityIds, offset } = action.payload;
	const syncContentDoc = getSyncContentDoc()
	if (!copiedIds || !activeSceneId || !syncContentDoc) return state;

	let contentDoc = syncContentDoc.change(doc => {
		for (let i = 0; i < copiedIds.length; i++) {
			const newId = newEntityIds[i];
			const copiedId = copiedIds[i];
			const { id, ...deepCopyRest } = JSON.parse(
				JSON.stringify(doc.componentsById[copiedId])
			);
			doc.componentsById[newId] = {
				...deepCopyRest
			};
			const spatialComp = doc.componentsById[newId] as ISpatialComponentUnion;
			const { position } = spatialComp;
			if (position && offset) {
				spatialComp.position = [
					position[0] + offset[0], 
					position[1] + offset[1],
					position[2] + offset[2]
				];
			}
			const activeScene = doc.componentsById[
				activeSceneId
			] as IAbstractComponentUnion;
			if (!activeScene.children) activeScene.children = [];
			activeScene.children.push(newId);
		}
	});
	return {
		...state,
		contentDoc,
	};
};

const undoCnDoc = (state: IContentReducer): IContentReducer => {
	const syncContentDoc = getSyncContentDoc()
	if (!syncContentDoc?.canUndo?.()) return state;
	return {
		...state,
		contentDoc: syncContentDoc.undo(),
	};
};

const redoCnDoc = (state: IContentReducer): IContentReducer => {
	const syncContentDoc = getSyncContentDoc()
	if (!syncContentDoc?.canRedo?.()) return state;
	return {
		...state,
		contentDoc: syncContentDoc.redo(),
	};
};

const removeSceneReferences = (
	state: IContentReducer,
	action: IOnRemoveSceneReferences_Cn_Doc_Action
): IContentReducer => {
	const { sceneIds } = action.payload;
	const syncContentDoc = getSyncContentDoc()
	if (!sceneIds || !syncContentDoc) return state;
	let contentDoc = syncContentDoc.change(doc => {
		for (let i = 0; i < sceneIds.length; i++) {
			const sceneId = sceneIds[i];
			const allComponentIds =  Object.keys(doc.componentsById);
			// loop through all components in content doc doc
			for (let i = 0; i < allComponentIds.length; i++) {
				const compId = allComponentIds[i];
				const comp = doc.componentsById[compId]
				// continue if not a spatial component or component has no actions to remove
				// otherwise delete action property
				if (
					comp.type === IComponentType.Root || 
					comp.type === IComponentType.Scene || 
					!comp.actions
				) continue;
				if (comp.actions?.linkScene === sceneId) delete comp.actions;
			}
		}
	});
	return {
		...state,
		contentDoc,
	};
};

const onSetMultipleComponentProps_Cn_Doc = (
	state: IContentReducer,
	action: IOnSetMultipleComponentProps_Cn_Doc_Action
): IContentReducer => {
	const propArray = action.payload;
	const syncContentDoc = getSyncContentDoc()
	if (!syncContentDoc) return state;
	let contentDoc = syncContentDoc.change((doc: any) => {
		if (!doc.componentsById) doc.componentsById = {};
		for (let i = 0; i < propArray.length; i++) {
			const { id, ...rest } = propArray[i];
			const keys = Object.keys(rest);
			if (!doc.componentsById[id]) doc.componentsById[id] = {};
			for (const k in rest) {
				const key = k as keyof typeof rest;
				const data = rest[key];
				if (
					typeof data === 'object' &&
					data !== null &&
					!(data instanceof Array)
				) {
					doc.componentsById[id][key] = {
						...doc.componentsById[id][key],
						...data,
					};
					continue;
				}
				doc.componentsById[id][key] = data;
			}
		}
	});
	return {
		...state,
		contentDoc,
	};
};

const onSetComponentAction_Cn_Doc = (
	state: IContentReducer,
	action: IOnSetComponentAction_Cn_Doc_Action
): IContentReducer => {
	const { ids, triggerType, ...data } = action.payload;
	const [actionType] = Object.keys(data);
	if (actionType === IActionCategory.linkSocial) {
		const { selectedProvider, ...rest } = data[actionType] as any;
		data[actionType] = rest;
	}
	const syncContentDoc = getSyncContentDoc()
	if (!syncContentDoc) return state;
	let contentDoc = syncContentDoc.change(doc => {
		if (!doc.componentsById) doc.componentsById = {};
		for (let i = 0; i < ids.length; i++) {
			if (doc.componentsById[ids[i]].type === IComponentType.Root) continue;
			if (actionType === IActionCategory.noAction) {
				delete (doc.componentsById[ids[i]] as
					| ISpatialComponentUnion
					| ISceneComp).actions;
				continue;
			}
			(doc.componentsById[ids[i]] as
				| ISpatialComponentUnion
				| ISceneComp).actions = {
				triggerType,
				...data,
			};
		}
	});
	return {
		...state,
		contentDoc,
	};
};

const contentReducer = (state: IContentReducer = initialState, action: any) => {
	switch (action.type) {
		case IContentDocActionTypes.UNDO_CN_DOC:
			return undoCnDoc(state);
		case IContentDocActionTypes.REDO_CN_DOC:
			return redoCnDoc(state);

		case IContentDocActionTypes.ADD_IMAGE_CN_DOC:
		case IContentDocActionTypes.ADD_VIDEO_CN_DOC:
		case IContentDocActionTypes.ADD_BUTTON_CN_DOC:
		case IContentDocActionTypes.ADD_TEXT_CN_DOC:
		case IContentDocActionTypes.ADD_MODEL3D_CN_DOC:
			// setUnpublishedChangesStatus(state);
			return updateContentDoc(state, action);

		case IContentDocActionTypes.REMOVE_ENTITIES_CN_DOC:
			// setUnpublishedChangesStatus(state);
			return removeComponentsFromContentDoc(state, action);

		case IContentDocActionTypes.SET_ENTITY_POSITIONS_CN_DOC:
			// setUnpublishedChangesStatus(state);
			return setPositionsInContentDoc(state, action);
		case IContentDocActionTypes.SET_ENTITY_ROTATIONS_CN_DOC:
			// setUnpublishedChangesStatus(state);
			return setRotationsInContentDoc(state, action);
		case IContentDocActionTypes.SET_SCALE_FACTORS_CN_DOC:
			// setUnpublishedChangesStatus(state);
			return setScalesInContentDoc(state, action);
		case IContentDocActionTypes.SET_ENTITY_COLORS_CN_DOC:
			// setUnpublishedChangesStatus(state);
			return setRgbaColorsInContentDoc(state, action);

		case IContentDocActionTypes.CHANGE_CN_DOC_REDUX:
			return updateContentDocFromBackend(state, action);

		case IContentDocActionTypes.PASTE_COPIED_ENTITIES_CN_DOC:
			// setUnpublishedChangesStatus(state);
			return pasteCopiedEntitiesInContentDoc(state, action);

		case IContentDocActionTypes.COPY_SCENES_AT_INDEX_CN_DOC:
			// setUnpublishedChangesStatus(state);
			return copyScenesAtIndex_Cn_Doc(state, action);

		case IContentDocActionTypes.SET_COMPONENT_TRANSITION_CN_DOC:
			// setUnpublishedChangesStatus(state);
			return setComponentTransition_Cn_Doc(state, action);

		case IContentDocActionTypes.SET_COMPONENT_ACTION_CN_DOC:
			return onSetComponentAction_Cn_Doc(state, action);

		case IContentDocActionTypes.SET_MULTIPLE_COMPONENT_PROPS_CN_DOC:
			// setUnpublishedChangesStatus(state);
			return onSetMultipleComponentProps_Cn_Doc(state, action);

		case IContentDocActionTypes.REMOVE_SCENE_REFERENCES_CN_DOC:
			// setUnpublishedChangesStatus(state);
			return removeSceneReferences(state, action);

		case IContentDocActionTypes.ADD_TRACKING_CN_DOC:
			return addTracking_Cn_Doc(state, action);

		case IContentDocActionTypes.SET_ACTIVE_SCENE_CHILDREN:
			return setActiveSceneChildren(state, action)

		default:
			return state;
	}
};

export default contentReducer;
