// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OuterContainer---ElQ5{position:absolute;left:60px;bottom:0;z-index:2;width:calc(100% - 272px - 60px);height:fit-content;display:flex;align-items:flex-end;justify-content:center;pointer-events:none}.OuterContainer---ElQ5>.SceneMenu--3EcUL{display:flex;flex-direction:column;align-items:center;justify-content:space-between;width:344px;height:56px;border-radius:6px 6px 0 0;box-shadow:0 2px 4px 0 rgba(52,75,96,.1),0 1px 3px 0 rgba(52,75,96,.2);background-color:#fff;transition:all .2s cubic-bezier(0.4, 0, 0.2, 1);pointer-events:auto}.OuterContainer---ElQ5>.SceneMenu--3EcUL.EnlargeMenu--dkSGA{height:206px;min-width:344px;width:fit-content;width:-moz-fit-content;max-width:-webkit-fill-available;max-width:-moz-max-content;padding:0 40px;transition:all .2s cubic-bezier(0.4, 0, 0.2, 1);overflow:hidden;position:relative}", ""]);
// Exports
exports.locals = {
	"OuterContainer": "OuterContainer---ElQ5",
	"SceneMenu": "SceneMenu--3EcUL",
	"EnlargeMenu": "EnlargeMenu--dkSGA"
};
module.exports = exports;
