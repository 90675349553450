import React, { FunctionComponent, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../../typings';
import uuid4 from 'uuid/v4';
import Divider from '../../../../Divider/Divider';
import {
	onSetEntityMenuDragActive,
	onSetDraggedEntityInfo,
	onAddButton_Global,
	IOnAddButton_Global_Payload,
} from '../../../../../../store/actions';
import {ENTITY_MENU_WIDTH, useTimeOutOnMount} from '../../../../../../utils';
import { IButtonDisplayState, IComponentType } from '../../../../../r3f/r3f-components/component-data-structure';
import DefaultBtnGrid from './DefaultBtnGrid/DefaultBtnGrid';
import SocialBtnGrid from './SocialBtnGrid/SocialBtnGrid';
import LargeSocialBtnGrid from './LargeSocialBtnGrid/LargeSocialBtnGrid';

import CSS from './ButtonSelection.scss';
import SubmenuHeader from '../shared/SubmenuHeader/SubmenuHeader';

interface IButtonContentProps {
	onClose: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => any;
}

const ButtonContent: FunctionComponent<IButtonContentProps> = ({onClose}) => {
	const dispatch = useDispatch();
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId);

	const [classes, setClasses] = useState<string[]>([CSS.BtnFadeContainer, CSS.ButtonContainer]);
	useTimeOutOnMount(
		() => setClasses(prevState => [...prevState, CSS.FadeIn]),
		50
	);

	const onPointerDown = (
		btn: IButtonDisplayState,
		e: React.PointerEvent<HTMLDivElement>
	) => {
		dispatch(onSetEntityMenuDragActive(true));
		const draggedDomEl = document.getElementById(btn.id);
		const domRect = draggedDomEl.getBoundingClientRect();
		dispatch(onSetDraggedEntityInfo({
			id: btn.id,
			dimensions: [domRect.width, domRect.height],
			aspectRatio: domRect.width / domRect.height,
			borderRadius: btn.borderRadius,
			clickOffset: [e.clientX - domRect.left + ENTITY_MENU_WIDTH, e.clientY - 50 - domRect.top],
			title: btn.text,
			type: IComponentType.Button,
			...btn
		}));
	}

	const onDoubleClick = (btnInfo: IButtonDisplayState) => {
		dispatch(onSetEntityMenuDragActive(false));
		const payload: IOnAddButton_Global_Payload = {
			id: uuid4(),
			title: 'Button',
			activeSceneId,
			scalesInverted: [false, false],
			aspectRatioLocked: true,
			isLocked: false,
			fontRgba: btnInfo.fontRgba,
			fontFamily: btnInfo.fontFamily,
			fontSize: btnInfo.fontSize,
			text: btnInfo.text,
			color: btnInfo.color,
			scale: btnInfo.scale,
			position: [0, 0, 0],
			rotation: [0, 0, 0],
			borderRadius: btnInfo.borderRadius,
			borderWidth: btnInfo.borderWidth,
			borderRgba: btnInfo.borderRgba,
			textAlignment: btnInfo.textAlignment,
		}
		if(btnInfo.textureUrl) payload.textureUrl = btnInfo.textureUrl;
		if(btnInfo.svgUrl) payload.svgUrl = btnInfo.svgUrl;
		dispatch(onAddButton_Global(payload))
	}

	return (
		<div className={classes.join(' ')}>
			<div className={CSS.ButtonContainerInner}>
				<SubmenuHeader title='Buttons' onClose={onClose}/>
				<div className={CSS.Title}>Basic buttons</div>
				<Divider className={CSS.TitleDivider} width={'100%'} />
				<DefaultBtnGrid onPointerDown={onPointerDown} onDoubleClick={onDoubleClick}/>
				<div className={CSS.Title}>Social buttons</div>
				<Divider className={CSS.TitleDivider} width={'100%'} />
				<SocialBtnGrid onPointerDown={onPointerDown} onDoubleClick={onDoubleClick}/>
				<LargeSocialBtnGrid onPointerDown={onPointerDown} onDoubleClick={onDoubleClick}/>
			</div>
		</div>
	);
};

export default memo(ButtonContent);
