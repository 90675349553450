import * as THREE from 'three'
import React, { FunctionComponent, memo, useLayoutEffect, useMemo, useRef } from 'react';
import { ITuple3, IModel3d } from '../component-data-structure';
import { ISharedHOCProps } from '../types';
import { IUserData } from '../utils/general';
import { Group } from 'three';
import { useGLTFLoader } from '../hooks/useGLTFLoader';
// import { useHelper } from '@react-three/drei';
// import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
// import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';


const Model3d: FunctionComponent<IModel3d & ISharedHOCProps> = ({
    model3dUrl = '',
	scale: s,
	position: p,
	rotation: r,
	onPointerUp,
	onPointerMove,
	onDoubleClick,
	renderOrder = 0,
	id = '',
}) => {
    const outerGroupRef = useRef<THREE.Group>();
    const innerGroupRef = useRef<THREE.Group>();
    const modelSceneRef = useRef<any>();
    const scale = useMemo(() => s as ITuple3, [s])
    const position = useMemo(() => p as ITuple3, [p])
    const rotation = useMemo(() => r as ITuple3, [r])
	const userData: IUserData = useMemo(() => ({renderOrder, contentId: id}), [id, renderOrder])
    // const {gl} = useThree()
    // const DRACO_LOADER = useMemo(() => new DRACOLoader().setDecoderPath( 'three/examples/js/libs/draco/gltf/'), []);
    // const KTX2_LOADER = useMemo(() => new KTX2Loader().setTranscoderPath( 'three/examples/js/libs/basis/'), []);

    // const model = useLoader(GLTFLoader, model3dUrl, loader => {
    //     (loader as GLTFLoader)
    //         .setCrossOrigin('anonymous')
    //         .setDRACOLoader( DRACO_LOADER )
    //         .setKTX2Loader( KTX2_LOADER.detectSupport( gl ) )
    // })

    // useHelper(modelSceneRef, BoxHelper, 'royalblue')
    // useHelper(modelSceneRef, SkeletonHelper, 'red')

    const modelScene = useGLTFLoader(model3dUrl, renderOrder);

    useLayoutEffect(() => {
        const innerGroup = innerGroupRef.current as Group;
        
        const sceneBBoxScale = new THREE.Box3()
            .setFromObject(modelScene)
            .getSize(new THREE.Vector3());

        innerGroup.scale
            .set(...new THREE.Vector3(1, 1, 1)
            .divide(sceneBBoxScale).toArray());

        const center = new THREE.Box3()
            .setFromObject( innerGroup )
            .getCenter( new THREE.Vector3() );

        innerGroup.position.x += ( innerGroup.position.x - center.x );
        innerGroup.position.y += ( innerGroup.position.y - center.y );
        innerGroup.position.z += ( innerGroup.position.z - center.z );
    }, [])

	return (
		<group
			key={'box_group'}
			userData={userData}
            scale={scale}
            position={position} 
            rotation={rotation}
            ref={outerGroupRef}
            onPointerUp={onPointerUp || undefined}
            // onPointerDown={() => console.log('pointer down!')}
            onPointerMove={onPointerMove || undefined}
            onDoubleClick={onDoubleClick || undefined}
        >
            <group ref={innerGroupRef} >
                <primitive ref={modelSceneRef} object={modelScene} />
            </group>
		</group>
	);
};

Model3d.displayName = 'Model3d';
export default memo(Model3d);