import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import * as Sentry from "@sentry/react";
import { bindActionCreators, Store } from 'redux';
import { connect } from 'react-redux';
import { hotkeyIsPressed, IHotkeyTypes } from '../../utils';
import Header from '../dom/Header/Header';
import EntityMenu from '../dom/menus/EntityMenu/EntityMenu';
import SceneMenu from '../dom/menus/SceneMenu/SceneMenu';
import ContextMenu from '../dom/menus/ContextMenu/ContextMenu';
import InspectorMenu from '../dom/menus/InspectorMenu/InspectorMenu';
import UploadDialog from '../dom/upload/UploadDialog/UploadDialog';
import IntroModal from '../dom/modals/IntroModal/IntroModal';
import EditorCanvas from '../r3f/EditorCanvas/EditorCanvas'
import { Button, Image, Video, Text, Model3d } from '../r3f/r3f-components';
import { IDesignerState, IToast, IUploadInfoDict } from '../../../typings';
import { ToastsData } from '../../utils/toasts-data'; 
import PanelSkeleton from '../dom/skeletons/PanelSkeleton/PanelSkeleton'
import {
	onSetMultipleEntityProps_Ed_Doc,
	onRemoveEntities_Global,
	onSetHotKey,
	onRemoveHotKey,
	IOnSetHotKey,
	IOnRemoveHotKey,
	IOnRemoveEntities_Global,
	onCopySelectedEntities,
	IOnCopySelectedEntities,
	IOnAddToast,
	onAddToast,
	onPasteCopiedEntities_Global,
	IOnPasteCopiedEntities_Global,
	onSetActiveScene,
	onSetIs3dMode, 
	IOnSetIs3dMode,
	onSetSceneHas3dEntity,
	IOnSetSceneHas3dEntity,
	IOnSetMultipleEntityProps_Ed_Doc,
	onSetSelection_Ed_Doc,
	onRemoveSelection
} from '../../store/actions';
import {
	getContentEntityDict,
	getActiveSceneComponent,
	getRootComponent,
	getCurrentUploadDict, 
} from '../../store/selectors';
import { withEntityDefaults } from '../r3f/HOC';
import {
	ISpatialComponentUnion,
	IComponentUnion,
	IComponentType,
	ISceneComp,
	IRoot,
} from '../r3f/r3f-components/component-data-structure';
import TrackingDisplay from '../r3f/TrackingDisplay/TrackingDisplay'; 
import Toasts from '../dom/Toasts/Toasts';
import { useRefState } from '../r3f/r3f-components/hooks';

import CSS from './App.scss';

const ImageHOC = withEntityDefaults(Image);
const ButtonHOC = withEntityDefaults(Button);
const VideoHOC = withEntityDefaults(Video);
const TextHOC = withEntityDefaults(Text);
const Model3dHOC = withEntityDefaults(Model3d);

interface IParentProps {
	store: Store;
}
interface IReduxProps {
	is3dMode: boolean;
	entityDict: { [id: string]: ISpatialComponentUnion };
	activeSceneId: string;
	selectedEntityIds: string[];
	amergeIsLoaded: boolean;
	root: IRoot;
	activeScene?: ISceneComp;
	projectId: string; 
	toasts: IToast[]; 
	projectLoadingProgress: number;
	projectLoadingFailure: boolean;
	currentUploads: IUploadInfoDict;
}
interface IDispatchProps {
	onSetHotKey: IOnSetHotKey;
	onRemoveHotKey: IOnRemoveHotKey;
	onRemoveEntities_Global: IOnRemoveEntities_Global;
	onCopySelectedEntities: IOnCopySelectedEntities;
	onAddToast: IOnAddToast;
	onSetIs3dMode: IOnSetIs3dMode;
	onPasteCopiedEntities_Global: IOnPasteCopiedEntities_Global;
	onSetSceneHas3dEntity: IOnSetSceneHas3dEntity;
	onSetMultipleEntityProps_Ed_Doc: IOnSetMultipleEntityProps_Ed_Doc;
}


const App: FunctionComponent<IParentProps & IReduxProps & IDispatchProps> = ({
	store,
	onSetHotKey,
	onRemoveHotKey,
	onCopySelectedEntities,
	onRemoveEntities_Global,
	onPasteCopiedEntities_Global,
	amergeIsLoaded,
	entityDict,
	is3dMode,
	selectedEntityIds,
	activeScene,
	root,
	projectId,
	onSetIs3dMode,
	onSetSceneHas3dEntity,
	onSetMultipleEntityProps_Ed_Doc,
	onAddToast,
	toasts,
	projectLoadingProgress,
	projectLoadingFailure, 
	currentUploads, 
}) => {
	const [closeIntroModal, setCloseIntroModal] = useState(false);
	const [_, setNumberPastes, numberPastesRef] = useRefState(0);

	useEffect(() => {
		let hotkeyArray: string[] = [];
		
		document.addEventListener('copy', (e: any) => {
			if (
				document.activeElement.tagName !== 'INPUT' &&
				document.activeElement.tagName !== 'TEXTAREA'
			) {
				const ids = store.getState().userReducer.selectedEntityIds;
				onCopySelectedEntities(ids);
				setNumberPastes(0);
			}
		});

		document.addEventListener('paste', () => {
			if (
				document.activeElement.tagName !== 'INPUT' &&
				document.activeElement.tagName !== 'TEXTAREA'
			) {
				const copiedIds = store.getState().userReducer.copiedEntityIds;
				const activeSceneId = store.getState().userReducer.activeSceneId;
				onPasteCopiedEntities_Global({ activeSceneId, copiedIds, offset: [
					0.05 * numberPastesRef.current + 0.05, 
					-0.05 * numberPastesRef.current - 0.05, 
					0
				] })
				setNumberPastes(numberPastesRef.current + 1);
			}
		});

		document.addEventListener('keydown', (e: KeyboardEvent) => {
			if (
				document.activeElement.tagName !== 'INPUT' &&
				document.activeElement.tagName !== 'TEXTAREA'
			) {
				const ids = store.getState().userReducer.selectedEntityIds;


				if (!hotkeyArray.includes(e.key)) {
					onSetHotKey(e.key);
					hotkeyArray.push(e.key);
				}
				else if (
					hotkeyIsPressed(hotkeyArray, [
						IHotkeyTypes.removeEntities,
						IHotkeyTypes.removeEntitiesAlt,
					]) &&
					ids.length
				) {
					onRemoveEntities_Global(ids);
					return;
				}

				switch (e.key) {
					case 'f':
						document.getElementsByTagName('BODY')[0].requestFullscreen();
						return;
					case 'Delete':
					case 'Backspace':
						onRemoveEntities_Global(ids);
						return;
					default:
						return;
					} 
			}
		});

		document.addEventListener('dragover', e => e.preventDefault());
		document.addEventListener('drop', e => e.preventDefault());

		document.addEventListener('keyup', (e: KeyboardEvent) => {
			onRemoveHotKey(e.key);
			hotkeyArray = hotkeyArray.filter(key => key !== e.key);
		});

	}, [])

	useEffect(() => {
		// if active scene is deleted by another user/backend, set active scene to first scene
		if (!activeScene) onSetActiveScene({activeSceneId: root.children[0]})
	}, [activeScene, root])

	// Unlock any entities on switching to 3D mode
	useEffect(() => {
		if (is3dMode) {
			let updatedLockedEntityDict = [];
			for (const [entityId, entity] of Object.entries(entityDict)) {
				if ((entity as IComponentUnion).type !== IComponentType.Root && (entity as IComponentUnion).type !== IComponentType.Scene) { 
					updatedLockedEntityDict.push({ id: entityId, isLocked: false });
				}
			}
			onSetMultipleEntityProps_Ed_Doc(updatedLockedEntityDict);
			// onRemoveSelection();
		}
	}, [is3dMode]);

	useEffect(() => {
		const activeSceneChildren = activeScene?.children || [];
		const entity3dArray = activeSceneChildren.filter(id => {
			return entityDict[id].scale[2] !== 0 || 
				entityDict[id].position[2] !==0 ||
				entityDict[id].rotation[0] !==0 ||
				entityDict[id].rotation[1] !==0 ||
				entityDict[id].type === IComponentType.Model3d;
		});
		const sceneHas3dEntity = entity3dArray.length > 0;
		onSetSceneHas3dEntity(sceneHas3dEntity);
		// Don't set it to false just because there is no 3D model, can be in 3D mode with only 2D entities if the user switches
		if(sceneHas3dEntity) onSetIs3dMode(sceneHas3dEntity);	
	}, [activeScene?.children, entityDict, is3dMode])

	// Canvas content
	const canvasContent = useMemo(() => {
		const activeSceneChildren = activeScene?.children || [];
		return activeSceneChildren.map((id, index) => {
			const renderOrder = index + 1;
			const props = {key: id, id, renderOrder: renderOrder * 100}
			const entity = entityDict[id];
			switch (entity.type) {
				case IComponentType.Model3d:
					return <Model3dHOC {...props} model3dUrl={entity.model3dUrl}/>
				case IComponentType.Button:
					return <ButtonHOC {...props} />
				case IComponentType.Text:
					return <TextHOC {...props} />;
				case IComponentType.Image:
					return <ImageHOC {...props} hasAlpha={entity.hasAlpha} imageUrl={entity.imageUrl} />;
				case IComponentType.Video:
					return <VideoHOC thumbnailUrl={entity.thumbnailUrl} videoUrl={entity.videoUrl} hasAlpha={entity.hasAlpha} {...props} />
				default:
					return null;
			}
		});
	}, [activeScene])

	return (
		<Sentry.ErrorBoundary fallback={null} onError={() =>  onAddToast(ToastsData.GeneralError)}>
			<div className={CSS.App} id="zappar-app">
				{(toasts.length > 0) && (<Toasts/>)}
				<Header />
				<EntityMenu />
				<InspectorMenu />
				<ContextMenu />
				<SceneMenu />
				{(!!currentUploads && !!Object.keys(currentUploads).length) && <UploadDialog />}
				{
					!localStorage.getItem('hide-intro-modal') && 
					!closeIntroModal && 
					<IntroModal onClose={() => setCloseIntroModal(true)}/>
				}
				{amergeIsLoaded ? (
					<EditorCanvas store={store}>
						<>
							<TrackingDisplay />
							{canvasContent}
						</>
					</EditorCanvas>
				) : <>
					{!projectLoadingFailure && <div className={CSS.LoadingDiv}>
						{`Loading (${projectLoadingProgress}%)...`}
					</div>}
					<PanelSkeleton />
				</>}
			</div>
		</Sentry.ErrorBoundary>
	);
}

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	return {
		is3dMode: state.userReducer.is3dMode,
		root: getRootComponent(state),
		entityDict: getContentEntityDict(state) as any, //TODO: change
		activeSceneId: state.userReducer.activeSceneId,
		activeScene: getActiveSceneComponent(state),
		selectedEntityIds: state.userReducer.selectedEntityIds,
		projectId: state.userReducer.project.id,
		amergeIsLoaded: !!state?.editorReducer?.editorDoc?.createdAt,
		toasts: state.userReducer.toasts, 
		projectLoadingProgress: state.userReducer.projectLoadingProgress,
		projectLoadingFailure: state.userReducer.projectLoadingFailure,
		currentUploads: getCurrentUploadDict(state),
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onSetHotKey,
			onRemoveHotKey,
			onRemoveEntities_Global,
			onCopySelectedEntities,
			onPasteCopiedEntities_Global,
			onAddToast,
			onSetIs3dMode,
			onSetSceneHas3dEntity,
			onSetMultipleEntityProps_Ed_Doc
		},
		dispatch
	);
};

export default Sentry.withProfiler(connect(mapStateToProps, mapDispatchToProps)(App));
