import React, { useState } from 'react';
import './HueSlider.global.scss';

interface IParentProps {
	hue: number;
	onChange: (newHue: number) => void;
	onSubmit: (newHue: number) => void;
}

const HueSlider: React.SFC<IParentProps> = ({ hue, onChange, onSubmit }) => {
	const [tempHue, setTempHue] = useState<number | null>(hue);
	return (
		<div className={'zcp-hue-slider-container'}>
			<input
				className={'zcp-hue-slider'}
				type="range"
				max={250}
				value={(hue / 360) * 250}
				onChange={e => {
					setTempHue(e.target.valueAsNumber);
					onChange(e.target.valueAsNumber);
				}}
				onMouseUp={() => onSubmit(tempHue)}
			/>
		</div>
	);
};

export default HueSlider;
