import React, { FunctionComponent, memo } from 'react';
import { useSelector } from 'react-redux';
import {
	IDesignerState,
} from '../../../../../../../typings';
import { IFileType } from 'zw-api-client/src/zml/specs';
import ZmlEntitySelection from '../shared/ZmlEntitySelection/ZmlEntitySelection';

interface Model3dSelectionProps {
	onClose: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => any;
}

const Model3dSelection: FunctionComponent<Model3dSelectionProps> = ({onClose}) => {
	const loadedModels = useSelector((state: IDesignerState) => state.userReducer.loaded3dModels);

    return <ZmlEntitySelection loadedEntities={loadedModels} columns={1} fileType={IFileType.Model3d} onClose={onClose}/>;
};

export default memo(Model3dSelection);