import React, { forwardRef, ReactComponentElement, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { IDomIdSelectors } from '../../../../typings';

import { ArrowDownIcon, ArrowUpIcon, CrossIcon } from '../../../assets/icons';
import { useForwardedRef } from '../../r3f/r3f-components/hooks/useForwardedRef';

// import { IIconParentProps } from '../../../../../assets/icons';
import CSS from './Dialog.scss';

interface IParentProps {
	style?: React.CSSProperties;
	className?: string;
	closable?: boolean;
	show?: boolean;
	children: any;
	onMinify?: (b: boolean) => any;
	onClose?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => any;
	appendToElById?: string;
	id?: IDomIdSelectors;
	ref: React.ForwardedRef<HTMLDivElement>
}

const Dialog: React.FunctionComponent<IParentProps> = React.forwardRef(({
	style,
	className = '',
	show,
	onMinify,
	onClose,
	children,
	closable = false,
	appendToElById = 'root',
	id, 
}, ref) => {
	if (!show) return null;
	const dialogRef = useForwardedRef(ref)
	const classes = [CSS.DialogContainer, className, 'z-dialog'];
	const [isMinified, setIsMinified] = useState<boolean>(false);

	const bottom = dialogRef.current ? `-${dialogRef.current.clientHeight}` : '0';

	let Icon = <ArrowDownIcon />;
	if (isMinified) Icon = <ArrowUpIcon/>;
	let clickFn = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
		if (!onMinify) return;
		onMinify(!isMinified);
		setIsMinified(prevState => !prevState);
	};
	if (closable) {
		Icon = <CrossIcon />;
		clickFn = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
			if (!onClose) return;
			onClose(e);
		};
	}

	// append popup to 'root' or specified element
	return createPortal(
		<div
			ref={ref}
			className={classes.join(' ')}
			style={{ ...style, bottom }}
			id={id}
		>
			<span className={CSS.Button} onClick={clickFn}>
				{Icon}
			</span>
			{children}
		</div>,
		document.getElementById(appendToElById)
	);
});

export default Dialog;
