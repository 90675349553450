import { IVector3 } from '../index';
import { IAreaTypes, IUnitTypes } from './general';

export enum ITrackingTypes {
	image = 'image',
	face = 'face',
}

export interface ITargetDisplayInfo_Ed_Doc {
	heightInMm?: number;
	targetArea?: IAreaTypes;
	targetAreaAspectRatio?: number;
	displayUnits?: IUnitTypes;
}

export interface IImageTrackingState_Ed_Doc {
	type: ITrackingTypes.image;
	id: string;
	scale: IVector3;
	url: string;
	aspectRatio: number;
	name: string;
	unit: IUnitTypes;
	opacity: number;
}

export interface IFaceTrackingState_Ed_Doc {
	type: ITrackingTypes.face;
}

export type ITrackingUnionState_Ed_Doc =
	| IImageTrackingState_Ed_Doc
	| IFaceTrackingState_Ed_Doc;

export interface IImageTrackingState_Cn_Doc {
	type: ITrackingTypes.image;
	url: string;
	zmlId: string;
}

export interface IFaceTrackingState_Cn_Doc {
	type: ITrackingTypes.face;
}

export type ITrackingUnionState_Cn_Doc =
	| IImageTrackingState_Cn_Doc
	| IFaceTrackingState_Cn_Doc;
