import React from "react";
import UploadTrkImgBtn from "../../../../../upload/UploadTrkImgBtn/UploadTrkImgBtn";
import CSS from "./TargetAreaProperties.scss";

const TargetProperties: React.FunctionComponent = ({}) => {
  return (
    <>
      <div className={CSS.TargetImageContainer}>
        <div className={CSS.PreviewDiv}>
          <UploadTrkImgBtn>Upload target image</UploadTrkImgBtn>
        </div>
        To enable preview and publish on your project, you need to first add a
        target image.
      </div>
    </>
  );
};

export default TargetProperties;
