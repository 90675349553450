// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "form{width:100%}.FlexContainer--x6W0N{display:flex;flex-direction:column;align-items:flex-start;padding:5px;padding-bottom:32px}.FlexRow--1g4kk{display:flex;align-items:center;width:100%;justify-content:space-between;margin-bottom:8px}.FlexRow--1g4kk .Description--3Tf8T{margin-top:0}.EmailInputField--1Wndo{margin-bottom:8px}.HelpDiv--HU9nh{position:relative;width:fit-content}.Error--2nGn_{color:red}.Description--3Tf8T{color:#5c5c5c;font-size:14px;margin:8px 0;align-self:flex-start;width:100%}.SubmitBtn--CbegJ{width:100%;height:32px;margin-top:24px}", ""]);
// Exports
exports.locals = {
	"FlexContainer": "FlexContainer--x6W0N",
	"FlexRow": "FlexRow--1g4kk",
	"Description": "Description--3Tf8T",
	"EmailInputField": "EmailInputField--1Wndo",
	"HelpDiv": "HelpDiv--HU9nh",
	"Error": "Error--2nGn_",
	"SubmitBtn": "SubmitBtn--CbegJ"
};
module.exports = exports;
