import React, { useEffect, useState, useRef } from 'react';
import { SearchIcon } from '../../../assets/icons';

import CSS from './Progressbar.scss';

interface IParentProps {
	style?: React.CSSProperties;
	className?: string;
	perc: number;
}

const Progressbar: React.SFC<IParentProps> = ({ style, className, perc }) => {
	const classes = [CSS.Container, className ? className : ''];
	return (
		<div className={classes.join(' ')} style={style}>
			<div className={CSS.Progressbar} style={{ width: `${perc}%` }} />
		</div>
	);
};

export default Progressbar;
