import {
	IUserActionTypes,
	IContentDocActionTypes,
	IEditorDocActionTypes,
	IOnRemoveEntities_Global,
	IOnRemoveScenes_Global,
	IOnUpdateUsers_Global,
	IOnUpdateUsers_Action,
	IOnRemoveSelections_Ed_Doc_Action,
	IOnRemoveLocalUserSelectionAction,
	IDisableUndoAction,
	IOnRemoveEntities_Cn_Doc_Action,
	IOnRemoveEntities_Ed_Doc_Action,
	IOnRemoveSceneReferences_Cn_Doc_Action,
	ISetActiveSceneAction,
	ISetCurrentSceneIndexAction,
	IOnSetCurrentUploads,
	ISetCurrentUploadsAction,
	ILoadedMediaTypes,
	IOnTrainImage_Global,
	IOnSetTrackingImageDataAction,
	IOnAddButton_Global,
	IOnCopyScenesAtIndex_Global,
	IOnAddButton_Cn_Doc_Action,
	ISetMultipleEntityProps_Ed_Doc_Action,
	IOnAddImage_Cn_Doc_Action,
	IOnAddImage_Global,
	IOnAddVideo_Cn_Doc_Action,
	IOnAddVideo_Global,
	IOnSetActionData_Global,
	IOnSetActionData_Ed_Doc_Action,
	IOnAddScenes_Global,
	IOnSetMultipleComponentProps_Cn_Doc_Action,
	IMultipleEntityProps_Cn_Doc,
	IMultipleEntityProps_Ed_Doc,
	IOnRemoveSceneReferences_Ed_Doc_Action,
	IOnSetComponentAction_Cn_Doc_Action,
	IOnAddText_Global,
	IOnAddText_Cn_Doc_Action,
	IOnPasteCopiedEntities_Global,
	IPasteCopiedEntities_Cn_Doc_Action,
	IPasteCopiedEntities_Ed_Doc_Action,
	IOnCopyScenesAtIndex_Cn_Doc_Action,
	IOnCopyScenesAtIndex_Ed_Doc_Action,
	ISetSceneSnapshotsAction,
	IAddEntityIdsToSelectionAction,
	IOnAddModel3d_Cn_Doc_Action, 
} from './action-types';
import uuid4 from 'uuid/v4';
import { Dispatch } from 'redux';
import { IDesignerState, ILoadedImageInfo } from '../../../typings';
import { zwClient, ISyncDocUser } from '../../syncdoc';
import { store } from '../../index';
import { IProgressStatus } from 'zw-api-client/src/specs';
import { DEFAULT_SCENE_NAME, getSnapshotDictFromLStorage, updateLStorageWithSnapshot } from '../../utils';
import {
	ITriggerTypes,
	IComponentType,
	IAbstractComponentUnion,
	IRoot,
	ISceneComp,
	IActionCategory,
} from '../../components/r3f/r3f-components/component-data-structure';
import { IOnAdd3dModel_Global } from '.';

export const onTrainImage_Global: IOnTrainImage_Global = (
	img: ILoadedImageInfo
) => {
	return async (dispatch: Dispatch) => {
		const result = await zwClient.zml.analyseTrackingImage(
			img.zmlFileId,
			(perc, progress) => {
				let uploadBaseData = {
					perc,
					fileName: img.name,
					mediaType: ILoadedMediaTypes.trackingImage,
				};
				if (!!progress.errorCode || !!progress.desc)
					dispatch<ISetCurrentUploadsAction>({
						type: IUserActionTypes.SET_CURRENT_UPLOADS,
						payload: {
							id: img.zmlFileId,
							progress: {
								...uploadBaseData,
								type: IProgressStatus.error,
								errorStatus: progress.errorCode,
								errorText: progress.desc,
							},
						},
					});
				else
					dispatch<ISetCurrentUploadsAction>({
						type: IUserActionTypes.SET_CURRENT_UPLOADS,
						payload: {
							id: img.zmlFileId,
							progress: {
								...uploadBaseData,
								type: IProgressStatus.analysing,
							},
						},
					});
			}
		);

		zwClient.zml.trainTrackingImage(img.zmlFileId, (perc, progress) => {
			let uploadBaseData = {
				perc,
				fileName: img.name,
				mediaType: ILoadedMediaTypes.trackingImage,
			};
			if (!!progress.errorCode || !!progress.desc)
				dispatch<ISetCurrentUploadsAction>({
					type: IUserActionTypes.SET_CURRENT_UPLOADS,
					payload: {
						id: img.zmlFileId,
						progress: {
							...uploadBaseData,
							type: IProgressStatus.error,
							errorStatus: progress.errorCode,
							errorText: progress.desc,
						},
					},
				});
			else if (perc < 100)
				dispatch<ISetCurrentUploadsAction>({
					type: IUserActionTypes.SET_CURRENT_UPLOADS,
					payload: {
						id: img.zmlFileId,
						progress: {
							...uploadBaseData,
							type: IProgressStatus.training,
						},
					},
				});
			else
				dispatch<ISetCurrentUploadsAction>({
					type: IUserActionTypes.SET_CURRENT_UPLOADS,
					payload: {
						id: img.zmlFileId,
						progress: {
							...uploadBaseData,
							type: IProgressStatus.trainingCompleted,
						},
					},
				});
		});
	};
};

export const onUpdateUsers_Global: IOnUpdateUsers_Global = (
	syncUsers: ISyncDocUser[]
) => {
	return (dispatch: Dispatch) => {
		const oldUserIds = Object.keys(store.getState().userReducer.loggedInUsers);
		const updatedUserIds = syncUsers.map(syncUser => syncUser.id);
		const userIdsToRemove = oldUserIds.filter(
			oldUserId => !updatedUserIds.includes(oldUserId)
		);
		dispatch<IOnUpdateUsers_Action>({
			type: IUserActionTypes.UPDATE_USERS,
			payload: { users: syncUsers },
		});
		dispatch<IOnRemoveSelections_Ed_Doc_Action>({
			type: IEditorDocActionTypes.REMOVE_USER_SELECTION_ED_DOC,
			payload: { userIds: userIdsToRemove },
		});
	};
};

export const onRemoveEntities_Global: IOnRemoveEntities_Global = (
	entityIds: string[]
) => {
	return (dispatch: Dispatch) => {
		dispatch<IOnRemoveLocalUserSelectionAction>({
			type: IUserActionTypes.REMOVE_LOCAL_USER_SELECTION,
		});
		dispatch<IDisableUndoAction>({
			type: IUserActionTypes.DISABLE_UNDO_BTN,
			payload: false,
		});
		dispatch<IOnRemoveEntities_Cn_Doc_Action>({
			type: IContentDocActionTypes.REMOVE_ENTITIES_CN_DOC,
			payload: { ids: entityIds },
		});
		dispatch<IOnRemoveEntities_Ed_Doc_Action>({
			type: IEditorDocActionTypes.REMOVE_ENTITIES_ED_DOC,
			payload: { ids: entityIds },
		});
	};
};

export const onAddButton_Global: IOnAddButton_Global = pld => {
	return (dispatch: Dispatch) => {
		const payload: any = {
			id: pld.id,
			activeSceneId: pld.activeSceneId,
			type: IComponentType.Button,
			position: pld.position,
			rotation: pld.rotation,
			color: pld.color,
			borderWidth: pld.borderWidth,
			fontRgba: pld.fontRgba,
			fontSize: pld.fontSize,
			scale: pld.scale,
			fontFamily: pld.fontFamily, 
			text: pld.text,
			borderRadius: pld.borderRadius,
			borderRgba: pld.borderRgba,
			textAlignment: pld.textAlignment,
		}
		if(pld.textureUrl) payload.textureUrl = pld.textureUrl;
		if(pld.svgUrl) payload.svgUrl = pld.svgUrl;

		console.log('onADDBUTTON', payload)
		dispatch<IOnAddButton_Cn_Doc_Action>({
			type: IContentDocActionTypes.ADD_BUTTON_CN_DOC,
			payload,
		});
		dispatch<ISetMultipleEntityProps_Ed_Doc_Action>({
      type: IEditorDocActionTypes.SET_MULTIPLE_ENTITY_PROPS_ED_DOC,
      payload: [
        {
          id: pld.id,
          titles: pld.title,
          isLocked: pld.isLocked,
          editingDisabled: pld.textureUrl || pld.svgUrl ? true : false, // If a button has a texture, can't edit appearance
          aspectRatioLocked: pld.aspectRatioLocked,
          scalesInverted: pld.scalesInverted,
          activeTriggers: ITriggerTypes.onTap,
        },
      ],
    });
		dispatch<IOnRemoveLocalUserSelectionAction>({
			type: IUserActionTypes.REMOVE_LOCAL_USER_SELECTION,
		});
		dispatch<IAddEntityIdsToSelectionAction>({
			type: IUserActionTypes.ADD_ENTITY_IDS_TO_SELECTION, 
			payload: [pld.id],
		});
	};
};

export const onAddText_Global: IOnAddText_Global = ({
	id,
	activeSceneId,
	position,
	scale, 
	rotation,
	fontFamily, 
	fontSize, 
	fontRgba, 
	text,
	title, 
	textAlignment,
}) => {
	return (dispatch: Dispatch) => {
		dispatch<IOnAddText_Cn_Doc_Action>({
			type: IContentDocActionTypes.ADD_TEXT_CN_DOC,
			payload: {
				id,
				activeSceneId,
				type: IComponentType.Text,
				color: [0, 0, 0, 0],
				fontFamily,
				position,
				rotation,
				fontRgba,
				fontSize,
				scale,
				text,
				textAlignment, 
			},
		});
		dispatch<ISetMultipleEntityProps_Ed_Doc_Action>({
			type: IEditorDocActionTypes.SET_MULTIPLE_ENTITY_PROPS_ED_DOC,
			payload: [
				{
					id,
					titles: title,
					isLocked: false,
					aspectRatioLocked: false,
					scalesInverted: [false, false],
					activeTriggers: ITriggerTypes.onTap,
				},
			],
		});
		dispatch<IOnRemoveLocalUserSelectionAction>({
			type: IUserActionTypes.REMOVE_LOCAL_USER_SELECTION,
		});
		dispatch<IAddEntityIdsToSelectionAction>({
			type: IUserActionTypes.ADD_ENTITY_IDS_TO_SELECTION, 
			payload: [id],
		});
	};
};

export const onAddImage_Global: IOnAddImage_Global = ({
	activeSceneId,
	position,
	entity,	
}) => {
	return (dispatch: Dispatch) => {
		const { id, aspectRatio, zmlFileId, url: imageUrl, name: title } = entity;
		const hasAlpha = title.includes('.png') ? true : false;
		let scale = [0.3, 0.3 / aspectRatio, 0];
		if (aspectRatio < 1) scale = [0.24 * aspectRatio, 0.24, 0];
		dispatch<IOnAddImage_Cn_Doc_Action>({
			type: IContentDocActionTypes.ADD_IMAGE_CN_DOC,
			payload: {
				id,
				zmlFileId,
				activeSceneId,
				type: IComponentType.Image,
				position,
				opacity: 1,
				rotation: [0, 0, 0],
				scale,
				imageUrl,
				hasAlpha,
			},
		});
		dispatch<ISetMultipleEntityProps_Ed_Doc_Action>({
			type: IEditorDocActionTypes.SET_MULTIPLE_ENTITY_PROPS_ED_DOC,
			payload: [
				{
					id,
					zmlFileId,
					titles: title || 'Image',
					isLocked: false,
					aspectRatioLocked: true,
					scalesInverted: [false, false],
					activeTriggers: ITriggerTypes.onTap,
				},
			],
		});
		dispatch<IOnRemoveLocalUserSelectionAction>({
			type: IUserActionTypes.REMOVE_LOCAL_USER_SELECTION,
		});
		dispatch<IAddEntityIdsToSelectionAction>({
			type: IUserActionTypes.ADD_ENTITY_IDS_TO_SELECTION, 
			payload: [id],
		});

		// const defaultActionPayload = {
		// 	ids: [id],
		// 	triggerType: ITriggerTypes.onTap,
		// 	selectedActionCategory: IActionCategory.enlarge,
		// 	enlarge: true,
		// }

		// dispatch<IOnSetActionData_Ed_Doc_Action>({
		// 	type: IEditorDocActionTypes.SET_ENTITY_ACTION_DATA_ED_DOC,
		// 	payload: defaultActionPayload,
		// });
		// dispatch<IOnSetComponentAction_Cn_Doc_Action>({
		// 	type: IContentDocActionTypes.SET_COMPONENT_ACTION_CN_DOC,
		// 	payload: {
		// 		ids: [id],
		// 		triggerType: ITriggerTypes.onTap,
		// 		[IActionCategory.enlarge as any]: true,
		// 	},
		// });
	};
};

export const onAddVideo_Global: IOnAddVideo_Global = ({
	activeSceneId,
	position,
	entity,
}) => {
	return (dispatch: Dispatch) => {
		const {
			id,
			aspectRatio,
			zmlFileId,
			videoUrl,
			name: title,
			thumbnailUrl,
			mp4Url,
			source,
			externalId,
			hasAlpha
		} = entity;
		let scale = [0.35 * 2, 0.35 * 2 / aspectRatio, 0];
		if (aspectRatio < 1) scale = [0.3 * 2 * aspectRatio, 0.3 * 2, 0];
		dispatch<IOnAddVideo_Cn_Doc_Action>({
			type: IContentDocActionTypes.ADD_VIDEO_CN_DOC,
			payload: {
				id,
				zmlFileId,
				activeSceneId,
				type: IComponentType.Video,
				position,
				rotation: [0, 0, 0],
				scale: scale,
				videoUrl: videoUrl || null,
				thumbnailUrl,
				source,
				externalId,
				hasAlpha: hasAlpha || false
			},
		});
		dispatch<ISetMultipleEntityProps_Ed_Doc_Action>({
			type: IEditorDocActionTypes.SET_MULTIPLE_ENTITY_PROPS_ED_DOC,
			payload: [
				{
					id,
					zmlFileId,
					titles: title || 'Video',
					isLocked: false,
					aspectRatioLocked: true,
					scalesInverted: [false, false],
					activeTriggers: ITriggerTypes.onTap,
					mp4Url: mp4Url
				},
			],
		});
		dispatch<IOnRemoveLocalUserSelectionAction>({
			type: IUserActionTypes.REMOVE_LOCAL_USER_SELECTION,
		});
		dispatch<IAddEntityIdsToSelectionAction>({
			type: IUserActionTypes.ADD_ENTITY_IDS_TO_SELECTION, 
			payload: [id],
		});
	};
};

export const onAddModel3d_Global: IOnAdd3dModel_Global = ({
	activeSceneId,
	position,
	entity,
}) => {
	return (dispatch: Dispatch) => {
		// if model wider than tall make width half target size width
		const widerThanTall = entity.dimensions[0] > entity.dimensions[1];
		const scale = [
			!widerThanTall ? entity.dimensions[0] / entity.dimensions[1] : 1,
			!widerThanTall ? 1 : entity.dimensions[1] / entity.dimensions[0],
			!widerThanTall ? entity.dimensions[2] / entity.dimensions[1] : entity.dimensions[2] /  entity.dimensions[0]
		]

		dispatch<IOnAddModel3d_Cn_Doc_Action>({
			type: IContentDocActionTypes.ADD_MODEL3D_CN_DOC,
			payload: {
				type: IComponentType.Model3d,
				id: entity.id,
				activeSceneId,
				scale,
				position,
				rotation: [0, 0, 0],
				model3dUrl: entity.model3dUrl,
				// dimensions: entity.dimensions,
				thumbnailUrl: entity.thumbnailUrl
			}
		});
		dispatch<ISetMultipleEntityProps_Ed_Doc_Action>({
			type: IEditorDocActionTypes.SET_MULTIPLE_ENTITY_PROPS_ED_DOC,
			payload: [
				{
					id: entity.id,
					// zmlFileId: entity.zmlFileId,
					titles: entity.name || '3d Model',
					isLocked: false,  
					aspectRatioLocked: true,
					scalesInverted: [false, false, false],
					activeTriggers: ITriggerTypes.onTap,
				},
			],
		});
		dispatch<IOnRemoveLocalUserSelectionAction>({
			type: IUserActionTypes.REMOVE_LOCAL_USER_SELECTION,
		});
		dispatch<IAddEntityIdsToSelectionAction>({
			type: IUserActionTypes.ADD_ENTITY_IDS_TO_SELECTION, 
			payload: [entity.id],
		});
	}
}

export const onCopyScenesAtIndex_Global: IOnCopyScenesAtIndex_Global = ({
	projectId,
	sceneIds,
	index
}) => {
	const { componentsById } = store.getState().contentReducer.contentDoc;
	const oldToNewIdDict: {[id: string]: string} = {}

	// const newSceneIds = sceneIds.map(() => uuid4());

	for (let i = 0; i < sceneIds.length; i++) {
		const sceneId = sceneIds[i];
		// add new scene id to dict
		oldToNewIdDict[sceneId] = uuid4();
		// add new spatial component ids to dict
		const { children } = componentsById[sceneId] as ISceneComp;
		for (let j = 0; j < children.length; j++) {
			const componentId = children[j];
			oldToNewIdDict[componentId] = uuid4();
		}
	}

	// TODO: refactor once multiple scene selection enabled
	updateLStorageWithSnapshot(oldToNewIdDict[sceneIds[0]], projectId);

	return (dispatch: Dispatch) => {
		
		dispatch<ISetSceneSnapshotsAction>({
			type: IUserActionTypes.SET_SCENE_SNAPSHOTS,
			payload: getSnapshotDictFromLStorage(localStorage, projectId)
		})

		dispatch<IOnCopyScenesAtIndex_Cn_Doc_Action>({
			type: IContentDocActionTypes.COPY_SCENES_AT_INDEX_CN_DOC,
			payload: { sceneIds, oldToNewIdDict, index },
		})
		dispatch<IOnCopyScenesAtIndex_Ed_Doc_Action>({
			type: IEditorDocActionTypes.COPY_SCENES_ED_DOC,
			payload: { sceneIds, oldToNewIdDict }
		})

		dispatch<ISetActiveSceneAction>({
			type: IUserActionTypes.SET_ACTIVE_SCENE,
			payload: { activeSceneId: oldToNewIdDict[sceneIds[0]] }
		})

	};
}

export const onAddScenes_Global: IOnAddScenes_Global = ({
	sceneIds,
	newSceneIds,
	rootId,
	index,
}) => {
	let rootChildren = [...sceneIds];
	let newComponentData: IMultipleEntityProps_Cn_Doc[] = [];
	let sceneTitles: IMultipleEntityProps_Ed_Doc[] = [];
	for (let i = 0; i < newSceneIds.length; i++) {
		rootChildren.splice(index + i, 0, newSceneIds[i]);
		sceneTitles.push({
			id: newSceneIds[i],
			titles: `${DEFAULT_SCENE_NAME} ${index + i + 1}`,
		});
		newComponentData.push({
			id: newSceneIds[i],
			type: IComponentType.Scene,
			children: [],
		});
	}

	return (dispatch: Dispatch) => {
		dispatch<IOnSetMultipleComponentProps_Cn_Doc_Action>({
			type: IContentDocActionTypes.SET_MULTIPLE_COMPONENT_PROPS_CN_DOC,
			payload: [
				{
					id: rootId,
					children: rootChildren,
				},
				...newComponentData,
			],
		});
		dispatch<ISetMultipleEntityProps_Ed_Doc_Action>({
			type: IEditorDocActionTypes.SET_MULTIPLE_ENTITY_PROPS_ED_DOC,
			payload: sceneTitles,
		});
	};
};

export const onRemoveScenes_Global: IOnRemoveScenes_Global = (
	sceneIds: string[]
) => {
	const rootId = store.getState().contentReducer.contentDoc.rootComponentId;
	const componentDict = store.getState().contentReducer.contentDoc
		.componentsById;
	const rootComponent = componentDict[rootId] as IAbstractComponentUnion;
	if (rootComponent.children.length === 1) return;

	return (dispatch: Dispatch, getState: () => IDesignerState) => {
		let componentIdsToDelete = sceneIds; // delete scenes
		for (let i = 0; i < sceneIds.length; i++) {
			const sceneChildren = (componentDict[
				sceneIds[i]
			] as IAbstractComponentUnion).children;
			componentIdsToDelete = [...componentIdsToDelete, ...sceneChildren]; // delete entities in scenes
		}
		const firstSceneSelected =
			getState().userReducer.activeSceneId === rootComponent.children[0];
		const indexOfFirstDeletedScene = rootComponent.children.indexOf(
			sceneIds[0]
		);
		const newActiveSceneIndex = firstSceneSelected
			? 0
			: indexOfFirstDeletedScene - 1;

		dispatch<IOnRemoveLocalUserSelectionAction>({
			type: IUserActionTypes.REMOVE_LOCAL_USER_SELECTION,
		});
		dispatch<IDisableUndoAction>({
			type: IUserActionTypes.DISABLE_UNDO_BTN,
			payload: false,
		});
		dispatch<ISetActiveSceneAction>({
			type: IUserActionTypes.SET_ACTIVE_SCENE,
			payload: { activeSceneId: rootComponent.children[newActiveSceneIndex] },
		});
		dispatch<ISetCurrentSceneIndexAction>({
			type: IUserActionTypes.SET_CURRENT_SCENE_INDEX,
			payload: newActiveSceneIndex,
		});
		dispatch<IOnRemoveEntities_Cn_Doc_Action>({
			type: IContentDocActionTypes.REMOVE_ENTITIES_CN_DOC,
			payload: { ids: componentIdsToDelete },
		});
		dispatch<IOnRemoveEntities_Ed_Doc_Action>({
			type: IEditorDocActionTypes.REMOVE_ENTITIES_ED_DOC,
			payload: { ids: componentIdsToDelete },
		});
		dispatch<IOnRemoveSceneReferences_Cn_Doc_Action>({
			type: IContentDocActionTypes.REMOVE_SCENE_REFERENCES_CN_DOC,
			payload: { sceneIds },
		});
		dispatch<IOnRemoveSceneReferences_Ed_Doc_Action>({
			type: IEditorDocActionTypes.REMOVE_SCENE_REFERENCES_ED_DOC,
			payload: { sceneIds },
		});
	};
};

export const onSetActionData_Global: IOnSetActionData_Global = payload => {
	return (dispatch: Dispatch) => {
		dispatch<IOnSetActionData_Ed_Doc_Action>({
			type: IEditorDocActionTypes.SET_ENTITY_ACTION_DATA_ED_DOC,
			payload,
		});
		const {
			ids,
			selectedActionCategory: actionCategory,
			triggerType,
			...data
		} = payload;
		// TODO: change 'data' structure
		if (!data) return;
		const key = Object.keys(data)[0] as keyof typeof data;
		dispatch<IOnSetComponentAction_Cn_Doc_Action>({
			type: IContentDocActionTypes.SET_COMPONENT_ACTION_CN_DOC,
			payload: {
				ids,
				triggerType,
				[actionCategory]: data[key] || {},
			},
		});
	};
};

export const onPasteCopiedEntities_Global: IOnPasteCopiedEntities_Global = pld => {
	const newEntityIds = pld.copiedIds.map(id => uuid4());
	const { offset, ...restPld } = pld;

	const edDocPayload = {...restPld, newEntityIds};
	const cnDocPayload = {...edDocPayload, offset};

	return (dispatch: Dispatch) => {
		dispatch<IPasteCopiedEntities_Ed_Doc_Action>({
			type: IEditorDocActionTypes.PASTE_COPIED_ENTITIES_ED_DOC,
			payload: edDocPayload
		})
		dispatch<IPasteCopiedEntities_Cn_Doc_Action>({
			type: IContentDocActionTypes.PASTE_COPIED_ENTITIES_CN_DOC,
			payload: cnDocPayload
		});
	}
}