import React, { useState, memo } from 'react';

import Divider from '../Divider/Divider';
import { ArrowRightIcon as ArrowIcon } from '../../../assets/icons/index';

import CSS from './Shelf.scss';

interface IParentProps {
	style?: any;
	title: string;
	children: any;
	open?: boolean;
	show?: boolean;
	id?: string;
	onOpen?: (id: string) => any;
	onClose?: (id: string) => any;
	forceClose?: boolean;
	className?: string;
	noDivider?: boolean;
	icon?: React.FunctionComponent;
	disabled?: boolean; 
}

const Shelf: React.FunctionComponent<IParentProps> = ({
	style,
	title,
	children,
	open,
	show,
	id,
	onOpen,
	onClose,
	noDivider,
	className,
	icon: Icon,
	disabled, 
}) => {
	const [menuIsFullyOpened, setMenuIsFullyOpened] = useState(open);

	return (
		 show ? 
			<div style={{ ...style, width: '100%' }} >
				<button
					className={`${CSS.DrawerBtn} ${className || ''}`}
					onClick={() => {
						if(disabled) return; 
						if (!open) onOpen?.(id);
						if (open) onClose?.(id);
						setMenuIsFullyOpened(false);
					}}
					disabled={disabled}
				>
					<div className={`${CSS.TitleContainer} ${open ? CSS.Open : ''}`}>
						{Icon ? <Icon /> : null}
						{title}
					</div>
					<ArrowIcon
						viewBox={[0, 0, 7, 7]}
						className={open ? `${CSS.RotatedArrow} ${CSS.Arrow}` : CSS.Arrow}
					/>
				</button>
				<div 
					style={{overflow: menuIsFullyOpened && open ? 'visible' : 'hidden'}}
					className={`${CSS.Content} ${open ? CSS.Open : CSS.Closed}`}
					onTransitionEnd={() => { 
						if(open) setMenuIsFullyOpened(true) 
						else setMenuIsFullyOpened(false)
					}}
				>
					{open ? children : null}
				</div>
				{noDivider ? null : <Divider width="100%" />}
			</div> : null
	);
};

export default memo(Shelf);
