import React from 'react';
import { connect } from 'react-redux';
import { IDesignerState } from '../../../../../typings';
import { IUserData } from '../../../r3f/r3f-components/component-data-structure';
import CSS from './TeamView.scss';

interface IParentProps {
	className?: string;
}

interface IReduxProps {
	userId: string;
	socketHasConnected: boolean;
	users: { [id: string]: IUserData };
}

const TeamView: React.SFC<IParentProps & IReduxProps> = ({
	users,
	className = '',
	socketHasConnected,
	userId,
}) => {
	if (!socketHasConnected) return null;
	const classes = [CSS.Container, className];
	let loggedInUserIds = Object.keys(users);
	if (loggedInUserIds.length === 0 || !userId) return null;

	for (let i = 0; i < loggedInUserIds.length; i++) {
		if (loggedInUserIds[i] === userId) {
			loggedInUserIds.splice(i, 1);
		}
	}

	loggedInUserIds = [userId, ...loggedInUserIds];

	const userNameDivs = loggedInUserIds.map((id, i) => {
		const user = users[id];
		const firstLetter = user.name.charAt(0).toUpperCase();
		return (
			<div
				key={id}
				className={CSS.NameDiv}
				style={{
					left: `${i * 20}px`,
					backgroundColor: `#4a90e2`,
					zIndex: 50 - i,
				}}
			>
				{user.avatarUrl ? <img src={user.avatarUrl}/> : <span style={{ color: 'inherit' }}>{firstLetter}</span>}
			</div>
		);
	});

	return (
		<div
			className={classes.join(' ')}
			style={{ height: '28px', width: `${userNameDivs.length * 20}px` }}
		>
			{userNameDivs}
		</div>
	);
};

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	return {
		userId: state.userReducer.userId,
		users: state.userReducer.loggedInUsers,
		socketHasConnected: state.userReducer.socketHasConnected,
	};
};

export default connect(mapStateToProps)(TeamView);
