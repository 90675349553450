import React, { FunctionComponent, useMemo, memo } from 'react';
import { Object3D, Texture, Shape, Path } from 'three';
import { ISharedHOCProps } from '../types';
import { ITuple3, ITuple4 } from '../component-data-structure';
import {
	createRoundedRectInnerForm,
	createRoundedRectShape,
} from '../utils/general';
import ShapeMesh from './ShapeMesh';

export interface IRoundRectParentProps {
	name?: string;
	rotation?: ITuple3;
	position?: ITuple3;
	scale: ITuple3;
	radius?: number;
	opacity: number;
	bw?: number;
	visible?: boolean;
	texture?: Texture;
	fillRgba?: ITuple4;
	borderRgba?: ITuple4;
	borderWidth?: number;
	depthWrite?: boolean;
	renderOrder?: number;
	hasAlpha?: boolean;
	stackedAlpha?: boolean;
	onUpdate?: (self: Object3D) => any;
}

const RoundedRectWithBorder: FunctionComponent<
	IRoundRectParentProps & ISharedHOCProps
> = ({
	scale,
	opacity = 1,
	position = [0, 0, 0],
	rotation = [0, 0, 0],
	onPointerUp,
	onPointerDown,
	onPointerMove,
	onDoubleClick,
	radius = 0,
	texture,
	fillRgba,
	borderRgba: brgba = [0, 0, 0, 1],
	borderWidth: bw = 0,
	renderOrder = 0,
	hasAlpha = false,
	stackedAlpha = false,
	visible = true,
	name = '',
}) => {
	const s = useMemo(
		() =>
			[
				scale[0] + 2 * (scale[0] < 0 ? -bw : bw),
				scale[1] + 2 * (scale[1] < 0 ? -bw : bw),
				scale[2],
			] as ITuple3,
		[scale, bw]
	);
	const zeroPos = useMemo(() => [0, 0, 0] as ITuple3, []);
	const innerRoundedRect = useMemo(
		() => createRoundedRectInnerForm(new Shape(), s, radius, bw, true),
		[s, radius, bw]
	);
	const outerBorderRoundedRect = useMemo(() => {
		const holePath = createRoundedRectInnerForm(new Path(), s, radius, bw);
		return createRoundedRectShape(s, radius, holePath);
	}, [s, radius, bw]);

	return (
		<group
			key={'image_group'}
			visible={visible}
			// renderOrder={renderOrder}
			position={position as ITuple3 || zeroPos}
			rotation={rotation as ITuple3 || zeroPos}
			onPointerUp={onPointerUp || undefined}
			onPointerDown={onPointerDown || undefined}
			onPointerMove={onPointerMove || undefined}
			onDoubleClick={onDoubleClick || undefined}
		>
			<ShapeMesh
				name={name}
				key={'img_bkgr'}
				scale={scale as ITuple3}
				position={position as ITuple3}
				rotation={rotation as ITuple3}
				opacity={opacity}
				color={fillRgba}
				texture={texture}
				hasAlpha={hasAlpha}
				stackedAlpha={stackedAlpha}
				shape={innerRoundedRect}
				depthWrite={false}
				renderOrder={renderOrder + 1}
			/>
			{!!bw && (
				<ShapeMesh
					name={name}
					key={'img_border_bkgr'}
					scale={s}
					position={position as ITuple3}
					rotation={rotation as ITuple3}
					color={brgba as ITuple4}
					opacity={brgba[3]}
					shape={outerBorderRoundedRect}
					depthWrite={false}
					renderOrder={renderOrder}
				/>
			)}
		</group>
	);
};

RoundedRectWithBorder.displayName = 'RoundedRectWithBorder';
export default memo(RoundedRectWithBorder);
