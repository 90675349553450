import React, { useState, useRef, useEffect, useMemo, FunctionComponent } from 'react';
import DragElement from './DragElement/DragElement';
import './DragArea.global.scss';
import { useComponentWillMount } from '../../../../utils';


interface IDragAreaPosition {
	bottom: number;
	height: number;
	left: number;
	right: number;
	top: number;
	width: number;
}

interface IParentProps {
	dragCursorDimensions: [number, number];
	swatchDimensions: [number, number];
	backgroundColor: string;
	// dragElPosition: [number, number] | null;
	value: number;
	saturation: number;
	onSubmit?: (args: { saturation: number; value: number }) => void;
	onChange?: (args: { saturation: number; value: number }) => void;
	onPointerUp?: () => any;
}

interface IReduxProps {}

interface IDispatchProps {}

const DragArea: FunctionComponent<IParentProps & IReduxProps & IDispatchProps> = ({
	dragCursorDimensions,
	swatchDimensions,
	onSubmit,
	onChange,
	backgroundColor,
	// dragElPosition: dragPos,
	value,
	saturation,
	onPointerUp,
}) => {
	const backgroundEl = useRef(null);
	const dragAreaEl = useRef(null);
	const [dragElTop, setDragElTop] = useState<number | null>(null);
	const [dragElLeft, setDragElLeft] = useState<number | null>(null);
	const [dragActive, setDragActive] = useState<boolean>(false);
	const [dragAreaPos, setDragAreaPos] = useState<IDragAreaPosition | null>(
		null
	);

	useEffect(() => {
		setDragAreaPos(dragAreaEl.current.getBoundingClientRect());
	}, [])

	// Run before mount and if saturation & value change
	useEffect(() => {
		if (dragActive) return;
		const pos = [((value - 100) * swatchDimensions[1]) / -100, (saturation / 100) * swatchDimensions[0]];
		setDragElTop(pos[0] - dragCursorDimensions[0] / 2);
		setDragElLeft(pos[1] - dragCursorDimensions[1] / 2);
	}, [value, saturation, swatchDimensions]);

	const calcColors = useMemo(() => (p: {top: number, left: number}) => {
		return {
			saturation: (100 * (p.left + dragCursorDimensions[0] / 2)) / swatchDimensions[0],
			value: (-100 * (p.top + dragCursorDimensions[1] / 2)) / swatchDimensions[1] + 100,
		}
	}, [dragCursorDimensions, swatchDimensions])

	const moveDragElement = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const dragElHalfHeight = dragCursorDimensions[1] / 2;
		const dragElHalfWidth = dragCursorDimensions[0] / 2;
		let top = e.pageY - dragAreaPos.top - dragElHalfHeight - 1;
		let left = e.pageX - dragAreaPos.left - dragElHalfWidth;

		if (e.pageY <= dragAreaPos.top) top = dragElHalfHeight * -1;
		if (e.pageY > dragAreaPos.bottom)
			top = dragAreaPos.height - dragElHalfHeight;
		if (e.pageX < dragAreaPos.left) left = dragElHalfWidth * -1;
		if (e.pageX > dragAreaPos.right) left = dragAreaPos.width - dragElHalfWidth;

		setDragElTop(top);
		setDragElLeft(left);
		return {top, left};
	};

	const pointerDownHandler = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		setDragActive(true);
		pointerMoveHandler(e);
	};

	const pointerMoveHandler = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		const dragPos = moveDragElement(e);
		if (onChange) onChange(calcColors(dragPos));
	};

	const pointerUpHandler = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		setDragActive(false);
		const dragPos = moveDragElement(e);
		if (onSubmit) onSubmit(calcColors(dragPos));
		if (onPointerUp) onPointerUp();
	};

	return (
		<>
			{dragActive && (
				<div
					className={'zcp-swatch-bkgr'}
					ref={backgroundEl}
					onPointerMove={pointerMoveHandler}
					onPointerUp={pointerUpHandler}
				/>
			)}
			<div
				className={'zcp-swatch-drag-area'}
				ref={dragAreaEl}
				style={{ backgroundColor: `#${backgroundColor}` }}
				onPointerDown={pointerDownHandler}
			>
				<DragElement
					top={`${dragElTop}px`}
					left={`${dragElLeft}px`}
					width={`${dragCursorDimensions[0]}px`}
					height={`${dragCursorDimensions[1]}px`}
				/>
			</div>
		</>
	);
};

export default DragArea;
