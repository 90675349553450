import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { Color, Object3D } from "three";
import { IVector3 } from "../../r3f-components/component-data-structure";

export const DEFAULT_X_AXIS_COLOR_ACTIVE = 0xFF264A;
export const DEFAULT_X_AXIS_COLOR_INACTIVE = 0xFFB3BF;
export const DEFAULT_Y_AXIS_COLOR_ACTIVE = 0x26FF9A;
export const DEFAULT_Y_AXIS_COLOR_INACTIVE = 0xB3FFDB;
export const DEFAULT_Z_AXIS_COLOR_ACTIVE = 0x26B0FF;
export const DEFAULT_Z_AXIS_COLOR_INACTIVE = 0xB3E3FF;
export const DEFAULT_XYZ_AXIS_COLOR_ACTIVE = 0xFFB026;
export const DEFAULT_XYZ_AXIS_COLOR_INACTIVE = 0xFFE3B3;
export const DEFAULT_XY_AXIS_COLOR_ACTIVE = 0xffff00;
export const DEFAULT_XY_AXIS_COLOR_INACTIVE = 0xffffbb;
export const DEFAULT_YZ_AXIS_COLOR_ACTIVE = 0x00ffff;
export const DEFAULT_YZ_AXIS_COLOR_INACTIVE = 0xbbffff;
export const DEFAULT_XZ_AXIS_COLOR_ACTIVE = 0xff00ff;
export const DEFAULT_XZ_AXIS_COLOR_INACTIVE = 0xffbbff;
export const DEFAULT_XYZX_AXIS_COLOR_ACTIVE = 0xffffff;
export const DEFAULT_XYZX_AXIS_COLOR_INACTIVE = 0xaaaaaa;
export const DEFAULT_XYZY_AXIS_COLOR_ACTIVE = 0xffffff;
export const DEFAULT_XYZY_AXIS_COLOR_INACTIVE = 0xaaaaaa;
export const DEFAULT_XYZZ_AXIS_COLOR_ACTIVE = 0xffffff;
export const DEFAULT_XYZZ_AXIS_COLOR_INACTIVE = 0xaaaaaa;
export const DEFAULT_E_AXIS_COLOR_ACTIVE = 0xFFB026;
export const DEFAULT_E_AXIS_COLOR_INACTIVE = 0xFFE3B3;
export const DEFAULT_XYZE_AXIS_COLOR_ACTIVE = 0x787878;
export const DEFAULT_XYZE_AXIS_COLOR_INACTIVE = 0x232323;
export const DEFAULT_TRANSLATE_LINE_THICKNESS = 5.0;
export const DEFAULT_SCALE_LINE_THICKNESS = 5.0;
export const DEFAULT_ROTATE_LINE_THICKNESS = 5.0;
export const DEFAULT_OVERALL_LINE_THICKNESS = 5.0;
export const DEFAULT_INITIAL_ENTITY_ROTATION = [0, 0, 0];

export type TransformControlsGizmoPrivateGizmos = {
  ["translate"]: Object3D;
  ["scale"]: Object3D;
  ["rotate"]: Object3D;
  ["visible"]: boolean;
};

export enum Axis { 'X' = 'X' , 'Y' = 'Y' , 'Z' = 'Z' , 'XYZ' = 'XYZ' , 'XY' = 'XY' , 'XZ' = 'XZ' , 'YZ' = 'YZ', 'XYZY' = 'XYZY' , 'XYZX' = 'XYZX' , 'XYZZ' = 'XYZZ' , 'XYZE' = 'XYZE' , 'E' = 'E' }
export enum Mode { 'translate' = 'translate' , 'scale' = 'scale' , 'rotate' = 'rotate'}

export type AxisColorDictionary = {
  [axis in Axis]?: {
    active: number | string | Color;
    inactive: number | string | Color;
}}

export interface GizmoModeProps {
  axisToShow?: Axis[];  // Which axis to show
  lineThickness?: number; // Line thickness just for this gizmo
  axisColors?: AxisColorDictionary; // e.g. axisColors.X.active = 0xff0000, axisColors.X.inactive = 0xbb0000
}

export interface GizmoProps {
  lineThickness?: number; // Single setting for all axis line widths within the gizmo - rotate, translate, scale ( not the helper lines )
  gizmoTranslateProps?: GizmoModeProps;
  gizmoScaleProps?: GizmoModeProps;
  gizmoRotateProps?: GizmoModeProps;
  initialEntityRotation?: IVector3;
}

export const CircleGeometry = (radius: number, arc: number): LineGeometry => {
  const geometry = new LineGeometry();
  const vertices = [];

  for (let i = 0; i <= 64 * arc; ++i) {
    vertices.push(
      0,
      Math.cos((i / 32) * Math.PI) * radius,
      Math.sin((i / 32) * Math.PI) * radius
    );
  }

  geometry.setPositions(vertices);
  return geometry;
};
