import { ReactThreeFiber, useThree } from '@react-three/fiber'
import React, { useLayoutEffect, useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import { Group, Object3D } from 'three'
import { IVector3 } from '../../r3f-components/component-data-structure'
import { TransformControls as TransformControlsImpl } from './TransformControlsThree'

const pick = (haystack: any, keyNames: any) => {
  return { keyNames } = haystack;
}

const omit = (obj: any, props: any) => {
  obj = { ...obj }
  // @ts-ignore
  props.forEach(prop => delete obj[prop]) 
  return obj
}

type ControlsProto = {
  enabled: boolean
}

export type TransformControlsProps = ReactThreeFiber.Object3DNode<TransformControlsImpl, typeof TransformControlsImpl> &
  JSX.IntrinsicElements['group'] & {
  object?: THREE.Object3D | React.MutableRefObject<THREE.Object3D>;
  startPosition?: number[];
  initialEntityRotation: IVector3;
  entityId?: string;
  enabled?: boolean;
  axis?: string | null;
  mode?: string;
  translationSnap?: number | null;
  rotationSnap?: number | null;
  scaleSnap?: number | null;
  space?: string;
  size?: number;
  showX?: boolean;
  showY?: boolean;
  showZ?: boolean;
  camera?: THREE.Camera;
  onChange?: (e?: THREE.Event) => void;
  onMouseDown?: (e?: THREE.Event) => void;
  onMouseUp?: (e?: THREE.Event) => void;
  onObjectChange?: (e?: THREE.Event) => void;
  }

const TransformControlsReactWrapper = React.forwardRef<TransformControlsImpl, TransformControlsProps>(
  ({ startPosition, initialEntityRotation, entityId, onChange, onMouseDown, onMouseUp, onObjectChange, object, ...props }, ref) => {
    const transformOnlyPropNames = [
      'enabled',
      'axis',
      'mode',
      'translationSnap',
      'rotationSnap',
      'scaleSnap',
      'space',
      'size',
      'showX',
      'showY',
      'showZ',
    ]

    const { camera, ...rest } = props
    const transformProps = pick(rest, transformOnlyPropNames)
    const objectProps = omit(rest, transformOnlyPropNames)
    // @ts-expect-error new in @react-three/fiber@7.0.5
    const defaultControls = useThree((state) => state.controls) as ControlsProto
    const gl = useThree(({ gl }) => gl)
    const scene = useThree();
    const defaultCamera = useThree(({ camera }) => camera)
    const invalidate = useThree(({ invalidate }) => invalidate)
    const explCamera = camera || defaultCamera
    const [controls] = useState(() => new TransformControlsImpl(explCamera, gl.domElement,{initialEntityRotation}))
    const group = useRef<THREE.Group>();

    // Without this, manipulating the position in transform panel will move the entity but not the gizmo
    useLayoutEffect(() => {
      if (!object && startPosition) (group.current as any).position.set(startPosition[0], startPosition[1], startPosition[2]);
    }, [startPosition]);

    useLayoutEffect(() => {
      (group.current as any).rotation.set(initialEntityRotation[0], initialEntityRotation[1], initialEntityRotation[2]);
    }, [initialEntityRotation]);

    useLayoutEffect(() => {
      controls?.attach(group.current as Group);
      if (!object && startPosition) {
        (group.current as any).position.set(startPosition[0], startPosition[1], startPosition[2]);
      }
    }, [entityId]); 

    useEffect(() => {
      if (defaultControls) {
        const callback = (event: any) => (defaultControls.enabled = !event.value)
        controls.addEventListener('dragging-changed', callback)
        return () => controls.removeEventListener('dragging-changed', callback)
      }
    }, [controls, defaultControls])

    useEffect(() => {
      const callback = (e: THREE.Event) => {
        invalidate()
        if (onChange) onChange(e)
      }

      controls?.addEventListener?.('change', callback)
      if (onMouseDown) controls?.addEventListener?.('mouseDown', onMouseDown)
      if (onMouseUp) controls?.addEventListener?.('mouseUp', onMouseUp)
      if (onObjectChange) { controls?.addEventListener?.('objectChange', onObjectChange) }

      return () => {
        controls?.removeEventListener?.('change', callback)
        if (onMouseDown) controls?.removeEventListener?.('mouseDown', onMouseDown)
        if (onMouseUp) controls?.removeEventListener?.('mouseUp', onMouseUp)
        if (onObjectChange) controls?.removeEventListener?.('objectChange', onObjectChange)
      }
    }, [onChange, onMouseDown, onMouseUp, onObjectChange, controls, invalidate])

    return controls ? (
      <>
        <primitive ref={ref} dispose={undefined} object={controls} {...transformProps} />
        <group ref={group} {...objectProps} />
      </>
    ) : null
  }
)
export default TransformControlsReactWrapper