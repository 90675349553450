import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../../..';
import { IDesignerState } from '../../../../../typings';
import Spinner from '../../../../assets/spinner';
import { JUTE_VERSION } from '../../../../settings';
import { onAddToast, onSetPublishStatus_Ed_Doc, onSetPublishUploadStatus } from '../../../../store/actions';
import { zwClient } from '../../../../syncdoc';
import { ToastsData } from '../../../../utils/toasts-data';
import { IPublishTypes } from '../../../r3f/r3f-components/component-data-structure';
import { ActionButton } from '../../buttons';
import CSS from './PublishBtn.scss';


const PreviewButton: React.FunctionComponent = () => {
	const dispatch = useDispatch();
    const tracking = useSelector((state: IDesignerState) => state.editorReducer.editorDoc.tracking);
    const projectId = useSelector((state: IDesignerState) => state.userReducer.project.id);
    const publishUploadStatus = useSelector((state: IDesignerState) => state.userReducer.publishUploadStatus);

    const renderPublishButtonContent = (() => {
        switch (publishUploadStatus) {
            case 'loading':
                return (
                <div className={CSS.LoadingPlaceholder}>
                    <Spinner />
				</div>);
            default:
                return 'Publish'
        }
    })
   
	return (
        <ActionButton
            className={`${CSS.PublishBtn} ${(!tracking?.type ? `${CSS.AppearDisabled}` : '')}  ${publishUploadStatus === 'loading' ? CSS.Transparent : ''}`}
            onClick={async () => {
                if (!tracking?.type) {
                    dispatch(onAddToast(ToastsData.PublishNoTrkImgError));
                    return;
                }
                dispatch(onSetPublishUploadStatus('loading'));
                try {
                    const publishData = store.getState().contentReducer.contentDoc as any;
                    publishData.juteVersion = JUTE_VERSION;
                    await zwClient.publish(projectId, publishData);
                    const date = new Date();
                    dispatch(onSetPublishStatus_Ed_Doc({
                        status: IPublishTypes.published,
                        dateString: date.toString(),
                    }));
                    dispatch(onAddToast(ToastsData.PublishSuccess))
                } catch (err) { // TODO: send error to Sentry
                    dispatch(onAddToast(ToastsData.PublishError));
                }
                dispatch(onSetPublishUploadStatus(null))
            }}
        >
            {renderPublishButtonContent()}
        </ActionButton>
	);
};

export default memo(PreviewButton);
