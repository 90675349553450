import React, { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { IDesignerState } from '../../../../typings';
import * as THREE from 'three';
import uuid4 from 'uuid/v4';
import { bindActionCreators } from 'redux';
import Plane from '../Plane/Plane';
import DragElement from '../DragElement/DragElement';

import { ENTITY_MENU_WIDTH, HOTSPOT_SCALE } from '../../../utils'
import {
	onSetEntityMenuDragActive,
	IOnSetEntityMenuDragActive,
	onSetEntityMenuHotspotDragPosition,
	IOnSetEntityMenuHotspotDragPosition,
	IImageEntityDragInfo,
	IBtnEntityDragInfo,
	onAddImage_Global,
	IOnAddImage_Global,
	onSetDraggedEntityInfo,
	IOnSetDraggedEntityInfo,
	IUnionVideoEntityDragInfo,
	onAddVideo_Global,
	IOnAddVideo_Global,
	onAddButton_Global,
	IOnAddButton_Global,
	ITextEntityDragInfo,
	onAddText_Global,
	IOnAddText_Global,
	IModel3dEntityDragInfo,
	onAddModel3d_Global,
	IOnAdd3dModel_Global,
} from '../../../store/actions';

import { useThree, ThreeEvent } from '@react-three/fiber';
import { Object3D } from 'three';
import {
	IVector3,
	ITuple3,
	IComponentType,
	IFontTypes,
	ITextAlignment,
} from '../../r3f/r3f-components/component-data-structure';

interface IReduxProps {
	activeSceneId: string;
	entityMenuDragActive: boolean;
	entityMenuDragHotSpotPosition: IVector3;
	entityDragInfo:
		| IImageEntityDragInfo
		| ITextEntityDragInfo
		| IBtnEntityDragInfo
		| IUnionVideoEntityDragInfo
		| IModel3dEntityDragInfo
		| null;
	entityDrag2DPosition: IVector3;
	zoomLevel: number;
}

interface IDispatchProps {
	onAddButton_Global: IOnAddButton_Global;
	onAddImage_Global: IOnAddImage_Global;
	onAddVideo_Global: IOnAddVideo_Global;
	onAddText_Global: IOnAddText_Global;
	onAddModel3d_Global: IOnAdd3dModel_Global;
	onSetEntityMenuHotspotDragPosition: IOnSetEntityMenuHotspotDragPosition;
	onSetEntityMenuDragActive: IOnSetEntityMenuDragActive;
	onSetDraggedEntityInfo: IOnSetDraggedEntityInfo;
}

const EntityMenuDragHotspot: React.SFC<IReduxProps & IDispatchProps> = ({
	activeSceneId,
	entityDragInfo: entity,
	onAddButton_Global,
	onAddImage_Global,
	onAddVideo_Global,
	onAddText_Global,
	onAddModel3d_Global,
	entityMenuDragActive,
	onSetEntityMenuHotspotDragPosition,
	onSetEntityMenuDragActive,
	entityMenuDragHotSpotPosition,
	onSetDraggedEntityInfo,
	zoomLevel,
}) => {
	const hasRunRef = useRef(false);
	useEffect(() => {
	
		const pointerUpOnDocument = (e: ThreeEvent<PointerEvent>) => {
			if (e.target === canvasEl) return;
			removeDragSetup();
		}
		document.addEventListener('pointerup', pointerUpOnDocument);
		// Clean up after effect (equiv. to componentWillUnmount):
		return function cleanup() {
			document.removeEventListener('pointerup', pointerUpOnDocument);
		};
	}, []);

	const canvasEl = useMemo(() => {
		const containerDiv = document.getElementById('zapparCanvas');
		return containerDiv.getElementsByTagName('canvas')[0];
	}, []);


	const [hotspotObject, setHotspotObject] = useState<Object3D>();
	const [coords, setCoords] = useState<ITuple3>();
	const [pxCoords, setPxCoords] = useState<[number, number]>();
	const { raycaster, camera } = useThree();

	const domRect = useMemo(() => {
		const domRect = document.getElementById(entity?.id)?.getBoundingClientRect();
		let rect = {	
			width: domRect?.width,
			height: domRect?.height,
			left: domRect?.left,
			top: domRect?.top
		}

		if (entity.type !== IComponentType.Video) return rect;
		
		if (entity.aspectRatio > 1) { 
			const posterHeight = domRect.width / entity.aspectRatio;
			const heightDiff = domRect.height - posterHeight;
			rect.height = domRect.width / entity.aspectRatio;
			rect.top = domRect.top + heightDiff / 2;
		} else {
			const posterWidth = domRect.height * entity.aspectRatio;
			const widthDiff = domRect.width - posterWidth;
			rect.width = domRect.height * entity.aspectRatio;
			rect.left = domRect.left + widthDiff / 2;
		}
		return rect;		
	}, [entity?.id]);

	const convertPixelTo2DCoords = (
		xPxVal: number,
		yPxVal: number,
		object: Object3D,
		e?: ThreeEvent<PointerEvent>
	): ITuple3 => {
		let width = canvasEl.clientWidth;
		let height = canvasEl.clientHeight;
		let xCoord = (xPxVal / width) * 2 - 1;
		let yCoord = -(yPxVal / height) * 2 + 1;
		// update the picking ray with the camera and 2d xy position
		raycaster.setFromCamera(new THREE.Vector2(xCoord, yCoord), camera);
		const intersection = raycaster.intersectObject(object);
		if (!intersection) return;
		return [
			intersection[0].point.x,
			intersection[0].point.y,
			intersection[0].point.z,
		];
	};

	const onPointerMoveHandler = (e: ThreeEvent<PointerEvent>) => {
		if (!hotspotObject || e.button === 2) return;
		if (!hasRunRef.current) {
			setCoords(
				convertPixelTo2DCoords(domRect.left, domRect.top, hotspotObject)
			);
			setPxCoords([domRect.left, domRect.top]);
			(e.target as any).setPointerCapture(e.pointerId)
		}
		const left = e.clientX - entity.clickOffset[0];
		const top = e.clientY - entity.clickOffset[1] - 50;
		let newCoords = convertPixelTo2DCoords(left, top, hotspotObject)
		// if drag position has not changed, return
		if (coords && (coords[0] === newCoords[0] && coords[1] === newCoords[1] && coords[2] === newCoords[2])) return;

		setCoords(newCoords);
		setPxCoords([left, top]);
		// Use 2D positions returned from raycaster
		const [x, y, z] = convertPixelTo2DCoords(
			left + domRect.width / 2,
			top + domRect.height / 2,
			hotspotObject
		);
		onSetEntityMenuHotspotDragPosition([x, y, z]);
		hasRunRef.current = true;
	};

	const removeDragSetup = () => {
		onSetDraggedEntityInfo(null);
		onSetEntityMenuHotspotDragPosition(null);
		onSetEntityMenuDragActive(false);
	};


	const onPointerUp = (e: ThreeEvent<PointerEvent>) => {
		(e.target as any).releasePointerCapture(e.pointerId);
		if (pxCoords) {
			const menuDomRect = document
				.getElementById('entityMenu')
				.getBoundingClientRect();

			//dont add if dropped on entity menu
			if (
				pxCoords[1] >= menuDomRect.top &&
				pxCoords[1] <= menuDomRect.bottom &&
				pxCoords[0] <= menuDomRect.width - ENTITY_MENU_WIDTH
			) {
				removeDragSetup();
				return;
			}
		}
		const [x, y] = entityMenuDragHotSpotPosition || [0, 0, 0];

		if(hasRunRef.current) {
			switch (entity.type) {
				case IComponentType.Model3d: {
					const model = entity as IModel3dEntityDragInfo;
					onAddModel3d_Global({
						activeSceneId,
						position: [x, y, model.dimensions3d[2] / model.dimensions3d[1] ],
						entity: {
							id: uuid4(),
							zmlFileId: model.zmlFileId,
							model3dUrl: model.model3dUrl,
							thumbnailUrl: model.thumbnailUrl,
							dimensions: model.dimensions3d,
							aspectRatio: model.aspectRatio,
							name: model.title,
						}
					})
					break;
				}
				case IComponentType.Text: {
					const txt = entity as ITextEntityDragInfo;
					onAddText_Global({
						id: uuid4(),
						activeSceneId,
						title: txt.title,
						text: txt.text,
						fontSize: txt.fontSize,
						fontFamily: txt.fontFamily,
						fontRgba: txt.fontRgba,
						color: txt.color,
						scale: txt.scale,
						position: [x, y, 0],
						rotation: txt.rotation,
						textAlignment: ITextAlignment.left
					})
					break;
				}
				case IComponentType.Button: {
					const btn = entity as IBtnEntityDragInfo;				
					onAddButton_Global({
						id: uuid4(),
						activeSceneId,
						position: [x, y, 0],
						rotation: btn.rotation,
						scale: btn.scale,
						color: btn.color,
						fontRgba: btn.fontRgba,
						borderRgba: btn.borderRgba,
						borderRadius: btn.borderRadius,
						borderWidth: btn.borderWidth,
						text: btn.text,
						title: btn.title,
						fontSize: btn.fontSize,
						scalesInverted: [false, false],
						isLocked: false,
						aspectRatioLocked: true,
						fontFamily: btn.fontFamily,
						textAlignment: btn.textAlignment,
						textureUrl: btn.textureUrl,
						svgUrl: btn.svgUrl,
					});
					break
				};
				case IComponentType.Image: {
					const image = entity as IImageEntityDragInfo;
					onAddImage_Global({
						activeSceneId,
						position: [x, y, 0],
						entity: {
							id: uuid4(),
							zmlFileId: image.zmlFileId,
							url: image.url,
							aspectRatio: image.aspectRatio,
							name: image.title,
						},
					});
					break;
				}
				case IComponentType.Video: {
					const video = entity as IUnionVideoEntityDragInfo;
					onAddVideo_Global({
						activeSceneId,
						position: [x, y, 0],
						entity: {
							id: uuid4(),
							zmlFileId: video.zmlFileId,
							aspectRatio: video.aspectRatio,
							videoUrl: video.videoUrl || null,
							name: video.title,
							thumbnailUrl: video.thumbnailUrl,
							mp4Url: video.mp4Url,
							source: video.source,
							externalId: video.externalId || null,
							scalesInverted: [false, false],
							hasAlpha: video.hasAlpha
						},
					});
					break;
				};
				default:
					break;
			}
		}
		hasRunRef.current = false;
		removeDragSetup();
	}

	return (
		<>
			{coords && (
				<DragElement
					zoomLevel={zoomLevel}
					entityDragInfo={entity}
					position={[coords[0], coords[1], 0]}
				/>
			)}
			<Plane
				visible={false}
				onUpdate={self => {
					if (!hasRunRef.current) setHotspotObject(self);
				}}
				enabled={entityMenuDragActive}
				scale={HOTSPOT_SCALE}
				position={[0, 0, 0.02]}
				rotation={[0, 0, 0]}
				pointerMoveHandler={onPointerMoveHandler}
				pointerUpHandler={onPointerUp}
			/>
		</>
	);
};

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	return {
		activeSceneId: state.userReducer.activeSceneId,
		entityMenuDragActive: state.userReducer.entityMenuDragActive,
		entityMenuDragHotSpotPosition: state.userReducer.entityDragHotspotPosition,
		entityDragInfo: state.userReducer.entityDragInfo,
		entityDrag2DPosition: state.userReducer.entityDrag2DPosition,
		zoomLevel: state.userReducer.zoomLevel,
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onAddButton_Global,
			onAddImage_Global,
			onAddVideo_Global,
			onAddText_Global,
			onAddModel3d_Global,
			onSetEntityMenuDragActive,
			onSetEntityMenuHotspotDragPosition,
			onSetDraggedEntityInfo,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EntityMenuDragHotspot);
