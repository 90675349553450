// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DragContainer--1xlY1{display:grid;column-gap:8px;row-gap:16px;width:100%}.DragContainer--1xlY1 .DummySlide--2iXSE{background-color:#f9f9f9}", ""]);
// Exports
exports.locals = {
	"DragContainer": "DragContainer--1xlY1",
	"DummySlide": "DummySlide--2iXSE"
};
module.exports = exports;
