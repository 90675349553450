// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".App--3Jj_y{height:100vh;display:flex}.SocketErrorContainer--3diwA{height:100vh;display:flex;align-items:center;justify-content:center}.LoadingDiv--3St5y{position:absolute;z-index:103;width:calc(100% - 272px);height:100%;top:0;left:0;display:flex;align-items:center;justify-content:center}.LoadingDiv--3St5y svg{height:100px;width:100px}.Toggle3dButton--3iXtD.Active--LRPFc{background-color:#add8e6}.Toggle3dButton--3iXtD:disabled{opacity:.3}", ""]);
// Exports
exports.locals = {
	"App": "App--3Jj_y",
	"SocketErrorContainer": "SocketErrorContainer--3diwA",
	"LoadingDiv": "LoadingDiv--3St5y",
	"Toggle3dButton": "Toggle3dButton--3iXtD",
	"Active": "Active--LRPFc"
};
module.exports = exports;
