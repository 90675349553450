// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PageFlexContainer--2Pqu4{display:flex;flex-direction:column;align-items:flex-start;padding:5px}.LinkedPageInputField--1PEfw{margin-bottom:24px}.Description--oYq7W{color:#5c5c5c;font-size:14px;margin:24px 0 8px 0;align-self:flex-start;width:100%}.Instruction--3uwjR{margin-bottom:24px}.Error--HOuUm{color:red}.HelpDiv--H2-Vn{position:relative;width:fit-content}.RadioBtnRow--1YSkX{display:flex;justify-content:space-between;align-items:center;width:100%;height:30px}", ""]);
// Exports
exports.locals = {
	"PageFlexContainer": "PageFlexContainer--2Pqu4",
	"LinkedPageInputField": "LinkedPageInputField--1PEfw",
	"Description": "Description--oYq7W",
	"Instruction": "Instruction--3uwjR",
	"Error": "Error--HOuUm",
	"HelpDiv": "HelpDiv--H2-Vn",
	"RadioBtnRow": "RadioBtnRow--1YSkX"
};
module.exports = exports;
