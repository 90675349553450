import { EventEmitter } from 'events';
import * as Automerge from 'automerge';
import { Socket } from 'socket.io-client';
import { IEventType } from '../components/r3f/r3f-components/component-data-structure';

const MINIMUM_GAP_BETWEEN_UPDATES_MS = 30;

export class SyncDocClient<T> extends EventEmitter {
	public static create<T>(socket: Socket, docName: string, previousDoc?: Automerge.Doc<T>): SyncDocClient<T> {
		return new SyncDocClient<T>(docName, socket, previousDoc);
	}

	private _connection: Automerge.Connection<T>;
	private _docSet = new Automerge.DocSet<T>();
	private _doc: Automerge.Doc<T>;
	private _previousDoc: Automerge.Doc<T> | undefined;
	private _hadDoc: Promise<void>;
	private _hadDocResolve: () => void | undefined;
	private _pendingIncomingMessages: Automerge.Message[] = [];

	private constructor(private _docName: string, private _socket: Socket, previousDoc?: Automerge.Doc<T>) {
		super();
		this._previousDoc = previousDoc
		this._connection = new Automerge.Connection<T>(this._docSet, msg => {
			this._socket.emit(`${IEventType.automerge_update}-${this._docName}`, msg);
		});

		this._connection.open();

		this._hadDoc = new Promise(resolve => {
			this._hadDocResolve = resolve;
		});

		// Functions used as event callbacks must be bound to this
		this._onUpdateFromServer = this._onUpdateFromServer.bind(this);
		this._onDocUpdate = this._onDocUpdate.bind(this);

		this._docSet.registerHandler(this._onDocUpdate);
		this._socket.on(
			`${IEventType.automerge_update}-${this._docName}`,
			this._onUpdateFromServer
		);
	}

	public waitForDoc(): Promise<void> {
		return this._hadDoc;
	}

	public disconnect() {
		this._socket.off(
			`${IEventType.automerge_update}-${this._docName}`,
			this._onUpdateFromServer
		);
		this._docSet.unregisterHandler(this._onDocUpdate);
	}

	private _onDocUpdate(_: string, h: Automerge.Doc<T>) {
		this._doc = h;
		if (this._hadDocResolve) {
			if (typeof this._previousDoc !== 'undefined') {
				this._doc = Automerge.merge(this._doc, this._previousDoc)
				this._postUpdate()
			}
			this._hadDocResolve();
			this._hadDocResolve = undefined;
		}
		this.emit('change');
	}

	private _onUpdateFromServer(data: Automerge.Message) {
		if (this._updateTimeout !== undefined) {
			this._pendingIncomingMessages.push(data);
			return;
		}
		this._connection.receiveMsg(data);
	}

	private _processPendingComms() {
		let msgs = this._pendingIncomingMessages;
		this._pendingIncomingMessages = [];

		for (let msg of msgs) {
			this._connection.receiveMsg(msg);
		}
	}

	public getDoc(): Automerge.Doc<T> {
		return this._doc;
	}

	public change(
		fn: (d: Automerge.Proxy<Automerge.Doc<T>>) => void
	): Automerge.Doc<T> {
		this._doc = Automerge.change(this._doc, mut => {
			fn(mut);
		});
		this._postUpdate();
		return this._doc;
	}

	private _updateTimeout: number | undefined;
	private _lastTimeoutTime = 0;
	private _postUpdate() {
		if (this._updateTimeout === undefined) {
			let currentTime = Date.now();
			let timeout = Math.max(
				MINIMUM_GAP_BETWEEN_UPDATES_MS - (currentTime - this._lastTimeoutTime),
				1
			);
			this._updateTimeout = window.setTimeout(() => {
				this._lastTimeoutTime = Date.now();
				this._docSet.setDoc('doc', this._doc);
				this._updateTimeout = undefined;
				this._processPendingComms();
			}, timeout);
		}
	}

	public canUndo(): boolean {
		return Automerge?.canUndo?.(this._doc) || false;
	}

	public canRedo(): boolean {
		return Automerge?.canRedo?.(this._doc) || false;
	}

	public undo(): Automerge.Doc<T> {
		if (!Automerge) return;
		this._doc = Automerge.undo(this._doc);
		this._postUpdate();
		return this._doc;
	}

	public redo() {
		if (!Automerge) return;
		this._doc = Automerge.redo(this._doc);
		this._postUpdate();
		return this._doc;
	}
}
