import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { IDesignerState } from '../../../../../../../../typings';
import { onSetMultipleEntityProps_Ed_Doc, onSetScales_Cn_Doc } from '../../../../../../../store/actions';
import { getContentEntityDict, getEditorScalesInvertedDict } from '../../../../../../../store/selectors';
import { IMultipleEntityProps_Ed_Doc } from '../../../../../../../store/actions';
import { ISpatialComponentUnion, IComponentType } from '../../../../../../r3f/r3f-components/component-data-structure';
import { MirrorIcon } from '../../../../../../../assets/icons';
import CSS from './TransformsMirror.scss';

interface IParentProps {
  entityIsLocked: boolean;
}

const TransformsMirror = ({
  entityIsLocked,
}: IParentProps) => {
  // Redux
  const dispatch = useDispatch();
  const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
  const entityContentDict = useSelector((state: IDesignerState) => getContentEntityDict(state));
  const editorScalesInvertedDict = useSelector((state: IDesignerState) => getEditorScalesInvertedDict(state));
  // console.log({ editorScalesInvertedDict });
  
  // Derived
  const id = selectedEntityIds[0];
  const entity = entityContentDict[id] as ISpatialComponentUnion;
  const disabled = (entity.type === IComponentType.Button || entity.type === IComponentType.Text);

  // Number mirrored behaviour - if ALL in X or Y are mirrored then set as actively mirrored
  let numberMirrored_X = 0; 
  let numberMirrored_Y = 0; 
  for (const id in editorScalesInvertedDict) {
    if (selectedEntityIds.includes(id)) {
      if (editorScalesInvertedDict[id][0]) numberMirrored_X++;
      if (editorScalesInvertedDict[id][1]) numberMirrored_Y++;
    }
  }
  // console.log({numberMirrored_X});
  // console.log({numberMirrored_Y});
  
  const allSelectedEntitiesHorizontallyMirrored = numberMirrored_X === selectedEntityIds.length;
  const allSelectedEntitiesVerticallyMirrored = numberMirrored_Y === selectedEntityIds.length;

  // Helpers
  const horizontalMirrorClick = () => {
    let multipleEntityProps_Ed_Doc: IMultipleEntityProps_Ed_Doc[] = [];
    let scaleDict: { [id: string]: number[] } = {};
    for (let i = 0; i < selectedEntityIds.length; i++) {
      const id = selectedEntityIds[i];
      const entity = entityContentDict[id] as ISpatialComponentUnion;
      const scaleInv = editorScalesInvertedDict[id];
      if (numberMirrored_X !== selectedEntityIds.length && scaleInv?.[0])
        continue;
      let newScale = [...entity.scale];
      newScale[0] = newScale[0] * -1;
      scaleDict[id] = newScale;
      multipleEntityProps_Ed_Doc.push({
        id,
        scalesInverted: [!scaleInv?.[0], !!scaleInv?.[1]],
      });
    }
    dispatch(onSetMultipleEntityProps_Ed_Doc(multipleEntityProps_Ed_Doc));
    dispatch(onSetScales_Cn_Doc(scaleDict));
  }

  const verticalMirrorClick = () => {
    let multipleEntityProps_Ed_Doc: IMultipleEntityProps_Ed_Doc[] = [];
    let scaleDict: { [id: string]: number[] } = {};
    for (let i = 0; i < selectedEntityIds.length; i++) {
      const id = selectedEntityIds[i];
      const entity = entityContentDict[id] as ISpatialComponentUnion;
      const scaleInv = editorScalesInvertedDict[id];
      if (numberMirrored_Y !== selectedEntityIds.length && scaleInv?.[1])
        continue;
      let newScale = [...entity.scale];
      newScale[1] = newScale[1] * -1;
      scaleDict[id] = newScale;
      multipleEntityProps_Ed_Doc.push({
        id,
        scalesInverted: [scaleInv?.[0], !scaleInv?.[1]],
      });
    }
    dispatch(onSetMultipleEntityProps_Ed_Doc(multipleEntityProps_Ed_Doc));
    dispatch(onSetScales_Cn_Doc(scaleDict));
  }

  // Content
  const mirrorEntity =
    <>
      <button
        key={'mirror-btn-1'}
        disabled={entityIsLocked || disabled}
        onClick={horizontalMirrorClick}>
        <MirrorIcon
          title={"Horizontal mirror"}
          hexFill={ allSelectedEntitiesHorizontallyMirrored ? '#4a90e2' : null }/>
      </button>
      <button
        key={'mirror-btn-2'}
        disabled={entityIsLocked || disabled}
        onClick={verticalMirrorClick}>
        <MirrorIcon
          title={"Vertical mirror"}
          className={CSS.RotatedMirror}
          hexFill={ allSelectedEntitiesVerticallyMirrored ? '#4a90e2' : null } />
      </button>
	  </>;

  return (
    <div className={CSS.InputRow}>
      <div className={CSS.InputRowTitle}>
        <span>Mirror</span>
      </div>
      {mirrorEntity}
    </div>
  )
}

export default React.memo(TransformsMirror);