import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const crossIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
	strokeWidth, 
	height = "10px", 
	width = "10px", 
	onPointerEnter,
	onPointerLeave
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width={width}
			height={height}
			onPointerEnter={onPointerEnter}
			onPointerLeave={onPointerLeave}
			viewBox={viewBox ? viewBox.join(' ') : '0 0 10 10'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<path d="M9 9L1 1M1 9L9 1L1 9Z" stroke={hexFill} />
		</svg>
	);
};

export default crossIcon;
