import React, { FunctionComponent, Suspense, useMemo } from 'react';
import { ITuple3, ITuple4 } from '../../component-data-structure';
import PlaceholderEntity from './PlaceholderEntity';
import Spinner from '../Spinner';

interface IParentProps {
	scale: ITuple3;
    rotation: ITuple3;
    position: ITuple3;
    borderWidth: number;
    borderRadius: number;
    renderOrder: number;
    children?: JSX.Element | JSX.Element[];
}

const SuspenseBoundary: FunctionComponent<IParentProps> = ({ 
	scale,
    rotation,
    position,
    borderWidth,
    borderRadius,
    renderOrder,
    children
}) => {

    const mPlc = useMemo(() => ({
        scale,
        rotation,
        position,
        fillRgba: [227, 227, 227, 1] as ITuple4,
        borderRgba: [181, 181, 181, 1] as ITuple4,
    }), []) 


    const spinnerScale = useMemo(() => {
        const isPortrait = scale[1] > scale[0];
		let spScale = [scale[1] * 0.3, scale[1] * 0.3, 0]
		if (isPortrait) spScale = [scale[0] * 0.3, scale[0] * 0.3, 0]
		return spScale as ITuple3;
    }, [scale])

    return (
        <Suspense fallback={
            <PlaceholderEntity
                scale={mPlc.scale}
                rotation={mPlc.rotation}
                position={mPlc.position}
                fillRgba={mPlc.fillRgba }
                borderRgba={mPlc.borderRgba}
                borderWidth={borderWidth}
                borderRadius={borderRadius}
                renderOrder={renderOrder + 1}
                depthWrite={false}
            >
                <Spinner 
                    opacity={1}
                    radiansPerFrame={0.06}
                    color={'#d9d9d9'}
                    scale={spinnerScale}
                    renderOrder={renderOrder + 2}
                    depthWrite={false}
                />
            </PlaceholderEntity>
        }>
           {children}
        </Suspense>
        
    )

};

export default SuspenseBoundary;