// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../Transforms.scss");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".InputRow--1bROy{margin-bottom:0px}.InputRowTitle--6kOLL{}.RotatedMirror--iCT3c{transform:rotate(90deg)}button:disabled{opacity:.2;cursor:default}.MirrorRow--K_Qns{padding-top:8px;margin-bottom:0}.MirrorRow--K_Qns button svg{width:16px !important}.MirrorRow--K_Qns button:nth-of-type(2) svg{transform:rotate(90deg);width:14px !important}", ""]);
// Exports
exports.locals = {
	"InputRow": "InputRow--1bROy " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["InputRow"] + "",
	"InputRowTitle": "InputRowTitle--6kOLL " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["InputRowTitle"] + "",
	"RotatedMirror": "RotatedMirror--iCT3c",
	"MirrorRow": "MirrorRow--K_Qns"
};
module.exports = exports;
