// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OuterColorContainer--3OvdX{display:flex;align-items:center;justify-content:center}.OuterColorContainer--3OvdX.Active--1etw3 .ColorContainer--9OmZu{border:2px solid #4a90e2}.OuterColorContainer--3OvdX .ColorContainer--9OmZu{position:relative;display:inline-block;overflow:hidden}.OuterColorContainer--3OvdX .ColorContainer--9OmZu .ColorContainerBackground--2TOEW{width:100%;height:100%}.OuterColorContainer--3OvdX .ColorContainer--9OmZu .ColorContainerBackground--2TOEW .ColorContent--1fdSb{height:100%;width:100%}.RoundShape--3Virw.OuterColorContainer--3OvdX{height:32px;width:32px;border-radius:100%}.RoundShape--3Virw.OuterColorContainer--3OvdX .ColorContainer--9OmZu{width:24px;height:24px;border-radius:100%}.RoundShape--3Virw.OuterColorContainer--3OvdX .ColorContainer--9OmZu .ColorContainerBackground--2TOEW{background:url(\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==\")}.RoundShape--3Virw.OuterColorContainer--3OvdX .ColorContainer--9OmZu .ColorContainerShadowMask--1XzRb{position:absolute;top:0px;left:0px;width:100%;height:100%;border-radius:100%;pointer-events:none;box-shadow:0 0 0 1px rgba(0,0,0,.08) inset}.RectangleShape--1jkmt.OuterColorContainer--3OvdX{height:32px;width:100px;border-radius:4px}.RectangleShape--1jkmt.OuterColorContainer--3OvdX .ColorContainer--9OmZu{width:100px;height:24px;border-radius:4px;background:url(\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==\")}.RectangleShape--1jkmt.OuterColorContainer--3OvdX .ColorContainer--9OmZu .ColorContent--1fdSb{border:1px solid rgba(0,0,0,.08);border-radius:4px}", ""]);
// Exports
exports.locals = {
	"OuterColorContainer": "OuterColorContainer--3OvdX",
	"Active": "Active--1etw3",
	"ColorContainer": "ColorContainer--9OmZu",
	"ColorContainerBackground": "ColorContainerBackground--2TOEW",
	"ColorContent": "ColorContent--1fdSb",
	"RoundShape": "RoundShape--3Virw",
	"ColorContainerShadowMask": "ColorContainerShadowMask--1XzRb",
	"RectangleShape": "RectangleShape--1jkmt"
};
module.exports = exports;
