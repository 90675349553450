import React, { FunctionComponent, memo, useEffect, useRef, useState } from 'react';
import {
	ILoadedImageInfo,
	ILoadedModel3dInfo,
	ILoadedUserVideoInfo,
} from '../../../../../../typings';
import { ILoadedEntityInfo } from '../DragContainer';
import {
	IBtnEntityDragInfo,
	IImageEntityDragInfo,
	IModel3dEntityDragInfo,
	IUnionVideoEntityDragInfo,
} from '../../../../../store/actions';
import { IComponentType } from '../../../../r3f/r3f-components/component-data-structure';

import CSS from './DragItem.scss';
import { useIsDoubleClick } from '../../../../../utils';

interface IParentProps {
	showTitle: boolean;
	style?: React.CSSProperties;
	className?: string;
	columns: number;
	entityType: IComponentType;
	lightHoverCover: boolean;
	loadedEntityInfo: ILoadedEntityInfo;
	onDoubleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
	onPointerDown?: (e: React.PointerEvent<HTMLDivElement>) => any;
	onPointerUp: (e: React.PointerEvent<HTMLDivElement>) => any;
	dragedElInfo:
		| IBtnEntityDragInfo
		| IImageEntityDragInfo
		| IModel3dEntityDragInfo
		| IUnionVideoEntityDragInfo;
}

const DragItem: FunctionComponent<IParentProps> = ({
	className = '',
	onDoubleClick,
	onPointerDown,
	onPointerUp,
	entityType,
	columns,
	loadedEntityInfo: loadElInfo,
	lightHoverCover: lightCov,
	dragedElInfo,
	showTitle
}) => {
	const divRef = useRef<HTMLDivElement>();
	const videoRef = useRef<HTMLVideoElement>();
	const isDoubleClickFn = useIsDoubleClick();

	const [isHovered, setIsHovered] = useState<boolean>(false);
	let classes = [CSS.DragItem, className];
	if (dragedElInfo?.id === loadElInfo.id) classes.push(CSS.Active);

	const onPointerEnter = () => {
		videoRef.current.play().catch(() => null);
	};
	const onPointerLeave = () => { videoRef.current.pause() };

	const renderPreviewContent = () => {
		switch (entityType) {
			case IComponentType.Model3d: {
				const { thumbnailUrl, id, aspectRatio } = loadElInfo as ILoadedModel3dInfo;
				let imgStyle: any = {};
				if (aspectRatio > 1) imgStyle.width = '100%';
				else imgStyle.height = '100%';
				return (
					<img
						id={id}
						style={imgStyle}
						src={thumbnailUrl}
						onDoubleClick={() => console.log('double click')}
					/>
				)
			}
			case IComponentType.Image: {
				const img = loadElInfo as ILoadedImageInfo;
				let imgStyle: any = {};
				if (img.aspectRatio > 1) imgStyle.width = '100%';
				else imgStyle.height = '100%';
				return (
					<img
						id={loadElInfo.id}
						style={imgStyle}
						src={img.url.split('_')[0]}
					/>
				);
			}
			case IComponentType.Video:
				const { thumbnailUrl, mp4Url } = loadElInfo as ILoadedUserVideoInfo;
				return (
					<video
						id={loadElInfo.id}
						ref={videoRef}
						width={'100%'}
						height={192 / columns}
						style={{ position: 'absolute', top: '0px', left: '0px' }}
						src={mp4Url}
						poster={thumbnailUrl}
						preload={'none'}
						onPointerEnter={onPointerEnter}
						onPointerLeave={onPointerLeave}
						muted
						loop
					/>
				);
			default:
				break;
		}
	};

	return (
		<div
			ref={divRef}
			title={showTitle ? loadElInfo.name : undefined}
			style={{ height: `${192 / columns}px` }}
			className={classes.join(' ')}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			onPointerDown={(e) => {
				if (isDoubleClickFn()) onDoubleClick(e)
				else onPointerDown(e)
			}}
		>
			{isHovered && (
				<div className={`${CSS.HoverCover} ${lightCov ? CSS.LightHoverCover : ''}`} >
					<span className={CSS.NameSpan}>{loadElInfo.name}</span>
				</div>
			)}
			{renderPreviewContent()}
		</div>
	);
};

export default memo(DragItem);
