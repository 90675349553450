import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const cubeIcon: React.FunctionComponent<IIconParentProps> = ({
	hexFill: fill,
	viewBox,
	className,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="18px"
			height="20px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 18 20`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
    	<title>3D Model</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-11.000000, -10.000000)" fill={hexFill} fillRule="nonzero">
				<path d="M19.7349205,10.0645733 C19.8962208,9.97858813 20.0933737,9.97846845 20.2547909,10.0642578 L20.2547909,10.0642578 L28.6843459,14.5421262 C28.7782086,14.5806432 28.8610673,14.6448759 28.9194998,14.7319371 C28.9732168,14.8119724 28.9992413,14.9007969 28.9999842,14.9887085 L29.0000006,15.0044778 L29.0000006,24.9996312 C29.0000006,25.180255 28.8968479,25.3468365 28.7301604,25.4353968 L28.7301604,25.4353968 L20.2595722,29.9357779 C20.098401,30.0214074 19.9016002,30.0214074 19.740429,29.9357779 L19.740429,29.9357779 L11.2698407,25.4353968 C11.1029077,25.3467061 10.9997164,25.1797774 11.0000006,24.9988877 L11.0000006,24.9988877 L11.0133407,15.1026906 C10.983263,14.9800173 11.0033442,14.8466794 11.0807391,14.7315826 C11.1508973,14.6272477 11.2561433,14.5557613 11.3733343,14.522082 Z M12.014,15.856 L12.0000006,24.7462611 L19.5,28.734 L19.502,20.215 L12.014,15.856 Z M28,15.847 L20.502,20.203 L20.5,28.734 L28.0000006,24.7462611 L28,15.847 Z M19.9962675,11 L12.52,14.988 L19.9921254,19.3366682 L27.484,14.984 L19.9962675,11 Z" ></path>
			</g>
		</g>
	</svg>
	);
};

export default cubeIcon;