// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TabContainer--1pKdL{flex-grow:1;height:100%;display:flex;align-items:center;justify-content:center;cursor:pointer;width:70%}.TabInner--2KJN9{display:flex;align-items:center;justify-content:center;height:100%;color:#a0a0a0;font-weight:400;letter-spacing:.57px;padding-bottom:2px;font-size:16px}.TabInner--2KJN9.Active--1SHqC{border-bottom:2px solid #4a90e2;color:#4a90e2}.TabInner--2KJN9>span{color:inherit}", ""]);
// Exports
exports.locals = {
	"TabContainer": "TabContainer--1pKdL",
	"TabInner": "TabInner--2KJN9",
	"Active": "Active--1SHqC"
};
module.exports = exports;
