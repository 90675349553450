import { ITuple3 } from '../component-data-structure';
import * as THREE from 'three';
import { ThreeEvent } from '@react-three/fiber';
import { Intersection } from 'three';

export interface IUserData {
	contentId: string;
	renderOrder?: number;
	enabled?: boolean;
}

export const toRadians = (degrees: number): number => (degrees * Math.PI) / 180;

export const createRoundedRectShape = (
	scale: ITuple3,
	r: number,
	holePath: THREE.Path
) => {
	const x = 0;
	const y = 0;
	let width = Math.abs(scale[0]);
	let height = Math.abs(scale[1]);
	let radius = r * (height / 2);

	const aspectRatio = width / height;
	if (aspectRatio < 1) radius *= aspectRatio;

	const radiusPerc = ((radius || 0) / (height / 2)) * 100;
	const roundedRectShape = new THREE.Shape();

	if (radiusPerc > 0 && (radiusPerc < 100 || aspectRatio < 1)) {
		roundedRectShape
			.moveTo(x, y + radius)
			.lineTo(x, y + height - radius)
			.quadraticCurveTo(x, y + height, x + radius, y + height)
			.lineTo(x + width - radius, y + height)
			.quadraticCurveTo(x + width, y + height, x + width, y + height - radius)
			.lineTo(x + width, y + radius)
			.quadraticCurveTo(x + width, y, x + width - radius, y)
			.lineTo(x + radius, y)
			.quadraticCurveTo(x, y, x, y + radius);
	} else if (radiusPerc === 0) {
		roundedRectShape
			.moveTo(x, y)
			.lineTo(x, y + height)
			.lineTo(x + width, y + height)
			.lineTo(x + width, y)
			.lineTo(x, y);
	} else if (radiusPerc === 100) {
		const rad = height / 2;
		roundedRectShape
			.moveTo(x + rad, y)
			.absarc(x + rad, y + rad, rad, (Math.PI * 3) / 2, Math.PI / 2, true)
			.lineTo(width - rad, height)
			.absarc(width - rad, rad, rad, Math.PI / 2, (Math.PI * 3) / 2, true)
			.lineTo(x + rad, y);
	}

	if (!!holePath) {
		if (holePath.curves.length) roundedRectShape.holes.push(holePath);
	}

	return roundedRectShape;
};

export const createRoundedRectInnerForm = <T extends THREE.Path | THREE.Shape>(
	form: T,
	scale: ITuple3,
	r: number,
	bw: number,
	isShape?: boolean
) => {
	let x1 = isShape ? 0 : bw;
	let y1 = isShape ? 0 : bw;
	let width = Math.abs(scale[0]);
	let height = Math.abs(scale[1]);
	let radius = r * (height / 2);
	if (width / height < 1) radius *= width / height;
	let innerRadius = ((height - bw * 2) / height) * radius;
	if (width / height < 1) innerRadius = ((width - bw * 2) / width) * radius;
	const radiusPerc = ((radius || 0) / (height / 2)) * 100;
	const innerRadiusPerc = ((innerRadius || 0) / (height / 2)) * 100;
	width = width - bw * 2;
	height = height - bw * 2;

	if (radiusPerc === 100 && !(width / height < 1)) {
		let rad = height / 2;
		if (width / height < 1) rad = width / 2;
		form
			.moveTo(x1 + rad, y1)
			.absarc(x1 + rad, y1 + rad, rad, (Math.PI * 3) / 2, Math.PI / 2, true)
			.lineTo(width - rad, height + y1)
			.absarc(
				width - rad + x1,
				rad + y1,
				rad,
				Math.PI / 2,
				(Math.PI * 3) / 2,
				true
			)
			.lineTo(x1 + rad, y1);
	} else if (
		innerRadiusPerc > 0 &&
		(innerRadiusPerc < 100 || width / height < 1)
	) {
		form
			.moveTo(x1, y1 + innerRadius)
			.lineTo(x1, y1 + height - innerRadius)
			.quadraticCurveTo(x1, y1 + height, x1 + innerRadius, y1 + height)
			.lineTo(x1 + width - innerRadius, y1 + height)
			.quadraticCurveTo(
				x1 + width,
				y1 + height,
				x1 + width,
				y1 + height - innerRadius
			)
			.lineTo(x1 + width, y1 + innerRadius)
			.quadraticCurveTo(x1 + width, y1, x1 + width - innerRadius, y1)
			.lineTo(x1 + innerRadius, y1)
			.quadraticCurveTo(x1, y1, x1, y1 + innerRadius);
	} else if (innerRadiusPerc === 0) {
		form
			.moveTo(x1, y1)
			.lineTo(x1, y1 + height)
			.lineTo(x1 + width, y1 + height)
			.lineTo(x1 + width, y1)
			.lineTo(x1, y1);
	}
	return form;
};



export const isHighestRenderOrder = (e: ThreeEvent<PointerEvent>, renderOrder?: number, sceneChildrenIds?: any, rayintersects?: Intersection[]): boolean => {
	let i = 0;
	let highestRenderOrder = 0;
	let userData: IUserData | null = null;
	let contentIds: string[] = [];
	let intersects = rayintersects || e.intersections;
	const userDataArray: IUserData[] = [];
	//console.log('INTERSECTIONS: ', intersects);
	for (let i = 0; i < intersects.length; i++) {
		userData = findParentUserData(intersects[i]);
		if (userData
			&& userData.contentId !== 'TransformControlsPlane'
			&& !contentIds.includes(userData.contentId)
		) {
			userDataArray.push(userData);
			contentIds.push(userData.contentId);
		}
	}
	// console.log('user data: ', userDataArray);
	const hasEnabledUserData = (userData: IUserData) => !!userData && !(typeof (userData.enabled) !== 'undefined' && !userData.enabled);
	// console.log(hasEnabledUserData);
	// console.log(hasEnabledUserData(userData));

	const filtUserData = (Array.isArray(sceneChildrenIds) ? 
		userDataArray.filter((userData) =>  hasEnabledUserData(userData) && sceneChildrenIds.includes(userData.contentId)) : 
		userDataArray.filter((userData) =>  hasEnabledUserData(userData)) as {contentId: string, renderOrder: number}[]);

	// console.log('filter user data: ', filtUserData)
	while (i < filtUserData.length) {
		const { renderOrder } = filtUserData[i];
		const ro = renderOrder || 0;
		if (ro > highestRenderOrder) highestRenderOrder = ro;
		i++;
	}
	// console.log('highest render order: ', highestRenderOrder)
	// console.log('is highest render order: ', highestRenderOrder === renderOrder)
	return highestRenderOrder === renderOrder;
}

const findParentUserData = (intersection: Intersection) => {
	const { object } = intersection;
	const hasUserData = (object: THREE.Object3D) => !!Object.keys(object.userData).length && "renderOrder" in object.userData && "contentId" in object.userData;
	// console.log(object);
	if (hasUserData(object)) {
		return object.userData as IUserData;
	}
	let {parent: ancestor} = object;
    while (ancestor) {
        if (hasUserData(ancestor)) return ancestor.userData as IUserData;
        ancestor = ancestor.parent;
    }
    return null;
}

export default function mergeRefs<T = any>(
	refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
  ): React.RefCallback<T> {
	return (value) => {
		refs.forEach((ref) => {
			if (typeof ref === "function") {
			ref(value);
			} else if (ref != null) {
			(ref as React.MutableRefObject<T | null>).current = value;
			}
		});
	};
}