// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VolumeControl--2L5N9{display:flex;align-items:center}.VolumeControl--2L5N9>input{width:84%}.VolumeSlider--nTubi{width:100%;display:flex;justify-content:center;align-items:center}", ""]);
// Exports
exports.locals = {
	"VolumeControl": "VolumeControl--2L5N9",
	"VolumeSlider": "VolumeSlider--nTubi"
};
module.exports = exports;
