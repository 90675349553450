import React, { useState, useEffect, useRef, FunctionComponent, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { IDesignerState } from '../../../../../typings';
import EntityMenuButtonRow from './ButtonRow/ButtonRow';
import ButtonSelection from './SubMenu/ButtonSelection/ButtonSelection';
import ImageSelection from './SubMenu/ImageSelection/ImageSelection';
import Model3dSelection from './SubMenu/Model3dSelection/Model3dSelection';
import VideoContent from './SubMenu/VideoSelection/VideoSelection';
import TextContent from './SubMenu/TextContent/TextContent';
import { IEntityDrawerTypes } from '../../../r3f/r3f-components/component-data-structure';
import { onAddToast } from '../../../../store/actions';
import { ToastsData } from '../../../../utils/toasts-data'; 

import CSS from './EntityMenu.scss';

const Entitymenu: FunctionComponent = () => {

	const dispatch = useDispatch();
	const entityDragIsActive = useSelector((state: IDesignerState) => state.userReducer.entityMenuDragActive);
	const socketHasConnected = useSelector((state: IDesignerState) => state.userReducer.socketHasConnected);

	const menuEl = useRef<HTMLDivElement>(null);
	const [openSubMenu, setOpenSubMenu] = useState<IEntityDrawerTypes>(
		IEntityDrawerTypes.none
	);

	useEffect(() => {
		document.addEventListener('pointerdown', handlePointerDownOutside);
	}, []);

	const handlePointerDownOutside = (event: any) => {
		const modalParent = document.getElementById('zmdl-background');
		const dialog = document.getElementsByClassName('z-dialog')[0];
		if (
			menuEl.current &&
			!menuEl.current.contains(event.target) &&
			!modalParent &&
			menuEl.current.childElementCount !== 1 && // has scene carousel div in addition to button row div
			(!dialog || !dialog?.contains(event.target))
		) {
			setOpenSubMenu(IEntityDrawerTypes.none);
		}
	};

	const renderSubmenuContent = useCallback(() => {
		switch (openSubMenu) {
			case IEntityDrawerTypes.button:
				return <ButtonSelection onClose={() => setOpenSubMenu(IEntityDrawerTypes.none)}/>;
			case IEntityDrawerTypes.text:
				return <TextContent onClose={() => setOpenSubMenu(IEntityDrawerTypes.none)}/>;
			case IEntityDrawerTypes.image:
				return <ImageSelection onClose={() => setOpenSubMenu(IEntityDrawerTypes.none)}/>;
			case IEntityDrawerTypes.model3d:
				return <Model3dSelection onClose={() => setOpenSubMenu(IEntityDrawerTypes.none)}/>;
			case IEntityDrawerTypes.video:
				return <VideoContent onClose={() => setOpenSubMenu(IEntityDrawerTypes.none)}/>;
			default:
				return null;
		}
	}, [openSubMenu])

	const subMenuIsOpen = openSubMenu !== IEntityDrawerTypes.none;

	return (
		<div
			id={'entityMenu'}
			style={{
				pointerEvents: entityDragIsActive ? 'none' : 'auto',
			}}
			className={`${CSS.EntityMenu} ${subMenuIsOpen ? CSS.EnlargeMenu : ''}`}
			ref={menuEl}
		>
			<Sentry.ErrorBoundary fallback={null} onError={() => dispatch(onAddToast(ToastsData.GeneralError))
			}>
				{socketHasConnected ?
					<>
						<EntityMenuButtonRow
							setOpenSubMenu={setOpenSubMenu}
							openSubMenu={openSubMenu}
						/>
						{renderSubmenuContent()}
					</> : null
				}
			</Sentry.ErrorBoundary>
			
		</div>
	);
};

export default memo(Entitymenu);
