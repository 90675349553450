// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OuterContainer--1AGzZ{position:absolute;z-index:1;right:0px;bottom:0px;width:100%;height:58px;display:flex;align-items:center;justify-content:center;background-color:#fff}", ""]);
// Exports
exports.locals = {
	"OuterContainer": "OuterContainer--1AGzZ"
};
module.exports = exports;
