import { IDesignerState } from '../../typings';
import {
	ITargetDisplayInfo_Ed_Doc,
	ITuple3,
	ITuple4,
} from '../components/r3f/r3f-components/component-data-structure';

//////////
// Helper functions to retrieve states

export const getEntityPositionById = (
	state: IDesignerState,
	id: string
): ITuple3 | undefined => {
	if (!state.editorReducer.editorDoc) return undefined;
	return state.editorReducer.editorDoc.positions[id] as ITuple3;
};

export const getEntityRotationById = (
	state: IDesignerState,
	id: string
): ITuple3 | undefined => {
	if (!state.editorReducer.editorDoc) return undefined;
	return state.editorReducer.editorDoc.rotations[id] as ITuple3;
};

export const getEntityColorById = (
	state: IDesignerState,
	id: string
): ITuple4 | undefined => {
	if (!state.editorReducer.editorDoc) return undefined;
	return state.editorReducer.editorDoc.rgbaColors[id] as ITuple4;
};

export const getEntityEditingDisabledById = (
	state: IDesignerState,
	id: string
): boolean | undefined => {
	return state.editorReducer.editorDoc.editingDisabled?.[id];
};

export const getEntityBorderRgbaById = (
	state: IDesignerState,
	id: string
): ITuple4 | undefined => {
	if (!state.editorReducer.editorDoc) return undefined;
	if (!state.editorReducer.editorDoc.borderRgba) return undefined; //TODO: fix
	return state.editorReducer.editorDoc.borderRgba[id] as ITuple4;
};

export const getEntityTextById = (
	state: IDesignerState,
	id: string
): string | undefined => {
	if (!state.editorReducer.editorDoc || !state.editorReducer.editorDoc.text)
		return undefined;
	return state.editorReducer.editorDoc.text[id];
};

export const getEntityTextColorById = (
	state: IDesignerState,
	id: string
): ITuple4 | undefined => {
	if (!state.editorReducer.editorDoc || !state.editorReducer.editorDoc.fontRgba)
		return undefined;
	return state.editorReducer.editorDoc.fontRgba[id] as ITuple4;
};

export const getEditorTargetDisplayInfo = (
	state: IDesignerState
): ITargetDisplayInfo_Ed_Doc => {
	return state.editorReducer.editorDoc.targetDisplayInfo;
}

export const getEntityScaleById = (
	state: IDesignerState,
	id: string
): ITuple3 | undefined => {
	if (!state.editorReducer.editorDoc) return undefined;
	return state.editorReducer.editorDoc.scales?.[id] as ITuple3;
};

export const getEntityUserSelectionById = (
	state: IDesignerState,
	id: string
): string | undefined => {
	if (!state.editorReducer.editorDoc) return undefined;
	return state.editorReducer.editorDoc.entityIdToUserId[id];
};
