import React from 'react';
// import { useState } from 'react';
import { useEffect } from 'react';
// import { IDomIdSelectors } from '../../../../../typings';
import { useRefState } from './useRefState';

export const useClickedOutside = <T extends HTMLElement>(ref: React.MutableRefObject<T>):[boolean, (value: boolean) => void, React.MutableRefObject<boolean>] => {
    const [clickedOutside, setClickedOutside, clickedOutsideRef] = useRefState(false);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if(ref.current?.contains(event.target as Node)) return; 
            setClickedOutside(true);
        }
        document.addEventListener('pointerdown', handleClickOutside);
        return () => document.removeEventListener('pointerdown', handleClickOutside);
    }, []);

    return [clickedOutside, setClickedOutside, clickedOutsideRef]; 
};