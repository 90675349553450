import React from 'react';

import CSS from './Tab.scss';

interface ITab {
	id: string;
	title: string;
}

export type ITabData = ITab[];

interface IParentProps {
	style?: any;
	id: string;
	title: string;
	isActive: boolean;
	onClick: (tabId: string) => any;
}

const Tab: React.SFC<IParentProps> = ({ id, title, isActive, onClick }) => {
	const classes = [CSS.TabInner, isActive ? CSS.Active : ''];
	return (
		<div className={CSS.TabContainer} onClick={() => onClick(id)}>
			<div className={classes.join(' ')}>
				<span>{title}</span>
			</div>
		</div>
	);
};

export default Tab;
