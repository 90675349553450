import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IDesignerState, IToastSize, IToastType } from "../../../../../../../typings";
import { onSetIs3dMode, onAddToast } from "../../../../../../store/actions";
import Toggle from "../../../../Inputs/Toggle/Toggle";
import CSS from "./Switcher2d3d.scss"

const Switcher2d3d = () => {
  const dispatch = useDispatch();
  const is3dMode = useSelector((state: IDesignerState) => state.userReducer.is3dMode);
  const sceneHas3dEntity = useSelector((state: IDesignerState) => state.userReducer.sceneHas3dEntity);
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    if (sceneHas3dEntity) {
      setEnabled(false);
    } else {
      setEnabled(true);
    }
  }, [sceneHas3dEntity])
  
  const _onClick = (is3dMode: boolean) => {    
    if (!sceneHas3dEntity) {
      dispatch(onSetIs3dMode(is3dMode)); 
    } else {
      if (!is3dMode) {
        dispatch(onAddToast({
          type: IToastType.info,
          message: "2D Mode is disabled. 3D content on this scene must be deleted before changing to 2D mode.",
          size: IToastSize.default
        }));
      }
    }
  }

  return (
    <div className={`${CSS.Switcher2d3d} ${(!enabled && CSS.Disabled)}`}>
      <Toggle
        toggleOffFunc={() => _onClick(false)}
        toggleOnFunc={() => _onClick(true)}
        checked={is3dMode}
      />
    </div>
  )
}

export default React.memo(Switcher2d3d);