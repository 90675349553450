export enum IHotkeyTypes {
	addBoxEntity = 'b',
	removeEntities = 'Delete',
	removeEntitiesAlt = 'Backspace',
	propScale = 'Shift',
	allSidesScale = 'Alt',
	groupSelect = 'Shift',
	individualGroupRotation = 'r',
	individualGroupScale = 's',
	fullScreen = 'f',
	meta = 'Meta',
	copy = 'c',
	paste = 'v',
}

export const hotkeyIsPressed = (
	selectedKeys: string[],
	hotkeys: IHotkeyTypes[]
) => {
	//TODO: refactor below to allow multiple hotkeys to be checked
	// return (
	// 	hotkeys.filter((hotkey: string) => selectedKeys.includes(hotkey)).length ===
	// 	hotkeys.length
	// );
	return selectedKeys.includes(hotkeys[0]);
};
