import {
	ROOT_COMPNENT_ID,
	FIRST_SCENE_ID,
	FIRST_SCENE_TITLE,
	IComponentType,
	IContentDoc,
	IEditorDoc,
	IAreaTypes,
	IUnitTypes
} from '.';

export const CONTENT_DOC_VERSION = 1;
export const EDITOR_DOC_VERSION = 2;

export const initCnDoc: () => IContentDoc = () => {
	return {
		version: CONTENT_DOC_VERSION,
		rootComponentId: ROOT_COMPNENT_ID,
		componentsById: {
			[ROOT_COMPNENT_ID]: {
				type: IComponentType.Root,
				children: [FIRST_SCENE_ID],
			},
			[FIRST_SCENE_ID]: {
				type: IComponentType.Scene,
				children: [],
			},
		},
	};
};

export const initEdDoc: () => IEditorDoc = () => {
	const editorDoc: IEditorDoc = {
		version: EDITOR_DOC_VERSION,
		createdAt: Date.now(),
		users: {},
		entityIdToUserId: {},
		positions: {},
		rotations: {},
		rgbaColors: {},
		fontRgba: {},
		text: {},
		borderRgba: {},
		scales: {},
		titles: { [FIRST_SCENE_ID]: FIRST_SCENE_TITLE },
		isLocked: {},
		aspectRatioLocked: {},
		scalesInverted: {},
		originalAspectRatio: {},
		targetDisplayInfo: {
			heightInMm: 297,
			targetArea: IAreaTypes.a4,
			targetAreaAspectRatio: 1 / 1.41,
			displayUnits: IUnitTypes.cm,
		},
		projectColors: [],
		publishStatus: null,
		textScale: {},
		zmlFileId: {},
		activeTriggers: {},
		actions: {},
		mp4Url: {},
		editingDisabled: {}
	};
	return editorDoc;
};
