// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SubBtn--26RUV{border-radius:5px;padding:10px;width:100%;background-color:#4a90e2;color:#fff;margin-bottom:8px;display:flex;flex-direction:column;justify-content:center}", ""]);
// Exports
exports.locals = {
	"SubBtn": "SubBtn--26RUV"
};
module.exports = exports;
