import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const arrowDownIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
    <svg
      onClick={onClick}
      className={className || ""}
      width="7"
      height="7"
      viewBox={viewBox ? viewBox.join(" ") : "0 0 7 7"}
      fill="none"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M3.85303 6.64592C3.75987 6.73908 3.63371 6.79171 3.50197 6.79238C3.37022 6.79304 3.24354 6.74168 3.14945 6.64945L0.353554 3.85355C0.281286 3.75727 0.246185 3.63814 0.254698 3.51806C0.263211 3.39797 0.314766 3.28498 0.399893 3.19986C0.485021 3.11473 0.598004 3.06318 0.718091 3.05466C0.838178 3.04615 0.957305 3.08125 1.05359 3.15352L3.49947 5.5994L5.94889 3.14998C6.04517 3.07772 6.1643 3.04261 6.28439 3.05113C6.40448 3.05964 6.51746 3.11119 6.60259 3.19632C6.68771 3.28145 6.73927 3.39443 6.74778 3.51452C6.75629 3.63461 6.72119 3.75373 6.64893 3.85002L3.85303 6.64592Z"
        fill={hexFill}
      />
    </svg>
  );
};

export default arrowDownIcon;
