import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onGetZmlFileInfo,	onSetLoadingMedia,	onSetCurrentUploads,	ILoadedMediaTypes,	onAddToLoadedMedia,	onSetActionData_Global,} from '../../../../../../../store/actions';
import { IDesignerState,ILoadedAudioInfo,} from '../../../../../../../../typings';
import { IUploadFileTypes,	IOnProgressData,	IOnCompletionData,} from '../../../../../upload/specs';
import { IOnErrorData } from '../../../../../upload/specs';
import { IFileType } from 'zw-api-client/src/zml/specs';
import { onSubmitHandler,	onErrorHandler,	onProgressHandler,	onCompletionHandler,	IUploadIndexToFileDict,} from '../../../../../upload/utils';
import { ITriggerTypes,	IActionCategory,	ISoundOptions,} from '../../../../../../r3f/r3f-components/component-data-structure';
import { CrossIcon, SoundIcon } from '../../../../../../../assets/icons';
import UploadButton from '../../../../../upload/UploadBtn/UploadBtn';
import AudioPlayer from '../../../../../player/AudioPlayer/AudioPlayer';
import CheckBox from '../../../../../Checkbox/Checkbox';
import AudioMenuList from '../../../../AudioMenuList/AudioMenuList';
import CSS from './PlaySound.scss';

interface IParentProps {
	triggerType: ITriggerTypes;
	setShowActionSummary: (value: React.SetStateAction<boolean>) => void;
	setOpenedAction: (value: React.SetStateAction<IActionCategory>) => void;
	showActionSummary: boolean;
}

const PlaySound: React.FunctionComponent<IParentProps> = ({
	setShowActionSummary,
	setOpenedAction,
	showActionSummary,
	triggerType
}) => {
	// Redux
	const dispatch = useDispatch();
	const id = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds[0]);
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const actionsDict = useSelector((state: IDesignerState) => state.editorReducer.editorDoc?.actions?.[id] || {});
	const activeTrigger = useSelector((state: IDesignerState) => state.editorReducer.editorDoc.activeTriggers?.[id] || ITriggerTypes.onLoad);
	const editorSoundOptions = actionsDict?.[activeTrigger]?.soundOptions || ({} as ISoundOptions);
	const loadedAudio = useSelector((state: IDesignerState) => state.userReducer.loadedAudio);

	useEffect(() => {
		dispatch(onSetLoadingMedia(true));
		dispatch(onGetZmlFileInfo({ type: IFileType.Audio }));
	}, []);
	//const [error, setError] = useState<string>('');
	const [playerAudio, setPlayerAudio] = useState<HTMLAudioElement>(
		new Audio(editorSoundOptions.url)
	);

	const onSubmit = (fileDict: IUploadIndexToFileDict) => {
		onSubmitHandler(fileDict, onSetCurrentUploads, dispatch);
	};

	const onError = (e: IOnErrorData) => {
		// TODO: when no id then error occurred before upload process
		// if (!e.id) setError(`[${e.fileName}]: ${e.desc || 'error uploading'}`);
		onErrorHandler(e, onSetCurrentUploads, dispatch);
	};

	const onProgress = (p: IOnProgressData) => {
		onProgressHandler(p, onSetCurrentUploads, dispatch);
	};

	const onCompletion = (c: IOnCompletionData) => {
		onCompletionHandler(
			c,
			onSetCurrentUploads,
			ILoadedMediaTypes.audio,
			onAddToLoadedMedia,
			dispatch
		);
	};

	const baseSoundActionData = {
		ids: selectedEntityIds,
		triggerType: triggerType,
		selectedActionCategory: IActionCategory.playSound,
	}

	const baseSoundOptions = {
		url: editorSoundOptions.url,
		maxVol: 1,
	}

	const audioListHandler = (
		audioMeta: ILoadedAudioInfo,
		html5Audio: HTMLAudioElement
	) => {
		dispatch(onSetActionData_Global({
			...baseSoundActionData,
			soundOptions: {
				...baseSoundOptions,
				url: audioMeta.url,
				title: audioMeta.name,
				stopMedia: false,
				loop: editorSoundOptions.loop || false, 
			},
		}));
		setOpenedAction(null)
		setShowActionSummary(true);
		setPlayerAudio(html5Audio);
	};
	
	const audioMetaUpdate = (
		loop?: boolean,
		stopMedia?: boolean
	) => {

		dispatch(onSetActionData_Global({
			...baseSoundActionData,
			soundOptions: {
				...baseSoundOptions,
				stopMedia: stopMedia,
				loop: loop, 
			},
		}));
	};

	const content = !showActionSummary ? (
    <>
      {loadedAudio && loadedAudio.length > 0 ? (
        <>
          <UploadButton
            className={CSS.GhostButton}
            onError={onError}
            onProgress={onProgress}
            onSubmit={onSubmit}
            onCompletion={onCompletion}
            allowedFileTypes={[
              IUploadFileTypes.audio_wav,
              IUploadFileTypes.audio_m4a,
              IUploadFileTypes.audio_mpeg,
              IUploadFileTypes.audio_ogg,
              IUploadFileTypes.audio_ext_fallback,
            ]}
          >
            Upload audio
          </UploadButton>
          <div className={CSS.AudioListContainer}>
            <div className={CSS.AudioTitle}>
              <strong>My files</strong>
            </div>
            <AudioMenuList
              className={CSS.AudioList}
              loadedAudio={loadedAudio}
              onChange={(audioMeta, html5Audio) =>
                audioListHandler(audioMeta, html5Audio)
              }
            />
          </div>
        </>
      ) : (
        <UploadButton
          onError={onError}
          className={CSS.UploadButton}
          onProgress={onProgress}
          onSubmit={onSubmit}
          onCompletion={onCompletion}
          allowedFileTypes={[
            IUploadFileTypes.audio_wav,
            IUploadFileTypes.audio_m4a,
            IUploadFileTypes.audio_mpeg,
            IUploadFileTypes.audio_ogg,
            IUploadFileTypes.audio_ext_fallback,
          ]}
        >
          Upload audio
        </UploadButton>
      )}
    </>
  ) : (
    <>
      <div className={CSS.AudioTitleContainer}>
        <SoundIcon></SoundIcon>
        <div className={CSS.Title}>
          <div
            // Use element style for now cause we think this will probably change
            style={{
              maxWidth: `150px`,
              overflow: `hidden`,
              textOverflow: `ellipsis`,
            }}
          >
            {editorSoundOptions.title}
          </div>
          <CrossIcon
            height={"12px"}
            width={"12px"}
            strokeWidth={1}
            className={CSS.TitleClose}
            onClick={() => {
              setShowActionSummary(false);
              setOpenedAction(IActionCategory.noAction);
              dispatch(
                onSetActionData_Global({
                  ids: selectedEntityIds,
                  triggerType: triggerType,
                  selectedActionCategory: IActionCategory.noAction,
                })
              );
            }}
          ></CrossIcon>
        </div>
      </div>
      <AudioPlayer src={editorSoundOptions.url} />
      <div className={CSS.CheckBoxRow}>
        <div className={CSS.CheckBoxRow}>
          <CheckBox
            onChange={(checked) =>
              audioMetaUpdate(editorSoundOptions.loop, checked)
            }
            label="Stop other media"
            checked={editorSoundOptions.stopMedia}
          />
        </div>
        <div className={CSS.CheckBoxRow}>
          <CheckBox
            onChange={(checked) =>
              audioMetaUpdate(checked, editorSoundOptions.stopMedia)
            }
            label="Loop"
            checked={editorSoundOptions.loop}
          />
        </div>
      </div>
    </>
  );

	return content;
};



export default React.memo(PlaySound);
