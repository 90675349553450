import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../../typings';
import { onGetZmlFileInfo, onSetLoadingMedia, onSetCurrentUploads, ILoadedMediaTypes, onAddToLoadedMedia, onSetMultipleComponentProps_Cn_Doc, onSetMultipleEntityProps_Ed_Doc } from '../../../../../../store/actions';
import { IOnCompletionData } from '../../../../upload/specs';
import { IFileType } from 'zw-api-client/src/zml/specs';
import { IUploadFileTypes } from '../../../../upload/specs';
import { onSubmitHandler, onErrorHandler, onProgressHandler, onCompletionHandler } from '../../../../upload/utils';
import { IBkgrSound, IRoot } from '../../../../../r3f/r3f-components/component-data-structure'
import { CrossIcon, SoundIcon } from '../../../../../../assets/icons';
import UploadButton from '../../../../upload/UploadBtn/UploadBtn';
import UploadDropzone from '../../../../upload/UploadDropzone/UploadDropzone';
import AudioPlayer from '../../../../player/AudioPlayer/AudioPlayer';
import AudioMenuList from '../../../AudioMenuList/AudioMenuList';
import CheckBox from '../../../../Checkbox/Checkbox';
import CSS from './BackgroundSound.scss';

const BackgroundSound: React.FunctionComponent = () => {
	// Redux
	const dispatch = useDispatch();
	const loadedAudio = useSelector((state: IDesignerState) => state.userReducer.loadedAudio);
	const rootComponentId = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.rootComponentId || 'rootId');
	const contentDict = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const backgroundSound = (contentDict[rootComponentId] as IRoot)?.bkgrSound || {} as IBkgrSound;
	const title = useSelector((state: IDesignerState) => state.editorReducer.editorDoc.titles[rootComponentId]);

	// State & Effect
	const [error, setError] = useState<string>('');
	useEffect(() => {
		dispatch(onSetLoadingMedia(true));
		dispatch(onGetZmlFileInfo({ type: IFileType.Audio }));
	}, []);

	const onCompletion = (c: IOnCompletionData) => {
		onCompletionHandler(
			c,
			onSetCurrentUploads,
			ILoadedMediaTypes.audio,
			onAddToLoadedMedia,
			dispatch
		);
	};

	const content = !backgroundSound.url ? (
    <>
      <UploadDropzone
        className={CSS.Dropzone}
        onDragover={() => setError("")}
        onError={(e) => onErrorHandler(e, onSetCurrentUploads, dispatch)}
        onDrop={(fileDict) =>
          onSubmitHandler(fileDict, onSetCurrentUploads, dispatch)
        }
        onProgress={(p) => onProgressHandler(p, onSetCurrentUploads, dispatch)}
        onCompletion={onCompletion}
        allowedFileTypes={[
          IUploadFileTypes.audio_wav,
          IUploadFileTypes.audio_m4a,
          IUploadFileTypes.audio_mpeg,
          IUploadFileTypes.audio_ogg,
          IUploadFileTypes.audio_ext_fallback,
        ]}
      >
        <UploadButton
          onError={(e) => onErrorHandler(e, onSetCurrentUploads, dispatch)}
          onProgress={(p) =>
            onProgressHandler(p, onSetCurrentUploads, dispatch)
          }
          onSubmit={(fileDict) =>
            onSubmitHandler(fileDict, onSetCurrentUploads, dispatch)
          }
          onCompletion={onCompletion}
          allowedFileTypes={[
            IUploadFileTypes.audio_wav,
            IUploadFileTypes.audio_m4a,
            IUploadFileTypes.audio_mpeg,
            IUploadFileTypes.audio_ogg,
            IUploadFileTypes.audio_ext_fallback,
          ]}
          style={	
            loadedAudio && loadedAudio.length
              ? {
                  backgroundColor: `white`,
                  color: `#4a90e2`,
                  border: `1px solid #4a90e2`,
                  height: `32px`,
                  borderRadius: `6px`,
                  marginTop: `0px`,
                }
              : null
          }
        >
          Upload audio
        </UploadButton>
      </UploadDropzone>
      Looking for <a href="https://docs.zap.works/general/design/sourcing-assets/#audio" target="_blank">audio assets</a>?
      {loadedAudio && loadedAudio.length > 0 && (
        <div className={CSS.AudioListContainer}>
          <div className={CSS.AudioTitle}>
            <strong>My files</strong>
          </div>
          <AudioMenuList
            className={CSS.AudioList}
            loadedAudio={loadedAudio}
            onChange={(audio) => {
              dispatch(onSetMultipleComponentProps_Cn_Doc([
                {
                  id: rootComponentId,
                  bkgrSound: {
                    url: audio.url,
                    maxVol: 1,
                    loop: backgroundSound.loop || false,
                  },
                },
              ]));
              dispatch(onSetMultipleEntityProps_Ed_Doc([
                {
                  id: rootComponentId,
                  titles: audio.name,
                },
              ]));
            }}
          />
        </div>
      )}
    </>
  ) : (
    <>
      <div className={CSS.AudioTitleContainer}>	
        <SoundIcon></SoundIcon>
        <div className={CSS.Title}>
          <div
            style={{
              maxWidth: `150px`,
              overflow: `hidden`,
              textOverflow: `ellipsis`,
            }}>
            {title}
          </div>
          <CrossIcon
            height={"12px"}
            width={"12px"}
            strokeWidth={1}
            className={CSS.TitleClose}
            onClick={() => {
              dispatch(onSetMultipleComponentProps_Cn_Doc([
                {
                  id: rootComponentId,
                  bkgrSound: null,
                },
              ]));
            }}></CrossIcon>
        </div>
      </div>
      <AudioPlayer src={backgroundSound.url} />
      <div className={CSS.CheckBoxRow}>
        <CheckBox
						onChange={(checked) => {
							dispatch(onSetMultipleComponentProps_Cn_Doc([
								{
									id: rootComponentId,
									bkgrSound: {
										url: backgroundSound.url,
										maxVol: 1,
										loop: checked
									},
								},
							]));
						}}
						checked={backgroundSound.loop}
						label="Loop"
        />
      </div>
    </>
  );

	return content;
};

export default React.memo(BackgroundSound);
