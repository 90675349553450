import React, { useState } from 'react';

import Divider from '../../Divider/Divider';
import CSS from './PublishInfo.scss';
import {
	IPublishStatus,
	IPublishTypes,
} from '../../../r3f/r3f-components/component-data-structure';

interface IParentProps {
	publishData: IPublishStatus;
}

const PublishInfo: React.SFC<IParentProps> = ({ publishData }) => {
	if (!publishData || publishData.status === IPublishTypes.notPublished)
		return null;
	const [isHovered, setIsHovered] = useState<boolean>(false);
	const { status, dateString } = publishData;
	const date = new Date(dateString);

	let statusColor: string;
	let statusText: string;

	switch (status) {
		case IPublishTypes.unpublishedChanges:
			statusColor = '#ffba5c';
			statusText = 'Unpublished Changes';
			break;
		default:
			statusColor = '#0cd185';
			statusText = 'Published';
			break;
	}

	const dateOptions = {
		weekday: 'short',
		year: 'numeric',
		month: 'long',
	} as Intl.DateTimeFormatOptions;
	const timeOptions = { hour: '2-digit', minute: '2-digit' } as Intl.DateTimeFormatOptions;

	const localDateString = date.toLocaleDateString('en-US', dateOptions);
	const localTimeString = date.toLocaleTimeString('en-US', timeOptions);

	const onMouseEnter = () => setIsHovered(true);
	const onMouseLeave = () => setIsHovered(false);

	return (
		<div className={CSS.PublishInfo}>
			<Divider height="25px" />
			<div
				className={CSS.StatusCirlce}
				style={{ backgroundColor: statusColor }}
			/>
			<span
				className={CSS.DateString}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{statusText}
			</span>
			{isHovered && (
				<span className={CSS.DateString}>
					on {localDateString} at {localTimeString}
				</span>
			)}
		</div>
	);
};

export default PublishInfo;
