// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UploadTitle--2d3oN{font-size:18px;margin-bottom:22px}.UploadBar--1ISlp{display:flex;align-items:center;justify-items:flex-start;width:198px}.MinimizedDialog--n8EaM{padding:12px 9px !important;padding-right:4px}.MinimizedDialog--n8EaM>span{top:6px}.HasError--1Fe9p{border-color:red !important}.Success--2sAdm{border-color:#0cd185 !important}.ModalContainer--3SZsE{height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center}.InfoRowContainer--2wCXd{max-height:176px;overflow-y:scroll;padding-right:4px}", ""]);
// Exports
exports.locals = {
	"UploadTitle": "UploadTitle--2d3oN",
	"UploadBar": "UploadBar--1ISlp",
	"MinimizedDialog": "MinimizedDialog--n8EaM",
	"HasError": "HasError--1Fe9p",
	"Success": "Success--2sAdm",
	"ModalContainer": "ModalContainer--3SZsE",
	"InfoRowContainer": "InfoRowContainer--2wCXd"
};
module.exports = exports;
