import { colors } from '../assets/colors';

let colorNames = colors.map(color => color.id);
let selColors: string[] = [];
let prohibColors: string[] = [];

export const getRandomColor = () => {
	const randomIndex = Math.floor(Math.random() * colorNames.length);
	let randomColor = colorNames[randomIndex];
	while (
		(prohibColors.includes(randomColor) || selColors.includes(randomColor)) &&
		selColors.length + prohibColors.length !== colorNames.length
	) {
		const randomIndex = Math.floor(Math.random() * colorNames.length);
		randomColor = colorNames[randomIndex];
	}
	if (selColors.length + prohibColors.length === colorNames.length)
		return 'white';
	selColors.push(randomColor);
	const colorObj = colors.filter(color => color.id === randomColor)[0];
	if (colorObj.exclude) {
		const newProhibCol = colorObj.exclude.filter(
			color => !prohibColors.includes(color)
		);
		prohibColors = [...prohibColors, ...newProhibCol];
	}
	return colorObj.id;
};
