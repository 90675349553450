// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".switch--1ZKOu{position:relative;display:inline-block;width:43px;height:24px;padding:0px;opacity:1}.switch--1ZKOu input{opacity:0;width:0;height:0}.slider--y3Xl-{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#ccc;-webkit-transition:.4s;transition:.4s;border-radius:13px}.slider--y3Xl-:before{position:absolute;content:\"\";height:18px;width:18px;left:3px;bottom:3px;background-color:#fff;-webkit-transition:.4s;transition:.4s;border-radius:50%}input:checked+.slider--y3Xl-{background-color:#4a90e2}input:focus+.slider--y3Xl-{box-shadow:0 0 1px #4a90e2}input:checked+.slider--y3Xl-:before{-webkit-transform:translateX(19px);-ms-transform:translateX(19px);transform:translateX(19px)}button.Active--3YQI9{color:#4a90e2;font-weight:bold;font-size:14px}button.Inactive--945so{color:#b4bbc3;font-weight:bold;font-size:14px}", ""]);
// Exports
exports.locals = {
	"switch": "switch--1ZKOu",
	"slider": "slider--y3Xl-",
	"Active": "Active--3YQI9",
	"Inactive": "Inactive--945so"
};
module.exports = exports;
