import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { IDesignerState } from '../../../../../../../typings';
import TargetAreaProperties from './TargetAreaProperties/TargetAreaProperties';
import TargetImageProperties from './TargetImageProperties/TargetImageProperties';
import { getEditorTrackingInfo } from '../../../../../../store/selectors';
import {
	ITrackingUnionState_Ed_Doc,
	ITrackingTypes,
} from '../../../../../r3f/r3f-components/component-data-structure';

interface IReduxProps {
	tracking: ITrackingUnionState_Ed_Doc;
	trkImgIsUploading: boolean;
}

const TargetProperties: FunctionComponent<IReduxProps> = ({
	tracking, 
	trkImgIsUploading, 
}) => {
	const getUIForTrackingType = (trackingType?: ITrackingTypes) => {
		if((trackingType && trackingType === ITrackingTypes.image)
			|| trkImgIsUploading) {
			return <TargetImageProperties />
		}
		return  <TargetAreaProperties />
	}
	return getUIForTrackingType(tracking?.type);
};

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	return {
		tracking: getEditorTrackingInfo(state),
		trkImgIsUploading: state.userReducer.trkImgIsUploading,
	};
};

export default connect(mapStateToProps)(TargetProperties);
