// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FlexContainer--1K1fh{display:flex;flex-direction:column;align-items:center;margin-bottom:12px}.FlexContainer--1K1fh .FlexRow--1JOc3{display:flex;flex-direction:row;width:100%;margin:12px 0;justify-content:space-between}.FlexContainer--1K1fh .FlexRow--1JOc3.FirstRow--3qnrw{margin-top:unset}.FlexContainer--1K1fh .FlexRow--1JOc3 .Logo--DZg42,.FlexContainer--1K1fh .FlexRow--1JOc3 .Photo--XdEY6{width:24%;text-align:right}.FlexContainer--1K1fh .FlexRow--1JOc3 .Photo--XdEY6>img{box-shadow:0px 8px 7px -3px rgba(161,159,159,.71);-webkit-box-shadow:0px 8px 7px -3px rgba(161,159,159,.71);-moz-box-shadow:0px 8px 7px -3px rgba(161,159,159,.71)}.FlexContainer--1K1fh .FlexRow--1JOc3 .Description--1et2D{width:72%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.FlexContainer--1K1fh .FlexRow--1JOc3 .TextArea--2ic5e{width:72%}.FlexContainer--1K1fh .FlexRow--1JOc3 .Company--2pI7E{margin-top:9%}.FlexCol--3sYTC{display:flex;flex-direction:column;width:71%}", ""]);
// Exports
exports.locals = {
	"FlexContainer": "FlexContainer--1K1fh",
	"FlexRow": "FlexRow--1JOc3",
	"FirstRow": "FirstRow--3qnrw",
	"Logo": "Logo--DZg42",
	"Photo": "Photo--XdEY6",
	"Description": "Description--1et2D",
	"TextArea": "TextArea--2ic5e",
	"Company": "Company--2pI7E",
	"FlexCol": "FlexCol--3sYTC"
};
module.exports = exports;
