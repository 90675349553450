import React from 'react';

import CSS from './GhostBtn.scss';

interface IParentProps {
	children?: any;
	className?: string;
	onClick?: (e: React.MouseEvent) => any;
	style?: any;
	disabled?: boolean;
}

const GhostButton = ({
	children,
	onClick,
	className,
	style,
	disabled = false,
}: IParentProps) => {
	let classes = [CSS.GhostBtn, className ? className : ''];
	return (
		<button
			style={{ ...style }}
			className={classes.join(' ')}
			disabled={disabled}
			onClick={e => {
				if (onClick) onClick(e);
			}}
		>
			{children}
		</button>
	);
};

export default GhostButton;
