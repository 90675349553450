import React from 'react';
import { IIconParentProps } from './icons/index';

const spinner: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || '#4a90e2';
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="32px"
			height="32px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 100 100'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			style={{ margin: 'auto', background: 'transparent', display: 'block' }}
			preserveAspectRatio="xMidYMid"
		>
			<circle
				cx="50"
				cy="50"
				r="39"
				stroke="rgba(255, 255, 255, 0)"
				strokeWidth="0"
				fill="none"
			></circle>
			<circle
				cx="50"
				cy="50"
				r="39"
				stroke={hexFill}
				strokeWidth="8"
				strokeLinecap="round"
				fill="none"
				transform="rotate(260.996 50 50)"
			>
				<animateTransform
					attributeName="transform"
					type="rotate"
					repeatCount="indefinite"
					dur="1.9607843137254901s"
					values="0 50 50;180 50 50;720 50 50"
					keyTimes="0;0.5;1"
				></animateTransform>
				<animate
					attributeName="stroke-dasharray"
					repeatCount="indefinite"
					dur="1.9607843137254901s"
					values="0 245.04422698000386;205.83715066320326 39.207076316800595;0 245.04422698000386"
					keyTimes="0;0.5;1"
				></animate>
			</circle>
		</svg>
	);
};

export default spinner;
