import React, {useState} from 'react';

import {
	DownTransIcon,
	UpTransIcon,
	LeftTransIcon,
	RightTransIcon,
	FadeTransIcon,
	ScaleUpTransIcon,
	ScaleDownTransIcon,
	NoTransIcon,
	SpinTransIcon,
} from '../../../../../../../assets/icons';

import CSS from './TypeSelection.scss';
import { ITransitionCategoryTypes, ITransitionEffectTypes } from '../../../../../../r3f/r3f-components/component-data-structure';

interface IParentProps {
	transitionType: ITransitionEffectTypes;
	setTransitionType: (eff: ITransitionEffectTypes) =>  any;
	transitionCategory: ITransitionCategoryTypes
}

interface IReduxProps {}

const Transitions: React.SFC<IParentProps & IReduxProps> = ({
	transitionType,
	setTransitionType,
	transitionCategory,
}) => {
	const [hoveredEffect, setHoveredEffect] = useState<ITransitionEffectTypes | null>(null); 
	let transitionTypeBtnsData = [
		{
			type: ITransitionEffectTypes.noEffect,
			icon: NoTransIcon,
			title: "None", 
			animationClass: null,
		},
		{
			type: ITransitionEffectTypes.right,
			icon: RightTransIcon,
			title: "Slide L-R",
			animationClass: CSS.RightAnimation
		},
		{
			type: ITransitionEffectTypes.left,
			icon: LeftTransIcon,
			title: "Slide R-L",
			animationClass: CSS.LeftAnimation, 
		},
		{
			type: ITransitionEffectTypes.up,
			icon: UpTransIcon,
			title: "Slide up",
			animationClass: CSS.UpAnimation, 
		},
		{
			type: ITransitionEffectTypes.down,
			icon: DownTransIcon,
			title: "Slide down",
			animationClass: CSS.DownAnimation, 
		},
		{
			type: ITransitionEffectTypes.scaleUp,
			icon: ScaleUpTransIcon,
			title: "Scale-Up",
			animationClass: CSS.ScaleUpAnimation, 
		},
		{
			type: ITransitionEffectTypes.scaleDown,
			icon: ScaleDownTransIcon,
			title: "Scale-Down",
			animationClass: CSS.ScaleDownAnimation, 
		},
		//TODO: Add back when the spining transition will exist
		// {
		// 	type: ITransitionEffectTypes.spin,
		// 	icon: SpinTransIcon,
		// 	title: "Spin-In",
		// 	animationClass: CSS.SpinAnimation, 
		// },
	];

	// Fade in for Enter, Fade out for Exit
	const fadeElement = (transitionCategory === ITransitionCategoryTypes.enter ? {
		type: ITransitionEffectTypes.fadeIn,
		icon: FadeTransIcon,
		title: "Fade-In", 
		animationClass: CSS.FadeInAnimation, 
	} : {
		type: ITransitionEffectTypes.fadeOut,
		icon: FadeTransIcon,
		title: "Fade-Out", 
		animationClass: CSS.FadeOutAnimation, 
	});
	transitionTypeBtnsData.splice(5,0,fadeElement)

	return (
		<div className={CSS.TypesContainer}>
			{transitionTypeBtnsData.map(trans => {
				const classNames = [CSS.SvgContainer];
				const svgClassNames = []; 
				if(transitionType === trans.type) classNames.push(CSS.Active);
				if(hoveredEffect ===  trans.type) svgClassNames.push(trans.animationClass);
				
				const SvgIcon = trans.icon;
				return (
					<div className={CSS.ButtonWrap} key={trans.title}>
						<div
							className={classNames.join(' ')}
							key={trans.type}
							onPointerDown={() => {
								setTransitionType(trans.type);
							}}	
							onPointerEnter={
								() => {
									if(hoveredEffect !== trans.type) setHoveredEffect(trans.type)
								}
							}
							onPointerLeave={() => {
								setHoveredEffect(null)
							}}
						>
							<SvgIcon 
								hexFill={transitionType !== trans.type ? "#96BFEF" : "#FFFFFF"}
								secondHexFill={transitionType !== trans.type ? "#EEF6FF" : "#FFFFFF"}
								className={svgClassNames.join(' ')}
								/>
						</div>
						<div className={CSS.Title}>
							{trans.title}
						</div>
					</div>
				);
			})}
			{/* TODO: Added to keep the grid of buttons as original grid while removing the spin transition. Delete me when adding back the spin transition. 	 */}
			<div className={CSS.DummyButtonWrap}></div>
		</div>
	);
};

export default Transitions;
