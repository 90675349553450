// this only works if this is part of an npm package that has @react-three/fiber as a dependency
// import { PointerEvent } from '@react-three/fiber';

import {
	IVector3,
	IVector4,
	IFontTypes,
	IModel3dActionData,
	IImageActionData,
	ITransitionOptions,
	IBtnAndTxtActionData,
	IBkgrSound,
	ISceneActionData,
	IVideoActionData,
	ITextAlignment
} from '../index';
import { ITuple3 } from './general';

export enum IComponentType {
	Button = 'Button',
	Image = 'Image',
	Text = 'Text',
	Video = 'Video',
	Scene = 'Scene',
	Root = 'Root',
	Model3d = 'Model3d'
}

export type ISpatialComponentUnion = IButton | IImage | IText | IVideo | IModel3d;
export type IAbstractComponentUnion = ISceneComp | IRoot;
export type IComponentUnion = ISpatialComponentUnion | IAbstractComponentUnion;

export interface IBaseComponent {
	transitions?: ITransitionOptions;
}

interface IBaseAbstractComponent {
	children: string[];
}

export interface IRoot extends IBaseAbstractComponent {
	type: IComponentType.Root;
	bkgrSound?: IBkgrSound | null;
}

export interface ISceneComp extends IBaseComponent, IBaseAbstractComponent {
	type: IComponentType.Scene;
	actions?: ISceneActionData;
}

export interface IBaseSpatialComponent extends IBaseComponent {
	id?: string;
	scale: IVector3;
	position: IVector3;
	rotation: IVector3;
	opacity?: number;
	borderRadius?: number;
	borderWidth?: number;
	borderRgba?: IVector4;
	stopMedia?: boolean;
}

// Button
interface IButtonMetaState {
	type: IComponentType.Button;
	actions?: IBtnAndTxtActionData;
}


export interface IButtonDisplayState extends IBaseSpatialComponent {
	fontRgba: IVector4;
	text: string;
	color: IVector4;
	fontFamily: IFontTypes;
	fontSize: number;
	sceneId?: string;
	textAlignment?: ITextAlignment;
	textureUrl?: string;
	svgUrl?: string;
}

export interface IButton extends IButtonMetaState, IButtonDisplayState { }



interface IModel3dDisplayState extends IBaseSpatialComponent {}
interface IModel3dMetaState {
	type: IComponentType.Model3d;
	thumbnailUrl?: string;
	model3dUrl?: string;
	dimensions?: ITuple3;
	actions?: IModel3dActionData; 
}

export interface IModel3d extends IModel3dMetaState, IModel3dDisplayState {}

interface ITextMetaState {
	type: IComponentType.Text;
	actions?: IBtnAndTxtActionData;
}
export interface ITextDisplayState extends IBaseSpatialComponent {
	fontRgba: IVector4;
	text: string;
	color: IVector4;
	fontFamily: IFontTypes;
	fontSize: number;
	textAlignment?: ITextAlignment;
}

export interface IText extends ITextMetaState, ITextDisplayState {}
export interface IImage extends IBaseSpatialComponent {
	type: IComponentType.Image;
	zmlId?: string;
	actions?: IImageActionData;
	imageUrl: string;
	opacity?: number;
	hasAlpha?: boolean;
}

export interface IVideo extends IBaseSpatialComponent {
	type: IComponentType.Video;
	id: string;
	muted?: boolean;
	paused?: boolean; 
	zmlId?: string;
	autoplay?: boolean;
	loop?: boolean;
	hideControls?: boolean;
	hasAlpha?: boolean;
	enabled?: boolean;
	actions?: IVideoActionData;
	thumbnailUrl?: string;
	videoUrl: string;
	opacity?: number;
	btnOpacity?: number;
	maxVol?: number;
	source: string;
	externalId: string;
	siblings?: string[];
	preventVideoLoad?: boolean;
	onFullScreen?: (video: HTMLVideoElement, e: any) => any;
	onEnded?: () => any;
}
