// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ButtonRow--Bdpjq{display:flex;align-items:center;justify-content:space-between;width:100%;margin-top:5px}.ButtonRow--Bdpjq>.TimeContainerRight--3v_VU{width:50px;display:flex;align-items:center;justify-content:flex-end}.ButtonRow--Bdpjq>.TimeContainerLeft--R6m9z{width:50px;display:flex;align-items:center;justify-content:flex-start}.ButtonRow--Bdpjq .MainControlDiv--2aeDj{width:fit-content;display:flex;height:100%;align-items:center;justify-content:center}.ButtonRow--Bdpjq .MainControlDiv--2aeDj .OuterIconContainer--2-cdp{width:60px;display:flex;align-items:center;justify-content:center}.ButtonRow--Bdpjq .MainControlDiv--2aeDj .OuterIconContainer--2-cdp>.InnerIconContainer--6Eexh{width:32px;height:32px;display:flex;justify-content:center;align-items:center}", ""]);
// Exports
exports.locals = {
	"ButtonRow": "ButtonRow--Bdpjq",
	"TimeContainerRight": "TimeContainerRight--3v_VU",
	"TimeContainerLeft": "TimeContainerLeft--R6m9z",
	"MainControlDiv": "MainControlDiv--2aeDj",
	"OuterIconContainer": "OuterIconContainer--2-cdp",
	"InnerIconContainer": "InnerIconContainer--6Eexh"
};
module.exports = exports;
