import React from 'react';

interface IParentProps {
	width?: string;
	height?: string;
	className?: any;
}

const Divider: React.SFC<IParentProps> = ({ width, height, className }) => {
	let dividerStyle: React.CSSProperties = {};
	if (width) {
		dividerStyle = {
			borderTop: `1px solid #B2C4D7`,
			height: 'auto',
			width,
		};
	} else {
		dividerStyle = {
			borderLeft: `1px solid #B2C4D7`,
			width: 'auto',
			height,
		};
	}

	return <div className={className} style={dividerStyle} />;
};

export default Divider;
