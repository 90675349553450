import React, { FunctionComponent, memo } from 'react';
import { useSelector } from 'react-redux';
import {IDesignerState,} from '../../../../../../../typings';
import { IFileType } from 'zw-api-client/src/zml/specs';
import ZmlEntitySelection from '../shared/ZmlEntitySelection/ZmlEntitySelection';

interface VideoSelectionProps {
	onClose: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => any;
}


const VideoSelection: FunctionComponent<VideoSelectionProps> = ({onClose}) => {
	const loadedVideos = useSelector((state: IDesignerState) => state.userReducer.loadedVideos);
    return (
		<ZmlEntitySelection loadedEntities={loadedVideos} columns={1} fileType={IFileType.Hls} onClose={onClose}/>
	);
};

export default memo(VideoSelection);
