import { combineReducers } from 'redux';
import editorReducer from './editor-reducer';
import contentReducer from './content-reducer';
import userReducer from './user-reducer';

export default combineReducers({
	userReducer,
	editorReducer,
	contentReducer,
});
