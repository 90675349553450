import React, { memo, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { CrossIcon } from '../../../../assets/icons';
import CSS from './IntroModal.scss';

import '../Modal.global.scss';
import DangerButton from '../../buttons/DangerBtn/DangerBtn';
import Checkbox from '../../Checkbox/Checkbox';


interface IParentProps {
	className?: string;
	children?: any;
	onClose: () => any;
	appendToElId?: string;
	zIndex?: number;
}

const IntroModal: React.FunctionComponent<IParentProps> = ({
	appendToElId = 'root',
	className = '',
	zIndex: zI,
  onClose
}) => {  
	const [isChecked, setIsChecked ]= useState(true);
	const classes = [CSS.InnerContainer, className];
	const zIndex = typeof zI === 'undefined' ? 999 : zI;
	const bkgrRef = useRef(null);
	const closeBtnRef = useRef(null);
	const modalRef = useRef(null);

  const _onClose = () => {
    if (isChecked) localStorage.setItem('hide-intro-modal', 'true')
    onClose();
  }

	const checkBoxHandler = (checked: boolean) => {
		setIsChecked(prevState => !prevState);
    if (checked) localStorage.setItem('hide-intro-modal', 'true');
    else localStorage.removeItem('hide-intro-modal')
	}
	
    return createPortal(
      <>
        <div className={CSS.Container} ref={modalRef}>
          <div className={classes.join(" ")}>
            <div className={CSS.CrossButton} onClick={_onClose} ref={closeBtnRef}>
              <CrossIcon />
            </div>

            <div className={CSS.Content}>
              <div className={CSS.ImageContent}>
                <div className={CSS.Image}></div>
              </div>
              <div className={CSS.TextContent}>
                <h1>Welcome to Designer!</h1>
                <p>
                  Thank you for trying the new and improved Designer. To get started, simply upload a target image on
                  the right hand side and add some content to your scenes. You can also learn more with our{" "}
                  <a rel="noreferrer" target="_blank" href="https://docs.zap.works/designer/getting-started/">
                    documentation and tutorials.
                  </a>
                </p>
                <p>
                  If you have experience building content with Designer, you will notice the following improvements:{" "}
                </p>
                <ul>
                  <li>A new 3D viewport for manipulating objects</li>
                  <li>Support for 3D models</li>
                  <li>A lovely new user interface</li>
                  <li>Improved video including streaming and alpha support</li>
                  <li>WebAR first</li>
                  <li>Dozens of quality of life improvements</li>
                </ul>
                <p>
                  Please do test your projects thoroughly on multiple devices and share your feature requests and any
                  bugs using the ’Send feedback’ button at the top of the screen.
                </p>

                <div className={CSS.Footer}>
                  <div className={CSS.Checkbox}>
                    <Checkbox checked={isChecked} onChange={checkBoxHandler} />
                    <span>Don't show this again.</span>
                  </div>
                  <DangerButton onClick={_onClose}>Get started</DangerButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={CSS.Background} onClick={_onClose} style={{ zIndex }} ref={bkgrRef} />
      </>,
      document.getElementById(appendToElId)
    );
};

export default memo(IntroModal);
