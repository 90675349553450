export enum IFileType {
    Image = 'image',
    Model3d = '3dmodel',
    Hls = 'hls',
    Audio = 'audio',
    YouTubeThumbnail = 'youtube thumbnail',
    VimeoThumbnail = 'vimeo thumbnail',
    WebsiteScreenshot = 'website screenshot',
    TrackingImage = 'trackingimage',
    ZPP = 'zpp',
    Image360 = '360 image',
    Video360 = '360 video',
    Temp = 'temporary',
    UARPackage = 'uarzip',
    LookUpImage = 'lookup image'
}

export enum IFileStatus {
    Pending = 'pending',
    Ready = 'ready',
    Error = 'error',
    Deleted = 'deleted',
    Success = 'success' // v2
}

export interface IUpdateSettings {
    folder: string
    filename: string
    thumbnails: Array<object>
    image_url: string
    title: string
    ftype: IFileType
}

export interface IFileDict {
    [fileName: string]: boolean;
}


export interface IMediaFileOutput {
    AspectRatio?: number;
    Codes?: string[];
    CompositeURL?: string;
    Quality?: number;
}

export interface IImageJobOutput {
    AspectRatio: number;
}

export interface IAnalysisJobOutput {
    Codes: string[];
    CompositeURL: string;
    Quality: number;
}

export interface ITrainingJobOutput {
    zpt: string
}

export interface IBaseMediaData {
    id: string;
    url: string;
    type: IFileType;
    caption: string;
    filename: string;
    status: IFileStatus;
    error: string;
    date: string;
    statusURL: string;
}

export interface IMediaData extends IBaseMediaData {
    output: IMediaFileOutput;
}

export interface IMediaResponse {
    count: number;
    next: string;
    previous: string;
    results: IMediaData[];
}

export interface IPreviewData {
    status: IFileStatus;
    statusURL: string;
    zapcode: {code: string, svgURL: string};
    code: string;
    svgURL: string;
}

export interface ITrackingImageData extends IBaseMediaData {
    type: IFileType.TrackingImage;
    output: IMediaFileOutput;
}

export interface IImageData extends IMediaData {
    type: IFileType.Image;
    output: IMediaFileOutput;
}

export interface IVideoData extends IBaseMediaData {
    type: IFileType.Hls,
    output: {
        aspectRatio: number;
        thumbnailURL: string;
        duration: number;
        hasAlpha?: boolean;
        isPortrait?: boolean;
        height?: number;
        width?: number;
        mp4URL: string;
    };
}

export interface IModel3dData extends IBaseMediaData {
    type: IFileType.Model3d,
    output: {
        modelURL: string;
        thumbnailURL: string;
        infoURL: string;
        dimensions: {
            x: number;
            y: number;
            z: number;
        };
    }
}

export interface IAudioData extends IMediaData {
    type: IFileType.Audio;
    output: IMediaFileOutput;
}

export type IUnionMediaData = IImageData | IVideoData | IAudioData | ITrackingImageData | IModel3dData;