import React, { useState, useRef, useEffect, FunctionComponent, useMemo, useLayoutEffect } from 'react';
import { DropdownButton } from '../../buttons';
import PopupMenu from '../PopupMenu/PopupMenu';
import { IMenuItem } from '../MenuList/MenuList';
import { ListSmallIcon } from '../../../../assets/icons';
import { IDomIdSelectors } from '../../../../../typings';
import CSS from './Dropdown.scss';


export enum IMenuPosition {
    left = 'left', 
    right = 'right', 
    center = 'center',
}

export interface IDropdownParentProps {
	width?: number | string;
	height?: number | string;
	children: IMenuItem[];
	value: string;
	onChange?: (val: string) => any;
	onCloseMenu?: () => any; 
	multiSelect?: boolean;
	menuHeight?: number | string;
	isSelectInput?: boolean; 
	appendMenuToRoot?: boolean; 
	forcedHideMenu?: boolean; 
	hideButton?: boolean; 
	className?: string; 
	positionMenu?: IMenuPosition;	
	menuWidth?: string; 
	buttonCornerStyle?: number;
	disabled?: boolean; 
}

const Dropdown: FunctionComponent<IDropdownParentProps> = ({
	children,
	onChange,
	onCloseMenu, 
	value,
	width: w = '100%',
	height: h = '40px',
	multiSelect,
	menuHeight: mh = 'auto',
	isSelectInput, 
	positionMenu, 
	menuWidth,
	appendMenuToRoot, 
	forcedHideMenu, 
	hideButton, 
	className = '', 
	buttonCornerStyle, 
	disabled, 
}) => {
	const divRef = useRef<HTMLDivElement>();
	const btnEl = useRef(null);
	const bodyRef = useRef(appendMenuToRoot ? document.getElementById(IDomIdSelectors.root) : null);
	const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
	const [selMenuItem, setSelMenuItem] = useState<IMenuItem>();
	const width = useMemo(() => typeof w === 'string' ? w : `${w}px`, [w]);
	const height = useMemo(() => typeof h === 'string' ? h : `${h}px`, [h]);
	const menuHeight = useMemo(() => typeof mh === 'string' ? mh : `${mh}px`, [mh]);
	const [styleMenu, setStyleMenu] = useState<React.CSSProperties>();

	useEffect(() => {
		setSelMenuItem(children.filter(item => item.val === value)[0])
	}, [value, setSelMenuItem]); 

	useEffect(()=>{
		if(forcedHideMenu) setMenuIsOpen(false);
	}, [forcedHideMenu])

	useLayoutEffect(()=>{
		let styleObj: React.CSSProperties = {
			width:`${menuWidth? menuWidth : '100%'}`, 
			top: `calc(${height} + 4px)`,  
		};
		if(appendMenuToRoot){
			let {top, left} = btnEl.current.getBoundingClientRect();
			top -= 12; 
			switch(positionMenu){
				case IMenuPosition.left: 
					left -= 165; // menu width (147) + part of button still visible (10) + margin between menu and button (8)
					break; 
				case IMenuPosition.right: 	
					left += 28; // part of button still visible (20) + margin between menu and button (8)
					break;
				default: 
					top += 26;
			}	
			styleObj = {
				...styleObj,
				top, 
				opacity: 1, 
				left, 
				height: menuHeight, 
			}
		}
		else {
			switch(positionMenu){
				case IMenuPosition.left: 
					styleObj.left = 0;
					break; 
				case IMenuPosition.right: 
					styleObj.marginTop = '8px';
					styleObj.right = 0;
					break;
			case IMenuPosition.center: 
			default: 
				break; 
			}
		}
		setStyleMenu(styleObj);
	},[ positionMenu, appendMenuToRoot, setStyleMenu, btnEl]);

	return (
		<div 
			ref={divRef} 
			className={CSS.DropdownContainer} 
			style={{width, height}}
		>
			{ isSelectInput ? 
				<DropdownButton
					ref={btnEl}
					style={{width: '100%', height,  borderRadius: `${buttonCornerStyle? buttonCornerStyle : 5}px`}}
					open={menuIsOpen}
					text={selMenuItem?.text || 'error: missing value'}
					icon={selMenuItem?.icon}
					onClick={() => setMenuIsOpen(prevState => !prevState)}
					disabled={disabled}
				/> : 
				<button
					disabled={disabled}
					className={CSS.DropdownButton}
					style={{visibility: hideButton? 'hidden' : 'visible'}}
					ref={btnEl} 
					onClick={() => setMenuIsOpen(prevState => !prevState)}>
						<ListSmallIcon className={CSS.Icon} />
				</button>
			}
			<PopupMenu
				className={`${CSS.DropdownMenu} ${className}`}
				style={styleMenu}
				multiSelect={!!multiSelect}
				show={menuIsOpen && !disabled}
				targetElRef={btnEl}
				selectedOption={selMenuItem}
				optionData={children}
				onChange={item => onChange?.(item.val)}
				onClose={() => {
					setMenuIsOpen(false); 
					onCloseMenu?.();
				}}
				appendToRef={appendMenuToRoot? bodyRef : divRef}
			/>
		</div>
	);
};

export default Dropdown;
