// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PropertiesContainer--3om6o.Disabled--30e40 .Title--3nkDe{opacity:.33}.Row--2gJ1o{display:flex;justify-content:space-between;align-items:center;margin:8px 0;flex-wrap:wrap}.Row--2gJ1o.FirstRow--yuWOH{margin-top:0}.Row--2gJ1o>.Slider--3eVEY{width:160px;padding-bottom:4px}.Row--2gJ1o>.SliderInput--1zvmM{width:45px;height:24px}.Row--2gJ1o .Title--3nkDe{margin:8px 0;display:flex}.Row--2gJ1o .FullRowTitle--40IC-{margin:8px 0 0 0;display:flex;flex-basis:100%}.Row--2gJ1o .SizeRow--1gy1t,.Row--2gJ1o .SizeRowInner--3VgZi{display:flex;align-items:center;justify-content:space-between}.Row--2gJ1o .SizeRowInner--3VgZi{width:fit-content}.Row--2gJ1o .SizeInputField--1CLRd{width:58px}.PreviewContainer--3uAi9{border:1px solid #b2c4d7;height:160px;border-radius:5px;display:flex;align-items:center;justify-content:center;margin-bottom:8px;margin-top:0px;position:relative}.PreviewContainer--3uAi9 img{max-height:100%;max-width:100%}.PreviewContainer--3uAi9 .HoverDiv--jI34Q{height:100%;width:100%;background-color:rgba(115,180,255,.2);position:absolute;z-index:1;user-select:none;border-radius:5px;background-color:rgba(74,144,226,.2);border:solid 1px #4a90e2;box-shadow:0px 0px 0px 2px #ecf6ff;-webkit-box-shadow:0px 0px 0px 2px #ecf6ff;-moz-box-shadow:0px 0px 0px 2px #ecf6ff;display:flex;flex-direction:column;align-items:center;justify-content:center;gap:1ch;opacity:0}.PreviewContainer--3uAi9 .HoverDiv--jI34Q.Hovered--2srcM{opacity:1}.PreviewContainer--3uAi9 .HoverDiv--jI34Q .TrkImgUploadButton--3e-ZC{width:108px;height:32px;border-radius:6px;border:1px solid #4a90e2;color:#fff}.SkeletonPreviewContainer--1TMt5{border:1px solid #b2c4d7;height:160px;border-radius:5px;display:flex;align-items:center;justify-content:center;margin-bottom:8px;position:relative;overflow:hidden}", ""]);
// Exports
exports.locals = {
	"PropertiesContainer": "PropertiesContainer--3om6o",
	"Disabled": "Disabled--30e40",
	"Title": "Title--3nkDe",
	"Row": "Row--2gJ1o",
	"FirstRow": "FirstRow--yuWOH",
	"Slider": "Slider--3eVEY",
	"SliderInput": "SliderInput--1zvmM",
	"FullRowTitle": "FullRowTitle--40IC-",
	"SizeRow": "SizeRow--1gy1t",
	"SizeRowInner": "SizeRowInner--3VgZi",
	"SizeInputField": "SizeInputField--1CLRd",
	"PreviewContainer": "PreviewContainer--3uAi9",
	"HoverDiv": "HoverDiv--jI34Q",
	"Hovered": "Hovered--2srcM",
	"TrkImgUploadButton": "TrkImgUploadButton--3e-ZC",
	"SkeletonPreviewContainer": "SkeletonPreviewContainer--1TMt5"
};
module.exports = exports;
