import React from "react";
import { useDispatch } from "react-redux";
import { IButton } from "../../../../../../r3f/r3f-components/component-data-structure";
import { IMultipleEntityProps_Cn_Doc } from "../../../../../../../store/actions";
import { onSetMultipleComponentProps_Cn_Doc } from "../../../../../../../store/actions";
import { ENTITY_PREVIEW_HEIGHT } from '../../../../../../../utils';
import TextArea from '../../../../../Inputs/TextArea/TextArea';
import CSS from './ButtonContent.scss'

interface IParentProps {
  entity: IButton;
  entityEditingDisabled: boolean;
  selectedEntityIds: string[];
}

const ButtonContent = ({
  entity,
  entityEditingDisabled,
  selectedEntityIds
}: IParentProps) => {
  const dispatch = useDispatch();

  const updateButtonText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] =
      [];
    for (let i = 0; i < selectedEntityIds.length; i++) {
      multipleEntityProps_Cn_Doc[i] = {
        id: selectedEntityIds[i],
        text: e.target.value,
      };
    }
    dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
  }

  const buttonWithText = () => {
    return (
      <div>
        <div className={CSS.Title}>Text</div> 
        <TextArea
          value={entity.text}
          onChange={(e)=>updateButtonText(e)} />
      </div>
    )
  }

  const buttonWithBackground = () => {
    return (
      <div className={CSS.Subsections}>
        <div className={CSS.Title}>
          Button background
        </div>
          <div
            className={CSS.PreviewDiv}
            style={{
              width: "100%",
              height: `${ENTITY_PREVIEW_HEIGHT}px`,
            }}>
          <img src={entity.textureUrl ? entity.textureUrl : entity.svgUrl} />
          </div>
      </div>
    );
  }

  return entityEditingDisabled ? buttonWithBackground() : buttonWithText();
}

export default React.memo(ButtonContent);