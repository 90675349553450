import { IFileType, IUploadParamsResponse } from '../specs'
import { BaseZmlClient } from './base'
import { RedirectOAuth } from '../../oauth/redirect'
import { BrowserClient } from '../../clients/browser'
import { IProgressCallback } from '../../specs'


export class BrowserZmlClient extends BaseZmlClient<RedirectOAuth> {
  constructor (client: BrowserClient) {
    super(client)
  }

  protected _createFormDataForUpload(file: File, contentType: string, uploadParams: IUploadParamsResponse) {
    const formData = new FormData()
    for (const [name, value] of Object.entries(uploadParams.fields)) {
      formData.append(name, value)
    }
    formData.append('file', file) // this needs to be the last value to append
    return formData
  }

  async uploadFile(fl: File, flType: IFileType, progressCb: IProgressCallback, id?: string | number) {
    const uploadParams = await this.getUploadParamsForFiletype(flType, fl.type);
    const formData = this._createFormDataForUpload(fl, fl.type, uploadParams);
    await this._bClient.requestWithProgress(uploadParams.url, formData, progressCb, id); // fetch API has no progress event listener
    return {
      folder: uploadParams.folder,
      key: uploadParams.fields.key,
      filename: fl.name || ''
    }
  }
}
