// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ColorPickerInputSeparator--3BjOI{width:1px;height:23px;background-color:#b2c4d7;border-top:1px solid #eef6fe;border-bottom:1px solid #eef6fe}", ""]);
// Exports
exports.locals = {
	"ColorPickerInputSeparator": "ColorPickerInputSeparator--3BjOI"
};
module.exports = exports;
