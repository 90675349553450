import React, {
  memo,
  useMemo,
  useEffect,
  FunctionComponent,
  useRef,
} from "react";
import { useThree } from "@react-three/fiber";
import { WebGLRenderer } from "three";
import { OrbitControls } from "@react-three/drei";
import { useRefState } from "../r3f-components/hooks";
import { MIN_ZOOM_LIMIT, MAX_ZOOM_LIMIT, ORTHOGRAPHIC_ZOOM_TO_PERSPECTIVE_POSITION } from "../../../utils";
interface IParentProps {
  zoomLevel: number;
  minZoom: number;
  maxZoom: number;
  is3dMode: boolean;
  projectId: string;
  onZoom: (zoomFactor: number) => any;
  onPanStart: (gl: WebGLRenderer) => any;
  onPanEnd: (gl: WebGLRenderer) => any;
  onUpdate?: (orbitControls: any) => any;
}

const OrbitControlsComp: FunctionComponent<IParentProps> = memo(
  ({
    zoomLevel,
    minZoom,
    maxZoom,
    is3dMode,
    projectId,
    onZoom,
    onPanEnd,
    onUpdate,
    onPanStart,
  }) => {
    const orbitControlsRef = useRef<any>();
    const [_p, setIsPanning, isPanningRef] = useRefState(false);
    const { camera, scene, gl } = useThree();
    // console.log('SCENE: ',scene);
    // console.log('ORBIT CONTROLS - CAMERA POSITION: ',camera.position);
    // console.log('ORBIT CONTROLS - CAMERA TYPE: ',camera.type);
    useEffect(() => {
      // console.log('IS 3D MODE USE EFFECT');
      if (camera.type === 'OrthographicCamera') {
        camera.position.setX(0);
        camera.position.setY(0);
        // console.log('SET X AND Y POSITION TO 0');
      }
    }, [camera.type])

    useEffect(() => {
      const onWheel = (e: any) => {
        // console.log('ON WHEEL - CAMERA POSITION: ',camera.position);
        // console.log('ON WHEEL - CAMERA TYPE: ',camera.type);
        if (!is3dMode) {
          onZoom(camera.zoom);
          localStorage.setItem(`${projectId}_zoom_level`, `${camera.zoom}`);
        } else {
          let oldZoom = Number(localStorage.getItem(`${projectId}_zoom_level`));
          if (e.deltaY > 0) {
            oldZoom--;
          } else oldZoom++;
          if (oldZoom > MAX_ZOOM_LIMIT) oldZoom = MAX_ZOOM_LIMIT;
          if (oldZoom < MIN_ZOOM_LIMIT) oldZoom = MIN_ZOOM_LIMIT;
          onZoom(oldZoom);
          localStorage.setItem(`${projectId}_zoom_level`, `${oldZoom}`);
        }
      };

      const pointerDown = (e: PointerEvent) => {
        // console.log('ON POINTER DOWN - CAMERA POSITION: ',camera.position);
        // console.log('ON POINTER DOWN - CAMERA TYPE: ',camera.type);
        if (e.buttons !== 2) return;
        onPanStart(gl);
        setIsPanning(true);
      };

      const pointerUp = () => {
        // console.log('ON POINTER UP - CAMERA POSITION: ',camera.position);
        // console.log('ON POINTER UP - CAMERA TYPE: ', camera.type);
        if (!isPanningRef.current) return;
        onPanEnd(gl);
        setIsPanning(false);
      };

      gl.domElement.addEventListener("wheel", onWheel);
      gl.domElement.addEventListener("pointerdown", pointerDown);
      gl.domElement.addEventListener("pointerup", pointerUp);

      return () => {
        gl.domElement.removeEventListener("wheel", onWheel);
        gl.domElement.removeEventListener("pointerdown", pointerDown);
        gl.domElement.removeEventListener("pointerup", pointerUp);
      };
    }, [
      onZoom,
      onPanStart,
      setIsPanning,
      onPanEnd,
      is3dMode,
      camera,
    ]);

    const { minPos, maxPos } = useMemo(() => {
      return {
        minPos: ORTHOGRAPHIC_ZOOM_TO_PERSPECTIVE_POSITION * (MIN_ZOOM_LIMIT / zoomLevel),
        maxPos: ORTHOGRAPHIC_ZOOM_TO_PERSPECTIVE_POSITION * (MAX_ZOOM_LIMIT / zoomLevel)
      };
    },[zoomLevel]);

    return (
      <OrbitControls
        ref={orbitControlsRef}
        makeDefault={true}
        onUpdate={onUpdate}
        minZoom={minZoom}
        maxZoom={maxZoom}
        minDistance={minPos}
        maxDistance={maxPos}
        rotation={[0, 0, 45]}
        enableZoom={true}
        enableRotate={is3dMode}
        args={[camera, gl.domElement]}
        enableDamping={false}
      />
    );
  }
);

export default memo(OrbitControlsComp);