import React from "react";
import { IIconParentProps } from "./index";
import { DEFAULT_ICON_COLOR } from "../../utils";

const binIcon: React.SFC<IIconParentProps> = ({
  hexFill: fill,
  viewBox,
  className,
  onClick = () => {},
  title,
}) => {
  const hexFill = fill || DEFAULT_ICON_COLOR;
  return (
    <svg
      onClick={onClick}
      className={className || ""}
      width="18px"
      height="20px"
      viewBox={viewBox ? viewBox.join(" ") : `0 0 18 20`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>

      <g
        id="Expanded"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Bin"
          transform="translate(-11.000000, -10.000000)"
          fill={hexFill}
          fillRule="nonzero"
        >
          <path d="M21.7142857,10 C22.9766509,10 24,10.8954305 24,12 L24,13 L28.5263158,13 C28.7879244,13 29,13.2238576 29,13.5 C29,13.7454599 28.8324341,13.9496084 28.6114611,13.9919443 L28.5263158,14 L26.945,14 L26.2733787,26.2348881 C26.1573022,28.3491386 24.5231993,30 22.5464877,30 L17.4535123,30 C15.4768007,30 13.8426978,28.3491386 13.7266213,26.2348881 L13.055,14 L11.4736842,14 C11.2120756,14 11,13.7761424 11,13.5 C11,13.2545401 11.1675659,13.0503916 11.3885389,13.0080557 L11.4736842,13 L16,13 L16,13 L16,12 C16,10.8954305 17.0233491,10 18.2857143,10 L21.7142857,10 Z M26.0097333,14 L13.9893333,14 L14.6583441,26.1761661 C14.7421771,27.7031248 15.8817569,28.9078087 17.2898886,28.99495 L17.4535123,29 L22.5464877,29 C23.9741127,29 25.1634626,27.8518701 25.3273424,26.3508781 L25.3416559,26.1761661 L26.0097333,14 Z M17.5,18 C17.7454599,18 17.9496084,18.1768752 17.9919443,18.4101244 L18,18.5 L18,24.5 C18,24.7761424 17.7761424,25 17.5,25 C17.2545401,25 17.0503916,24.8231248 17.0080557,24.5898756 L17,24.5 L17,18.5 C17,18.2238576 17.2238576,18 17.5,18 Z M22.5,18 C22.7454599,18 22.9496084,18.1768752 22.9919443,18.4101244 L23,18.5 L23,24.5 C23,24.7761424 22.7761424,25 22.5,25 C22.2545401,25 22.0503916,24.8231248 22.0080557,24.5898756 L22,24.5 L22,18.5 C22,18.2238576 22.2238576,18 22.5,18 Z M21.8,11 L18.2,11 C17.584597,11 17.0773914,11.3860402 17.0080733,11.8833789 L17,12 L17,13 L23,13 L23,12 C23,11.4871642 22.5367518,11.0644928 21.9399454,11.0067277 L21.8,11 Z"></path>
        </g>
      </g>
    </svg>
  );
};

export default binIcon;
