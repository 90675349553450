import React, { FunctionComponent, Suspense, useRef } from 'react';
import * as Sentry from '@sentry/react'
import { MAX_ZOOM_LIMIT, MIN_ZOOM_LIMIT } from '../../../utils';
import { IDesignerState, IDomIdSelectors } from '../../../../typings';
import { Canvas } from '@react-three/fiber';
import { connect, Provider } from 'react-redux';
import { bindActionCreators, Store } from 'redux';
import OrbitControls from '../Controls/OrbitControls';
import TransformControls from '../Controls/TransformControls/TransformControls';
import { 
    IOnChangeZoomLevel, 
    IOnSetCanvasLoaded, 
    onChangeZoomLevel, 
    onSetCanvasLoaded, 
    onAddToast, 
    onSetScaleHotSpotEnabled, 
    onSetBackgroundHotSpotEnabled, 
    IOnSetScaleHotSpotEnabled, 
    IOnSetBackgroundHotSpotEnabled, 
    onRemoveSelection,
    IOnRemoveSelection
} from '../../../store/actions';
import { BackgroundHotspot, EntityDragHotspot, ScaleHotspot } from '../Hotspots';
import ContextMenu from '../ContextMenu/ContextMenu';
import RotationMarker from '../RotationMarker/RotationMarker';
import { ToastsData } from '../../../utils/toasts-data'; 
import ViewGizmo from '../Controls/ViewGizmo'
import { OrthographicCamera, PerspectiveCamera } from '@react-three/drei';
import Lighting from '../r3f-components/components/Lighting/Lighting';
import neutralEnvMapPath from '../r3f-components/components/Lighting/neutral.hdr';

import CSS from './EditorCanvas.scss';

interface IParentProps {
   store: Store;
   children: JSX.Element;
}

interface IReduxProps {
    zoomLevel: number;
    entityMenuDragActive: boolean;
    projectId: string; 
    is3dMode: boolean;
    selectedEntityIds: string[];
}
interface IDispatchProps {
    onChangeZoomLevel: IOnChangeZoomLevel;
    onSetCanvasLoaded: IOnSetCanvasLoaded;
    onRemoveSelection: IOnRemoveSelection;
    onSetScaleHotSpotEnabled: IOnSetScaleHotSpotEnabled;
    onSetBackgroundHotSpotEnabled: IOnSetBackgroundHotSpotEnabled;
}

const EditorCanvas: FunctionComponent<IParentProps & IDispatchProps & IReduxProps> = ({
    store,
    is3dMode,
    children,
    zoomLevel,
    projectId,
    selectedEntityIds,
    onRemoveSelection,
    onChangeZoomLevel,
    onSetCanvasLoaded,
    entityMenuDragActive,
    onSetScaleHotSpotEnabled,
    onSetBackgroundHotSpotEnabled,
}) => {
    const orbitControlsRef = useRef();
    const somethingSelected = selectedEntityIds.length !== 0;

    const onClick = (e: any) => {
        // if hotspot stuck to enabled, disable on canvas click
        onSetScaleHotSpotEnabled(false)
        onSetBackgroundHotSpotEnabled(false)
    }
    
    const onOrbitUpdate = (orbitControls: any) => {
        orbitControlsRef.current = orbitControls;
    }

    return (
        <Sentry.ErrorBoundary 
            fallback={null} 
            onError={() => onAddToast(ToastsData.GeneralError)} 
        >
            <Canvas
                className={CSS.EditorCanvas}
                style={{backgroundColor: '#f8f8f8'}}
                id={IDomIdSelectors.zapparCanvas}
                linear
                dpr={window.devicePixelRatio}
                flat
                gl={{ preserveDrawingBuffer: true }} // needed for canvas snapshots (otherwise empty)
                onClick={onClick}
                onPointerMissed={(e) => {
                    console.log('MISSED');
                    onRemoveSelection();
                }}
                onCreated={() => { onSetCanvasLoaded(true) }}
            >
                <Lighting envMapPath={neutralEnvMapPath}/>
                <Provider store={store}>
                    <Suspense fallback={null}>
                        <OrbitControls 
                            zoomLevel={zoomLevel}
                            is3dMode={is3dMode}
                            minZoom={MIN_ZOOM_LIMIT}
                            maxZoom={MAX_ZOOM_LIMIT}
                            projectId={projectId}
                            onZoom={onChangeZoomLevel}
                            onUpdate={onOrbitUpdate}
                            onPanStart={gl => gl.domElement.style.cursor = 'grabbing'}
                            onPanEnd={gl => gl.domElement.style.cursor = 'default'}
                        />
                        <PerspectiveCamera position={[0, 0, 15]} zoom={1} makeDefault={is3dMode} />
                        <OrthographicCamera position={[0, 0, 5]} zoom={zoomLevel} makeDefault={!is3dMode} />
                        <group position={[0, 0, 0]} name="Editor Canvas Group">
                            {is3dMode && somethingSelected && <TransformControls />}
                            {children}
                            {!is3dMode && <BackgroundHotspot />}
                            {!is3dMode && <ScaleHotspot />}
                            {entityMenuDragActive && <EntityDragHotspot />}
                        </group>
                    </Suspense>
                    {/* <ContextMenu /> */}
                    {!is3dMode && <RotationMarker />}
                    {is3dMode && <ViewGizmo orbitControlsRef={orbitControlsRef}/>}
                    
                    
                </Provider>
            </Canvas>
        </Sentry.ErrorBoundary>
    );
};

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	return {
		zoomLevel: state.userReducer.zoomLevel,
        entityMenuDragActive: state.userReducer.entityMenuDragActive,
        projectId: state.userReducer.project.id,
        is3dMode: state.userReducer.is3dMode,
        selectedEntityIds: state.userReducer.selectedEntityIds,
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onChangeZoomLevel,
			onSetCanvasLoaded,
            onSetScaleHotSpotEnabled,
            onSetBackgroundHotSpotEnabled,
            onRemoveSelection
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorCanvas);