// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../base-classes.scss");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".TextFadeContainer--ZfGwC{height:auto !important;width:calc(100% - 60px - 5px);height:calc(100vh - 65px);align-self:flex-end;padding:25px 27px 19px 16px;position:absolute;top:0;opacity:0;transition:opacity .5s}.TextFadeContainer--ZfGwC.FadeIn--1T-wS{opacity:1}.FontContainer--1-kFC{}.FontContainer--1-kFC>.FontContainerInner--2pJYV{height:100%;width:250px}.StyleDivider--1jSRq{margin-top:24px}.TextContentSubheading--25nIB{width:100%;font-size:14px;margin-bottom:8px}", ""]);
// Exports
exports.locals = {
	"TextFadeContainer": "TextFadeContainer--ZfGwC",
	"FadeIn": "FadeIn--1T-wS",
	"FontContainer": "FontContainer--1-kFC " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["ScrollContainer"] + "",
	"FontContainerInner": "FontContainerInner--2pJYV",
	"StyleDivider": "StyleDivider--1jSRq",
	"TextContentSubheading": "TextContentSubheading--25nIB"
};
module.exports = exports;
