import React from 'react';

import CSS from './DragCntnrSkeleton.scss';

interface IParentProps {
	columns: number;
	className?: string;
	numberDummys: number;
}

const Sceleton: React.SFC<IParentProps> = ({
	columns,
	className = '',
	numberDummys,
}) => {
	let classes = [CSS.Skeleton, className];
	let dummyPreviews: any[] = [];

	const widthPerc = 100 / columns;
	const widthAdjPixels = 8 - 8 / columns;
	const width = `calc(${widthPerc}% - ${widthAdjPixels}px)`;

	const gridTemplateColumns = Array(columns)
		.fill(width)
		.join(' ');

	for (let i = 0; i < numberDummys; i++) {
		dummyPreviews.push(
			<div
				key={i}
				className={CSS.DummyPreview}
				style={{ height: `${192 / columns}px` }}
			/>
		);
	}

	return (
		<div className={classes.join(' ')}>
			<div className={CSS.LightShimmer} />
			<div className={CSS.FadeGradient} />
			<div className={CSS.UploadBtnDummy} />
			<div className={CSS.SearchbarDummy} />
			<div className={CSS.DummyGrid} style={{ gridTemplateColumns }}>
				{dummyPreviews}
			</div>
		</div>
	);
};

export default Sceleton;
