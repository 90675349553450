import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { IDesignerState } from '../../../../../../../../typings';
import { getActiveSceneComponent } from '../../../../../../../store/selectors';
import { onSetActiveSceneChildren_Cn_Doc } from '../../../../../../../store/actions';
import { moveArrayItemsByOffset } from '../../../../../../../utils/general';
import { MoveForwardIcon, MoveBackIcon, MoveToBackIcon, MoveToFrontIcon } from '../../../../../../../assets/icons';
import CSS from './TransformsOrder.scss'
interface IParentProps {
  entityIsLocked: boolean;
}

const TransformsOrder = ({
  entityIsLocked
}: IParentProps) => {
  // Redux
  const dispatch = useDispatch();
  const activeScene = useSelector((state: IDesignerState) => getActiveSceneComponent(state));
  const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
  const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId);

  // Derived
  const sortedSelelectedIds = activeScene?.children.filter(id => selectedEntityIds.includes(id));
	const highestRenderOrder = activeScene?.children.indexOf(sortedSelelectedIds[sortedSelelectedIds.length - 1]) + 1;
	const lowestRenderOrder = activeScene?.children.indexOf(sortedSelelectedIds[0]) + 1;

  // Content
  return (
      <div className={CSS.InputRow}>
      <div className={CSS.InputRowTitle}>
        <span>Order</span>
      </div>
      <button
        className={CSS.MoveForward}
        disabled={entityIsLocked || !selectedEntityIds.length || highestRenderOrder === activeScene?.children.length}
        onClick={() => {
          const selIds = activeScene.children.filter(id => selectedEntityIds.includes(id))
          const unSelIds = activeScene.children.filter(id => !selectedEntityIds.includes(id))
          dispatch(onSetActiveSceneChildren_Cn_Doc({ sceneChildren: [...unSelIds, ...selIds], activeSceneId }));
        }}
      >
        <MoveToFrontIcon title={"Move to front"} />
      </button>
      <button
        disabled={entityIsLocked || !selectedEntityIds.length || highestRenderOrder === activeScene?.children.length}
        className={CSS.MoveForward}
        onClick={() => {
          const newChildArray = moveArrayItemsByOffset(activeScene.children, selectedEntityIds, 2)
          dispatch(onSetActiveSceneChildren_Cn_Doc({ sceneChildren: newChildArray, activeSceneId }));
        }}
      >
        <MoveForwardIcon title={"Move forward"} />
      </button>
      <button
        disabled={entityIsLocked || !selectedEntityIds.length || lowestRenderOrder === 1}
        className={CSS.MoveBack}
        onClick={() => {
          const newChildArray = moveArrayItemsByOffset(activeScene.children, selectedEntityIds, -1);
          dispatch(onSetActiveSceneChildren_Cn_Doc({ sceneChildren: newChildArray, activeSceneId }));
        }}
      >
        <MoveBackIcon title={"Move backward"} />
      </button>
      <button
        // style={{marginRight: '16px'}}
        disabled={entityIsLocked || !selectedEntityIds.length || lowestRenderOrder === 1}
        className={CSS.MoveBack}
        onClick={() => {
          const selIds = activeScene.children.filter(id => selectedEntityIds.includes(id));
          const unSelIds = activeScene.children.filter(id => !selectedEntityIds.includes(id));
          dispatch(onSetActiveSceneChildren_Cn_Doc({ sceneChildren: [...selIds, ...unSelIds], activeSceneId }));
        }}
      >
        <MoveToBackIcon title={"Move to back"} />
        </button>
        </div>
   );
}

export default React.memo(TransformsOrder);