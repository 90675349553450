// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Toasts--cvqtJ{position:fixed;max-width:672px;bottom:0;left:50%;z-index:1000;overflow:visible;transform:translateX(-50%)}", ""]);
// Exports
exports.locals = {
	"Toasts": "Toasts--cvqtJ"
};
module.exports = exports;
