import React, { FunctionComponent, useMemo } from 'react';
import uuid4 from 'uuid/v4';
import { SOCIAL_PROVIDERS } from '../../../../../../r3f/r3f-components/utils/constants';
import { IButtonDisplayState, IFontTypes, ITextAlignment } from '../../../../../../r3f/r3f-components/component-data-structure';
import CSS from './LargeSocialBtnGrid.scss';
import { useIsDoubleClick } from '../../../../../../../utils';

interface ISocialBtnMetaProps  {
	style: React.CSSProperties,
}

interface ISocialBtnProperties extends IButtonDisplayState, ISocialBtnMetaProps {}

interface IParentProps {
	onPointerDown?: (btnInfo: IButtonDisplayState, e: React.PointerEvent<HTMLDivElement>) => any;
	onDoubleClick?: (btnInfo: IButtonDisplayState, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
}

// Height reference is the approximate height of a non-social button and we are scaling the social buttons relative to this, so they appear on the canvas with the same height and correct aspect ratio
const heightReference = 0.13;
const btnSharedProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
  fontFamily: IFontTypes.Roboto,
  fontSize: 0.11,
  fontRgba: [255, 255, 255, 0],
  text: "",
  color: [255, 255, 255, 0],
  borderRadius: 0,
  borderWidth: 0,
  borderRgba: [150, 191, 239, 1],
  textAlignment: ITextAlignment.center,
};

const btnSharedStyle = {
  display: "flex",
  cursor: "pointer",
  backgroundRepeat: "no-repeat",
  backgroundPositionX: "center",
  backgroundPositionY: "center",
  backgroundPosition: "50% 50%",
  backgroundSize: "contain",
};

const socialBtnInfo: ISocialBtnProperties[] = SOCIAL_PROVIDERS.reduce(
  (acc, socialProvider) => {
    if (socialProvider.largeIconUrl) {
      acc.push({
        style: {
          ...btnSharedStyle,
          backgroundImage: `url(${socialProvider.largeIconUrl})`,
          width: socialProvider.largeIconW,
          height: socialProvider.largeIconH,
        },
        svgUrl: socialProvider.largeIconUrl,
        scale: [
          socialProvider.largeIconW *
            (heightReference / socialProvider.largeIconH) +
            0.02,
          heightReference,
          0,
        ],
        ...btnSharedProps,
      });
    }
    return acc;
  },
  []
);

const LargeSocialBtnGrid: FunctionComponent<IParentProps> = ({
	onPointerDown,
	onDoubleClick
}) => {
  const isDoubleClick = useIsDoubleClick();
	const btnInfo = useMemo(() => socialBtnInfo.map(btnDft => { return {...btnDft, id: uuid4()}}), [])
	const SocialElements = btnInfo.map(opt => {
		return (
			<div
				id={opt.id}
				key={opt.id}
				style={opt.style}
				onPointerDown={e => {
          if (isDoubleClick()) onDoubleClick?.(opt, e)
          else onPointerDown?.(opt, e);
        }}
			>
        {opt.text}
			</div>
		);
	});

	return (
        <div className={CSS.Grid}>
            {SocialElements}
        </div>
    );
};

export default LargeSocialBtnGrid;
