// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Btn--262qk{display:flex;flex-direction:column;justify-content:center;align-items:center;user-select:none;margin:0;padding:0;width:100%;outline:none;border-style:none;cursor:pointer;pointer-events:inherit}", ""]);
// Exports
exports.locals = {
	"Btn": "Btn--262qk"
};
module.exports = exports;
