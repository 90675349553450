import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import {
  TickIcon,
  WarningIcon,
  BinIcon,
  HourglassIcon,
} from "../../../../../assets/icons";
import { onRemoveUpload } from "../../../../../store/actions/redux-actions";
import Progressbar from "../../../Progressbar/Progressbar";
import { IUploadInfoDict, ITrkImgData } from "../../../../../../typings";
import { ILoadedMediaTypes } from "../../../../../store/actions";
import { IProgressStatus } from "zw-api-client/src/specs";
import { IErrorText } from "../../specs";

import CSS from "./InfoRow.scss";

interface IParentProps {
  id: string;
  currentUploads: IUploadInfoDict;
  trkImgData: ITrkImgData | null;
  uploadStatus: "error" | "processing" | "success";
}

const InfoRow: React.FunctionComponent<IParentProps> = ({
  id,
  currentUploads,
  trkImgData,
  uploadStatus,
}) => {
  let { errorText, fileName, perc, type, mediaType } = currentUploads[id];
  const dispatch = useDispatch();

  // Debug - Remove after testing on staging
  // type = IProgressStatus.error;
  // errorText = IErrorText.formatNotSupported;
  // trkImgData = {
  // 	quality: 1,
  // 	compositeUrl: ''
  // }
  // end debug

  const renderDescription = () => {
    switch (type) {
      case IProgressStatus.trainingCompleted:
        const displayClassName = trkImgData?.quality === 1 ? CSS.ErrorText : "";
        const displayText =
          trkImgData?.quality === 1 ? "Low Quality Image" : "Training complete";
        return (
          <>
            <div className={displayClassName}>{displayText}</div>
            <a
              className={CSS.InfoLink}
              target="_blank"
              href="https://docs.zap.works/general/design/what-makes-good-tracking-image/"
            >
              Learn more
            </a>
          </>
        );

      case IProgressStatus.completed:
        return <div>Saved in Library</div>;

      case IProgressStatus.error:
        const error =
          errorText === IErrorText.formatNotSupported
            ? `${errorText?.charAt(0).toUpperCase()}${errorText?.slice(1)}`
            : "Upload failed, please try again";
        let errorLink: string;
        switch (mediaType) {
          case ILoadedMediaTypes.image:
            errorLink =
              "https://docs.zap.works/designer/components/image/#supported-image-formats";
            break;
          case ILoadedMediaTypes.video:
            errorLink =
              "https://docs.zap.works/designer/components/video/#supported-video-formats";
            break;
          case ILoadedMediaTypes.audio:
            errorLink =
              "https://docs.zap.works/designer/audio/#supported-audio-formats";
            break;
        }

        return (
          <>
            <div className={CSS.ErrorText}>{error}</div>
            {errorText === IErrorText.formatNotSupported && (
              <a
                className={CSS.ErrorLink}
                target="_blank"
                rel="noreferrer"
                href={errorLink}
              >
                Supported formats
              </a>
            )}
          </>
        );

      default:
        return (
          <div className={CSS.FileProgressContainer}>
            <Progressbar perc={perc} className={CSS.ProgressBar} />
            <span>{`${type} ${Math.round(perc)}%`}</span>
          </div>
        );
    }
  };

  const renderBin = () => {
    return (
      <div className={CSS.BinIconContainer}>
        <BinIcon
          hexFill={"#344b60"}
          onClick={() => dispatch(onRemoveUpload(id))}
        />
      </div>
    );
  };

  const renderTickIcon = () => {
    return (
      <div className={CSS.TickContainerOuter}>
        <div className={CSS.TickContainer}>
          <TickIcon hexFill={"#FFFFFF"} />
        </div>
      </div>
    );
  };

  const renderStatusIcon = () => {
    if (uploadStatus === "error") return <WarningIcon hexFill={"#FF0000"} />;
    if (uploadStatus === "processing") return <HourglassIcon />;
    if (uploadStatus === "success") return renderTickIcon();
  };

  return (
    <div className={CSS.UploadRow} key={id}>
      <div className={CSS.FileInfoContainer}>
        <div className={CSS.ImageContainer}>{renderStatusIcon()}</div>
        <span className={CSS.ImageName}>{fileName}</span>
      </div>
      <div className={CSS.FeedBackContainer}>{renderDescription()}</div>
      {uploadStatus === "error" && renderBin()}
    </div>
  );
};

export default memo(InfoRow);