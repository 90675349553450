// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TabRow--2KzoQ{display:flex;flex-direction:row;width:100%;justify-content:center;align-items:center}", ""]);
// Exports
exports.locals = {
	"TabRow": "TabRow--2KzoQ"
};
module.exports = exports;
