// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../../../base-classes.scss");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../../../assets/emptyZmlDropImg.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".FadeContainer--3DQE1{height:calc(100% - 40px);width:100%;transition:opacity .5s;opacity:0}.FadeContainer--3DQE1.FadeIn--3c-wm{opacity:1}.FadeContainer--3DQE1 .Dropzone--11psH{height:100%;width:100%;display:flex;flex-direction:column}.EmptyZmlZone--2Nf_N{border:1px dashed #b2c4d7;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position-x:center;background-position-y:center;flex-grow:2}.ScrollContainer--jzb1Z{}", ""]);
// Exports
exports.locals = {
	"FadeContainer": "FadeContainer--3DQE1",
	"FadeIn": "FadeIn--3c-wm",
	"Dropzone": "Dropzone--11psH",
	"EmptyZmlZone": "EmptyZmlZone--2Nf_N",
	"ScrollContainer": "ScrollContainer--jzb1Z " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["ScrollContainer"] + ""
};
module.exports = exports;
