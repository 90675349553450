import React, { Fragment, memo, useMemo } from 'react';
import Divider from '../../../Divider/Divider';
import { DEFAULT_ICON_COLOR } from '../../../../../utils';
import { IEntityDrawerTypes } from '../../../../r3f/r3f-components/component-data-structure';
import {
	HandIcon,
	TextIcon,
	ImageIcon,
	VideoIcon,
	IIconParentProps,
	CubeIcon,
} from '../../../../../assets/icons/index';

import CSS from './ButtonRow.scss';
import { useRefState } from '../../../../r3f/r3f-components/hooks';

interface IEntityMenuBtnData {
	id: IEntityDrawerTypes;
	text: string;
	icon: React.FunctionComponent<IIconParentProps>;
}

interface IParentProps {
	openSubMenu: IEntityDrawerTypes;
	setOpenSubMenu: any;
}


const EntityMenuButtonRow: React.FunctionComponent<IParentProps> = ({
	setOpenSubMenu,
	openSubMenu
}) => {
	const [hoveredBtn, setHoveredBtn, hoveredBtnRef] = useRefState<string>('');
	let entityMenuBtnData: IEntityMenuBtnData[] = [
		{
			id: IEntityDrawerTypes.button,
			text: 'Button',
			icon: HandIcon,
		},
		{
			id: IEntityDrawerTypes.text,
			text: 'Text',
			icon: TextIcon,
		},
		{
			id: IEntityDrawerTypes.image,
			text: 'Image',
			icon: ImageIcon,
		},
		{
			id: IEntityDrawerTypes.video,
			text: 'Video',
			icon: VideoIcon,
		}, {
			id: IEntityDrawerTypes.model3d,
			text: '3D',
			icon: CubeIcon
		}
	];

	const clickHandler = (drawerId: IEntityDrawerTypes) => {
		if (openSubMenu === drawerId) setOpenSubMenu(IEntityDrawerTypes.none);
		else setOpenSubMenu(drawerId);
	};

	const buttons = useMemo(() => {
		return entityMenuBtnData.map((btn, i) => {
			const isSelected = openSubMenu === btn.id;
			const isHovered = hoveredBtnRef.current === btn.id;
			return (
				<Fragment key={btn.id}>
					<div
						id={btn.id}
						className={CSS.BtnContainer}
						onClick={() => clickHandler(btn.id)}
						onPointerOver={() => setHoveredBtn(btn.id)}
						onPointerLeave={() => setHoveredBtn('')}
					>
						<btn.icon
							hexFill={`${isSelected || isHovered ? '#4a90e2' : DEFAULT_ICON_COLOR}`}
						/>
						<span style={{color: `${isSelected || isHovered ? '#4a90e2' : ''}`}}>{btn.text}</span>
					</div>
					{i !== entityMenuBtnData.length - 1 ? <Divider width="24px" /> : null}
				</Fragment>
			);
		})
	}, [openSubMenu, clickHandler, setHoveredBtn])

	return (
		<div className={CSS.ButtonRow}>
			{buttons}
		</div>
	);
};

export default memo(EntityMenuButtonRow);
