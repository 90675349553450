
export interface UserResponse {
  name: string
}

export interface ProjectResponse {
  created: ProjectVersion,
  versions: ProjectVersion[]
  url: string
}


interface ProjectVersion {
  name: string,
  url: string,
  date: string,
  user: string,
  qrCodeUrl: string,
}


// TODO: check for other states
enum PreviewStatus {
  success = 'success',
  error = 'error'
}

export interface IZapcodeData {
  code: string;
  svgURL: string
}

export interface PreviewResponse {
  status: PreviewStatus;
  statusURL: string;
  zapcode: IZapcodeData;
}
