// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FlexContainer--1p-e-{display:flex;flex-direction:column;align-items:center}.ProviderLink--YcY6S{display:flex;flex-direction:row;align-items:center;justify-content:center;color:#5ea4e9;text-decoration:none;margin:8px 0 24px 0;font-size:16px;width:fit-content}.ProviderIcon--2eL0a{height:32px;width:32px;margin-right:16px}.EditBtn--tq3U-{width:100%;height:36px}.Description--34Zg2{color:#5c5c5c;font-size:14px;width:100%}.LinkContainerDiv--2Sp4-{overflow:hidden;width:100%;position:relative}.GradientDiv--wgSmO{position:absolute;top:0;right:0;height:100%;width:10%;background-image:linear-gradient(to right, rgba(255, 255, 255, 0), white)}", ""]);
// Exports
exports.locals = {
	"FlexContainer": "FlexContainer--1p-e-",
	"ProviderLink": "ProviderLink--YcY6S",
	"ProviderIcon": "ProviderIcon--2eL0a",
	"EditBtn": "EditBtn--tq3U-",
	"Description": "Description--34Zg2",
	"LinkContainerDiv": "LinkContainerDiv--2Sp4-",
	"GradientDiv": "GradientDiv--wgSmO"
};
module.exports = exports;
