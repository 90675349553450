import React from 'react';
import { IUpdateVideoStateByIds } from '../hooks';

export type IPauseVideos = (excludedIds?: string[]) => void;

interface ISceneVideoState {
    videoStateDict: {[id: string]: boolean};
    updateVideoStateDict: IUpdateVideoStateByIds;
    pauseVideos: IPauseVideos;
}

const VideoStateContext = React.createContext<null | ISceneVideoState>(null);
VideoStateContext.displayName = 'VideoPlayContext'; // useful for react dev tools

export { VideoStateContext };