// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OuterContainer--3XPEV .Description--3Kl1r{color:#5c5c5c;font-size:14px;margin:16px 0 8px 0}.OuterContainer--3XPEV .SocialInputContainer--2b2Eg{display:flex;flex-direction:column}.OuterContainer--3XPEV .BtnGridContainer--2Fj3u{display:grid;grid-column-gap:14px;grid-row-gap:14px;grid-template-columns:auto auto auto}.OuterContainer--3XPEV .BtnGridContainer--2Fj3u .SocialBtn--3EzJn{border:1px solid #efefef;border-radius:5px;height:63px;width:63px;display:flex;align-items:center;justify-content:center}.OuterContainer--3XPEV .BtnGridContainer--2Fj3u .SocialBtn--3EzJn.Active--2mV_i{border-color:blue}.OuterContainer--3XPEV .BtnGridContainer--2Fj3u img{height:35px;width:35px}.ProfileError--1pha8{font-size:12px;font-weight:bold}", ""]);
// Exports
exports.locals = {
	"OuterContainer": "OuterContainer--3XPEV",
	"Description": "Description--3Kl1r",
	"SocialInputContainer": "SocialInputContainer--2b2Eg",
	"BtnGridContainer": "BtnGridContainer--2Fj3u",
	"SocialBtn": "SocialBtn--3EzJn",
	"Active": "Active--2mV_i",
	"ProfileError": "ProfileError--1pha8"
};
module.exports = exports;
