import { IProto } from "../hooks/useTextureLoader";

type PromiseCache<T> = {
  promise: Promise<void>
  url: string,
  error?: any,
  proto?: IProto<T>,
  response?: T
}

type PromiseFn<T> = (proto: IProto<T>, url: string) => Promise<T>

const globalCache: PromiseCache<any>[] = []

function handleAsset<T>(
  fn: PromiseFn<T>,
  cache: PromiseCache<T>[],
  throwError: boolean,
  url: string,
  proto?: IProto<T>,
) {
  for (const entry of cache) {
    // Find a match
    if (url === entry.url) {
      // If an error occurred, throw
      if (entry.error && throwError) throw entry.error
      // If an error occurred but throwing suppressed, return
      if (entry.error && !throwError) return null
      // If a response was successful, return
      if (entry.response) return entry.response
      // If the promise is still unresolved, throw
      throw entry.promise
    }
  }

  // The request is new or has changed.
  const entry: PromiseCache<T> = {
    url,
    proto,
    promise:
      // Make the promise request.
      fn(proto as IProto<T>, url)
        // Response can't be undefined or else the loop above wouldn't be able to return it
        // This is for promises that do not return results (delays for instance)
        .then((response) => (entry.response = (response ?? true) as T))
        .catch((e) => (entry.error = e ?? 'unknown error')),
  }
  cache.push(entry)
  throw entry.promise
}

function loadAsset<T>(fn: PromiseFn<T>, url: string, throwError = false, proto: IProto<any>): T {
  return handleAsset(fn, globalCache as PromiseCache<T>[], throwError, url, proto) as T;
}

export { loadAsset }