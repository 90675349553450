import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const pauseIcon: React.SFC<IIconParentProps> = ({
	hexFill: fill,
	viewBox,
	className,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="8px"
			height="17px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 8 17`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g
				id="Components"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
				strokeLinecap="round"
			>
				<g
					id="Atom/Icon/Controls/Play-Dark-Copy"
					transform="translate(-16.000000, -12.000000)"
					stroke={hexFill}
				>
					<g id="Group" transform="translate(16.000000, 12.000000)">
						<line x1="0.5" y1="0.5" x2="0.5" y2="16.4666667" id="Line"></line>
						<line x1="7.5" y1="0.5" x2="7.5" y2="16.5" id="Line-Copy"></line>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default pauseIcon;
