// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FlexContainer--2WjT5{display:flex;flex-direction:column;align-items:center;margin-bottom:12px}.FlexContainer--2WjT5 .FlexRow--2E2x9{display:flex;flex-direction:row;width:100%;margin:12px 0}.FlexContainer--2WjT5 .FlexRow--2E2x9.FirstRow--1j0Ut{margin-top:unset}.FlexContainer--2WjT5 .FlexRow--2E2x9 .TextLine--8GEsw,.FlexContainer--2WjT5 .FlexRow--2E2x9 .Description--GZ054{margin-left:16px}.OverallDescription--2HdEq{color:#5c5c5c;font-size:14px;margin:2px 0 16px 0}", ""]);
// Exports
exports.locals = {
	"FlexContainer": "FlexContainer--2WjT5",
	"FlexRow": "FlexRow--2E2x9",
	"FirstRow": "FirstRow--1j0Ut",
	"TextLine": "TextLine--8GEsw",
	"Description": "Description--GZ054",
	"OverallDescription": "OverallDescription--2HdEq"
};
module.exports = exports;
