import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const playIcon: React.SFC<IIconParentProps> = ({
	hexFill: fill,
	viewBox,
	className,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="14px"
			height="15px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 14 15`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<path
				fill={hexFill}
				d="M16.3264524,11.0016659 L10.9990389,20.2358492 C10.8237083,20.5397556 10.7314101,20.8844381 10.7314101,21.2352941 C10.7314101,22.3398636 11.6268406,23.2352941 12.7314101,23.2352941 L23.386237,23.2352941 C23.737093,23.2352941 24.0817755,23.1429959 24.3856819,22.9676653 C25.3424441,22.4156871 25.6705863,21.1926114 25.1186082,20.2358492 L19.7911947,11.0016659 C19.6155383,10.6971947 19.3627396,10.4443961 19.0582684,10.2687396 C18.1015063,9.71676145 16.8784305,10.0449037 16.3264524,11.0016659 Z M18.558546,11.1349252 C18.7107816,11.2227534 18.8371809,11.3491528 18.9250091,11.5013883 L24.2524226,20.7355717 C24.5284117,21.2139528 24.3643405,21.8254906 23.8859594,22.1014797 C23.7340062,22.189145 23.561665,22.2352941 23.386237,22.2352941 L12.7314101,22.2352941 C12.1791253,22.2352941 11.7314101,21.7875789 11.7314101,21.2352941 C11.7314101,21.0598661 11.7775592,20.8875249 11.8652245,20.7355717 L17.1926379,11.5013883 C17.468627,11.0230073 18.0801649,10.8589361 18.558546,11.1349252 Z"
				transform="rotate(90 17.059 6.618)"
			/>
		</svg>
	);
};

export default playIcon;
