import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TextInput } from '../../../../Inputs';
import {
	onSetMultipleEntityProps_Ed_Doc,
	IOnSetMultipleEntityProps_Ed_Doc,
	ISceneSnapshotDict,
} from '../../../../../../store/actions';

import CSS from './Properties.scss';
import { IDesignerState } from '../../../../../../../typings';
import { SceneIcon } from '../../../../../../assets/icons';

interface IParentProps {
	sceneId: string;
	sceneTitle: string;
	snapshotDict: ISceneSnapshotDict;
}

interface IReduxProps {}

interface IDispatchProps {
	onSetMultipleEntityProps_Ed_Doc: IOnSetMultipleEntityProps_Ed_Doc;
}

const Properties: React.SFC<IParentProps & IReduxProps & IDispatchProps> = ({
	sceneId,
	sceneTitle,
	snapshotDict,
	onSetMultipleEntityProps_Ed_Doc,
}) => {
	const dataUrl = snapshotDict[sceneId];

	return (
		<>
			{/* <div className={CSS.Description}>Name</div> */}
			<TextInput
				value={sceneTitle}
				onFinalChange={e =>
					onSetMultipleEntityProps_Ed_Doc([
						{
							id: sceneId,
							titles: e.currentTarget.value,
						},
					])
				}
			/>
			{/* <div className={CSS.ScenePreviewDiv}>
				{dataUrl ? (
					<img src={dataUrl} />
				) : (
					<SceneIcon hexFill={'#4A90E2'} />
				)}
			</div> */}
		</>
	);
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onSetMultipleEntityProps_Ed_Doc,
		},
		dispatch
	);
};

export default connect(null, mapDispatchToProps)(Properties);
