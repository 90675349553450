// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ColorPickerOpacityInput--3Xitk{width:45px;margin-right:8px}", ""]);
// Exports
exports.locals = {
	"ColorPickerOpacityInput": "ColorPickerOpacityInput--3Xitk"
};
module.exports = exports;
