import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../../typings';
import { ITriggerTypes,	IActionCategory,	ISocialOptions,	IPageEditorLinkOptions,	IComponentType,} from '../../../../../r3f/r3f-components/component-data-structure';
import { onSetActionData_Global, } from '../../../../../../store/actions';
import NoActionContent from './NoAction/NoAction';
import SocialLinkContent from '../../Shared/Actions/SocialLink/SocialLink';
import SceneLinkContent from './SceneLink/SceneLink';
import PageLinkContent from '../../Shared/Actions/PageLink/PageLink';
import DialNumberContent from './DialNumber/DialNumber';
import ComposeEmailContent from './ComposeEmail/ComposeEmail';
import PlaySoundContent from './PlaySound/PlaySound';
import GhostBtn from '../../../../buttons/GhostBtn/GhostBtn';
import Dropdown from '../../../../menus/Dropdown/SelectDropdown/SelectDropdown';
import { IMenuItem } from '../../../MenuList/MenuList';
import SaveContactContent from '../Actions/SaveContact/SaveContact';
import { 	PhoneContactIcon,	ExpandIcon,	HashIcon, MailContactIcon, SceneIcon, SoundIconSmall,	WebsiteIcon, ContactIcon,	NoActionIcon } from '../../../../../../assets/icons';
import CSS from './Actions.scss';

let baseActionCat: IMenuItem[] = [
	{ text: 'No action', val: IActionCategory.noAction, icon: NoActionIcon },
	{ text: 'Links to scene', val: IActionCategory.linkScene, icon: SceneIcon },
	{ text: 'Links to social network', val: IActionCategory.linkSocial, icon: HashIcon },
	{ text: 'Links to web page', val: IActionCategory.linkPage, icon: WebsiteIcon },
];

let	advancedActionCat: IMenuItem[] = [
	{ text: 'Dials phone number', val: IActionCategory.dialNumber, icon: PhoneContactIcon },
	{ text: 'Plays sound', val: IActionCategory.playSound, icon: SoundIconSmall },
	{ text: 'Composes email', val: IActionCategory.composeEmail, icon: MailContactIcon },
	{ text: 'Saves contact', val: IActionCategory.saveContact, icon: ContactIcon } 
];

const ActionsContent: React.FunctionComponent = () => {
	// Redux & Derived
	const dispatch = useDispatch();
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const id = selectedEntityIds[0];
	const entityDict = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const actionsDict = useSelector((state: IDesignerState) => state.editorReducer.editorDoc?.actions?.[id] || {});
	const activeTrigger = useSelector((state: IDesignerState) => state.editorReducer.editorDoc.activeTriggers?.[id] || ITriggerTypes.onLoad);
	const activeAction = actionsDict?.[activeTrigger]?.selectedActionCategory || IActionCategory.noAction;
	const entityLinkActionData = actionsDict?.[activeTrigger]?.socialOptions || ({} as ISocialOptions);
	const pageLinkData = actionsDict?.[activeTrigger]?.linkPageOptions || ({} as IPageEditorLinkOptions);
	const entity = entityDict[selectedEntityIds[0]];

	// State
	const [showActionSummary, setShowActionSummary] = useState<boolean>(activeAction && activeAction !== IActionCategory.noAction);
	const [openedAction, setOpenedAction] = useState<IActionCategory | null>(null);
	const [pageLinkStateFromSocials, setPageLinkStateInputFromSocials] = useState<string | null>(null);

	// Effects
	useEffect(() => {
		if (activeAction && activeAction !== IActionCategory.noAction) setShowActionSummary(true);
		else setShowActionSummary(false);
	}, [activeAction, selectedEntityIds, setShowActionSummary, setOpenedAction]);


	useEffect(() => {
		setOpenedAction(null)
	}, [setOpenedAction, selectedEntityIds])

	const actionCategories = useMemo(() => {
		switch (entity.type) {
			case IComponentType.Video:
				return baseActionCat;
			case IComponentType.Button:
			case IComponentType.Text:
				return [
					...baseActionCat, 
					...advancedActionCat
				];
			case IComponentType.Image:
				return [
					...baseActionCat, 
					...advancedActionCat, 
					{ text: 'Enlarges', val: IActionCategory.enlarge, icon: ExpandIcon }
				];
			default:
				return baseActionCat;
		}
	}, [entity.type])

	const showEditButton = useMemo(() => {
		return showActionSummary &&
			openedAction !== IActionCategory.playSound &&
			activeAction !== IActionCategory.playSound &&
			openedAction !== IActionCategory.noAction &&
			activeAction !== IActionCategory.noAction;
	}, [showActionSummary, activeAction, openedAction])


	const setPageLinkInputFromSocials = useCallback((socialInputValue: string) => {
		setOpenedAction(IActionCategory.linkPage);
		setPageLinkStateInputFromSocials(socialInputValue);
	}, []);

	const renderActionsContent = useCallback(() => {
		switch (openedAction || activeAction) {
			case IActionCategory.linkSocial:
				return (
					<SocialLinkContent
						targetIds={selectedEntityIds}
						triggerType={ITriggerTypes.onTap}
						setShowActionSummary={setShowActionSummary}
						showActionSummary={showActionSummary}
						setPageLinkInputFromSocials={setPageLinkInputFromSocials}
						socialLinkActions={entityLinkActionData}
					/>
				);
			case IActionCategory.linkScene:
				return (
					<SceneLinkContent
						triggerType={ITriggerTypes.onTap}
						setShowActionSummary={setShowActionSummary}
						showActionSummary={showActionSummary}
					/>
				);
			case IActionCategory.playSound:
				return (
					<PlaySoundContent
						triggerType={ITriggerTypes.onTap}
						setShowActionSummary={setShowActionSummary}
						showActionSummary={showActionSummary}
						setOpenedAction={setOpenedAction}
					/>
				);
			case IActionCategory.linkPage:
				return (
					<PageLinkContent
						targetIds={selectedEntityIds}
						triggerType={ITriggerTypes.onTap}
						setShowActionSummary={setShowActionSummary}
						showActionSummary={showActionSummary}
						inputFromSocials={pageLinkStateFromSocials}
						editorPageLinkOptions={pageLinkData}
					/>
				);
			case IActionCategory.dialNumber:
				return (
					<DialNumberContent
						triggerType={ITriggerTypes.onTap}
						setShowActionSummary={setShowActionSummary}
						showActionSummary={showActionSummary}
					/>
				);
			case IActionCategory.composeEmail:
				return (
					<ComposeEmailContent
						triggerType={ITriggerTypes.onTap}
						setShowActionSummary={setShowActionSummary}
						showActionSummary={showActionSummary}
					/>
				);
			case IActionCategory.saveContact: 
				return(
					<SaveContactContent
						triggerType={ITriggerTypes.onTap}
						setShowActionSummary={setShowActionSummary}
						showActionSummary={showActionSummary}
					/>
				)
			case IActionCategory.enlarge:
				return showActionSummary ? 
					<div style={{marginBottom: '16px'}}>The image will enlarge on tap.</div> :
					<div style={{margin: '16px 0'}}>Tapping the image will enlarge it to fullscreen.</div>
			default:
				return <NoActionContent selectedEntityIds={selectedEntityIds} />;
		}
	}, [
		openedAction, 
		activeAction, 
		selectedEntityIds, 
		showActionSummary, 
		entityLinkActionData, 
		pageLinkStateFromSocials,
		pageLinkData,
		setShowActionSummary, 
		setPageLinkInputFromSocials, 
		setOpenedAction, 
		onSetActionData_Global, 
		onSetActionData_Global,
	]);

	return (
		<div className={CSS.ActionsFlexContainer}>
			{!showActionSummary &&
				<>
					<span className={CSS.Description}>
						{entity.type === IComponentType.Video ? 'On Finish:' : 'On Tap:'}
					</span>
					<Dropdown
						value={openedAction || activeAction}
						onChange={val => {
							if (val === IActionCategory.enlarge) {
								dispatch(onSetActionData_Global({
									ids: selectedEntityIds,
									triggerType:ITriggerTypes.onTap,
									selectedActionCategory: IActionCategory.enlarge,
								}));
							}
							setOpenedAction(val as IActionCategory)
						}}
					>
						{actionCategories}
					</Dropdown>
				</>
			}
			{renderActionsContent()}
			{showEditButton &&
				<GhostBtn
					className={CSS.EditBtn}
					onClick={() => setShowActionSummary(false)}
				>
					Edit Action
				</GhostBtn>
			}
		</div>
	);
};

export default React.memo(ActionsContent);
