import { Dispatch } from 'redux';
import {
	IDesignerState,
	IProjectData,
	ILoadedImageInfo,
	IUnionLoadedVideoInfo,
	ILoadedAudioInfo,
	IAudioStatus,
	IUploadInfo,
	IBaseToast,
	ITransformControlsMode,
	ILoadedModel3dInfo,
	IPublishUploadStatus,
} from '../../../typings';
import { ISyncDocUser } from '../../syncdoc';
import { IFileType } from 'zw-api-client/src/zml/specs';
import { VideoSourceTypes } from '../../utils';
import {
	ITriggerTypes,
	IComponentType,
	IBkgrSound,
	IContactOptions,
	ISoundOptions,
	IDialOptions,
	IEmailOptions,
	IContentDoc,
	IActionCategory,
	IEditorDoc,
	IUserData,
	ISocialOptions,
	IPageEditorLinkOptions,
	IVector2,
	IVector3,
	IVector4,
	IUnitTypes,
	IVector3Dict,
	IAreaTypes,
	IBaseActionData,
	ITransitionEffectTypes,
	ITransitionCategoryTypes,
	IFontTypes,
	IPublishTypes,
	ITrackingTypes,
	ITargetDisplayInfo_Ed_Doc,
	ITextAlignment,
	IText,
	ITextDisplayState,
	ITuple4,
	IButton,
	IButtonDisplayState,
	IProjectColor,
	ITuple3,
	IBaseSpatialComponent,
	IModel3d,
	ITuple2,
	IPublishStatus,
} from '../../components/r3f/r3f-components/component-data-structure';

export enum IContentDocActionTypes {
	ADD_BUTTON_CN_DOC = 'ADD_BUTTON_CN_DOC',
	ADD_TEXT_CN_DOC = 'ADD_TEXT_CN_DOC',
	ADD_VIDEO_CN_DOC = 'ADD_VIDEO_CN_DOC',
	ADD_IMAGE_CN_DOC = 'ADD_IMAGE_CN_DOC',
	ADD_MODEL3D_CN_DOC = 'ADD_MODEL3D_CN_DOC',
	ADD_SCENES_AT_INDEX_CN_DOC = 'ADD_SCENES_AT_INDEX_CN_DOC',
	COPY_SCENES_AT_INDEX_CN_DOC = 'COPY_SCENES_AT_INDEX_CN_DOC',
	LOCK_ENTITY_CN_DOC = 'LOCK_ENTITY_CN_DOC',
	SET_ENTITY_POSITIONS_CN_DOC = 'SET_ENTITY_POSITIONS_CN_DOC',
	SET_ENTITY_ROTATIONS_CN_DOC = 'SET_ENTITY_ROTATIONS_CN_DOC',
	SET_ENTITY_COLORS_CN_DOC = 'SET_ENTITY_COLORS_CN_DOC',
	SET_SCALE_FACTORS_CN_DOC = 'SET_SCALE_FACTORS_CN_DOC',
	PASTE_COPIED_ENTITIES_CN_DOC = 'PASTE_COPIED_ENTITIES_CN_DOC',
	REMOVE_ENTITIES_CN_DOC = 'REMOVE_ENTITIES_CN_DOC',
	CHANGE_CN_DOC_REDUX = 'CHANGE_CN_DOC_REDUX',
	SET_COMPONENT_TRANSITION_CN_DOC = 'SET_COMPONENT_TRANSITION_CN_DOC',
	SET_COMPONENT_ACTION_CN_DOC = 'SET_COMPONENT_ACTION_CN_DOC',
	REMOVE_SCENE_REFERENCES_CN_DOC = 'REMOVE_SCENE_REFERENCES_CN_DOC',
	SET_MULTIPLE_COMPONENT_PROPS_CN_DOC = 'SET_MULTIPLE_COMPONENT_PROPS_CN_DOC',
	ADD_TRACKING_CN_DOC = 'ADD_TRACKING_CN_DOC',
	SET_ACTIVE_SCENE_CHILDREN = 'SET_ACTIVE_SCENE_CHILDREN',
	UNDO_CN_DOC = 'UNDO_CN_DOC',
	REDO_CN_DOC = 'REDO_CN_DOC',
}

export enum IEditorDocActionTypes {
	CHANGE_ED_DOC_REDUX = 'CHANGE_ED_DOC_REDUX',
	SET_SCALE_FACTOR_ED_DOC = 'SET_SCALE_FACTOR_ED_DOC',
	SET_POSITION_ED_DOC = 'SET_POSITION_ED_DOC',
	SET_ROTATION_ED_DOC = 'SET_ROTATION_ED_DOC',
	SET_COLOR_ED_DOC = 'SET_COLOR_ED_DOC',
	SET_OFFSET_ED_DOC = 'SET_OFFSET_ED_DOC',
	SET_SELECTION_ED_DOC = 'SET_SELECTION_ED_DOC',
	REMOVE_USER_SELECTION_ED_DOC = 'REMOVE_USER_SELECTION_ED_DOC',
	REMOVE_ENTITIES_ED_DOC = 'REMOVE_ENTITIES_ED_DOC',
	SET_SCALE_AND_POSITION_ED_DOC = 'SET_SCALE_AND_POSITION_ED_DOC',
	SET_MULTIPLE_ENTITY_PROPS_ED_DOC = 'SET_MULTIPLE_ENTITY_PROPS_ED_DOC',
	ADD_TRACKING_ED_DOC = 'ADD_TRACKING_ED_DOC',
	ADD_PROJECT_COLORS_ED_DOC = 'ADD_PROJECT_COLORS_ED_DOC',
	SET_PUBLISH_STATUS_ED_DOC = 'SET_PUBLISH_STATUS_ED_DOC',
	SET_ENTITY_ACTION_DATA_ED_DOC = 'SET_ENTITY_ACTION_DATA_ED_DOC',
	REMOVE_SCENE_REFERENCES_ED_DOC = 'REMOVE_SCENE_REFERENCES_ED_DOC',
	SET_TARGET_DISPLAY_INFO = 'SET_TARGET_DISPLAY_INFO',
	PASTE_COPIED_ENTITIES_ED_DOC = 'PASTE_COPIED_ENTITIES_ED_DOC',
	COPY_SCENES_ED_DOC = 'COPY_SCENES_ED_DOC'
}

export enum IUserActionTypes {
	SETUP_AUTOMERGE_SOCKET = 'SETUP_AUTOMERGE_SOCKET',
	CANVAS_IS_LOADED = 'CANVAS_IS_LOADED',
	SET_USER_ID = 'SET_USER_ID',
	UPDATE_USERS = 'UPDATE_USERS',
	SET_ACTIVE_SCENE = 'SET_ACTIVE_SCENE',
	SET_START_POS = 'SET_START_POS',
	GET_ALL_IMAGES = 'GET_ALL_IMAGES',
	SET_MARKER_INDEX_PRESSED = 'SET_MARKER_INDEX_PRESSED',
	SET_POSITIONING_ACTIVE = 'SET_POSITIONING_ACTIVE',
	SET_BACKGROUND_HOTSPOT_ENABLED = 'SET_BACKGROUND_HOTSPOT_ENABLED',
	SET_SCALE_HOTSPOT_ENABLED = 'SET_SCALE_HOTSPOT_ENABLED',
	SET_ROTATION_ACTIVE = 'SET_ROTATION_ACTIVE',
	SET_CLICK_OFFSET = 'SET_CLICK_OFFSET',
	SET_HOTSPOT_POINTER_MOVED = 'SET_HOTSPOT_POINTER_MOVED',
	SET_CURRENT_SCENE_INDEX = 'SET_CURRENT_SCENE_INDEX',
	SCROLL_SCENE_MENU = 'SCROLL_SCENE_MENU',
	SET_SCENE_SNAPSHOTS = 'SET_SCENE_SNAPSHOTS',
	ADD_ENTITY_IDS_TO_SELECTION = 'ADD_ENTITY_IDS_TO_SELECTION',
	REMOVE_SELECTION = 'REMOVE_SELECTION',
	REMOVE_ENTITIES = 'REMOVE_ENTITIES',
	REMOVE_LOCAL_USER_SELECTION = 'REMOVE_LOCAL_USER_SELECTION',
	CHANGE_ZOOM_LEVEL = 'CHANGE_ZOOM_LEVEL',
	DISABLE_REDO_BTN = 'DISABLE_REDO_BTN',
	DISABLE_UNDO_BTN = 'DISABLE_UNDO_BTN',
	SET_HOTKEY = 'SET_HOTKEY',
	REMOVE_HOTKEY = 'REMOVE_HOTKEY',
	SET_DRAG_SELECTION_ENABLED = 'SET_DRAG_SELECTION_ENABLED',
	SET_GROUP_INVERSION = 'SET_GROUP_INVERSION',
	COPY_SELECTED_ENTITIES = 'COPY_SELECTED_ENTITIES',
	SET_SELECTION_2D_POSITION = 'SET_SELECTION_2D_POSITION',
	SET_SELECTION_2D_TOP_LEFT_POSITION = 'SET_SELECTION_2D_TOP_LEFT_POSITION',
	SET_SELECTION_2D_TOP_RIGHT_POSITION = 'SET_SELECTION_2D_TOP_RIGHT_POSITION',
	SET_SELECTION_2D_BOTTOM_LEFT_POSITION = 'SET_SELECTION_2D_BOTTOM_LEFT_POSITION',
	SET_SELECTION_2D_BOTTOM_RIGHT_POSITION = 'SET_SELECTION_2D_BOTTOM_RIGHT_POSITION',
	SET_ROTATION_MARKER_2D_POSITION = 'SET_ROTATION_MARKER_2D_POSITION',
	SET_SCENE_ENTITY_LIST_VISIBLE = 'SET_SCENE_ENTITY_LIST_VISIBLE',
	SET_SNAPSHOT_DICT = 'SET_SNAPSHOT_DICT',
	SET_SCENE_CAROUSEL_SCROLL_LEFT = 'SET_SCENE_CAROUSEL_SCROLL_LEFT',
	SET_SCENE_MENU_LARGE = 'SET_SCENE_MENU_LARGE',
	FOCUS_SCENE_NAME_INPUT = 'FOCUS_SCENE_NAME_INPUT',
	SET_PROJECT_INFO = 'SET_PROJECT_INFO',
	SET_ENTITY_MENU_DRAG_ACTIVE = 'SET_ENTITY_MENU_DRAG_ACTIVE',
	SET_ENTITY_MENU_DRAG_2D_POSITION = 'SET_ENTITY_MENU_DRAG_2D_POSITION',
	SET_ENTITY_MENU_DRAG_HOTSPOT_POSITION = 'SET_ENTITY_MENU_DRAG_HOTSPOT_POSITION',
	SET_ENTITY_MENU_DRAG_INFO = 'SET_ENTITY_MENU_DRAG_INFO',
	SET_LOADED_IMAGES = 'SET_LOADED_IMAGES',
	SET_LOADED_AUDIO = 'SET_LOADED_AUDIO',
	LOADING_MEDIA = 'LOADING_MEDIA',
	ADD_TO_LOADED_MEDIA = 'ADD_TO_LOADED_MEDIA',
	SET_AUDIO_STATUS = 'SET_AUDIO_STATUS',
	SET_CURRENT_UPLOADS = 'SET_CURRENT_UPLOADS',
	REMOVE_UPLOAD = 'REMOVE_UPLOAD',
	REMOVE_LOADED_MEDIA = 'REMOVE_LOADED_MEDIA',
	SET_TRACKING_IMG_DATA = 'SET_TRACKING_IMG_DATA',
	SET_TRACKING_IMG_UPLOADING = 'SET_TRACKING_IMG_UPLOADING',
	REMOVE_TRACKING_IMG_DATA = 'REMOVE_TRACKING_IMG_DATA',
	SET_PURE_REACT_IN_CANVAS_HOVERED = 'SET_PURE_REACT_IN_CANVAS_HOVERED',
	SET_SOCKET_RECONNECTING = 'SET_SOCKET_RECONNECTING',
	SET_SOCKET_HAS_CONNECTED = 'SET_SOCKET_HAS_CONNECTED',
	SET_PROJECT_LOADING_PROGRESS = 'SET_PROJECT_LOADING_PROGRESS',
	SET_PROJECT_LOADING_FAILURE = 'SET_PROJECT_LOADING_FAILURE', 
	ADD_TOAST = 'ADD_TOAST', 
	REMOVE_TOAST = 'REMOVE_TOAST', 
	SET_SELECTED_ENTIY_IDS = 'SET_SELECTED_ENTIY_IDS',
	EDIT_SCENE_TITLE = 'EDIT_SCENE_TITLE',
	SET_3D_MODE = 'SET_3D_MODE',
	SET_SCENE_HAS_3D_ENTITY = 'SET_SCENE_HAS_3D_ENTITY',
	SET_TRANSFORM_CONTROLS_ACTIVE = 'SET_TRANSFORM_CONTROLS_ACTIVE',
	SET_TRANSFORM_CONTROLS_MODE = 'SET_TRANSFORM_CONTROLS_MODE',
	SET_PUBLISH_UPLOAD_STATUS = 'SET_PUBLISH_UPLOAD_STATUS'
}

export enum IGlobalActionTypes {
	ADD_TEXT_GLOBAL = 'ADD_TEXT_GLOBAL'
}

///////////////////
// Purely Redux related actions (not affecting automerge)
///////////////////

// Sets user id on page load
export interface ISetUserIdAction {
	type: IUserActionTypes.SET_USER_ID;
	payload: { userId: string };
}

export interface IOnSetUserId {
	(userId: string): ISetUserIdAction;
}

export interface IOnRemoveLocalUserSelectionAction {
	type: IUserActionTypes.REMOVE_LOCAL_USER_SELECTION;
}

export interface ISetPRojectInfoAction {
	type: IUserActionTypes.SET_PROJECT_INFO;
	payload: {
		project: IProjectData;
	};
}

export interface ISetHotKeyAction {
	type: IUserActionTypes.SET_HOTKEY;
	payload: { hotKey: string };
}

export interface IOnSetCanvasLoadedAction {
	type: IUserActionTypes.CANVAS_IS_LOADED;
	payload: boolean;
}

export interface IOnSetCanvasLoaded {
	(payload: boolean): IOnSetCanvasLoadedAction;
}

export interface ISetTrkImgIsUploadingAction {
	type: IUserActionTypes.SET_TRACKING_IMG_UPLOADING;
	payload: boolean;
}

export interface ISetTrkImgIsUploading {
	(payload: boolean): ISetTrkImgIsUploadingAction;
}

export interface IOnSetLoadingMediaAction {
	type: IUserActionTypes.LOADING_MEDIA;
	payload: boolean;
}

export interface IOnSetLoadingMedia {
	(payload: boolean): IOnSetLoadingMediaAction;
}

export interface ISetLoadedImagesAction {
	type: IUserActionTypes.SET_LOADED_IMAGES;
	payload: ILoadedImageInfo[];
}

export interface ISetLoadedAudioAction {
	type: IUserActionTypes.SET_LOADED_AUDIO;
	payload: ILoadedAudioInfo[];
}

export interface IRemoveUploadAction {
	type: IUserActionTypes.REMOVE_UPLOAD;
	payload: string;
}

export interface IRemoveUpload {
	(id: string): IRemoveUploadAction;
}
interface ISetCurrentUploadsPayload {
	id: string;
	progress: IUploadInfo;
}
export interface ISetCurrentUploadsAction {
	type: IUserActionTypes.SET_CURRENT_UPLOADS;
	payload: ISetCurrentUploadsPayload;
}

export interface IOnSetCurrentUploads {
	(id: string, progress: IUploadInfo): ISetCurrentUploadsAction;
}

export interface IOnSetAudioStatusPayload {
	id: string;
	status: IAudioStatus | null;
}

export interface IOnSetAudioStatusAction {
	type: IUserActionTypes.SET_AUDIO_STATUS;
	payload: IOnSetAudioStatusPayload;
}

export interface IOnSetAudioStatus {
	(payload: IOnSetAudioStatusPayload): IOnSetAudioStatusAction;
}

export interface IOnSetEntityMenuDragActiveAction {
	type: IUserActionTypes.SET_ENTITY_MENU_DRAG_ACTIVE;
	payload: boolean;
}

export interface IOnSetEntityMenuDragActive {
	(payload: boolean): IOnSetEntityMenuDragActiveAction;
}

export interface IOnSetEntityMenuHotspotDragPositionAction {
	type: IUserActionTypes.SET_ENTITY_MENU_DRAG_HOTSPOT_POSITION;
	payload: IVector3 | null;
}


export interface IOnSetEntityMenuHotspotDragPosition {
	(payload: IVector3 | null): IOnSetEntityMenuHotspotDragPositionAction;
}

export interface IOnSetSocketIsReconnectingAction {
	type: IUserActionTypes.SET_SOCKET_RECONNECTING;
	payload: boolean;
}

export interface IOnSetSocketHasConnectedAction {
	type: IUserActionTypes.SET_SOCKET_HAS_CONNECTED;
	payload: boolean;
}

export interface IOnSetProjectLoadingProgressAction {
	type: IUserActionTypes.SET_PROJECT_LOADING_PROGRESS;
	payload: number;
}

export interface IOnSetProjectLoadingFailureAction {
	type: IUserActionTypes.SET_PROJECT_LOADING_FAILURE;
	payload: boolean;
}

export interface IBaseEntityDragInfo {
	id: string;
	title: string;
	clickOffset: ITuple2;
	aspectRatio: number;
	type: IComponentType;
}

interface IBase2dEntityDragInfo extends IBaseEntityDragInfo {
	dimensions: ITuple2;
}

interface IBase3dEntityDragInfo extends IBaseEntityDragInfo {
	dimensions: ITuple2;
}

export type IBtnEntityDragInfo = IBase2dEntityDragInfo & IButtonDisplayState;

export interface IImageEntityDragInfo extends IBase2dEntityDragInfo {
	zmlFileId: string;
	url: string;
}

export interface IModel3dEntityDragInfo extends IBase3dEntityDragInfo {
	zmlFileId: string;
	model3dUrl: string;
	thumbnailUrl: string;
	aspectRatio: number;
	dimensions3d: ITuple3;
}

export type ITextEntityDragInfo = IBase2dEntityDragInfo & ITextDisplayState;

interface IBaseVideoEntityDragInfo extends IBase2dEntityDragInfo {
	id: string;
	zmlFileId: string;
	thumbnailUrl: string;
	mp4Url: string;
	aspectRatio: number;
	externalId?: string;
	videoUrl?: string;
	hasAlpha: boolean;
	dimensions: ITuple2;
}

export interface IExtVideoEntityDragInfo extends IBaseVideoEntityDragInfo {
	source: VideoSourceTypes.user;
	videoUrl?: string;
}

export interface IUserVideoEntityDragInfo extends IBaseVideoEntityDragInfo {
	source: VideoSourceTypes.vimeo | VideoSourceTypes.youtube;
	externalId?: string;
}

export type IUnionVideoEntityDragInfo =
	| IExtVideoEntityDragInfo
	| IUserVideoEntityDragInfo;

export interface IOnSetDraggedEntityInfoAction {
	type: IUserActionTypes.SET_ENTITY_MENU_DRAG_INFO;
	payload:
		| IImageEntityDragInfo
		| IBtnEntityDragInfo
		| IUnionVideoEntityDragInfo
		| IModel3dEntityDragInfo
		| null;
}

export interface IOnSetDraggedEntityInfo {
	(
		payload:
			| IImageEntityDragInfo
			| IBtnEntityDragInfo
			| IUnionVideoEntityDragInfo
			| IModel3dEntityDragInfo
			| null
	): IOnSetDraggedEntityInfoAction;
}

export interface IOnSetHotKey {
	(hotKey: string): ISetHotKeyAction;
}

export interface IRemoveHotKeyAction {
	type: IUserActionTypes.REMOVE_HOTKEY;
	payload: { hotKey: string };
}

export interface IOnRemoveHotKey {
	(hotKey: string): IRemoveHotKeyAction;
}

export interface IOnAddToastAction {
	type: IUserActionTypes.ADD_TOAST;
	payload: IBaseToast | null
}

export interface IOnAddToast {
	(payload: IBaseToast | null ): IOnAddToastAction;
}

export interface IOnRemoveToastAction {
	type: IUserActionTypes.REMOVE_TOAST;
	payload: string; 
}

export interface IOnRemoveToast {
	(payload: string) : IOnRemoveToastAction;
}

export interface ISetActiveSceneAction {
	type: IUserActionTypes.SET_ACTIVE_SCENE;
	payload: { activeSceneId: string };
}

export interface IOnSetActiveScene {
	(activeSceneProps: { activeSceneId: string }): ISetActiveSceneAction;
}

export interface IOnSetEnlargeSceneMenuAction {
	type: IUserActionTypes.SET_SCENE_MENU_LARGE;
	payload: boolean;
}

export interface IOnFocusSceneNameInputAction {
	type: IUserActionTypes.FOCUS_SCENE_NAME_INPUT;
	payload: boolean;
}

export interface IOnFocusSceneNameInput {
	(isFocus: boolean): IOnFocusSceneNameInputAction;
}

export interface IOnSetEnlargeSceneMenu {
	(isLarge: boolean): IOnSetEnlargeSceneMenuAction;
}

// export interface ISetSnapshotDictAction {
// 	type: IUserActionTypes.SET_SNAPSHOT_DICT;
// 	payload: ISceneSnapshotDict;
// }

// export interface IOnSetSceneSnapshotDict {
// 	(snapshotDict: ISceneSnapshotDict): ISetSnapshotDictAction;
// }

export interface ISetSceneEntityListVisibleAction {
	type: IUserActionTypes.SET_SCENE_ENTITY_LIST_VISIBLE;
	payload: {
		isVisible: boolean;
		position?: [number, number];
	};
}

export interface IOnSetSceneEntityListVisible {
	(isVisibleProps: {
		isVisible: boolean;
		position?: [number, number];
	}): ISetSceneEntityListVisibleAction;
}

interface GroupInversionPayload {
	xInverted?: boolean;
	yInverted?: boolean;
}
export interface ISetGroupInversionAction {
	type: IUserActionTypes.SET_GROUP_INVERSION;
	payload: GroupInversionPayload;
}

export interface IOnSetGroupInversion {
	(axisInversions: GroupInversionPayload): ISetGroupInversionAction;
}

export interface IOnEditSceneTitleAction {
	type: IUserActionTypes.EDIT_SCENE_TITLE; 
	payload: boolean; 
}


export interface IOnEditSceneTitle {
	(editScene: boolean): IOnEditSceneTitleAction;
}

// Middleware generated action to update store
export interface IUpdateReduxAction {
	type: string;
	editorDoc?: any;
	contentDoc?: any;
}

export interface IOnChangeZoomLevelAction {
	type: IUserActionTypes.CHANGE_ZOOM_LEVEL;
	payload: number;
}

export interface IOnChangeZoomLevel {
	(zoomLevel: number): IOnChangeZoomLevelAction;
}
export interface IOnSetPositioningIsActiveAction {
	type: IUserActionTypes.SET_POSITIONING_ACTIVE;
	payload: boolean;
}

export interface IOnSetPositioningIsActive {
	(positioningIsActive: boolean): IOnSetPositioningIsActiveAction;
}

interface IOnSetTrackingImageDataPayload {
	aspectRatio?: number;
	codes?: string[];
	compositeUrl?: string;
	quality?: number;
}

export interface IOnSetTrackingImageDataAction {
	type: IUserActionTypes.SET_TRACKING_IMG_DATA;
	payload: IOnSetTrackingImageDataPayload;
}

export interface IOnSetTrackingImageData {
	(payload: IOnSetTrackingImageDataPayload): IOnSetTrackingImageDataAction;
}

export interface IOnRemoveTrackingImageData {
	(): { type: IUserActionTypes.REMOVE_TRACKING_IMG_DATA };
}

export interface IOnSetSelection_Action {
	type: IUserActionTypes.SET_SELECTED_ENTIY_IDS;
	payload: string[];
}

// Enable/disable scale hotspot
export interface ISetScaleHotSpotEnabledAction {
	type: string;
	payload: boolean;
}

export interface IOnSetScaleHotSpotEnabled {
	(scaleHotspotIsEnabled: boolean): ISetScaleHotSpotEnabledAction;
}

// Enable/disable background hotspot (drag etc.)
export interface ISetBackgroundHotSpotEnabledAction {
	type: string;
	payload: boolean;
}

export interface IOnSetBackgroundHotSpotEnabled {
	(backgroundHotspotIsEnabled: boolean): ISetBackgroundHotSpotEnabledAction;
}

export interface IOnSetPureReactInCanvasHoveredAction {
	type: IUserActionTypes.SET_PURE_REACT_IN_CANVAS_HOVERED;
	payload: boolean;
}
export interface IOnSetIs3dModeAction {
	type: IUserActionTypes.SET_3D_MODE; 
	payload: boolean; 
}

export interface IOnSetIs3dMode {
	(payload: boolean): IOnSetIs3dModeAction
}

export interface IOnSetSceneHas3dEntityAction {
	type: IUserActionTypes.SET_SCENE_HAS_3D_ENTITY;
	payload: boolean;
}
export interface IOnSetSceneHas3dEntity {
	(payload: boolean): IOnSetSceneHas3dEntityAction
}

export interface IOnSetTransformControlsActiveAction {
	type: IUserActionTypes.SET_TRANSFORM_CONTROLS_ACTIVE;
	payload: boolean;
}

export interface IOnSetTransformControlsActive {
	(payload: boolean): IOnSetTransformControlsActiveAction;
}

export interface IOnSetTransformControlsModeAction {
	type: IUserActionTypes.SET_TRANSFORM_CONTROLS_MODE;
	payload: ITransformControlsMode
}

export interface IOnSetTransformControlsMode {
	(payload: ITransformControlsMode): IOnSetTransformControlsModeAction;
}

export interface IOnSetPureReactInCanvasHovered {
	(pureReactIsHovered: boolean): ISetBackgroundHotSpotEnabledAction;
}

// Enable/disable rotation hotspot
export interface ISetRotationHotSpotEnabledAction {
	type: string;
	payload: boolean;
}

export interface ISetEntityMenuDrag2DPositionAction {
	type: IUserActionTypes.SET_ENTITY_MENU_DRAG_2D_POSITION;
	payload: IVector2 | null;
}

export interface IOnSetEntityMenuDrag2DPosition {
	(payload: IVector2 | null): ISetEntityMenuDrag2DPositionAction;
}

export interface ISetSelection2DPositionAction {
	type: IUserActionTypes.SET_SELECTION_2D_POSITION;
	payload: IVector2 | null;
}

export interface ISetSelection2DTopLeftPositionAction {
	type: IUserActionTypes.SET_SELECTION_2D_TOP_LEFT_POSITION;
	payload: IVector2 | null;
}

export interface ISetSelection2DTopRightPositionAction {
	type: IUserActionTypes.SET_SELECTION_2D_TOP_RIGHT_POSITION;
	payload: IVector2 | null;
}

export interface ISetSelection2DBottomLeftPositionAction {
	type: IUserActionTypes.SET_SELECTION_2D_BOTTOM_LEFT_POSITION;
	payload: IVector2 | null;
}

export interface ISetSelection2DBottomRightPositionAction {
	type: IUserActionTypes.SET_SELECTION_2D_BOTTOM_RIGHT_POSITION;
	payload: IVector2 | null;
}

export interface ISetRotationMarker2DPositionAction {
	type: IUserActionTypes.SET_ROTATION_MARKER_2D_POSITION;
	payload: IVector2 | null;
}

export interface IOnSetRotationIsActive {
	(rotationIsActive: boolean): ISetRotationHotSpotEnabledAction;
}

export interface ISetMarkerIndexPressedAction {
	type: string;
	payload: number | null;
}

export interface IOnSetMarkerIndexPressed {
	(markerIndexPressed: string | number | null): ISetMarkerIndexPressedAction;
}

// Set click offsets for entities and group selections
export interface ISetPointerOffsetAction {
	type: string;
	payload: IVector3;
}

export interface IOnSetPointerOffset {
	(pointerOffset: IVector3): ISetPointerOffsetAction;
}

export interface ISetCurrentSceneIndexAction {
	type: IUserActionTypes.SET_CURRENT_SCENE_INDEX;
	payload: number;
}

export interface IOnSetCurrentSceneIndex {
	(currentIndex: number): ISetCurrentSceneIndexAction;
}

export interface IOnScrollSceneMenuAction{
	type: IUserActionTypes.SCROLL_SCENE_MENU; 
	payload: boolean; 
}

export interface IOnScrollSceneMenu {
	(scroll: boolean): IOnScrollSceneMenuAction
}

export interface ISceneSnapshotDict {
	[id: string]: string;
}

export interface ISetSceneSnapshotsAction {
	type: IUserActionTypes.SET_SCENE_SNAPSHOTS;
	payload: ISceneSnapshotDict;
}

export interface IOnSetSceneSnapshots {
	(sceneShots: ISceneSnapshotDict): ISetSceneSnapshotsAction;
}

export interface IOnSetSceneCarouselScrollLeftAction {
	type: IUserActionTypes.SET_SCENE_CAROUSEL_SCROLL_LEFT;
	payload: number;
}

export interface IOnSetSceneCarouselScrollLeft {
	(scrollLeft: number): IOnSetSceneCarouselScrollLeftAction;
}

// Enable/disable undo
export interface IDisableUndoAction {
	type: string;
	payload: boolean;
}

export interface IDisableRedoAction {
	type: string;
	payload: boolean;
}

// Set publish status

export interface IOnSetPublishUploadStatusAction {
	type: IUserActionTypes.SET_PUBLISH_UPLOAD_STATUS;
	payload: IPublishUploadStatus | null
}

export interface IOnSetPublishUploadStatus {
	(status: IPublishUploadStatus | null): IOnSetPublishUploadStatusAction;
}

// Add entity ids to selection
export interface IAddEntityIdsToSelectionAction {
	type: string;
	payload: string[];
}

export interface IOnAddEntityIdsToSelection {
	(ids: string[]): IAddEntityIdsToSelectionAction;
}

// Remove entity ids to selection
export interface IOnRemoveSelection {
	(ids?: string[]): { type: string };
}

// Add entity ids to copy selection
export interface ICopySelectedEntitiesAction {
	type: IUserActionTypes.COPY_SELECTED_ENTITIES;
	payload: string[];
}

export interface IOnCopySelectedEntities {
	(ids: string[]): ICopySelectedEntitiesAction;
}

export interface IOnUpdateUsers_Payload {
	users: ISyncDocUser[];
}

export interface IOnUpdateUsers_Action {
	type: IUserActionTypes.UPDATE_USERS;
	payload: IOnUpdateUsers_Payload;
}

export interface IOnUpdateUsers {
	(payload: IOnUpdateUsers_Payload): IOnUpdateUsers_Action;
}

export interface IOnGetZmlFileInfo {
	(args: {
		type?: IFileType;
		limit?: number;
		page?: number; 
		q?: string;
	}): (dispatch: Dispatch) => void;
}

export enum IAddMediaPositionTypes {
	start = 'start',
	end = 'end',
}

export enum ILoadedMediaTypes {
	image = 'image',
	model3d = 'model3d',
	video = 'video',
	audio = 'audio',
	zpp = 'zpp',
	trackingImage = 'trackingImage',
}

export interface IOnAddToLoadedMediaPayload {
	mediaType: ILoadedMediaTypes;
	position?: IAddMediaPositionTypes;
	mediaInfo: ILoadedImageInfo[] | IUnionLoadedVideoInfo[] | ILoadedAudioInfo[] | ILoadedModel3dInfo[];
}

export interface IOnAddToLoadedMediaAction {
	type: IUserActionTypes.ADD_TO_LOADED_MEDIA;
	payload: IOnAddToLoadedMediaPayload;
}

export interface IOnAddToLoadedMedia {
	(payload: IOnAddToLoadedMediaPayload): IOnAddToLoadedMediaAction;
}

export interface IOnRemoveLoadedMediaPayload {
	type: IFileType;
}

export interface IOnRemoveLoadedMediaAction {
	type: IUserActionTypes.REMOVE_LOADED_MEDIA;
	payload: IOnRemoveLoadedMediaPayload;
}

export interface IOnRemoveLoadedMedia {
	(payload: IOnRemoveLoadedMediaPayload): IOnRemoveLoadedMediaAction;
}
/////////////////
// Editor Doc
/////////////////
export interface IOnRemoveEntities_Ed_Doc_Action {
	type: IEditorDocActionTypes.REMOVE_ENTITIES_ED_DOC;
	payload: { ids: string[] };
}

export interface IOnRemoveSelections_Ed_Doc_Action {
	type: IEditorDocActionTypes.REMOVE_USER_SELECTION_ED_DOC;
	payload: { userIds: string[] };
}

export interface IOnRemoveSelections_Ed_Doc {
	(payload: { userIds: string[] }): IOnRemoveSelections_Ed_Doc_Action;
}

export interface IMultipleEntityProps_Ed_Doc {
	id: string;
	rotations?: IVector3 | null;
	positions?: IVector3 | null;
	scales?: IVector3 | null;
	userSelection?: string | null;
	rgbaColors?: IVector4 | null;
	//borderWidth?: number | null;
	titles?: string;
	borderRgba?: IVector4 | null;
	fontRgba?: IVector4 | null;
	isLocked?: boolean;
	scalesInverted?: boolean[];
	aspectRatioLocked?: boolean;
	originalAspectRatio?: number;
	textScale?: IVector3;
	zmlFileId?: string;
	activeTriggers?: ITriggerTypes;
	mp4Url?: string
	editingDisabled?: boolean;
}

export interface ISetMultipleEntityProps_Ed_Doc_Action {
	type: IEditorDocActionTypes.SET_MULTIPLE_ENTITY_PROPS_ED_DOC;
	payload: IMultipleEntityProps_Ed_Doc[];
}

export interface IOnSetMultipleEntityProps_Ed_Doc {
	(
		entityProps: IMultipleEntityProps_Ed_Doc[]
	): ISetMultipleEntityProps_Ed_Doc_Action;
}

// Set selection

export interface IOnSetSelection_Ed_Doc_Action {
	type: IEditorDocActionTypes.SET_SELECTION_ED_DOC;
	payload: { entityIds: string[]; userId: string | null };
}

export interface IOnSetSelection_Ed_Doc {
	(entityProps: {
		entityIds: string[];
		userId: string | null;
	}): IOnSetSelection_Ed_Doc_Action;
}

// Set offset
export interface IOnSetPosition_Ed_Doc {
	(entityProps: {
		ids: string[];
		positionDict: IVector3Dict;
		pointerOffset: IVector3;
	}): {
		type: string;
		payload: {
			ids: string[];
			pointerOffset: IVector3;
			positionDict: IVector3Dict;
		};
	};
}

// Set Scale Factor
export interface IOnSetScale_Ed_Doc {
	(entityProps: { id: string; scale: IVector3 }): {
		type: string;
		payload: {
			id: string;
			scale: IVector3;
		};
	};
}

export interface IOnSetScaleAndPosition_Ed_Doc {
	(entityProps: { id: string; scale: IVector3; position: IVector3 }): {
		type: string;
		payload: {
			id: string;
			scale: IVector3;
			position: IVector3;
		};
	};
}

export interface IOnSetRotation_Ed_Doc {
	(entityProps: { ids: string[]; rotation: IVector3 }): {
		type: string;
		payload: {
			ids: string[];
			rotation: IVector3;
		};
	};
}

export interface IChange_Ed_Doc_Action {
	type: IEditorDocActionTypes.CHANGE_ED_DOC_REDUX;
	editorDoc: IEditorDoc;
}

/////////////////
// Content Doc
////////////////
export interface IMultipleEntityProps_Cn_Doc {
	id: string;
	opacity?: number;
	rotation?: IVector3 | null;
	position?: IVector3 | null;
	scale?: IVector3 | null;
	color?: IVector4 | null;
	fontRgba?: IVector4 | null;
	text?: string;
	fontFamily?: IFontTypes;
	fontSize?: number;
	borderRadius?: number;
	borderWidth?: number;
	borderRgba?: IVector4 | null;
	bkgrSound?: IBkgrSound | null;
	children?: string[];
	type?: IComponentType;
	textAlignment?: ITextAlignment;
	loop?: boolean;
	autoplay?: boolean;
	hideControls?: boolean;
}

export interface IOnSetMultipleComponentProps_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_MULTIPLE_COMPONENT_PROPS_CN_DOC;
	payload: IMultipleEntityProps_Cn_Doc[];
}

export interface IOnSetMultipleComponentProps_Cn_Doc {
	(
		entityProps: IMultipleEntityProps_Cn_Doc[]
	): IOnSetMultipleComponentProps_Cn_Doc_Action;
}

////

// Undo & Redo
export interface ISetUndoAction {
	type: IContentDocActionTypes.UNDO_CN_DOC;
}
export type IOnUndoCnDoc = () => ISetUndoAction;

export interface ISetRedoAction {
	type: IContentDocActionTypes.REDO_CN_DOC;
}
export type IOnRedoCnDoc = () => ISetRedoAction;

// Set Position

export interface IOnSetPositions_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_ENTITY_POSITIONS_CN_DOC;
	payload: { [id: string]: IVector3 };
}

export interface IOnSetPositions_Cn_Doc {
	(positionDict: { [id: string]: IVector3 }): IOnSetPositions_Cn_Doc_Action;
}

export interface IOnSetRgbaColors_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_ENTITY_COLORS_CN_DOC;
	payload: { [id: string]: IVector4 };
}

export interface IOnSetRgbaColors_Cn_Doc {
	(colorDict: { [id: string]: IVector4 }): IOnSetRgbaColors_Cn_Doc_Action;
}

// Set Scale Factor
export interface IOnSetScales_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_SCALE_FACTORS_CN_DOC;
	payload: { [id: string]: IVector3 };
}

export interface IOnSetScales_Cn_Doc {
	(scaleDict: { [id: string]: IVector3 }): IOnSetScales_Cn_Doc_Action;
}

// Set Rotation

export interface IOnSetRotations_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_ENTITY_ROTATIONS_CN_DOC;
	payload: { [id: string]: number[] };
}

export interface IOnSetRotations_Cn_Doc {
	(rotationDict: { [id: string]: number[] }): IOnSetRotations_Cn_Doc_Action;
}

/////////////////////////////////
////////////////////////////////////
// Editor actions

interface IOnSetActionData_Ed_Doc_Payload {
	ids: string[];
	triggerType: ITriggerTypes;
	selectedActionCategory?: IActionCategory;
	socialOptions?: ISocialOptions;
	soundOptions?: ISoundOptions;
	dialOptions?: IDialOptions;
	emailOptions?: IEmailOptions;
	linkPageOptions?: IPageEditorLinkOptions;
	contactOptions?: IContactOptions;
	linkedSceneId?: string | null;
	enlarge?: boolean;
}

interface IOnSetActionData_Global_Payload
	extends IOnSetActionData_Ed_Doc_Payload {}

export interface IOnSetActionData_Ed_Doc_Action {
	type: IEditorDocActionTypes.SET_ENTITY_ACTION_DATA_ED_DOC;
	payload: IOnSetActionData_Ed_Doc_Payload;
}

export interface IOnSetActionData_Ed_Doc {
	(payload: IOnSetActionData_Ed_Doc_Payload): IOnSetActionData_Ed_Doc_Action;
}

export interface IOnSetActionData_Global {
	(payload: IOnSetActionData_Global_Payload): (dispatch: Dispatch) => void;
}

export interface IOnSetPublishStatus_Ed_Doc_Payload {
	status: IPublishTypes;
	dateString: string;
}

export interface IOnSetPublishStatus_Ed_Doc_Action {
	type: IEditorDocActionTypes.SET_PUBLISH_STATUS_ED_DOC;
	payload: IOnSetPublishStatus_Ed_Doc_Payload;
}

export interface IOnSetPublishStatus_Ed_Doc {
	(
		payload: IOnSetPublishStatus_Ed_Doc_Payload
	): IOnSetPublishStatus_Ed_Doc_Action;
}

//Add Button



export interface IOnAddButton_Global_Payload extends IButtonDisplayState {
	id: string;
	title: string;
	activeSceneId: string;
	scalesInverted: boolean[];
	aspectRatioLocked: boolean;
	isLocked: boolean;
}

export interface IOnAddButton_Global_Action {
	type: string;
	payload: IOnAddButton_Global_Payload;
}

export interface IOnAddButton_Global {
	(payload: IOnAddButton_Global_Payload): (dispatch: Dispatch) => void;
}


// Add text entity
interface IOnAddText_Global_Payload extends ITextDisplayState {
	id: string;
	activeSceneId: string;
	title: string;
}

export interface IOnAddText_Global_Action {
	type: IGlobalActionTypes.ADD_TEXT_GLOBAL;
	payload: IOnAddText_Global_Payload;
}

export interface IOnAddText_Global {
	(payload: IOnAddText_Global_Payload): (dispatch: Dispatch) => void;
}

// Add button entity



export interface IOnAddButton_Cn_Doc_Payload extends IButton {
	id: string;
	activeSceneId: string;
}
export interface IOnAddButton_Cn_Doc_Action {
	type: IContentDocActionTypes.ADD_BUTTON_CN_DOC;
	payload: IOnAddButton_Cn_Doc_Payload;
}

export interface IOnAddButton_Cn_Doc {
	(payload: IOnAddButton_Cn_Doc_Payload): IOnAddButton_Cn_Doc_Action;
}

// Add text

interface IOnAddText_Payload extends ITextDisplayState {
	id: string;
	activeSceneId: string;
	type: IComponentType.Text;
}

export interface IOnAddText_Cn_Doc_Action {
	type: IContentDocActionTypes.ADD_TEXT_CN_DOC;
	payload: IOnAddText_Payload;
}

export interface IOnAddText_Cn_Doc {
	(payload: IOnAddText_Payload): IOnAddText_Cn_Doc_Action;
}

// Add image
export interface IOnAddImage_Global_Payload {
	activeSceneId: string;
	id: string;
	type: IComponentType.Image;
	position: IVector3;
	rotation: IVector3;
	scale: IVector3;
	scalesInverted: boolean[];
	imageUrl: string;
	aspectRatio: number;
	originalAspectRatio: number;
	title: string;
	aspectRatioLocked: boolean;
}

export interface IOnAddImage_Global_Action {
	type: string;
	payload: IOnAddImage_Global_Payload;
}

export interface IOnAddImage_Global {
	(payload: {
		activeSceneId: string;
		position: IVector3;
		entity: ILoadedImageInfo;
	}): (dispatch: Dispatch) => void;
}

export interface IOnAddImage_Cn_Doc_Payload {
	activeSceneId: string;
	id: string;
	type: IComponentType.Image;
	position: IVector3;
	rotation: IVector3;
	scale: IVector3;
	opacity: number;
	imageUrl: string;
	zmlFileId: string;
	hasAlpha: boolean;
}

export interface IOnAddImage_Cn_Doc_Action {
	type: IContentDocActionTypes.ADD_IMAGE_CN_DOC;
	payload: IOnAddImage_Cn_Doc_Payload;
}

export interface IOnAddImage_Cn_Doc {
	(payload: IOnAddImage_Cn_Doc_Payload): IOnAddImage_Cn_Doc_Action;
}

// Video
interface IOnAddVideo_Global_Info {
	id: string;
	zmlFileId?: string;
	aspectRatio: number;
	videoUrl?: string | null;
	name: string;
	thumbnailUrl: string;
	mp4Url: string;
	source: VideoSourceTypes;
	externalId?: string | null;
	scalesInverted: boolean[];
	hasAlpha?: boolean;
}

export interface IOnAddVideo_Global {
	(payload: {
		activeSceneId: string;
		position: IVector3;
		entity: IOnAddVideo_Global_Info;
	}): (dispatch: Dispatch) => void;
}

export interface IOnAddVideo_Cn_Doc_Payload {
	id: string;
	zmlFileId: string;
	activeSceneId: string;
	type: IComponentType;
	position: number[];
	rotation: number[];
	scale: number[];
	videoUrl: string | null;
	thumbnailUrl: string;
	source: VideoSourceTypes;
	externalId: string | null;
	hasAlpha: boolean;
}

export interface IOnAddVideo_Cn_Doc_Action {
	type: IContentDocActionTypes.ADD_VIDEO_CN_DOC;
	payload: IOnAddVideo_Cn_Doc_Payload;
}

export interface IOnAddVideo_Cn_Doc {
	(payload: IOnAddVideo_Cn_Doc_Payload): IOnAddVideo_Cn_Doc_Action;
}

export interface IOnAddImgTracking_Ed_Doc_Payload {
	type?: ITrackingTypes.image;
	id?: string;
	url?: string;
	aspectRatio?: number;
	name?: string;
	scale?: number[];
	opacity?: number;
}


// Add 3d model

interface IOnAdd3dModel_Global_Info {
	id: string;
	zmlFileId?: string;
	aspectRatio: number;
	model3dUrl?: string | null;
	name: string;
	thumbnailUrl: string;
	// scalesInverted: boolean[];
	dimensions: ITuple3;
}


export interface IOnAdd3dModel_Global {
	(payload: {
		activeSceneId: string;
		position: IVector3;
		entity: IOnAdd3dModel_Global_Info;
	}): (dispatch: Dispatch) => void;
}



// export interface IOnAddModel3d_Global {
// 	(payload: IOnAddModel3d_Global_Payload): (dispatch: Dispatch) => void;
// }


export interface IOnAddModel3d_Global_Payload extends IBaseSpatialComponent {
	id: string;
	title: string;
	activeSceneId: string;
	scalesInverted: boolean[];
	// aspectRatioLocked: boolean;
	isLocked: boolean;
}



export interface IOnAddModel3d_Cn_Doc_Payload extends IModel3d {
	id: string;
	activeSceneId: string;
}

export interface IOnAddModel3d_Cn_Doc_Action {
	type: IContentDocActionTypes.ADD_MODEL3D_CN_DOC;
	payload: IOnAddModel3d_Cn_Doc_Payload
}

export interface IOnAddModel3d_Cn_Doc {
	(payload: IOnAddModel3d_Cn_Doc_Payload): IOnAddModel3d_Cn_Doc_Action;
}


interface IOnAddFaceTracking_Ed_Doc_Payload {
	type?: ITrackingTypes.face;
}

type TrackingUnion_Ed_Doc_Payload = IOnAddImgTracking_Ed_Doc_Payload | IOnAddFaceTracking_Ed_Doc_Payload;

export interface IOnAddTracking_Ed_Doc_Action {
	type: IEditorDocActionTypes.ADD_TRACKING_ED_DOC;
	payload: TrackingUnion_Ed_Doc_Payload;
}

export interface IOnAddTracking_Ed_Doc {
	(payload: TrackingUnion_Ed_Doc_Payload): (dispatch: Dispatch) => void;
}

export interface IOnCopyScenesAtIndex_Ed_Doc_Action {
	type: IEditorDocActionTypes.COPY_SCENES_ED_DOC;
	payload: { oldToNewIdDict: {[id: string]: string}; sceneIds: string[]; }
}


//Remove user
export interface IOnUpdateUsers_Global {
	(users: ISyncDocUser[]): any;
}

//Remove entity

export interface IOnRemoveEntities_Global {
	(ids: string[]): (dispatch: Dispatch) => void;
}

export interface IOnRemoveScenes_Global {
	(sceneIds: string[]): (
		dispatch: Dispatch,
		getState: () => IDesignerState
	) => void;
}

export interface IOnRemoveEntities_Cn_Doc_Action {
	type: IContentDocActionTypes.REMOVE_ENTITIES_CN_DOC;
	payload: { ids: string[] };
}

export interface IOnRemoveSceneReferences_Cn_Doc_Action {
	type: IContentDocActionTypes.REMOVE_SCENE_REFERENCES_CN_DOC;
	payload: { sceneIds: string[] };
}

export interface IOnRemoveSceneReferences_Ed_Doc_Action {
	type: IEditorDocActionTypes.REMOVE_SCENE_REFERENCES_ED_DOC;
	payload: { sceneIds: string[] };
}



interface IOnPasteCopiedEntities_Base_Payload {
	activeSceneId: string; 
	copiedIds: string[];
}

interface IOnPasteCopiedEntities_Global_Payload extends IOnPasteCopiedEntities_Base_Payload {
	offset?: ITuple3;
}
interface IOnPasteCopiedEntities_Cn_Doc_Payload extends IOnPasteCopiedEntities_Global_Payload {
	newEntityIds: string[];
}

interface IOnPasteCopiedEntities_Ed_Doc_Payload extends IOnPasteCopiedEntities_Base_Payload {
	newEntityIds: string[];
}
export interface IOnPasteCopiedEntities_Global {
	(payload: IOnPasteCopiedEntities_Global_Payload): (dispatch: Dispatch) => void;
}

export interface IPasteCopiedEntities_Cn_Doc_Action {
	type: IContentDocActionTypes.PASTE_COPIED_ENTITIES_CN_DOC;
	payload: IOnPasteCopiedEntities_Cn_Doc_Payload;
}

export interface IPasteCopiedEntities_Ed_Doc_Action {
	type: IEditorDocActionTypes.PASTE_COPIED_ENTITIES_ED_DOC;
	payload: IOnPasteCopiedEntities_Ed_Doc_Payload;
}

export interface IOnCopyScenes_Global_Payload {
	projectId: string;
	sceneIds: string[];
	index: number;
}

export interface IOnCopyScenesAtIndex_Global {
	(payload: IOnCopyScenes_Global_Payload): (dispatch: Dispatch) => void;
}

export interface IOnAddScenes_Global_Payload {
	sceneIds: string[];
	newSceneIds: string[];
	rootId: string;
	index: number;
}

export interface IOnAddScenes_Global {
	(payload: IOnAddScenes_Global_Payload): (dispatch: Dispatch) => void;
}

export interface IOnCopyScenesAtIndex_Cn_Doc_Action {
	type: IContentDocActionTypes.COPY_SCENES_AT_INDEX_CN_DOC;
	payload: { oldToNewIdDict: {[id: string]: string}; sceneIds: string[]; index: number };
}
export interface IOnCopyScenesAtIndex_Cn_Doc {
	(sceneProps: {
		newSceneIds: string[];
		sceneIds: string[];
		index: number;
	}): IOnCopyScenesAtIndex_Cn_Doc_Action;
}

export interface IOnSetTransition_Cn_Doc_Payload {
	ids: string[];
	category: ITransitionCategoryTypes;
	type: ITransitionEffectTypes;
	duration: number;
	delay: number;
}

export interface IOnSetComponentTransition_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_COMPONENT_TRANSITION_CN_DOC;
	payload: IOnSetTransition_Cn_Doc_Payload;
}

export interface IOnSetComponentTransition_Cn_Doc {
	(
		payload: IOnSetTransition_Cn_Doc_Payload
	): IOnSetComponentTransition_Cn_Doc_Action;
}

interface IOnSetComponentAction_Cn_Doc_Payload extends IBaseActionData {
	ids: string[];
}

export interface IOnSetComponentAction_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_COMPONENT_ACTION_CN_DOC;
	payload: IOnSetComponentAction_Cn_Doc_Payload;
}

export interface IOnSetComponentAction_Cn_Doc {
	(
		payload: IOnSetComponentAction_Cn_Doc_Payload
	): IOnSetComponentAction_Cn_Doc_Action;
}

type IOnSetProjectColors_Ed_Doc_Payload = IProjectColor[];

export interface IOnSetProjectColors_Ed_Doc_Action {
	type: IEditorDocActionTypes.ADD_PROJECT_COLORS_ED_DOC;
	payload: IOnSetProjectColors_Ed_Doc_Payload;
}

export interface IOnSetProjectColors_Ed_Doc {
	(
		payload: IOnSetProjectColors_Ed_Doc_Payload
	): IOnSetProjectColors_Ed_Doc_Action;
}

export interface IChange_Cn_Doc_Action {
	type: IContentDocActionTypes.CHANGE_CN_DOC_REDUX;
	contentDoc: IContentDoc;
}

export interface IOnTrainImage_Global {
	(img: ILoadedImageInfo): (dispatch: Dispatch) => void;
}

interface IOnAddImageTracking_Cn_Doc_Payload {
	type: ITrackingTypes.image;
	url: string;
	zmlId: string;
}

interface IOnAddFaceTracking_Cn_Doc_Payload {
	type: ITrackingTypes.face;
}

type IOnAddTracking_Cn_Doc_Payload_Union = IOnAddImageTracking_Cn_Doc_Payload | IOnAddFaceTracking_Cn_Doc_Payload;

export interface IOnAddTracking_Cn_Doc_Action {
	type: IContentDocActionTypes.ADD_TRACKING_CN_DOC;
	payload: IOnAddTracking_Cn_Doc_Payload_Union;
}

export interface IOnAddTracking_Cn_Doc {
	(
		payload: IOnAddTracking_Cn_Doc_Payload_Union
	): IOnAddTracking_Cn_Doc_Action;
}

export interface IOnSetTargetDisplayInfo_Ed_Doc_Action {
	type: IEditorDocActionTypes.SET_TARGET_DISPLAY_INFO;
	payload: ITargetDisplayInfo_Ed_Doc;
}

export interface IOnSetTargetDisplayInfo_Ed_Doc {
	(payload: ITargetDisplayInfo_Ed_Doc): IOnSetTargetDisplayInfo_Ed_Doc_Action;
}

interface IOnSetActiveSceneChildren_Cn_Doc_Payload {
	sceneChildren: string[];
	activeSceneId: string;
}

export interface IOnSetActiveSceneChildren_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_ACTIVE_SCENE_CHILDREN;
	payload: IOnSetActiveSceneChildren_Cn_Doc_Payload;
}

export interface IOnSetActiveSceneChildren_Cn_Doc {
	(payload: IOnSetActiveSceneChildren_Cn_Doc_Payload): IOnSetActiveSceneChildren_Cn_Doc_Action;
}


