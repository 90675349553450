import React from 'react';
import './DragElement.global.scss';

interface IParentProps {
	top: string;
	left: string;
	width: string;
	height: string;
}

interface IReduxProps {}

const DragElement: React.SFC<IParentProps & IReduxProps> = ({
	top,
	left,
	width,
	height,
}) => {
	return (
		<div className={'zcp-drag-cursor'} style={{ top, left, width, height }} />
	);
};

export default DragElement;
