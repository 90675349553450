import React from "react";
import { useDispatch } from "react-redux";
import { IText } from "../../../../../../r3f/r3f-components/component-data-structure";
import { IMultipleEntityProps_Cn_Doc } from "../../../../../../../store/actions";
import { onSetMultipleComponentProps_Cn_Doc } from "../../../../../../../store/actions";
import TextArea from '../../../../../Inputs/TextArea/TextArea';
import CSS from './TextContent.scss'

interface IParentProps {
  entity: IText;
  selectedEntityIds: string[];
}

const TextContent = ({
  entity,
  selectedEntityIds
}: IParentProps) => {
  const dispatch = useDispatch();
  return (
    <div>
      <div className={CSS.Title}>
        Text
      </div>
        <TextArea
          value={entity.text}
          onChange={(e) => {
            const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] =
              [];
            for (let i = 0; i < selectedEntityIds.length; i++) {
              multipleEntityProps_Cn_Doc[i] = {
                id: selectedEntityIds[i],
                text: e.target.value,
              };
            }
            dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
          }}
        />
    </div>
  );
}

export default React.memo(TextContent);