import React from 'react';
import { ITuple4 } from '../../../../../r3f/r3f-components/component-data-structure';

import CSS from './ColorCircle.scss';

interface IParentProps {
	colorId?: string;
	isActive: boolean;
	disabled?: boolean;
	rgba: ITuple4;
	rectangle?: boolean; 
	onClick?: (args: {
		colorId: string;
		rgba: ITuple4;
		e: React.MouseEvent;
	}) => any;
}

const ColorCircle: React.SFC<IParentProps> = ({
	isActive,
	colorId = 'no-id',
	rgba,
	disabled,
	rectangle, 
	onClick,
}) => {
	return (
		<div
			className={`${CSS.OuterColorContainer} 
						${isActive ? CSS.Active : null}
						${rectangle?  CSS.RectangleShape: CSS.RoundShape}`}
		>
			<div
				className={CSS.ColorContainer}
				onClick={e => {
					if (onClick && !disabled) onClick({ colorId, rgba, e });
				}}
			>
				<div className={CSS.ColorContainerBackground}>
					<div
						className={CSS.ColorContent}
						style={{ backgroundColor: `rgba(${rgba})` }}
					/>
				</div>
				<div className={CSS.ColorContainerShadowMask} />
			</div>
		</div>
	);
};

export default ColorCircle;
