import React, {
	FunctionComponent,
	memo,
	useMemo,
} from 'react';
import { Object3D, Texture } from 'three';
import * as THREE from 'three';
import { ITuple3, ITuple4 } from '../component-data-structure';
import {
	createRoundedRectShape,
	createRoundedRectInnerForm,
} from '../utils/general';
import ShapeMesh from './ShapeMesh';
import { ISharedHOCProps } from '../types';

export interface IRoundedRectParentProps {
	visible?: boolean;
	name?: string;
	rotation?: ITuple3;
	position?: ITuple3;
	scale: ITuple3;
	color?: ITuple4;
	depthWrite?: boolean;
	renderOrder?: number;
	onUpdate?: (self: Object3D) => any;
	radius?: number;
	opacity: number;
	stackedAlpha?: boolean;
	bw?: number;
	texture?: Texture;
}

const ShapeRect: FunctionComponent<IRoundedRectParentProps & ISharedHOCProps> = ({
	rotation: r = [0, 0, 0],
	position: p = [0, 0, 0],
	scale: s,
	color,
	onUpdate,
	onPointerUp,
	onPointerDown,
	onPointerMove,
	onDoubleClick,
	renderOrder,
	opacity,
	radius = 0,
	bw = 0,
	depthWrite,
	texture,
	name = '',
	stackedAlpha = false
}) => {
	const rotation = useMemo(() => r as ITuple3,[r]);
	const position = useMemo(
		() =>
			[p[0] - s[0] - 2 * bw, p[1] - s[1] - 2 * bw, p[2] - 2 * bw] as ITuple3,
		[p, s, bw]
	);
	const roundedRect = useMemo(() => {
		const path = new THREE.Path();
		const holePath = createRoundedRectInnerForm(path, s, radius, bw);
		return createRoundedRectShape(s, radius, holePath);
	}, [s, radius, bw]);

	return (
		<ShapeMesh
			name={name}
			key='roundedRect'
			renderOrder={renderOrder}
			rotation={rotation}
			position={position}
			scale={s as ITuple3}
			stackedAlpha={stackedAlpha}
			color={color}
			opacity={opacity}
			depthWrite={depthWrite}
			texture={texture}
			shape={roundedRect}
			onUpdate={onUpdate || undefined}
			onPointerUp={onPointerUp || undefined}
			onPointerDown={onPointerDown || undefined}
			onPointerMove={onPointerMove || undefined}
			onDoubleClick={onDoubleClick || undefined}
		/>
	);
};

ShapeRect.displayName = 'shapeRect';

export default memo(ShapeRect);
