import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing"
import * as Automerge from 'automerge';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from './store/reducers/index';
import App from './components/App/App';
import BrowserError from './components/dom/BrowserError/BrowserError';

import './index.scss';
import { setupWebSocket } from './syncdoc';
import * as settings from './settings';
import { isSupportedBrowser } from './utils/compatibility'

// Check browser compatibility & show upgrade message if browser can't support designer
const browserIsCompatible = isSupportedBrowser();

//Setting up socket connection & initialize docSet
export const docSet = new Automerge.DocSet();

// Initialize sentry before React
Sentry.init({
	dsn: settings.SENTRY_DSN,
	integrations: [new Integrations.BrowserTracing()],
	environment: settings.ENVIRONMENT,
	release: settings.VERSION,
	tracesSampleRate: 4.0,
});


// Setting up redux store & middlewares
let composeEnhancers = compose;
if (settings.DEBUG) {
	composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({ trace: true, traceLimit: 25 }) || compose;
}

export const store = createStore(
	reducer,
	composeEnhancers(applyMiddleware(thunk))
);

setupWebSocket(store);

render(
	browserIsCompatible ? 
	<Sentry.ErrorBoundary fallback={null}>
		<Provider store={store}>
			<App store={store} />
		</Provider>
	</Sentry.ErrorBoundary>
	: 
	<BrowserError />
	,
	document.getElementById('root') || document.createElement('div') // for testing purposes
);
