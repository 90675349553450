// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Checkbox--2y_ve{width:16px;height:16px;border:1px solid #b2c4d7;display:flex;align-items:center;justify-content:center;border-radius:3px}.Checkbox--2y_ve.Active--2_UkS{background-color:#4a90e2;border-color:#4a90e2}.ChkbxLabel--WKD8o{margin-left:8px}", ""]);
// Exports
exports.locals = {
	"Checkbox": "Checkbox--2y_ve",
	"Active": "Active--2_UkS",
	"ChkbxLabel": "ChkbxLabel--WKD8o"
};
module.exports = exports;
