import React, { useState, useRef, useEffect, useCallback, useMemo, memo } from 'react';
import {
	IDesignerState,
	ILoadedImageInfo,
	ILoadedUserVideoInfo,
	ILoadedExternalVideoInfo,
	ILoadedModel3dInfo,
} from '../../../../../typings';
import {
	onSetEntityMenuDragActive,
	onSetDraggedEntityInfo,
	onAddImage_Global,
	onAddVideo_Global,
	onAddModel3d_Global,
} from '../../../../store/actions';
import DragItem from './DragItem/DragItem';
import CSS from './DragContainer.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IComponentType } from '../../../r3f/r3f-components/component-data-structure';
import { ENTITY_MENU_WIDTH, NUM_OF_ENTITIES_DISPLAYED } from '../../../../utils';

export type ILoadedEntityInfo =
	| ILoadedImageInfo
	| ILoadedUserVideoInfo
	| ILoadedExternalVideoInfo
	| ILoadedModel3dInfo;

interface IParentProps {
	className?: string;
	loadableEntityCount?: number;
	columns: number;
	lightHoverCover?: boolean;
	entityType: IComponentType;
	activePagination?: number;
	showTitle: boolean;
}

const DragContainer: React.FunctionComponent<IParentProps> = ({
	className = '',
	columns,
	entityType,
	lightHoverCover = false,
	loadableEntityCount: totalNumOfEntity,
	activePagination,
	showTitle
}) => {
	const dispatch = useDispatch();

	const loadedImages = useSelector((state: IDesignerState) => state.userReducer.loadedImages);
	const loaded3dModels = useSelector((state: IDesignerState) => state.userReducer.loaded3dModels);
	const loadedVideos = useSelector((state: IDesignerState) => state.userReducer.loadedVideos);
	const dragedElInfo = useSelector((state: IDesignerState) => state.userReducer.entityDragInfo);
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId);

	const containerRef = useRef(null);
	const [slideArray, setSlideArray] = useState([]);
	const slidesRef = useRef<JSX.Element[]>([])

	const pointerDownHandler = useCallback( (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		entity: ILoadedEntityInfo
	) => {
		dispatch(onSetEntityMenuDragActive(true));
		const domRect = document.getElementById(entity.id).getBoundingClientRect();

		switch (entityType) {
			case IComponentType.Model3d:
				const model = entity as ILoadedModel3dInfo;
				// console.log('loadedModelInfo', model)
				dispatch(onSetDraggedEntityInfo({
					id: model.id,
					title: model.name,
					zmlFileId: model.zmlFileId,
					dimensions: [domRect.width, domRect.height],
					dimensions3d: model.dimensions,
					clickOffset: [e.clientX - domRect.left + ENTITY_MENU_WIDTH, e.clientY - 50 - domRect.top],
					type: IComponentType.Model3d,
					model3dUrl: model.model3dUrl,
					thumbnailUrl: model.thumbnailUrl,
					aspectRatio: model.aspectRatio,
				}));
				break;
			case IComponentType.Image:
				const image = entity as ILoadedImageInfo;
				dispatch(onSetDraggedEntityInfo({
					id: image.id,
					title: image.name,
					zmlFileId: image.zmlFileId,
					dimensions: [domRect.width, domRect.height],
					clickOffset: [e.clientX - domRect.left + ENTITY_MENU_WIDTH, e.clientY - 50 - domRect.top],
					type: IComponentType.Image,
					url: image.url,
					aspectRatio: image.aspectRatio,
				}));
				break;
			case IComponentType.Video:
				const video = entity as ILoadedUserVideoInfo;
				const adjOffset = [0, 0];
				let dimensions = [0, 0]
				if (video.aspectRatio > 1) {
					const height = domRect.width / video.aspectRatio;
					adjOffset[1] = (domRect.height - height) / 2;
					dimensions = [domRect.width, height]
				} else {
					const width = domRect.height * video.aspectRatio;
					adjOffset[0] = (domRect.width - width) / 2
					dimensions = [domRect.height * video.aspectRatio, domRect.height]
				}

				dispatch(onSetDraggedEntityInfo({
					id: video.id,
					title: video.name,
					zmlFileId: video.zmlFileId,
					dimensions: dimensions as [number, number],
					clickOffset: [e.clientX - domRect.left - adjOffset[0] + ENTITY_MENU_WIDTH, e.clientY - 50 - domRect.top - adjOffset[1]],
					type: IComponentType.Video,
					videoUrl: video.videoUrl,
					thumbnailUrl: video.thumbnailUrl,
					mp4Url: video.mp4Url,
					aspectRatio: video.aspectRatio,
					source: video.source,
					hasAlpha: video.hasAlpha
				}));
				break;
			default:
				break;
		}},[
			dispatch,
			onSetEntityMenuDragActive, 
			entityType, 
			onAddImage_Global, 
			activeSceneId, 
			onAddVideo_Global,  
			onSetDraggedEntityInfo
		]);


	const doubleClick = useCallback( (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		entity: ILoadedEntityInfo
	) => {
		dispatch(onSetEntityMenuDragActive(false));
		switch (entityType) {
			case IComponentType.Model3d: {
				const model = entity as ILoadedModel3dInfo
				dispatch(onAddModel3d_Global({
					activeSceneId,
					position: [0, 0, model.dimensions[2] / model.dimensions[1]],
					entity: model,
				}));
				break;
			}
			case IComponentType.Image: {
				dispatch(onAddImage_Global({
					activeSceneId,
					position: [0, 0, 0],
					entity: entity as ILoadedImageInfo,
				}));
				break;
			}
			case IComponentType.Video: {
				const {
					id,
					zmlFileId,
					videoUrl,
					name,
					aspectRatio,
					thumbnailUrl,
					mp4Url,
					source,
					hasAlpha
				} = entity as ILoadedUserVideoInfo;
				dispatch(onAddVideo_Global({
					activeSceneId,
					position: [0, 0, 0],
					entity: {
						id,
						zmlFileId,
						videoUrl,
						aspectRatio,
						name,
						thumbnailUrl,
						mp4Url,
						source,
						externalId: null,
						scalesInverted: [false, false],
						hasAlpha
					},
				}));
				break;
			}
			default:
				break;
		}  
	}, [
		dispatch,
		onSetEntityMenuDragActive, 
		entityType, 
		onAddModel3d_Global, 
		onAddImage_Global, 
		onAddVideo_Global
	])

	// Add skeleton slides. Amount depends on pagination and entities still to be loaded
	useEffect(() => {
		if(totalNumOfEntity === 0 || activePagination ===  null) return; 
		if (activePagination === 1) slidesRef.current = [];
		const numberEntitiesAlreadyLoaded = (NUM_OF_ENTITIES_DISPLAYED * columns) * (activePagination - 1);
		const entitiesToLoad =  Math.max(totalNumOfEntity - numberEntitiesAlreadyLoaded, 0); 
		const dummiesToShow = Math.min((NUM_OF_ENTITIES_DISPLAYED * columns), entitiesToLoad); 
	
		for (let i = 0; i < dummiesToShow; i++) {
			slidesRef.current.push(
				<div 
					key={`${i}-${activePagination}`}
					style={{height:`${columns === 2 ? 96 : 192}px`}}
					className={CSS.DummySlide}>
				</div>
			)
		}
	}, [columns, activePagination, totalNumOfEntity]); 

	const loadedEntities = useMemo(() => {
		switch (entityType) {
			case IComponentType.Video:
				return loadedVideos;
			case IComponentType.Image:
				return loadedImages;
			case IComponentType.Model3d:
				return loaded3dModels;
			default:
				break;
		}
	}, [entityType, loadedVideos, loadedImages, loaded3dModels]);

	// Everytime the loaded entity array updates with a new entity, swap a skeleton slide with that entity
	useEffect( () => {
		// let loadedEntities = entityType === IComponentType.Video ? loadedVideos : loadedImages; 
		if(!loadedEntities?.length) return;
		if (activePagination === null) slidesRef.current = [];
		for (let i = 0; i < loadedEntities.length; i++) {
			const loadedEntityInfo = loadedEntities[i];
			slidesRef.current[i] = <DragItem
				showTitle={showTitle}
				key={loadedEntityInfo.id}
				columns={columns}
				lightHoverCover={lightHoverCover}
				entityType={entityType}
				dragedElInfo={dragedElInfo}
				loadedEntityInfo={loadedEntityInfo as any}
				onDoubleClick={e => {
					doubleClick(e, loadedEntityInfo as any)
				}}
				onPointerDown={e => {
					pointerDownHandler(e, loadedEntityInfo as any)
				}}
				onPointerUp={e => () => {}}
			/>;
		}
		setSlideArray([...slidesRef.current])
	}, [
		entityType, 
		loadedEntities, 
		setSlideArray, 
		pointerDownHandler, 
		doubleClick, 
		slidesRef, 
		totalNumOfEntity, 
		activePagination
	]); 

	const gridTemplateColumns = Array(columns)
		.fill(`calc(${100 / columns}% - ${8 - 8 / columns}px)`)
		.join(' ');

	return (
		<div className={className} ref={containerRef}>
			<div className={CSS.DragContainer} style={{ gridTemplateColumns }}>
				{slideArray}
			</div>
		</div>
	);
};

export default memo(DragContainer);
