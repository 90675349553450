import { useEffect, useState, useRef } from 'react';

export const useTimeOutOnMount = (cb: () => any, ms: number) => {
	useEffect(() => {
		const timout = setTimeout(() => cb(), ms);
		return () => clearTimeout(timout);
	}, []);
};

export const useComponentWillMount = (fn: () => any) => {
	const willMount = useRef(true);
	if (willMount.current) {
		fn();
	}
	willMount.current = false;
};

// export const useFadeInOnMount = async (
// 	cls: string[],
// 	fdInCls: string,
// 	ms: number
// ): Promise<string[]> => {
// 	const [classes, setClasses] = useState<string[]>(cls);
// 	return new Promise((resolve, reject) => {
// 		useTimeOutOnMount(() => {
// 			setClasses(prevState => [...prevState, fdInCls]);
// 			resolve(classes);
// 		}, ms);
// 	});
// };

export const useCombinedRefs = (...refs: any[]) => {
	const targetRef = useRef();
	useEffect(() => {
		refs.forEach(ref => {
			if (!ref) return;

			if (typeof ref === 'function') {
				ref(targetRef.current);
			} else {
				ref.current = targetRef.current;
			}
		});
	}, [refs]);
	return targetRef;
};
