import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../../../typings';
import { onSetActionData_Global } from '../../../../../../../store/actions';
import EmailSummary from './Summary/Summary';
import CSS from './ComposeEmail.scss';
import { parseEmailAddress } from '../../../../../../../utils';
import { ITriggerTypes, IEmailOptions, IActionCategory, } from '../../../../../../r3f/r3f-components/component-data-structure';
import { TextInput } from '../../../../../Inputs';
import TextArea from '../../../../../Inputs/TextArea/TextArea';
import ActionBtn from '../../../../../buttons/ActionBtn/ActionBtn';
enum IEmailParams {
	recipient = 'recipient',
	subject = 'subject',
	body = 'body',
}

interface IParentProps {
	triggerType: ITriggerTypes;
	setShowActionSummary: (value: React.SetStateAction<boolean>) => void;
	showActionSummary: boolean;
}

const ComposeEmail: React.FunctionComponent<IParentProps> = ({
	triggerType,
	showActionSummary,
	setShowActionSummary,
}) => {
	// Redux
	const dispatch = useDispatch();
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const id = selectedEntityIds[0];
	const actionsDict = useSelector((state: IDesignerState) => state.editorReducer.editorDoc?.actions?.[id] || {});
	const activeTrigger = useSelector((state: IDesignerState) => state.editorReducer.editorDoc.activeTriggers?.[id] || ITriggerTypes.onLoad);
	const editorEmailOptions = actionsDict?.[activeTrigger]?.emailOptions || ({} as IEmailOptions);

	// State
	const { recipient = null, subject = null, body = null } = editorEmailOptions;
	const [emailRecipient, setEmailRecipient] = useState<string>(recipient || '');
	const [emailSubject, setEmailSubject] = useState<string>(subject || '');
	const [emailBody, setEmailBody] = useState<string>(body || '');
	const [recipientError, setRecipientError] = useState<string>('');
	const [subjectError, setSubjectError] = useState<string>('');
	const [bodyError, setBodyError] = useState<string>('');
	const [isValidated, setIsValidated] = useState<boolean>(parseEmailAddress(emailRecipient) && !!emailSubject.length && !!emailBody.length);

	const changeHandler = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		inputField: IEmailParams
	) => {
		let emailAddressIsValid = false;
		let emailSubjectIsValid = false;
		let emailBodyIsValid = false;
		switch (inputField) {
			case IEmailParams.recipient:
				setEmailRecipient(e.target.value);
				setRecipientError('');
				emailAddressIsValid = parseEmailAddress(e.target.value).isValid;
				emailSubjectIsValid = !!emailSubject.length;
				emailBodyIsValid = !!emailBody.length;
				break;
			case IEmailParams.subject:
				setEmailSubject(e.target.value);
				setSubjectError('');
				emailAddressIsValid = parseEmailAddress(emailRecipient).isValid;
				emailSubjectIsValid = !!e.target.value.length;
				emailBodyIsValid = !!emailBody.length;
				break;
			case IEmailParams.body:
				setEmailBody(e.target.value);
				setBodyError('');
				emailAddressIsValid = parseEmailAddress(emailRecipient).isValid;
				emailSubjectIsValid = !!emailSubject.length;
				emailBodyIsValid = !!e.target.value.length;
				break;
			default:
				break;
		}

		setIsValidated(
			emailAddressIsValid && emailSubjectIsValid && emailBodyIsValid
		);
	};

	const blurHandler = (
		e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
		inputField: IEmailParams
	) => {
		switch (inputField) {
			case IEmailParams.recipient:
				const { isValid } = parseEmailAddress(emailRecipient);
				if (!isValid) setRecipientError('Invalid email address');
				break;
			case IEmailParams.subject:
				if (!emailSubject.length) setSubjectError('Subject missing');
				break;
			case IEmailParams.body:
				if (!emailBody.length) setBodyError('Message missing');
				break;
			default:
				break;
		}
	};

	const keyDownHandler = (
		e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
		inputField: IEmailParams
	) => {
		if (e.keyCode !== 13) return;
		const form = e.currentTarget.form as any;
		switch (inputField) {
			case IEmailParams.recipient:
				e.preventDefault();
				const { isValid } = parseEmailAddress(emailRecipient);
				if (!isValid) return;
				if (!emailSubject.length) form.elements[1].focus();
				else if (!emailBody.length) form.elements[2].focus();
				break;
			case IEmailParams.subject:
				e.preventDefault();
				if (!emailSubject.length) return;
				if (!emailRecipient.length) form.elements[0].focus();
				else if (!emailBody.length) form.elements[2].focus();
				break;
			case IEmailParams.body:
				if (!emailBody.length) return;
				if (!emailRecipient.length) form.elements[0].focus();
				else if (!emailSubject.length) form.elements[1].focus();
				break;
			default:
				break;
		}
	};

	const goToEmailSummary = () => {
		dispatch(onSetActionData_Global({
			ids: selectedEntityIds,
			triggerType: triggerType,
			selectedActionCategory: IActionCategory.composeEmail,
			emailOptions: {
				recipient: emailRecipient,
				subject: emailSubject,
				body: emailBody,
			},
		}));
		setShowActionSummary(true);
	};

	return !showActionSummary ? (
		<div className={CSS.FlexContainer}>
			<form>
				<div className={CSS.Description}>Enter the recipient</div>
				<TextInput
					className={CSS.EmailInputField}
					value={emailRecipient}
					placeholder={"myemail@email.com"}
					onChange={e => changeHandler(e, IEmailParams.recipient)}
					onBlur={e => blurHandler(e, IEmailParams.recipient)}
					onKeyDown={e => keyDownHandler(e, IEmailParams.recipient)}
				/>
				<div className={CSS.Error}>{recipientError}</div>
				<div className={CSS.Description}>Add a subject line</div>
				<TextInput
					className={CSS.EmailInputField}
					value={emailSubject}
					placeholder={"My subject is"}
					onChange={e => changeHandler(e, IEmailParams.subject)}
					onBlur={e => blurHandler(e, IEmailParams.subject)}
					onKeyDown={e => keyDownHandler(e, IEmailParams.subject)}
				/>
				<div className={CSS.Error}>{subjectError}</div>
				<div className={CSS.Description}>Message</div>
				<TextArea
					value={emailBody}
					placeholder={"My message is"}
					onChange={e => changeHandler(e, IEmailParams.body)}
					onBlur={e => blurHandler(e, IEmailParams.body)}
					onKeyDown={e => keyDownHandler(e, IEmailParams.body)}
				/>
				<div className={CSS.Error}>{bodyError}</div>
			</form>
			<ActionBtn
				disabled={!isValidated}
				onClick={goToEmailSummary}
				className={CSS.SubmitBtn}
			>Done</ActionBtn>
		</div>
	) : (
		<EmailSummary
			recipient={emailRecipient}
			subject={emailSubject}
			body={emailBody}
		/>
	);
};

export default React.memo(ComposeEmail);
