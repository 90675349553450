import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const volumeOffIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="40px"
			height="40px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 40 40'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g
				id="Atom/Icon/Volume/min-"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Group-5"
					transform="translate(9.000000, 9.000000)"
					stroke={hexFill}
				>
					<g id="Group-2">
						<g id="Group">
							<g id="Group-4" transform="translate(4.132774, 0.000000)">
								<path
									d="M8.92612102,1.50442762 C8.7994504,1.48630001 8.66586432,1.51649561 8.55538698,1.59931114 L1.36722562,6.98766883 L1.36722562,15.004335 L8.55515408,20.3990583 C8.64172633,20.464033 8.74704593,20.4991592 8.85528854,20.4991592 C8.99335973,20.4991592 9.11835973,20.4431948 9.20884193,20.3527126 C9.29932414,20.2622304 9.35528854,20.1372304 9.35528854,19.9991592 L9.35528854,1.99938495 C9.35528854,1.89124194 9.32022687,1.78601377 8.92612102,1.50442762 Z"
									id="Rectangle"
									transform="translate(5.361257, 11.000000) scale(1, -1) translate(-5.361257, -11.000000) "
								></path>
								<g
									id="Group-3"
									transform="translate(10.867226, 8.000000)"
									strokeLinecap="round"
								>
									<path
										d="M0,6 C1.27450327,6 2.30769231,4.65685425 2.30769231,3 C2.30769231,1.34314575 1.27450327,0 0,0"
										id="Path"
									></path>
								</g>
							</g>
							<path
								d="M5.5,6.5 L2,6.5 C1.58578644,6.5 1.21078644,6.66789322 0.939339828,6.93933983 C0.667893219,7.21078644 0.5,7.58578644 0.5,8 L0.5,14 C0.5,14.4142136 0.667893219,14.7892136 0.939339828,15.0606602 C1.21078644,15.3321068 1.58578644,15.5 2,15.5 L5.5,15.5 L5.5,6.5 Z"
								id="Rectangle-Copy"
								transform="translate(3.000000, 11.000000) scale(1, -1) translate(-3.000000, -11.000000) "
							></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default volumeOffIcon;
