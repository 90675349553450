import React from 'react';
import { TextInput } from '../../../../../../Inputs';
import CSS from './FormSection.scss';
import { IContactErrors, IContactParams, IInputDict } from '../SaveContact';

interface IParentProps {
    currentValues: IInputDict; 
    inputErrors: IContactErrors;
    isNameEmpty: boolean; 
    isPhoneEmpty: boolean; 
    onChange: (e: React.ChangeEvent<HTMLInputElement>, inputField: IContactParams ) => any; 
    blurHandler: (e: React.FocusEvent<HTMLInputElement>, inputField: IContactParams) => any; 
    keydownHandler: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, secondTab?: boolean) => any; 
}

const FromSectionOne: React.FunctionComponent<IParentProps> = ({
    currentValues, 
    inputErrors,
    isNameEmpty, 
    isPhoneEmpty,
    onChange, 
    blurHandler, 
    keydownHandler,
}) => {

	return (
        <>
            <div className={CSS.Description}>Name or nickname</div>
            <TextInput
                value={currentValues[IContactParams.name] || ""}
                focused={isNameEmpty}
                placeholder={"Archie"}
                className={CSS.Input}
                onChange={e => onChange(e, IContactParams.name)}
                onBlur={e => blurHandler(e, IContactParams.name)}
                onKeyDown={keydownHandler}
            />
            <div className={CSS.Error}>{inputErrors.name}</div>

            <div className={`${CSS.FlexRow} ${CSS.HelperRow}`}>
                <div className={CSS.Description}>Phone number</div>
            </div>
            <TextInput
                value={currentValues.phone || ""}
                focused={isPhoneEmpty}
                placeholder={"Area code - 123 4567 890"}
                className={CSS.Input}
                onChange={e => onChange(e, IContactParams.phone)}
                onBlur={e => blurHandler(e, IContactParams.phone)}
                onKeyDown={keydownHandler}
            />
            <div className={CSS.Error}>{inputErrors.phone}</div>

            <div className={CSS.Description}>Company</div>
            <TextInput
                value={currentValues.company || ""}
                placeholder={"Optional"}
                className={CSS.Input}
                onChange={e => onChange(e, IContactParams.company)}
                onBlur={e => blurHandler(e, IContactParams.company)}
                onKeyDown={keydownHandler}
            />

            <div className={CSS.Description}>Email address</div>
            <TextInput
                value={currentValues.email || ""}
                placeholder={"Optional"}
                className={CSS.Input}
                onChange={e => onChange(e, IContactParams.email)}
                onBlur={e => blurHandler(e, IContactParams.email)}
                onKeyDown={keydownHandler}
            />
            <div className={CSS.Error}>{inputErrors.email}</div>

            <div className={CSS.Description}>Website</div>
            <TextInput
                value={currentValues.website || ""}
                placeholder={"Optional"}
                className={CSS.Input}
                onChange={e => onChange(e, IContactParams.website)}
                onBlur={e => blurHandler(e, IContactParams.website)}
                onKeyDown={keydownHandler}
            />
            <div className={CSS.Error}>{inputErrors.website}</div>
        </>
    )
}

export default FromSectionOne; 