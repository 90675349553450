import React from 'react';
import { createPortal } from 'react-dom';
import './BackgroundDivOverlay.global.scss';

interface IParentProps {
	className?: string;
	children: any;
	onClose: (e?: React.MouseEvent<HTMLDivElement>) => any;
	appendToElId?: string;
	zIndex?: number;
    backgroundId?: string; 
}

const BackgroundDivOverlay: React.FunctionComponent<IParentProps> = ({
	children,
	appendToElId = 'root',
	onClose,
	zIndex: zI,
    backgroundId
}) => {
    const zIndex = typeof zI === 'undefined' ? 999 : zI;

    return createPortal(
        <>
            <div className={"background-overlay-outer-container"}>
                {children}
            </div>
            <div 
                className={"background-div-overlay"} 
                onClick={onClose}
                style={{ zIndex }}
                id={backgroundId}
            ></div>
        </>
    , document.getElementById(appendToElId) 
	);
};

export default BackgroundDivOverlay;