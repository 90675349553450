// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../Content.scss");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".Title--3XDlx{}", ""]);
// Exports
exports.locals = {
	"Title": "Title--3XDlx " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["Title"] + ""
};
module.exports = exports;
