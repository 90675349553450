import React, { useState, useRef, useEffect } from 'react';
import * as colorconvert from 'color-convert';

import { useComponentWillMount } from '../../../utils';

import DragArea from './DragArea/DragArea';
import HexInput from './ColorInputs/HexInput/HexInput';
import AlphaInput from './ColorInputs/AlphaInput/AlphaInput';
import RgbInputs255 from './ColorInputs/RgbInputs_255/RgbInputs_255';
import RgbInputs1 from './ColorInputs/RgbInputs_1/RgbInputs_1';
import AlphaSlider from './ColorInputs/AlphaSlider/AlphaSlider';
import HueSlider from './ColorInputs/HueSlider/HueSlider';
// import EyeDropperIcon from './assets/eyedropper';
// import EyeDropperSvgUrl from './assets/eyedropper-icon.svg';

import './Colorpicker.global.scss';

export type ITuple3 = [number, number, number];
export type ITuple4 = [number, number, number, number];

export const countDecimals = (value: number) => {
	if (Math.floor(value) === value) return 0;
	return value.toString().split('.')[1].length || 0;
};

// interface ISwatchReturnVals {
// 	saturation: number;
// 	value: number;
// }

interface IParentProps {
	showRGBA?: boolean;
	rgba: ITuple4;
	onChange: (rgba: ITuple4) => any;
	onSubmit: (rgba: ITuple4) => any;
}

const ColorPicker: React.SFC<IParentProps> = ({
	rgba,
	showRGBA,
	onChange,
	onSubmit,
}) => {
	const swatchEl = useRef(null);
	const [hex, setHex] = useState<string>();
	const [rgb, setRgb] = useState<ITuple3>();
	const [alpha, setAlpha] = useState<number>();
	const [hue, setHue] = useState<number>();
	const [saturation, setSaturation] = useState<number>();
	const [value, setValue] = useState<number>();
	const [swatchBkgrColor, setSwatchBkgrColor] = useState<string>();
	const [swatchActive, setSwatchActive] = useState<boolean>(false);

	const setColorVariables = () => {
		const [r, g, b, a] = rgba;
		const hex = colorconvert.rgb.hex([r, g, b]);
		setRgb([r, g, b]);
		setAlpha(a);
		setHex(hex);
		const hsv = colorconvert.rgb.hsv([r, g, b]);
		setSaturation(hsv[1]);
		setValue(hsv[2]);
		if (swatchActive) return;
		setHue(hsv[0]);
		const bkgrCol = colorconvert.hsv.hex([hsv[0], 100, 100]);
		setSwatchBkgrColor(colorconvert.hsv.hex([hsv[0], 100, 100]));
	};

	useComponentWillMount(() => setColorVariables()); // Run before render;
	useEffect(() => {
		setColorVariables();
	}, [rgba]); // Run if rgba input changes

	// const onClickHandler = () => {
	// 	const canvas = document.getElementById('canvas') as HTMLCanvasElement;
	// 	var gl = canvas.getContext('webgl');
	// 	canvas.addEventListener('mousemove', e => {
	// 		const pixels = new Uint8Array(
	// 			gl.drawingBufferWidth * gl.drawingBufferHeight * 4
	// 		);
	// 		gl.readPixels(
	// 			0,
	// 			0,
	// 			gl.drawingBufferWidth,
	// 			gl.drawingBufferHeight,
	// 			gl.RGBA,
	// 			gl.UNSIGNED_BYTE,
	// 			pixels
	// 		);
	// 		const rgba = [
	// 			pixels[4 * (e.pageY * gl.drawingBufferWidth + e.pageX)],
	// 			pixels[4 * (e.pageY * gl.drawingBufferWidth + e.pageX) + 1],
	// 			pixels[4 * (e.pageY * gl.drawingBufferWidth + e.pageX) + 2],
	// 			pixels[4 * (e.pageY * gl.drawingBufferWidth + e.pageX) + 3],
	// 		];
	// 	});
	// };

	return (
		<div className={'zcp-container'}>
			<HexInput
				colorconvert={colorconvert}
				hex={hex}
				onSubmit={newRgb => {
					if (onChange) onChange([...newRgb, alpha * 100] as ITuple4);
					if (onSubmit) onSubmit([...newRgb, alpha * 100] as ITuple4);
				}}
			/>
			<RgbInputs255
				rgb={rgb}
				updateColorValues={newRgb => {
					if (onSubmit) onSubmit([...newRgb, alpha * 100] as ITuple4);
				}}
			/>
			{showRGBA && (
				<RgbInputs1
					rgb={rgb}
					updateColorValues={newRgb => {
						if (onSubmit) onSubmit([...newRgb, alpha * 100] as ITuple4);
					}}
				/>
			)}
			<div className={'zcp-swatch-area'} ref={swatchEl}>
				<DragArea
					dragCursorDimensions={[8, 8]}
					swatchDimensions={
						swatchEl?.current
							? [swatchEl.current.clientWidth, swatchEl.current.clientHeight]
							: [null, null]
					}
					backgroundColor={swatchBkgrColor}
					value={value}
					saturation={saturation}
					onChange={swatchReturnVals => {
						setSwatchActive(true);
						const newRgb = colorconvert.hsv.rgb([
							hue,
							swatchReturnVals.saturation,
							swatchReturnVals.value,
						]);
						if (onChange && newRgb) {
							setRgb(newRgb);
							onChange([...newRgb, alpha * 100] as ITuple4);
						}
					}}
					onSubmit={() => {
						if (onSubmit && rgb) {
							onSubmit([...rgb, alpha * 100] as ITuple4);
						}
					}}
					onPointerUp={() => setTimeout(() => setSwatchActive(false), 10)} //TODO: refactor
				/>
				<div className={'zcp-swatch-saturation'}></div>
			</div>
			<div className={'zcp-flex-row'}>
				{/* <div className={'zcp-eye-dropper'} onClick={onClickHandler}>
					<EyeDropperIcon />
				</div> */}
				<HueSlider
					hue={hue}
					onChange={newHue => {
						setSwatchActive(false);
						const newRgb = colorconvert.hsv.rgb([
							(newHue * 360) / 250,
							saturation,
							value,
						]);
						if (onChange && newRgb) {
							onChange([...newRgb, alpha * 100] as ITuple4);
						}
					}}
					onSubmit={newHue => {
						const newRgb = colorconvert.hsv.rgb([
							(newHue * 360) / 250,
							saturation,
							value,
						]);
						if (onSubmit && newRgb)
							onSubmit([...newRgb, alpha * 100] as ITuple4);
					}}
				/>
			</div>
			<div className={'zcp-flex-row'}>
				<AlphaInput alpha={alpha} setAlpha={setAlpha} />
				<AlphaSlider
					hex={hex}
					alpha={alpha}
					onChange={alpha => {
						setAlpha(alpha);
						if (onChange) onChange([...rgb, alpha * 100] as ITuple4);
					}}
					onSubmit={alpha => {
						setAlpha(alpha);
						if (onSubmit) onSubmit([...rgb, alpha * 100] as ITuple4);
					}}
				/>
			</div>
		</div>
	);
};

export default ColorPicker;
