// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ActionBtn--1XZkN{border-radius:8px;background-color:#ef5332;color:#fff;cursor:pointer;font-size:16px;font-weight:500;padding:8px 32px}.ActionBtn--1XZkN:disabled{opacity:.5;cursor:default}", ""]);
// Exports
exports.locals = {
	"ActionBtn": "ActionBtn--1XZkN"
};
module.exports = exports;
