import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Html } from '@react-three/drei';
import { IDesignerState } from '../../../../typings';
import { bindActionCreators } from 'redux';
import {
	onRemoveEntities_Global,
	IOnRemoveEntities_Global,
	onSetMultipleComponentProps_Cn_Doc,
	IOnSetMultipleComponentProps_Cn_Doc,
	IMultipleEntityProps_Cn_Doc,
} from '../../../store/actions';
import { Vector3 } from '@react-three/fiber';
import TextArea from '../../dom/Inputs/TextArea/TextArea';
import CSS from './FloatingInput.scss';
import { ITuple2 } from '../r3f-components/component-data-structure';

interface IParentProps {
    position: Vector3; 
    text: string; 
	onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => any; 
	onUnmount: () => any; 
}
interface IReduxProps {
    selectedEntityIds: string[];
}

interface IDispatchProps {
	onRemoveEntities_Global: IOnRemoveEntities_Global;
	onSetMultipleComponentProps_Cn_Doc: IOnSetMultipleComponentProps_Cn_Doc;
}

const FloatingInput: FunctionComponent<IReduxProps & IDispatchProps & IParentProps> = ({
    position, 
    text, 
	selectedEntityIds,
	onSetMultipleComponentProps_Cn_Doc,
	onBlur, 
	onUnmount,
}) => {
	const wrapperRef = useRef();
  const timerRef = useRef(null);
	const [margins, setMargins] = useState<ITuple2>([0, 0]); 
	const [show, setShow] = useState(false);

	useEffect(() => {
		return unMountAndCleanup;
	}, []); 

  const unMountAndCleanup = () => {
    clearTimeout(timerRef?.current);
    onUnmount?.();
  }

	const onBlurHandler = (e: React.FocusEvent<HTMLTextAreaElement>) => {
		if(onBlur) onBlur(e); 
	}	

	const onMountHandler = (textAreaRef: React.MutableRefObject<HTMLTextAreaElement>) => {
    if (!textAreaRef?.current) return;
    setMargins([textAreaRef.current.offsetWidth / 2, textAreaRef.current.offsetHeight / 2]);
    setShow(true);
    timerRef.current = setTimeout(() => textAreaRef?.current?.focus?.(), 150);
    if (!ResizeObserver) return;

    function outputsize() {
      if (!textAreaRef?.current) return;
      setMargins([textAreaRef.current.offsetWidth / 2, textAreaRef.current.offsetHeight / 2]);
    }
    new ResizeObserver(outputsize).observe(textAreaRef.current);
  };

  return (
    <Html position={position} ref={wrapperRef}>
      <TextArea
        value={text}
        className={CSS.TextInput}
        style={{
          marginLeft: `-${margins[0]}px`,
          marginTop: `-${margins[1]}px`,
          opacity: !!margins[0] && !!margins[1] ? 1 : 0,
        }}
        focused={true}
        stopPropagation={true}
        onChange={(e) => {
          const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
          for (let i = 0; i < selectedEntityIds.length; i++) {
            multipleEntityProps_Cn_Doc[i] = {
              id: selectedEntityIds[i],
              text: e.target.value,
            };
          }
          onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc);
        }}
        onBlur={onBlurHandler}
        onMount={onMountHandler}
      />
    </Html>
  );
};

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	return {
		selectedEntityIds: state.userReducer.selectedEntityIds,
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onRemoveEntities_Global,
			onSetMultipleComponentProps_Cn_Doc,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(FloatingInput);
