// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CarouselContainer--1J7vf{width:100%;display:flex;align-items:center;margin-bottom:60px;overflow-x:scroll;-ms-overflow-style:none;scrollbar-width:none}.CarouselContainer--1J7vf::-webkit-scrollbar{display:none}.Arrow--k4BKI{height:20px;width:20px}.SceneFlexContainer--tvMSt{display:flex}.SceneShotBtn--JaJzp{width:auto !important;height:auto !important;border-radius:5px;box-shadow:0 2px 4px 0 rgba(52,75,96,.1),0 1px 3px 0 rgba(52,75,96,.2);padding:0}.PlaceholderSlide--3N-Lp{height:73px;width:100px;display:flex;align-items:center;justify-content:center}.Active--1i7yj{box-shadow:0 2px 8px 0 rgba(74,144,226,.38),0 2px 3px 0 rgba(52,75,96,.27)}.DummyAddSlide--2WRtE{width:100px;height:73px;border-radius:5px;border:dashed 1px #4a90e2;margin:24px 15px 10px 15px;display:flex;align-items:center;justify-content:center;position:relative}.DummyAddSlide--2WRtE.HoveredState--UetDu{border:solid 1px #4a90e2}.HoverDiv--1w2oo{height:100%;width:100%;background-color:rgba(115,180,255,.2);position:absolute;z-index:1;user-select:none;border-radius:3px}", ""]);
// Exports
exports.locals = {
	"CarouselContainer": "CarouselContainer--1J7vf",
	"Arrow": "Arrow--k4BKI",
	"SceneFlexContainer": "SceneFlexContainer--tvMSt",
	"SceneShotBtn": "SceneShotBtn--JaJzp",
	"PlaceholderSlide": "PlaceholderSlide--3N-Lp",
	"Active": "Active--1i7yj",
	"DummyAddSlide": "DummyAddSlide--2WRtE",
	"HoveredState": "HoveredState--UetDu",
	"HoverDiv": "HoverDiv--1w2oo"
};
module.exports = exports;
