import React, {
	FunctionComponent,
	useMemo,
	useState,
	useCallback,
	useEffect,
	memo,
	useRef,
	useContext,
} from 'react';
import { ITuple3, ITuple4, IVideo } from '../../component-data-structure';
import { useHlsLoader, useRefState, useVideoState } from '../../hooks';
import PlayButton from './PlayButton';
import { ISharedHOCProps } from '../../types';
import Spinner from '../Spinner';
import { isHighestRenderOrder, IUserData } from '../../utils/general';
import { TextureLoader } from 'three';
import VideoBackground from './VideoBackground';
import Hls from 'hls.js'
import { useTextureLoader } from '../../hooks/useTextureLoader';
import { useSRGBTexture } from '../../hooks/useSRGBTexture';
import { AudioContext } from '../../context/audio-context';

const Video: FunctionComponent<IVideo & ISharedHOCProps> = ({
	id,
	rotation: r,
	position: p,
	scale: s,
	stopMedia,
	videoUrl,
	thumbnailUrl,
	opacity = 1,
	autoplay = false,
	loop = false,
	muted = false,
	enabled,
	hideControls: hideCtrls = false,
	hasAlpha = false,
	renderOrder,
	onPointerUp,
	onPointerDown,
	onPointerMove,
	onDoubleClick,
	onFullScreen,
	onEnded: onEndedCb,
	borderRadius,
	borderWidth,
	borderRgba,
	siblings = null,
	preventVideoLoad = false,
}) => {
	const timeoutRef = useRef<any>(null);
	const videoStateManager =  useVideoState(id);
	const [isWaiting, setIsWaiting] = useState(true);
	const [initialBtnHide, setInitialBtnHide] = useState(autoplay)
	const [paused, setPaused, pausedRef] = useRefState(true);
	const [, setShowPause, showPauseRef] = useRefState(false);
	const [hideButtons, setHideButtons, hiddenButtonsRef] = useRefState(false);
	const [hasBeenClicked, setHasBeenClicked, hasBeenClickedRef] = useRefState(false);
	const {isPlaying, updateVideoStateByIds} = videoStateManager || {}
	const pointerDownRef = useRef(false);

	const rotation = useMemo(() => r as ITuple3, [r]);
	const position = useMemo(() => p as ITuple3, [p]);
	const scale = useMemo(() => s as ITuple3, [s]);
	const spinnerScale = useMemo(() => [s[1] * 0.3, s[1] * 0.3, 0] as ITuple3, [
		s,
	]);

	const sceneAudioManager = useContext(AudioContext);

	const hideControls = hideCtrls || (loop && autoplay);

	// Set up video texture, suspend component until texture available
	let [vidTxtr, video, hls] = useHlsLoader(videoUrl, id, {
		muted,
		loop,
		autoplay,
		preventVideoLoad
	});

	const vidTexture = useSRGBTexture(vidTxtr);

	if (isPlaying && enabled && video?.paused && autoplay) {
		setHideButtons(true)
		video?.play()
	}

	useEffect(() => {
		if (isPlaying && stopMedia) sceneAudioManager?.pauseAudio?.();
		if (isPlaying && stopMedia && !sceneAudioManager?.bkgrAudio?.paused) sceneAudioManager?.bkgrAudio.pause?.();
		if (!isPlaying && stopMedia && sceneAudioManager?.bkgrAudio?.paused) sceneAudioManager?.bkgrAudio.play?.();
	}, [isPlaying, stopMedia, sceneAudioManager])


	const resetVideo = useCallback((hls: Hls | null, video: HTMLVideoElement | null, setHasBeenClicked: any, setPaused: any) => {
		setHasBeenClicked(false);
		setHideButtons(autoplay);
		setPaused(true);
		if (video) video.pause();
		if (hls) hls.stopLoad();
		if (video) video.currentTime = 0;
	}, [setHideButtons, autoplay])

	// If video is stopped programmatically (i.e. no user interaction (no previous pointerdown event on video element)
	// reset video to start
	useEffect(() => {
		if (!isPlaying && !pointerDownRef.current) {
			resetVideo(hls || null, video || null, setHasBeenClicked, setPaused)
		}
	}, [pointerDownRef, isPlaying, setHideButtons, setPaused, autoplay, hls, video, resetVideo, setHasBeenClicked])

	useEffect(() => {
		const onEndedHandler = () => {
			onEndedCb?.();
			if (loop) return;
			resetVideo(hls || null, video || null, setHasBeenClicked, setPaused);
			updateVideoStateByIds?.([{id, playing: false}]);
		};
		const onWaitingHandler = () =>  setIsWaiting(true);
		const onPlayingHandler = () => setIsWaiting(false);
		const onCanPlayHandler = () => setIsWaiting(false);

		video?.addEventListener('waiting', onWaitingHandler);
		video?.addEventListener('playing', onPlayingHandler);
		video?.addEventListener('canplay', onCanPlayHandler);
		video?.addEventListener('ended', onEndedHandler);

		return () => {
			video?.removeEventListener('waiting', onWaitingHandler);
			video?.removeEventListener('playing', onPlayingHandler);
			video?.removeEventListener('canplay', onCanPlayHandler);
			video?.removeEventListener('ended', onEndedHandler);
		}
	}, [id, hls, video, setHasBeenClicked, setPaused, onEndedCb, loop, updateVideoStateByIds, resetVideo])
	
	// Import thumbnail
	let thumbnailTxtr = useTextureLoader(TextureLoader, thumbnailUrl || '', false);
	const thumbnailTexture = useSRGBTexture(thumbnailTxtr);
	
	// Reset video if disabled (e.g. in exit phase)
	useEffect(() => {
		if (enabled) return;
		resetVideo(hls || null, video || null, setHasBeenClicked, setPaused);
		if (isPlaying) updateVideoStateByIds?.([{id, playing: false}]);
	}, [id, enabled, hls, setPaused, video, setHasBeenClicked, updateVideoStateByIds, isPlaying]);


	const pauseVideo = useCallback(() => {
		clearTimeout(timeoutRef.current);
		setHideButtons(false);
		video?.pause();
		hls?.stopLoad();
		setShowPause(false);
		setPaused(true);
	}, [
		setHideButtons, 
		video, 
		hls, 
		setShowPause, 
		setPaused
	])

	const playVideoUI = useCallback(() => {
		if (timeoutRef) clearTimeout(timeoutRef.current);
		setHideButtons(false);
		hls?.startLoad();
		setPaused(false);
		timeoutRef.current= setTimeout(() => {
			setHideButtons(true)
		}, 2000)
	}, [hls, setHideButtons, setPaused, video])

	const showPauseButtonTemporarily = useCallback(() => {
		if (timeoutRef) clearTimeout(timeoutRef.current);
		setHideButtons(false);
		setShowPause(true);
		timeoutRef.current = setTimeout(() => {
			setShowPause(false);
			setHideButtons(true)
		}, 2000);
	}, [setHideButtons, setShowPause])

	const onVideoPointerDown = useCallback(e => {
		if (!enabled || 
			!isHighestRenderOrder(e, renderOrder, siblings || [])
		) return;
		pointerDownRef.current = true;
		if (hasBeenClickedRef.current) return;
		setHasBeenClicked(true);
		hls?.startLoad();
	}, [
		hls, 
		enabled,
		siblings, 
		renderOrder, 
		hasBeenClicked,
		setHasBeenClicked,
	]);

	const onVideoPointerUp = useCallback(e => {
		if (!pointerDownRef.current) return;
		pointerDownRef.current = false;
		if (!enabled || (loop && autoplay) || !isHighestRenderOrder(e, renderOrder, siblings || [])) return;
		setInitialBtnHide(false);
		if (pausedRef.current) {
			video?.play()
			playVideoUI();
			updateVideoStateByIds?.([{id, playing: true}]);
		} else if (!pausedRef.current && showPauseRef.current) {
			pauseVideo();
			updateVideoStateByIds?.([{id, playing: false}]);
		} else if (!pausedRef.current) {
			if (!hiddenButtonsRef.current) {
				pauseVideo();
				updateVideoStateByIds?.([{id, playing: false}])
			}
			else showPauseButtonTemporarily(); 
		}
	}, [
		id,
		loop,
		video,
		enabled,
		siblings,
		autoplay,
		renderOrder,
		pausedRef, 
		pauseVideo,
		playVideoUI,
		showPauseRef, 
		hiddenButtonsRef,
		setInitialBtnHide, 
		updateVideoStateByIds,
		showPauseButtonTemporarily,
	]);
	
	const _onFullScreen = useCallback(
		(e: MouseEvent) => {
			if (!enabled || loop || hasAlpha) return;
			e.stopPropagation();
			setHasBeenClicked(true);
			if (video) {
				video.play();
				updateVideoStateByIds?.([{id, playing: true}]);
			}
			setPaused(false);
			if (video && onFullScreen) onFullScreen(video, e as any);
		},
		[
			id,
			video,
			loop, 
			enabled,
			hasAlpha, 
			setPaused,
			onFullScreen,
			setHasBeenClicked,
			updateVideoStateByIds, 
		]
	);
	const userData: IUserData = useMemo(() => ({renderOrder, contentId: id}), [id, renderOrder])

	const playBtnGrpScale: ITuple3 = useMemo(() => {
		const sx = Math.abs(scale[0]);
		const sy = Math.abs(scale[1]);
		if (sx > sy) return [sy * 1.8, sy, 0 ];
		else return [sx * 1.8, sx, 0 ];
	}, [scale]);

	const showThumbnail = useMemo(() => !!thumbnailUrl && !hasBeenClicked && !autoplay, [
		thumbnailUrl,
		hasBeenClicked,
		autoplay
	]);

	const showButton = !initialBtnHide && !hideControls && !hideButtons;
	
	return (
		<group
			key={'video_group'}
			userData={userData}
			position={position}
			rotation={rotation}
			onPointerUp={onPointerUp || undefined} // active even when disabled (needed for d2 editor)
			onPointerDown={onPointerDown || undefined }
			onPointerMove={onPointerMove || undefined }
			onDoubleClick={onDoubleClick || undefined }
		>
			<VideoBackground
				id={id}
				scale={scale}
				borderRadius={borderRadius}
				borderWidth={borderWidth}
				opacity={opacity}
				borderRgba={borderRgba as ITuple4}
				hasAlpha={hasAlpha}
				stackedAlpha={showThumbnail ? false : hasAlpha}
				texture={showThumbnail ? thumbnailTexture : vidTexture}
				color={thumbnailTexture ===  null ? [0, 0, 0, 1] : undefined}
				onPointerDown={onVideoPointerDown}
				onPointerUp={onVideoPointerUp}
				onDoubleClick={_onFullScreen}
				renderOrder={renderOrder}
			/>
			<group scale={playBtnGrpScale} >
				<PlayButton
					name={id}
					visible={(paused || !hasBeenClicked) && showButton}
					showPlayIcon={true}
					svgOpacity={1}
					bkgrOpacity={0.4}
					renderOrder={(renderOrder || 0) + 1}
				/>
				<PlayButton
					name={id}
					visible={!(paused || !hasBeenClicked) && showButton}
					showPlayIcon={false}
					svgOpacity={1}
					bkgrOpacity={0.4}
					renderOrder={(renderOrder || 0) + 1}
				/>
			</group>
			{isWaiting && enabled && (
				<Spinner
					opacity={1}
					radiansPerFrame={0.06}
					color={'#d9d9d9'}
					scale={spinnerScale}
					depthWrite={false}
				/>
			)}
		</group>
	);
};

Video.displayName = 'Video';
export default memo(Video);