import { IProgressStatus } from 'zw-api-client/src/specs';
import {
	ILoadedMediaTypes,
	ISceneSnapshotDict,
	IBtnEntityDragInfo,
	IImageEntityDragInfo,
	IUnionVideoEntityDragInfo,
	IModel3dEntityDragInfo,
} from '../src/store/actions';
import { VideoSourceTypes } from '../src/utils';
import {
	ITuple3,
	IUserData,
	IVector3,
} from '../src/components/r3f/r3f-components/component-data-structure';

export enum IToastType {
	error = 'error',
	warning = 'warning',
	info = 'info',
	success = 'success'
}
export interface IBaseToast {
	type: IToastType,
	message: string;
	timeout?: number;
	size?: IToastSize;
	contrast?: IToastContrast;
	stackToasts?: boolean; 
	id?: string; 
}

export interface IToast extends IBaseToast {
	id: string; 
}

export enum IToastSize {
	default = 'default', 
	mini = 'mini', 
}

export enum IToastContrast {
	undefined = 'undefined', 
	default = 'default', 
	high = 'high',
}

export enum ITransformControlsMode {
	translate = 'translate',
	rotate = 'rotate',
	scale = 'scale'
}

export type IPublishUploadStatus = 'loading'| 'error' | 'success';

// User State
export interface IUserState {
	userId?: string;
	toasts: IToast[];
	publishUploadStatus: IPublishUploadStatus | null;
	is3dMode: boolean;
	sceneHas3dEntity: boolean;
	transformControlsMode: ITransformControlsMode;
	transformControlsActive: boolean;
	socketIsReconnecting?: boolean;
	socketHasConnected?: boolean;
	projectLoadingProgress: number;
	projectLoadingFailure: boolean; 
	selectedEntityIds?: string[];
	lockedEntityIds?: string[];
	copiedEntityIds?: string[];
	activeSceneId: string | null;
	positioningIsActive: boolean;
	scaleHotspotIsEnabled: boolean;
	rotationIsActive: boolean;
	backgroundHotspotIsEnabled: boolean;
	zoomLevel: number;
	undoDisabled: boolean;
	redoDisabled: boolean;
	markerIndexPressed: number | null;
	hotKeys: string[];
	pointerOffset: IVector3;
	entityDragHotspotPosition: IVector3 | null;
	selectedObject2DPosition: IVector3 | null;
	selectionTopLeft2DPosition: IVector3 | null;
	selectionTopRight2DPosition: IVector3 | null;
	selectionBottomLeft2DPosition: IVector3 | null;
	selectionBottomRight2DPosition: IVector3 | null;
	rotationMarker2DPosition: IVector3 | null;
	entityDrag2DPosition: IVector3 | null;
	groupIsXInverted: boolean;
	groupIsYInverted: boolean;
	sceneEntityListIsVisible: boolean;
	sceneEntityListPosition: [number, number];
	currentSceneIndex: number;
	scrollSceneMenu: boolean; 
	sceneSnapshots: ISceneSnapshotDict;
	sceneCarouselScrollLeft: number;
	sceneMenuIsLarge: boolean;
	sceneNameInputIsFocused: boolean;
	project: IProjectData;
	loggedInUsers: { [id: string]: IUserData };
	canvasLoaded: boolean;
	entityMenuDragActive: boolean;
	entityDragInfo:
		| IBtnEntityDragInfo
		| IImageEntityDragInfo
		| IUnionVideoEntityDragInfo
		| IModel3dEntityDragInfo
		| null;
	loadedImages: ILoadedImageInfo[] | null;
	loadedVideos: IUnionLoadedVideoInfo[] | null;
	loadedAudio: ILoadedAudioInfo[] | null;
	loaded3dModels: ILoadedModel3dInfo [] | null;
	loadingMedia: boolean;
	currentUploads: IUploadInfoDict;
	numberProcessedUploads: number; //needed to calculate overall upload process
	trackImgData: ITrkImgData | null;
	trkImgIsUploading: boolean;
	pureReactInCanvasHovered: boolean;
	editSceneTitle: boolean; 
}

export interface IUploadInfoDict {
	[id: string]: IUploadInfo;
}

export type IUploadInfo = IUploadData | null;

interface IUploadData {
	fileName?: string;
	type?: IProgressStatus;
	perc?: number;
	dataURl?: string | ArrayBuffer;
	errorStatus?: number;
	errorText?: string;
	mediaType?: ILoadedMediaTypes;
}

export interface ILoadedAudioInfo {
	id: string;
	zmlFileId: string;
	url: string;
	name: string;
	status?: IAudioStatus;
}

export enum IAudioStatus {
	playing = 'playing',
	paused = 'paused',
	loading = 'loading',
}

export interface IProjectData {
	id?: string;
	title?: string;
}

export interface ILoadedImageInfo {
	id: string;
	zmlFileId: string;
	url: string;
	aspectRatio: number;
	name: string;
	hasAlpha?: boolean;
}

export interface ILoadedModel3dInfo {
	id: string;
	aspectRatio: number;
	zmlFileId?: string;
	thumbnailUrl: string;
	model3dUrl: string;
	dimensions: ITuple3;
	name: string;
}

interface ILoadedVideoInfo {
	id: string;
	zmlFileId: string;
	name: string;
	thumbnailUrl: string;
	mp4Url: string;
	aspectRatio: number;
	hasAlpha: boolean;
}

export interface ILoadedUserVideoInfo extends ILoadedVideoInfo {
	videoUrl: string;
	source: VideoSourceTypes.user;
}

export interface ILoadedExternalVideoInfo extends ILoadedVideoInfo {
	externalId: string;
	source: VideoSourceTypes.youtube | VideoSourceTypes.vimeo;
}

export type IUnionLoadedVideoInfo =
	| ILoadedExternalVideoInfo
	| ILoadedUserVideoInfo;

export interface ITrkImgData {
	quality?: number;
	aspectRatio?: number;
	codes?: string[];
	compositeUrl: string;
}
