// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EntityMenu--2dm-k{left:0;top:60px;width:60px;position:absolute;height:calc(100% - 60px);display:flex;flex-direction:column;align-items:center;justify-content:center;z-index:997;box-shadow:0 2px 4px 0 #00000029;background-color:#fff;overflow:hidden;transition:all .2s cubic-bezier(0.4, 0, 0.2, 1)}.EntityMenu--2dm-k>div:nth-of-type(2){width:calc(100% - 60px - 5px);align-self:flex-end;display:flex;flex-direction:column;justify-content:flex-start;height:100%;padding:32px 27px 32px 16px}.EnlargeMenu--3TEiH{width:358px;height:calc(100% - 60px);transition:all .2s cubic-bezier(0.4, 0, 0.2, 1)}", ""]);
// Exports
exports.locals = {
	"EntityMenu": "EntityMenu--2dm-k",
	"EnlargeMenu": "EnlargeMenu--3TEiH"
};
module.exports = exports;
