import { Settings, JSONObject, IProgressCallback, IProgressStatus } from "../specs";
import { RedirectOAuth } from "../oauth/redirect"
import { BaseClient } from "./base"
import { prodEnvSettings } from "../settings"
import { BrowserZmlClient } from "../zml/clients/browser"


export class BrowserClient extends BaseClient<RedirectOAuth> {
  constructor(settings: Settings) {
    super(
      settings,
      new RedirectOAuth(prodEnvSettings.url, settings.clientId, (settings.redirectURI as string))
    )
    this.zml = new BrowserZmlClient(this)
  }

  requestWithProgress(url: string, body?: string | FormData, progressCb?: IProgressCallback, requestId: number | string = '') {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest(); // fetch API has no progress eventlistener
      if (progressCb) {
        xhr.upload.onprogress = e => progressCb(Math.round((e.loaded / e.total) * 100), {status: IProgressStatus.uploading, id: requestId});
      }
      xhr.onload = () => {
        if (xhr.status < 300) {
          return resolve(xhr.response)
        }
        console.error(xhr.status, url)
        throw xhr.statusText
      }
      xhr.open('POST', url, true);
      xhr.send(body);
    })
  }

  protected async _authenticationSetup() {
    let token = this.getAccessToken()
    if (typeof token === "undefined") {
      this._oauth.makeAuthorizationRequest()
      token = await this._oauth.waitForAccessToken()
    }
    return {
      method: "GET",
      headers: {
        'content-type': 'application/json; charset=utf-8',
        authorization: `Bearer ${token}`
      },
      mode: "cors"
    } as RequestInit
  }

  async getAPISVG (endpoint: string): Promise<Blob> {
    const options =  await this._authenticationSetup();
    const resp = await fetch(`${this.envSettings.url}${endpoint}`, options);
    if (resp.ok) return await <unknown>resp.blob() as Blob;
    throw new Error(`${resp.status} ${await resp.text()}`);
  }

  async apiRequest<T>(endpoint: string, postData?: JSONObject, method?: string) {
    let options = await this._authenticationSetup();
    if (typeof postData !== "undefined") {
      options.body = JSON.stringify(postData);
      if (typeof method === "undefined") {
        options.method = "POST";
      }
    }
    const resp = await fetch(`${this.envSettings.url}${endpoint}`, options);
    if (resp.ok) return await resp.json() as T;
    throw new Error(`${resp.status} ${await resp.text()}`);
  }
}
