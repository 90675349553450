
import React from 'react';
import { IMenuItem } from '../../MenuList/MenuList';
import Dropdown, { IMenuPosition } from '../Dropdown';


interface IParentProps {
    children: IMenuItem[];
    positionMenu?: IMenuPosition; 
    menuWidth: string; 
    onClose?: () => any; 
    showMenu?: boolean; 
    hideButton?: boolean; 
    appendMenuToRoot?: boolean; 
    className?: string; 
}

const BtnDropdown: React.FunctionComponent<IParentProps> = ({
	children,
	positionMenu, 
    menuWidth,
    onClose, 
    hideButton, 
    appendMenuToRoot, 
    className, 
}) => {
    return (
        <Dropdown
            value={null}
            positionMenu={positionMenu}
            isSelectInput={false}
            height={'16px'}
            width={'4px'}
            menuWidth={menuWidth}
            onCloseMenu={() => onClose()}
            hideButton={hideButton}
            appendMenuToRoot={appendMenuToRoot}
            className={className}
        >
            {children}
        </Dropdown>
    ); 
};

export default BtnDropdown;