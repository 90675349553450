import React, {
	FunctionComponent,
	useRef,
	memo,
	useCallback,
	useLayoutEffect,
} from 'react';
import { ITextAlignment, ITuple3 } from '../component-data-structure';
import { Material, Mesh, BackSide, FrontSide, DoubleSide } from 'three';
import { Text } from '@react-three/drei';
import { ThreeEvent } from '@react-three/fiber';

export interface ITextRenderInfo {
	ascender: number;
	descender: number;
	caretHeight: number;
	totalBlockSize: [number, number];
	chunkedBoundsRect: [number, number, number, number];
}

export interface ITextParentProps {
	children: string;
	name?: string;
	color?: string | THREE.Color;
	visible?: boolean;
	fontSize?: number;
	lineHeight?: number;
	letterSpacing?: number;
	textAlign?: ITextAlignment;
	anchorX?: 'left' | 'right' | 'center';
	anchorY?:
		| 'middle'
		| 'top'
		| 'top-baseline'
		| 'bottom-baseline'
		| 'bottom';
	url?: string;
	maxWidth?: number;
	onUpdateScale?: (textRenderInfo: ITextRenderInfo) => any;
	alpha: number;
	position: ITuple3;
	scale?: ITuple3;
	depthWrite?: boolean;
	renderOrder?: number;
	onPointerOver?: (e: ThreeEvent<PointerEvent>) => any;
	onPointerOut?: (e: ThreeEvent<PointerEvent>) => any;
	side?: THREE.Side;
}

const TroikaText: FunctionComponent<ITextParentProps> = memo(
	({
		children,
		color,
		fontSize,
		lineHeight,
		letterSpacing,
		onUpdateScale,
		textAlign,
		url: fontUrl,
		anchorX,
		anchorY,
		visible = true,
		alpha,
		position,
		maxWidth,
		depthWrite,
		renderOrder,
		onPointerOver,
		onPointerOut,
		side = DoubleSide,
	}) => {
		const meshRef = useRef<Mesh>();

		useLayoutEffect(() => {
			const material = (meshRef as any).current.material;
			(material as Material).depthWrite = false;
			(material as Material).side = side;
		}, [meshRef, side]);

		const onUpdateScaleHandler = useCallback((): ITextRenderInfo | undefined => {
			if (!(meshRef?.current as any)?.textRenderInfo || !onUpdateScale) return;
			const {
				ascender,
				descender,
				caretHeight,
				blockBounds,
				chunkedBounds,
			} = (meshRef?.current as any)?.textRenderInfo;
			const { rect: chunkedBoundsRect } = chunkedBounds[0];
			onUpdateScale({
				ascender,
				descender,
				caretHeight,
				totalBlockSize: blockBounds,
				chunkedBoundsRect,
			});
		}, [onUpdateScale]);

		if (fontSize === 0) return null;
		else return (
			<group>
				<Text
					ref={meshRef}
					color={color}
					position={position}
					fontSize={fontSize}
					lineHeight={lineHeight}
					letterSpacing={letterSpacing}
					textAlign={textAlign}
					font={fontUrl}
					maxWidth={maxWidth}
					anchorX={anchorX}
					anchorY={anchorY}
					material-opacity={alpha}
					material-transparent={true}
					renderOrder={(renderOrder || 0) + 2}
					onPointerOver={onPointerOver}
					onPointerOut={onPointerOut}
					onSync={onUpdateScaleHandler}
					visible={visible}
				>
					{children}
				</Text>
			</group>
		);
	}
);

TroikaText.displayName = 'Text';
export default memo(TroikaText);