import React from "react";
import { IImage } from "../../../../../../r3f/r3f-components/component-data-structure";
import { ENTITY_PREVIEW_HEIGHT } from '../../../../../../../utils';
import CSS from './ImageContent.scss'

interface IParentProps {
  entity: IImage
}

const ImageContent = ({
  entity
}: IParentProps) => {
  return (
    <>
      <div>
						<div className={CSS.Title}>Image</div>
						<div
							className={CSS.PreviewDiv}
							style={{
								width: '100%',
								height: `${ENTITY_PREVIEW_HEIGHT}px`,
							}}
						>
							<img src={entity.imageUrl}/>
						</div>
					</div>
    </>
  )
}

export default React.memo(ImageContent);