import React, { useState } from 'react';
import { useRefState } from '../../../../../../r3f/r3f-components/hooks';
import PageLinkSummary from './Summary/Summary';
import CSS from './PageLink.scss';
import { IOnSetActionData_Global } from '../../../../../../../store/actions';
import { parseCustomUrl } from '../../../../../../../utils';
import { onSetActionData_Global } from '../../../../../../../store/actions';
import {
	ITriggerTypes,
	IPageEditorLinkOptions,
	IActionCategory,
} from '../../../../../../r3f/r3f-components/component-data-structure';
import { TextInput } from '../../../../../Inputs';
import { useDispatch } from 'react-redux';

interface IParentProps {
	targetIds: string[];
	triggerType: ITriggerTypes;
	setShowActionSummary: (value: React.SetStateAction<boolean>) => void;
	showActionSummary: boolean;
	inputFromSocials: string;
	editorPageLinkOptions: IPageEditorLinkOptions;
}

const SceneLink: React.FunctionComponent<IParentProps> = ({
	targetIds,
	triggerType,
	showActionSummary,
	setShowActionSummary,
	editorPageLinkOptions,
	inputFromSocials,
}) => {
	const dispatch = useDispatch();
	const [linkedPageUrl, setLinkedPageUrl, linkedPageUrlRef] = useRefState<string>(
		inputFromSocials || editorPageLinkOptions.pageUrl || ''
	);
	const [error, setError] = useState<string>('');

	const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLinkedPageUrl(e.target.value);
		setError('');
	};

	const keyUpHandler = (e: React.KeyboardEvent) => {
		if (e.keyCode === 13) return;
	};

	const addLink = () => {
		dispatch(onSetActionData_Global({
			ids: targetIds,
			triggerType: triggerType,
			selectedActionCategory: IActionCategory.linkPage,
			linkPageOptions: {
				pageUrl: linkedPageUrlRef.current,
			},
		}));
		setShowActionSummary(true);
	}

	const keyDownHandler = (e: React.KeyboardEvent) => {
		if (e.keyCode !== 13) return;
		addLink(); 
	};

	return !showActionSummary ? (
		<div className={CSS.PageFlexContainer}>
			<div className={CSS.Description}>Page URL</div>
			<TextInput
				value={linkedPageUrl}
				placeholder={"www.example.com"}
				onChange={changeHandler}
				onKeyDown={keyDownHandler}
				className={CSS.LinkedPageInputField}
				onBlur={addLink}
			/>
			<span className={CSS.Error}>{error}</span>
		</div>
	) : (
		<PageLinkSummary
			url={editorPageLinkOptions.pageUrl}
		/>
	);
};

export default SceneLink;
