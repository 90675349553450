import React, { useState, useRef, FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../../typings';
import { FONT_PT_TO_MM_HEIGHT, convMmToUnit,	getInputStepByUnit } from '../../../../../../utils';
import { IMultipleEntityProps_Ed_Doc, onSetMultipleEntityProps_Ed_Doc, IMultipleEntityProps_Cn_Doc, onSetMultipleComponentProps_Cn_Doc } from '../../../../../../store/actions';
import { NumericalInput } from '../../../../Inputs';
import { IComponentUnion,	ITuple4,	IComponentType,	IFontTypes,	ITextAlignment, IUnitTypes,	FONT_TYPES, } from '../../../../../r3f/r3f-components/component-data-structure';
import { TextAlignmentCenter, TextAlignmentLeft, TextAlignmentRight } from '../../../../../../assets/icons';
import ColorPickerMenu from '../../../ColorPickerMenu/ColorPickerMenu';
import ColorCircle from '../../Shared/ColorCircle/ColorCircle';
import Dropdown from '../../../Dropdown/SelectDropdown/SelectDropdown';
import CSS from './Text.scss';

const createMultipleBtnEntityProps_Cn_Doc = (
	ids: string[], 
	entityContentDict: { [id: string]: IComponentUnion },
	property: { [id: string]: any}
) => {
	const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
	for (let i = 0; i < ids.length; i++) {
		const id = ids[i];
		if (
			entityContentDict[id].type !== IComponentType.Button &&
			entityContentDict[id].type !== IComponentType.Text
		)
			continue;
		const entityProps: IMultipleEntityProps_Cn_Doc = {
			id: ids[i],
			...property,
		};
		multipleEntityProps_Cn_Doc[i] = entityProps;
	}
	return multipleEntityProps_Cn_Doc;
}

const TextContent: FunctionComponent = () => {
	// Redux
	const dispatch = useDispatch();
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const id = selectedEntityIds[0];
	const entityContentDict = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const editorFontColor = useSelector((state: IDesignerState) => {
		if (!state.editorReducer.editorDoc || !state.editorReducer.editorDoc.fontRgba) return undefined;
		return state.editorReducer.editorDoc.fontRgba[id] as ITuple4;
	});
	const targetDisplayInfo = useSelector((state: IDesignerState) => state.editorReducer.editorDoc.targetDisplayInfo);
	const displayUnit = useSelector((state: IDesignerState) => state.editorReducer.editorDoc?.targetDisplayInfo?.displayUnits);

	// Derived
	if (selectedEntityIds.length === 0) return null;
	const entity = entityContentDict[id];
	if (
		entity.type !== IComponentType.Button && 
		entity.type !== IComponentType.Text
	) return null;

	const unitIsCoords = displayUnit === IUnitTypes.coords;

	const targetElRef = useRef();
	const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
	const { fontRgba = [0, 0, 0, 1], fontSize = 12 } = entity;
	const fontColor = editorFontColor || (fontRgba as ITuple4);
	
	// 1) Convert 1pt (font height) on target to x coords in experience
	// const ptToXCoord = unitIsCoords ? 1 : FONT_PT_TO_MM_HEIGHT / targetDisplayInfo.heightInMm;
	// const multiplier = ptToXCoord / 0.25;
	const { displayUnits: targetDisplayUnits, heightInMm: targetHeightInMm } = targetDisplayInfo;
	const unit  = targetDisplayUnits || IUnitTypes.cm;
	let multiplier = convMmToUnit(unit === IUnitTypes.coords ? 1 : targetHeightInMm, unit) / 4;

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div className={CSS.Subsections}>
				<div className={CSS.Title}>Font</div>
				<Dropdown
					value={entity.fontFamily || IFontTypes.Roboto}
					menuHeight={175}
					onChange={value => {
						const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
						for (let i = 0; i < selectedEntityIds.length; i++) {
							multipleEntityProps_Cn_Doc[i] = {
								id: selectedEntityIds[i],
								fontFamily: value as IFontTypes,
							};
						}
						dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
					}}
				>
					{FONT_TYPES.map(({fontFamily}) => ({text: fontFamily, val: fontFamily, fontFamily}))}
				</Dropdown>
			</div>
			
			<div className={`${CSS.Subsections} ${CSS.FontSection}`}>
				<div>
					<div className={CSS.Title}>
						{`Font ${unitIsCoords ? 'height' : 'size'}`}
					</div>
					<NumericalInput
						className={CSS.FontSizeInput}
						decimals={2}
						value={fontSize * multiplier * (1/FONT_PT_TO_MM_HEIGHT) * (unitIsCoords ? 0.25 : 1)}
						labelText={unitIsCoords ? IUnitTypes.coords : IUnitTypes.pt}
						min={0}
						step={getInputStepByUnit(targetDisplayUnits)}
						onChange={v => {
							const val = v / multiplier / (1/FONT_PT_TO_MM_HEIGHT) / (unitIsCoords ? 0.25 : 1) 
							const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
							for (let i = 0; i < selectedEntityIds.length; i++) {
								const id = selectedEntityIds[i];
								if (
									entityContentDict[id].type !== IComponentType.Button && 
									entityContentDict[id].type !== IComponentType.Text
								)
									continue;
								const entityProps: IMultipleEntityProps_Cn_Doc = {
									id: selectedEntityIds[i],
									fontSize: val,
								};
								multipleEntityProps_Cn_Doc[i] = entityProps;
							}
							dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
						}}
					/>
				</div>
				<div>
					<div className={CSS.Title}>Font color</div>
					<div 
						className={CSS.ColorCircleContainer}
						ref={targetElRef} 
						style={{ width: 'fit-content' }}
					>
						<ColorCircle
							isActive={showColorPicker}
							rgba={fontColor}
							onClick={() => setShowColorPicker(prevState => !prevState)}
							rectangle={true}
						/>
					</div>
				</div>
				
			</div>			

			<div className={CSS.Subsections}>
				<div className={CSS.Title}>Alignment</div>
				<div className={CSS.TextAlignmentRow}>
					<button
						onClick={() => {
							const mtlpEntProps_Cn_Doc = createMultipleBtnEntityProps_Cn_Doc(
								selectedEntityIds, 
								entityContentDict, 
								{textAlignment: ITextAlignment.left}
							);
							dispatch(onSetMultipleComponentProps_Cn_Doc(mtlpEntProps_Cn_Doc));
							
						}}
					>
						<TextAlignmentLeft
							hexFill={entity.textAlignment === ITextAlignment.left ? '#4a90e2' : null}
						/>
					</button>

					<button
						onClick={() => {
							const mtlpEntProps_Cn_Doc = createMultipleBtnEntityProps_Cn_Doc(
								selectedEntityIds, 
								entityContentDict, 
								{textAlignment: ITextAlignment.center}
							);
							dispatch(onSetMultipleComponentProps_Cn_Doc(mtlpEntProps_Cn_Doc));
						}}
					>
						<TextAlignmentCenter
							hexFill={entity.textAlignment === ITextAlignment.center ? '#4a90e2' : null}
						/>
					</button>
					<button
						onClick={() => {
							const mtlpEntProps_Cn_Doc = createMultipleBtnEntityProps_Cn_Doc(
								selectedEntityIds, 
								entityContentDict, 
								{textAlignment: ITextAlignment.right}
							);
							dispatch(onSetMultipleComponentProps_Cn_Doc(mtlpEntProps_Cn_Doc));
						}}
					>
						<TextAlignmentRight
							hexFill={entity.textAlignment === ITextAlignment.right ? '#4a90e2' : null}
						/>
					</button>
				</div>
			</div>

			<ColorPickerMenu
				show={showColorPicker}
				rgba={fontColor}
				targetElRef={targetElRef}
				onClose={() => setShowColorPicker(false)}
				onChange={([r, g, b, a]) => {
					const multipleEntityProps_Ed_Doc: IMultipleEntityProps_Ed_Doc[] = [];
					for (let i = 0; i < selectedEntityIds.length; i++) {
						const entityProps: IMultipleEntityProps_Ed_Doc = {
							id: selectedEntityIds[i],
							fontRgba: [r, g, b, a / 100],
						};
						multipleEntityProps_Ed_Doc[i] = entityProps;
					}
					dispatch(onSetMultipleEntityProps_Ed_Doc(multipleEntityProps_Ed_Doc));
				}}
				onSubmit={([r, g, b, a]) => {
					const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
					const multipleEntityProps_Ed_Doc: IMultipleEntityProps_Ed_Doc[] = [];
					for (let i = 0; i < selectedEntityIds.length; i++) {
						multipleEntityProps_Cn_Doc[i] = {
							id: selectedEntityIds[i],
							fontRgba: [r, g, b, a / 100],
						};
						multipleEntityProps_Ed_Doc[i] = {
							id: selectedEntityIds[i],
							fontRgba: null,
						};
					}
					dispatch(onSetMultipleEntityProps_Ed_Doc(multipleEntityProps_Ed_Doc));
					dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
				}}
			/>
		</div>
	);
};

export default React.memo(TextContent);
