import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const imageIcon: React.SFC<IIconParentProps> = ({
	hexFill: fill,
	viewBox,
	className,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="20px"
			height="16px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 20 16`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<title>Image</title>
			<g id="Expanded" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Image" transform="translate(-10.000000, -12.000000)" fill={hexFill} fillRule="nonzero">
					<path d="M15,14.5 C13.6192881,14.5 12.5,15.6192881 12.5,17 C12.5,18.3807119 13.6192881,19.5 15,19.5 C16.3807119,19.5 17.5,18.3807119 17.5,17 C17.5,15.6192881 16.3807119,14.5 15,14.5 Z M15,15.5 C15.8284271,15.5 16.5,16.1715729 16.5,17 C16.5,17.8284271 15.8284271,18.5 15,18.5 C14.1715729,18.5 13.5,17.8284271 13.5,17 C13.5,16.1715729 14.1715729,15.5 15,15.5 Z M19.4752046,27.0044403 L11.0017932,27.0044403 L11.0017932,23.9381699 C11.7308784,23.2244931 12.8789254,22.500103 14.5,22.500103 C15.1760459,22.500103 16.1414013,22.6736409 16.982861,23.1585373 C18.0885912,23.7957213 19.2545161,25.0563753 19.4752046,27.0044403 Z M28.997402,23.0981637 L29.0777202,27.1 L20.4802495,27.0038354 C20.2431023,24.2405797 18.3210529,22.8724439 18.1620675,22.7541249 C19.4260845,20.3672708 21.7120804,19.4909647 23.5,19.4909647 C25.3899914,19.4909647 27.7998485,20.4757622 28.997402,23.0981637 Z M11.0024719,12.999527 L29.0043335,12.999527 L29.0043335,21.175171 C28.0937884,20 26.1967803,18.4965048 23.5,18.4965048 C20.8931512,18.4965048 18.4946122,19.9885451 17.3272585,22.2067002 C16.7046356,21.8676758 15.7225189,21.4954681 14.5,21.4954681 C13.2774811,21.4954681 12.0056,21.9081268 11.0024719,22.6190948 C11.0024719,22.6190948 11.0024719,19.4125722 11.0024719,12.999527 Z M10.4559585,12 C10.2086362,12.0101696 10.0105385,12.201334 10,12.44 L10,27.56 C10.0105385,27.798666 10.2086362,27.9898304 10.4559585,28 L29.5440415,28 C29.7913638,27.9898304 29.9894615,27.798666 30,27.56 L30,12.44 C29.9926734,12.2185494 29.8227984,12.0336033 29.5958549,12 L10.4559585,12 Z"></path>
				</g>
			</g>
		</svg>

	);
};

export default imageIcon;
