import React, { useState } from 'react';
import {
	SoundIconSmall,
	PauseIcon,
	PlayHighlightIcon,
	PauseHighlightIcon,
} from '../../../../assets/icons';
import MenuList, { IMenuItem } from '../MenuList/MenuList';
import { IOptionOverflowType } from '../MenuList/Option/Option';
import { IAudioStatus, ILoadedAudioInfo } from '../../../../../typings';

import CSS from './AudioMenuList.scss';

interface IAudioDict {
	[id: string]: HTMLAudioElement;
}

interface IAudioStatusDict {
	[id: string]: IAudioStatus | null;
}

interface IUploadInfo {
	[fileName: string]: { type: string; perc: number };
}

interface IParentProps {
	loadedAudio: ILoadedAudioInfo[];
	className?: string;
	onChange: (audio: ILoadedAudioInfo, selAudio: HTMLAudioElement) => any;
}

const AudioMenuList: React.SFC<IParentProps> = ({
	loadedAudio,
	onChange,
	className = '',
}) => {
	const [hoveredSoundIconId, setHoveredSoundIconId] = useState<string>(null);
	const [audioDict, setAudioDict] = useState<IAudioDict>({});
	const [audioStatusDict, setAudioStatusDict] = useState<IAudioStatusDict>({});
	if (!loadedAudio) return null;
	
	const audioOptions: IMenuItem[] = loadedAudio.map(audio => {
		let icon = SoundIconSmall;
		switch (audioStatusDict[audio.id]) {
			case IAudioStatus.playing:
				if (hoveredSoundIconId === audio.id) {
					icon = PauseHighlightIcon;
				} else icon = PauseIcon;
				break;
			default:
			case IAudioStatus.paused:
				if (hoveredSoundIconId === audio.id) {
					icon = PlayHighlightIcon;
				}
		}

		return {
			text: audio.name,
			val: audio.id,
			icon,
			fn: () => {
				if (!audioDict[audio.id]) audioDict[audio.id] = new Audio(audio.url);
				onChange(audio, audioDict[audio.id]);
				setHoveredSoundIconId(null);
			},
			iconFn: () => {
				// pause all other audio sounds
				for (const audioId in audioDict) {
					if (
						audio.id !== audioId &&
						audioDict[audioId] &&
						!audioDict[audioId].paused
					) {
						audioDict[audioId].pause();
						setAudioStatusDict(prevState => {
							return {
								...prevState,
								[audioId]: IAudioStatus.paused,
							};
						});
					}
				}

				// if sound is playing pause it, otherwise play
				switch (audioStatusDict[audio.id]) {
					case IAudioStatus.paused:
						audioDict[audio.id].play();
						setAudioStatusDict(prevState => {
							return {
								...prevState,
								[audio.id]: IAudioStatus.playing,
							};
						});
						break;
					case IAudioStatus.playing:
						audioDict[audio.id].pause();
						setAudioStatusDict(prevState => {
							return {
								...prevState,
								[audio.id]: IAudioStatus.paused,
							};
						});
						break;
					default:
						const htmlAudio = new Audio(audio.url);
						setAudioStatusDict(prevState => {
							return {
								...prevState,
								[audio.id]: IAudioStatus.playing,
							};
						});
						setAudioDict(prevState => {
							prevState[audio.id] = htmlAudio;
							return prevState;
						});
						htmlAudio.play();
						htmlAudio.onended = () =>
							setAudioStatusDict(prevState => {
								return {
									...prevState,
									[audio.id]: null,
								};
							});
						break;
				}
			},
		};
	});

	const onChangeHandler = (opt: IMenuItem) => {
		// pause and set to beginning all audio
		for (const audioId in audioDict) {
			if (audioDict[audioId] && !audioDict[audioId].paused) {
				audioDict[audioId].pause();
				audioDict[audioId].currentTime = 0;
				setAudioStatusDict(prevState => {
					return {
						...prevState,
						[audioId]: IAudioStatus.paused,
					};
				});
			}
		}
	};

	return (
		<MenuList
			show
			className={`${CSS.AudioList} ${className}`}
			overflow={IOptionOverflowType.ellipsis}
			optionData={audioOptions}
			selectedOption={null}
			onChange={onChangeHandler}
			onMouseEnterIcon={opt => setHoveredSoundIconId(opt.val)}
			onMouseLeaveIcon={() => setHoveredSoundIconId(null)}
			hasButtonAtEnd={true}
			endButtonText={"Use"}
		/>
	);
};

export default AudioMenuList;
