// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FlexContainer--2qE7S{display:flex;flex-direction:column;align-items:flex-start;padding:5px}.FlexContainer--2qE7S a{text-decoration:none;color:#5ea4e9}.DialNumberInputField--2AJl-{margin-bottom:8px}.Description--1Tbqa{color:#5c5c5c;font-size:14px;margin:8px 0;align-self:flex-start;width:100%}.Error--3vY7i{color:red}", ""]);
// Exports
exports.locals = {
	"FlexContainer": "FlexContainer--2qE7S",
	"DialNumberInputField": "DialNumberInputField--2AJl-",
	"Description": "Description--1Tbqa",
	"Error": "Error--3vY7i"
};
module.exports = exports;
