// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Grid--r1Nnn{display:grid;grid-gap:12px;padding:4px 0;grid-template-columns:auto auto;justify-items:center}.Grid--r1Nnn div{width:100%;display:flex;align-items:center;justify-content:center;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"Grid": "Grid--r1Nnn"
};
module.exports = exports;
