import React, { FunctionComponent, useState, memo } from 'react';
import { connect } from 'react-redux';
import TargetProperties from './TargetProperties/TargetProperties';
import BackgroundSound from './BackgroundSound/BackgroundSound';
import Shelf from '../../../Shelf/Shelf';
import { ITrackingUnionState_Ed_Doc } from '../../../../r3f/r3f-components/component-data-structure';
import { IDesignerState } from '../../../../../../typings';
import { getEditorTrackingInfo } from '../../../../../store/selectors';

enum IProjectShelves {
	targetProperty = 'targetProperty', 
	backgroundSound = 'backgroundSound', 
	targetNotSeen = 'targetNotSeen',
}

interface IParentProps {
	show: boolean;
}

interface IReduxProps {
	tracking: ITrackingUnionState_Ed_Doc;
}

const ProjectShelves: FunctionComponent<IParentProps & IReduxProps> = ({
	show,
	tracking,
}) => {
	const [openShelf, setOpenSelf] = useState<IProjectShelves | null>(IProjectShelves.targetProperty);
	return (
		show ? 
		<>
			<Shelf
				show
				open={openShelf === IProjectShelves.targetProperty}
				id={IProjectShelves.targetProperty}
				title="Target Image"
				onOpen={() => setOpenSelf(IProjectShelves.targetProperty)}
				onClose={() => setOpenSelf(null)}
			>
				<TargetProperties />
			</Shelf>
			<Shelf
				show
				disabled={!tracking}
				open={openShelf === IProjectShelves.backgroundSound}
				id={IProjectShelves.backgroundSound}
				title="Background Sound"
				onOpen={() => setOpenSelf(IProjectShelves.backgroundSound)}
				onClose={() => setOpenSelf(null)}
			>
				<BackgroundSound />
			</Shelf>
		</> : null
	);
};

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	return {
		tracking: getEditorTrackingInfo(state),
	};
};

export default connect(mapStateToProps)(memo(ProjectShelves));
