import React from "react";
import { IIconParentProps } from "./index";
import { DEFAULT_ICON_COLOR } from "../../utils";

const aspectRatioUnlocked: React.SFC<IIconParentProps> = ({
  hexFill: fill,
  viewBox,
  className,
  onClick = () => {},
  title,
}) => {
  const hexFill = fill || DEFAULT_ICON_COLOR;
  return (
    <svg
      className={className}
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g
        id="Aspect-Ratio-Unlocked-20-x-20"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M7.54173011,11.2236159 L7.54225716,12.4305006 C7.18116563,12.7642848 6.94339598,13.2295396 6.90498067,13.7502326 L6.89949494,13.8994949 L6.89949494,16.8994949 C6.89949494,17.9538567 7.71537273,18.8176601 8.75023259,18.8940092 L8.89949494,18.8994949 L10.8994949,18.8994949 C11.9538567,18.8994949 12.8176601,18.0836171 12.8940092,17.0487573 L12.8994949,16.8994949 L12.8994949,13.8994949 C12.8994949,13.4758124 12.7677521,13.0829 12.5429968,12.7594881 L12.5422046,11.388807 C13.3596201,11.924719 13.8994949,12.8490597 13.8994949,13.8994949 L13.8994949,16.8994949 C13.8994949,18.5563492 12.5563492,19.8994949 10.8994949,19.8994949 L8.89949494,19.8994949 C7.24264069,19.8994949 5.89949494,18.5563492 5.89949494,16.8994949 L5.89949494,13.8994949 C5.89949494,12.7313241 6.56717318,11.7190979 7.54173011,11.2236159 Z M10.8994949,-0.100505063 C12.5563492,-0.100505063 13.8994949,1.24264069 13.8994949,2.89949494 L13.8994949,5.89949494 C13.8994949,6.94993016 13.3596201,7.87427087 12.5422046,8.41018292 L12.5422546,7.04056901 C12.7408036,6.75526165 12.8669311,6.41578173 12.8940092,6.04875728 L12.8994949,5.89949494 L12.8994949,2.89949494 C12.8994949,1.79492544 12.0040644,0.899494937 10.8994949,0.899494937 L8.89949494,0.899494937 C7.84513314,0.899494937 6.98132981,1.71537273 6.90498067,2.75023259 L6.89949494,2.89949494 L6.89949494,5.89949494 C6.89949494,6.47986886 7.1467024,7.00250439 7.54156459,7.3678488 L7.54173011,8.57537396 C6.56717318,8.07989194 5.89949494,7.06766574 5.89949494,5.89949494 L5.89949494,2.89949494 C5.89949494,1.24264069 7.24264069,-0.100505063 8.89949494,-0.100505063 L10.8994949,-0.100505063 Z"
          id="Combined-Shape"
          fill={hexFill}
          fillRule="nonzero"
          transform="translate(9.899495, 9.899495) rotate(-315.000000) translate(-9.899495, -9.899495) "
        ></path>
      </g>
    </svg>
  );
};

export default aspectRatioUnlocked;
