// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DrawerBtn--bdcva{display:flex;align-content:center;justify-content:space-between;width:100%;padding:10px 0}.Arrow--3HygK{height:10px;width:11px;transition:all .2s cubic-bezier(0.4, 0, 0.2, 1)}.TitleContainer--2xD-b{display:flex;width:fit-content;align-items:center;font-size:16;font-weight:700}.TitleContainer--2xD-b.Open--EFZA8{margin-bottom:10px}.RotatedArrow--23Tkz{height:10px !important;width:11px !important;transform:rotate(90deg);transition:all .2s cubic-bezier(0.4, 0, 0.2, 1)}.Content--15Wyt{height:100%;overflow:hidden}.Content--15Wyt.Closed--2YzdP{overflow:hidden;max-height:0px;transition:max-height .2s cubic-bezier(0.4, 0, 0.2, 1)}.Content--15Wyt.Open--EFZA8{height:auto;max-height:900px;transition:max-height .25s cubic-bezier(0.4, 0, 0.2, 1);margin-bottom:16px}", ""]);
// Exports
exports.locals = {
	"DrawerBtn": "DrawerBtn--bdcva",
	"Arrow": "Arrow--3HygK",
	"TitleContainer": "TitleContainer--2xD-b",
	"Open": "Open--EFZA8",
	"RotatedArrow": "RotatedArrow--23Tkz",
	"Content": "Content--15Wyt",
	"Closed": "Closed--2YzdP"
};
module.exports = exports;
