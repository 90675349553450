import uuid4 from 'uuid/v4';
import {
	IUserActionTypes,
	IOnAddEntityIdsToSelection,
	IOnRemoveSelection,
	IOnChangeZoomLevel,
	IOnSetUserId,
	IOnUpdateUsers,
	IOnSetScaleHotSpotEnabled,
	IOnSetHotKey,
	IOnRemoveHotKey,
	IOnSetPointerOffset,
	IOnSetMarkerIndexPressed,
	IOnSetRotationIsActive,
	IOnSetBackgroundHotSpotEnabled,
	IOnSetGroupInversion,
	IOnCopySelectedEntities,
	IOnSetPositioningIsActive,
	IOnSetActiveScene,
	IOnSetSceneEntityListVisible,
	IOnSetCurrentSceneIndex,
	IOnSetSceneSnapshots,
	IOnSetSceneCarouselScrollLeft,
	IOnSetEnlargeSceneMenu,
	IOnFocusSceneNameInput,
	IOnSetCanvasLoaded,
	IOnSetEntityMenuDragActive,
	IOnSetEntityMenuHotspotDragPosition,
	IOnSetDraggedEntityInfo,
	IOnSetEntityMenuDrag2DPosition,
	IOnGetZmlFileInfo,
	IOnSetLoadingMedia,
	IOnAddToLoadedMedia,
	ISetLoadedAudioAction,
	IOnSetAudioStatus,
	IOnAddToLoadedMediaAction,
	ILoadedMediaTypes,
	IAddMediaPositionTypes,
	IOnSetCurrentUploads,
	IOnRemoveLoadedMedia,
	IOnSetTrackingImageData,
	ISetTrkImgIsUploading,
	IOnRemoveTrackingImageData,
	IOnSetPureReactInCanvasHovered,
	IOnAddToast,
	IOnRemoveToast,
	IOnEditSceneTitle,
	IOnScrollSceneMenu,
	IRemoveUpload,
	IOnSetTransformControlsMode
} from './action-types';
import { Dispatch } from 'redux';
import { VideoSourceTypes } from '../../utils';
import {
	ILoadedImageInfo,
	IUnionLoadedVideoInfo,
	ILoadedAudioInfo,
	ILoadedModel3dInfo,
} from '../../../typings';
import { zwClient } from '../../syncdoc';
import {
	IFileType,
} from 'zw-api-client/src/zml/specs';
import { calcImgAspectRatio } from '../../components/dom/upload/utils';
import { IOnSetIs3dMode, IOnSetPublishUploadStatus, IOnSetSceneHas3dEntity, IOnSetTransformControlsActive } from '.';

export const onSetUserId: IOnSetUserId = userId => ({
	type: IUserActionTypes.SET_USER_ID,
	payload: { userId },
});

export const onUpdateUsers: IOnUpdateUsers = payload => ({
	type: IUserActionTypes.UPDATE_USERS,
	payload,
});

export const onSetHotKey: IOnSetHotKey = hotKey => ({
	type: IUserActionTypes.SET_HOTKEY,
	payload: { hotKey },
});

export const onSetCanvasLoaded: IOnSetCanvasLoaded = payload => ({
	type: IUserActionTypes.CANVAS_IS_LOADED,
	payload,
});

export const onSetActiveScene: IOnSetActiveScene = activeSceneProps => ({
	type: IUserActionTypes.SET_ACTIVE_SCENE,
	payload: activeSceneProps,
});

export const onSetCurrentSceneIndex: IOnSetCurrentSceneIndex = index => ({
	type: IUserActionTypes.SET_CURRENT_SCENE_INDEX,
	payload: index,
});

export const onScrollSceneMenu: IOnScrollSceneMenu = scrollMenuScene => ({
	type: IUserActionTypes.SCROLL_SCENE_MENU, 
	payload: scrollMenuScene, 
});

export const onSetSceneSnapshots: IOnSetSceneSnapshots = sceneSnapshotsDict => ({
	type: IUserActionTypes.SET_SCENE_SNAPSHOTS,
	payload: sceneSnapshotsDict,
});

export const onRemoveHotKey: IOnRemoveHotKey = hotKey => ({
	type: IUserActionTypes.REMOVE_HOTKEY,
	payload: { hotKey },
});

export const onSetPositioningIsActive: IOnSetPositioningIsActive = payload => ({
	type: IUserActionTypes.SET_POSITIONING_ACTIVE,
	payload,
});

export const onSetRotationIsActive: IOnSetRotationIsActive = payload => ({
	type: IUserActionTypes.SET_ROTATION_ACTIVE,
	payload,
});

export const onSetScaleHotSpotEnabled: IOnSetScaleHotSpotEnabled = scaleHotspotIsEnabled => ({
	type: IUserActionTypes.SET_SCALE_HOTSPOT_ENABLED,
	payload: scaleHotspotIsEnabled,
});

export const onSetBackgroundHotSpotEnabled: IOnSetBackgroundHotSpotEnabled = backgroundHotspotIsEnabled => ({
	type: IUserActionTypes.SET_BACKGROUND_HOTSPOT_ENABLED,
	payload: backgroundHotspotIsEnabled,
});

export const onSetPureReactInCanvasHovered: IOnSetPureReactInCanvasHovered = payload => ({
	type: IUserActionTypes.SET_PURE_REACT_IN_CANVAS_HOVERED,
	payload,
});

export const onAddEntityIdsToSelection: IOnAddEntityIdsToSelection = entityIds => ({
	type: IUserActionTypes.ADD_ENTITY_IDS_TO_SELECTION,
	payload: entityIds,
});

export const onRemoveSelection: IOnRemoveSelection = entityIds => ({
	type: IUserActionTypes.REMOVE_SELECTION,
	payload: entityIds,
});

export const onChangeZoomLevel: IOnChangeZoomLevel = zoomLevel => ({
	type: IUserActionTypes.CHANGE_ZOOM_LEVEL,
	payload: zoomLevel,
});

export const onSetPointerOffset: IOnSetPointerOffset = pointerOffset => ({
	type: IUserActionTypes.SET_CLICK_OFFSET,
	payload: pointerOffset,
});

export const onSetMarkerIndexPressed: IOnSetMarkerIndexPressed = markerIndex => ({
	type: IUserActionTypes.SET_MARKER_INDEX_PRESSED,
	payload: markerIndex as any,
});

export const onSetGroupInversion: IOnSetGroupInversion = axisInversion => ({
	type: IUserActionTypes.SET_GROUP_INVERSION,
	payload: axisInversion,
});

export const onCopySelectedEntities: IOnCopySelectedEntities = selectedIds => ({
	type: IUserActionTypes.COPY_SELECTED_ENTITIES,
	payload: selectedIds,
});

export const onSetSceneEntityListVisible: IOnSetSceneEntityListVisible = isVisibleProps => ({
	type: IUserActionTypes.SET_SCENE_ENTITY_LIST_VISIBLE,
	payload: isVisibleProps,
});

// export const onSetSnapshotDict: IOnSetSnapshotDict = snapshotDict => ({
// 	type: IUserActionTypes.SET_SNAPSHOT_DICT,
// 	payload: snapshotDict,
// });

export const onSetSceneCarouselScrollLeft: IOnSetSceneCarouselScrollLeft = scrollLeft => ({
	type: IUserActionTypes.SET_SCENE_CAROUSEL_SCROLL_LEFT,
	payload: scrollLeft,
});

export const onFocusSceneNameInput: IOnFocusSceneNameInput = boolean => ({
	type: IUserActionTypes.FOCUS_SCENE_NAME_INPUT,
	payload: boolean,
});

export const onSetEnlargeSceneMenu: IOnSetEnlargeSceneMenu = boolean => ({
	type: IUserActionTypes.SET_SCENE_MENU_LARGE,
	payload: boolean,
});

export const onEditSceneTitle: IOnEditSceneTitle = boolean => ({
	type: IUserActionTypes.EDIT_SCENE_TITLE, 
	payload: boolean, 
})

export const onSetEntityMenuDragActive: IOnSetEntityMenuDragActive = boolean => ({
	type: IUserActionTypes.SET_ENTITY_MENU_DRAG_ACTIVE,
	payload: boolean,
});

export const onSetEntityMenuHotspotDragPosition: IOnSetEntityMenuHotspotDragPosition = payload => ({
	type: IUserActionTypes.SET_ENTITY_MENU_DRAG_HOTSPOT_POSITION,
	payload,
});

export const onSetDraggedEntityInfo: IOnSetDraggedEntityInfo = payload => ({
	type: IUserActionTypes.SET_ENTITY_MENU_DRAG_INFO,
	payload,
});

export const onSetEntityMenuDrag2DPosition: IOnSetEntityMenuDrag2DPosition = payload => ({
	type: IUserActionTypes.SET_ENTITY_MENU_DRAG_2D_POSITION,
	payload,
});

export const onSetLoadingMedia: IOnSetLoadingMedia = payload => ({
	type: IUserActionTypes.LOADING_MEDIA,
	payload,
});

export const onAddToLoadedMedia: IOnAddToLoadedMedia = payload => ({
	type: IUserActionTypes.ADD_TO_LOADED_MEDIA,
	payload,
});

export const onRemoveLoadedMedia: IOnRemoveLoadedMedia = payload => ({
	type: IUserActionTypes.REMOVE_LOADED_MEDIA,
	payload,
});

export const onRemoveUpload: IRemoveUpload = payload => ({
	type: IUserActionTypes.REMOVE_UPLOAD,
	payload,
});

export const onSetCurrentUploads: IOnSetCurrentUploads = (id, progress) => ({
	type: IUserActionTypes.SET_CURRENT_UPLOADS,
	payload: { id, progress },
});

export const onSetAudioStatus: IOnSetAudioStatus = payload => ({
	type: IUserActionTypes.SET_AUDIO_STATUS,
	payload,
});

export const setTrkImgIsUploading: ISetTrkImgIsUploading = payload => ({
	type: IUserActionTypes.SET_TRACKING_IMG_UPLOADING,
	payload,
});

export const onSetTrackingImageData: IOnSetTrackingImageData = payload => ({
	type: IUserActionTypes.SET_TRACKING_IMG_DATA,
	payload,
});

export const onRemoveTrackingImageData: IOnRemoveTrackingImageData = () => ({
	type: IUserActionTypes.REMOVE_TRACKING_IMG_DATA,
});

export const onAddToast: IOnAddToast = payload => ({
	type: IUserActionTypes.ADD_TOAST, 
	payload, 
});

export const onRemoveToast: IOnRemoveToast = payload => ({
	type: IUserActionTypes.REMOVE_TOAST, 
	payload
});

export const onSetIs3dMode: IOnSetIs3dMode = boolean => ({
	type: IUserActionTypes.SET_3D_MODE,
	payload: boolean
})

export const onSetSceneHas3dEntity: IOnSetSceneHas3dEntity = boolean => ({
	type: IUserActionTypes.SET_SCENE_HAS_3D_ENTITY,
	payload: boolean
})

export const onSetTransformControlsActive: IOnSetTransformControlsActive = boolean => ({
	type: IUserActionTypes.SET_TRANSFORM_CONTROLS_ACTIVE,
	payload: boolean
})

export const onSetTransformControlsMode: IOnSetTransformControlsMode = payload => ({
	type: IUserActionTypes.SET_TRANSFORM_CONTROLS_MODE,
	payload
})

export const onSetPublishUploadStatus: IOnSetPublishUploadStatus = payload => ({
	type: IUserActionTypes.SET_PUBLISH_UPLOAD_STATUS,
	payload
})

export const onGetZmlFileInfo: IOnGetZmlFileInfo = args => {
	return async (dispatch: Dispatch) => {
		switch (args.type) {
			case IFileType.Model3d:
				const modelData = await zwClient.zml.search({
					...args,
					type: IFileType.Model3d,
				});
				modelData.forEach(model => {
					calcImgAspectRatio(model.output.thumbnailURL, aspectRatio => {
						const modelInfo: ILoadedModel3dInfo[] = [
							{
								id: uuid4(),
								zmlFileId: model.id,
								thumbnailUrl: model.output.thumbnailURL,
								model3dUrl: model.output.modelURL,
								aspectRatio,
								name: model.caption,
								dimensions: [
									model.output.dimensions.x,
									model.output.dimensions.y,
									model.output.dimensions.z
								]
							},
						];
						const payload = {
							mediaType: ILoadedMediaTypes.model3d,
							position: IAddMediaPositionTypes.end,
							mediaInfo: modelInfo,
						} as any;
						dispatch<IOnAddToLoadedMediaAction>({
							type: IUserActionTypes.ADD_TO_LOADED_MEDIA,
							payload,
						});	

					})
				})
				break;
			case IFileType.Hls:
				const videoData = await zwClient.zml.search({
					...args,
					type: IFileType.Hls,
				});
				videoData.forEach(video => {
					calcImgAspectRatio(video.output.thumbnailURL, aspectRatio => {
						const videoInfo: IUnionLoadedVideoInfo[] = [
							{
								id: uuid4(),
								zmlFileId: video.id,
								thumbnailUrl: video.output.thumbnailURL,
								mp4Url: video.output.mp4URL,
								aspectRatio,
								name: video.caption,
								source: VideoSourceTypes.user,
								videoUrl: `https:${video.url}/auto.m3u8`,
								hasAlpha: video.output.hasAlpha || false
							},
						];
						const payload = {
							mediaType: ILoadedMediaTypes.video,
							position: IAddMediaPositionTypes.end,
							mediaInfo: videoInfo,
						};
						dispatch<IOnAddToLoadedMediaAction>({
							type: IUserActionTypes.ADD_TO_LOADED_MEDIA,
							payload,
						});	

					})
				});
				break;
			case IFileType.Audio:
				const audioData = await zwClient.zml.search({
					...args,
					type: IFileType.Audio,
				});
				const audioInfoArray: ILoadedAudioInfo[] = audioData.map((audio, i) => {
					return {
						id: uuid4(),
						zmlFileId: audio.id,
						url: `https:${audio.url}/aud.mp3`,
						name: audio.caption,
					};
				});
				dispatch<ISetLoadedAudioAction>({
					type: IUserActionTypes.SET_LOADED_AUDIO,
					payload: audioInfoArray,
				});
				break;

			default:
				const imageData = await zwClient.zml.search({
					...args,
					type: IFileType.Image,
				});
				//TODO: change back once backend aspect ratio implemented
				imageData.forEach((img, i) => {
					const imgObj = new Image();
					imgObj.addEventListener('load', function() {
						const mediaInfo: ILoadedImageInfo[] = [
							{
								id: uuid4(),
								zmlFileId: img.id,
								url: `https:${img.url}/img`,
								aspectRatio: this.naturalWidth / this.naturalHeight,
								name: img.caption,
							},
						];
						const payload = {
							mediaType: ILoadedMediaTypes.image,
							position: IAddMediaPositionTypes.end,
							mediaInfo,
						};
						dispatch<IOnAddToLoadedMediaAction>({
							type: IUserActionTypes.ADD_TO_LOADED_MEDIA,
							payload,
						});
					});
					imgObj.src = `https:${img.url}/img`;
				});
		}

		dispatch({
			type: IUserActionTypes.LOADING_MEDIA,
			payload: false,
		});
	};
};
