import React, { FunctionComponent } from 'react';

import CSS from './PanelSkeleton.scss';

interface IParentProps {
    className?: string
	style?: React.CSSProperties; 
	animationDuration?: string; 
}


const Skeleton: FunctionComponent<IParentProps> = ({
	className = '', 
	style = {}, 
	animationDuration = '2.5s',
}) => {
	let classes = [CSS.Skeleton, className];

	return (
		<>
			<div className={CSS.OuterBackground} />
			<div 
				className={classes.join(' ')} 
				style={{...style}}
			>
				<div 
					className={CSS.LightShimmer} 
					style={{animationDuration: `${animationDuration}`}}
				/>
				<div className={CSS.FadeGradient}/>
				<div className={CSS.InnerBackground} />
			</div>
			
		</>
	);
};

export default Skeleton;