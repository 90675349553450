import React, {useEffect, useRef, useState} from 'react';

import CSS from './Slider.scss';

interface IParentProps {
	style?: React.CSSProperties;
	className?: string;
	value: number;
	onChange?: (value: number) => any;
	onSubmit?: () => any; 
	min?: number;
	max?: number;
	step?: number;
	disabled?: boolean; 
}


const Slider: React.SFC<IParentProps> = ({
	min = 0,
	max = 100,
	step = 1,
	value,
	onChange, 
	onSubmit,
	className,
	style,
	disabled, 
}) => {
	const [thumbMove, setThumbMove] = useState(false); 

	let sliderClasses = [CSS.Slider, className ? className : ''];
	if(disabled){
		sliderClasses.push(CSS.DisabledSlider); 
	}
	if(value < min || value > max) value = min; 
	
	const thumbEl = useRef<HTMLDivElement>(null);
	
	useEffect(() => {
		if(thumbMove) return;
		positionThumbFromValue(value)
		positionProgressBarFromValue(value)
	}, [value, thumbMove]);

	const generateSlide = (mousePosition: number) => {
		const thumb = thumbEl.current as HTMLDivElement;
		const sliderBar = thumb.parentNode as HTMLDivElement;
		const sliderBarRect = sliderBar.getBoundingClientRect();
		const progressBar =  sliderBar.childNodes[0] as HTMLDivElement;
		
		const leftBorder = sliderBarRect.x;
		const rightBorder = leftBorder + sliderBarRect.width - (thumb.getBoundingClientRect().width); 
	
		const maxLeft = 0;
		const maxRight = sliderBarRect.width - thumb.getBoundingClientRect().width; 
	
		let newPos = mousePosition - leftBorder - (thumb.getBoundingClientRect().width /2);

		if( mousePosition <= leftBorder){
			thumb.style.transform = `translate(${maxLeft}px, -40%)`; 
			progressBar.style.width = "0%";
		} 
		else if( mousePosition >= rightBorder){
			thumb.style.transform = `translate(${maxRight}px, -40%)`; 
			progressBar.style.width = `${sliderBarRect.width - thumb.getBoundingClientRect().width}`;
		} 
		else {
			thumb.style.transform = `translate(${newPos}px, -40%)`; 
			progressBar.style.width = `${mousePosition - leftBorder }px`;
		}
	}
	
	const countDecimals = (numberToTest:number) => {
		if(Math.floor(numberToTest.valueOf()) === numberToTest.valueOf()) return 0;
		return numberToTest.toString().split(".")[1].length || 0; 
	}
	
	const positionProgressBarFromValue = (currValue: number) => {
		const sliderBar = thumbEl.current?.parentNode as HTMLDivElement; 
		const progressBar = sliderBar.childNodes[0] as HTMLElement; 
	
		const maxValue = max - min; 
		const updatedValue = currValue - min; 
		const newPos = updatedValue * 100 / maxValue; 
	
		progressBar.style.width = `${newPos}%`;
	}
	
	const positionThumbFromValue = (currValue: number) => {
		const thumb = thumbEl.current as HTMLDivElement;
		const sliderBar = thumb.parentNode as HTMLElement; 
		const endSliderBar = sliderBar.getBoundingClientRect().width - thumb.getBoundingClientRect().width;
		const totalNumOfSteps = (max - min) / step; // floor? 
		const stepSize = endSliderBar / totalNumOfSteps; 
		const numOfStepToDo = (currValue - min) * totalNumOfSteps / (max - min);
		const newPos = stepSize * numOfStepToDo; 
	
		thumb.style.transform = `translate(${newPos}px, -40%)`; 
	}

	const calculateValue = () =>{
		const thumb = thumbEl.current as HTMLDivElement;
		const sliderBar = thumb.parentNode as HTMLDivElement;
		const maxRightPosition = sliderBar.getBoundingClientRect().width - thumb.getBoundingClientRect().width;
		const thumbPosition =  Math.round(thumb.getBoundingClientRect().x - sliderBar.getBoundingClientRect().x);  

		const outputValue = (thumbPosition * (max - min) / maxRightPosition) + min; 
		const numbOfDecimals = countDecimals(step); 
		const updatedOutputValue = +(Math.round(outputValue / step) * step).toFixed(numbOfDecimals); 

		if(updatedOutputValue <= min) return min; 
		if(updatedOutputValue >= max) return max; 

		return updatedOutputValue; 
	}

	const slide = (event:React.PointerEvent<HTMLDivElement>) => {
		generateSlide(event.clientX);
		event.preventDefault(); 
		
		const updatedValue = calculateValue();
		positionThumbFromValue(updatedValue);
		positionProgressBarFromValue(updatedValue);
		if (onChange) onChange(updatedValue);
	  }

	const onPointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
		if(e.button === 0){
			setThumbMove(true);
			const thumb = thumbEl.current as HTMLDivElement;
			thumb.setPointerCapture(e.pointerId);
		} 
	}
	
	const onPointerUp = (e: React.PointerEvent<HTMLDivElement>) => {
		setThumbMove(false);	
		const thumb = thumbEl.current as HTMLDivElement;
		thumb.releasePointerCapture(e.pointerId);	
	}
				
	return (
		<div
			onPointerDown={(e) => {if(!disabled) slide(e)}}
			className={sliderClasses.join(' ')}
			style={style}
			onPointerUp={(e) => {
				if(!disabled){
					if (onSubmit) onSubmit(); 
					onPointerUp(e); 
				}		
			}}	
			onPointerMove={thumbMove? slide : undefined}
		>
			<div className={CSS.Track}>
				<div className={CSS.Progress}></div>
				<div 
					ref={thumbEl}
					className={CSS.Thumb}
					onPointerDown={(e) => {if(!disabled) onPointerDown(e)}}
					onPointerUp={(e) => {if(!disabled) onPointerUp(e)}}
				></div>
			</div>	
		</div>
	);
};

export default Slider;