import {
	IEditorDocActionTypes,
	IOnSetSelection_Ed_Doc,
	IOnSetPosition_Ed_Doc,
	IOnSetScale_Ed_Doc,
	IOnSetScaleAndPosition_Ed_Doc,
	IOnSetRotation_Ed_Doc,
	IOnSetMultipleEntityProps_Ed_Doc,
	IOnAddTracking_Ed_Doc,
	IOnSetProjectColors_Ed_Doc,
	IOnSetPublishStatus_Ed_Doc,
	IOnSetTargetDisplayInfo_Ed_Doc
} from '../action-types';
import { Dispatch } from 'redux';
import { IImageTrackingState_Ed_Doc, ITrackingTypes, ITrackingUnionState_Ed_Doc } from '../../../components/r3f/r3f-components/component-data-structure';

export const onSetSelection_Ed_Doc: IOnSetSelection_Ed_Doc = payload => ({
	type: IEditorDocActionTypes.SET_SELECTION_ED_DOC,
	payload,
});

export const onSetPosition_Ed_Doc: IOnSetPosition_Ed_Doc = payload => ({
	type: IEditorDocActionTypes.SET_POSITION_ED_DOC,
	payload,
});

export const onSetScale_Ed_Doc: IOnSetScale_Ed_Doc = scale => ({
	type: IEditorDocActionTypes.SET_SCALE_FACTOR_ED_DOC,
	payload: scale,
});

export const onSetScaleAndPosition_Ed_Doc: IOnSetScaleAndPosition_Ed_Doc = entityProps => ({
	type: IEditorDocActionTypes.SET_SCALE_AND_POSITION_ED_DOC,
	payload: entityProps,
});

export const onSetRotation_Ed_Doc: IOnSetRotation_Ed_Doc = entityProps => ({
	type: IEditorDocActionTypes.SET_ROTATION_ED_DOC,
	payload: entityProps,
});

export const onSetMultipleEntityProps_Ed_Doc: IOnSetMultipleEntityProps_Ed_Doc = multipleEntityProps => ({
	type: IEditorDocActionTypes.SET_MULTIPLE_ENTITY_PROPS_ED_DOC,
	payload: multipleEntityProps,
});

//Add tracking image
export const onAddTracking_Ed_Doc: IOnAddTracking_Ed_Doc = pld => {
	let payload: ITrackingUnionState_Ed_Doc;
	if (pld.type === ITrackingTypes.image) {
		payload = pld as IImageTrackingState_Ed_Doc;
		if (payload.aspectRatio) {
			payload = { ...payload, scale: [1 * payload.aspectRatio, 1, 0] };
		}
	}
	return async (dispatch: Dispatch) => {
		dispatch({
			type: IEditorDocActionTypes.ADD_TRACKING_ED_DOC,
			payload,
		});
	};
};

// Set display values for target
export const onSetTargetDisplayInfo_Ed_Doc: IOnSetTargetDisplayInfo_Ed_Doc = payload => ({
	type: IEditorDocActionTypes.SET_TARGET_DISPLAY_INFO,
	payload,
});

export const onSetProjectColors_Ed_Doc: IOnSetProjectColors_Ed_Doc = payload => {
	return {
		type: IEditorDocActionTypes.ADD_PROJECT_COLORS_ED_DOC,
		payload,
	}
};

export const onSetPublishStatus_Ed_Doc: IOnSetPublishStatus_Ed_Doc = payload => ({
	type: IEditorDocActionTypes.SET_PUBLISH_STATUS_ED_DOC,
	payload,
});
