import React, { useState, useRef, useEffect } from 'react';
import { useRefState } from '../../../r3f/r3f-components/hooks';
import CSS from './Text.scss';
interface IParentProps {
	style?: React.CSSProperties;
	className?: string;
	error?: boolean;
	value: string;
	focused?: boolean;
	placeholder?: string; 
	maxLength? : number; 
	disabled?: boolean;
	label? : string;
	ellispsis? : boolean; 
	title? : string; 
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
	onFinalChange?: (
		e:
			| React.KeyboardEvent<HTMLInputElement>
			| React.FocusEvent<HTMLInputElement>
	) => any;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => any;
	onFocus?: (e: React.FocusEvent<HTMLInputElement>) => any;
	onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => any;
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => any;
	onClick?:  (e: React.MouseEvent<HTMLInputElement>) => any;
	onMouseLeave?: (e: React.MouseEvent<HTMLInputElement>) => any; 
	onUnmount?: (val: string) => any;
}

const TextInput: React.FunctionComponent<IParentProps> = ({
	style,
	className,
	value,
	onChange,
	onFinalChange,
	onFocus,
	focused = false,
	placeholder, 
	onBlur,	// This runs on onUnmount and onBlur
	onKeyUp,
	onKeyDown,
	onClick, 
	error,
	maxLength, 
	disabled,
	label, 
	ellispsis = false, 
	title, 
	onMouseLeave, 
	onUnmount
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const focusRef = useRef<React.FocusEvent<HTMLInputElement>>(null);
	const [userInput, setUserInput] = useState<boolean>(false);
	const [tempValue, setTempValue, tempValueRef] = useRefState<string>(value);
	if (!userInput && tempValue !== value) setTempValue(value);
	let classes = [CSS.TextInput, className ? className : ''];
	if(!disabled) classes.push(CSS.Editable);
	if(label) classes.push(CSS.Label);
	if(ellispsis) classes.push(CSS.Ellipsis);

	useEffect(() =>{
		if (focused) inputRef.current.focus();
	}, [focused, inputRef]);

	useEffect(() => {
		return () => {
			onBlur(focusRef.current);
			onUnmount?.(tempValueRef.current);
		}
	},[])


	const onFocusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
		focusRef.current = e;
		if (onFocus) onFocus(e);
	};

	const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
		setUserInput(false);
		if (onBlur) onBlur(e);
		if (onFinalChange && tempValue !== value) onFinalChange(e);
	};

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserInput(true);
		if (onChange) onChange(e);
		setTempValue(e.target.value);
	};

	const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (onKeyDown) onKeyDown(e);
		if (e.keyCode !== 13) return;
		inputRef?.current?.blur();
	};
	
	return (
		<div className={CSS.TextInputContainer}>
			<input
				ref={inputRef}
				style={{ ...style, borderColor: error ? 'red' : '' }}
				className={classes.join(' ')}
				type="text"
				value={tempValue}
				placeholder={placeholder}
				maxLength={maxLength}
				disabled={disabled}
				title={title}
				onFocus={onFocusHandler}
				onBlur={onBlurHandler}
				onChange={onChangeHandler}
				onKeyDown={onKeyDownHandler}
				onKeyUp={e => {
					if (onKeyUp) onKeyUp(e);
				}}
				onClick={onClick}
				onMouseLeave={onMouseLeave}
			/>
			{label && <label>{label}</label>}
		</div>
	);
};

export default TextInput;
