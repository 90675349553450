import { IToastType,  IToastSize, IToastContrast  } from '../../typings';
import { ILoadedMediaTypes } from '../store/actions';

type IFileDescription = 'model' | 'video' | 'image' | 'audio';

const errorSettings = {
    type: IToastType.error,
    contrast: IToastContrast.high, 
    size: IToastSize.mini, 
}

const successSettings = {
    type: IToastType.success, 
    contrast: IToastContrast.default, 
    size: IToastSize.mini, 
}

const infoSettings = {
    type: IToastType.info, 
    contrast: IToastContrast.default, 
    size: IToastSize.mini, 
}

export const ToastsData = {
    GeneralError: {
        ...errorSettings,
        message: "Something went wrong. Try reloading the page.", 
    }, 
    PreviewError: {
        ...errorSettings,
        message: "Unable to preview project. Try again later.", 
    }, 
    PreviewNoTrkImgError: {
        ...errorSettings,
        message: "Add a target image to be able to preview.", 
    },
    PublishSuccess: {
        ...successSettings,
        message: "Project successfully published!", 
    },
    PublishError: {
        ...errorSettings,
        message: "Unable to publish project. Try again later.", 
    }, 
    PublishNoTrkImgError: {
        ...errorSettings,
        message: "Add a target image to be able to publish.", 
    },
    NoAccessGrantedError: {
        ...errorSettings,
        message: "You don't have access to this project. Choose another project from ZapWorks.",
    },
    SessionExpiredError: {
        ...errorSettings,
        message: "Your session has expired. Refresh the page to log back in to ZapWorks.",
    },
    SocketConnectionError: {
        ...errorSettings,
        message:'Unable to connect to server. Try reloading the page.',
    }, 
    SocketConnectionSuccess: {
        ...successSettings,
        message: 'Connection with server established.',
    },
    SocketReconnecting: {
        ...infoSettings, 
        message: 'Reconnecting to server.',
    },
    AuthorizationError: {
        ...errorSettings,
        message: 'Authentication failed. Try reloading the app.',
    },
    InternalServerError: {
        ...errorSettings,
        message: 'Internal server error. Try reloading the app.', 
    }, 
    FailureLoadingError: {
        ...errorSettings,
        message: 'Failure loading component.',
    },
    MaxUploadSizeExceededError: (filesize: number, fileDesc: IFileDescription) => {
        return {
            ...errorSettings,
            message: `Maximum upload size for ${fileDesc} files exceeded (${filesize}MB) .`,
        }
    },
    ErrorWithCustomMessage: (message: string) => ({
        ...errorSettings,
        message,
    })

}