import React, { FunctionComponent, memo, useMemo } from 'react';
import * as THREE from 'three';
import { ITuple3, ITuple4 } from '../component-data-structure';
import { ISharedHOCProps } from '../types';
import { AlphaMaterial } from '../components/customMaterials';
import { extend } from '@react-three/fiber';
import { useSRGBColors } from '../hooks/useSRGBColors';

extend({ AlphaMaterial });

export interface IShapeParentProps {
	visible?: boolean;
	rotation?: ITuple3;
	position?: ITuple3;
	scale: ITuple3;
	color?: ITuple4 | string;
	depthWrite?: boolean;
	renderOrder?: number;
	onUpdate?: (self: THREE.Object3D) => any;
	opacity: number;
	texture?: THREE.Texture;
	shape: THREE.Shape;
	hasAlpha?: boolean;
	stackedAlpha?: boolean;
	name?: string;
}

const CustomShape: FunctionComponent<IShapeParentProps & ISharedHOCProps> = ({
	rotation: r = [0, 0, 0],
	position: p = [0, 0, 0],
	scale: s,
	color: c,
	onUpdate,
	onPointerUp,
	onPointerDown,
	onPointerMove,
	onDoubleClick,
	renderOrder,
	opacity,
	texture,
	shape: sp,
	name = '',
	hasAlpha = false,
	stackedAlpha = false,
}) => {
	const color = useSRGBColors(c);
	const rotation = useMemo(() => r as ITuple3,[r]);
	const position = useMemo(
		() => [p[0] - s[0] / 2, p[1] - s[1] / 2, p[2]] as ITuple3,
		[p, s]
	);
	const shape = useMemo(() => [sp] as [THREE.Shape], [sp]);
	const meshScale = useMemo(
		() => [s[0] < 0 ? -1 : 1, s[1] < 0 ? -1 : 1, 1] as ITuple3,
		[s]
	);

	const geometry = useMemo(() => {
		return <shapeGeometry
			// attach="geometry"
			args={shape}
			onUpdate={(self) => {
				if (!texture) return; // if no texture provided no need to adjust uv mapping
				const { uv, position } = self.attributes;
				for (let i = 0; i < uv.count; i++) {
					const u = position.getX(i) / Math.abs(s[0]);
					const v = position.getY(i) / Math.abs(s[1]);
					uv.setXY(i, u, v);
				}
				if ((uv as any).needsUpdate !== undefined)
					(uv as any).needsUpdate = true;
			}}
		/>
	}, [shape])

	return (
		<group
			position={position}
			rotation={rotation}
			scale={meshScale}
			key={'rounded_rect_group'}
			name={'rounded_rect_group'}
			onUpdate={onUpdate || undefined}
			onPointerUp={onPointerUp || undefined}
			onPointerDown={onPointerDown || undefined}
			onPointerMove={onPointerMove || undefined}
			onDoubleClick={onDoubleClick || undefined}
		>
			{((opacity === 1.0 && !hasAlpha) &&
			<mesh renderOrder={1}>
				{geometry}
				<meshBasicMaterial
					colorWrite={false}
					polygonOffset={true}					
					polygonOffsetUnits={10}
					polygonOffsetFactor={10}
					depthWrite={true}
					side={THREE.DoubleSide}
				/>
			</mesh>
			)}
			<mesh renderOrder={renderOrder}>
				{geometry}
				{!stackedAlpha ? (
					<meshBasicMaterial
						color={color}
						opacity={opacity}
						side={THREE.DoubleSide}
						map={texture}
						depthWrite={false}
						transparent={true}
						// transparent={(opacity !== 1.0)}
					/>
				) : (
					<alphaMaterial
						packedTexture={texture || new THREE.Texture()}
						transparent={true}
						opacity={opacity}
						depthWrite={false}
					/>
				)}
			</mesh>
		</group>
	);
};

CustomShape.displayName = 'shapeRect';

export default memo(CustomShape);
