import React, { FunctionComponent } from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const moveForwardIcon: FunctionComponent<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
	title, 
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="32px"
			height="32px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 32 32'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<title>{title}</title>
			  <path fill={hexFill} d="M25,6.15085414 C25.5522847,6.15085414 26,6.59856939 26,7.15085414 L26,10.1508541 C26,10.7031389 25.5522847,11.1508541 25,11.1508541 L19,11.1508541 L19,20.1508541 L20.618975,20.1508541 C20.8951174,20.1508541 21.118975,20.3747118 21.118975,20.6508541 C21.118975,20.7992441 21.0530635,20.9399679 20.9390672,21.0349648 L16.3200922,24.8841106 C16.1346692,25.0386298 15.8653308,25.0386298 15.6799078,24.8841106 L11.0609328,21.0349648 C10.8487943,20.8581827 10.8201323,20.5429004 10.9969143,20.3307619 C11.0919112,20.2167656 11.232635,20.1508541 11.381025,20.1508541 L12.999,20.1508541 L13,11.1508541 L7,11.1508541 C6.44771525,11.1508541 6,10.7031389 6,10.1508541 L6,7.15085414 C6,6.59856939 6.44771525,6.15085414 7,6.15085414 L25,6.15085414 Z M25,7.15085414 L7,7.15085414 L7,10.1508541 L14.0001111,10.1508541 L13.9988889,21.1508541 L12.763,21.1508541 L16,23.8478541 L19.236,21.1508541 L18,21.1508541 L18,10.1508541 L25,10.1508541 L25,7.15085414 Z M25.25,13.1508541 C25.6642136,13.1508541 26,13.5239502 26,13.9841875 L26,17.3175208 C26,17.7777581 25.6642136,18.1508541 25.25,18.1508541 L20,18.1508541 L20,17.1508541 L25,17.1508541 L25,14.1508541 L20,14.1508541 L20,13.1508541 L25.25,13.1508541 Z M12,13.1508541 L12,14.1508541 L7,14.1508541 L7,17.1508541 L12,17.1508541 L12,18.1508541 L6.75,18.1508541 C6.33578644,18.1508541 6,17.7777581 6,17.3175208 L6,13.9841875 C6,13.5239502 6.33578644,13.1508541 6.75,13.1508541 L12,13.1508541 Z" transform="matrix(1 0 0 -1 0 31.15)"/>
		</svg>
	);
};

export default moveForwardIcon;
