import React, { useState, useRef, useEffect } from 'react';
import { onSetActionData_Global } from '../../../../../../../store/actions';

import {
	parseSocialUrl,
	generateSocialUrl,
	getSocialUrlForProvider,
} from '../../../../../../../utils';

import {
	WebsiteIconSvg,
} from '../../../../../../../assets/icons';

import { 
	InstagramIcon,
	FacebookIcon,
	LinkedinIcon,
	RedditIcon,
	SoundcloudIcon,
	TiktokIcon,
	TwitchIcon,
	TwitterIcon,
	VimeoIcon,
	YoutubeIcon,
	SpotifyIcon,
 } from '../../../../../../r3f/r3f-components/components/assets/icons';

import SocialSummary from './Summary/Summary';

import CSS from './SocialLink.scss';
import { socialRegex as regex } from '../../../../../../../utils';
import { TextInput } from '../../../../../Inputs';
import {
	ITriggerTypes,
	ISocialOptions,
	ISocialProvider,
	IActionCategory,
} from '../../../../../../r3f/r3f-components/component-data-structure';
import { useDispatch } from 'react-redux';

interface IParentProps {
	targetIds: string[];
	triggerType: ITriggerTypes;
	setShowActionSummary: (value: React.SetStateAction<boolean>) => void;
	setPageLinkInputFromSocials: (customUrl: string) => void;
	showActionSummary: boolean;
	socialLinkActions: ISocialOptions;
}

interface ISocial {
	provider: ISocialProvider;
	svg?: string;
	regex?: RegExp;
}

type ISocialsData = ISocial[];

const SocialLink: React.SFC<IParentProps> = ({
	targetIds,
	triggerType,
	socialLinkActions: socialData,
	setShowActionSummary,
	showActionSummary,
	setPageLinkInputFromSocials,
}) => {
	const dispatch = useDispatch();
	const socialsData: ISocialsData = [
		{
			provider: ISocialProvider.facebook,
			svg: FacebookIcon,
			regex: regex.facebook,
		},
		{
			provider: ISocialProvider.youtube,
			svg: YoutubeIcon,
			regex: regex.youtube,
		},
		{
			provider: ISocialProvider.instagram,
			svg: InstagramIcon,
			regex: regex.instagram,
		},
		{
			provider: ISocialProvider.reddit,
			svg: RedditIcon,
			regex: regex.reddit,
		},
		{
			provider: ISocialProvider.tiktok,
			svg: TiktokIcon,
			regex: regex.tiktok,
		},
		{
			provider: ISocialProvider.soundCloud,
			svg: SoundcloudIcon,
			regex: regex.soundCloud,
		},
		{
			provider: ISocialProvider.twitch,
			svg: TwitchIcon,
			regex: regex.twitch,
		},
		{
			provider: ISocialProvider.linkedIn,
			svg: LinkedinIcon,
			regex: regex.linkedIn,
		},
		{
			provider: ISocialProvider.vimeo,
			svg: VimeoIcon,
			regex: regex.vimeo,
		},
		{
			provider: ISocialProvider.spotify,
			svg: SpotifyIcon,
			regex: regex.spotify,
		},
		{
			provider: ISocialProvider.twitter,
			svg: TwitterIcon,
			regex: regex.twitter,
		},
		{
			provider: ISocialProvider.custom,
			svg: WebsiteIconSvg,
		},
	];

	const activeSocialUrl = socialData.selectedProvider
		? getSocialUrlForProvider(socialData, socialData.selectedProvider)
		: '';

	const {
		selectedSocial: activeSocial,
		userName: activeUserName,
	} = parseSocialUrl(activeSocialUrl || '');

	const [userName, setUserName] = useState<string>(activeUserName || '');
	const [error, setError] = useState<string>('');
	const [socialUrl, setSocialUrl] = useState<string>(activeSocialUrl);
	const [socialProvider, setSocialProvider] = useState<ISocialProvider>(
		activeSocial || ISocialProvider.noSocial
	);
	const [inputFocused, setInputFocus] = useState<boolean>(false);
	const socialContainerDivEl = useRef(null);

	const hideSummary =
		error ||
		!showActionSummary ||
		!activeUserName ||
		!socialData.selectedProvider ||
		socialData.selectedProvider === ISocialProvider.noSocial;

	const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSocialUrl(e.target.value);
		setError('');
	};

	const keyUpHandler = (e: React.KeyboardEvent) => {
		if (e.keyCode === 13) return;
		const { selectedSocial: provider, userName } = parseSocialUrl(socialUrl);
		setUserName(userName);
		setSocialProvider(provider);
	};

	const keyDownHandler = (e: React.KeyboardEvent) => {
		if (e.keyCode !== 13) return;
		setInputFocus(false);
		processInputValue(socialProvider, userName);
	};

	const processInputValue = (
		socialProvider: ISocialProvider,
		userName: string
	) => {
		if (!socialUrl?.length) return;
		else if (!userName && socialProvider !== ISocialProvider.noSocial) {
			setError('Profile username or id not found. Click on the highlighted button to add a custom url.');
			setSocialProvider(ISocialProvider.custom);
		} else if (socialProvider === ISocialProvider.noSocial) {
			setError(`Social media profile not found. Click on the highlighted button to add a custom url.`);
			setSocialProvider(ISocialProvider.custom)
		} else {
			setError('');
			setShowActionSummary(true);
			dispatch(onSetActionData_Global({
				ids: targetIds,
				triggerType: triggerType,
				selectedActionCategory: IActionCategory.linkSocial,
				socialOptions: {
					[socialProvider]: generateSocialUrl(socialProvider, userName),
					selectedProvider: socialProvider,
				},
			}));
		};
	};

	const socialBtns = socialsData.map(social => {
		return (
			<button
				key={social.provider}
				onClick={() => {
					const url = getSocialUrlForProvider(socialData, social.provider);
					const generatedUrl = generateSocialUrl(social.provider, userName);
					setError('');
					if (social.provider === ISocialProvider.custom) {
						setPageLinkInputFromSocials(socialUrl);
						return;
					}
					setSocialUrl(url || generatedUrl);
					setSocialProvider(social.provider);
					setInputFocus(true);
				}}
				className={`${CSS.SocialBtn} ${
					social.provider === socialProvider ? CSS.Active : ''
				}`}
			>
				<img src={social.svg} />
			</button>
		);
	});

	let selectedSvg: string;
	socialsData.forEach(soc => {
		if (soc.provider === activeSocial) selectedSvg = soc.svg;
	});

	return (
		<div className={CSS.ActionsFlexContainer} ref={socialContainerDivEl}>
			{hideSummary ? (
				<div className={CSS.OuterContainer}>
					<div className={CSS.Description}>Add a social media profile</div>
					<div className={CSS.SocialInputContainer}>
						<TextInput
							value={socialUrl}
							placeholder={"Social profile url"}
							onChange={changeHandler}
							onKeyUp={keyUpHandler}
							onKeyDown={keyDownHandler}
							focused={inputFocused}
						/>
						<span className={CSS.ProfileError} style={{margin: `${!!error ? 16 : 8}px 0`}}>{error}</span>
					</div>
					<div className={CSS.BtnGridContainer}>{socialBtns}</div>
				</div>
			) : (
				<SocialSummary
					svg={selectedSvg}
					userName={activeUserName}
					url={activeSocialUrl}
				/>
			)}
		</div>
	);
};

export default SocialLink;
