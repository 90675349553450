import React, {FunctionComponent, useEffect} from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { bindActionCreators } from 'redux';
import { IToast, IDesignerState } from '../../../../typings';
import Toast from './Toast/Toast';

import {
    onRemoveToast,
	IOnRemoveToast, 
} from '../../../store/actions';

import CSS from './Toasts.scss';

interface IParentProps {
    appendToElById?: string; 
}

interface IReduxProps {
    toasts: IToast[];
}

interface IDispatchProps {
	onRemoveToast: IOnRemoveToast;
}

const Toasts: FunctionComponent<IParentProps  & IReduxProps & IDispatchProps> = ({
	toasts, 
    appendToElById = 'root',
    onRemoveToast,
}) => {

	useEffect(() => {
		for(let i = 0; i < toasts.length - 1; i++){
			if(!toasts[i].timeout) toasts[i].timeout = 6000; 
		}
	}, [toasts]); 


    return (
		createPortal(
            <div className={CSS.Toasts}>
                {toasts.map((toast, index) => 
				{
					return <Toast 
							id={toast.id} 
							key={toast.id} 
							message={toast.message} 
							type={toast.type} 
							onClose={() => onRemoveToast(toast.id)}  
							timeout={toast.timeout} 
							size={toast.size}  
							contrast={toast.contrast} 
							stackToasts={((toasts.length > 1) && (index + 1 < toasts.length))}
						/>
				})
				}
            </div>
            , document.getElementById(appendToElById))
        );
};

const mapStateToProps  = (state: IDesignerState): IReduxProps => {	
	return {
		toasts: state.userReducer.toasts,
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onRemoveToast
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);