import React, { useState, useRef, memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { IDesignerState } from '../../../../../../typings';
import Divider from '../../../Divider/Divider';
import Btn from '../../../buttons/MenuBtn/MenuBtn';
import uuid4 from 'uuid/v4';

import { bindActionCreators } from 'redux';

import {
	updateLStorageWithSnapshot,
	getSnapshotDictFromLStorage,
} from '../../../../../utils';

import {
	IOnRemoveSelection,
	onRemoveSelection,
	onSetActiveScene,
	IOnSetActiveScene,
	onCopyScenesAtIndex_Global,
	IOnCopyScenesAtIndex_Global,
	onSetCurrentSceneIndex,
	IOnSetCurrentSceneIndex,
	onSetSceneSnapshots,
	IOnSetSceneSnapshots,
	ISceneSnapshotDict,
	onSetSceneCarouselScrollLeft,
	IOnSetSceneCarouselScrollLeft,
	IOnSetEnlargeSceneMenu,
	onSetEnlargeSceneMenu,
	onAddScenes_Global,
	IOnAddScenes_Global,
	IOnEditSceneTitle,
	onEditSceneTitle,
	onScrollSceneMenu,
	IOnScrollSceneMenu, 
} from '../../../../../store/actions';

import {
	ArrowLeftThin,
	ArrowRightIcon,
	DuplicateSceneIcon, 
	AddSceneIcon,
} from '../../../../../assets/icons/index';

import CSS from './ButtonRow.scss';
import {
	getRootComponent,
	getRootComponentId,
	getEditorTitlesDict,
} from '../../../../../store/selectors';

interface IParentProps{
	showSceneChangeArrow: boolean; 
}

interface IReduxProps {
	selectedEntityIds: string[];
	activeSceneId: string;
	currentSceneIndex: number;
	sceneSnapshotDict: ISceneSnapshotDict;
	sceneMenuIsLarge: boolean;
	projectId: string;
	rootId: string;
	rootChildren: string[];
	editorTitlesDict: { [id: string]: string };
}

interface IDispatchProps {
	onRemoveSelection: IOnRemoveSelection;
	onSetActiveScene: IOnSetActiveScene;
	onCopyScenesAtIndex_Global: IOnCopyScenesAtIndex_Global;
	onSetCurrentSceneIndex: IOnSetCurrentSceneIndex;
	onSetSceneSnapshots: IOnSetSceneSnapshots;
	onSetSceneCarouselScrollLeft: IOnSetSceneCarouselScrollLeft;
	onSetEnlargeSceneMenu: IOnSetEnlargeSceneMenu;
	onAddScenes_Global: IOnAddScenes_Global;
	onEditSceneTitle: IOnEditSceneTitle;
	onScrollSceneMenu: IOnScrollSceneMenu;
}

const SceneMenuButtonRow: React.FunctionComponent<IReduxProps & IDispatchProps & IParentProps> = ({
	onRemoveSelection,
	onSetActiveScene,
	selectedEntityIds,
	activeSceneId,
	onCopyScenesAtIndex_Global,
	onSetCurrentSceneIndex,
	currentSceneIndex,
	onSetEnlargeSceneMenu,
	sceneMenuIsLarge,
	onSetSceneSnapshots,
	onAddScenes_Global,
	onEditSceneTitle,
	projectId,
	rootId,
	rootChildren,
	editorTitlesDict,
	showSceneChangeArrow, 
	onScrollSceneMenu, 
}) => {
	const listBtnRef = useRef<HTMLDivElement>(null);
	const [isSceneMenuOpen, setIsSceneMenuOpen] = useState<boolean>();
	
	useEffect(() => {
		setIsSceneMenuOpen(sceneMenuIsLarge)
	}, [sceneMenuIsLarge]); 

	const openSlideCarouselClickHandler = (hasBeenDoubledClicked = false) => {
		const sceneId = activeSceneId || rootChildren[0];
		onSetActiveScene({ activeSceneId: sceneId });
		onEditSceneTitle(hasBeenDoubledClicked);
		onSetEnlargeSceneMenu(!sceneMenuIsLarge);
		setIsSceneMenuOpen(!isSceneMenuOpen); 
		updateLStorageWithSnapshot(sceneId, projectId);
		onSetSceneSnapshots(getSnapshotDictFromLStorage(localStorage, projectId));
	};

	const copySceneAtIndexClickHandler = () => {
		onRemoveSelection(selectedEntityIds);
		onCopyScenesAtIndex_Global({
			projectId,
			sceneIds: [activeSceneId],
			index: currentSceneIndex + 1,
		});
		onSetCurrentSceneIndex(currentSceneIndex + 1);
		onSetEnlargeSceneMenu(true);
	};

	const addSceneAtIndexClickHandler = () => {
		const sceneId = uuid4();
		onAddScenes_Global({
			sceneIds: rootChildren,
			newSceneIds: [sceneId],
			index: currentSceneIndex + 1,
			rootId,
		});
		onRemoveSelection(selectedEntityIds);
		onSetActiveScene({ activeSceneId: sceneId });
		onSetCurrentSceneIndex(currentSceneIndex + 1);
		onSetEnlargeSceneMenu(true);
		setTimeout(() => {
			updateLStorageWithSnapshot(sceneId, projectId);
			onSetSceneSnapshots(getSnapshotDictFromLStorage(localStorage, projectId));
		}, 0);
	};

	const arrowStyle = {
		transform: 'rotate(-90deg)',
		paddingLeft: '3px',
		transition: 'transform 0.5s',
	};

	if (sceneMenuIsLarge) arrowStyle.transform = 'rotate(90deg)';

	const leftArrowClickHandler = () => {
		onRemoveSelection(selectedEntityIds);
		const newActiveSceneId = rootChildren[currentSceneIndex - 1];

		onSetActiveScene({
			activeSceneId: newActiveSceneId,
		});
		onSetCurrentSceneIndex(currentSceneIndex - 1);
		onScrollSceneMenu(true);
	};

	const rightArrowClickHandler = () => {
		const newActiveSceneId = rootChildren[currentSceneIndex + 1];
		onRemoveSelection(selectedEntityIds);
		onSetActiveScene({
			activeSceneId: newActiveSceneId,
		});
		onSetCurrentSceneIndex(currentSceneIndex + 1);
		onScrollSceneMenu(true);
	};

	return (
		<div className={CSS.ButtonRow} ref={listBtnRef}>
			<div className={CSS.ButtonRow_Inner}>
			{showSceneChangeArrow && 
				<Btn
					onClick={leftArrowClickHandler}
					classNames={CSS.PrevSceneBtn}
					disabled={currentSceneIndex === 0}
				>
						<ArrowLeftThin 
							width={'16px'}
							height={'18px'}
							viewBox={[0, 0, 18, 18]}
							className={CSS.SideArrow} 
							title={"Go to previous scene"}
						/>
				</Btn>}
				<Btn 
					onClick={copySceneAtIndexClickHandler}
					classNames={CSS.CopyBtn}
				>
					<img 
					title={"Duplicate scene"}
					src={DuplicateSceneIcon}/>
				</Btn>
				<Divider height="23px" />
				<div className={CSS.SceneName}>
					<div 
						className={`${CSS.NameField} ${isSceneMenuOpen? CSS.ActiveNameField : ''}`}
						onClick={ () => openSlideCarouselClickHandler()}
						title={editorTitlesDict[activeSceneId]}
						onDoubleClick={() => openSlideCarouselClickHandler(true)}
					>
						{editorTitlesDict[activeSceneId]}
					</div>
					<Btn 
						classNames={CSS.turnedArrow}
						style={arrowStyle}
						onClick={() => openSlideCarouselClickHandler()}
					>
						<ArrowRightIcon 
						hexFill={`${isSceneMenuOpen? '#4a90e2' : null}`}
						className={CSS.Arrow} 
						viewBox={[0, 0, 7, 7]}
						title={"Open scene menu"}
						/>
					</Btn>
				</div>
				
				<Divider height="23px" />
				<Btn 
					onClick={addSceneAtIndexClickHandler}
					classNames={CSS.AddBtn}
				>
					<img 
					title={"Add scene"}
					src={AddSceneIcon}
					/>
				</Btn>
				{showSceneChangeArrow && <Btn
					onClick={rightArrowClickHandler}
					classNames={CSS.NextSceneBtn}
					disabled={
						!rootChildren.length ||
						currentSceneIndex === rootChildren.length - 1
					}
				>
					<ArrowLeftThin 
						width={'16px'}
						height={'18px'}
						style={{ transform: 'rotate(180deg)' }}
						viewBox={[0, 0, 18, 18]}
						className={CSS.SideArrow} 
						title={"Go to next scene"}
					/>
				</Btn>}
			</div>
		</div>
	);
};

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	return {
		selectedEntityIds: state.userReducer.selectedEntityIds,
		activeSceneId: state.userReducer.activeSceneId,
		currentSceneIndex: state.userReducer.currentSceneIndex,
		sceneSnapshotDict: state.userReducer.sceneSnapshots,
		sceneMenuIsLarge: state.userReducer.sceneMenuIsLarge,
		projectId: state.userReducer.project.id,
		rootId: getRootComponentId(state) || 'rootId',
		rootChildren: getRootComponent(state)?.children || [],
		editorTitlesDict: getEditorTitlesDict(state),
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onRemoveSelection,
			onSetActiveScene,
			onCopyScenesAtIndex_Global,
			onAddScenes_Global,
			onSetCurrentSceneIndex,
			onSetSceneSnapshots,
			onSetSceneCarouselScrollLeft,
			onSetEnlargeSceneMenu,
			onEditSceneTitle, 
			onScrollSceneMenu, 
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(SceneMenuButtonRow));
