import {
  IMediaData,
  IMediaResponse,
  IImageData,
  IFileType,
  ICaptionResponse,
  IUploadParamsResponse,
  ISaveSettings,
  IFileStatus,
  IProgressStatusResponse,
  IVideoData,
  IFileDict,
  IAudioData,
  IModel3dData,
  ITrainingResponse,
  IRawAnalysisResponse,
  IRefinedAnalysisResponse,
  IUnionMediaData,
  ITrackingImageData,
  IMediaFileOutput,
  ITrainingJobOutput
} from '../specs';
import { addInterfaceToMediaData, sleep } from '../utils';
import { BaseClient } from '../../clients/base';
import { JSONObject, IProgressCallback, IProgressStatus } from '../../specs';
import { BaseOAuth } from '../../oauth/base'

const DEFAULT_THUMBNAILS = [{
  H: 256, W: 256, Quality: 75
},{
  H: 200, W: 385, Quality: 70
}];

export class BaseZmlClient<T extends BaseOAuth> {
  protected _bClient: BaseClient<T>;

  constructor (baseClient: BaseClient<T>) {
    this._bClient = baseClient;
  }

  async getAllFiles() {
    try {
      let mediaResponse = await this._bClient.apiRequest<IMediaResponse>('/zml/');
      return mediaResponse.results.map((mediaData:IMediaData) => addInterfaceToMediaData(mediaData));
    } catch(err) {
      throw err; // TODO: refactor catch
      // throw new Error(err);
    }
  }

  async getFileById(id: string) {
    try {
    const mediaData = await this._bClient.apiRequest<IMediaData>(`/zml/${id}/`);
    return addInterfaceToMediaData(mediaData)
    } catch(err) {
      throw err;
      // throw new Error(err);
    }
  }

  uploadMediaFile(data: ISaveSettings) {
    try {
      return this._bClient.apiRequest<{id: number, status: string, mediaData: IMediaData}>(`/zml/`, <unknown>data as JSONObject);
    } catch(err) {
      throw err;
      // throw new Error(err as any);
    }
  }

  async search(args: {limit?: number, page?: number, q?: string}): Promise<IImageData[]>;
  async search(args: {type: IFileType.Hls, limit?: number, page?: number, q?: string}): Promise<IVideoData[]>;
  async search(args: {type: IFileType.Image, limit?: number, page?: number, q?: string}): Promise<IImageData[]>;
  async search(args: {type: IFileType.Audio, limit?: number, page?: number, q?: string}): Promise<IAudioData[]>;
  async search(args: {type: IFileType.Model3d, limit?: number, page?: number, q?: string}): Promise<IModel3dData[]>;
  async search(args: {type: IFileType, limit?: number, page?: number, q?: string}): Promise<IMediaData[]>;
  async search(args: any): Promise<any[]> {
    const onlyTypeArg = !!args.type && Object.keys(args).length === 1;
    try {
      const mediaData = await this._bClient.apiRequest<IMediaResponse>(`/zml/?status=Ready&${args.type ? `type=${args.type}${!onlyTypeArg ? '&' : ''}` : ''}${args.limit ? `limit=${args.limit || 50}&` : ''}${args.page ? `page=${args.page || 0 }&` : ''}${args.q ? `q=${args.q}` : ''}`); 
      return mediaData.results
    } catch (err) {
      throw err
      // throw new Error(err);
    }
  }

  async getTotalMediaCount(args:  IFileType.Image |  IFileType.Hls | IFileType.Audio | IFileType.Model3d ): Promise<number> {
    try {
      const mediaData = await this._bClient.apiRequest<IMediaResponse>(`/zml/?${args ? `type=${args}` : ''}`); 
      return mediaData.count;
    } catch (err) {
      throw err;
      // throw new Error(err );
    }
  }

  async getStatus(progressUrl: string) {
    const resp = await fetch(progressUrl);
    if (resp.ok) return resp;
    throw new Error(resp.statusText)
  }

  setCaption(id: string, caption: string) {
    return this._bClient.apiRequest<ICaptionResponse>(`/zml/${id}/`, {'caption': caption || false}, "PATCH")
  }

  getUploadParamsForFiletype(fileType: IFileType, contentType?: string) {
    let url = `/upload-params/${fileType}/`;
    if (typeof contentType === 'string'){
      url = `${url}?ct=${contentType}`
    }
    return this._bClient.apiRequest<IUploadParamsResponse>(url)
  }

  async getJobResult(statusURL: string, progressCb: IProgressCallback, jobId: string, retries=0): Promise<null | IProgressStatusResponse> {
    const resp = await fetch(statusURL);
    let progress = Math.min(retries, 80); // fake min progress on each retry

    if (!resp.ok && resp.status !== 403) {
      progressCb(null, {status: IProgressStatus.error, errorCode: resp.status, id: jobId})
      return null;
    } else if (resp.status === 403) {
      if (retries > 200) { // 1 minute
        progressCb(null, {status: IProgressStatus.error, errorCode: 408, id: jobId, desc: 'Timeout waiting for job to start'})
        return null;
      }
      progressCb(progress, {status: IProgressStatus.processing, id: jobId})
      await sleep(300)
      return this.getJobResult(statusURL, progressCb, jobId, retries+1)
    }

    const result = await resp.json() as IProgressStatusResponse;
    if ('Status' in result) { // v1
      result.status = result.Status
      result.progress = progress = Math.min(Math.max(progress, result.PercentComplete), 100)
      result.output = result.Output
      result.error = {
        code: result.Description,
        message: result.DetailedDescription
      }
    } else {
      progress = Math.min(Math.max(progress, result.progress || 0), 100)
    }

    if (result.status === IFileStatus.Error) {
      progressCb(progress, {
        status: IProgressStatus.error,
        id: jobId,
        errorCode: 500,
        desc: result.error?.message
      })
      return null;
    } else if (progress < 100) {
      progressCb(progress, {status: IProgressStatus.processing, id: jobId})
      await sleep(300)
      return this.getJobResult(statusURL, progressCb, jobId, retries)
    }
    return result;
  }

  async getMediaOutput(statusInfo: IUnionMediaData | IRawAnalysisResponse, progressCb: IProgressCallback, id: string): Promise<null | IMediaFileOutput> {
    const statusUrl = statusInfo.statusURL;
    const result = await this.getJobResult(statusUrl, progressCb, id);
    if (!result) return null;

    let mediaData = statusInfo as IUnionMediaData;
    mediaData.output = result.output || {};

    progressCb(100, {id, status: IProgressStatus.completed, mediaData});
    return mediaData.output;
  }

  async uploadFile(fl: any, flType: IFileType, progressCb: IProgressCallback, id?: string) {
    return { folder: '', key: '', filename: '' }
  }

  async uploadFilesOfType<T extends IMediaData | IVideoData | IModel3dData>(fileInput: FileList | File[], fileType: IFileType, progressCb: IProgressCallback): Promise< {
    mediaDataArray: T[];
    uploadDataArray: { folder: string, key: string, filename: string }[];
  }> {

    const fileInArray: IFileDict = {};
    const files: File[] = [];

    for (let i = 0; i < fileInput.length; i++) {
        if (!fileInArray[fileInput[i].name]) {
            files.push(fileInput[i]);
            fileInArray[fileInput[i].name] = true;
        }; 
    }

    let mediaDataArray: T[] = [];
    let uploadDataArray: { folder: string, key: string, filename: string }[] = [];
    for (let i = 0; i < files.length; i++) {
      const fileName = files[i].name;
      const uploadData = await this.uploadFile(files[i], fileType, progressCb, `${i}`)
      const saveSettings: ISaveSettings = {
        folder: uploadData.folder,
        filename: fileName,
        ftype: fileType,
        thumbnails: JSON.stringify(DEFAULT_THUMBNAILS), // TODO: get these as args
        title: files[i].name,
      }

      let mediaData: T;
      try {
        mediaData = await this._bClient.apiRequest<T>('/zml/', <unknown>saveSettings as JSONObject);
      } catch (error) {
        progressCb(error as any, {status: IProgressStatus.error, id: i, errorCode: parseInt(error as any)});
        continue;
      }

      await this.getMediaOutput((mediaData as IUnionMediaData), progressCb, `${i}`)
      uploadDataArray.push(uploadData)
      mediaDataArray.push(mediaData as any)     
    }
    return { mediaDataArray, uploadDataArray }
  }

  async uploadImages (files: FileList | File[], progressCb: IProgressCallback): Promise<IImageData[]> {
    const d = await this.uploadFilesOfType<IImageData>(files, IFileType.Image, progressCb);
    return d.mediaDataArray;
  }

  async upload3dModels (files: FileList | File[], progressCb: IProgressCallback): Promise<IModel3dData[]> {
    const d = await this.uploadFilesOfType<IModel3dData>(files, IFileType.Model3d, progressCb);
    return d.mediaDataArray;
  }

  async uploadVideos (files: FileList | File[], progressCb: IProgressCallback): Promise<IVideoData[]> {
    const d = await this.uploadFilesOfType<IVideoData>(files, IFileType.Hls, progressCb);
    return d.mediaDataArray;
  }

  async uploadAudio (files: FileList | File[], progressCb: IProgressCallback): Promise<IVideoData[]> {
    const d = await this.uploadFilesOfType<any>(files, IFileType.Audio, progressCb);
    return d.mediaDataArray;
  }

  private async _analyseImage (fileId: string, fileData: {
    folder: string;
    filename: string;
  }, progressCb: IProgressCallback): Promise<IRefinedAnalysisResponse | null> {
    let response: IRawAnalysisResponse;
    const { folder, filename } = fileData;
    try {
      response = await this._bClient.apiRequest<IRawAnalysisResponse>(`/zml/${fileId}/analysis/`, {
        folder,
        filename,
        thumbnails: JSON.stringify(DEFAULT_THUMBNAILS)
      });
    } catch (err) {
      progressCb(err as any, {status: IProgressStatus.error, errorCode: parseInt((err as any).message), desc: 'error analysing'});
      return null;
    }
    const output = await this.getMediaOutput(response, progressCb, fileId);
    if (!output) return null;
    return {
      fileId,
      fileName: filename,
      quality: (output as IRawAnalysisResponse)?.Quality,
      codes: (output as IRawAnalysisResponse)?.Codes,
      compositeURL: response.CompositeURL,
      statusURL: response.statusURL,
    };
  }

  async uploadTrackingImage (file: File, progressCb: IProgressCallback) {
    const d = await this.uploadFilesOfType<IImageData>([file], IFileType.TrackingImage, progressCb);
    const uploadData = d.uploadDataArray[0];
    const fileId = d.mediaDataArray[0].id;
    const result = await this._analyseImage(fileId, uploadData, progressCb);
    if (!result) return null;
    return result;
  }

  async analyseTrackingImage(fileId: string, progressCb: IProgressCallback) {
    try {
      const { filename } = await this.getFileById(fileId) as IImageData;
      const { folder } = await this.getUploadParamsForFiletype(IFileType.Image);
      const result = await this._analyseImage(fileId, { folder, filename }, progressCb);
      if (!result) return null;
      return result;
    } catch (err) {
      progressCb(err as any, {status: IProgressStatus.error, errorCode: parseInt((err as any).message)});
      return null;
    }
  }

  async trainTrackingImage(fileId: string, progressCb: IProgressCallback): Promise<ITrainingResponse | null> {
    let trkImgData: ITrackingImageData;

    try {
      trkImgData = await this._bClient.apiRequest<ITrackingImageData>(`/zml/${fileId}/train/`, {
        fileId: fileId
      });
    } catch (err) {
      progressCb(err as any, {status: IProgressStatus.error, errorCode: parseInt((err as any).message), desc: 'error training'});
      return null;
    }

    const output = await this.getMediaOutput(trkImgData, progressCb, fileId) as ITrainingJobOutput;
    console.log('trainTrackingImage:', trkImgData, output)
    return {
      statusURL: trkImgData.statusURL,
      url: trkImgData.url,
      caption: trkImgData.caption,
      zpt: output.zpt
    }
  }
}
