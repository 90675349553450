// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FlexContainer--31pj-{display:flex;flex-direction:column;align-items:center;padding:12px 0}.FlexContainer--31pj- .Description--1dWJ1{color:#5c5c5c}.FlexCol--1appm{display:flex;flex-direction:column}.FlexRow--1P9rF{display:flex;flex-direction:row;width:100%}.FlexRow--1P9rF.OuterRow--31m6r{margin:12px 0}.FlexRow--1P9rF.SpaceBetween--dTIt6{justify-content:space-between}.FlexRow--1P9rF .UploadPhoto--3AEmC{margin-right:16px}.FlexRow--1P9rF .Upload--2w-BY,.FlexRow--1P9rF .Remove--80u8d{margin:4px 0;align-items:center}.FlexRow--1P9rF .Upload--2w-BY img,.FlexRow--1P9rF .Remove--80u8d img{margin-right:4px}.FlexRow--1P9rF .Upload--2w-BY div{color:#4a90e2}.FlexRow--1P9rF .Remove--80u8d div{color:#ce3923;opacity:.3}.Tabs--3T-5S{margin:12px 0;height:32px}.SubmitBtn--3c2PN{width:100%;height:32px;margin-top:24px}.HelpButton--_jhQ8{width:unset;position:relative}.ExtraInfo--2XVdB{display:none}.ExtraInfo--2XVdB.Active--TTJG3{display:block;position:absolute;z-index:2;background-color:#fff;top:90%;right:5%;width:200px;padding:16px;border-radius:8px;text-align:left;box-shadow:0 2px 4px 0 rgba(52,75,96,.1),0 1px 3px 0 rgba(52,75,96,.2)}", ""]);
// Exports
exports.locals = {
	"FlexContainer": "FlexContainer--31pj-",
	"Description": "Description--1dWJ1",
	"FlexCol": "FlexCol--1appm",
	"FlexRow": "FlexRow--1P9rF",
	"OuterRow": "OuterRow--31m6r",
	"SpaceBetween": "SpaceBetween--dTIt6",
	"UploadPhoto": "UploadPhoto--3AEmC",
	"Upload": "Upload--2w-BY",
	"Remove": "Remove--80u8d",
	"Tabs": "Tabs--3T-5S",
	"SubmitBtn": "SubmitBtn--3c2PN",
	"HelpButton": "HelpButton--_jhQ8",
	"ExtraInfo": "ExtraInfo--2XVdB",
	"Active": "Active--TTJG3"
};
module.exports = exports;
