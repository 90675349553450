// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Description--3R_An{color:#5c5c5c;font-size:14px;margin:8px 0;align-self:flex-start;width:100%}.Input--3NuzT{margin-bottom:8px}.NonResizable--2UBy9{resize:none}.Error--3_Xy9{color:red;width:100%}.FlexRow--152UF{display:flex;flex-direction:row;width:100%}.FlexRow--152UF.HelperRow--dk2zV button{align-items:end}.HelpButton--K5mwg{width:unset;position:relative}.ExtraInfo--vdzcs{display:none}.ExtraInfo--vdzcs.Active--3fMJK{display:block;position:absolute;z-index:2;background-color:#fff;top:90%;right:5%;width:200px;padding:16px;border-radius:8px;text-align:left;box-shadow:0 2px 4px 0 rgba(52,75,96,.1),0 1px 3px 0 rgba(52,75,96,.2)}", ""]);
// Exports
exports.locals = {
	"Description": "Description--3R_An",
	"Input": "Input--3NuzT",
	"NonResizable": "NonResizable--2UBy9",
	"Error": "Error--3_Xy9",
	"FlexRow": "FlexRow--152UF",
	"HelperRow": "HelperRow--dk2zV",
	"HelpButton": "HelpButton--K5mwg",
	"ExtraInfo": "ExtraInfo--vdzcs",
	"Active": "Active--3fMJK"
};
module.exports = exports;
