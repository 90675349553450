import React from 'react';
import './UploadDropzone.global.scss';
import {
	IOnProgressCb,
	IOnCompletionCb,
	IOnErrorCb,
	IUploadFileTypes,
} from '../specs';
import {
	returnFileType,
	createUploadIndexToFileDict,
	uploadMedia,
	IUploadIndexToFileDict,
} from '../utils';
import { useDispatch } from 'react-redux';

interface IParentProps {
	children: any;
	style?: any;
	disabled?: boolean;
	className?: string;
	onDrop?: (fileDict: IUploadIndexToFileDict) => any;
	onDragover?: (e: React.DragEvent<HTMLDivElement>) => any;
	onProgress?: IOnProgressCb;
	onCompletion?: IOnCompletionCb;
	onError?: IOnErrorCb;
	allowedFileTypes: IUploadFileTypes[];
}

const UploadDropzone = ({
	style,
	className = '',
	onDrop,
	onDragover,
	disabled = false,
	children,
	onProgress,
	onCompletion,
	onError,
	allowedFileTypes,
}: IParentProps) => {

	const dispatch = useDispatch();

	if (disabled) return children;
	const fileType = returnFileType(allowedFileTypes[0]); // TODO: refactor to allow for mix of file types

	let classes = ['zdz-container', className];

	const onDragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
		onDragover?.(e);
	};

	const onDropHandler = async (e: React.DragEvent<HTMLDivElement>) => {
		if (!e?.dataTransfer?.files) return;
		const {
			uploadIndexToFileDict, 
			imageFilesArray, 
			videoFilesArray, 
			audioFilesArray, 
			model3dFilesArray
		} = createUploadIndexToFileDict(e.dataTransfer.files, allowedFileTypes, onError, fileType, dispatch, onProgress);

		onDrop?.(uploadIndexToFileDict);

		await uploadMedia(
			uploadIndexToFileDict, 
			imageFilesArray,
			videoFilesArray,
			audioFilesArray,
			model3dFilesArray,
			fileType,
			onError,
			onCompletion,
			onProgress,
		)
	};

	return (
		<div
			style={{ ...style }}
			className={classes.join(' ')}
			onDragOver={onDragOverHandler}
			onDrop={onDropHandler}
		>
			{children}
		</div>
	);
};

export default UploadDropzone;
