// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../base-variables.scss");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".NameInput--2qEDc {\n\tmargin: 8px 0 16px 0;\n}\n\n.PreviewDiv--1AHiO {\n\tborder: 1px solid #b2c4d7;\n\tborder-radius: 3px;\n\theight: 170px;\n\twidth: 100%;\n\toverflow: hidden;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tmargin-bottom: 8px;\n\timg {\n\t\timage-rendering: -moz-crisp-edges; /* Firefox */\n\t\timage-rendering: -o-crisp-edges; /* Opera */\n\t\timage-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */\n\t\timage-rendering: crisp-edges;\n\t\t-ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */\n\t\tmax-height: 100%;\n    \tmax-width: 100%;\n\t}\n}\n\n.Title--36gWd {\n\tmargin-bottom: 13px;\n}\n\n", ""]);
// Exports
exports.locals = {
	"NameInput": "NameInput--2qEDc",
	"PreviewDiv": "PreviewDiv--1AHiO",
	"Title": "Title--36gWd"
};
module.exports = exports;
