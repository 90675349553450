import React, { FunctionComponent, useMemo } from 'react';
import uuid4 from 'uuid/v4';
import { useIsDoubleClick } from '../../../../../../../utils';
import { IButtonDisplayState, IFontTypes, ITextAlignment } from '../../../../../../r3f/r3f-components/component-data-structure';

import CSS from './DefaultBtnGrid.scss';


interface IDefaultBtnMetaProps  {
	style: React.CSSProperties,
}


interface IDefaultBtnProperties extends IButtonDisplayState, IDefaultBtnMetaProps {}

interface IParentProps {
	onPointerDown?: (btnInfo: IButtonDisplayState, e: React.PointerEvent<HTMLDivElement>) => any;
	onDoubleClick?: (btnInfo: IButtonDisplayState, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
}

const sharedDefaultProps = {
	text: 'Abc',
	fontFamily: IFontTypes.Roboto,
	fontSize: 0.11,
	scale: [0.37, 0.13, 0],
	position: [0, 0, 0],
	rotation: [0, 0, 0], 
	textAlignment: ITextAlignment.center
}

const btnSharedStyle = {
	width: '100px',  
	height: '36px',
	color: 'white',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	cursor: 'pointer',
	backgroundColor: '#96bfef'
}

const frameStyle = {
	width: '100px',  
	height: '36px',
	border:	'2px solid #96bfef',
	backgroundColor: 'transparent',
	color: '#96bfef'
}

const slightlyRoundedStyle = {
	borderRadius: '6px'
}

const roundedStyle = {
	borderRadius: '36px'
}

const defaultBtnInfo: IDefaultBtnProperties[] = [
	{
		style: btnSharedStyle,
		borderRadius: 0,
		borderWidth: 0,
		color: [150, 191, 239, 1],
		borderRgba: [52, 75, 96, 1],
		fontRgba: [255, 255, 255, 1],
		...sharedDefaultProps
	},
	{
		style: {
			...btnSharedStyle,
			...frameStyle
		},
		borderRadius: 0,
		borderWidth: 0.01,
		borderRgba: [150, 191, 239, 1],
		fontRgba: [150, 191, 239, 1],
		color: [255, 255, 255, 0],
		...sharedDefaultProps
	},
	{
		style: {
			...btnSharedStyle,
			...slightlyRoundedStyle
		},
		borderRadius: 0.35,
		borderWidth: 0,
		borderRgba: [52, 75, 96, 1],
		fontRgba: [255, 255, 255, 1],
		color: [150, 191, 239, 1],
		...sharedDefaultProps
	},
	{
		style: {
			...btnSharedStyle,
			...slightlyRoundedStyle,
			...frameStyle
		},
		borderRadius: 0.35,
		borderWidth: 0.01,
		borderRgba: [150, 191, 239, 1],
		fontRgba: [150, 191, 239, 1],
		color: [255, 255, 255, 0],
		...sharedDefaultProps
	},
	{
		style: {
			...btnSharedStyle,
			...roundedStyle
		},
		borderRadius: 1,
		borderWidth: 0,
		borderRgba: [52, 75, 96, 1],
		fontRgba: [255, 255, 255, 1],
		color: [150, 191, 239, 1],
		...sharedDefaultProps
	},
	{
		style: {
			...btnSharedStyle,
			...roundedStyle,
			...frameStyle
		},
		borderRadius: 1,
		borderWidth: 0.01,
		borderRgba: [150, 191, 239, 1],
		fontRgba: [150, 191, 239, 1],
		color: [255, 255, 255, 0],
		...sharedDefaultProps
	},
];

const DefaultBtnGrid: FunctionComponent<IParentProps> = ({
	onPointerDown,
	onDoubleClick
}) => {
	const isDoubleClick = useIsDoubleClick()
	const btnInfo = useMemo(() => defaultBtnInfo.map(btnDft => { return {...btnDft, id: uuid4()}}), [])

	const DefaultElements = btnInfo.map(opt => {
		return (
			<div
				id={opt.id}
				key={opt.id}
				style={opt.style}
				onPointerDown={e => {
					if (isDoubleClick()) onDoubleClick?.(opt, e)
					else onPointerDown?.(opt, e);
				}}
			>
				{opt.text}
			</div>
		);
	});

	return <div className={CSS.Grid}>{DefaultElements}</div>;
};

export default DefaultBtnGrid;
