import React from 'react';
import CSS from './Summary.scss';

interface IParentProps {
	svg: string;
	userName: string;
	url: string;
}

const SocialSummary: React.SFC<IParentProps> = ({ svg, userName, url }) => {
	return (
		<div className={CSS.FlexContainer}>
			<div className={CSS.Description}>Linked to:</div>
			<div className={CSS.LinkContainerDiv}>
				<a className={CSS.ProviderLink} href={url} target="_blank">
					<img src={svg} className={CSS.ProviderIcon} />
					{userName}
				</a>
				<div className={CSS.GradientDiv} />
			</div>
		</div>
	);
};

export default SocialSummary;
