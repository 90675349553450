import React from 'react';

interface IPauseAudioInput {
	excludedIds?: string[];
	resetToStart?: boolean;
}

interface ISetupAudioData {
    src: string,
    id: string;
}

export type ISetupAudioInput = ISetupAudioData[];

type IPauseAudio = (a?: IPauseAudioInput) => void;
type ISetupAudio = (a: ISetupAudioInput) => void;
type IPlayAudio = (a: string[], b?: boolean, pauseBkgrAudio?: boolean) => void;

interface IAudioDict {
    [audioId: string]: HTMLAudioElement
}

class SceneAudioManager {
    private _audioDict: IAudioDict = {};
    public bkgrAudio: HTMLAudioElement;

    constructor(bkgrAudio: HTMLAudioElement) {
        this.bkgrAudio = bkgrAudio;
    }

    setupAudio: ISetupAudio = (sceneAudio: ISetupAudioInput) => {
        //console.log('SETUP AUDIO');
        if (this.bkgrAudio.paused) this.bkgrAudio.play();
        for (let i = 0; i < sceneAudio.length; i++) {
            const {src, id: audioId} = sceneAudio[i];
            if (!src || !audioId) continue;
            if (this._audioDict[audioId]) continue;
            this._audioDict[audioId] = new Audio(src);
        }
        //console.log('SCENE AUDIO DICT', this._audioDict)
    }

    playAudio: IPlayAudio = (ids, loop = false, pauseBkgrAudio) => {
        //console.log('PAUSED?? ', this._bkgrAudio.paused);
        if (pauseBkgrAudio) this.bkgrAudio.pause();
        //console.log('PAUSED?? ', this._bkgrAudio.paused);
        let numberAudioPlaying = 0;
        for (let i = 0; i < ids.length; i++) {
            const audioId = ids[i];
            if (loop) this._audioDict[audioId].loop = true;
            this._audioDict[audioId].currentTime = 0;
            this._audioDict[audioId].play();
            numberAudioPlaying++
            this._audioDict[audioId].onended = () => {
                if (numberAudioPlaying === 1) {
                    if (this.bkgrAudio.paused) this.bkgrAudio.play()
                }
                numberAudioPlaying--;
            };
        }
    }

    pauseAudio: IPauseAudio = (args) => {
        //console.log('ARGS...')
        //console.log(args);
        const audioIds = Object.keys(this._audioDict);
        for (let i = 0; i < audioIds.length; i++) {
            const audioId = audioIds[i];
            if (Array.isArray(args?.excludedIds) && args?.excludedIds?.includes(audioId)) continue;
            this._audioDict[audioId].pause();
            if (args?.resetToStart) this._audioDict[audioId].currentTime = 0;
        }
    }
}


const AudioContext = React.createContext<null | SceneAudioManager>(null);
AudioContext.displayName = 'AudioContext'; // useful for react dev tools

export { AudioContext, SceneAudioManager };