// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DragItem--373we{background-color:#f9f9f9;height:96px;position:relative;display:flex;align-items:center;justify-content:center;cursor:grab;overflow:hidden}.HoverCover--jkquH{position:absolute;z-index:1;pointer-events:none;display:flex;align-items:center;justify-content:center;flex-direction:column;width:100%;height:100%;font-weight:400;font-size:14px;color:#fff;background-blend-mode:multiply;background-image:linear-gradient(to bottom, rgba(49, 86, 114, 0.2) 60%, #1f2d39)}.HoverCover--jkquH>.NameSpan--2D606{position:absolute;bottom:8px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;justify-content:flex-end;width:80%;color:#fff}.LightHoverCover--3Rk8D{background-image:linear-gradient(to bottom, rgba(49, 86, 114, 0) 70%, #1f2d39)}.PlayIconContainer--2oi1I{display:flex;align-items:center;justify-content:center;align-self:center;background-color:rgba(22,43,62,.33);border-radius:100%;width:32px;height:32px}.PlayIconContainer--2oi1I>svg{pointer-events:none}", ""]);
// Exports
exports.locals = {
	"DragItem": "DragItem--373we",
	"HoverCover": "HoverCover--jkquH",
	"NameSpan": "NameSpan--2D606",
	"LightHoverCover": "LightHoverCover--3Rk8D",
	"PlayIconContainer": "PlayIconContainer--2oi1I"
};
module.exports = exports;
