// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ViewMenu--2ejhM{height:24px;width:48%;display:flex;align-items:center;justify-content:center}.ViewMenu--2ejhM button{width:24px;height:100%}.ViewMenu--2ejhM span{width:36px;height:17px;font-family:Arial,Helvetica,sans-serif;font-size:14px;text-align:center;color:#30475d}.ViewMenu--2ejhM .BigIcon--11APj{width:20px;height:20px}.ViewMenu--2ejhM .SmallIcon--37ekb{height:7px;width:7px}", ""]);
// Exports
exports.locals = {
	"ViewMenu": "ViewMenu--2ejhM",
	"BigIcon": "BigIcon--11APj",
	"SmallIcon": "SmallIcon--37ekb"
};
module.exports = exports;
