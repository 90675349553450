import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../typings';
import ActionsContent from './Actions/Actions';
import TransitionsContent from '../Shared/Transitions/Transitions';
import TransformsContent from './Transforms/Transforms';
import Content from './Content/Content';
import TextContent from './Text/Text';
import BtnAppearanceContent from './Appearance/Btn/BtnAppearance';
import ImgVidAppearanceContent from './Appearance/ImgVid/ImgVidAppearance';
import Shelf from '../../../Shelf/Shelf';
import { ISpatialComponentUnion, IComponentType,	ITransitionDict,} from '../../../../r3f/r3f-components/component-data-structure';
enum IEntityShelves {
	transforms = 'transforms', 
	previews = 'previews',    // content
	actions = 'actions', 
	appearances = 'appearances', 
	transitions = 'transitions', 
} 

interface IParentProps {
	show: boolean;
	entity: ISpatialComponentUnion;
}

const EntityShelves: React.FunctionComponent<IParentProps> = ({
	entity,
	show,
}) => {
	// Redux
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const id = selectedEntityIds[0];
	const entityDict = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const editorIsLockedDict = useSelector((state: IDesignerState) => {
		return state.editorReducer.editorDoc && state.editorReducer.editorDoc.isLocked
			? state.editorReducer.editorDoc.isLocked
			: {};
	});
	const entityEditingDisabled = useSelector((state: IDesignerState) => state.editorReducer.editorDoc.editingDisabled?.[id]);
	const selectedTransitionsDict = useMemo(() => {
		let transitionDict: ITransitionDict = {};
		for (let i = 0; i < selectedEntityIds.length; i++) {
			const id = selectedEntityIds[i];
			const { type } = entityDict[id];
			if (type === IComponentType.Root || type === IComponentType.Scene) continue;
			transitionDict[id] = (entityDict[id] as ISpatialComponentUnion).transitions;
		}
		return transitionDict;
	},[id, selectedEntityIds]);

	// State
	const [openShelves, setOpenShelves] = useState<IEntityShelves[] | null>([IEntityShelves.transforms, IEntityShelves.previews]);
	const actionShelfIsDisabled = (entity?.type === IComponentType.Video && entity?.loop ? true : false);
	const isModel3d = (entity?.type === IComponentType.Model3d);

	// Enabling this will reset the entity shelves each time you click a new entity - can't include if we are having it remember state
	// useEffect(()=>{
	// 	setOpenShelves([IEntityShelves.transforms, IEntityShelves.previews]);
	// }, [entityId]);

	const renderAppearanceContent = useCallback((type: IComponentType) => {
		switch (type) {
			case IComponentType.Button:
				return <BtnAppearanceContent />
			case IComponentType.Text:
				return <TextContent />
			case IComponentType.Image:
			case IComponentType.Video:
				return <ImgVidAppearanceContent />
			default:
				break;
		}
	}, []);

	if (!show) return null;	

	const openShelf = (shelf: IEntityShelves) => {
		// If only one allowed to be open
		setOpenShelves([shelf]);
		// If multiple allowed to be open
		// setOpenShelves([...openShelves, shelf])
	}

	const closeShelf = (shelftoClose: IEntityShelves) => {
		// Close current shelf
		setOpenShelves(openShelves.reduce((acc, shelf) => {
			return (shelftoClose === shelf 
			? acc
			: acc.concat(shelf))
		}, []));
	}

	return (
		<>
		{selectedEntityIds.length === 1 && (
			<Shelf
				show={!editorIsLockedDict[id]}
				title={'Content'}
				open={openShelves.includes(IEntityShelves.previews)}
				id={IEntityShelves.previews} 
				onOpen={() => openShelf(IEntityShelves.previews)}
				onClose={() => closeShelf(IEntityShelves.previews)}
			>
				<Content entityEditingDisabled={entityEditingDisabled}/>
			</Shelf>
		)}
		{!!selectedEntityIds.length &&
		<>
			<Shelf
				show
				open={openShelves.includes(IEntityShelves.transforms)}
				id={IEntityShelves.transforms}
				title={'Transforms'}
				onOpen={() => openShelf(IEntityShelves.transforms)}
				onClose={() => closeShelf(IEntityShelves.transforms)}
				>
				<TransformsContent />
			</Shelf>
			</>
			}
			{selectedEntityIds.length === 1 && (
				<>
					{!isModel3d &&
						<>
						<Shelf
							show={!editorIsLockedDict[selectedEntityIds[0]]}
							title="Appearance"
							open={openShelves.includes(IEntityShelves.appearances)}
							disabled={entityEditingDisabled}
							id={IEntityShelves.appearances}
							onOpen={() => openShelf(IEntityShelves.appearances)}
							onClose={() => closeShelf(IEntityShelves.appearances)}>
							{renderAppearanceContent(entity.type)}
							</Shelf>						
						<Shelf
							show={!editorIsLockedDict[selectedEntityIds[0]]}
							title="Actions"
							open={openShelves.includes(IEntityShelves.actions)}
							id={IEntityShelves.actions}
							onOpen={() => openShelf(IEntityShelves.actions)}
							onClose={() => closeShelf(IEntityShelves.actions)}
							disabled={actionShelfIsDisabled}>
							<ActionsContent />
						</Shelf>
						</>
					}
					<Shelf
						show={!editorIsLockedDict[selectedEntityIds[0]]}
						title="Transition Effects"
						open={openShelves.includes(IEntityShelves.transitions)}
						id={IEntityShelves.transitions}
						onOpen={() => openShelf(IEntityShelves.transitions)}
						onClose={() => closeShelf(IEntityShelves.transitions)}
					>
						<TransitionsContent
							transitionDict={selectedTransitionsDict}
						/>
					</Shelf>
				</>
			)}
		</>
	);
};

export default React.memo(EntityShelves);