// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PaddingInput--2TYVC{width:70px}.SliderCol--iPJbh{margin-top:8px;display:flex;flex-direction:column}.Title--s5I1L{margin-bottom:8px}.InputRow--uWvpT{display:flex;flex-direction:row;justify-content:space-between}.InputRow--uWvpT .Slider--3zKo0{width:65%}.InputRow--uWvpT .Input--39lUD{width:25%;margin-right:8px}.ColorSelectors--1m1aG{margin-top:8px}.ColorSelectors--1m1aG .ColorRow--dmryH{display:flex;flex-direction:row;justify-content:start;align-items:center;margin:8px 0}.FlexRow--Eco--{display:flex;justify-content:space-between}.FlexColumn--1oLw8{display:flex;flex-direction:column;width:100px;margin-right:8px}.ColorInput--1Csft{width:100px;height:24px;font-size:12px}.BorderWidthInput--2kdhc{margin-top:3px;width:100px}", ""]);
// Exports
exports.locals = {
	"PaddingInput": "PaddingInput--2TYVC",
	"SliderCol": "SliderCol--iPJbh",
	"Title": "Title--s5I1L",
	"InputRow": "InputRow--uWvpT",
	"Slider": "Slider--3zKo0",
	"Input": "Input--39lUD",
	"ColorSelectors": "ColorSelectors--1m1aG",
	"ColorRow": "ColorRow--dmryH",
	"FlexRow": "FlexRow--Eco--",
	"FlexColumn": "FlexColumn--1oLw8",
	"ColorInput": "ColorInput--1Csft",
	"BorderWidthInput": "BorderWidthInput--2kdhc"
};
module.exports = exports;
