// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SliderCol--I5MXq{margin-top:16px;display:flex;flex-direction:column}.SliderCol--I5MXq .SliderTitle--3Pwg7{margin-bottom:8px}.SliderCol--I5MXq .SliderRow--3EzSK{display:flex;flex-direction:row;justify-content:space-between}.SliderCol--I5MXq .SliderRow--3EzSK .TransitionSlider--16d9P{width:70%}.SliderCol--I5MXq .SliderRow--3EzSK .TransitionInput--1wXDD{width:25%}.TransitionTabs--1WZAN{height:32px;font-size:16px;margin-bottom:8px}", ""]);
// Exports
exports.locals = {
	"SliderCol": "SliderCol--I5MXq",
	"SliderTitle": "SliderTitle--3Pwg7",
	"SliderRow": "SliderRow--3EzSK",
	"TransitionSlider": "TransitionSlider--16d9P",
	"TransitionInput": "TransitionInput--1wXDD",
	"TransitionTabs": "TransitionTabs--1WZAN"
};
module.exports = exports;
