import { Environment } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React, {
    Suspense,
    useLayoutEffect,
    useRef,
} from 'react';
import * as THREE from 'three';
import { DirectionalLight } from 'three';

interface IEnvPathProps {
    envMapPath: string;
}
interface ITrkGroupProps {
    trkGroupRef: React.MutableRefObject<any>;
    zapparCameraRef: React.MutableRefObject<any>
}

function Lighting (props: IEnvPathProps): React.ReactElement;
function Lighting (props: ITrkGroupProps): React.ReactElement;
function Lighting (props: unknown): React.ReactElement {
    const {gl} = useThree();
    const lightRef = useRef<DirectionalLight>();

    const {trkGroupRef, envMapPath, zapparCameraRef} = props as IEnvPathProps & ITrkGroupProps;

    useLayoutEffect(() => {
        gl.outputEncoding = THREE.sRGBEncoding;
        if (!zapparCameraRef?.current) return;
        zapparCameraRef.current.backgroundTexture.encoding = THREE.sRGBEncoding;
    }, [gl, zapparCameraRef])

    useLayoutEffect(() => {
        if (!trkGroupRef?.current || !lightRef?.current) return;
        trkGroupRef.current.add(lightRef.current);
        trkGroupRef.current.add(lightRef.current.target)
    }, [trkGroupRef, lightRef])

	return (
        <Suspense fallback={null}>
            {!!envMapPath && <Environment files={envMapPath}/>}
            {!envMapPath && <ambientLight intensity={0.4} /> }
            <directionalLight ref={lightRef} position={[0, 1, 0]} intensity={!envMapPath ? 2 : 0.4}/>
        </Suspense>
	);
}

export default Lighting;
