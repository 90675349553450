import React, { useState, useRef } from 'react';
import './AlphaSlider.global.scss';

interface IParentProps {
	hex: string;
	alpha: number;
	onChange: (alpha: number) => void;
	onSubmit: (alpha: number) => void;
}

const AlphaSlider: React.SFC<IParentProps> = ({
	hex,
	alpha,
	onChange,
	onSubmit,
}) => {
	const [tempAlpha, setTempAlpha] = useState<number | null>(alpha);
	// const sliderEl = useRef(null);
	return (
		<div
			className={'zcp-alpha-slider-container'}
			style={{
				background: `linear-gradient(to right, transparent 0%, #${hex} 100%), url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==')`,
			}}
		>
			<input
				// ref={sliderEl}
				className={'zcp-alpha-slider'}
				type="range"
				max={250}
				value={alpha * 250}
				onChange={e => {
					setTempAlpha(e.target.valueAsNumber / 250);
					onChange(e.target.valueAsNumber / 250);
				}}
				onPointerUp={() => onSubmit(tempAlpha)}
			/>
		</div>
	);
};

export default AlphaSlider;
