// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../Content.scss");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".Title--1W3go{}.PreviewDiv--28u11{}.PreviewDiv--28u11 img{image-rendering:-moz-crisp-edges;image-rendering:-o-crisp-edges;image-rendering:-webkit-optimize-contrast;image-rendering:crisp-edges;-ms-interpolation-mode:nearest-neighbor;max-height:100%;max-width:100%}", ""]);
// Exports
exports.locals = {
	"Title": "Title--1W3go " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["Title"] + "",
	"PreviewDiv": "PreviewDiv--28u11 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["PreviewDiv"] + ""
};
module.exports = exports;
