// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Switcher2d3d--1SCbh{height:24px;width:48%;display:flex;align-items:center;justify-content:center;opacity:100%}.Disabled--fqu6h{opacity:30%}", ""]);
// Exports
exports.locals = {
	"Switcher2d3d": "Switcher2d3d--1SCbh",
	"Disabled": "Disabled--fqu6h"
};
module.exports = exports;
