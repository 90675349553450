// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PreviewBkgr--1qrqj{top:0;left:0;width:100vw;height:100vh;background-color:#162b3e;mix-blend-mode:multiply;display:flex;align-items:center;justify-content:center;position:absolute;z-index:999}.Image--3CSgI{height:80vh;max-height:80vh;max-width:80vw;z-index:1000}", ""]);
// Exports
exports.locals = {
	"PreviewBkgr": "PreviewBkgr--1qrqj",
	"Image": "Image--3CSgI"
};
module.exports = exports;
