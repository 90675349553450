import React, { memo, useRef } from 'react';
import CSS from './UploadBtn.scss';
import {
	returnFileType,
	createUploadIndexToFileDict,
	uploadMedia,
} from '../utils';
import {
	IUploadFileTypes,
	IOnProgressCb,
	IOnCompletionCb,
	IOnErrorCb,
} from '../specs';
import { IUploadIndexToFileDict } from '../utils';
import { useDispatch } from 'react-redux';

interface IParentProps {
	style?: React.CSSProperties;
	singleFileUpload?: boolean;
	className?: string;
	disabled?: boolean;
	children?: any;
	allowedFileTypes: IUploadFileTypes[];
	withImgAnalysis?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
	onProgress?: IOnProgressCb;
	onCompletion?: IOnCompletionCb;
	onError?: IOnErrorCb;
	onSubmit?: (fileDict: IUploadIndexToFileDict) => any;
}

const UploadButton: React.FunctionComponent<IParentProps> = ({
	children,
	onError,
	onClick,
	onSubmit,
	onCompletion,
	singleFileUpload,
	onProgress,
	className,
	style,
	disabled = false,
	allowedFileTypes,
}) => {
	const dispatch = useDispatch();
	const fileType = returnFileType(allowedFileTypes[0]);
	let classes = [CSS.SubBtn, className ? className : ''];
	let uploadInputEl = useRef<HTMLInputElement>(null);

	const onChangeMediaFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e?.target?.value) return;
		const {
			uploadIndexToFileDict, 
			imageFilesArray, 
			videoFilesArray, 
			audioFilesArray, 
			model3dFilesArray
		} = createUploadIndexToFileDict(e.target.files, allowedFileTypes, onError, fileType, dispatch, onProgress);

		onSubmit?.(uploadIndexToFileDict);

		await uploadMedia(
			uploadIndexToFileDict, 
			imageFilesArray,
			videoFilesArray,
			audioFilesArray,
			model3dFilesArray,
			fileType,
			onError,
			onCompletion,
			onProgress,
		);

		if(uploadInputEl.current) uploadInputEl.current.value = null;
	};

	return (
		<>
			<button
				disabled={disabled}
				style={style}
				className={classes.join(' ')}
				onClick={e => {
					if (!uploadInputEl.current) return;
					uploadInputEl.current.click();
					onClick?.(e);
				}}
			>
				{children}
			</button>
			<input
				type="file"
				multiple={!singleFileUpload}
				ref={uploadInputEl}
				style={{ display: 'none' }}
				accept={allowedFileTypes.join(',')}
				onChange={onChangeMediaFile}
			/>
		</>
	);
};


export default memo(UploadButton);
