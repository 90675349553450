import React, { FunctionComponent, useRef, useMemo, useState } from 'react';
import {
	IOnRemoveSelection,
	IOnSetSelection_Ed_Doc,
	IOnAddEntityIdsToSelection,
	onRemoveSelection,
	onAddEntityIdsToSelection,
	onSetSelection_Ed_Doc,
	onSetRotationIsActive,
	IOnSetRotationIsActive,
	onAddToast,
	IOnAddToast,
	IOnRemoveEntities_Global,
	onRemoveEntities_Global,
	IOnSetMultipleComponentProps_Cn_Doc,
	IOnSetMultipleEntityProps_Ed_Doc,
	onSetMultipleComponentProps_Cn_Doc,
	onSetMultipleEntityProps_Ed_Doc,
	IMultipleEntityProps_Cn_Doc,
	IMultipleEntityProps_Ed_Doc,
	IOnSetPosition_Ed_Doc,
	onSetPosition_Ed_Doc,
	onSetPositioningIsActive,
	IOnSetPositioningIsActive
} from '../../../store/actions';
import {
	hotkeyIsPressed,
	IHotkeyTypes,
	getEntityPositionById,
	getEntityScaleById,
	getEntityRotationById,
	getEntityColorById,
	maths,
	getEntityTextById,
	getEntityTextColorById,
	getEntityBorderRgbaById,
	HOTSPOT_SCALE,
} from '../../../utils';
import { bindActionCreators } from 'redux';
import { IDesignerState, IDomIdSelectors } from '../../../../typings';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
	getContentEntityDict,
	getEditorIsLockedDict,
	getEditorTextScaleDict,
	getSelectedContentPositions,
	getSelectedEditorPositions,
} from '../../../store/selectors';
import EntitySuspenseBoundary from '../r3f-components/components/boundaries/EntitySuspense';
import EntityErrorBoundary from '../r3f-components/components/boundaries/EntityError';
import { ThreeEvent, useThree } from '@react-three/fiber';
import {
	ITuple3,
	ITuple4,
	IUserData,
	IVector3,
	IButton,
	IVideo,
	IImage,
	IModel3d,
	IText, 
	ISpatialComponentUnion,
	IComponentType,
	ITuple3Dict,
} from '../../r3f/r3f-components/component-data-structure';
import { ISharedHOCProps } from '../../r3f/r3f-components/types'
import { ToastsData } from '../../../utils/toasts-data'; 
import { isHighestRenderOrder } from '../r3f-components/utils/general';
import FloatingInput from '../FloatingInput/FloatingInput';
import Plane from '../Plane/Plane';
import { useRefState } from '../r3f-components/hooks';
import { Raycaster, ArrowHelper } from 'three';
const { vec3 } = maths;

interface IParentProps {
	id: string;
	enabled?: boolean;
	position?: ITuple3;
	rotation?: ITuple3;
	scale?: ITuple3;
	imageUrl?: string;
	thumbnailUrl?: string;
	model3dUrl?: string;
	mp4Url?: string;
	videoUrl?: string;
	visible?: boolean;
	renderOrder?: number;
	children?: string;
	opacity?: number;
	hasAlpha?: boolean;
}

interface IReduxProps {
	userId: string;
	hotKeys: string[];
	// selectedBy: string;
	scaleHotspotIsEnabled: boolean;
	rotationIsActive: boolean;
	editorScale: ITuple3;
	editorPosition: ITuple3;
	editorRotation: ITuple3;
	contentEntityState: ISpatialComponentUnion;
	selectedEntityIds: string[];
	backgroundHotspotIsEnabled: boolean;
	rotationMarkerPressed: boolean;
	entityIdToUserId: { [id: string]: string };
	editorColor: ITuple4;
	editorBorderRgba: ITuple4;
	users: { [id: string]: IUserData };
	editorText: string;
	is3dMode: boolean;
	editorFontColor: ITuple4;
	editorTextScale: { [id: string]: IVector3 };
	selectedEditorPositions: ITuple3Dict;
	selectedContentPositions: ITuple3Dict;
	editorIsLockedDict: { [id: string]: boolean };
}

interface IDispatchProps {
	onAddToast: IOnAddToast;
	onRemoveSelection: IOnRemoveSelection;
	onSetPositioningIsActive: IOnSetPositioningIsActive;
	onSetPosition_Ed_Doc: IOnSetPosition_Ed_Doc;
	onSetSelection_Ed_Doc: IOnSetSelection_Ed_Doc;
	onSetRotationIsActive: IOnSetRotationIsActive;
	onAddEntityIdsToSelection: IOnAddEntityIdsToSelection;
	onRemoveEntities_Global: IOnRemoveEntities_Global;
	onSetMultipleComponentProps_Cn_Doc: IOnSetMultipleComponentProps_Cn_Doc;
	onSetMultipleEntityProps_Ed_Doc: IOnSetMultipleEntityProps_Ed_Doc;
}

export type IBaseEntityProps = IParentProps & IReduxProps & IDispatchProps;
type IWrappedComponentProps = IButton | IImage | IVideo | IModel3d | ISharedHOCProps | IText;

const addEntityDefaults = (
	WrappedComponent: FunctionComponent<IWrappedComponentProps>
): FunctionComponent<IBaseEntityProps> => {
	const FuncComp: FunctionComponent<IBaseEntityProps> = props => {
		const {
			id,
			hotKeys,
			userId,
			is3dMode,
			selectedEntityIds,
			editorScale,
			editorRotation,
			editorPosition,
			onRemoveSelection,
			editorIsLockedDict,
			onSetPosition_Ed_Doc,
			rotationMarkerPressed,
			selectedEditorPositions,
			contentEntityState: entity,
			selectedContentPositions,
			onSetRotationIsActive,
			onSetMultipleComponentProps_Cn_Doc,
			onSetMultipleEntityProps_Ed_Doc,
			onSetSelection_Ed_Doc,
			scaleHotspotIsEnabled,
			rotationIsActive,
			onAddEntityIdsToSelection,
			backgroundHotspotIsEnabled,
			entityIdToUserId,
			users,
			editorColor,
			visible = true,
			enabled: isEnabled = true,
			position: p,
			rotation: r,
			scale: s,
			editorFontColor,
			editorBorderRgba,
			editorText,
			renderOrder,
			onAddToast,
			onSetPositioningIsActive,
		} = props;
		const initialPosRef = useRef(null);
		const canvasRef = useRef(document.getElementById(IDomIdSelectors.zapparCanvas))
		const oldPositionRef = useRef(null);
		const firstMoveRef = useRef(true);
		const [pointerIsDown, setPointerIsDown, pointerIsDownRef] = useRefState(false);
		const [doubleClicked, setDoubleClicked] = useState(false);
		const {raycaster, scene, camera} = useThree();
		if (!entity) return null;

		const groupSelectHotkey = hotkeyIsPressed(hotKeys, [
			IHotkeyTypes.groupSelect,
		]);


		const isText = entity.type === IComponentType.Text; 
		const isButton  = entity.type === IComponentType.Button; 

		const stopPropagation =
			!scaleHotspotIsEnabled &&
			!rotationIsActive &&
			!backgroundHotspotIsEnabled;

		const enabled =
			isEnabled &&
			stopPropagation; 
			// &&
			// !(
			// 	entityIdToUserId &&
			// 	entityIdToUserId[id] &&
			// 	entityIdToUserId[id] !== userId
			// );

		const onDoubleClick = (e: ThreeEvent<PointerEvent>) => {
			if (!isHighestRenderOrder(e, renderOrder)) return;
			setDoubleClicked(true); 
		}

		const onPointerDown = enabled
			? (e: ThreeEvent<PointerEvent>) => {

				// Useful for debugging...
				// scene.add(new ArrowHelper(raycaster.ray.direction, raycaster.ray.origin, 100, 0xff0000) ); 
				
				const rayintersects = raycaster.intersectObjects(scene.children);
				// return;
				if (!isHighestRenderOrder(e, renderOrder, null, rayintersects) || pointerIsDown) return;
					(e.target as any).setPointerCapture(e.pointerId)
					firstMoveRef.current = true;
					setPointerIsDown(true);

					if (stopPropagation) e.stopPropagation();
					if (rotationMarkerPressed || e.buttons === 2) return;
					const groupSelect = hotkeyIsPressed(hotKeys, [
						IHotkeyTypes.groupSelect,
					]) && !is3dMode;
					const selectedIds = selectedEntityIds || [];
					const filteredIds = selectedIds.filter(selId => selId !== id);
					if (
						(!groupSelect && selectedIds.length === 1) ||
						(!groupSelect && !selectedIds.includes(id))
					) {
						onRemoveSelection(filteredIds);
						onSetSelection_Ed_Doc({ entityIds: filteredIds, userId: null });
					}
					if (groupSelect && selectedIds.includes(id)) {
						onRemoveSelection([id]);
						onSetSelection_Ed_Doc({ entityIds: [id], userId: null });
					} else if (!selectedIds.includes(id)) {
						onAddEntityIdsToSelection([id]);
						onSetSelection_Ed_Doc({ entityIds: [id], userId });
					}
					if (!is3dMode) onSetPositioningIsActive(true)
					onSetRotationIsActive(false);
					initialPosRef.current = [e.point.x, e.point.y, e.point.z]
			  }
			: undefined;

	
		const onPointerMove = (e: ThreeEvent<PointerEvent>) => {
			if (!pointerIsDownRef.current || is3dMode) return
			if (firstMoveRef.current) {
				firstMoveRef.current = false;
				(e.target as any).setPointerCapture(e.pointerId)
			}
			// Return if entity is locked or entity not dragged
			const { x, y, z } = e.point;
			if (e.buttons === 2) return;
			if (
				(selectedEntityIds.length === 1 &&
					editorIsLockedDict[selectedEntityIds[0]]) ||
				(selectedEntityIds.length > 1 &&
					selectedEntityIds.filter(id => editorIsLockedDict[id]).length !== 0)
			)
				return;
			if (doubleClicked) setDoubleClicked(false)
		
			// Return if position has not changed (re-render and/or continuous firing)
			const noMove = oldPositionRef.current && vec3.equal([x, y, z], oldPositionRef.current);
			if (noMove || !selectedEntityIds) return;
			oldPositionRef.current =  [x, y, z];
			initialPosRef.current = initialPosRef.current || [x, y, z];

			// Calculate pointer offset on hotspot
			const pointerDiff = vec3.subtract([x, y, z], initialPosRef.current);
			onSetPosition_Ed_Doc({
				ids: selectedEntityIds,
				positionDict: selectedContentPositions,
				pointerOffset: [pointerDiff[0], pointerDiff[1], 0]
			});
		};
	
		const onPointerUp = (e: ThreeEvent<PointerEvent>) => { 
			(e.target as any).releasePointerCapture(e.pointerId);
			onSetPositioningIsActive(false)
			if (!groupSelectHotkey && e.buttons !== 2 && !is3dMode) {
				let multipleEntityPropsArray_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
				let multipleEntityPropsArray_Ed_Doc: IMultipleEntityProps_Ed_Doc[] = [];
	
				for (let i = 0; i < selectedEntityIds.length; i++) {
					const id = selectedEntityIds[i];
					if (selectedEditorPositions[id]) {
						multipleEntityPropsArray_Cn_Doc.push({
							id,
							position: [
								selectedEditorPositions[id][0],
								selectedEditorPositions[id][1],
								0
							],
						});
					}
					multipleEntityPropsArray_Ed_Doc.push({
						id,
						positions: null,
						userSelection: null,
					});
				}
				onSetMultipleComponentProps_Cn_Doc(multipleEntityPropsArray_Cn_Doc);
				onSetMultipleEntityProps_Ed_Doc(multipleEntityPropsArray_Ed_Doc);
			}

			initialPosRef.current = null;
			setPointerIsDown(false);
		}

		const onPointerOver = (e: ThreeEvent<PointerEvent>) => {
			canvasRef.current.style.cursor = 'text';
		}

		const onPointerOut = (e: ThreeEvent<PointerEvent>) => {
			canvasRef.current.style.cursor = 'default';
		}
		

		const [sx, sy, sz] = s || editorScale || entity.scale;
		const [rx, ry, rz] = r || editorRotation || entity.rotation;
		const rotation = [
			maths.toRadians(rx),
			maths.toRadians(ry),
			maths.toRadians(rz),
		] as any;

		const scale = [sx * 2, sy * 2, sz * 2] as ITuple3
		const position = p || editorPosition || (entity.position as any);

		const data = (entity as any) as IButton;
		const entityHasText = isText || isButton;

		const hasActiveTranforms = useMemo(() => {
			return props.is3dMode && props.selectedEntityIds.includes(id)
		}, [props.is3dMode, props.selectedEntityIds, id]);

		return (
			<>
				<EntityErrorBoundary
					rotation={rotation}
					scale={scale}
					borderWidth={entity.borderWidth}
					borderRadius={entity.borderRadius}
					position={position}
					renderOrder={renderOrder}
					onPointerDown={onPointerDown}
					onPointerUp={onPointerUp}
					onError={() => onAddToast(ToastsData.FailureLoadingError)}
				>
					<>
						<EntitySuspenseBoundary
							rotation={rotation}
							scale={scale}
							borderWidth={entity.borderWidth}
							borderRadius={entity.borderRadius}
							position={position}
							renderOrder={renderOrder}
						>
							<group
								onPointerDown={onPointerDown}
								onPointerUp={onPointerUp}
								name={((entity as IButton).svgUrl ? 'SVG ' : '') + entity.type + ", Render Order: " + renderOrder}
							>
							<WrappedComponent
								id={id}
								scale={scale}
								rotation={rotation}
								position={position}
								text={editorText || data.text}
								imageUrl={props.imageUrl}
								thumbnailUrl={props.thumbnailUrl}
								videoUrl={props.videoUrl}
								color={editorColor || (data.color as ITuple4)}
								fontFamily={data.fontFamily}
								renderOrder={renderOrder}
								fontSize={data.fontSize}
								preventVideoLoad={entity.type === IComponentType.Video ? true : undefined}
								opacity={(entity as IImage).opacity}
								hasAlpha={(entity as IVideo | IImage).hasAlpha}
								hideControls={(entity as IVideo).autoplay}
								borderWidth={entity.borderWidth}
								borderRgba={editorBorderRgba || data.borderRgba || [0, 0, 0, 1]}
								borderRadius={entity.borderRadius}
								textAlignment={(entity as IButton).textAlignment}
								textureUrl={(entity as IButton).textureUrl}
								svgUrl={(entity as IButton).svgUrl}
								model3dUrl={(entity as IModel3d).model3dUrl}
								fontRgba={editorFontColor || (data.fontRgba as ITuple4) || [0, 0, 0, 1]}
								onDoubleClick={entityHasText ? onDoubleClick : undefined}
								onPointerOver={entity.type === IComponentType.Text || entity.type === IComponentType.Button ? onPointerOver : undefined}
								onPointerOut={entity.type === IComponentType.Text || entity.type === IComponentType.Button ? onPointerOut : undefined}
							/>
							</group>
						</EntitySuspenseBoundary>
						{selectedEntityIds.length && entityHasText && doubleClicked && <FloatingInput
							position={p || editorPosition || (entity.position as any)}
							text={editorText || data.text}
							onBlur={() => setDoubleClicked(false)}
							onUnmount={() => setDoubleClicked(false)}
						/>}
					</>
				</EntityErrorBoundary>
				{/* plane to capture position movement */}
			{pointerIsDown && !is3dMode && <Plane
				visible={false}
				enabled
				rotation={[0, 0, 0]}
				position={[0, 0, 0]}
				scale={HOTSPOT_SCALE}
				pointerMoveHandler={onPointerMove}
			/>}
		</>);
	};
	return FuncComp;
};

const mapStateToProps = (
	state: IDesignerState,
	parentProps: IParentProps
): IReduxProps => {
	const { id } = parentProps;
	return {
		userId: state.userReducer.userId,
		is3dMode: state.userReducer.is3dMode,
		hotKeys: state.userReducer.hotKeys,
		scaleHotspotIsEnabled: state.userReducer.scaleHotspotIsEnabled,
		rotationIsActive: state.userReducer.rotationIsActive,
		contentEntityState: getContentEntityDict(state)[
			id
		] as ISpatialComponentUnion,
		editorPosition: getEntityPositionById(state, id),
		editorRotation: getEntityRotationById(state, id),
		editorColor: getEntityColorById(state, id),
		editorBorderRgba: getEntityBorderRgbaById(state, id),
		editorScale: getEntityScaleById(state, id),
		editorText: getEntityTextById(state, id),
		selectedEditorPositions: getSelectedEditorPositions(state),
		editorTextScale: getEditorTextScaleDict(state),
		editorFontColor: getEntityTextColorById(state, id),
		editorIsLockedDict: getEditorIsLockedDict(state),
		selectedContentPositions: getSelectedContentPositions(state),
		selectedEntityIds: state.userReducer.selectedEntityIds,
		rotationMarkerPressed: state.userReducer.markerIndexPressed === 999,
		backgroundHotspotIsEnabled: state.userReducer.backgroundHotspotIsEnabled,
		entityIdToUserId: state.editorReducer.editorDoc.entityIdToUserId,
		users: state.editorReducer.editorDoc.users,
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onAddToast,
			onRemoveSelection,
			onSetPositioningIsActive,
			onRemoveEntities_Global,
			onSetRotationIsActive,
			onSetSelection_Ed_Doc,
			onAddEntityIdsToSelection,
			onSetPosition_Ed_Doc,
			onSetMultipleComponentProps_Cn_Doc,
			onSetMultipleEntityProps_Ed_Doc,
		},
		dispatch
	);
};

const withEntityDefaults = compose(
	connect(mapStateToProps, mapDispatchToProps),
	addEntityDefaults
);

export default withEntityDefaults;
