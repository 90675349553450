import React, { FunctionComponent, memo } from 'react';
import { connect } from 'react-redux';
import { IDesignerState } from '../../../../typings';
import { IImageTrackingState_Ed_Doc, ITrackingTypes, ITrackingUnionState_Ed_Doc, ITuple3 } from '../r3f-components/component-data-structure';
import TrkImage from './TrackingImage/TrackingImage';
import EntitySuspenseBoundary from '../r3f-components/components/boundaries/EntitySuspense'
import Plane from '../Plane/Plane';
import { getEditorTargetAreaAspectRatio } from '../../../store/selectors';
import TrackingRearView from './TrackingRearView/TrackingRearView';

interface IReduxProps {
    tracking: ITrackingUnionState_Ed_Doc;
    targetAreaAspectRatio: number;
}


const D2TrackingDisplay: FunctionComponent<IReduxProps> = ({
  tracking,
  targetAreaAspectRatio
}) => {
    switch (tracking?.type) {
        case ITrackingTypes.image: {
            const trk = tracking as IImageTrackingState_Ed_Doc;
            const trkScale = [
                trk.scale[0] * 4,
                trk.scale[1] * 4,
                0,
            ] as ITuple3;
            return <EntitySuspenseBoundary
                    scale={trkScale}
                    rotation={[0, 0, 0]}
                    position={[0, 0, 0]}
                    borderWidth={0}
                    borderRadius={0}
                    renderOrder={-1}
                >
                    <TrkImage
                        scale={trkScale}
                        trkImgUrl={trk.url}
                        opacity={trk.opacity}
                    />
                </EntitySuspenseBoundary>
        }
        default:
            return (
              <>
                <Plane
                  name="Tracking Display"
                  enabled={false}
                  visible={true}
                  color={[235, 235, 235, 1]}
                  position={[0, 0, -0.011]}
                  rotation={[0, 0, 0]}
                  scale={[targetAreaAspectRatio * 2, 2, 0]}
                  renderOrder={0}
                />
                <TrackingRearView scale={[targetAreaAspectRatio * 2, 2, 0]} />   
              </>
            );
    }
};


const mapStateToProps = (state: IDesignerState): IReduxProps => {
	return {
		tracking: state.editorReducer.editorDoc.tracking,
        targetAreaAspectRatio: getEditorTargetAreaAspectRatio(state),
	};
};

export default connect(mapStateToProps)(memo(D2TrackingDisplay));