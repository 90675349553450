// Set up audio context
const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();

export const drawAudio = (url: string) => {	
	fetch(url)
		.then(response => response.arrayBuffer())
		.then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
		.then(audioBuffer => draw(normalizeData(filterData(audioBuffer))))};

const filterData = (audioBuffer: AudioBuffer) => {
	const rawData = audioBuffer.getChannelData(0); // We only need to work with one channel of data
	const samples = 70; // Number of samples we want to have in our final data set
	const blockSize = Math.floor(rawData.length / samples); // the number of samples in each subdivision
	const filteredData = [];
	for (let i = 0; i < samples; i++) {
		let blockStart = blockSize * i; // the location of the first sample in the block
		let sum = 0;
		for (let j = 0; j < blockSize; j++) {
			sum = sum + Math.abs(rawData[blockStart + j]); // find the sum of all the samples in the block
		}
		filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
	}
	return filteredData;
};

const normalizeData = (filteredData: number[]) => {
	const multiplier = Math.pow(Math.max(...filteredData), -1);
	return filteredData.map(n => n * multiplier);
};

const drawLineSegment = (
	ctx: CanvasRenderingContext2D,
	x: number,
	height: number,
	width: number,
	isEven: any
) => {
	ctx.lineWidth = 1; // how thick the line is
	ctx.strokeStyle = '#000000'; // what color our line is
	ctx.beginPath();
	height = isEven ? height : -height;
	ctx.moveTo(x, 0);
	ctx.lineTo(x, height);
	ctx.arc(x + width / 2, height, width / 2, Math.PI, 0, isEven);
	ctx.lineTo(x + width, 0);
	ctx.stroke();
};

const draw = (normalizedData: number[]) => {
	// set up the canvas
	const canvas = document.getElementById(
		'AudioPlayerCanvas'
	) as HTMLCanvasElement;
	const dpr = window.devicePixelRatio || 1;
	const padding = 20;
	canvas.width = canvas.offsetWidth * dpr;
	canvas.height = (canvas.offsetHeight + padding * 2) * dpr;
	const ctx = canvas.getContext('2d');
	ctx.scale(dpr, dpr);
	ctx.translate(0, canvas.offsetHeight / 2 + padding); // set Y = 0 to be in the middle of the canvas

	// draw the line segments
	const width = canvas.offsetWidth / normalizedData.length;
	for (let i = 0; i < normalizedData.length; i++) {
		const x = width * i;
		let height = normalizedData[i] * canvas.offsetHeight - padding;
		if (height < 0) {
			height = 0;
		} else if (height > canvas.offsetHeight / 2) {
			//   height = height > canvas.offsetHeight / 2; //TODO: check
		}
		drawLineSegment(ctx, x, height, width, (i + 1) % 2);
	}
};
// drawAudio(audio.src);

export const secondsToTimeString = (seconds: number) => {
	const min = Math.floor(seconds / 60);
	var sec = Math.floor(seconds - min * 60);
	return `${min}:${sec < 10 ? '0' : ''}${sec}`;
};
