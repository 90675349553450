import React, { useMemo, FunctionComponent, memo } from 'react';
import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import { ITuple3 } from '../../component-data-structure';
import SvgShape from './SvgShape'
interface IParentProps {
	name: string;
	svgUrl: string;
	scale: ITuple3;
	position?: ITuple3;
	color?: any;
	opacity?: number;
	depthWrite?: boolean;
	renderOrder?: number;
	visible?: boolean;
}
interface IShapeData {
	shape: THREE.Shape;
	color: string;
	fillOpacity: string;
	index: number;
} 
interface IShapesCache {
	[url: string]: {
		shapes: JSX.Element[];
		pos: ITuple3;
	};
}

const shapesCache: IShapesCache = {};

const getViewBox = (svgs: any[]) => {
	let str = svgs[0]?.xml.outerHTML;
	let tP = new DOMParser().parseFromString(str.toString(), 'image/svg+xml');
	let tS = document.importNode(tP.documentElement, true);
	let tV = tS.getAttribute('viewBox');
	return tV?.split?.(' ')?.map(x=>+x) || null;
}

const SvgComponent: FunctionComponent<IParentProps> = ({
	svgUrl,
	scale,
	name,
	opacity,
	position = [0, 0, 0],
	color: c,
	renderOrder = 0,
	depthWrite = false,
	visible = true,
}) => {
  // console.log({svgUrl});
  const urls = useMemo(() => [`${svgUrl}?=webgl`], [svgUrl]) 
  const svgs = useLoader(SVGLoader, urls);
  // console.log({ svgs });

  const viewBox = getViewBox(svgs);
  const viewBoxWidth = viewBox?.[2] || 0;
  const viewBoxHeight = viewBox?.[3] || 0;
  // console.log(viewBox);

  // if (!shapesCache[svgUrl]?.shapes) { //! If we use caching here, it breaks 2D render order changes, specifically there is a 'lag' when you move the render order of SVG buttons
    const shapeArray: IShapeData[] = svgs.map((obj: any) => {
      return obj.paths.reduce((acc: any, path: any, index: any) => {
        return acc.concat(
          path.toShapes(true).map((shape: any) => ({
            shape,
            color: path.color,
            fillOpacity: path.userData.style.fillOpacity,
            index,
          }))
        );
      }, []);
    })[0];
    shapesCache[svgUrl] = {
      pos: position, // calcPosition(shapeArray, position) as ITuple3,
      shapes: shapeArray.map((shapeData: any, i: number) => {
          return (
            <SvgShape
              key={i}
              name={name}
              opacity={opacity || shapeData.fillOpacity}
              color={(c || shapeData.color) as any}
              depthWrite={true}
              shape={shapeData.shape}
              renderOrder={renderOrder}
            />
          );
      }),
    };
  // }  // End cache loop

  if (!shapesCache[svgUrl]?.shapes?.length) return null;

  // console.log({scale});
  // console.log({position});
  // console.log(shapesCache[svgUrl]);

  const s = [
    scale[0] / viewBoxWidth,
    -scale[1] / viewBoxHeight,
    scale[2],
  ];

  const p = [
    position[0] - scale[0] / 2,
    position[1] + scale[1] / 2,
    position[2] + s[2] / 2,
  ];

  // 	console.log({ s });
  //   console.log({ p });

  return (
    <group 
      scale={s as ITuple3} 
      position={p as ITuple3} 
      // renderOrder={renderOrder} 
      name="SVG-Outer Group"
      visible={visible}>
      <group
         name="SVG-Inner Group"
        // renderOrder={renderOrder}
      >
          {shapesCache[svgUrl].shapes}
      </group>
    </group>
  );
};

export default memo(SvgComponent);