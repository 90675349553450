// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OuterContainer--pMxMG{position:absolute;left:60px;top:68px;z-index:2;width:calc(100% - 272px - 60px);height:fit-content;display:flex;align-items:flex-end;justify-content:center;pointer-events:none}.OuterContainer--pMxMG>.ContextMenu--LKriV{width:fit-content;height:36px;border-radius:8px;z-index:998;display:flex;flex-direction:row;align-items:center;justify-content:space-evenly;right:calc((100vw - 272px)/2);box-shadow:0px 3px 6px #00000029;background-color:#fff;pointer-events:auto;padding:0 5px 0 5px}.OuterContainer--pMxMG>.ContextMenu--LKriV>.ContextMenuItem--3DIyN{width:20px;height:20px;display:flex;align-items:center;justify-content:center}.ContextMenuButton--p-6XR{margin:0 5px 0 5px;display:block}.ContextMenuButton--p-6XR svg{display:block;height:20px;width:20px}", ""]);
// Exports
exports.locals = {
	"OuterContainer": "OuterContainer--pMxMG",
	"ContextMenu": "ContextMenu--LKriV",
	"ContextMenuItem": "ContextMenuItem--3DIyN",
	"ContextMenuButton": "ContextMenuButton--p-6XR"
};
module.exports = exports;
