import React from "react";
import { IIconParentProps } from "./index";
import { DEFAULT_ICON_COLOR } from "../../utils";

const scale3d: React.SFC<IIconParentProps> = ({
  hexFill: fill,
  viewBox,
  className,
  onClick = () => {},
  title,
}) => {
  const hexFill = fill || DEFAULT_ICON_COLOR;
  return (
    <svg
      className={className}
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Expanded"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Scale"
          transform="translate(-10.000000, -10.000000)"
          fill={hexFill}
          fillRule="nonzero"
        >
          <path d="M29,10 C29.5522847,10 30,10.4477153 30,11 L30,26 C30,26.5522847 29.5522847,27 29,27 L19,27 L19,29 C19,29.5128358 18.6139598,29.9355072 18.1166211,29.9932723 L18,30 L11,30 C10.4477153,30 10,29.5522847 10,29 L10,22 C10,21.4477153 10.4477153,21 11,21 L14,21 L14,11 C14,10.4871642 14.3860402,10.0644928 14.8833789,10.0067277 L15,10 L29,10 Z M18,22 L11,22 L11,29 L18,29 L18,22 Z M25.561,14.003 L25.5111697,14 C25.5794524,14 25.6444653,14.0143203 25.7034941,14.0401849 C25.8517856,14.1020467 25.9620921,14.2295353 25.9919443,14.3832143 L26,14.4671928 L26,17.0328072 C26,17.2908307 25.7761424,17.5 25.5,17.5 C25.2545401,17.5 25.0503916,17.3347304 25.0080557,17.1167857 L25,17.0328072 L25,15.706 L18.9661943,21.7412249 C18.9785246,21.7873796 18.9876356,21.8348494 18.9932723,21.8833789 L19,22 L19,26 L29,26 L29,11 L15,11 L15,21 L18,21 C18.0894906,21 18.1762356,21.0117552 18.2587751,21.0338057 L24.292,15 L22.9888303,15 C22.7188568,15 22.5,14.7761424 22.5,14.5 C22.5,14.2545401 22.6729239,14.0503916 22.9009625,14.0080557 L22.9888303,14 L25.523578,14.0005528 L25.5417126,14.0017323 L25.5417126,14.0017323 L25.556,14.003 L25.561,14.003 Z"></path>
        </g>
      </g>
    </svg>
  );
};

export default scale3d;
