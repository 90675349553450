import React from 'react';
import { useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../../typings';
import { getContentEntityDict } from '../../../../../../store/selectors';
import { IComponentType, ISpatialComponentUnion } from '../../../../../r3f/r3f-components/component-data-structure';
import Model3dContent from './Model3dContent/Model3dContent';
import ImageContent from './ImageContent/ImageContent';
import VideoContent from './VideoContent/VideoContent';
import TextContent from './TextContent/TextContent';
import ButtonContent from './ButtonContent/ButtonContent';
interface IParentProps {
	entityEditingDisabled: boolean;
}

const EntityContent: React.FunctionComponent<IParentProps> = ({entityEditingDisabled}) => {
	// Redux
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const entityContentDict = useSelector((state: IDesignerState) => getContentEntityDict(state));

	// Derived
	const id = selectedEntityIds[0];
	const entity = entityContentDict[id] as ISpatialComponentUnion;
	if (selectedEntityIds.length === 0) return null;

	const renderContent = () => {
		switch (entity.type) {
			case IComponentType.Button:
				return <ButtonContent entity={entity} entityEditingDisabled={entityEditingDisabled} selectedEntityIds={selectedEntityIds}  />
			case IComponentType.Text:
				return <TextContent entity={entity} selectedEntityIds={selectedEntityIds} />
			case IComponentType.Video:				
				return <VideoContent entity={entity} selectedEntityIds={selectedEntityIds} />
			case IComponentType.Image:
				return <ImageContent entity={entity} />
			case IComponentType.Model3d:
				return <Model3dContent entity={entity} />;
			default:
				break;
		}
	}

	return (
		<>
			{renderContent()}
		</>
	);
};


export default React.memo(EntityContent);