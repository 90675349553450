// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ActionsFlexContainer--2xXzs{display:flex;flex-direction:column;flex:1}.ActionSelect--2Z7cN{height:32px;margin-bottom:24px;outline:none}.Description--3Kr31{color:#5c5c5c;font-size:14px;margin-bottom:8px}.EditBtn--2mSda{width:100%;height:36px}.FlexCol--1F-56{display:flex;flex-direction:column}.FlexRow--3pTRF{display:flex;align-items:center}.Title--3U5io{margin-right:8px}", ""]);
// Exports
exports.locals = {
	"ActionsFlexContainer": "ActionsFlexContainer--2xXzs",
	"ActionSelect": "ActionSelect--2Z7cN",
	"Description": "Description--3Kr31",
	"EditBtn": "EditBtn--2mSda",
	"FlexCol": "FlexCol--1F-56",
	"FlexRow": "FlexRow--3pTRF",
	"Title": "Title--3U5io"
};
module.exports = exports;
