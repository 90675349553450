import React, { useState } from 'react';
import './HexInput.global.scss';
import { ITuple3 } from '../../../../r3f/r3f-components/component-data-structure';
import { TextInput } from '../../../Inputs';

interface IParentProps {
	hex: string;
	colorconvert: any;
	onSubmit: (rgb: ITuple3) => any;
}

const HexInput: React.FunctionComponent<IParentProps> = ({ hex, onSubmit, colorconvert }) => {
	const [tempHex, setTempHex] = useState<string | null>(null);

	return (
		<div className={'zcp-flex-row'}>
			<span className={'zcp-desc'}>HEX</span>
			<TextInput
				className={'zcp-hex-input'}
				label={'#'}
				value={tempHex || hex}
				maxLength={6}
				onChange={e => {
					setTempHex(e.target.value);
				}}
				onBlur={() => {
					if (!colorconvert.hex.rgb(tempHex) || tempHex.length !== 6) {
						setTempHex(hex);
						return;
					}
					onSubmit(colorconvert.hex.rgb(tempHex));
					setTempHex(null);
				}}
				onKeyDown={e => {
					if (e.key !== 'Enter') return;
					if (!colorconvert.hex.rgb(tempHex) || tempHex.length !== 6) {
						setTempHex(hex);
						return;
					}
					onSubmit(colorconvert.hex.rgb(tempHex) as ITuple3);
					setTempHex(null);
				}}
			/>
		</div>
	);
};

export default HexInput;
