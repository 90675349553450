import React, { FunctionComponent, useState } from "react";
import { connect } from "react-redux";
import {
  IOnAddTracking_Ed_Doc,
  IOnSetTargetDisplayInfo_Ed_Doc,
  onAddTracking_Ed_Doc,
  onSetTargetDisplayInfo_Ed_Doc,
} from "../../../../../../../store/actions";
import UploadTrkImgBtn from "../../../../../upload/UploadTrkImgBtn/UploadTrkImgBtn";
import { bindActionCreators } from "redux";
import {
  IDesignerState,
  ILoadedImageInfo,
} from "../../../../../../../../typings";
import { NumericalInput } from "../../../../../Inputs";
import Slider from "../../../../../Slider/Slider";
import {
  IAreaTypes,
  IImageTrackingState_Ed_Doc,
  ITrackingTypes,
  ITrackingUnionState_Ed_Doc,
  IUnitTypes,
} from "../../../../../../r3f/r3f-components/component-data-structure";

import CSS from "./TargetImageProperties.scss";
import Dropdown from "../../../../Dropdown/SelectDropdown/SelectDropdown";
import {
  getEditorTargetArea,
  getEditorTargetDisplayUnits,
  getEditorTargetHeightInMm,
} from "../../../../../../../store/selectors";
import {
  convMmToUnit,
  convUnitToMm,
  METRIC_UNIT_MENU_OPTIONS,
} from "../../../../../../../utils";
import { IInputPosition } from "../../../../../Inputs/Numerical/Numerical";
import Divider from "../../../../../Divider/Divider";
import PanelSkeleton from "../../../../../../dom/skeletons/PanelSkeleton/PanelSkeleton";
interface IReduxProps {
  targetArea: IAreaTypes;
  tracking: ITrackingUnionState_Ed_Doc;
  loadedImages: ILoadedImageInfo[] | null;
  loadingMedia: boolean;
  trkImgIsUploading: boolean;
  targetDisplayUnits: IUnitTypes;
  targetHeightInMm: number;
}

interface IDispatchProps {
  onAddTracking_Ed_Doc: IOnAddTracking_Ed_Doc;
  onSetTargetDisplayInfo_Ed_Doc: IOnSetTargetDisplayInfo_Ed_Doc;
}

const TargetImageProperties: FunctionComponent<IReduxProps & IDispatchProps> =
  ({
    tracking: trk,
    trkImgIsUploading,
    onAddTracking_Ed_Doc,
    onSetTargetDisplayInfo_Ed_Doc,
    targetDisplayUnits: unit,
    targetHeightInMm,
  }) => {
    const [isHovered, setIsHovered] = useState<boolean>();
    const tracking = trk as IImageTrackingState_Ed_Doc;
    const { scale, aspectRatio } = tracking || {
      scale: [0, 0, 0],
      aspectRatio: 1,
    };
    const unitIsMm = unit === IUnitTypes.mm;
    const unitIsCoords = unit === IUnitTypes.coords;
    const opacityPerc = isNaN(tracking?.opacity) ? 100 : tracking.opacity * 100;

    return (
      <div
        className={`${CSS.PropertiesContainer} ${
          trkImgIsUploading ? CSS.Disabled : ""
        }`}
      >
				{trkImgIsUploading ? (
          <div className={CSS.SkeletonPreviewContainer}>
            <PanelSkeleton
              style={{
                transform: `rotate(28deg)`,
                height: "158%",
                width: "100%",
                top: "unset",
              }}
              animationDuration={"7.5s"}
            />
          </div>
        ) : (
          <div
            className={CSS.PreviewContainer}
            onMouseOver={() => setIsHovered(true)}
            onPointerLeave={() => setIsHovered(false)}>
            <img src={tracking?.url?.split("_")[0]} />
            <div className={`${CSS.HoverDiv} ${isHovered ? CSS.Hovered : ""}`}>
                <UploadTrkImgBtn
                  className={CSS.TrkImgUploadButton}
                  disabled={trkImgIsUploading}>
                  Replace
                </UploadTrkImgBtn>
            </div>
          </div>
				)}

			  <div className={CSS.Row}>
          <div className={CSS.FullRowTitle}>Opacity</div>
          <Slider
            min={0}
            max={100}
            value={opacityPerc}
            className={CSS.Slider}
            onChange={(val) =>
              onAddTracking_Ed_Doc({
                type: ITrackingTypes.image,
                opacity: val / 100,
              })
            }
            disabled={trkImgIsUploading}/>
          <NumericalInput
            min={0}
            max={100}
            decimals={0}
            value={opacityPerc}
						className={CSS.SliderInput}
						labelText="%"
						labelColor="#5C5C5C"
            step={1}
            onChange={(val) =>
              onAddTracking_Ed_Doc({
                type: ITrackingTypes.image,
                opacity: val / 100,
              })
            }
            disabled={trkImgIsUploading}/>
				  </div>
				
				  <div className={CSS.Row}>
				  <span className={CSS.Title}>Dimensions</span>
            <div className={CSS.SizeRow}>
              <div className={CSS.SizeRowInner}>
                <NumericalInput
                  className={CSS.SizeInputField}
                  position={IInputPosition.first}
                  decimals={unitIsMm ? 0 : 2}
                  step={unitIsMm ? 1 : 0.1}
                  min={unitIsMm ? 1 : 0.01}
                  labelText="W"
                  labelColor="#3f556c"
                  disabled={unitIsCoords || trkImgIsUploading}
                  value={
                    unitIsCoords
                      ? scale[0] * 100
                      : convMmToUnit(targetHeightInMm * aspectRatio, unit)
                  }
                  onChange={(val) => {
                    const heightInMm = convUnitToMm(val, unit) / aspectRatio;
                    if (!heightInMm) return; // includes 0
                    onSetTargetDisplayInfo_Ed_Doc({
                      displayUnits: unit,
                      heightInMm,
                    });
                  }}
                />
                <Divider height={"23px"} />
                <NumericalInput
                  className={CSS.SizeInputField}
                  position={IInputPosition.last}
                  decimals={unitIsMm ? 0 : 2}
                  step={unitIsMm ? 1 : 0.1}
                  min={unitIsMm ? 1 : 0.01}
                  labelText="H"
                  labelColor="#3f556c"
                  disabled={unitIsCoords || trkImgIsUploading}
                  value={
                    unitIsCoords
                      ? scale[1] * 100
                      : convMmToUnit(targetHeightInMm, unit)
                  }
                  onChange={(val) => {
                    const heightInMm = convUnitToMm(val, unit);
                    if (!heightInMm) return; // includes 0
                    onSetTargetDisplayInfo_Ed_Doc({
                      displayUnits: unit,
                      heightInMm,
                    });
                  }}
                />
              </div>
            </div>
				  </div>

        <div className={CSS.Row}>
          <span className={CSS.Title}>Units</span>
          <Dropdown
            height={"24px"}
            width={"115px"}
            value={unit || IUnitTypes.cm}
            onChange={(val) =>
              onSetTargetDisplayInfo_Ed_Doc({ displayUnits: val as IUnitTypes })
            }
            disabled={trkImgIsUploading}>
            {METRIC_UNIT_MENU_OPTIONS}
          </Dropdown>
        </div>
				
      </div>
    );
  };

const mapStateToProps = (state: IDesignerState): IReduxProps => {
  return {
    targetArea: getEditorTargetArea(state),
    tracking: state.editorReducer.editorDoc.tracking,
    loadedImages: state.userReducer.loadedImages,
    loadingMedia: state.userReducer.loadingMedia,
    trkImgIsUploading: state.userReducer.trkImgIsUploading,
    targetHeightInMm: getEditorTargetHeightInMm(state),
    targetDisplayUnits: getEditorTargetDisplayUnits(state),
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return bindActionCreators(
    {
      onAddTracking_Ed_Doc,
      onSetTargetDisplayInfo_Ed_Doc,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetImageProperties);
