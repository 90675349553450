import {
	IContentDocActionTypes,
	IOnUndoCnDoc,
	IOnRedoCnDoc,
	IOnSetPositions_Cn_Doc,
	IOnAddButton_Cn_Doc,
	IOnAddVideo_Cn_Doc,
	IOnSetScales_Cn_Doc,
	IOnAddImage_Cn_Doc,
	IOnCopyScenesAtIndex_Cn_Doc,
	IOnSetRotations_Cn_Doc,
	IOnSetRgbaColors_Cn_Doc,
	IOnSetMultipleComponentProps_Cn_Doc,
	IOnSetComponentAction_Cn_Doc,
	IOnSetComponentTransition_Cn_Doc,
	IOnAddTracking_Cn_Doc,
	IOnSetActiveSceneChildren_Cn_Doc,
} from '../action-types';

// UNDO & REDO

export const onUndo_Cn_Doc: IOnUndoCnDoc = () => ({
	type: IContentDocActionTypes.UNDO_CN_DOC,
});

export const onRedo_Cn_Doc: IOnRedoCnDoc = () => ({
	type: IContentDocActionTypes.REDO_CN_DOC,
});

// Set position, scale & rotation

export const onSetPositions_Cn_Doc: IOnSetPositions_Cn_Doc = positionDict => ({
	type: IContentDocActionTypes.SET_ENTITY_POSITIONS_CN_DOC,
	payload: positionDict,
});

export const onSetRotations_Cn_Doc: IOnSetRotations_Cn_Doc = rotationDict => ({
	type: IContentDocActionTypes.SET_ENTITY_ROTATIONS_CN_DOC,
	payload: rotationDict,
});

export const onSetScales_Cn_Doc: IOnSetScales_Cn_Doc = payload => ({
	type: IContentDocActionTypes.SET_SCALE_FACTORS_CN_DOC,
	payload,
});

export const onSetRgbaColors_Cn_Doc: IOnSetRgbaColors_Cn_Doc = payload => ({
	type: IContentDocActionTypes.SET_ENTITY_COLORS_CN_DOC,
	payload,
});

// Add box
export const onAddButton_Cn_Doc: IOnAddButton_Cn_Doc = payload => ({
	type: IContentDocActionTypes.ADD_BUTTON_CN_DOC,
	payload,
});

// Add image
export const onAddImage_Cn_Doc: IOnAddImage_Cn_Doc = payload => ({
	type: IContentDocActionTypes.ADD_IMAGE_CN_DOC,
	payload,
});

// Add video
export const onAddVideo_Cn_Doc: IOnAddVideo_Cn_Doc = payload => ({
	type: IContentDocActionTypes.ADD_VIDEO_CN_DOC,
	payload,
});

export const onSetComponentTransition_Cn_Doc: IOnSetComponentTransition_Cn_Doc = payload => ({
	type: IContentDocActionTypes.SET_COMPONENT_TRANSITION_CN_DOC,
	payload,
});

export const onSetComponentAction_Cn_Doc: IOnSetComponentAction_Cn_Doc = payload => ({
	type: IContentDocActionTypes.SET_COMPONENT_ACTION_CN_DOC,
	payload,
});

export const onSetMultipleComponentProps_Cn_Doc: IOnSetMultipleComponentProps_Cn_Doc = payload => ({
	type: IContentDocActionTypes.SET_MULTIPLE_COMPONENT_PROPS_CN_DOC,
	payload,
});

export const onAddTracking_Cn_Doc: IOnAddTracking_Cn_Doc = payload => ({
	type: IContentDocActionTypes.ADD_TRACKING_CN_DOC,
	payload,
});

export const onSetActiveSceneChildren_Cn_Doc: IOnSetActiveSceneChildren_Cn_Doc = payload => ({
	type: IContentDocActionTypes.SET_ACTIVE_SCENE_CHILDREN,
	payload,
})