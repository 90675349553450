// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FlexContainer--a5hg4{display:flex;flex-direction:column;align-items:center}.FlexContainer--a5hg4 img{height:140px;margin-top:32px}.Description--EbDOs{color:#a5a5a5;font-size:13px;margin-bottom:16px;align-self:flex-start}", ""]);
// Exports
exports.locals = {
	"FlexContainer": "FlexContainer--a5hg4",
	"Description": "Description--EbDOs"
};
module.exports = exports;
