import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { onSetTransformControlsMode } from "../../../../../store/actions";
import { IDesignerState, ITransformControlsMode  } from "../../../../../../typings";
import { Move3dIcon, Rotate3dIcon, Scale3dIcon } from "../../../../../assets/icons";
import { DEFAULT_ICON_COLOR, ICON_INACTIVE_COLOR, ICON_ACTIVE_BLUE_COLOR } from "../../../../../utils";
import CSS from "./Entity3dContextMenu.scss";

const Entity3dContextMenu = () => {
  // Redux
  const dispatch = useDispatch();
  const transformControlsMode = useSelector((state: IDesignerState) => state.userReducer.transformControlsMode);
  const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
  const is3dMode = useSelector((state: IDesignerState) => state.userReducer.is3dMode);

  // Derived
  const somethingSelected = selectedEntityIds.length !== 0;

  // Local state
  const [moveHexfill, setMoveHexFill] = useState(is3dMode && somethingSelected ? ICON_INACTIVE_COLOR : DEFAULT_ICON_COLOR);
  const [scaleHexfill, setScaleHexFill] = useState(is3dMode && somethingSelected ? ICON_INACTIVE_COLOR : DEFAULT_ICON_COLOR);
  const [rotateHexfill, setRotateHexFill] = useState(is3dMode && somethingSelected ? ICON_INACTIVE_COLOR : DEFAULT_ICON_COLOR);

  // Update colors on switch 2D / 3D mode, and if user switches mode
  useEffect(() => {
    setMoveHexFill(is3dMode && somethingSelected ? DEFAULT_ICON_COLOR : ICON_INACTIVE_COLOR);
    setScaleHexFill(is3dMode && somethingSelected ? DEFAULT_ICON_COLOR : ICON_INACTIVE_COLOR);
    setRotateHexFill(is3dMode && somethingSelected ? DEFAULT_ICON_COLOR : ICON_INACTIVE_COLOR);
  }, [is3dMode, transformControlsMode, somethingSelected]);

  const scaleClick = () => {
    if(scaleHexfill !== ICON_INACTIVE_COLOR) dispatch(onSetTransformControlsMode(ITransformControlsMode.scale));
  }

  const moveClick = () => {
    if (moveHexfill !== ICON_INACTIVE_COLOR) dispatch(onSetTransformControlsMode(ITransformControlsMode.translate));
  }

  const rotateClick = () => {
    if (rotateHexfill !== ICON_INACTIVE_COLOR) dispatch(onSetTransformControlsMode(ITransformControlsMode.rotate));
  }

  return (
    <>
      <button
        className={CSS.ContextMenuButton}
        onClick={moveClick}
        onMouseOver={() => { if (is3dMode) setMoveHexFill(ICON_ACTIVE_BLUE_COLOR); }}
        onMouseOut={() => setMoveHexFill(is3dMode ? DEFAULT_ICON_COLOR : ICON_INACTIVE_COLOR)}
        title="Move">
        <Move3dIcon hexFill={(transformControlsMode === ITransformControlsMode.translate && somethingSelected && is3dMode ? ICON_ACTIVE_BLUE_COLOR : moveHexfill)} />
      </button>

      <button
        className={CSS.ContextMenuButton}
        onClick={scaleClick}
        onMouseOver={() => { if (is3dMode) setScaleHexFill(ICON_ACTIVE_BLUE_COLOR); }}
        onMouseOut={() => setScaleHexFill(is3dMode ? DEFAULT_ICON_COLOR : ICON_INACTIVE_COLOR)}
        title="Scale">
        <Scale3dIcon hexFill={(transformControlsMode === ITransformControlsMode.scale && somethingSelected && is3dMode ? ICON_ACTIVE_BLUE_COLOR : scaleHexfill)} />
      </button>

      <button
        className={CSS.ContextMenuButton}
        onClick={rotateClick}
        onMouseOver={() => { if (is3dMode) setRotateHexFill(ICON_ACTIVE_BLUE_COLOR); }}
        onMouseOut={() => setRotateHexFill(is3dMode ? DEFAULT_ICON_COLOR : ICON_INACTIVE_COLOR)}
        title="Rotate">
        <Rotate3dIcon hexFill={(transformControlsMode === ITransformControlsMode.rotate && somethingSelected && is3dMode ? ICON_ACTIVE_BLUE_COLOR : rotateHexfill)} />
      </button>
    </>
  );
};

export default React.memo(Entity3dContextMenu);