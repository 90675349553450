import { IUnionMediaData } from 'zw-api-client/src/zml/specs';
import { IProgressStatus } from 'zw-api-client/src/specs';
import { ILoadedMediaTypes } from '../../../store/actions';

export interface IFileIdDict {
	[id: string]: string;
}

export interface IOnErrorData {
	id: string;
	fileName: string;
	type: IProgressStatus;
	mediaType: ILoadedMediaTypes;
	status?: number;
	desc?: string;
}

export interface IOnProgressData {
	id: string;
	fileName: string;
	type: IProgressStatus;
	perc: number;
}

export interface IOnCompletionData {
	id: string;
	fileName: string;
	data: IUnionMediaData;
}

export interface IOnAnalysisCompletionData {
	id: string;
	fileName: string;
	// data: ITrackingImageData;
}

export type IOnAnalysisProgressData = IOnProgressData;

export enum IUploadFileTypes {
	model_gltf = 'model/gltf+json',
	model_glb = 'model/gltf-binary',
	model_glb_ext = '.glb', // file api does not recognize mime
	model_gltf_ext = '.gltf', // file api does not recognize mime
	video_all = 'video/*',
	video_quicktime = 'video/quicktime',
	video_avi = 'video/x-msvideo',
	video_mp4 = 'video/mp4',
	video_mpeg = 'video/mpeg',
	video_3gp = 'video/3gpp',
	video_flv = 'video/x-flv',
	video_webm = 'video/webm',
	video_ext_fallback = '.webm,.mkv,.mov', // these are used in the "accept" attribute of <input type="file"> because, for example, Safari doesn't recognise webm or mkv mime types
	image_jpeg = 'image/jpeg',
	image_png = 'image/png',
	image_ext_fallback = '.jpeg,.jpg,.png',
	audio_wav = 'audio/wav',
	audio_mpeg = 'audio/mpeg',
	audio_ogg = 'audio/ogg',
	audio_m4a = 'audio/m4a',
	audio_ext_fallback = '.wav,.ogg,.mp3,.mp4'
}

export enum IErrorText {
	formatNotSupported = 'format not supported',
	fileDuplicate = 'file duplicate',
}

export type IOnProgressCb = (p: IOnProgressData) => any;
export type IOnCompletionCb = (c: IOnCompletionData) => any;
export type IOnErrorCb = (e: IOnErrorData) => any;
export type IOnAnalysisProgressCb = (ap: IOnAnalysisProgressData) => any;
export type IOnAnalysisCompletionCb = (ac: IOnAnalysisCompletionData) => any;
