// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SceneFlexContainer--21gqU{display:flex}.Dropdown--3422L{margin:16px 0}.ScenePreviewDiv--2ao7f{border:1px solid #b2c4d7;border-radius:3px;height:170px;width:100%;display:flex;flex-direction:column;align-items:center;justify-content:center;margin-bottom:8px;margin-top:16px;overflow:hidden}.ScenePreviewDiv--2ao7f img{width:100%}.ScenePreviewDiv--2ao7f svg{width:40px;height:40px;margin-bottom:8px}", ""]);
// Exports
exports.locals = {
	"SceneFlexContainer": "SceneFlexContainer--21gqU",
	"Dropdown": "Dropdown--3422L",
	"ScenePreviewDiv": "ScenePreviewDiv--2ao7f"
};
module.exports = exports;
