import { BufferAttribute, Float16BufferAttribute, Float32BufferAttribute, InterleavedBufferAttribute, Line, Material, Mesh, Points, Vector3} from "three";


// * Calculates the morphed attributes of a morphed/skinned THREE.BufferGeometry.
//  * Helpful for Raytracing or Decals.
//  * @param {Mesh | Line | Points} object An instance of Mesh, Line or Points.
//  * @return {Object} An Object with original position/normal attributes and morphed ones.
//  */


export function customComputeMorphedAttributes( object: Mesh | Line | Points) {

		if ( object.geometry.isBufferGeometry !== true ) {

			console.error( 'THREE.BufferGeometryUtils: Geometry is not of type THREE.BufferGeometry.' );
			return null;

		}

		const _vA = new Vector3();

		const _vB = new Vector3();

		const _vC = new Vector3();

		const _tempA = new Vector3();

		const _tempB = new Vector3();

		const _tempC = new Vector3();

		const _morphA = new Vector3();

		const _morphB = new Vector3();

		const _morphC = new Vector3();

		function _calculateMorphedAttributeData( object: any, material: Material, attribute: InterleavedBufferAttribute | BufferAttribute, morphAttribute: (InterleavedBufferAttribute | BufferAttribute)[], morphTargetsRelative: boolean, a: number, b: number, c: number, modifiedAttributeArray: Float32Array | undefined ) {

            if (typeof attribute !== 'undefined') {
                _vA.fromBufferAttribute( attribute, a );

                _vB.fromBufferAttribute( attribute, b );
    
                _vC.fromBufferAttribute( attribute, c );
            }


			const morphInfluences = object.morphTargetInfluences;

			if ( (material as any).morphTargets && morphAttribute && morphInfluences ) {

				_morphA.set( 0, 0, 0 );

				_morphB.set( 0, 0, 0 );

				_morphC.set( 0, 0, 0 );

				for ( let i = 0, il = morphAttribute.length; i < il; i ++ ) {

					const influence = morphInfluences[ i ];
					const morph = morphAttribute[ i ];
					if ( influence === 0 || typeof morph === 'undefined') continue;

					_tempA.fromBufferAttribute( morph, a );

					_tempB.fromBufferAttribute( morph, b );

					_tempC.fromBufferAttribute( morph, c );

					if ( morphTargetsRelative ) {

						_morphA.addScaledVector( _tempA, influence );

						_morphB.addScaledVector( _tempB, influence );

						_morphC.addScaledVector( _tempC, influence );

					} else {

						_morphA.addScaledVector( _tempA.sub( _vA ), influence );

						_morphB.addScaledVector( _tempB.sub( _vB ), influence );

						_morphC.addScaledVector( _tempC.sub( _vC ), influence );

					}

				}

				_vA.add( _morphA );

				_vB.add( _morphB );

				_vC.add( _morphC );

			}

			if ( object.isSkinnedMesh ) {

				object.boneTransform( a, _vA );
				object.boneTransform( b, _vB );
				object.boneTransform( c, _vC );

			}

            if (typeof _vA !== 'undefined' && typeof modifiedAttributeArray !== 'undefined') {
                console.log()
                modifiedAttributeArray[ a * 3 + 0 ] = _vA.x;
                modifiedAttributeArray[ a * 3 + 1 ] = _vA.y;
                modifiedAttributeArray[ a * 3 + 2 ] = _vA.z;
            }
            if (typeof _vB !== 'undefined' && typeof modifiedAttributeArray !== 'undefined') {
                modifiedAttributeArray[ b * 3 + 0 ] = _vB.x;
                modifiedAttributeArray[ b * 3 + 1 ] = _vB.y;
                modifiedAttributeArray[ b * 3 + 2 ] = _vB.z;
            }
            if (typeof _vC !== 'undefined' && typeof modifiedAttributeArray !== 'undefined') {
                modifiedAttributeArray[ c * 3 + 0 ] = _vC.x;
                modifiedAttributeArray[ c * 3 + 1 ] = _vC.y;
                modifiedAttributeArray[ c * 3 + 2 ] = _vC.z;
            }
		}

		function _fixNormalizedWeightsIfNeeded( morphedPosition: any, skinWeightAttribute: any ) {
			if( !skinWeightAttribute?.normalized ) return;
			if( skinWeightAttribute?.isFloat16BufferAttribute ) return;
		
			const array = skinWeightAttribute.array;
			let factor = 1;
			if ( array instanceof Int8Array )        factor = 1.0 / 0x7F;
			else if ( array instanceof Uint8Array )  factor = 1.0 / 0xFF;
			else if ( array instanceof Int16Array )  factor = 1.0 / 0x7FFF;
			else if ( array instanceof Uint16Array ) factor = 1.0 / 0xFFFF;
			else return;
		
			const morphedArray = morphedPosition.array;
			for(let i = 0; i < morphedArray.length; ++i) {
				morphedArray[i] *= factor;
			}
		}

		const geometry = object.geometry;
		const material = object.material;
		let a, b, c;
		const index = geometry.index;
		const positionAttribute = geometry.attributes.position;
		const skinWeightAttribute = geometry.attributes.skinWeight
		const morphPosition = geometry.morphAttributes.position;
		const morphTargetsRelative = geometry.morphTargetsRelative;
		const normalAttribute = geometry.attributes.normal;
		const morphNormal = geometry.morphAttributes.position;
		const groups = geometry.groups;
		const drawRange = geometry.drawRange;
		let i, j, il, jl;
		let group, groupMaterial;
		let start, end;

		const modifiedPosition = positionAttribute ? new Float32Array( positionAttribute.count * positionAttribute.itemSize ) : undefined;
		const modifiedNormal = normalAttribute ? new Float32Array( normalAttribute.count * normalAttribute.itemSize ) : undefined;

		if ( index !== null ) {

			// indexed buffer geometry
			if ( Array.isArray( material ) ) {

				for ( i = 0, il = groups.length; i < il; i ++ ) {

					group = groups[ i ];
					groupMaterial = material[ group.materialIndex as number ];
					start = Math.max( group.start, drawRange.start );
					end = Math.min( group.start + group.count, drawRange.start + drawRange.count );

					for ( j = start, jl = end; j < jl; j += 3 ) {

						a = index.getX( j );
						b = index.getX( j + 1 );
						c = index.getX( j + 2 );

						_calculateMorphedAttributeData( object, groupMaterial, positionAttribute, morphPosition, morphTargetsRelative, a, b, c, modifiedPosition );

						_calculateMorphedAttributeData( object, groupMaterial, normalAttribute, morphNormal, morphTargetsRelative, a, b, c, modifiedNormal );

					}

				}

			} else {

				start = Math.max( 0, drawRange.start );
				end = Math.min( index.count, drawRange.start + drawRange.count );

				for ( i = start, il = end; i < il; i += 3 ) {

					a = index.getX( i );
					b = index.getX( i + 1 );
					c = index.getX( i + 2 );

					_calculateMorphedAttributeData( object, material, positionAttribute, morphPosition, morphTargetsRelative, a, b, c, modifiedPosition );

					_calculateMorphedAttributeData( object, material, normalAttribute, morphNormal, morphTargetsRelative, a, b, c, modifiedNormal );

				}

			}

		} else if ( positionAttribute !== undefined ) {

			// non-indexed buffer geometry
			if ( Array.isArray( material ) ) {

				for ( i = 0, il = groups.length; i < il; i ++ ) {

					group = groups[ i ];
					groupMaterial = material[ group.materialIndex as number ];
					start = Math.max( group.start, drawRange.start );
					end = Math.min( group.start + group.count, drawRange.start + drawRange.count );

					for ( j = start, jl = end; j < jl; j += 3 ) {

						a = j;
						b = j + 1;
						c = j + 2;

						_calculateMorphedAttributeData( object, groupMaterial, positionAttribute, morphPosition, morphTargetsRelative, a, b, c, modifiedPosition );

						_calculateMorphedAttributeData( object, groupMaterial, normalAttribute, morphNormal, morphTargetsRelative, a, b, c, modifiedNormal );

					}

				}

			} else {

				start = Math.max( 0, drawRange.start );
				end = Math.min( positionAttribute.count, drawRange.start + drawRange.count );

				for ( i = start, il = end; i < il; i += 3 ) {

					a = i;
					b = i + 1;
					c = i + 2;

					_calculateMorphedAttributeData( object, material, positionAttribute, morphPosition, morphTargetsRelative, a, b, c, modifiedPosition );

					_calculateMorphedAttributeData( object, material, normalAttribute, morphNormal, morphTargetsRelative, a, b, c, modifiedNormal );

				}

			}

		}

		const morphedPositionAttribute = modifiedPosition ? new Float32BufferAttribute( modifiedPosition, 3 ) : undefined;
		const morphedNormalAttribute = modifiedNormal ? new Float32BufferAttribute( modifiedNormal, 3 ) : undefined;

		_fixNormalizedWeightsIfNeeded(morphedPositionAttribute, skinWeightAttribute)

		return {
			positionAttribute: positionAttribute,
			normalAttribute: normalAttribute,
			morphedPositionAttribute: morphedPositionAttribute,
			morphedNormalAttribute: morphedNormalAttribute
		};

	}