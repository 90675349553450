import React, { useRef } from 'react';
import { connect } from 'react-redux';
import uuid4 from 'uuid/v4';
import { IProgressStatus } from 'zw-api-client/src/specs';
import { zwClient } from '../../../../syncdoc';
import { store } from '../../../../index';
import { uploadResponseHandler, checkAndFilterFilesByType } from '../utils';
import {
	IUploadFileTypes,
	IOnProgressCb,
	IOnCompletionCb,
	IOnErrorCb,
} from '../specs';
import {
	ILoadedMediaTypes,
	onSetTrackingImageData,
	IOnSetTrackingImageData,
	onSetCurrentUploads,
	IOnSetCurrentUploads,
	onAddTracking_Ed_Doc,
	IOnAddTracking_Ed_Doc,
	onAddTracking_Cn_Doc,
	IOnAddTracking_Cn_Doc,
	setTrkImgIsUploading,
	ISetTrkImgIsUploading,
	onRemoveTrackingImageData,
	IOnRemoveTrackingImageData,
	onSetTargetDisplayInfo_Ed_Doc,
	IOnSetTargetDisplayInfo_Ed_Doc,
	onAddToast,
	IOnAddToast
} from '../../../../store/actions';
import { IDesignerState, IUploadInfoDict } from '../../../../../typings';
import { bindActionCreators } from 'redux';
import { ITrackingTypes, IUnitTypes } from '../../../r3f/r3f-components/component-data-structure';

import CSS from './UploadTrkImgBtn.scss';
import { IMediaFileOutput } from 'zw-api-client/src/zml';
import { ToastsData } from '../../../../utils/toasts-data';

interface IParentProps {
	style?: React.CSSProperties;
	className?: string;
	disabled?: boolean;
	children?: any;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
	onProgress?: IOnProgressCb;
	onCompletion?: IOnCompletionCb;
	onTrainingProgress?: IOnProgressCb;
	onTrainingCompletion?: IOnCompletionCb;
	onError?: IOnErrorCb;
	onSubmit?: (file: File, id: string) => any;
}

interface IReduxProps {
	trkImgIsUploading: boolean;
	currentUploads: IUploadInfoDict;
}

interface IDispatchProps {
	onAddToast: IOnAddToast;
	onSetTrackingImageData: IOnSetTrackingImageData;
	onSetCurrentUploads: IOnSetCurrentUploads;
	onAddTracking_Ed_Doc: IOnAddTracking_Ed_Doc;
	onAddTracking_Cn_Doc: IOnAddTracking_Cn_Doc;
	setTrkImgIsUploading: ISetTrkImgIsUploading;
	onRemoveTrackingImageData: IOnRemoveTrackingImageData;
	onSetTargetDisplayInfo_Ed_Doc: IOnSetTargetDisplayInfo_Ed_Doc;
}

const UploadTrkImgButton: React.SFC<IParentProps &
	IReduxProps &
	IDispatchProps> = ({
	children,
	onError: onErr,
	onClick,
	onSubmit,
	onCompletion: onCompl,
	onTrainingProgress: onTrainProgr,
	onTrainingCompletion: onTrainCompl,
	onProgress: onProgr,
	className,
	style,
	onAddToast,
	currentUploads,
	disabled = false,
	trkImgIsUploading,
	setTrkImgIsUploading,
	onSetTrackingImageData,
	onRemoveTrackingImageData,
	onSetCurrentUploads,
	onAddTracking_Ed_Doc,
	onAddTracking_Cn_Doc,
	onSetTargetDisplayInfo_Ed_Doc
}) => {
	let classes = [CSS.SubBtn, className ? className : ''];
	let uploadInputEl = useRef<HTMLInputElement>(null);
	const allowedFileTypes = [
		IUploadFileTypes.image_jpeg,
		IUploadFileTypes.image_png,
		IUploadFileTypes.image_ext_fallback
	];

	const onChangeMediaFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value === null) return;
		const files = e.target.files;
		if (files[0].size > 1000000 * 10) { // larger than 10 MB
			onAddToast(ToastsData.MaxImgSizeExceededError(10));
			return;
		}
		setTrkImgIsUploading(true);
		onRemoveTrackingImageData();
		const id = uuid4();
		// checkAndFilterFilesByType filters out filename duplicates
		const onError: IOnErrorCb = e => {
			onSetCurrentUploads(e.id, { errorText: e.desc || 'error uploading' });
			setTrkImgIsUploading(false);
			if (onErr) onErr(e);
		};

		const file = checkAndFilterFilesByType({
			files,
			allowedFileTypes,
			onError,
			fileType: ILoadedMediaTypes.trackingImage,
		})[0];
		if (!file) return;


		if (onSubmit) onSubmit(file, id);

		const uploadDictKeys = currentUploads ? Object.keys(currentUploads) : [];

		uploadDictKeys.forEach(key => {
			const uploadData = currentUploads[key];
			if (uploadData.mediaType !== ILoadedMediaTypes.trackingImage) return;
			onSetCurrentUploads(key, null)
		})

		onSetCurrentUploads(id, {
			fileName: file.name,
			type: IProgressStatus.uploading,
			mediaType: ILoadedMediaTypes.trackingImage,
			perc: 0,
		});

		const onProgress: IOnProgressCb = p => {
			const fileIsSaved = !!store.getState().userReducer.trackImgData
				?.aspectRatio;
			onSetCurrentUploads(p.id, {
				fileName: p.fileName,
				type: fileIsSaved
					? IProgressStatus.analysing
					: IProgressStatus.processing,
				perc: p.perc,
			});
			if (onProgr) onProgr(p);
		};

		const onCompletion: IOnCompletionCb = c => {
			if (!c.data) return;
			const {
				AspectRatio: aspectRatio,
				Quality: quality,
				Codes: codes,
				CompositeURL: compositeUrl,
			} = c.data.output as IMediaFileOutput;
			if (aspectRatio) {
				onSetCurrentUploads(c.id, {
					fileName: c.fileName,
					type: IProgressStatus.processing,
					perc: 100,
				});
				onSetTrackingImageData({ aspectRatio });
			} else {
				onSetCurrentUploads(c.id, {
					fileName: c.fileName,
					type: IProgressStatus.analysing,
					perc: 100,
				});

				onSetTrackingImageData({ quality, codes, compositeUrl });
			}

			if (onCompl) onCompl(c);
		};

		const onTrainingProgress: IOnProgressCb = p => {
			onSetCurrentUploads(p.id, {
				fileName: p.fileName,
				type: IProgressStatus.training,
				perc: p.perc,
			});
			if (onTrainProgr) onTrainProgr(p);
		};

		const onTrainingCompletion: IOnCompletionCb = c => {
			if (!c?.data) return;
			const { id, data } = c;
			onSetCurrentUploads(c.id, {
				fileName: c.fileName,
				type: IProgressStatus.training,
				perc: 100,
			});
			onAddTracking_Ed_Doc({
				type: ITrackingTypes.image,
				id,
				url: `https:${data.url}/img`,
				aspectRatio: store.getState().userReducer.trackImgData?.aspectRatio,
				name: c.fileName,
			});
			onSetTargetDisplayInfo_Ed_Doc({ displayUnits: IUnitTypes.coords });
			onAddTracking_Cn_Doc({
				type: ITrackingTypes.image,
				zmlId: id,
				url: `https:${data.url}/uploaded.zpt`,
			})
			onSetCurrentUploads(id, {
				fileName: data.filename,
				type: IProgressStatus.trainingCompleted,
			});
			if (onTrainCompl) onTrainCompl(c);
			setTrkImgIsUploading(false);
		};

		const resp = await zwClient.zml.uploadTrackingImage(
			file,
			(perc, progressData) => {
				uploadResponseHandler({
					onProgress,
					onCompletion,
					onError,
					progressData,
					perc,
					fileInfo: { file, id },
					fileType: ILoadedMediaTypes.trackingImage,
				});
			}
		);
		if (resp) {
			await zwClient.zml.trainTrackingImage(
				resp.fileId,
				(perc, progressData) => {
					uploadResponseHandler({
						onProgress: onTrainingProgress,
						onCompletion: onTrainingCompletion,
						onError,
						progressData,
						perc,
						fileInfo: { file, id },
						fileType: ILoadedMediaTypes.trackingImage,
					});
				}
			);
		}
		if (uploadInputEl?.current) uploadInputEl.current.value = null;
	};

	return (
    <>
			<button
				disabled={trkImgIsUploading || disabled}
        onClick={(e) => {
          if (!uploadInputEl.current) return;
          uploadInputEl.current.click();
          if (onClick) onClick(e);
				}}
				style={{
					width: "100%",
					height: "100%",
				}}
      >
        <div          
          style={style}
          className={classes.join(" ")}
        >
          {children}
        </div>
      </button>
      <input
        type="file"
        multiple={false}
        ref={uploadInputEl}
        style={{ display: "none" }}
        accept={allowedFileTypes.join(",")}
        onChange={onChangeMediaFile}
      />
    </>
  );
};

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	return {
		trkImgIsUploading: state.userReducer.trkImgIsUploading,
		currentUploads: state.userReducer.currentUploads
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onSetTrackingImageData,
			onSetCurrentUploads,
			onAddToast,
			setTrkImgIsUploading,
			onAddTracking_Ed_Doc,
			onAddTracking_Cn_Doc,
			onRemoveTrackingImageData,
			onSetTargetDisplayInfo_Ed_Doc,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadTrkImgButton);
