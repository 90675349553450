import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ThreeEvent } from '@react-three/fiber';
import { HOTSPOT_SCALE, maths, IShapeTypes } from '../../../utils';
import { IDesignerState } from '../../../../typings';
import {
	onRemoveSelection,
	IOnRemoveSelection,
	onSetMarkerIndexPressed,
	IOnSetMarkerIndexPressed,
	onAddEntityIdsToSelection,
	IOnAddEntityIdsToSelection,
	IOnSetBackgroundHotSpotEnabled,
	onSetBackgroundHotSpotEnabled,
	onSetSelection_Ed_Doc,
	IOnSetSelection_Ed_Doc,
} from '../../../store/actions';
import {
	getContentEntityDict,
	getEditorIsLockedDict,
	getComponentDict,
} from '../../../store/selectors';
import Plane from '../Plane/Plane';
import {
	ISceneComp,
	ITuple3,
	ITuple3Area,
	IComponentUnion,
	ISpatialComponentUnion,
} from '../../r3f/r3f-components/component-data-structure';

interface IReduxProps {
	selectedEntityIds: string[];
	hotKeys: string[];
	rotationMarkerPressed: boolean;
	rotationIsActive: boolean;
	contentEntityDict: { [id: string]: IComponentUnion };
	backgroundHotspotIsEnabled: boolean;
	entityIdToUserId: { [id: string]: string };
	userId: string;
	pureReactInCanvasIsHovered: boolean;
	editorIsLockedDict: { [id: string]: boolean };
	activeScene: ISceneComp;
}
interface IDispatchProps {
	onRemoveSelection: IOnRemoveSelection;
	onSetMarkerIndexPressed: IOnSetMarkerIndexPressed;
	onSetBackgroundHotSpotEnabled: IOnSetBackgroundHotSpotEnabled;
	onAddEntityIdsToSelection: IOnAddEntityIdsToSelection;
	onSetSelection_Ed_Doc: IOnSetSelection_Ed_Doc;
}

interface InternalState {
	initialPosition: ITuple3 | null;
	dragAreaPosition: ITuple3 | null;
	dragAreaScale: ITuple3;
	enableDrag: boolean;
	oldPosition: ITuple3 | null;
	oldAddIdArray: string[];
	oldRemoveIdArray: string[];
}

class BackgroundHotspot extends PureComponent<
	IReduxProps & IDispatchProps,
	InternalState
> {
	state: InternalState = {
		initialPosition: null,
		dragAreaPosition: null,
		dragAreaScale: [0, 0, 0],
		enableDrag: false,
		oldPosition: null,
		oldAddIdArray: [],
		oldRemoveIdArray: [],
	};

	render() {
		const {
			contentEntityDict,
			activeScene,
			onRemoveSelection,
			selectedEntityIds,
			rotationMarkerPressed,
			rotationIsActive,
			backgroundHotspotIsEnabled,
			onSetMarkerIndexPressed,
			onAddEntityIdsToSelection,
			onSetBackgroundHotSpotEnabled,
			entityIdToUserId,
			onSetSelection_Ed_Doc,
			userId,
			pureReactInCanvasIsHovered,
			editorIsLockedDict,
		} = this.props;

		const { initialPosition, oldPosition, dragAreaScale } = this.state;

		const pointerDownHandler = (e: ThreeEvent<PointerEvent>) => {
			if (e.buttons === 2) return;
			if (pureReactInCanvasIsHovered) return;
			(e.target as any).setPointerCapture(e.pointerId)
			const { x, y, z } = e.point;
			if (!rotationMarkerPressed) {
				onRemoveSelection(selectedEntityIds);
				onSetSelection_Ed_Doc({ entityIds: selectedEntityIds, userId: null });
			}
			if (!rotationMarkerPressed) onSetBackgroundHotSpotEnabled(true);
			this.setState({
				// initialPosition: maths.vec3.multiply(e.localPosition, HOTSPOT_SCALE),
				initialPosition: [x, y, z],
				enableDrag: true,
			});
		};

		const pointerMoveHandler = (e: ThreeEvent<PointerEvent>) => {
			if (e.buttons === 2) return;
			const { x, y, z } = e.point;
			if (
				!initialPosition || 
				(
					initialPosition[0] === x && 
					initialPosition[1] === y && 
					initialPosition[2] === z
				)
			) return;
			// Return if position has not changed (re-render and/or continuous firing)
			if (oldPosition && maths.vec3.equal([x, y, z], oldPosition)) return;
			this.setState({ oldPosition: [x, y, z] });

			// Calc drag area (scale, coords & position)
			const newDragAreaScale = [
				(x - initialPosition[0]) / 2,
				(y - initialPosition[1]) / 2,
				0,
			] as ITuple3;

			const dragAreaCoords = [
				initialPosition,
				[x, initialPosition[1], 0],
				[x, y, z],
				[initialPosition[0], y, 0],
			] as ITuple3Area;

			// Check for drag area changes (using 'old' drag component state)
			let xIncreased = false;
			let yIncreased = false;
			if (Math.abs(dragAreaScale[0]) - Math.abs(newDragAreaScale[0]) <= 0)
				xIncreased = true;
			if (Math.abs(dragAreaScale[1]) - Math.abs(newDragAreaScale[1]) <= 0)
				yIncreased = true;

			// Only then update state
			this.setState({
				dragAreaPosition: [
					initialPosition[0] + (x - initialPosition[0]) / 2,
					initialPosition[1] + (y - initialPosition[1]) / 2,
					-0.001,
				],
				dragAreaScale: newDragAreaScale,
			});

			// If drag x & y increase filter out selected entitis (no need ot check if selected)
			let entityIdsToCheck = activeScene.children;
			if (xIncreased && yIncreased) {
				entityIdsToCheck = entityIdsToCheck.filter(
					id => !selectedEntityIds.includes(id) || !editorIsLockedDict[id]
				);
				// if drag x & y decrease only check selected entities (no need to check non-selected ones)
			} else if (!xIncreased && !yIncreased) {
				entityIdsToCheck = selectedEntityIds;
			}

			let addIdArray: string[] = [];
			let removeIdArray: string[] = [];

			for (let i = 0; i < entityIdsToCheck.length; i++) {
				const id = entityIdsToCheck[i];
				const { position, rotation, scale } = contentEntityDict[
					id
				] as ISpatialComponentUnion;
				const isLocked = editorIsLockedDict[id];
				if (isLocked) continue;
				let entityHotspotCoords = maths.localToWorldPosition2d(
					rotation[2],
					[position[0], position[1]],
					[
						[-scale[0], scale[1]],
						[scale[0], scale[1]],
						[scale[0], -scale[1]],
						[-scale[0], -scale[1]],
					]
				) as ITuple3Area;

				const entityIsCompletely = maths.isCompletelyInOrOutOfDragArea(
					entityHotspotCoords,
					dragAreaCoords
				);

				if (entityIsCompletely.inside) {
					if (!selectedEntityIds.includes(id)) addIdArray.push(id);
					return;
				}

				if (entityIsCompletely.outside) {
					if (selectedEntityIds.includes(id)) removeIdArray.push(id);
					return;
				}

				if (
					maths.areasAreColliding2d(
						entityHotspotCoords,
						dragAreaCoords,
						rotation[2],
						IShapeTypes.rect
					)
				) {
					if (!selectedEntityIds.includes(id)) addIdArray.push(id);
					continue;
				}
				if (selectedEntityIds.includes(id)) removeIdArray.push(id);
			}

			if (addIdArray.length > 0) {
				onAddEntityIdsToSelection(addIdArray);
				onSetSelection_Ed_Doc({ entityIds: addIdArray, userId });
			}
			if (removeIdArray.length > 0 && selectedEntityIds.length > 0) {
				onRemoveSelection(removeIdArray);
				onSetSelection_Ed_Doc({ entityIds: removeIdArray, userId: null });
			}
		};

		const pointerUpHandler = (e: ThreeEvent<PointerEvent>) => {
			(e.target as any).releasePointerCapture(e.pointerId);
			if (e.buttons === 2) return;
			onSetBackgroundHotSpotEnabled(false);
			onSetMarkerIndexPressed(null);
			this.setState({
				dragAreaPosition: null,
				dragAreaScale: [0, 0, 0],
				initialPosition: null,
				enableDrag: false,
			});
		};

		return (
			<>
				<Plane
					enabled={false}
					name="Background Hotspot 1"
					visible={backgroundHotspotIsEnabled}
					scale={[
						Math.abs(dragAreaScale[0]),
						Math.abs(dragAreaScale[1]),
						Math.abs(dragAreaScale[0]),
					]}
					position={this.state.dragAreaPosition}
					rotation={[0, 0, 0]}
					color={[74, 143, 227, 0.13]}
					renderOrder={0}
				/>
				<Plane
					visible={false}
					name="Background Hotspot 2"
					enabled={
						!rotationIsActive &&
						!rotationMarkerPressed
					}
					position={[0, 0, -0.001]}
					rotation={[0, 0, 0]}
					scale={HOTSPOT_SCALE}
					pointerUpHandler={pointerUpHandler}
					pointerMoveHandler={pointerMoveHandler}
					pointerDownHandler={pointerDownHandler}
				/>
			</>
		);
	}
}

const mapStateToProps = (state: IDesignerState): IReduxProps => {
	const activeSceneId = state.userReducer.activeSceneId;
	return {
		selectedEntityIds: state.userReducer.selectedEntityIds,
		hotKeys: state.userReducer.hotKeys,
		backgroundHotspotIsEnabled: state.userReducer.backgroundHotspotIsEnabled,
		rotationIsActive: state.userReducer.rotationIsActive,
		contentEntityDict: getContentEntityDict(state),
		activeScene: getComponentDict(state)[activeSceneId] as ISceneComp,
		rotationMarkerPressed: state.userReducer.markerIndexPressed === 999,
		entityIdToUserId: state.editorReducer.editorDoc.entityIdToUserId,
		userId: state.userReducer.userId,
		pureReactInCanvasIsHovered: state.userReducer.pureReactInCanvasHovered,
		editorIsLockedDict: getEditorIsLockedDict(state),
	};
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
	return bindActionCreators(
		{
			onRemoveSelection,
			onSetBackgroundHotSpotEnabled,
			onSetMarkerIndexPressed,
			onAddEntityIdsToSelection,
			onSetSelection_Ed_Doc,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundHotspot);
