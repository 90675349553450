// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../Content.scss");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".Title--18JGH{}.ChkbxLabel--wHmD2{margin-left:8px}.FlexRow--39cYs{display:flex;align-items:center}.FlexColumn--1t0I5{display:flex;flex-direction:column;width:50%}video{outline:none}", ""]);
// Exports
exports.locals = {
	"Title": "Title--18JGH " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["Title"] + "",
	"ChkbxLabel": "ChkbxLabel--wHmD2",
	"FlexRow": "FlexRow--39cYs",
	"FlexColumn": "FlexColumn--1t0I5"
};
module.exports = exports;
