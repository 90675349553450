import * as THREE from 'three';
import { useMemo } from "react";

type IUnionTexture = THREE.VideoTexture | THREE.Texture;

export const useSRGBTexture = <T extends IUnionTexture | IUnionTexture[]>(texture: T | undefined): T | undefined => {
    const threeTexture = useMemo(() => {
        if (!texture) return texture; 
        if (Array.isArray(texture)) {
            for (let i = 0; i < texture.length; i++) {
                (texture[i] as IUnionTexture).encoding = THREE.sRGBEncoding;
            }
        } else (texture as IUnionTexture).encoding = THREE.sRGBEncoding;
        return texture;
    }, [texture])
    return threeTexture as T;
}