// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Grid--1NhFW{display:grid;grid-gap:16px;padding:16px 0;grid-template-columns:auto auto auto auto}.Grid--1NhFW div{display:flex;align-items:center;justify-content:center;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"Grid": "Grid--1NhFW"
};
module.exports = exports;
