import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../../../typings';
import { IContactOptions, ITriggerTypes, IActionCategory,} from '../../../../../../r3f/r3f-components/component-data-structure';
import { onSetActionData_Global } from '../../../../../../../store/actions';
import SaveContactSummary from './Summary/Summary';
import ActionBtn from '../../../../../buttons/ActionBtn/ActionBtn';
import Tabs, { ITabData } from '../../../../../Tabs/Tabs';
import FormSectionOne from  './FormSections/FormSectionOne';
import FormSectionTwo from './FormSections/FormSectionTwo';
import { parseEmailAddress } from '../../../../../../../utils';
import { parsePhoneNumber } from '../../../../../../../utils';
import {parseCustomUrl} from '../../../../../../../utils';
import CSS from './SaveContact.scss';

interface IParentProps {
	triggerType: ITriggerTypes;
	setShowActionSummary: (value: React.SetStateAction<boolean>) => void;
	showActionSummary: boolean;
}

enum IFormSectionTypes {
    main = 'main',
	other = 'other',
}

export enum IContactParams {
    name = 'name',
    phone = 'phone',
    company = 'company', 
	email = 'email',
    website = 'website',
    phone2 = 'phone2', 
    phone3 = 'phone3',
    street1 = 'street1', 
    street2= 'street2', 
    city = 'city', 
    county= 'county', 
    zip= 'zip', 
    country = 'country', 
    mainForm = 'mainForm', 
}

export interface IInputDict {
    name: string; 
    phone: string; 
    company?: string; 
	email?: string; 
    website?: string; 
    phone2?: string; 
    phone3?: string; 
    street1?: string; 
    street2?: string; 
    city?: string; 
    county?: string; 
    zip?: string; 
    country?: string; 
}

export interface IContactErrors {
    [key:string]: string;
}

const SaveContact: React.FunctionComponent<IParentProps> = ({
    triggerType,
    showActionSummary, 
    setShowActionSummary,
}) => {
    // Redux
    const dispatch = useDispatch();
    const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
    const id = selectedEntityIds[0];
	const actionsDict = useSelector((state: IDesignerState) => state.editorReducer.editorDoc?.actions?.[id] || {});
	const activeTrigger = useSelector((state: IDesignerState) => state.editorReducer.editorDoc.activeTriggers?.[id] || ITriggerTypes.onLoad);
    const editorContactOptions = actionsDict?.[activeTrigger]?.contactOptions || ({} as IContactOptions);

    // State
    const { name = null,phone = null,company = null,email = null,website = null,phone2 = null,phone3 = null, street1 = null, street2 = null, city = null, county = null, zip = null, country = null 
    } = editorContactOptions;
    const tabData: ITabData = [
		{ id: IFormSectionTypes.main, title: 'Main details' },
		{ id: IFormSectionTypes.other, title: 'Other details' },
	];

	const [formSectionCategory, setFormSectionCategory] = useState<IFormSectionTypes>(IFormSectionTypes.main);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [errorsDict, setErrorsDict] = useState<IContactErrors>({}); 
    const [valuesDict, setValuesDict] = useState<IInputDict>({name: name || '', 
                                                            phone: phone || '', 
                                                            company: company, 
                                                            email: email, 
                                                            website: website, 
                                                            phone2: phone2, 
                                                            phone3: phone3, 
                                                            street1: street1, 
                                                            street2: street2, 
                                                            city: city, 
                                                            county: county, 
                                                            zip: zip, 
                                                            country: country});
    const [isNameEmpty, setIsNameEmpty] = useState<boolean>(false);
    const [isPhoneEmpty, setIsPhoneEmpty] = useState<boolean>(false); 

    useEffect(() => {
        setIsValidated(isValidForm())
    });
    
    const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, inputField: IContactParams ) => {
        setErrorsDict(prevState => { return {...prevState , [inputField]: '' }});      
        const tempObject = {[inputField]: e.currentTarget.value}; 
        setValuesDict(prevState => { return {...prevState, ...tempObject}});
    }

    const isValidForm = () => {
        return valuesDict[IContactParams.name]
                && valuesDict[IContactParams.name].length 
                && valuesDict[IContactParams.phone] 
                && parsePhoneNumber(valuesDict[IContactParams.phone]).isValid; 
    }

    const blurHandler = (
		e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
		inputField: IContactParams
	) => {
        let nameValid = true;
        let phoneValid = true;
        
		switch (inputField) {
            case IContactParams.name:{
                
                setIsNameEmpty(false);
                if (!e.currentTarget.value.length) setErrorsDict(prevState => { return {...prevState , [IContactParams.name]: 'Name missing.' }});
                nameValid = !!e.currentTarget.value.length;
				break;
            }
            case IContactParams.phone:{
                setIsPhoneEmpty(false);
                if (!e.currentTarget.value.length) setErrorsDict(prevState => { return {...prevState , [inputField]: 'Phone number missing.' }});
                else if (!parsePhoneNumber(e.currentTarget.value).isValid) setErrorsDict(prevState => { return {...prevState , [inputField]: 'Invalid phone number' }}); 
                phoneValid = !!e.currentTarget.value.length && !!parsePhoneNumber(e.currentTarget.value).isValid; 
                break;
            } 
            case IContactParams.phone2: 
            case IContactParams.phone3:{
                if(!e.currentTarget.value.length) return; 
                if (!parsePhoneNumber(e.currentTarget.value).isValid) setErrorsDict(prevState => { return {...prevState , [inputField]: 'Invalid phone number' }}); 
				break;
            } 
            case IContactParams.email: {
                if(!e.currentTarget.value.length) return; 
                const { isValid } = parseEmailAddress(e.currentTarget.value);
                if(!isValid) setErrorsDict(prevState => { return {...prevState , [inputField]: 'Invalid email address.' }});
                break; 
            }
            case IContactParams.website: {
                if(!e.currentTarget.value.length) return;      
                if (!parseCustomUrl(e.currentTarget.value).isValid) setErrorsDict(prevState => { return {...prevState , [inputField]: 'Invalid website address.' }});
                break; 
            }
			default:
                break;
            }
        setIsValidated(isValidForm() && nameValid && phoneValid);
    };

    const keyDownHandler = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, 
        secondTab?: boolean, 
	) => {
        if (e.keyCode !== 13) return;
        if (!isValidForm()) {
            if(!valuesDict[IContactParams.name] || !valuesDict[IContactParams.name].length){
                if(secondTab) setErrorsDict(prevState => { return {...prevState , [IContactParams.mainForm]: 'The name is missing in the main tab.' }});
                setIsNameEmpty(true);
            }else if(!valuesDict[IContactParams.phone] || !parsePhoneNumber(valuesDict[IContactParams.phone]).isValid){
                if(secondTab) setErrorsDict(prevState => { return {...prevState , [IContactParams.mainForm]: 'The phone is missing or incorrect in the main tab.' }});
                setIsPhoneEmpty(true);
            }

        }
    };

    const verifyValueDict = () => {
        if(valuesDict[IContactParams.email] && !parseEmailAddress(valuesDict[IContactParams.email]).isValid){
            const tempEmail: IContactParams.email = null;
            const tempObject = {[IContactParams.email]: tempEmail}; 
            setValuesDict(prevState => { return {...prevState, ...tempObject}});
            delete errorsDict[IContactParams.email];
        }
        if(valuesDict[IContactParams.website] && !parseCustomUrl(valuesDict[IContactParams.website]).isValid){
            const tempWebsite: IContactParams.website = null; 
            const tempObject = {[IContactParams.website]: tempWebsite}; 
            setValuesDict(prevState => { return {...prevState, ...tempObject}});
            delete errorsDict[IContactParams.website];
        }
        if(valuesDict[IContactParams.phone2] && !parsePhoneNumber(valuesDict[IContactParams.phone2]).isValid){
            const tempPhone2: IContactParams.phone2 = null; 
            const tempObject = {[IContactParams.phone2]: tempPhone2}; 
            setValuesDict(prevState => { return {...prevState, ...tempObject}});
            delete errorsDict[IContactParams.phone2];
        }
        if(valuesDict[IContactParams.phone3] && !parsePhoneNumber(valuesDict[IContactParams.phone3]).isValid){
            const tempPhone3: IContactParams.phone3 = null; 
            const tempObject = {[IContactParams.phone3]: tempPhone3}; 
            setValuesDict(prevState => { return {...prevState, ...tempObject}});
            delete errorsDict[IContactParams.phone3];
        }
    }
    
    const goToSaveContactSummary = () => {  
        verifyValueDict(); 
		dispatch(onSetActionData_Global({
			ids: selectedEntityIds,
			triggerType: triggerType,
			selectedActionCategory: IActionCategory.saveContact,
			contactOptions: valuesDict,
		}));
		setShowActionSummary(true);
	};
    
    return !showActionSummary ? (  
       
       <div className={CSS.FlexContainer}>
            <div className={`${CSS.FlexRow} ${CSS.OuterRow} ${CSS.SpaceBetween} `}>
                <div>Save a contact on user’s device.</div>
            </div>

            <Tabs
                className={CSS.Tabs}
                show={true}
                activeTabId={formSectionCategory}
                tabData={tabData}
                onClick={(formSectionCategory) =>{
                    setFormSectionCategory(formSectionCategory as IFormSectionTypes);
                }}
            />

			{formSectionCategory === IFormSectionTypes.main && <FormSectionOne
                isNameEmpty={isNameEmpty}
                isPhoneEmpty={isPhoneEmpty}
                currentValues={valuesDict}
                inputErrors={errorsDict}
                onChange={changeHandler}
                blurHandler={blurHandler}
                keydownHandler={keyDownHandler}
            />}
            
            {formSectionCategory === IFormSectionTypes.other && <FormSectionTwo
                currentValues={valuesDict}
                inputErrors={errorsDict}
                onChange={changeHandler}
                blurHandler={blurHandler}
                keydownHandler={keyDownHandler}
            />}

            <ActionBtn
                disabled={!isValidated}
                onClick={goToSaveContactSummary}
                className={CSS.SubmitBtn}
            >
                Create contact
            </ActionBtn>
        </div>
    ) : (
        <SaveContactSummary
            valuesDict={valuesDict}
        />
    )
};

export default React.memo(SaveContact); 
