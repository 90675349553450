import HandIcon from './hand';
import HelpIcon from './help'
import CubeIcon from './cube';
import ImageIcon from './image';
import RotateIcon from './rotate';
import rotateSvgUrl from './rotate.svg';
import DuplicateIcon from './duplicate';
import PadlockClosedIcon from './padlock-closed';
import PadlockOpenIcon from './padlock-open';
import ElementsIcon from './elements';
import LayerIcon from './layer';
import RecenterIcon from './recenter';
import ShapesIcon from './shapes';
import SoundIcon from './sound';
import SoundIconSmall from './sound-small';
import TextIcon from './text';
import VideoIcon from './video';
import WidgetsIcon from './widgets';
import ListSmallIcon from './list-small';
import PenIcon from './pen';
import ExpandIcon from './expand';
import MinusIcon from './minus';
import PlusIcon from './plus';
import BinIcon from './bin';
import Move3dIcon from './move3d';
import Rotate3dIcon from './rotate3d';
import Scale3dIcon from './scale3d';
import Scale3dLockIcon from './scale3d-lock';
import HourglassIcon from "./hourglass";
import TransitionIcon from "./transition";
import CopyIcon from "./copy";
import CrossIcon from "./cross";
import ArrowLeftIcon from "./arrow-left";
import ArrowRightIcon from "./arrow-right";
import ArrowDownIcon from "./arrow-down";
import ArrowUpIcon from "./arrow-up";
import BillboardIcon from "./billbard";
import BusinessCardIcon from "./business-card";
import CustomSizeIcon from "./custom-size";
import FileIcon from "./file";
import LightBoxIcon from "./lightbox";
import PostcardIcon from "./postcard";
import PosterIcon from "./poster";
import TabloidIcon from "./tabloid";
import CogIcon from "./cog";
import AppleStoreIcon from "./apple-store";
import GoogleStoreIcon from "./google-store";
import SaveIcon from "./save";
import TickIcon from "./tick";
import PlayIcon from "./play";
import PauseIcon from "./pause";
import UploadIcon from "./upload";
import LabelsIcon from "./labels";
import TargetAreaIcon from "./target-area";
import SnapIcon from "./snap";
import StarIcon from "./star";
import MediaLibIcon from "./media-library";
import PlayHighlightIcon from "./play-highlight";
import PauseHighlightIcon from "./pause-highlight";
import TextAlignmentRight from "./text-align-right";
import TextAlignmentLeft from "./text-align-left";
import TextAlignmentCenter from "./text-align-center";
import VolumeOffIcon from "./volume-off";
import VolumeMinIcon from "./volume-min";
import VolumeMedIcon from "./volume-med";
import VolumeMaxIcon from "./volume-max";
import MoveBackIcon from "./move-back";
import MoveForwardIcon from "./move-forward";
import MoveToBackIcon from "./moveToBack";
import MoveToFrontIcon from "./moveToFront";
import ListIcon from "./list";
import GrabGoIcon from "./grab-go";
import GridIcon from "./grid";
import GuidesIcon from "./guides";
import RocketIcon from "./rocket";
import ShortCutsIcon from "./shortcuts";
import TargetImageIcon from "./targetImage";
import MirrorIcon from "./mirror";
import UndoRedoIcon from "./undo";
import SceneIcon from "./scene";
import SearchIcon from "./search";
import WarningIcon from "./warning";
import ArrowLeftBigIcon from "./arrow-left-big";
import ForwardsIcon from "./forwards";
import BackwardsIcon from "./backwards";
import NoActionPlaceholderUrl from "./no-action-placeholder.svg";
import NoActionIcon from "./no-action";
import DownTransIcon from "./down";
import UpTransIcon from "./up";
import LeftTransIcon from "./left";
import RightTransIcon from "./right";
import FadeTransIcon from "./fade";
import ScaleUpTransIcon from "./scale-up";
import ScaleDownTransIcon from "./scale-down";
import NoTransIcon from "./no-effect";
import SpinTransIcon from "./spin";
import TargetUploadIcon from "./target-upload.svg";
import qrCodeIcon from "./qr-code.svg";
import qrCodeBorder from "./qr-code-border.svg";
import appleQr from "./apple-qr.png";
import googleQr from "./google-qr.png";
import appDemo from "./app-demo.png";
import pictureDummy from "./picture-dummy.svg";
import CustomIcon from "./custom.svg";
import SaveContactIcon from "./save-contact.svg";
import UploadContactIcon from "./upload-contact.svg";
import TrashIcon from "./trash.svg";
import ExperidtorArrowIcon from "./expeditor-arrow.svg";
import StarEmailIcon from "./star-email.svg";
import EmailSummaryIcon from "./email-summary.svg";
import PhoneContactIcon from "./phone-contact";
import AddressContactIcon from "./address-contact.svg";
import MailContactIcon from "./mail-contact";
import WebsiteContactIcon from "./website-contact.svg";
import DummyPortrait from "./dummy-portrait.png";
import DuplicateSceneIcon from "./duplicate-scene.svg";
import AddSceneIcon from "./add-scene.svg";
import ArrowLeftThin from "./arrow-left-thin";
import WebsiteIcon from "./website-icon";
import WebsiteIconSvg from "./website-icon.svg";
import HashIcon from "./hash-icon";
import ContactIcon from "./contact-icon";
import BrokenImage from "./broken-image.svg";
import BugReports from "./bug-reports.svg";
import AspectRatioLockedIcon from "./aspect-ratio-locked";
import AspectRatioUnlockedIcon from "./aspect-ratio-unlocked";
import { CSSProperties } from "react";

export interface IIconParentProps {
  className?: string;
  hexFill?: string;
  secondHexFill?: string;
  viewBox?: [number, number, number, number];
  strokeWidth?: number;
  height?: string;
  width?: string;
  title?: string;
  style?: CSSProperties;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => any;
  onMouseDown?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => any;
  onPointerEnter?: () => any;
  onPointerLeave?: () => any;
}

export enum IIconTypes {
  handIcon = "handIcon",
  padlockClosedIcon = "padlockClosedIcon",
  helpIcon = "helpIcon",
  copyIcon = "copyIcon",
  binIcon = "binIcon",
  transitionIcon = "transitionIcon",
  layerIcon = "layerIcon",
  penIcon = "penIcon",
}

export {
	Move3dIcon,
	Rotate3dIcon,
	Scale3dIcon,
	Scale3dLockIcon,
  HandIcon,
  CubeIcon,
  ImageIcon,
  RotateIcon,
  rotateSvgUrl,
  PadlockClosedIcon,
  PadlockOpenIcon,
  ElementsIcon,
  LayerIcon,
  ShapesIcon,
  SoundIcon,
  SoundIconSmall,
  TextIcon,
  VideoIcon,
  WidgetsIcon,
  PenIcon,
  HelpIcon,
  ExpandIcon,
  MinusIcon,
  PlusIcon,
  BinIcon,
  HourglassIcon,
  TransitionIcon,
  CopyIcon,
  ListIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  CogIcon,
  SaveIcon,
  PlayIcon,
  PauseIcon,
  MirrorIcon,
  UndoRedoIcon,
  CustomIcon,
  CrossIcon,
  ListSmallIcon,
  DownTransIcon,
  UpTransIcon,
  LeftTransIcon,
  RightTransIcon,
  FadeTransIcon,
  ScaleUpTransIcon,
  ScaleDownTransIcon,
  NoTransIcon,
  SpinTransIcon,
  SceneIcon,
  SearchIcon,
  DuplicateIcon,
  TargetUploadIcon,
  TickIcon,
  BillboardIcon,
  BusinessCardIcon,
  CustomSizeIcon,
  FileIcon,
  LightBoxIcon,
  PostcardIcon,
  PosterIcon,
  TabloidIcon,
  PlayHighlightIcon,
  PauseHighlightIcon,
  ForwardsIcon,
  BackwardsIcon,
  VolumeOffIcon,
  VolumeMinIcon,
  VolumeMedIcon,
  VolumeMaxIcon,
  UploadIcon,
  GrabGoIcon,
  RocketIcon,
  ShortCutsIcon,
  TargetImageIcon,
  LabelsIcon,
  GridIcon,
  GuidesIcon,
  TargetAreaIcon,
  SnapIcon,
  StarIcon,
  MediaLibIcon,
  ArrowLeftBigIcon,
  qrCodeIcon,
  qrCodeBorder,
  appleQr,
  googleQr,
  appDemo,
  AppleStoreIcon,
  GoogleStoreIcon,
  WarningIcon,
  RecenterIcon,
  pictureDummy,
  TextAlignmentRight,
  TextAlignmentCenter,
  TextAlignmentLeft,
  SaveContactIcon,
  UploadContactIcon,
  TrashIcon,
  ExperidtorArrowIcon,
  StarEmailIcon,
  MoveBackIcon,
  MoveForwardIcon,
  MoveToBackIcon,
  MoveToFrontIcon,
  PhoneContactIcon,
  AddressContactIcon,
  MailContactIcon,
  WebsiteContactIcon,
  DummyPortrait,
  EmailSummaryIcon,
  DuplicateSceneIcon,
  AddSceneIcon,
  ArrowLeftThin,
  WebsiteIcon,
  WebsiteIconSvg,
  HashIcon,
  ContactIcon,
  BrokenImage,
  BugReports,
  NoActionIcon,
  NoActionPlaceholderUrl,
  AspectRatioLockedIcon,
  AspectRatioUnlockedIcon
};
