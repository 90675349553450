// import { useMemo } from "react";
import { SkinnedMesh, Mesh, BufferGeometry, Material, Bone } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import * as utils from 'three/examples/jsm/utils/SkeletonUtils';
import { customComputeMorphedAttributes } from "../utils/geometry-utils";
import { Object3D } from 'three';

export const convertGltf = (gltf: GLTF, renderOrder?: number): Object3D => {
    // return useMemo(() => {

        // console.log('clone convert!!!', renderOrder, 'scene id', gltf.scene.id)
        const userDataUuidToNodeUuidDict: {[id: string]: string} = {};

        gltf.scene.traverse((node: any) => {
            // add skinned mesh and bone uuid to user data for later mapping to cloned model instance
            if (node.isBone || node.isSkinnedMesh) {
                node.userData = {
                    ...node.userData,
                    uuid: node.uuid
                }
            }
            // add render order to mesh if material(s) is / are transparent
            if ((node.isSkinnedMesh || node.isMesh) && typeof renderOrder !== 'undefined') {
                const mesh = node as Mesh | SkinnedMesh;
                const materialArray = Array.isArray(mesh.material) ? mesh.material : [mesh.material];
                
                let meshIsTransparent = false;
                for (let i = 0; i < materialArray.length; i++) {
                    if (materialArray[i].transparent) meshIsTransparent = true;
                }
                if (meshIsTransparent) mesh.renderOrder = renderOrder
            }
        });

        // safely get skeleton utils clone method from different threejs versions
        const clone = (utils as any).clone || (utils as any).SkeletonUtils?.clone;
        // use Skeleton utils to safely clone skinned mesh (and other) models
        const clonedScene = clone(gltf.scene) as Object3D;

        // map user data uuids to cloned node uuids (which change during cloning)
        clonedScene.traverse((node: any) => {
            if (node.isBone || node.isSkinnedMesh) {
                userDataUuidToNodeUuidDict[node.userData.uuid] = node.uuid;
            }
        })

        // console.log('original model', gltf.scene);

        gltf.scene.updateMatrixWorld(true);

        gltf.scene.traverse((node: any) => {
            

            // create baked geometry from skinned mesh
            if (node.isSkinnedMesh) {
                const skinnedMesh = node as SkinnedMesh;
                skinnedMesh.skeleton.update();

                const skinnedGeom = skinnedMesh.geometry;
                const { attributes } = skinnedGeom;

                const bakedGeometry = new BufferGeometry();

                // name
                bakedGeometry.name = skinnedGeom.name;
                // index
                if (skinnedGeom.index) bakedGeometry.setIndex( skinnedGeom.index?.clone?.());

                // if normals are missing, compute them
                if (!attributes.normal) skinnedGeom.computeVertexNormals();

                // attributes
                for ( const name in attributes ) {
                    if ( 
                        name !== 'position' &&
                        name !== 'normal' &&
                        name !== 'skinIndex' &&
                        name !== 'skinWeight'
                    ) {
                        const attribute = attributes[name];
                        bakedGeometry.setAttribute(name, attribute.clone());
                    }
                }

                // groups
                const groups = skinnedGeom.groups;
                for (let i = 0, l = groups.length; i < l; i ++) {
                    const group = groups[i];
                    bakedGeometry.addGroup(group.start, group.count, group.materialIndex);
                }

                // convert morphed attributes
                const morphedAttributes = customComputeMorphedAttributes(skinnedMesh);
                if (morphedAttributes?.morphedPositionAttribute) {
                    bakedGeometry.setAttribute('position', morphedAttributes.morphedPositionAttribute);
                }
                if (morphedAttributes?.morphedNormalAttribute) {
                    bakedGeometry.setAttribute('normal', morphedAttributes.morphedNormalAttribute);
                } 
                
                // draw range
                bakedGeometry.drawRange.start = skinnedGeom.drawRange.start;
                bakedGeometry.drawRange.count = skinnedGeom.drawRange.count;

                // user data
                bakedGeometry.userData = skinnedGeom.userData;

                // calculate bounding box and shpere
                bakedGeometry.computeBoundingBox();
                bakedGeometry.computeBoundingSphere();

                // create new material(s)
                const { material } = skinnedMesh;
                let newMaterial: Material | Material[];

                if (material instanceof Array) {
                    newMaterial = [];
                    for (let i = 0; i < material.length; i++) {
                        newMaterial.push(material[i].clone());  
                    }
                } else newMaterial = material.clone();
             
                // add newly created mesh to mesh parent of cloned scene
                const clonedNodeUuid = userDataUuidToNodeUuidDict[node.userData.uuid];
                const clonedSceneNode = clonedScene.getObjectByProperty('uuid', clonedNodeUuid) as SkinnedMesh;

                // create a new mesh
                const newMesh = new Mesh();
                newMesh.copy(clonedSceneNode);
                newMesh.geometry = bakedGeometry;
                newMesh.material = newMaterial;

                clonedSceneNode?.parent?.add?.(newMesh);
            }
        });

        // hide skinnedMeshes and bones model scene (needed in future milestones for animation)
        for (const userDataUuid in userDataUuidToNodeUuidDict) {
            const nodeUuid = userDataUuidToNodeUuidDict[userDataUuid];
            const redundantObject = clonedScene.getObjectByProperty("uuid", nodeUuid) as SkinnedMesh | Bone;
            redundantObject.visible = false;
        }

        // console.log('converted scene', clonedScene);

        return clonedScene;
    // }, [])
}