import React from "react";
import CSS from "./Toggle.scss";

interface IParentProps {
  toggleOnFunc: () => void;
  toggleOffFunc: () => void;
  checked: boolean;
}

const Toggle = ({
  toggleOnFunc,
  toggleOffFunc,
  checked,
}: IParentProps) => {

  return (
    <>
      <button className={(checked ? CSS.Inactive : CSS.Active)} onClick={toggleOffFunc}>
        2D
      </button>
      <label className={CSS.switch}>
        <input type="checkbox" onChange={checked ? toggleOffFunc : toggleOnFunc} checked={checked}/>
        <span className={CSS.slider}></span>
      </label>
      <button className={(checked ? CSS.Active : CSS.Inactive)} onClick={toggleOnFunc}>
        3D
      </button>
    </>
  )
}

export default React.memo(Toggle);