// Environment settings
import * as zw from 'zw-api-client/src/browser';

declare global {
  interface Window {
    VERSION: string
    _env: {
      ENVIRONMENT: string
      BASE_URL: string
      SENTRY_DSN: string
      ZW_ENV: string
      ZW_BASE_URL: string
      ZW_CLIENT_ID: string
      CDS_URL: string
    }
  }
}

export const VERSION = window.VERSION;
export const ENVIRONMENT = window._env.ENVIRONMENT;
export const BASE_URL = window._env.BASE_URL;
export const DEBUG = ['local', 'development'].includes(ENVIRONMENT);

// Sentry
export const SENTRY_DSN = window._env.SENTRY_DSN;

// ZapWorks CMS
export const ZW_ENV = (window._env.ZW_ENV || zw.Environment.Dev).toUpperCase() as zw.Environment;
export const ZW_BASE_URL = window._env.ZW_BASE_URL;
export const ZW_CLIENT_ID = window._env.ZW_CLIENT_ID;

// CDS
export const CDS_URL = window._env.CDS_URL;

// Jute
export const JUTE_VERSION = '1.3';
