import React from 'react';
import {NumericalInput} from '../../../Inputs'
import CSS from './AlphaInput.scss';

interface IParentProps {
	alpha: number;
	setAlpha: any;
}

const AlphaInput: React.SFC<IParentProps> = ({ alpha, setAlpha }) => {
	return (
		//RGB_A value, not used in this design
		<NumericalInput
				className={CSS.ColorPickerOpacityInput}
				labelText='%'
				labelColor='#5d5d5d'
				value={Math.round(alpha * 100)}
				min={0}
				max={100}
				decimals={0}
				step={1}
				onChange={val => {
					if (!val) return;
					if (val > 100) return;
					setAlpha(val / 100);
				}}
			/>
	);
};

export default AlphaInput;
