// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InspectorMenu--1PeA4{width:272px;height:calc(100vh - 60px);padding:10px 20px 10px 20px;position:absolute;display:flex;flex-direction:column;align-items:center;right:0;top:60px;z-index:997;box-shadow:0 2px 4px 0 #00000029;background-color:#fff}.InspectorMenu--1PeA4 .InspectorTabs--1myXC{height:32px;font-size:16px;margin-bottom:8px}.InspectorMenu--1PeA4 .ShelfContainer--1Ad4O{height:calc(100% - 68px);width:calc(100% + 16px);overflow-y:scroll;padding:0 13px 30px 13px;margin-right:-13px;margin-left:-13px}", ""]);
// Exports
exports.locals = {
	"InspectorMenu": "InspectorMenu--1PeA4",
	"InspectorTabs": "InspectorTabs--1myXC",
	"ShelfContainer": "ShelfContainer--1Ad4O"
};
module.exports = exports;
