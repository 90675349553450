import { IFontTypes } from "./types/general";
export const ROOT_COMPNENT_ID = "d2_root_v1";
export const FIRST_SCENE_TITLE = "Scene 1";
export const FIRST_SCENE_ID = "16fd2706-8baf-433b-82eb-8c7fada847da";


// Font collection: https://codesandbox.io/embed/troika-3d-text-via-react-three-fiber-ntfx2?fontsize=14
export const FONT_TYPES = [
  {
    fontFamily: IFontTypes.Roboto,
    url: "https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxM.woff",
    desc: "Standard title",
    scale: [0.26 * 2, 0.04 * 2, 0],
    ptToXCoord: 1.171875,
  },
  {
    fontFamily: IFontTypes.Comfortaa,
    url: "https://fonts.gstatic.com/s/comfortaa/v12/1Ptsg8LJRfWJmhDAuUs4TYFs.woff",
    desc: "Rounded title",
    scale: [0.3 * 2, 0.04 * 2, 0],
    ptToXCoord: 1.115,
  },
  {
    fontFamily: IFontTypes.Cookie,
    url: "https://fonts.gstatic.com/s/cookie/v8/syky-y18lb0tSbf9kgqU.woff",
    desc: "Elegant title",
    scale: [0.15 * 2, 0.04 * 2, 0],
    ptToXCoord: 1.109,
  },
  {
    fontFamily: IFontTypes.Philosopher,
    url: "https://fonts.gstatic.com/s/philosopher/v9/vEFV2_5QCwIS4_Dhez5jcWBuT0s.woff",
    desc: "Classic title",
    scale: [0.21 * 2, 0.04 * 2, 0],
  },
  {
    fontFamily: IFontTypes.Quicksand,
    url: "https://fonts.gstatic.com/s/quicksand/v7/6xKtdSZaM9iE8KbpRA_hK1QL.woff",
    desc: "Modern title",
    scale: [0.24 * 2, 0.04 * 2, 0],
  },
  {
    fontFamily: IFontTypes.Trirong,
    url: "https://fonts.gstatic.com/s/trirong/v3/7r3GqXNgp8wxdOdOn4so3g.woff",
    desc: "Classic title 2",
    scale: [0.27 * 2, 0.04 * 2, 0],
  },
  {
    fontFamily: IFontTypes.Trocchi,
    url: "https://fonts.gstatic.com/s/trocchi/v6/qWcqB6WkuIDxDZLcPrxeuw.woff",
    desc: "Modern title 2",
    scale: [0.3 * 2, 0.04 * 2, 0],
  },
  {
    fontFamily: IFontTypes.AdventPro,
    url: "https://fonts.gstatic.com/s/adventpro/v7/V8mAoQfxVT4Dvddr_yOwhTqtLg.woff",
    desc: "Ultra modern title",
    scale: [0.29 * 2, 0.04 * 2, 0],
  },
  {
    fontFamily: IFontTypes.HennyPenny,
    url: "https://fonts.gstatic.com/s/hennypenny/v5/wXKvE3UZookzsxz_kjGSfPQtvXQ.woff",
    desc: "Magic title",
    scale: [0.23 * 2, 0.04 * 2, 0],
  },
  {
    fontFamily: IFontTypes.Orbitron,
    url: "https://fonts.gstatic.com/s/orbitron/v9/yMJRMIlzdpvBhQQL_Qq7dys.woff",
    desc: "Futuristic title",
    scale: [0.32 * 2, 0.04 * 2, 0],
  },
  {
    fontFamily: IFontTypes.Sacramento,
    url: "https://fonts.gstatic.com/s/sacramento/v5/buEzpo6gcdjy0EiZMBUG4C0f-w.woff",
    desc: "Handwritten title",
    scale: [0.25 * 2, 0.04 * 2, 0],
  },
  {
    fontFamily: IFontTypes.SnowburstOne,
    url: "https://fonts.gstatic.com/s/snowburstone/v5/MQpS-WezKdujBsXY3B7I-UT7SZieOA.woff",
    desc: "Magic title 2",
    scale: [0.32 * 2, 0.04 * 2, 0],
  },
  {
    fontFamily: IFontTypes.Syncopate,
    url: "https://fonts.gstatic.com/s/syncopate/v9/pe0sMIuPIYBCpEV5eFdCBfe5.woff",
    desc: "Futuristic title 2",
    scale: [0.3 * 2, 0.09 * 2, 0],
  },
  {
    fontFamily: IFontTypes.Wallpoet,
    url: "https://fonts.gstatic.com/s/wallpoet/v9/f0X10em2_8RnXVVdUObp58I.woff",
    desc: "Funky title",
    scale: [0.29 * 2, 0.04 * 2, 0],
  },
  {
    fontFamily: IFontTypes.SirinStencil,
    url: "https://fonts.gstatic.com/s/sirinstencil/v6/mem4YaWwznmLx-lzGfN7MdRyRc9MAQ.woff",
    desc: "Ugly title",
    scale: [0.17 * 2, 0.04 * 2, 0],
  },
];
