import React, { useState, useRef, useEffect } from 'react';

import CSS from './TextArea.scss';


interface IParentProps {
	style?: React.CSSProperties;
	className?: string;
	error?: boolean;
	value: string;
	focused?: boolean;
	placeholder?: string; 
	stopPropagation?: boolean; 
	onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => any;
	onFinalChange?: (
		e:
			| React.KeyboardEvent<HTMLTextAreaElement>
			| React.FocusEvent<HTMLTextAreaElement>
	) => any;
	onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => any;
	onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => any;
	onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => any;
	onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => any;
	onPointerDown?: (e: React.PointerEvent<HTMLTextAreaElement>) => any;
	onMount?: (ref: React.MutableRefObject<HTMLTextAreaElement>) => any;
}

const TextArea: React.FunctionComponent<IParentProps> = ({
	style,
	className,
	value,
	onChange,
	onFinalChange,
	onFocus,
	focused = false,
	stopPropagation = false, 
	onBlur,
	onKeyUp,
	onKeyDown,
	onPointerDown, 
	onMount, 
	error,
	placeholder, 
}) => {
	const textAreaRef = useRef<HTMLTextAreaElement>();
	const [userInput, setUserInput] = useState<boolean>(false);
	const [tempValue, setTempValue] = useState<string>(value);
	let classes = [CSS.TextArea, className ? className : ''];

	useEffect(() => {
		if (!userInput && tempValue !== value) setTempValue(value);
	}, [userInput, tempValue, value]);

	useEffect(() => {
		if (focused)  textAreaRef.current.focus(); 
	}, [focused, textAreaRef]);

	useEffect(() => {
		if(onMount) onMount(textAreaRef);   
	}, [textAreaRef]);

	const _onFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => onFocus?.(e);

	const _onBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
		setUserInput(false);
		onBlur?.(e);
		if (onFinalChange && tempValue !== value) onFinalChange(e);
	};

	const _onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setUserInput(true);
		onChange?.(e);
		setTempValue(e.target.value);
	};

	const _onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if(stopPropagation) e.stopPropagation(); 
		if (e.key === "Enter" && !(e.metaKey || e.shiftKey )) {  
			e.preventDefault(); 
			textAreaRef.current.blur(); 
		}
		if (onKeyDown) onKeyDown(e);
	};

	const _onPointerDown = (e: React.PointerEvent<HTMLTextAreaElement>) => {
		if(stopPropagation) e.stopPropagation(); 
		onPointerDown?.(e); 
	}

	return (
		<textarea
			ref={textAreaRef}
			style={{ ...style, borderColor: error ? 'red' : '' }}
			className={classes.join(' ')}
			value={tempValue}
			placeholder={placeholder}
			onFocus={_onFocus}
			onBlur={_onBlur}
			onChange={_onChange}
			onKeyDown={_onKeyDown}
			onKeyUp={e => {
				if (onKeyUp) onKeyUp(e);
			}}
			onPointerDown={_onPointerDown}
		/>
	);
};

export default TextArea ;


