import React, { useState, forwardRef } from 'react';
import { ArrowRightIcon as ArrowIcon } from '../../../../assets/icons/index';

import CSS from './DropdownBtn.scss';

interface IParentProps {
	style?: any;
	ref?: React.Ref<any>;
	icon?: React.SFC;
	open: boolean;
	text: string;
	disabled?: boolean; 
	onClick: () => any;
}

const Drowdown: React.SFC<IParentProps> = forwardRef(
	({ style, open, icon: Icon, text, disabled, onClick }, ref) => {
		return (
			<div
				className={`${CSS.DropDown} ${open && CSS.Active} ${disabled && CSS.Disabled}`}
				onClick={() => {if(!disabled) onClick()}}
				style={{ ...style }}
				ref={ref}
			>
				<div className={CSS.SelectedOptionContainer}>
					{Icon && <Icon />}
					<span>{text}</span>
				</div>
				<ArrowIcon
					viewBox={[0, 0, 7, 7]}
					className={`${CSS.Arrow} ${open && CSS.RotatedArrow}`}
				/>
			</div>
		);
	}
);

export default Drowdown;
