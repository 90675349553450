import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getContentEntityDict, getSelectedEditorRotations } from "../../../../../../../store/selectors";
import { IDesignerState } from "../../../../../../../../typings";
import { onSetRotations_Cn_Doc, onSetMultipleEntityProps_Ed_Doc, IMultipleEntityProps_Ed_Doc } from "../../../../../../../store/actions";
import { ISpatialComponentUnion } from "../../../../../../r3f/r3f-components/component-data-structure";
import { NumericalInput } from "../../../../../Inputs";
import Divider from '../../../../../Divider/Divider';
import CSS from './TransformsRotation.scss'

interface IParentProps {
  entityIsLocked: boolean;
  entitiesHaveSame: { [key: string]: boolean[] };
}
const TransformsRotation = ({
  entityIsLocked,
  entitiesHaveSame
}: IParentProps) => {
  // Redux
  const dispatch = useDispatch();
  const is3dMode = useSelector((state: IDesignerState) => state.userReducer.is3dMode);
  const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
  const entityContentDict = useSelector((state: IDesignerState) => getContentEntityDict(state));
  const editorRotationDict = useSelector((state: IDesignerState) => getSelectedEditorRotations(state));

  // Derived
	const id = selectedEntityIds[0];
	const entity = entityContentDict[id] as ISpatialComponentUnion;
  const rotation = editorRotationDict[id] || entity.rotation;

  // Helpers
  const updateRotation = (value: number, index: number) => {
    let rotationDict: { [id: string]: number[] } = {};
    let multipleEntityProps_Ed_Doc: IMultipleEntityProps_Ed_Doc[] = [];
    for (let i = 0; i < selectedEntityIds.length; i++) {
      const id = selectedEntityIds[i];
      const entity = entityContentDict[id] as ISpatialComponentUnion;
      let newRotation = [...entity.rotation];
      newRotation[index] = value;
      rotationDict[id] = newRotation;
      multipleEntityProps_Ed_Doc.push({ id, rotations: null })
    }
    dispatch(onSetRotations_Cn_Doc(rotationDict));
    dispatch(onSetMultipleEntityProps_Ed_Doc(multipleEntityProps_Ed_Doc));
  };

  // Content
  const rotationInputOptions = is3dMode ? [
		{ text: '°X', color: 'red' }, 
		{ text: '°Y', color: 'green' }, 
		{ text: '°Z', color: 'blue' }
	] : [{ text: '°Z', color: 'blue' }]

  const rotationRowContent = rotationInputOptions.map((label, index) => {
    const rotationalIndex = is3dMode ? index : 2; // 2 because in 2D mode you rotate around the Z axis
    const multitpleEntitiesWithDiffRotation = entitiesHaveSame && !entitiesHaveSame.rotation[rotationalIndex];
    return (
      <Fragment key={index}>
        <NumericalInput
          decimals={0}
          step={1}
          placeHolder={'multi'}
          className={CSS.TransformInput}
          labelText={label.text}
          labelColor={label.color}
          min={0}
          max={360}
          allowNegative={false}
          continuousRange={true}
          disabled={entityIsLocked}
          value={multitpleEntitiesWithDiffRotation ? undefined : rotation[rotationalIndex]}
          onChange={(v) => updateRotation(v, rotationalIndex)} 
        />
        {is3dMode && index < 2 && <Divider height={'23px'} />}
      </Fragment>
    );
	});

  return (
    <>
      <div className={CSS.RowTitle}>
				Rotation (deg)
			</div>
			<div className={CSS.InputRow}>
				{rotationRowContent}
      </div>
    </>
  )

}

export default React.memo(TransformsRotation);