import React from 'react';
import { TextInput } from '../../../../../../Inputs';
import CSS from './FormSection.scss';
import { IContactErrors, IContactParams, IInputDict } from '../SaveContact';

interface IParentProps {
	currentValues: IInputDict; 
	inputErrors: IContactErrors;
	onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, inputField: IContactParams ) => any; 
	blurHandler:  (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, inputField: IContactParams) => any; 
  keydownHandler: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, secondTab?: boolean) => any; 
}

const FromSectionTwo: React.FunctionComponent<IParentProps> = ({
  currentValues, 
	inputErrors,
  onChange, 
	blurHandler,
	keydownHandler, 
}) => {
	
	return (
    <>
		<div className={CSS.Error}>{inputErrors.mainForm}</div>
         <div className={`${CSS.FlexRow} ${CSS.HelperRow}`}>	 	
			<div className={CSS.Description}>Phone number 2</div>
		</div>
		<TextInput
			value={currentValues.phone2 || ""}
			placeholder={"Optional"}
			className={CSS.Input}
			onChange={e => onChange(e, IContactParams.phone2)}
			onBlur={e => blurHandler(e, IContactParams.phone2)}
			onKeyDown={e => keydownHandler(e, true)}
		/>
		<div className={CSS.Error}>{inputErrors.phone2}</div>

		<div className={CSS.Description}>Phone number 3</div>
		<TextInput
			value={currentValues.phone3 || ""}
			placeholder={"Optional"}
			className={CSS.Input}
			onChange={e => onChange(e, IContactParams.phone3)}
			onBlur={e => blurHandler(e, IContactParams.phone3)}
			onKeyDown={e => keydownHandler(e, true)}
		/>
		<div className={CSS.Error}>{inputErrors.phone3}</div>

		<div className={CSS.Description}>Postal Address</div>
		<div className={CSS.Description}>Street 1</div>
		<TextInput
			value={currentValues.street1 || ""}
			placeholder={"Optional"}
			className={CSS.Input}
			onChange={e => onChange(e, IContactParams.street1)}
			onBlur={e => blurHandler(e, IContactParams.street1)}
			onKeyDown={e => keydownHandler(e, true)}
		/>

		<div className={CSS.Description}>Street 2</div>
		<TextInput
			value={currentValues.street2 || ""}
			placeholder={"Optional"}
			className={CSS.Input}
			onChange={e => onChange(e, IContactParams.street2)}
			onBlur={e => blurHandler(e, IContactParams.street2)}
			onKeyDown={e => keydownHandler(e, true)}
		/>

		<div className={CSS.Description}>City</div>
		<TextInput
			value={currentValues.city || ""}
			placeholder={"Optional"}
			className={CSS.Input}
			onChange={e => onChange(e, IContactParams.city)}
			onBlur={e => blurHandler(e, IContactParams.city)}
			onKeyDown={e => keydownHandler(e, true)}
		/>

		<div className={CSS.Description}>County</div>
		<TextInput
			value={currentValues.county || ""}
			placeholder={"Optional"}
			className={CSS.Input}
			onChange={e => onChange(e, IContactParams.county)}
			onBlur={e => blurHandler(e, IContactParams.county)}
			onKeyDown={e => keydownHandler(e, true)}
		/>

		<div className={CSS.Description}>Postcode</div>
		<TextInput
			value={currentValues.zip || ""}
			placeholder={"Optional"}
			className={CSS.Input}
			onChange={e => onChange(e, IContactParams.zip)}
			onBlur={e => blurHandler(e, IContactParams.zip)}
			onKeyDown={e => keydownHandler(e, true)}
		/>

		<div className={CSS.Description}>Country</div>
		<TextInput
			value={currentValues.country || ""}
			placeholder={"Optional"}
			className={CSS.Input}
			onChange={e => onChange(e, IContactParams.country)}
			onBlur={e => blurHandler(e, IContactParams.country)}
			onKeyDown={e => keydownHandler(e, true)}
		/>
    </>
    )
}

export default FromSectionTwo; 