import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const volumeOffIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="40px"
			height="40px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 40 40'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g
				id="Atom/Icon/Volume/Off"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Group-5-Copy"
					transform="translate(9.000000, 9.000000)"
					stroke={hexFill}
				>
					<g id="Group-2">
						<g id="Group">
							<g id="Group-4" transform="translate(4.132774, 0.000000)">
								<path
									d="M8.93461854,1.50442762 C8.80794791,1.48630001 8.67436183,1.51649561 8.5638845,1.59931114 L1.37572313,6.98766883 L1.37572313,15.004335 L8.56365159,20.3990583 C8.65022385,20.464033 8.75554344,20.4991592 8.86378606,20.4991592 C9.00185725,20.4991592 9.12685725,20.4431948 9.21733945,20.3527126 C9.30782165,20.2622304 9.36378606,20.1372304 9.36378606,19.9991592 L9.36378606,1.99938495 C9.36378606,1.89124194 9.32872439,1.78601377 8.93461854,1.50442762 Z"
									id="Rectangle"
									transform="translate(5.369755, 11.000000) scale(1, -1) translate(-5.369755, -11.000000) "
								></path>
							</g>
							<path
								d="M5.5,6.5 L2,6.5 C1.58578644,6.5 1.21078644,6.66789322 0.939339828,6.93933983 C0.667893219,7.21078644 0.5,7.58578644 0.5,8 L0.5,14 C0.5,14.4142136 0.667893219,14.7892136 0.939339828,15.0606602 C1.21078644,15.3321068 1.58578644,15.5 2,15.5 L5.5,15.5 L5.5,6.5 Z"
								id="Rectangle-Copy"
								transform="translate(3.000000, 11.000000) scale(1, -1) translate(-3.000000, -11.000000) "
							></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default volumeOffIcon;
