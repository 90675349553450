import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../../typings';
import Btn from '../../../../buttons/MenuBtn/MenuBtn';
import { INITIAL_ZOOM_LEVEL, MIN_ZOOM_LIMIT, MAX_ZOOM_LIMIT } from '../../../../../../utils';
import { onChangeZoomLevel } from '../../../../../../store/actions';
import { PlusIcon,MinusIcon } from '../../../../../../assets/icons/index';
import CSS from './Zoom.scss';

const Zoom: FunctionComponent = () => {
	// Redux
	const dispatch = useDispatch();
	const zoomLevel = useSelector((state: IDesignerState) => state.userReducer.zoomLevel);
	const projectId = useSelector((state: IDesignerState) => state.userReducer.project.id);
	const is3dMode = useSelector((state: IDesignerState) => state.userReducer.is3dMode);

	// Derived
	const currentZoom = `${Math.round((zoomLevel / INITIAL_ZOOM_LEVEL) * 100)}%`;

	// Helpers
	const zoom = (action: 'in' | 'out') => {
		let newZoom = zoomLevel + (action === 'in' ? 3 : -3); 
		if(action === 'in' && newZoom > MAX_ZOOM_LIMIT) newZoom = MAX_ZOOM_LIMIT;
		if(action === 'out' && newZoom < MIN_ZOOM_LIMIT) newZoom = MIN_ZOOM_LIMIT;
    dispatch(onChangeZoomLevel(newZoom));
    localStorage.setItem(`${projectId}_zoom_level`, `${newZoom}`);
	}

	return (
		<div className={CSS.ViewMenu}>
			{!is3dMode &&
				<Btn onClick={() => zoom('out')}>
					<MinusIcon className={CSS.SmallIcon} />
				</Btn>
			}
			<span>{currentZoom}</span>
			{!is3dMode &&
				<Btn onClick={() => zoom('in')}>
					<PlusIcon className={CSS.SmallIcon} />
				</Btn>
			}
		</div>
	);
};

export default React.memo(Zoom);
