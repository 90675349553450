import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const textIcon: React.SFC<IIconParentProps> = ({
	hexFill: fill,
	viewBox,
	className,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="15px"
			height="20px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 15 20`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<title>Text</title>
			<g id="Expanded" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Text" transform="translate(-13.000000, -10.000000)" fill={hexFill} fillRule="nonzero">
					<polygon points="27.4694534 11.225 21.2234727 11.225 21.2234727 30 19.812701 30 19.812701 11.225 13 11.225 13 10 28 10 28 11.225"></polygon>
				</g>
			</g>

		</svg>
	);
};

export default textIcon;
