import { IMediaFileOutput, IVideoData, IAudioData, IImageData, IUnionMediaData } from "../zml";

export interface PublishRequest {
  projectId: string
  content: {[k: string]: string}
}

export enum IProgressStatus {
  uploading = 'uploading',
  processing = 'processing',
  analysing = 'analysing',
  training = 'training',
  completed = 'completed',
  trainingCompleted = 'trainingCompleted',
  imgQualityError = 'imgQualityError',
  error = 'error',
}

export interface IFileProgressInfo {
  status: IProgressStatus;
  id?: number | string;
  errorCode?: number;
  desc?: string;
  mediaData?: IUnionMediaData;
}

export type IProgressCallback = (p: number | null, info: IFileProgressInfo) => any;
