// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Container--1rnzC{width:100%;height:8px;border-radius:4px;background-color:#f2f2f2}.Container--1rnzC>.Progressbar--34oQ9{height:100%;border-radius:4px;background-color:#4a90e2}", ""]);
// Exports
exports.locals = {
	"Container": "Container--1rnzC",
	"Progressbar": "Progressbar--34oQ9"
};
module.exports = exports;
