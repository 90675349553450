import {
	ISocialProvider
} from '../component-data-structure';

export const SOCIAL_PROVIDERS = [
  {
    name: ISocialProvider.facebook,
    iconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/facebook-sqr-v1.svg',
    iconW: 38,
    iconH: 38,
    largeIconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/facebook-hrz-v1.svg',
    largeIconW: 110,
    largeIconH: 39,
  },
  {
    name: ISocialProvider.twitter,
    iconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/twitter-sqr-v1.svg',
    iconW: 38,
    iconH: 38,
    largeIconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/twitter-hrz-v1.svg',
    largeIconW: 110,
    largeIconH: 39,
  },
  {
    name: ISocialProvider.instagram,
    iconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/instagram-sqr-v1.svg',
    iconW: 38,
    iconH: 38,
    largeIconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/instagram-hrz-v1.svg',
    largeIconW: 110,
    largeIconH: 39,
  },
  {
    name: ISocialProvider.linkedIn,
    iconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/linkedin-sqr-v1.svg',
    iconW: 38,
    iconH: 38,
    largeIconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/linkedin-hrz-v1.svg',
    largeIconW: 110,
    largeIconH: 39,
  },
  {
    name: ISocialProvider.spotify,
    iconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/spotify-sqr-v1.svg',
    iconW: 38,
    iconH: 38,
  },
  {
    name: ISocialProvider.twitch,
    iconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/twitch-sqr-v1.svg',
    iconW: 38,
    iconH: 38,
  },
  {
    name: ISocialProvider.tiktok,
    iconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/tiktok-sqr-v1.svg',
    iconW: 38,
    iconH: 38,
    largeIconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/tiktok-hrz-v1.svg',
    largeIconW: 110,
    largeIconH: 39,
  },
  {
    name: ISocialProvider.youtube,
    iconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/youtube-sqr-v1.svg',
    iconW: 38,
    iconH: 30,
    largeIconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/youtube-hrz-v1.svg',
    largeIconW: 110,
    largeIconH: 39,
  },
];