// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Container--1ozIs{position:relative;margin-right:24px;color:#fff}.NameDiv--2ZqsJ{position:absolute;top:0;display:flex;align-items:center;justify-content:center;color:#fff;font-weight:bold;width:32px;height:32px;font-size:14px;border-radius:50%;line-height:22px;border:1px solid #fff;overflow:hidden;cursor:pointer}.NameDiv--2ZqsJ img{max-width:100%;max-height:100%}", ""]);
// Exports
exports.locals = {
	"Container": "Container--1ozIs",
	"NameDiv": "NameDiv--2ZqsJ"
};
module.exports = exports;
