import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { onCopySelectedEntities, onPasteCopiedEntities_Global, onSetMultipleEntityProps_Ed_Doc, onRemoveEntities_Global, onRemoveSelection, onSetTransformControlsMode } from "../../../../../store/actions";
import { getEditorIsLockedDict } from "../../../../../store/selectors";
import { useRefState } from "../../../../r3f/r3f-components/hooks";
import { IDesignerState, ITransformControlsMode } from "../../../../../../typings";
import { DuplicateIcon, BinIcon, PadlockOpenIcon, PadlockClosedIcon, AspectRatioLockedIcon, AspectRatioUnlockedIcon } from "../../../../../assets/icons";
import { DEFAULT_ICON_COLOR, ICON_INACTIVE_COLOR, ICON_ACTIVE_BLUE_COLOR } from "../../../../../utils";
import CSS from "./EntityActionContextMenu.scss";

const EntityActionContextMenu = () => {
  const [_, setNumberPasted, numberPastedRef] = useRefState(0);
  // Redux
  const dispatch = useDispatch();
  const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId);
  const editorIsLockedDict = useSelector((state: IDesignerState) => getEditorIsLockedDict(state));
  const aspectRatioLockedDict = useSelector((state: IDesignerState) => state.editorReducer.editorDoc.aspectRatioLocked);
  const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
  const is3dMode = useSelector((state: IDesignerState) => state.userReducer.is3dMode);
  const entityId = selectedEntityIds[0];

  // Derived
  const somethingSelected = selectedEntityIds.length !== 0;
  const somethingSelectedAndLocked = selectedEntityIds.length !== 0 && editorIsLockedDict[selectedEntityIds[0]];
  // const aspectRatioLocked = aspectRatioLockedDict[entityId];

  // Local state
  const [copyHexfill, setCopyHexFill] = useState(DEFAULT_ICON_COLOR);
  // const [aspectRatioLockHexfill, setAspectRatioHexFill] = useState(DEFAULT_ICON_COLOR);
  const [lockHexfill, setLockHexFill] = useState(somethingSelected ? DEFAULT_ICON_COLOR : ICON_INACTIVE_COLOR);
  const [binHexfill, setBinHexFill] = useState(DEFAULT_ICON_COLOR);

  // Update colors on switch 2D / 3D mode, and if user switches mode
  useEffect(() => {
    // setAspectRatioHexFill(somethingSelectedAndLocked
    //   ? ICON_INACTIVE_COLOR
    //   : somethingSelected
    //     ? DEFAULT_ICON_COLOR
    //     : ICON_INACTIVE_COLOR);
    setCopyHexFill(somethingSelectedAndLocked
      ? ICON_INACTIVE_COLOR
      : somethingSelected
        ? DEFAULT_ICON_COLOR
        : ICON_INACTIVE_COLOR);
    setLockHexFill(somethingSelected ? DEFAULT_ICON_COLOR : ICON_INACTIVE_COLOR);
    setBinHexFill(somethingSelectedAndLocked
      ? ICON_INACTIVE_COLOR
      : somethingSelected
        ? DEFAULT_ICON_COLOR
        : ICON_INACTIVE_COLOR);
  }, [is3dMode, somethingSelected, somethingSelectedAndLocked]);
  
  useEffect(() => {
		setNumberPasted(0)
	}, [selectedEntityIds])

  const copyAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (somethingSelectedAndLocked || copyHexfill === ICON_INACTIVE_COLOR) return;
    dispatch(onCopySelectedEntities(selectedEntityIds));
    dispatch(onPasteCopiedEntities_Global({
      activeSceneId,
      copiedIds: selectedEntityIds,
      offset: [
        0.05 * numberPastedRef.current + 0.05,
        -0.05 * numberPastedRef.current - 0.05,
        0
      ]
    }));
    setNumberPasted(numberPastedRef.current + 1);
  }

  const lockAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (lockHexfill === ICON_INACTIVE_COLOR) return;
    dispatch(onSetTransformControlsMode(ITransformControlsMode.translate));
    dispatch(onSetMultipleEntityProps_Ed_Doc(
      selectedEntityIds.map(id => {
        return { id, isLocked: !editorIsLockedDict[id] };
      })
    ));
  }

  const deleteAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (somethingSelectedAndLocked || binHexfill === ICON_INACTIVE_COLOR) return;
    dispatch(onRemoveEntities_Global(selectedEntityIds));
    dispatch(onRemoveSelection(selectedEntityIds));
  }

  return (
    <>
      {/* <button onClick={() => null}
        className={CSS.ContextMenuButton}
        onMouseOver={() => {
          setAspectRatioHexFill(somethingSelectedAndLocked
            ? ICON_INACTIVE_COLOR
            : somethingSelected
              ? ICON_ACTIVE_BLUE_COLOR
              : ICON_INACTIVE_COLOR);
        }}
        onMouseOut={() => {
          setAspectRatioHexFill(somethingSelectedAndLocked
            ? ICON_INACTIVE_COLOR
            : somethingSelected
              ? DEFAULT_ICON_COLOR
              : ICON_INACTIVE_COLOR);
        }}>
        {(
        aspectRatioLocked
          ? <AspectRatioLockedIcon hexFill={aspectRatioLockHexfill} />
          : <AspectRatioUnlockedIcon hexFill={aspectRatioLockHexfill} />
        )}
      </button> */}

      <button onClick={copyAction}
        className={CSS.ContextMenuButton}
        onMouseOver={() => {
          setCopyHexFill(somethingSelectedAndLocked
            ? ICON_INACTIVE_COLOR
            : somethingSelected
              ? ICON_ACTIVE_BLUE_COLOR
              : ICON_INACTIVE_COLOR);
        }}
        onMouseOut={() => {
          setCopyHexFill(somethingSelectedAndLocked
            ? ICON_INACTIVE_COLOR
            : somethingSelected
              ? DEFAULT_ICON_COLOR
              : ICON_INACTIVE_COLOR);
        }}
        title="Duplicate">
        <DuplicateIcon hexFill={copyHexfill} title="Duplicate" />
      </button>

      {!is3dMode &&
      <button onClick={lockAction} className={CSS.ContextMenuButton} title={somethingSelectedAndLocked ? "Unlock" : "Lock"}>
        {somethingSelectedAndLocked ? (
          <PadlockClosedIcon hexFill={ICON_ACTIVE_BLUE_COLOR} title={somethingSelectedAndLocked ? "Unlock" : "Lock"} />
        ) : (
          <PadlockOpenIcon hexFill={lockHexfill} title={somethingSelectedAndLocked ? "Unlock" : "Lock"} />
        )}
      </button>
      }

      <button
        onClick={deleteAction}
        className={CSS.ContextMenuButton}
        onMouseOver={() => {
          setBinHexFill(somethingSelectedAndLocked
            ? ICON_INACTIVE_COLOR
            : somethingSelected
              ? ICON_ACTIVE_BLUE_COLOR
              : ICON_INACTIVE_COLOR);
        }}
        onMouseOut={() => {
          setBinHexFill(somethingSelectedAndLocked
            ? ICON_INACTIVE_COLOR
            : somethingSelected
              ? DEFAULT_ICON_COLOR
              : ICON_INACTIVE_COLOR);
        }}
        title="Delete">
        <BinIcon title="Delete" hexFill={binHexfill} />
      </button>
    </>
  );
};

export default React.memo(EntityActionContextMenu);