import React from 'react';
import { IIconParentProps } from './index';
import { DEFAULT_ICON_COLOR } from '../../utils';

const videoIcon: React.SFC<IIconParentProps> = ({
	hexFill: fill,
	viewBox,
	className,
	onClick = () => {},
}) => {
	const hexFill = fill || DEFAULT_ICON_COLOR;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="20px"
			height="20px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 20 20`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<title>Video</title>
			<g id="Expanded" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Video" transform="translate(-10.000000, -10.000000)" fill={hexFill} fillRule="nonzero">
					<path d="M14.5,27 C15.1532187,27 15.7089149,27.4175435 15.9147505,28.0002925 L29.5,28 C29.7761424,28 30,28.2238576 30,28.5 C30,28.7454599 29.8231248,28.9496084 29.5898756,28.9919443 L29.5,29 L15.9143985,29.000703 C15.7082819,29.5829319 15.1528467,30 14.5,30 C13.8471533,30 13.2917181,29.5829319 13.0856015,29.000703 L10.5,29 C10.2238576,29 10,28.7761424 10,28.5 C10,28.2545401 10.1768752,28.0503916 10.4101244,28.0080557 L10.5,28 L13.0852495,28.0002925 C13.2910851,27.4175435 13.8467813,27 14.5,27 Z M14.5,28 C14.2238576,28 14,28.2238576 14,28.5 C14,28.7761424 14.2238576,29 14.5,29 C14.7761424,29 15,28.7761424 15,28.5 C15,28.2238576 14.7761424,28 14.5,28 Z M29,10 C29.5522847,10 30,10.4477153 30,11 L30,24 C30,24.5522847 29.5522847,25 29,25 L11,25 C10.4477153,25 10,24.5522847 10,24 L10,11 C10,10.4477153 10.4477153,10 11,10 L29,10 Z M29,11 L11,11 L11,24 L29,24 L29,11 Z M17.4342815,13.5 C17.5968716,13.5 17.7566471,13.5438955 17.8978149,13.6273476 L17.8978149,13.6273476 L23.0290921,16.6607301 C23.4770963,16.9255703 23.6327446,17.5159826 23.3767421,17.9794528 C23.2939748,18.129296 23.1739349,18.2534798 23.0290921,18.3391045 L23.0290921,18.3391045 L17.8978149,21.372487 C17.4498106,21.6373272 16.8791004,21.4763057 16.623098,21.0128354 C16.5424307,20.8667942 16.5,20.7015029 16.5,20.5332998 L16.5,20.5332998 L16.5,14.4665348 C16.5,13.9327324 16.9182921,13.5 17.4342815,13.5 Z M17.375,14.5 L17.375,20.5 L22.625,17.5 L17.375,14.5 Z"></path>
				</g>
			</g>
		</svg>
	);
};

export default videoIcon;
