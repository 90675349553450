import React, { useState, useRef, FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../../../typings';
import { convMmToUnit, getInputStepByUnit } from '../../../../../../../utils';
import { onSetMultipleComponentProps_Cn_Doc, IMultipleEntityProps_Cn_Doc } from '../../../../../../../store/actions';
import { NumericalInput } from '../../../../../Inputs';
import { ISpatialComponentUnion, ITuple4, IButton, IUnitTypes } from '../../../../../../r3f/r3f-components/component-data-structure';
import FillBorderColPicker from './FillBorderColPicker/FillBorderColPicker';
import ColorCircle from '../../../Shared/ColorCircle/ColorCircle';
import Slider from '../../../../../Slider/Slider';
import FontInputFields from '../../Text/Text'
import CSS from './BtnAppearance.scss';

export enum IColorType {
	fillColor = 'fillColor',
	borderColor = 'borderColor',
}

const BtnAppearanceContent: FunctionComponent = () => {
	// Redux
	const dispatch = useDispatch();
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const id = selectedEntityIds[0];
	const entityContentDict = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const targetDisplayUnits = useSelector((state: IDesignerState) => state.editorReducer.editorDoc?.targetDisplayInfo?.displayUnits);
	const targetHeightInMm = useSelector((state: IDesignerState) => state.editorReducer.editorDoc?.targetDisplayInfo?.heightInMm);
	const editorColor = useSelector((state: IDesignerState) => {
		if (!state.editorReducer.editorDoc) return undefined;
		return state.editorReducer.editorDoc.rgbaColors[id] as ITuple4;
	});
	const editorBorderRgba = useSelector((state: IDesignerState) => {
		if (!state.editorReducer.editorDoc) return undefined;
		if (!state.editorReducer.editorDoc.borderRgba) return undefined;
		return state.editorReducer.editorDoc.borderRgba[id] as ITuple4;
	})

	// State
	const [activeColorType, setActiveColorType] = useState<IColorType | null>(null);
	const fillColorCircleRef = useRef();
	const borderColorCircleRef = useRef();
	const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
	
	if (selectedEntityIds.length === 0) return null;

	const unit  = targetDisplayUnits || IUnitTypes.cm; // TODO: remove conditional
	let multiplier = convMmToUnit(unit === IUnitTypes.coords ? 1 : targetHeightInMm, unit) / 4;

	const {
		color,
		borderRgba,
		borderRadius = 0,
		borderWidth = 0,
	} = entityContentDict[selectedEntityIds[0]] as IButton;

	const onClickHandler = (colorType: IColorType) => {
		setActiveColorType(colorType);
		setShowColorPicker(prevState => !prevState);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div className={CSS.SliderCol}>
				<div className={CSS.Title}>
					Corner radius
				</div>
				<div className={CSS.InputRow}>
					<Slider
						className={CSS.Slider}
						value={borderRadius * 100}
						max={100}
						min={0}
						step={1}
						onChange={val => {
							const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
							for (let i = 0; i < selectedEntityIds.length; i++) {
								const entityProps: IMultipleEntityProps_Cn_Doc = {
									id: selectedEntityIds[i],
									borderRadius: val / 100,
								};
								multipleEntityProps_Cn_Doc[i] = entityProps;
							}
							dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
						}}
					/>
					<NumericalInput
						className={CSS.Input}
						value={borderRadius * 100}
						min={0}
						max={100}
						decimals={0}
						labelText='%'
						step={1}
						onChange={val => {
							const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
							for (let i = 0; i < selectedEntityIds.length; i++) {
								const entityProps: IMultipleEntityProps_Cn_Doc = {
									id: selectedEntityIds[i],
									borderRadius: val / 100,
								};
								multipleEntityProps_Cn_Doc[i] = entityProps;
							}
							dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
						}}
					/>
				</div>
			</div>
			<div className={CSS.ColorSelectors}>
				<div>Fill color</div>
				<div className={CSS.ColorRow}>
					<div 
						className={CSS.ColorCircleContainer}
						ref={fillColorCircleRef} 
						style={{ width: 'fit-content' }}
					>
						<ColorCircle
							isActive={activeColorType === IColorType.fillColor}
							rgba={editorColor || (color as ITuple4)}
							onClick={() => onClickHandler(IColorType.fillColor)}
							rectangle={true}
						/>
					</div>
				</div>
			</div>
			<div className={CSS.FlexRow} style={{height: '60px'}}>
				<div className={CSS.FlexColumn}>
					<div className={CSS.Title}>
						Border width
					</div>	
					<NumericalInput
						className={CSS.BorderWidthInput}
						value={borderWidth * multiplier}
						min={0}
						decimals={2}
						step={getInputStepByUnit(targetDisplayUnits)}
						labelText={targetDisplayUnits}
						onChange={v => {
							const val = v / multiplier;
							const multipleEntityProps_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
							for (let i = 0; i < selectedEntityIds.length; i++) {
								const { borderRgba } = entityContentDict[selectedEntityIds[i]] as ISpatialComponentUnion;
								const entityProps: IMultipleEntityProps_Cn_Doc = {
									id: selectedEntityIds[i],
									borderWidth: val,
									borderRgba: borderRgba || [75, 145, 215, 1],
								};
								multipleEntityProps_Cn_Doc[i] = entityProps;
							}
							dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityProps_Cn_Doc));
						}}
					/>
					
				</div>
				<div 
					className={CSS.FlexColumn} 
					style={{opacity: !!borderWidth ? 1 : 0.4}}
				>
					<div className={CSS.Title}>Border color</div>
					<div 
						className={CSS.ColorCircleContainer}
						ref={borderColorCircleRef} 
						style={{ width: 'fit-content' }}>
						<ColorCircle
							isActive={activeColorType === IColorType.borderColor}
							disabled={!borderWidth}
							rgba={editorBorderRgba || (borderRgba as ITuple4) || [0, 0, 0, 1]}
							onClick={() => onClickHandler(IColorType.borderColor)}
							rectangle={true}
						/>
					</div>
				</div>
			</div>
			<FontInputFields />

			<FillBorderColPicker
				show={showColorPicker}
				targetRef={
					activeColorType === IColorType.fillColor
						? fillColorCircleRef
						: borderColorCircleRef
				}
				onClose={() => {
					setShowColorPicker(false);
					setActiveColorType(null);
				}}
				activeColorType={activeColorType}
			/>
		</div>
	);
};

export default React.memo(BtnAppearanceContent);
