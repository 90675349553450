import { io } from 'socket.io-client';
import { SyncDocClient } from './syncdocclient';
import * as Sentry from '@sentry/react';
import * as zw from 'zw-api-client/src/browser';
import {
	IContentDoc,
	IEditorDoc,
	IDocType,
	IEventType,
} from './components/r3f/r3f-components/component-data-structure';
import { Store } from 'redux';
import { 
	IChange_Cn_Doc_Action, 
	IChange_Ed_Doc_Action, 
	IContentDocActionTypes, 
	IEditorDocActionTypes, 
	IOnAddToastAction, 
	ISetPRojectInfoAction, 
	ISetSceneSnapshotsAction, 
	ISetUserIdAction, 
	IUserActionTypes, 
	onUpdateUsers_Global,
	IOnSetSocketIsReconnectingAction,
	IOnSetSocketHasConnectedAction,
	IOnSetProjectLoadingProgressAction,
	IOnSetProjectLoadingFailureAction,
	IOnChangeZoomLevelAction,
	IOnSetSelection_Action,
	IOnRemoveToastAction
 } from './store/actions';
import { getSnapshotDictFromLStorage, removeExpiredSceneSnapshots } from './utils';
import { sleep } from 'zw-api-client/src/zml';
import { IBaseToast, IDesignerState, IToastContrast, IToastSize, IToastType } from '../typings';
import { ToastsData } from './utils/toasts-data';
import * as settings from './settings';
import uuid4 from 'uuid/v4';

export interface ISyncDocUser {
	id: string;
	name: string;
	avatarUrl: string | null;
}

interface Project {
	id: string;
	title: string;
}

interface WSConfig {
	url?: string;
	path?: string;
	user?: ISyncDocUser;
	project?: Project;
	error?: string;
	status?: number;
}

export interface SocketData {
	user: ISyncDocUser;
	project: Project;
}

let syncContentDoc: SyncDocClient<IContentDoc>;
let syncEditorDoc: SyncDocClient<IEditorDoc>;
let reconnectionToastsIdArray: string[] = []; 
export let zwClient: zw.Client;

export const getSyncContentDoc = () => {
	if (!syncContentDoc) {
		Sentry.withScope(scope => {
			scope.setExtra('syncContentDoc', syncContentDoc)
			scope.setExtra('reconnectionToastsIdArray', reconnectionToastsIdArray)
			Sentry.captureMessage('Empty content doc')
		})
	}
	return syncContentDoc
}
export const getSyncEditorDoc = () => {
	if (!syncEditorDoc) {
		Sentry.withScope(scope => {
			scope.setExtra('syncEditorDoc', syncEditorDoc)
			scope.setExtra('reconnectionToastsIdArray', reconnectionToastsIdArray)
			Sentry.captureMessage('Empty content doc')
		})
	}
	return syncEditorDoc
}

let projectId = location.pathname.replace(/\//g, '');

export const setupWebSocket = async (store: Store): Promise<SocketData> => {
	// if no project id, re-direct to my.zap.works
	if (!projectId || projectId.length === 0) {
		window.location.href = settings.ZW_BASE_URL;
		return;
	}

	zwClient = new zw.Client({
		clientId: settings.ZW_CLIENT_ID,
		redirectURI: `${settings.BASE_URL}callback/`,
		env: settings.ZW_ENV,
		debug: settings.DEBUG
	});

	// TODO: use react router to handle callback
	try {
		await zwClient.checkForAuthorizationResponse();

		// 5% loading progress
		store.dispatch<IOnSetProjectLoadingProgressAction>({
			type: IUserActionTypes.SET_PROJECT_LOADING_PROGRESS,
			payload: 5
		})

		const qs = new URLSearchParams(location.search);
		if (qs.get('error')) return; // TODO: display auth error
		if (qs.get('code')) {
			projectId = atob(qs.get('state')).replace('p', '');
			history.replaceState({}, document.title, `/${projectId}/`); // change URL from /callback/?code=... to /123/
			await zwClient.makeTokenRequest(); // get access token

			// 20% loading progress
			store.dispatch<IOnSetProjectLoadingProgressAction>({
				type: IUserActionTypes.SET_PROJECT_LOADING_PROGRESS,
				payload: 20
			})

		} else {
			zwClient.makeAuthorizationRequest(
				'user:read account:read media:read media:write project:read project:write project:publish',
				btoa(`p${projectId}`)
			); // redirect the user to ZapWorks login
			return;
		}
	} catch (err) {
		store.dispatch<IOnAddToastAction>({
			type: IUserActionTypes.ADD_TOAST,
			payload: ToastsData.AuthorizationError
		});
		store.dispatch<IOnSetProjectLoadingFailureAction>({
			type: IUserActionTypes.SET_PROJECT_LOADING_FAILURE,
			payload: true
		})
		Sentry.captureException(err);
	}

	return await createWebSocket(store)
}

const getWebSocketConfig = async (store: Store, isRetry=false): Promise<WSConfig | null> => {
	try {
		const headers = {
			'X-Authorization': `Basic ${zwClient.getAccessToken()}`,
			'ZW-Environment': settings.ZW_ENV,
		}
		const res = await fetch(`${settings.CDS_URL}${projectId}/`, {headers});
		const result = await res.json();
		return {...result, status: res.status}
	} catch (err) {
		console.error('Unable to get socket config:', err);
		const { socketIsReconnecting } = (store.getState() as IDesignerState).userReducer;
		if (isRetry || socketIsReconnecting) return null; // don't display errors if retry / reconnecting
		store.dispatch<IOnAddToastAction>({
			type: IUserActionTypes.ADD_TOAST,
			payload: ToastsData.SocketConnectionError
		});
		store.dispatch<IOnSetProjectLoadingFailureAction>({
			type: IUserActionTypes.SET_PROJECT_LOADING_FAILURE,
			payload: true
		})
		Sentry.captureException(err);
	}
	return null;
}

const createWebSocket = async (store: Store, isRetry=false): Promise<SocketData> => {
	const wsConfig = await getWebSocketConfig(store, isRetry);
	const { socketHasConnected } = (store.getState() as IDesignerState).userReducer; // TODO: refactor?

	if (!socketHasConnected) {
		store.dispatch<IOnSetProjectLoadingProgressAction>({
			type: IUserActionTypes.SET_PROJECT_LOADING_PROGRESS,
			payload: 35
		})
	}

	if (wsConfig === null) {
		await sleep(1000)
		return createWebSocket(store, true) // retry
	}

	console.log('wsConfig', wsConfig);

	// if error show toast and return.
	if (typeof wsConfig.error === 'string') {
		let errorPayload: IBaseToast;
		if (wsConfig.status >= 500) {
			errorPayload = ToastsData.InternalServerError;
		} else if (wsConfig.status === 403) {
			errorPayload = ToastsData.NoAccessGrantedError;
		} else if (wsConfig.status === 401) {
			errorPayload = ToastsData.SessionExpiredError;
		}

		store.dispatch<IOnAddToastAction>({
			type: IUserActionTypes.ADD_TOAST,
			payload: errorPayload || ToastsData.SocketConnectionError
		});
		store.dispatch<IOnSetProjectLoadingFailureAction>({
			type: IUserActionTypes.SET_PROJECT_LOADING_FAILURE,
			payload: true
		})
		Sentry.captureException(wsConfig.error);
		return;
	};

	document.title = `${wsConfig.project.title} | ZapWorks Designer`;
	Sentry.setUser({ id: wsConfig.user.id });
	Sentry.setExtra('project', wsConfig.project.id);

	const socket = io(wsConfig.url, {
		path: wsConfig.path,
		query: { env: settings.ZW_ENV },
		auth: { token: zwClient.getAccessToken(), userId: wsConfig.user.id },
		reconnection: false,
		autoConnect: false
	});

	socket.on('connect', async () => {
		console.log('Connected to CDS')
		await waitForDocs();
		const { 
			socketIsReconnecting, 
			socketHasConnected, 
		} = (store.getState() as IDesignerState).userReducer;

		if (socketIsReconnecting) {
			store.dispatch<IOnAddToastAction>({
				type: IUserActionTypes.ADD_TOAST,
				payload: ToastsData.SocketConnectionSuccess
			});
			store.dispatch<IOnSetSocketIsReconnectingAction>({
				type: IUserActionTypes.SET_SOCKET_RECONNECTING,
				payload: false
			})
		}
		if (!socketHasConnected) {
			store.dispatch<IOnSetProjectLoadingProgressAction>({
				type: IUserActionTypes.SET_PROJECT_LOADING_PROGRESS,
				payload: 100
			})
			store.dispatch<IOnSetSocketHasConnectedAction>({
				type: IUserActionTypes.SET_SOCKET_HAS_CONNECTED,
				payload: true
			})
		}

	});
	socket.on('disconnect', (reason) => {
		console.log('Disconnected from CDS')
		if (reason === 'io server disconnect') { // forcefully disconnected by the server (https://socket.io/docs/v3/client-api/index.html#Event-%E2%80%98disconnect%E2%80%99)
			store.dispatch<IOnAddToastAction>({
				type: IUserActionTypes.ADD_TOAST,
				payload: ToastsData.AuthorizationError
			})
			return;
		}
		const { socketIsReconnecting } = (store.getState() as IDesignerState).userReducer;
		if (!socketIsReconnecting) {
			reconnectionToastsIdArray.push(uuid4()); 
			store.dispatch<IOnAddToastAction>({
				type: IUserActionTypes.ADD_TOAST,
				payload: {...ToastsData.SocketReconnecting, id: reconnectionToastsIdArray[reconnectionToastsIdArray.length - 1]}
			});
			store.dispatch<IOnSetSocketIsReconnectingAction>({
				type: IUserActionTypes.SET_SOCKET_RECONNECTING,
				payload: true,
			})
		}
		setTimeout(() => createWebSocket(store), 1000);
	});
	socket.on('connect_error', (err: Error) => {
		console.log('Unable to connect to CDS:', err);
		store.dispatch<IOnAddToastAction>({
			type: IUserActionTypes.ADD_TOAST,
			payload: ToastsData.SocketConnectionError
		});
		store.dispatch<IOnSetProjectLoadingFailureAction>({
			type: IUserActionTypes.SET_PROJECT_LOADING_FAILURE,
			payload: true
		})
		Sentry.captureException(err);
	});
	socket.on('connect_failed', (err: Error) => {
		console.log('Error from CDS:', err.stack);
		store.dispatch<IOnAddToastAction>({
			type: IUserActionTypes.ADD_TOAST,
			payload: ToastsData.SocketConnectionError
		})
		store.dispatch<IOnSetProjectLoadingFailureAction>({
			type: IUserActionTypes.SET_PROJECT_LOADING_FAILURE,
			payload: true
		})
		Sentry.captureException(err);
	});
	socket.io.on('reconnection_attempt', () => {
		console.log('Attempting to reconnect to CDS')
	});
	socket.io.on('reconnect', () => {
		console.log('Reconnected?', socket.connected);
		reconnectionToastsIdArray.forEach(id => {
			store.dispatch<IOnRemoveToastAction>({
				type: IUserActionTypes.REMOVE_TOAST,
				payload: id
			});
		});
	});

	[syncContentDoc, syncEditorDoc] = await Promise.all([
		SyncDocClient.create<IContentDoc>(socket, IDocType.content_doc, syncContentDoc?.getDoc()),
		SyncDocClient.create<IEditorDoc>(socket, IDocType.editor_doc, syncEditorDoc?.getDoc())
	]);

	syncEditorDoc.on('change', () => {
		store.dispatch<IChange_Ed_Doc_Action>({
			type: IEditorDocActionTypes.CHANGE_ED_DOC_REDUX,
			editorDoc: syncEditorDoc.getDoc(),
		});
	});

	syncContentDoc.on('change', () => {

		// // TODO: This will lead to a slight performance hit, needs refactor
		const contentDoc = syncContentDoc.getDoc();
		const { selectedEntityIds } = (store.getState() as IDesignerState).userReducer;
		const componentIds = Object.keys(contentDoc.componentsById);
		const filtSelEntityIds = selectedEntityIds.filter(id => componentIds.includes(id));

		store.dispatch<IOnSetSelection_Action>({
			type: IUserActionTypes.SET_SELECTED_ENTIY_IDS,
			payload: filtSelEntityIds,
		})

		store.dispatch<IChange_Cn_Doc_Action>({
			type: IContentDocActionTypes.CHANGE_CN_DOC_REDUX,
			contentDoc,
		});
	});

	socket.on(IEventType.users_update, async (users: ISyncDocUser[]) => {
		await waitForDocs();
		store.dispatch(onUpdateUsers_Global(users));
	});

	socket.connect();

	await waitForDocs();

	socket.emit(IEventType.users_get, (users: ISyncDocUser[]) => {
		store.dispatch(onUpdateUsers_Global(users));
	});

	removeExpiredSceneSnapshots(); // remove any expired snapshots (from other projects too)

	if(localStorage.getItem(`${wsConfig.project.id}_zoom_level`)){
		store.dispatch<IOnChangeZoomLevelAction>({
			type: IUserActionTypes.CHANGE_ZOOM_LEVEL, 
			payload: parseFloat(localStorage.getItem(`${wsConfig.project.id}_zoom_level`))
		});
	}

	store.dispatch<IChange_Cn_Doc_Action>({
		type: IContentDocActionTypes.CHANGE_CN_DOC_REDUX,
		contentDoc: syncContentDoc.getDoc(),
	});

	store.dispatch<IChange_Ed_Doc_Action>({
		type: IEditorDocActionTypes.CHANGE_ED_DOC_REDUX,
		editorDoc: syncEditorDoc.getDoc(),
	});

	store.dispatch<ISetUserIdAction>({
		type: IUserActionTypes.SET_USER_ID,
		payload: { userId: wsConfig.user.id },
	});

	store.dispatch<ISetPRojectInfoAction>({
		type: IUserActionTypes.SET_PROJECT_INFO,
		payload: { project: wsConfig.project },
	});

	store.dispatch<ISetSceneSnapshotsAction>({
		type: IUserActionTypes.SET_SCENE_SNAPSHOTS,
		payload: getSnapshotDictFromLStorage(localStorage, wsConfig.project.id),
	});

	return {
		user: wsConfig.user,
		project: wsConfig.project
	}
}


export const waitForDocs = () => {
	return Promise.all([syncContentDoc.waitForDoc(), syncEditorDoc.waitForDoc()])
}
