// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ScrollContainer--2NFhO {\n    overflow-y: scroll;\n\tpadding-right: 4px;\n    margin-right: -4px;\n}", ""]);
// Exports
exports.locals = {
	"ScrollContainer": "ScrollContainer--2NFhO"
};
module.exports = exports;
