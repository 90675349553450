export type IVector2 = number[];
export type IVector3 = number[];
export type IVector4 = number[];
export type ITuple2 = [number, number];
export type ITuple3 = [number, number, number];
export type ITuple4 = [number, number, number, number];
export type ITuple3Area = [ITuple3, ITuple3, ITuple3, ITuple3];
export type ITuple2Area = [ITuple3, ITuple3, ITuple3, ITuple3];

export interface IVector3Dict {
	[id: string]: IVector3;
}

export interface ITuple3Dict {
	[id: string]: ITuple3;
}

export enum IFontTypes {
	Roboto = 'Roboto',
	NotoSans = 'Noto Sans',
	Comfortaa = 'Comfortaa',
	Cookie = 'Cookie',
	Philosopher = 'Philosopher',
	Quicksand = 'Quicksand',
	Trirong = 'Trirong',
	Trocchi = 'Trocchi',
	AdventPro = 'Advent Pro',
	HennyPenny = 'Henny Penny',
	Orbitron = 'Orbitron',
	Sacramento = 'Sacramento',
	SnowburstOne = 'Snowburst One',
	Syncopate = 'Syncopate',
	Wallpoet = 'Wallpoet',
	SirinStencil = 'Sirin Stencil',
}

export enum IUnitTypes {
	pt = 'pt',
	mm = 'mm',
	cm = 'cm',
	m = 'm',
	inch = 'inch',
	ft = 'ft',
	coords = 'coords'
}

export enum ITextAlignment {
	center = 'center',
	right = 'right',
	left = 'left',
}

export enum IAreaTypes {
	a5 = 'a5',
	a4 = 'a4',
	a3 = 'a3',
	ltr = 'ltr',
	b5 = 'b5',
	b4 = 'b4',
	pstc = 'pstc',
	bsnc = 'bsnc',
	pstr = 'pstr',
	tbl = 'tbl',
	lghtBx = 'lghtBx',
	blbrd = 'blbrd',
	custom = 'custom',
}

export enum IEventType {
	connect = 'connect',
	connection = 'connection',
	disconnect = 'disconnect',
	pos_doc_changed = 'pos_doc_changed',
	pos_doc_change = 'pos_doc_change',
	selection_changed = 'selection_changed',
	selection_change = 'selection_change',
	selection_removed = 'selection_removed',
	selection_remove = 'selection_remove',
	automerge_update = 'automerge_update',
	users_update = 'users-update',
	users_get = 'users-get',
}

export enum IDocType {
	editor_doc = 'editor_doc',
	content_doc = 'content_doc',
}

export enum ILocalStorageKeys {
	displayUnit = 'displayUnit',
}

export enum IEntityDrawerTypes {
	none = 'none',
	button = 'button',
	text = 'text',
	image = 'image',
	video = 'video',
	model3d = 'model3d'
}

export enum ITransformProps {
	scale = 'scale',
	position = 'position',
	rotation = 'rotation',
	scalesInverted = 'scalesInverted',
	aspectRatioLocked = 'aspectRatioLocked',
}

export interface IProjectColor {
	id: string;
	hex: string;
	rgba: IVector4; 
}
